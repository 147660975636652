import React from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { connect } from 'react-redux';
import {
    ButtonGroup,
    Button,
} from 'shards-react';
import Header from '../../../components/widget/widgetTitle';
import { userManagerActions } from '..';
import { modalActions } from '../../modals/_actions';
import { useTranslation } from 'react-i18next';

const DeactivateUser = ({ modalID, data, dispatch }) => {
    const deactivateUser = () => {
        dispatch(userManagerActions.deactivate(data));
        closeModal();
    };
    const { t } = useTranslation();

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal">
            <Header title={t('module.scoreLogic.modal.deactivateUser.headerTitle')} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal } />

            <div className="p-10">
                <p className="label">{t('module.scoreLogic.modal.deactivateUser.youUser')}</p>
                <ButtonGroup className="btn-group-actions site-modal__actions">
                    <Button theme="primary" className="rounded-sm thin mr-2" onClick={ () => { deactivateUser(); } } >{t('module.scoreLogic.modal.deactivateUser.save')}</Button>
                    <Button theme="secondary" className="rounded-sm thin delete" onClick={ () => { closeModal(); } }>{t('module.scoreLogic.modal.deactivateUser.cancel')}</Button>
                </ButtonGroup>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { users, modals } = state;
    return {
        users,
        modals,
    };
};

const connectedDeactivateUserr = connect(mapStateToProps)(DeactivateUser);
export { connectedDeactivateUserr as DeactivateUser };

import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col } from "shards-react"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { useTranslation } from "react-i18next"
import SearchIcon from "@material-ui/icons/Search"
import { Button } from "@material-ui/core"
import { CSVLink } from "react-csv"
import PageTitle from "../../../components/common/PageTitle"
import { alertActions } from "../../alert"
import { userManagerActions, UserTable } from ".."

import { LoadingView, ErrorsView, EmptyContentView } from "../../../views"
import { CustomIcon } from "./CustomIcon"

const UsersListView = ({ loading, items, error }) => {
  const { t } = useTranslation()
  const authentication = useSelector((state) => state.authentication)
  const {
    profile: {
      mfi: { mfiName },
    },
  } = authentication
  const dispatch = useDispatch()
  const [searchInput, setSearchInput] = useState("")
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    if (items) {
      const filtered = items.filter(
        (item) =>
          item.username.toLowerCase().includes(searchInput) ||
          item.name.toLowerCase().includes(searchInput)
      )
      setFilteredData(filtered)
    }
  }, [searchInput, items])

  const usersData = (filteredData || []).map((item) => {
    const {
      id,
      username,
      name,
      email,
      telephone,
      subRole,
      hierarchyEntity,
      status,
    } = item
    return {
      id,
      username,
      name,
      email,
      telephone,
      subRole,
      hierarchyEntity: hierarchyEntity?.name,
      status,
    }
  })

  const handleSearchInput = (e) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchInput(searchTerm)
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row
        noGutters
        className="page-header pt-4 flex items-end justify-between"
      >
        <PageTitle
          sm="4"
          title={t("data.topBarNav.userAccounts")}
          className="text-sm-left"
        />
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              style={{
                fontFamily: "inherit",
                fontSize: "inherit",
                paddingTop: "2px",
                padding: "4px 12px",
                borderRadius: "4px 0 0 4px",
                border: "1px solid #185597",
                outline: "none",
              }}
              className="inputSearch"
              onChange={handleSearchInput}
              autoFocus
            />
            <span
              style={{
                borderRadius: "0 3px 3px 0",
                borderTop: "1px solid",
                borderRight: "1px solid",
                borderBottom: "1px solid",
                marginLeft: "-2px",
                paddingLeft: "4px",
              }}
            >
              <SearchIcon
                style={{
                  color: "#a1b6ca",
                  marginTop: "6.5px",
                  cursor: "pointer",
                }}
              />
            </span>
          </div>
          <CSVLink
            className="hover:no-underline"
            data={usersData}
            filename={`${mfiName}_users.csv`}
            onClick={() => dispatch(alertActions.success("Downloading..."))}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<CustomIcon />}
            >
              Download Users
            </Button>
          </CSVLink>
        </div>
      </Row>
      <Row>
        <Col>
          {loading && <LoadingView />}
          {error && <ErrorsView error={error} />}
          {!loading && !error && isEmpty(items) && (
            <EmptyContentView title={t("data.topBarNav.noUserFound")} />
          )}
          {filteredData && filteredData.length > 0 && (
            <UserTable data={usersData} />
          )}
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => {
  const { users } = state
  const { loading, items, error } = users

  return { loading, items, error }
}

const mapDispatchToProps = (dispatch) => {
  dispatch(userManagerActions.getAll())
  return {}
}

const connectedUserListView = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersListView)
export { connectedUserListView as UsersListView }

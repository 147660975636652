import React from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { connect, useSelector } from 'react-redux';
import {
    ButtonGroup,
    Button,
} from 'shards-react';
import Header from '../../../../components/widget/widgetTitle';
import { modalActions } from '../../../modals';
import { clientActions } from '../../_actions';
import { LoadingView } from '../../../../views';

const AssignBackModal = ({ data, modalID, dispatch}) => {
    const { id: { payload, refetchClients } } = data;
    const { assignbackloading } = useSelector((state) => state.clients);

    // eslint-disable-next-line no-underscore-dangle
    const assignBack = () => {
        // dispatch(configActions.AssignBackModal(id));
        dispatch(clientActions.AssignBack(payload && payload, refetchClients))
        //for better ux
        setTimeout(() => {
            if (assignbackloading !== true) {
                closeModal();
            }
        }, 1800);

    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal w-5">
            <Header title="Assign Back" icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={closeModal} />
            <div className="p-10">
                <p>Are you sure you want to assign back {payload && payload.name}</p>
                {assignbackloading && <LoadingView/>}
                <ButtonGroup className="btn-group-actions site-modal__actions">
                    <Button theme="primary" className="thin delete mr-2" onClick={() => { assignBack(); }} >Yes</Button>
                    <Button theme="secondary" className="thin" onClick={() => { closeModal(); }}>Cancel</Button>
                </ButtonGroup>
            </div>
        </div>
    );
};

const connectedAssignBackModal = connect(null)(AssignBackModal);
export { connectedAssignBackModal as AssignBackModal };

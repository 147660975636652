import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'shards-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isObject } from 'lodash';
import { Button, makeStyles } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useLocation } from "react-router-dom";
import { FilterMfis } from '../../components/filterMfis';
import SearchClients from '../../components/client/search';
import { ClientErrorsView } from '../../components/ClientError';
import './client.scss';
import PageTitle from '../../../../components/common/PageTitle';
import { ListClients } from './listClients';
import { LoadingView, EmptyContentView } from '../../../../views';
import { authenticationActions } from '../../../authentication';
import { mfiManagerActions } from '../../../mfiManager';
import { clientActions } from '../../_actions';
import FilterClient from '../../components/client/filter';
import { pendingTasksActions } from '../pendingTasks/store/_actions';

const useStyles = makeStyles(() => ({
    filterButton: {
        backgroundColor: 'white',
        border: 'thin solid rgb(24, 85, 151)',
        boxShadow: 'none'
    }
}))

export const Clients = () => {
    const { t } = useTranslation();
    const client = useSelector((state) => state.clients);
    const user = useSelector((state) => state.authentication);
    const mfis = useSelector((state) => state.mfis);
    const singlePendingTask = useSelector((state) => state.pendingTasks?.singlePendingTask);
    const location = useLocation();
    const { profile: { mfi, roles } } = user;
    const allMfis = (roles?.includes('FA_ANALYST') || roles?.includes('FA_ADMIN')) && mfis?.items;
    const dispatch = useDispatch();
    const { clients, loading, error, searchClient, clientError, filteredClients, filterClientError } = client
    const [selectedMfi, setSelectedMfi] = useState({})
    const [state, setState] = useState({
        page: (searchClient?.content?.length > 0 ? searchClient?.number : clients?.number) || 0,
        perPage: 20
    });
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const classes = useStyles()
    const [pendingTasksActive, setPendingTasksActive] = useState({
        active: false,
        data: location?.state?.pendingTasks
    });

    const refetchClients = () => {
        dispatch(clientActions.getAllClients((selectedMfi?.id || mfi?.id), state?.perPage, state?.page));
    }

    const refetchPendingTasksClients = () => {
        (selectedMfi?.id || mfi?.id) && dispatch(pendingTasksActions.getSinglePendingTask((selectedMfi?.id || mfi?.id), state?.perPage, state?.page, {
            statusType: pendingTasksActive.data?.status?.type,
            loanOfficerName: pendingTasksActive.data?.loanOfficerName
        }))
    }

    useEffect(() => {
        dispatch(authenticationActions.getProfile());
    }, [dispatch]);

    useEffect(() => {
        dispatch(mfiManagerActions.getAllMFIs());
    }, [dispatch]);

    useEffect(() => {
        pendingTasksActive.active
            ? (selectedMfi?.id || mfi?.id) && dispatch(pendingTasksActions.getSinglePendingTask((selectedMfi?.id || mfi?.id), state?.perPage, state?.page, {
                statusType: pendingTasksActive.data?.status?.type,
                loanOfficerName: pendingTasksActive.data?.loanOfficerName
            }))
            : (selectedMfi?.id || mfi?.id) && dispatch(clientActions.getAllClients((selectedMfi?.id || mfi?.id), state?.perPage, state?.page));
    }, [dispatch, selectedMfi, mfi, state, pendingTasksActive]);


    useEffect(() => {
        if (location?.state !== undefined && isObject(location?.state?.pendingTasks)) {
            setPendingTasksActive((prevState) => ({
                ...prevState,
                active: true,
                data: location?.state?.pendingTasks
            }));
        }
    }, [location]);

    const selectQuestionnaire = (e) => {
        const { value } = e.target;
        const mfi = allMfis.filter(mfiItem => {
            return mfiItem.mfiName === value;
        })
        setSelectedMfi(mfi[0]);
    };

    const clientsData = clients?.content?.map((client) => ({ ...{ action: '' }, ...client, ...{ '': '' } }));
    const searchClientData = searchClient?.content?.map((client) => ({ ...{ action: '' }, ...client, ...{ '': '' } }));
    const filterClientData = filteredClients?.content?.map((client) => ({ ...{ action: '' }, ...client, ...{ '': '' } }));
    const pendingTasksData = pendingTasksActive?.active && singlePendingTask?.content?.map((client) => ({ ...{ action: '' }, ...client, ...{ '': '' } }));

    const activeData = (type) => {
        if (searchClientData?.length > 0) {
            return type ? searchClient : searchClientData;
        } else if (filterClientData?.length > 0) {
            return type ? filteredClients : filterClientData;
        } else if (pendingTasksData?.length >= 0) {
            return type ? singlePendingTask : pendingTasksData;
        } else {
            return type ? clients : clientsData;
        }
    }

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header pt-4 flex">
                <PageTitle sm="4" title={t('module.client.client.title')} className="text-sm-left" />
            </Row>
            <Row noGutters className="page-header pt-4 flex">
                <FilterMfis handler={selectQuestionnaire} selectedValue={selectedMfi?.mfiName !== undefined ? selectedMfi : mfi} items={allMfis?.length > 0 ? allMfis : clients[0]} />
                {(clients?.content?.length || searchClient?.content?.length) && (
                    <div className="application__actions ml-auto flex content-center">
                        <SearchClients mfiId={selectedMfi?.id || mfi?.id} />
                        <Button
                            variant="contained"
                            onClick={() => setIsFilterOpen(!isFilterOpen)}
                            startIcon={<FilterListIcon />}
                            endIcon={<ArrowDropDownIcon />}
                            className={classes.filterButton}
                        >
                            {t('module.client.arrears.filter.filter')}
                        </Button>
                    </div>
                )}
            </Row>
            <Row noGutters className="page-header pt-4 flex">
                {isFilterOpen && (
                    <FilterClient setIsFilterOpen={setIsFilterOpen} mfi={selectedMfi?.id || mfi?.id} />
                )}
            </Row>
            <Row className="mt-4 clientList">
                <Col>
                    {loading && <LoadingView />}
                    {error && <ClientErrorsView errorData={clientError?.response?.data || filterClientError?.response?.data} />}
                    {!loading && !error && ((isEmpty(clients?.content) || searchClient?.content?.length === 0 || filteredClients?.content?.length === 0 || activeData(false).length === 0))
                    && <EmptyContentView title={pendingTasksActive.active ? 'Empty' : t('module.client.client.noClientsFound')} />}
                    {!loading &&
                        <>
                            <ListClients
                                data={activeData(false)}
                                setState={setState}
                                refetchClients={ pendingTasksActive.active ? refetchPendingTasksClients : refetchClients}
                                state={state}
                                paginationData={activeData(true)}
                                roles={roles}
                            />
                        </>
                    }
                </Col>
            </Row>
        </Container>
    );
};

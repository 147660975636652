import React, { useEffect } from 'react';
import { isEmpty, pick } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { JobsTable } from './JobsTable';
import { toolsActions } from '..';

export const ListJobs = () => {
    const { importJobs } = useSelector((store) => store.tools);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(toolsActions.getAllImportBulkApplications());
    }, [dispatch]);
    const { t } = useTranslation()

    const allowedFlowDataItems = ['uploadedFileName', 'exportUrl'];
    const tableData = importJobs.map((item) => {
        const newItem = pick(item, allowedFlowDataItems);
        const { uploadedFileName: filename, exportUrl } = newItem;
        const status = !isEmpty(exportUrl) ? 'Completed' : 'In Progress';
        return { filename, status, download: exportUrl };
    });

    return (
        <div className="p-10">
            <h2 className="form-group-section">{t('module.tools.import.fileProcessing')}</h2>
            <div className="pr-10">
                <JobsTable data={tableData}/>
            </div>
        </div>
    );
};

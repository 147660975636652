import React, { useEffect, useCallback, useState } from "react"
import { connect } from "react-redux"
import { Container, Row, Col } from "shards-react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { Wizard, Steps, Step } from "react-albus"
import CssBaseline from "@material-ui/core/CssBaseline"
import { isEmpty } from "lodash"

import { withTranslation } from "react-i18next"
import { SetQuestionnaire } from "./SetQuestionnaire"
import { ComputationCodes } from "./ComputationCodes"
import { Assumptions } from "./Assumptions"
import { ManageProjectionPeriod } from "./ProjectionPeriod/ManageProjectionPeriod"
import { TimeDependent } from "./TimeDependent"
import { TimeDependentCalculated } from "./TimeDependentCalculated"
import { EveryMonthCalculated } from "./EveryMonthCalculated"
import { CashflowStatements } from "./CashflowStatements"
import { SingleCalculations } from "./SingleCalculations"
import { ScoringVariables } from "./ScoringVariables"
import { ScoreCard } from "./ScoreCard"
import { ApprovalWorkflow } from "./ApprovalWorkflow"
import { DocumentChecklist } from "./DocumentChecklist"
import { ScoreLogicTest } from "./ScoreLogicTest"

import { Navigation } from "../component/Navigation"
import { Heading } from "../component/Heading"
import { WizardActions } from "../component/WizardActions"
import { cslActions } from ".."
import { configActions } from "../../config"
import { cslService } from "../"
import { hasAuthority } from "../../../_helpers"

import "./ScoreLogic.scss"
import "./Animations.scss"
import "./Navigation.scss"

const ScoreLogic = ({ scoreLogic, dispatch, t }) => {
  const { uid, computationCodes, cashflowStatements, scoringVariables } =
    scoreLogic
  const noComputationCodes = isEmpty(computationCodes)
  const [selectedMfiId, setSelectedMfiId] = useState()

  useEffect(() => {
    if (!uid) {
      return
    }
    // const [xformId, xformVersion] = uid.split(':');

    if (!hasAuthority("MFI_ADMIN")) {
      dispatch(cslActions.getAssumptions(uid))
      dispatch(cslActions.getFormulas(uid))
      dispatch(cslActions.getScoreCardProfiles(uid))
      dispatch(cslActions.getDocumentChecklist(uid))
      dispatch(configActions.getCreditScoreReportSetup(uid))
      dispatch(configActions.getFirstComments(uid))
    }

    dispatch(cslActions.getSurveyCashflowStatements(uid))

    dispatch(configActions.getApplicationColumns(uid))

    dispatch(cslActions.getScoreCardCategories(uid))
    dispatch(cslActions.getScoreCardGrade(uid))
  }, [uid, dispatch])

  const createPredifined = useCallback(() => {
    let updateCashflowState = false
    const rows = {
      C: ["A", "-", "B"],
      F: ["C", "+", "D", "-", "E"],
      H: ["F", "-", "G"],
      K: ["J", "/", "I"],
    }
    const rowsDescription = {
      C: "Farm Operating Margin",
      F: "Household Operating Margin",
      H: "Net Cashflow",
      K: "Affordability",
    }
    const rowsKeys = Object.keys(rows)
    uid &&
      cashflowStatements &&
      cashflowStatements.length > 0 &&
      rowsKeys.forEach((key, i) => {
        const rowDefined = cashflowStatements.filter(
          (cashflowStatement) => cashflowStatement.code === key
        )
        if (rowDefined.length === 0) {
          let payload = {
            surveyUuid: uid,
            code: key,
            description: rowsDescription[key],
            timeDependentCalculatedVariables: null,
            everyMonthCalculatedVariables: null,
            variableCodes: null,
            formulaExpression: rows[key],
          }
          cslService.saveSurveyCashflowStatements(payload)
          updateCashflowState = true
        } else if (rowDefined.length > 0 && !rowDefined[0].formulaExpression) {
          const {
            id,
            code,
            description,
            timeDependentCalculatedVariables,
            everyMonthCalculatedVariables,
            variableCodes,
          } = rowDefined[0]
          const payload = {
            surveyUuid: uid,
            code,
            description,
            timeDependentCalculatedVariables,
            everyMonthCalculatedVariables,
            variableCodes,
            formulaExpression: rows[key],
          }
          if (id === -1) {
            cslService.saveSurveyCashflowStatements(payload)
          } else {
            payload.id = id
            cslService.updateSurveyCashflowStatements(payload)
          }
          updateCashflowState = true
        }
      })
    if (updateCashflowState) {
      dispatch(cslActions.getSurveyCashflowStatements(uid))
    }
  }, [cashflowStatements, uid, dispatch])

  useEffect(() => {
    createPredifined()
  }, [createPredifined])

  useEffect(() => {
    dispatch(cslActions.getComputationCodes(uid))
  }, [uid, noComputationCodes, dispatch])

  const titles = {
    questionnaire: t("module.scoreLogic.view.scoreLogic.titles.questionnaire"),
    computationCodes: t(
      "module.scoreLogic.view.scoreLogic.titles.computationCodes"
    ),
    assumptions: t("module.scoreLogic.view.scoreLogic.titles.assumptions"),
    projectionPeriod: t(
      "module.scoreLogic.view.scoreLogic.titles.projectionPeriod"
    ),
    tdv: t("module.scoreLogic.view.scoreLogic.titles.tdv"),
    tdcv: t("module.scoreLogic.view.scoreLogic.titles.tdcv"),
    emcv: t("module.scoreLogic.view.scoreLogic.titles.emcv"),
    cashflow: t("module.scoreLogic.view.scoreLogic.titles.cashflow"),
    otherCalculations: t(
      "module.scoreLogic.view.scoreLogic.titles.otherCalculations"
    ),
    sv: t("module.scoreLogic.view.scoreLogic.titles.sv"),
    scoreCard: t("module.scoreLogic.view.scoreLogic.titles.scoreCard"),
    approvalWorkflow: t(
      "module.scoreLogic.view.scoreLogic.titles.approvalWorkflow"
    ),
    documentChecklist: t(
      "module.scoreLogic.view.scoreLogic.titles.documentChecklist"
    ),
    scoreLogicTest: t(
      "module.scoreLogic.view.scoreLogic.titles.testScoreLogic"
    ),
  }

  if (hasAuthority("MFI_ADMIN")) {
    delete titles.computationCodes
    delete titles.assumptions
    delete titles.projectionPeriod
    delete titles.tdv
    delete titles.tdcv
    delete titles.emcv
    delete titles.cashflow
    delete titles.otherCalculations
    delete titles.approvalWorkflow
    delete titles.documentChecklist
    delete titles.scoreLogicTest
  }

  const classes = uid
    ? "setup-questionnaire-complete"
    : "setup-questionnaire-incomplete"

  return (
    <Container fluid className="main-content-container px-4">
      <CssBaseline />
      <Row noGutters className="page-header pt-4 pb-1"></Row>
      <Row noGutters className="page-header py-1">
        <Col
          md="12"
          lg="10"
          className="mx-auto mt-4 score-logic relative h-full"
        >
          <Wizard
            render={({ step }) => (
              <>
                <Heading
                  titles={titles}
                  computationCodes={computationCodes}
                  scoringVariables={scoringVariables}
                  uid={uid}
                />
                <div className={`flex bg-white p-2 ${classes}`}>
                  {uid && <Navigation titles={titles} step={step} />}
                  <TransitionGroup className="score-logic-setup-wrapper h-full">
                    <CSSTransition
                      key={step.id}
                      className="score-logic-setup"
                      timeout={{ enter: 500, exit: 0 }}
                    >
                      <div className="score-logic-setup-steps fluid">
                        {(hasAuthority("FA_ANALYST") ||
                          hasAuthority("FA_ADMIN")) && (
                          <Steps step={step}>
                            <Step
                              id="questionnaire"
                              render={() => (
                                <div className="content">
                                  <SetQuestionnaire
                                    setSelectedMfiId={setSelectedMfiId}
                                    desc={t(
                                      "module.scoreLogic.view.scoreLogic.titles.questionnaireSubtitle"
                                    )}
                                  />
                                </div>
                              )}
                            />

                            <Step
                              id="computationCodes"
                              render={() => (
                                <div className="content">
                                  <ComputationCodes />
                                </div>
                              )}
                            />

                            <Step
                              id="assumptions"
                              render={() => (
                                <div className="content">
                                  <Assumptions />
                                </div>
                              )}
                            />

                            <Step
                              id="projectionPeriod"
                              render={() => (
                                <div className="content">
                                  <ManageProjectionPeriod />
                                </div>
                              )}
                            />

                            <Step
                              id="tdv"
                              render={() => (
                                <div className="content">
                                  <TimeDependent />
                                </div>
                              )}
                            />

                            <Step
                              id="tdcv"
                              render={() => (
                                <div className="content">
                                  <TimeDependentCalculated />
                                </div>
                              )}
                            />

                            <Step
                              id="emcv"
                              render={() => (
                                <div className="content">
                                  <EveryMonthCalculated />
                                </div>
                              )}
                            />

                            <Step
                              id="cashflow"
                              render={() => (
                                <div className="content">
                                  <CashflowStatements />
                                </div>
                              )}
                            />

                            <Step
                              id="otherCalculations"
                              render={() => (
                                <div className="content">
                                  <SingleCalculations />
                                </div>
                              )}
                            />

                            <Step
                              id="sv"
                              render={() => (
                                <div className="content">
                                  <ScoringVariables />
                                </div>
                              )}
                            />

                            <Step
                              id="scoreCard"
                              render={() => (
                                <div className="content">
                                  <ScoreCard />
                                </div>
                              )}
                            />

                            <Step
                              id="approvalWorkflow"
                              render={() => (
                                <div className="content">
                                  <ApprovalWorkflow
                                    selectedMfiId={selectedMfiId}
                                  />
                                </div>
                              )}
                            />

                            <Step
                              id="documentChecklist"
                              render={() => (
                                <div className="content">
                                  <DocumentChecklist />
                                </div>
                              )}
                            />

                            <Step
                              id="scoreLogicTest"
                              render={() => (
                                <div className="content">
                                  <ScoreLogicTest />
                                </div>
                              )}
                            />
                          </Steps>
                        )}
                        {hasAuthority("MFI_ADMIN") && (
                          <Steps step={step}>
                            <Step
                              id="questionnaire"
                              render={() => (
                                <div className="content">
                                  <SetQuestionnaire
                                    desc={t(
                                      "module.scoreLogic.view.scoreLogic.titles.questionnaireSubtitle"
                                    )}
                                  />
                                </div>
                              )}
                            />

                            <Step
                              id="sv"
                              render={() => (
                                <div className="content">
                                  <ScoringVariables />
                                </div>
                              )}
                            />

                            <Step
                              id="scoreCard"
                              render={() => (
                                <div className="content">
                                  <ScoreCard />
                                </div>
                              )}
                            />
                          </Steps>
                        )}
                      </div>
                    </CSSTransition>
                  </TransitionGroup>
                </div>
                <WizardActions titles={titles} step={step} />
              </>
            )}
          />
        </Col>
      </Row>
    </Container>
  )
}

function mapStateToProps(state) {
  const { scoreLogic, kobotoolkit } = state
  return { scoreLogic, kobotoolkit }
}

const connectedScoreLogic = withTranslation()(
  connect(mapStateToProps)(ScoreLogic)
)
export { connectedScoreLogic as ScoreLogic }

import React, { useState, useRef, useEffect, useMemo } from "react"
import PersonOutlineOutlinedIcon from "@material-ui/icons/LockOutlined"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import countryList from "react-select-country-list"
import {
  FormGroup,
  FormInput,
  ButtonGroup,
  Button,
  FormSelect,
  FormCheckbox,
} from "shards-react"
import Header from "../../../components/widget/widgetTitle"
import { templates } from "../../../utils/templates"
import { modalActions } from "../../modals"
import { alertActions } from "../../alert"
import { mfiManagerActions } from ".."
import {
  menuPanel,
  mpesaPanel,
  imagePanel,
} from "../components/customization/Constants"
import { toolsActions } from "../../tools"

export const ManageCustomization = ({ modalID, data }) => {
  const { mfiId, edit, panel, raster, hazardData } = data
  const dispatch = useDispatch()
  const options = useMemo(() => countryList().getData(), [])

  const fileInputEl = useRef(null)

  const { t } = useTranslation()
  let header = ""
  if (panel === menuPanel) {
    header = t("module.mfiSetup.addCustomization.title")
  } else if (panel === mpesaPanel) {
    header = t("module.mfiSetup.addCustomization.mpesaTitle")
  } else {
    header = t("module.mfiSetup.addCustomization.rasterTitle")
  }

  const supportedFilesTypes = ["image/png", "image/jpeg", "image/tiff"]

  const initialInput = !hazardData
    ? {
        title: "",
        description: "",
        enabled: true,
        componentName: panel === menuPanel ? "" : undefined,
        country: raster ? "" : undefined,
        ...data,
      }
    : { ...hazardData }

  const [file, setFile] = useState({})
  const [inputs, setInputs] = useState(initialInput)
  const [isValidLength, setIsValidLength] = useState(false)

  useEffect(() => {
    if (inputs.title.length > 4) {
      setIsValidLength(true)
    } else {
      setIsValidLength(false)
    }
  }, [inputs])

  const handleChange = async (e) => {
    const { name, value } = e.target
    setInputs((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleCheckBoxChange = () => {
    setInputs((prevState) => ({ ...prevState, enabled: !prevState.enabled }))
  }

  const titleName = panel === mpesaPanel ? "url" : "title"
  const { name } = file

  const addDetails = () => {
    const payload = {
      ...inputs,
      [titleName]: inputs.title,
    }
    payload["mfiId"] = mfiId
    if (raster) {
      const data = {
        country: payload.country,
        title: payload.title,
        submission: file,
      }
      dispatch(toolsActions.addRasterFile(data))
    } else if (panel === menuPanel) {
      dispatch(mfiManagerActions.addCustomMenu(payload))
    } else if (panel === mpesaPanel) {
      dispatch(mfiManagerActions.addMpesaDetails(payload))
    } else if (panel === imagePanel) {
      payload.submission = file
      dispatch(mfiManagerActions.addMfiImage(payload))
    }
    closeModal()
  }

  const editDetails = () => {
    const payload = {
      ...inputs,
    }

    const { title, description, id, enabled, componentName, mfiId } = payload
    // remove edit
    let newPayload = {
      id,
      [titleName]: title,
      description,
      enabled,
      componentName,
      mfiId,
    }
    if (panel === menuPanel) {
      dispatch(mfiManagerActions.editCustomMenus(newPayload))
    } else if (panel === mpesaPanel) {
      dispatch(mfiManagerActions.editMpesaDetails(newPayload))
    } else if (!!hazardData) {
      dispatch(toolsActions.editHazardData(inputs))
    }
    closeModal()
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  const isValid = (input) => {
    if (!input) {
      return false
    }
    const { type } = input
    if (supportedFilesTypes.includes(type)) {
      return true
    }
    return false
  }

  const handleFileSelect = (event) => {
    const {
      target: { files },
    } = event
    event.preventDefault()
    const [tempFile] = files
    if (isValid(tempFile)) {
      return setFile(tempFile)
    }
    dispatch(
      alertActions.error(t("module.mfiSetup.addCustomization.uploadImageFile"))
    )
    return false
  }

  return (
    <div className="site-modal">
      <Header
        title={header}
        icon={<PersonOutlineOutlinedIcon />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10 select-file">
        {raster && (
          <FormGroup>
            <label htmlFor="componentName">Country</label>
            <FormSelect
              name="country"
              className="mb-2"
              onChange={handleChange}
              defaultValue={inputs.componentName}
            >
              <option name="">Select Country</option>
              {options &&
                options.map((item, index) => {
                  return (
                    <option key={index} name={item.name}>
                      {item.label}
                    </option>
                  )
                })}
            </FormSelect>
          </FormGroup>
        )}
        <FormGroup>
          <label htmlFor="title">{raster ? "Short Title" : panel}</label>
          <FormInput
            id="title"
            defaultValue={inputs.title}
            name="title"
            size="md"
            className="mb-2"
            onChange={handleChange}
          />
          {!isValidLength && panel === imagePanel && !raster && (
            <small id="descriptionError" className="form-text text-danger">
              {t("module.mfiSetup.addQuestionnaire.minimumLength")}.
            </small>
          )}
        </FormGroup>
        {!!hazardData && (
          <>
            <FormGroup>
              <label htmlFor="longTitle">Long Title</label>
              <FormInput
                id="longTitle"
                defaultValue={inputs.long_title}
                name="long_title"
                size="md"
                className="mb-2"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="minValue">Min Value</label>
              <FormInput
                id="minValue"
                type="number"
                min="0"
                defaultValue={inputs.min_value}
                name="min_value"
                size="md"
                className="mb-2"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="maxValue">Max Value</label>
              <FormInput
                id="maxValue"
                type="number"
                min="0"
                defaultValue={inputs.max_value}
                name="max_value"
                size="md"
                className="mb-2"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="increment">Increment</label>
              <FormInput
                id="increment"
                type="number"
                min="0"
                defaultValue={inputs.increment}
                name="increment"
                size="md"
                className="mb-2"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="stressLow">Low Threshold</label>
              <FormInput
                id="stressLow"
                type="number"
                min="0"
                defaultValue={inputs.stress_low}
                name="stress_low"
                size="md"
                className="mb-2"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="stressMedium">Medium Threshold</label>
              <FormInput
                id="stressMedium"
                type="number"
                min="0"
                defaultValue={inputs.stress_medium}
                name="stress_medium"
                size="md"
                className="mb-2"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="tooltip">Tooltip</label>
              <FormInput
                id="tooltip"
                defaultValue={inputs.tooltip}
                name="tooltip"
                size="lg"
                className="mb-2"
                onChange={handleChange}
              />
            </FormGroup>
          </>
        )}
        {panel !== imagePanel && (
          <FormGroup>
            <label htmlFor="description">Description</label>
            <FormInput
              defaultValue={inputs.description}
              name="description"
              size="md"
              className="mb-2"
              onChange={handleChange}
            />
          </FormGroup>
        )}
        {panel !== imagePanel && (
          <FormGroup>
            <FormCheckbox
              checked={inputs.enabled}
              onChange={handleCheckBoxChange}
            >
              {panel === menuPanel && "Enabled"}
              {panel === mpesaPanel && "Include Mpesa"}
            </FormCheckbox>
          </FormGroup>
        )}
        {panel === menuPanel && (
          <FormGroup>
            <label htmlFor="componentName">Template</label>
            <FormSelect
              name="componentName"
              className="mb-2"
              onChange={handleChange}
              defaultValue={inputs.componentName}
            >
              <option name="">Select template</option>
              {templates &&
                templates.map((item, index) => {
                  return (
                    <option key={index} name={item.name}>
                      {item.label}
                    </option>
                  )
                })}
            </FormSelect>
          </FormGroup>
        )}
        {panel === imagePanel && !hazardData && (
          <FormGroup>
            <input
              className="border p-1 file-upload"
              type="file"
              ref={fileInputEl}
              name="importFile"
              onChange={handleFileSelect}
              accept={supportedFilesTypes.join(",")}
            />
            <label htmlFor="file-import" className="m-0 flex items-center">
              <span>{name || t("module.tools.import.chooseImageFile")}</span>
              <strong
                className="cursor-pointer"
                onClick={() => fileInputEl.current.click()}
              >
                {t("module.tools.import.uploadImage")}
              </strong>
            </label>
          </FormGroup>
        )}
        <ButtonGroup className="btn-group-actions site-modal__actions">
          <Button
            theme="primary"
            className="thin mr-2"
            onClick={() => {
              edit || !!hazardData ? editDetails() : addDetails()
            }}
          >
            {edit || !!hazardData ? "Edit" : "Save"}
          </Button>
          <Button
            theme="secondary"
            className="thin delete"
            onClick={() => {
              closeModal()
            }}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import PageTitle from '../../../components/common/PageTitle';
import { authenticationActions } from '../../authentication';
import { mfiManagerActions } from '../../mfiManager';
import { pendingTasksActions } from './pendingTasks/store/_actions';
import { EmptyContentView, LoadingView } from '../../../views';
import { ClientErrorsView } from '../components/ClientError';
import { PendingTasksList } from './pendingTasks/pendingTasksList';
import { useTranslation } from 'react-i18next';

const MyPendingTasks = () => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.authentication);
    const pendingTasksResponse = useSelector((state) => state.pendingTasks);
    const { profile: { mfi }} = user;
    const dispatch = useDispatch();
    const { BMPendingTasks, loading, error, BMPendingTasksError } = pendingTasksResponse
    const [state, setState] = useState({
        page: BMPendingTasks?.number || 0,
        perPage: 20
    });

    useEffect(() => {
        dispatch(authenticationActions.getProfile());
    }, [dispatch]);

    useEffect(() => {
        dispatch(mfiManagerActions.getAllMFIs());
    }, [dispatch]);

    useEffect(() => {
        mfi?.id !== undefined && dispatch(pendingTasksActions.getBMAllPendingTasks((mfi?.id), state?.perPage, state?.page, ));
    }, [dispatch, mfi, state]);

    const pendingTasksData = BMPendingTasks?.content?.map((pendingTask) => ({ ...{ action: '' }, ...pendingTask, ...{ '': '' } }));

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header pt-4 flex">
                <PageTitle sm="4" title={t('module.client.pendingTask.title')} className="text-sm-left" />
            </Row>
            <Row className="mt-4 clientList">
                <Col>
                    { loading && <LoadingView /> }
                    { error && BMPendingTasksError !== null && <ClientErrorsView errorData={BMPendingTasksError?.response?.data} /> }
                    { !loading && !error && isEmpty(BMPendingTasks?.content) && <EmptyContentView title={t('module.client.pendingTask.noPendingTasks')} /> }
                    {!loading &&
                        <>
                            <PendingTasksList
                                mfiId={mfi?.id}
                                data={pendingTasksData}
                                setState={setState}
                                state={state}
                                paginationData={BMPendingTasks}
                            />
                        </>
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default MyPendingTasks;

import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import {
  Grid,
  Paper,
  Typography,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core"
import cx from "classnames"
import Svg from "react-inlinesvg"
import { ApplicationDetailsActions } from "../../applicationManager"
import { creditScoreActions } from "../_actions"
import CashFlowChart from "./Chart"
import NetCashFlowChart from "./NetCashFlowChart"
import styles from "./CashFlowAnalysis.styles"
import { useTranslation } from "react-i18next"

const billSVG = require("../../../assets/images/icons/money-bills.svg")
const calendarSVG = require("../../../assets/images/icons/calendar-icon.svg")
const handSVG = require("../../../assets/images/icons/hand-icon.svg")
const clockSVG = require("../../../assets/images/icons/clock-icon.svg")
const houseSVG = require("../../../assets/images/icons/house-icon.svg")
const leafSVG = require("../../../assets/images/icons/leaf-icon.svg")

export const CashflowAnalysis = withStyles(styles)(
  ({ applicationId, classes }) => {
    const cashFlow = useSelector((state) => {
      const {
        creditScore: { cashFlow: data },
      } = state
      return data
    })
    const report = useSelector((state) => {
      const {
        creditScore: { report: data },
      } = state

      return data
    })

    const { t, i18n } = useTranslation()
    const formatNumber1 = (num) => {
      if (
        typeof num == "string" &&
        num.charAt(0) === '"' &&
        num.charAt(num.length - 1) === '"' &&
        num != undefined
      ) {
        num = num.slice(1, -1)
      }
      return num
    }

    const farmCashFlow = cashFlow?.filter((cf) =>
      cf.label.trim().toLowerCase().includes("farm")
    )
    const businessCashFlow = cashFlow?.filter((cf) =>
      cf.label.trim().toLowerCase().includes("business")
    )

    const householdCashFlow = cashFlow?.filter(
      (cf) =>
        cf.label.trim().toLowerCase().includes("monthly household") ||
        cf.label.trim().toLowerCase().includes("monthly_household") ||
        cf.label.trim().toLowerCase().includes("hh_")
    )
    const OutstandingLoanAmount = cashFlow?.find(
      (cf) =>
        cf.label.trim().toLowerCase().includes("outstanding") ||
        cf.label.trim().toLowerCase().includes("outstanding_loan")
    )?.value
    const LoanAmount = report?.find(
      (cf) =>
        cf.label.trim().toLowerCase().includes("amount") ||
        cf.label.trim().toLowerCase().includes("loan_amount")
    )?.value

    const tenor = report?.find(
      (cf) =>
        cf.label.trim().toLowerCase().includes("tenor") ||
        cf.label.trim().toLowerCase().includes("loan_tenor")
    )?.value

    const loantenor = tenor ? tenor.replace(/"/g, "") : 0

    let ColleteraltoBorrowed

    let assetLabel = "asset"
    let collateralLabel = "collateral"

    let AssetValue = report?.find((cf) =>
      cf.label.toLowerCase().includes(assetLabel)
    )?.value

    if (typeof AssetValue !== "undefined") {
      AssetValue = AssetValue.split("")
        .filter((item) => item !== '"')
        .join("")
      ColleteraltoBorrowed = Math.round(
        (formatNumber1(AssetValue) / formatNumber1(LoanAmount)) * 100
      )
    } else {
      let collateralValue = report?.find((cf) =>
        cf.label.toLowerCase().includes(collateralLabel)
      )?.value

      if (typeof collateralValue !== "undefined") {
        collateralValue = collateralValue
          .split("")
          .filter((item) => item !== '"')
          .join("")
        if (isNaN(collateralValue)) {
          ColleteraltoBorrowed = 0
        } else {
          ColleteraltoBorrowed = collateralValue
        }
      }
    }

    const {
      profile: { mfi },
    } = useSelector((store) => store.authentication)
    let { currency } = mfi || {}

    let OutstandingLoanAmountvalue = 0
    if (OutstandingLoanAmount && OutstandingLoanAmount.length > 0) {
      OutstandingLoanAmountvalue = OutstandingLoanAmount[0]
    }
    const MonthlyHouseholdIncome = cashFlow?.find(
      (cf) =>
        cf.label.trim().toLowerCase().includes("household income") ||
        cf.label.trim().toLowerCase().includes("monthly_household_income") ||
        cf.label.trim().toLowerCase().includes("hh_inc_monthly")
    )?.value

    let MonthlyHouseholdIncomevalue = 0
    if (MonthlyHouseholdIncome && MonthlyHouseholdIncome.length > 0) {
      MonthlyHouseholdIncomevalue = MonthlyHouseholdIncome[0]
    }
    const MonthlyHouseholdExpense = cashFlow?.find(
      (cf) =>
        cf.label.toLowerCase().includes("household expen") ||
        cf.label.toLowerCase().includes("monthly_household_expen") ||
        cf.label.toLowerCase().includes("hh_exp_monthly")
    )?.value

    let MonthlyHouseholdExpensevalue = 0
    if (MonthlyHouseholdExpense && MonthlyHouseholdExpense.length > 0) {
      MonthlyHouseholdExpensevalue = MonthlyHouseholdExpense[0]
    }

    const MonthlyFarmExpense = cashFlow?.find(
      (cf) =>
        cf.label.toLowerCase().includes("farm expen") ||
        cf.label.toLowerCase().includes("monthly_farm_expen") ||
        cf.label.toLowerCase().includes("farm_exp_monthly")
    )?.value

    const MonthlyBusinessExpense = cashFlow?.find(
      (cf) =>
        cf.label.toLowerCase().includes("business expen") ||
        cf.label.toLowerCase().includes("monthly_business_expen") ||
        cf.label.toLowerCase().includes("business_exp_monthly")
    )?.value

    let MonthlyActivityExpensevalue = 0
    if (MonthlyFarmExpense && MonthlyFarmExpense.length > 0) {
      MonthlyActivityExpensevalue = MonthlyFarmExpense[0]
    } else if (MonthlyBusinessExpense && MonthlyBusinessExpense.length > 0) {
      MonthlyActivityExpensevalue = MonthlyBusinessExpense[0]
    }

    const MonthlyFarmIncome = cashFlow?.find(
      (cf) =>
        cf.label.toLowerCase().includes("farm income") ||
        cf.label.toLowerCase().includes("monthly_farm_income") ||
        cf.label.toLowerCase().includes("farm_inc_monthly")
    )?.value

    const MonthlyBusinessIncome = cashFlow?.find(
      (cf) =>
        cf.label.toLowerCase().includes("business income") ||
        cf.label.toLowerCase().includes("monthly_business_income") ||
        cf.label.toLowerCase().includes("business_inc_monthly")
    )?.value

    let MonthlyActivityIncomevalue = 0
    if (MonthlyFarmIncome && MonthlyFarmIncome.length > 0) {
      MonthlyActivityIncomevalue =
        Math.round(parseFloat(MonthlyFarmIncome[0]) * 100) / 100
    } else if (MonthlyBusinessIncome && MonthlyBusinessIncome.length > 0) {
      MonthlyActivityIncomevalue =
        Math.round(parseFloat(MonthlyBusinessIncome[0]) * 100) / 100
    }

    const { cashFlowLoading } = useSelector((state) => state.creditScore)
    const dispatch = useDispatch()

    useEffect(() => {
      dispatch(creditScoreActions.getCashFlowAnalysis(applicationId))
    }, [dispatch, applicationId])

    useEffect(() => {
      dispatch(creditScoreActions.getCreditScoreReport(applicationId))
    }, [dispatch, applicationId])

    let cashFlowTitle = ""
    let typeHolder = ""
    let typeHolder1 = ""
    let typeHolder2 = ""
    let typeHolder3 = t("component.cashflow_analisis.monthly_household_income")
    let typeHolder4 = t(
      "component.cashflow_analisis.Monthly_Household_Expenses"
    )
    let typeHolder5 = t(
      "component.cashflow_analisis.Monthly_Household_CashFlow"
    )
    let typeHolder6 = t("component.cashflow_analisis.Net_Monthly_CashFlow")

    if (
      farmCashFlow?.length > 1 &&
      MonthlyActivityExpensevalue + MonthlyActivityIncomevalue !== 0
    ) {
      cashFlowTitle = t("component.cashflow_analisis.monthly_farm_cashFlow")
      typeHolder = "Monthly farm"
      typeHolder1 = t("component.cashflow_analisis.monthly_farm_income")
      typeHolder2 = t("component.cashflow_analisis.monthly_farm_expense")
    } else if (
      businessCashFlow?.length > 1 &&
      MonthlyActivityExpensevalue !== 0 &&
      MonthlyActivityIncomevalue !== 0
    ) {
      cashFlowTitle = t("component.cashflow_analisis.monthly_Business_cashFlow")
      typeHolder = "Monthly business"
      typeHolder1 = t("component.cashflow_analisis.Monthly_business_income")
      typeHolder2 = t("component.cashflow_analisis.monthly_business_expense")
    } else {
      cashFlowTitle = t(
        "component.cashflow_analisis.Monthly_Cash_Flow_Not_Available"
      )
      typeHolder = "Monthly"
      typeHolder1 = t("component.cashflow_analisis.monthly_income")
      typeHolder2 = t("component.cashflow_analisis.monthly_expense")
    }

    const formatNumber = (num) => {
      if (typeof num == "number") {
        num = num.toString()
      }

      if (num == undefined) {
        return "0"
      }

      if (num.charAt(0) === '"' && num.charAt(num.length - 1) === '"') {
        num = num.slice(1, -1)
      }
      const floatNum = Math.round(parseFloat(num))

      if (i18n.language === "en") {
        return floatNum.toLocaleString()
      } else if (i18n.language === "fr") {
        return floatNum.toLocaleString("fr-FR")
      } else {
        return floatNum.toLocaleString()
      }
    }

    return (
      <div className="cashflow-analysis" style={{ backgroundColor: "#f1f8ff" }}>
        <h4 className={classes.cashflowtitle}>
          {t("component.cashflow_analisis.section.loan_details")}
        </h4>
        <Grid container spacing={3} className={classes.cardcontainer}>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.cashCard}>
              <CardHeader
                title={
                  <Typography variant="h7" className="text-black">
                    {t("component.cashflow_analisis.loan_amount")}
                  </Typography>
                }
                className={classes.cardHeader}
              />
              <CardContent className={classes.cardcontent}>
                <Typography
                  variant="subtitle1"
                  className={classes.cashsubtitle1}
                >
                  <sup className={classes.cardsup}>{currency}</sup>
                  {formatNumber(LoanAmount)}
                </Typography>
                <Svg className={classes.svgitem} src={billSVG} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.cashCard}>
              <CardHeader
                title={
                  <Typography variant="h7" className="text-black">
                    {t("component.cashflow_analisis.loan_tenor")}
                  </Typography>
                }
                className={classes.cardHeader}
              />
              <CardContent className={classes.cardcontent}>
                <Typography
                  variant="subtitle1"
                  className={classes.cashsubtitle1}
                >
                  {loantenor} Months
                </Typography>
                <Svg className={classes.svgitem} src={calendarSVG} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.cashCard}>
              <CardHeader
                title={
                  <Typography variant="h7" className="text-black">
                    {t("component.cashflow_analisis.colleteral")}
                  </Typography>
                }
                className={classes.cardHeader}
              />
              <CardContent className={classes.cardcontent}>
                <Typography
                  variant="subtitle1"
                  className={classes.cashsubtitle1}
                >
                  {ColleteraltoBorrowed}%
                </Typography>
                <Svg className={classes.svgitem} src={handSVG} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.cashCard}>
              <CardHeader
                title={
                  <Typography variant="h7" className="text-black">
                    {t("component.cashflow_analisis.Outstanding")}
                  </Typography>
                }
                className={classes.cardHeader}
              />
              <CardContent className={classes.cardcontent}>
                <Typography
                  variant="subtitle1"
                  className={classes.cashsubtitle1}
                >
                  <sup className={classes.cardsup}>{currency}</sup>
                  {formatNumber(OutstandingLoanAmountvalue)}
                </Typography>
                <Svg className={classes.svgitem} src={clockSVG} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <h4 className={classes.cashflowtitle}>
          {t("component.cashflow_analisis.section.cash_flow_summary")}
        </h4>
        <Grid container spacing={3} className={classes.cardcontainer}>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.cashCard}>
              <CardHeader
                title={
                  <Typography variant="h7" className="text-black">
                    {typeHolder1}
                  </Typography>
                }
                className={classes.cardHeader}
              />
              <CardContent className={classes.cardcontent}>
                <Typography
                  variant="subtitle1"
                  className={classes.cashsubtitle1}
                >
                  <sup className={classes.cardsup}>
                    <span className={classes.arrowgreen}>&#9650;</span>
                    {currency}
                  </sup>
                  {formatNumber(MonthlyActivityIncomevalue)}
                </Typography>
                <Svg className={classes.svgitem} src={leafSVG} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.cashCard}>
              <CardHeader
                title={
                  <Typography variant="h7" className="text-black">
                    {typeHolder2}
                  </Typography>
                }
                className={classes.cardHeader}
              />
              <CardContent className={classes.cardcontent}>
                <Typography
                  variant="subtitle1"
                  className={classes.cashsubtitle1}
                >
                  <sup className={classes.cardsup}>
                    <span className={classes.arrowred}>&#9660;</span>
                    {currency}
                  </sup>
                  {formatNumber(MonthlyActivityExpensevalue)}
                </Typography>
                <Svg className={classes.svgitem} src={leafSVG} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.cashCard}>
              <CardHeader
                title={
                  <Typography variant="h7" className="text-black">
                    {typeHolder3}
                  </Typography>
                }
                className={classes.cardHeader}
              />
              <CardContent className={classes.cardcontent}>
                <Typography
                  variant="subtitle1"
                  className={classes.cashsubtitle1}
                >
                  <sup className={classes.cardsup}>
                    <span className={classes.arrowgreen}>&#9650;</span>
                    {currency}
                  </sup>
                  {formatNumber(MonthlyHouseholdIncomevalue)}
                </Typography>
                <Svg className={classes.svgitem} src={houseSVG} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.cashCard}>
              <CardHeader
                title={<Typography variant="h7">{typeHolder4}</Typography>}
                className={classes.cardHeader}
              />
              <CardContent className={classes.cardcontent}>
                <Typography
                  variant="subtitle1"
                  className={classes.cashsubtitle1}
                >
                  <sup className={classes.cardsup}>
                    <span class={classes.arrowred}>&#9660;</span>
                    {currency}
                  </sup>
                  {formatNumber(MonthlyHouseholdExpensevalue)}
                </Typography>
                <Svg className={classes.svgitem} src={houseSVG} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container className={classes.gridContainer}>
          <Grid item sm={6} xs={12} className={classes.gridPie}>
            <Paper className={cx(classes.paperContainer, classes.paperHeight)}>
              <Typography className={classes.headerText}>
                {cashFlowTitle}
              </Typography>
              <CashFlowChart
                cashFlow={
                  farmCashFlow?.length > 1 ? farmCashFlow : businessCashFlow
                }
                loading={cashFlowLoading}
                type={typeHolder}
                className={classes.smallChartContainer}
              />
            </Paper>
          </Grid>
          <Grid item sm={6} xs={12} className={classes.gridPie}>
            <Paper className={cx(classes.paperContainer, classes.paperHeight)}>
              <Typography className={classes.headerText}>
                {typeHolder5}
              </Typography>
              <CashFlowChart
                cashFlow={householdCashFlow}
                loading={cashFlowLoading}
                type="Monthly household"
                className={classes.smallChartContainer}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} className={classes.gridBar}>
            <Paper className={classes.paperContainer}>
              <Typography className={classes.headerText}>
                {typeHolder6}
              </Typography>
              <NetCashFlowChart
                cashFlow={cashFlow}
                report={report}
                loading={cashFlowLoading}
                type="Net"
              />
            </Paper>
          </Grid>
        </Grid>
        <ApplicationDetailsActions id={applicationId} saveDisabled={true} />
      </div>
    )
  }
)

import React from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { connect } from 'react-redux';
import {
    ButtonGroup,
    Button,
} from 'shards-react';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/widget/widgetTitle';
import { cslActions } from '..';
import { modalActions } from '../../modals';

const DeleteCashflowVariable = ({ data, modalID, dispatch }) => {
    const {codes} = data;
    const { t } = useTranslation();

    // eslint-disable-next-line
    const _delete = () => {
        dispatch(cslActions.deleteCashflowStatementsVariables({ ...data, codes }));
        closeModal();
    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal w-5">
            <Header title={t('module.scoreLogic.modal.deleteCashflowVariable.headerTitle')} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal } />
            <div className="p-10">
                <p>{t('module.scoreLogic.modal.deleteCashflowVariable.deleteWarning')}</p>
                <ButtonGroup className="btn-group-actions site-modal__actions">
                    <Button theme="primary" className="thin delete mr-2" onClick={ () => { _delete(); } } >{t('module.scoreLogic.modal.deleteCashflowVariable.yes')}</Button>
                    <Button theme="secondary" className="thin" onClick={ () => { closeModal(); } }>{t('module.scoreLogic.modal.deleteCashflowVariable.cancel')}</Button>
                </ButtonGroup>
            </div>
        </div>
    );
};

const connectedDeleteCashflowVariable = connect(null)(DeleteCashflowVariable);
export { connectedDeleteCashflowVariable as DeleteCashflowVariable };

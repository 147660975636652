import React from 'react';
import { Container, Button } from 'shards-react';
import { withTranslation } from 'react-i18next';

const UnauthorisedView = ( { t, i18n } ) => (
    <Container fluid className="main-content-container px-4 pb-4">
        <div className="error">
            <div className="error__content">
                <h2>{t("view.unauthorised.code")}</h2>
                <h3>{t("view.unauthorised.title")}</h3>
                <p>{t("view.unauthorised.text")}</p>
                <Button pill onClick={() => { window.location.href = '/'; }}>&larr; {t("view.unauthorised.button")}</Button>
            </div>
        </div>
    </Container>
);

const translatedUnauthorisedView = withTranslation()(UnauthorisedView);
export { translatedUnauthorisedView as UnauthorisedView };

import { userManagerConstants } from "./_constants";

export const registration = (state = {}, action) => {
    switch (action.type) {
    case userManagerConstants.REGISTER_REQUEST:
        return { registering: true };
    case userManagerConstants.REGISTER_SUCCESS:
        return {};
    case userManagerConstants.REGISTER_FAILURE:
        return {};
    default:
        return state;
    }
};

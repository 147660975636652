import React, { useEffect, useState, useRef } from "react"
import ReactDOMServer from "react-dom/server"
import { SvgIcon, Typography, withStyles } from "@material-ui/core"
import RoomIcon from "@material-ui/icons/Room"
import L from "leaflet"
import "leaflet/dist/leaflet.css"
import styles from "./ClimateProfile.styles"

const HazardPlotMap = ({
  latitude,
  longitude,
  plotMap,
  country,
  hazard_list,
  classes,
}) => {
  const CENTER_LATITUDE = !country ? 0 : -12.870223315
  const CENTER_LONGITUDE = !country ? 37.899 : 27.588040151
  const ZOOM_LEVEL = 6
  const SOUTH_LAT = !country ? -4.899 : -16.737267788
  const SOUTH_LONG = !country ? 33.899 : 19.998035795
  const NORTH_LAT = !country ? 4.899 : -9.003178842
  const NORTH_LONG = !country ? 41.899 : 35.178044507

  const [map, setMap] = useState(null)
  const [imageOverlayInstance, setImageOverlayInstance] = useState(null)
  const mapContainerRef = useRef(null)

  useEffect(() => {
    if (!mapContainerRef.current) return
    // Create the map without displaying it
    const map = L.map(mapContainerRef.current, {
      center: [CENTER_LATITUDE, CENTER_LONGITUDE],
      zoom: ZOOM_LEVEL,
      zoomControl: false, // Hide the zoom control
      attributionControl: false, // Hide the attribution control
      scrollWheelZoom: false, // Disable zoom on scroll
    })
    setMap(map)
  }, [])

  useEffect(() => {
    // Check if marker coordinates are provided
    if (latitude && longitude && map && plotMap) {
      // Create a custom divIcon for the marker
      const MarkerIcon = L.divIcon({
        className: "bg-transparent border-none",
        html: ReactDOMServer.renderToString(
          <SvgIcon
            component={RoomIcon}
            style={{ fontSize: 32, color: "blue" }}
          />
        ),
        iconSize: [32, 32], // Set the icon size (width and height)
        iconAnchor: [16, 32], // Set the icon anchor point (center of the bottom edge)
      })
      // Add marker to specific coordinates
      L.marker([latitude, longitude], { icon: MarkerIcon }).addTo(map)
    }
  }, [map, plotMap])

  useEffect(() => {
    if (plotMap && map) {
      // Remove the previous image overlay, if it exists
      if (imageOverlayInstance) {
        imageOverlayInstance.remove()
      }
      // Add the image overlay
      const newImageOverlayInstance = L.imageOverlay(plotMap, [
        [SOUTH_LAT, SOUTH_LONG],
        [NORTH_LAT, NORTH_LONG],
      ]).addTo(map)
      setImageOverlayInstance(newImageOverlayInstance)
    }
  }, [plotMap, map])

  if (hazard_list.length === 0) {
    return (
      <Typography className={classes.notAvailableText} variant="h5">
        Climate Risk Profile Plot Map Not Available. Possible Reason is GPS
        coordinates below not from country of focus.
      </Typography>
    )
  }

  return (
    <div
      ref={mapContainerRef}
      style={{
        width: "100%",
        height: "485px",
        position: "relative",
        background: "transparent",
      }}
    />
  )
}

export default withStyles(styles)(HazardPlotMap)

import React from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { connect } from 'react-redux';
import {
    ButtonGroup,
    Button,
} from 'shards-react';
import Header from '../../../components/widget/widgetTitle';
import { configActions } from '..';
import { modalActions } from '../../modals';

const DeleteCreditScoreReportSetup = ({ data, modalID, dispatch }) => {
    const { id } = data;

    // eslint-disable-next-line no-underscore-dangle
    const _delete = () => {
        dispatch(configActions.deleteCreditScoreReportSetup(id));
        closeModal();
    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal w-5">
            <Header title="Delete Checklist" icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal } />
            <div className="p-10">
                <p>Are you sure you want to delete the selected Credit Score Set up?</p>
                <ButtonGroup className="btn-group-actions site-modal__actions">
                    <Button theme="primary" className="thin delete mr-2" onClick={ () => { _delete(); } } >Yes</Button>
                    <Button theme="secondary" className="thin" onClick={ () => { closeModal(); } }>Cancel</Button>
                </ButtonGroup>
            </div>
        </div>
    );
};

const connectedDeleteCreditScoreReportSetup = connect(null)(DeleteCreditScoreReportSetup);
export { connectedDeleteCreditScoreReportSetup as DeleteCreditScoreReportSetup };

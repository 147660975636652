import { axiosInstance, handleResponse } from "../../_helpers/base.service"

export const commentsService = {
  addComment,
  getAll,
}
const serviceGroup = "workflow"

function addComment(id, note, base64Image) {
  const requestConfig = {
    applicationId: `${id}`,
    note: `${note}`,
    base64Image: `${base64Image}`,
  }

  return axiosInstance
    .post(`${serviceGroup}/application-comments/`, requestConfig)
    .then(handleResponse)
}

function getAll(id) {
  const requestConfig = {
    params: {
      applicationId: `${id}`,
    },
  }

  return axiosInstance
    .get(`${serviceGroup}/application-comments/`, requestConfig)
    .then(handleResponse)
}

import React, { Component } from "react"
import { UnauthorisedView } from "./views"
import { DefaultLayout } from "./layouts"

const Authenticate = (WrappedComponent, options = {}) => {
  // eslint-disable-next-line
  const HOC = class extends Component {
    render() {
      const { roles } = options
      const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : false
      const { authorities } = user

      if (authorities) {
        const newAuthorities = [...authorities]
        newAuthorities.push("PUBLIC")
        if (
          !newAuthorities.some((authority) => roles.indexOf(authority) >= 0)
        ) {
          return (
            <DefaultLayout>
              <UnauthorisedView />
            </DefaultLayout>
          )
        }
      }
      return <WrappedComponent {...this.props} />
    }
  }

  return HOC
}

export default Authenticate

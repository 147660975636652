import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Alert.scss';

import { alertActions, alertConstants } from '../index';

const Alert = ({ alerts, dispatch }) => (
    <div className="alerts-wrapper">
        {
            alerts && alerts.map((alert) => {
                const { id, type, message } = alert;
                const options = {
                    toastId: id,
                    autoClose: 3200,
                    draggable: false,
                    hideProgressBar: true,
                    onClose: () => dispatch(alertActions.remove(id)),
                };

                if (alertConstants.ERROR === type) {
                    return toast.error(message, options);
                }

                if (alertConstants.INFO === type) {
                    return toast.info(message, options);
                }

                return toast(message, options);
            })
        }
        <ToastContainer />
    </div>
);

Alert.propTypes = {
    /**
   * The component's alerts.
   */
    alerts: PropTypes.array,
};

const mapStateToProps = (state) => {
    const { alerts } = state;
    return {
        alerts,
    };
};

const connectedAlert = connect(mapStateToProps)(Alert);
export { connectedAlert as Alert };

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup } from 'shards-react';
import {
    Input,
    FieldFeedbacks,
    FieldFeedback,
} from 'react-form-with-constraints-bootstrap4';
import { useTranslation } from 'react-i18next';

function NumericAssumptionCodes({
    inputs,
    setInputs,
    formRef: ref,
    saveAssumption,
}) {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const formIsValid = ref.current.isValid();
        const { description, numeric, code } = inputs;
        const shouldEnableSubmitButton = () => (formIsValid && numeric && code && description);

        setSubmitButtonDisabled(!shouldEnableSubmitButton());
    }, [inputs, ref]);

    const handleChange = async ({ target, currentTarget }) => {
        const { name, value } = target;
        setInputs((prevState) => ({ ...prevState, [name]: value }));
        // eslint-disable-next-line no-unused-expressions
        currentTarget.tagName !== 'LABEL' && await ref.current.validateFields(currentTarget);
    };

    return (
        <div className="fields-group">

            <FormGroup>
                <div className="flex">
                    <label>{t('module.scoreLogic.component.manageAssumption.numeric')}</label>
                    <Input
                        className="form-control  mb-2"
                        name="numeric"
                        onChange={handleChange}
                        ref={() => ref}
                        required
                        min="0"
                        step="0.01"
                        size="sm"
                        type="number"
                        defaultValue={inputs.numeric}
                    />
                </div>
                <FieldFeedbacks for="numeric">
                    <FieldFeedback when="*" />
                </FieldFeedbacks>
            </FormGroup>

            <div className="fields flex">
                <Button
                    className="btn"
                    disabled={submitButtonDisabled}
                    theme="primary"
                    onClick={() => { saveAssumption(false); }
                    }>{t('module.scoreLogic.component.manageAssumption.saveClose')}</Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { scoreLogic } = state;
    return { scoreLogic };
};

const connectedNumericAssumptionCodes = connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true },
)(NumericAssumptionCodes);
export { connectedNumericAssumptionCodes as NumericAssumptionCodes };

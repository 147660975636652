import React, { useState, useEffect } from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { connect, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
} from "shards-react";
import { useTranslation } from 'react-i18next';
import { modalActions } from '../../../modals';
import Header from '../../../../components/widget/widgetTitle';
import { clientActions } from '../../_actions';
import Actions from './modalComponents/viewClient/actions';
import SendReminder from './modalComponents/viewClient/sendReminder';
import LoanDetailsCard from './loanDetailsCard';
import ViewNotes from './modalComponents/viewClient/viewNotes';
import DocumentUpload from './modalComponents/viewClient/uploads';
import './getClientProfile.scss';

const ListClientDetails = ({ modalID, data, dispatch }) => {
    const client = useSelector((state) => state.clients);
    const { t } = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { profile, error } = client;
    const { id, mfiCode, refetchClients} = data

    const updatefetchClient = () => {
        dispatch(clientActions.getClientProfile(data?.id, data?.mfiCode))
    }

    const { clientAssignees } = useSelector((state) => state.clients);

    useEffect(() => {
        dispatch(clientActions.getClientAssignees(id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const AssignTo = (name, authentication) => {
        const payload = {
            mfiId: id,
            loanId: mfiCode,
            userId: authentication,
            name
        }

        dispatch(modalActions.clientAssignToClientProfile({ id, mfiCode, payload, refetchClients }));
    }

    useEffect(() => {
        dispatch(clientActions.getClientProfile(data?.id, data?.mfiCode))
    }, [data, dispatch]);

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal" style={{ width: '1120px' }}>
            <Header title={profile?.fullName} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={closeModal} />
            <div className="w-full p-10">
                {error && (
                    <center>
                        <h3>{t('module.client.arrears.action.modal.error.title')}</h3>
                        <p>{t('module.client.arrears.action.modal.error.description')}</p>
                        <Button onClick={closeModal}>{t('module.client.arrears.action.modal.error.action')}</Button>
                    </center>
                )}
                {!error && (
                    <div>
                        <div className="clientDetailsDropdown">
                            <Dropdown open={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} group className="clientDetailsDropdown__dropdown-item">
                                <Button onClick={() => setDropdownOpen(!dropdownOpen)}>{t('module.client.arrears.action.assignTo')}</Button>
                                <DropdownToggle split />
                                <DropdownMenu style={{ backgroundColor: '#60c1f7' }}>
                                    {(clientAssignees !== null) ? (
                                        <>
                                            {clientAssignees && clientAssignees.map((assignee) =>
                                            (
                                                <>
                                                    <DropdownItem onClick={() =>  AssignTo(assignee.name, assignee.id)}>{assignee.name}</DropdownItem>
                                                </>
                                            ))}
                                        </>
                                    ) : <p style={{ color: '#000' }}>{t('module.client.arrears.action.noData')}</p>}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <h5>{t('module.client.arrears.action.modal.loanDetails')}</h5>
                        <hr />
                        <div className="clientDetails">
                            <div className="clientDetails__section">
                                <LoanDetailsCard keyValue={t('module.client.arrears.action.modal.profile.fullName')} value={profile?.fullName} />
                                <LoanDetailsCard keyValue={t('module.client.arrears.action.modal.profile.idNo')} value={profile?.nationalId} />
                                <LoanDetailsCard keyValue={t('module.client.arrears.action.modal.profile.phoneNo')} value={profile?.phoneNumber} />
                                <LoanDetailsCard keyValue={t('module.client.arrears.action.modal.profile.loanCycle')} value={profile?.loan?.loanCycle} />
                                <LoanDetailsCard keyValue={t('module.client.arrears.action.modal.profile.timelyRepayment')} value={profile?.loan?.timelyRepayments} />
                            </div>

                            <div className="clientDetails__section">
                                <LoanDetailsCard keyValue={t('module.client.arrears.action.modal.profile.amountInArrears')} value={profile?.loan?.amountInArrears} />
                                <LoanDetailsCard keyValue={t('module.client.arrears.action.modal.profile.disbursedDate')} value={profile?.loan?.disbursementDate} />
                                <LoanDetailsCard keyValue={t('module.client.arrears.action.modal.profile.lastRepayment')} value={profile?.loan?.lastPayment} />
                                <LoanDetailsCard keyValue={t('module.client.arrears.action.modal.profile.nextRepayment')} value={profile?.loan?.nextPayment} />
                                {
                                    profile?.loan?.deliquency?.length > 0 && (
                                        <LoanDetailsCard keyValue={t('module.client.arrears.action.modal.profile.deliquency')} value={profile?.loan?.deliquency} />
                                    )
                                }
                                <LoanDetailsCard keyValue={t('module.client.arrears.action.modal.profile.daysInArrears')} value={profile?.loan?.daysInArrears} />
                            </div>
                        </div>

                        {/* tab */}
                        <div>
                            <Tabs>
                                <TabList>
                                    <Tab>{t('module.client.arrears.action.sendReminder')}</Tab>
                                    <Tab>{t('module.client.arrears.action.modal.documentChecklist')}</Tab>
                                    <Tab>{t('module.client.arrears.action.modal.profile.action')}</Tab>
                                    <Tab>{t('module.client.arrears.action.modal.viewNotes')}</Tab>
                                </TabList>
                                <TabPanel>
                                    <SendReminder mfiId={profile?.mfiId} loanId={profile?.id} />
                                </TabPanel>
                                <TabPanel>
                                    <DocumentUpload updatefetchClient={updatefetchClient} mfiId={profile?.mfiId} loanId={profile?.id} />
                                </TabPanel>
                                <TabPanel>
                                    <Actions mfiId={profile?.mfiId} loanId={profile?.id} />
                                </TabPanel>
                                <TabPanel>
                                    <ViewNotes mfiId={profile?.mfiId} loanId={profile?.id} />
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { clients, modals } = state;
    return { clients, modals };
};

const connectedListClientDetails = connect(mapStateToProps)(ListClientDetails);
export { connectedListClientDetails as ListClientDetails };

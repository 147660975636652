export default () => ({
  selectMenu: {
    color: "white",
  },
  container: {
    width: "100%",
  },
  hazardInfoItem: {
    height: "fit-content",
  },
  sliderRoot: {
    margin: "2em",
  },
  notAvailableText: {
    color: "#CACEDB",
    textAlign: "center",
    marginTop: "1em",
  },
  indicatorPaper: {
    height: "50%",
    marginBottom: "1.5rem",
  },
  sliderRoot: {},
  track: {
    height: 38,
    borderRadius: 20,
    background: "linear-gradient(to right, #008d6a, #F9EAC2)",
  },
  rail: {
    height: 38,
    borderRadius: 20,
    color: "#EFF5FA",
    border: "solid #BBC4C2 1px",
  },
  sliderContainer: {
    width: "80%",
    margin: "0 auto",
    marginTop: "0.5rem",
    position: "relative",
    paddingBottom: "0.5rem",
  },
  severityText: {
    fontWeight: "bold",
    position: "absolute",
    top: "3.4rem",
  },
  streesIndexHeader: {
    fontWeight: "bold",
    textAlign: "center",
    padding: 10,
    backgroundColor: "#D8E8F5",
  },
  hazardSubHeader: {
    fontWeight: "bold",
    textAlign: "center",
    padding: 5,
    backgroundColor: "#D8E8F5",
  },
  superscript: {
    color: "black",
    width: 5,
    height: 5,
    borderRadius: "50%",
    border: "1px solid",
    marginLeft: 2,
  },
  stressIndexText: {
    fontWeight: 600,
  },
  risk: {
    fontWeight: 550,
  },
  totalPoints: {
    fontWeight: "bold",
  },
  finalScore: {
    fontSize: 18,
    fontWeight: 600,
    color: "var(--primary)",
  },
  pageTextSecondary: {
    color: "var(--secondary)",
    fontWeight: 550,
  },
  maxScore: {
    color: "#999999",
  },
  emptyRisk: {
    fontWeight: 500,
    height: 24,
  },
  tabContainer: {
    justifyContent: "space-around",
  },
  tabWrapper: {
    fontWeight: "bold",
    flexDirection: "row",
  },
  iconImage: {
    marginRight: 5,
  },
  sliderHeader: {
    fontWeight: 500,
    fontSize: "1.25rem",
  },

  hazardHeader: {
    backgroundColor: "var(--primary)",
    color: "#ffffff",
    padding: 8,
    marginBottom: 5,
  },
  notAvailableText: {
    color: "#CACEDB",
    textAlign: "center",
    padding: 30,
  },
})

import React from 'react';
import { useDispatch } from 'react-redux';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
    ButtonGroup,
    Button,
} from 'shards-react';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/widget/widgetTitle';
import { modalActions } from '../../modals/_actions';
import { mfiManagerActions } from '..';

export function ActivateQuestionnaire({ modalID, data }) {
    const { mfiId, id } = data;
    const dispatch = useDispatch();
    const { t } = useTranslation();


    const activateQuestionnaire = () => {
        const data = {
        	"active" : true,
        	"mfi_id" : mfiId,
            "success": t('module.mfiSetup.activateQuestionnaire.alertSuccess'),
            "error": t('module.mfiSetup.activateQuestionnaire.alertError')
        }
        dispatch(mfiManagerActions.deactivateQuestionnaire(data, id));
        closeModal();
    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };
    return (
        <div className="site-modal">
            <Header title={t('module.scoreLogic.modal.activateQuestionnaire.headerTitle')} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal }/>
            <div className="p-10">
                <p>{t('module.scoreLogic.modal.activateQuestionnaire.description')}</p>
                <ButtonGroup className="btn-group-actions site-modal__actions">
                    <Button theme="primary" className="thin delete mr-2" onClick={() => activateQuestionnaire()} >{t('module.scoreLogic.modal.activateQuestionnaire.yes')}</Button>
                    <Button theme="secondary" className="thin" onClick={ () => { closeModal(); } }>{t('module.scoreLogic.modal.activateQuestionnaire.cancel')}</Button>
                </ButtonGroup></div>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { Nav } from 'shards-react';
import { UserActions } from './UserActions';
import { HeaderNavbar } from '../HeaderNavbar/HeaderNavbar';
import LanguageSelector from './LanguageSelector';
import NotificationContainer from './Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { notificationsActions } from '../../../../modules/client/views/notifications/store/_actions';
import { authenticationActions } from '../../../../modules/authentication';

export const NavbarNav = () => {

    const [realtime, setRealTime] = useState({})

    const user = useSelector((state) => state.authentication);
    const notifications = useSelector((state) => state.notifications);

    const { notificationsData,  realTimeNotifications: { data = null } } = notifications
    const { mfi, roles } = user && user.profile
    const dispatch = useDispatch()
    const mfiId = mfi && mfi.id

    useEffect(() => {
        if (data !== null) {
            setRealTime(data)
        }
    }, [data])

    useEffect(() => {
        dispatch(authenticationActions.getProfile());
    }, [dispatch])

    useEffect(() => {
        if (mfiId !== undefined) {
            dispatch(notificationsActions.fetchNotifications(mfiId))
        }
    }, [mfiId, dispatch])

    const markAllAsRead = (fetchAgain) => {
        if (mfiId !== undefined) {
            dispatch(notificationsActions.markAllAsReadNotifications(mfiId, fetchAgain))
        }
    }

    return (
        <Nav navbar className="flex-row w-100">
            <HeaderNavbar />
            <NotificationContainer
                roles={roles && roles}
                markAllAsRead={markAllAsRead}
                mfiId={mfiId}
                realtime={realtime && realtime}
                notifications={notificationsData && notificationsData.content}
            />
            <LanguageSelector />
            <UserActions />
        </Nav>
    )
}

import React from 'react';
import { Nav } from 'shards-react';
import { find } from 'lodash';

import { connect } from 'react-redux';
import { SidebarNavItem } from './SidebarNavItem';
import { settingsActions } from '../../../modules/settings';
import { publicRoutes, privateRoutes } from '../../../routes';

class SidebarNavItems extends React.Component {
    constructor(props) {
        super(props);
        this.props.dispatch(settingsActions.navItems());
        this.allRoutes = { ...publicRoutes, ...privateRoutes };
    }

    getPathRoles(item) {
        const { to } = item;
        const route = find(this.allRoutes, { path: to });
        if (!route) {
            return false;
        }
        const { roles } = route;

        return roles;
    }

    render() {
        const { settings } = this.props;
        const { navItems: items } = settings;

        return (
            <div className="nav-wrapper">
                {items && items.map((nav, idx) => (
                    <div key={idx}>
                        {typeof nav.items !== 'undefined' && nav.items.length && (
                            <Nav className="nav--no-borders flex-column">
                                {nav.items.map((item, idx2) => (<SidebarNavItem key={idx2} item={item} roles={ this.getPathRoles(item) }/>))}
                            </Nav>
                        )}
                    </div>
                ))}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { settings } = state;
    return { settings };
};

const connectedSidebarNavItems = connect(mapStateToProps)(SidebarNavItems);
export { connectedSidebarNavItems as SidebarNavItems };

import React, { useEffect, useState, useCallback, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Card, CardBody, Button } from "shards-react"
import { AgGridReact } from "ag-grid-react"
import { useTranslation } from "react-i18next"

import PageTitle from "../../../components/common/PageTitle"
import { formatDate } from "../../../utils/FormatDate"
import { configActions } from "../../config"
import { history } from "../../../_helpers"
import { applicationActions } from ".."
import Svg from "react-inlinesvg"
import "./Profile.scss"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"

const iconSyncSVG = require("../../../assets/images/icons/user-circle-svgrepo-com.svg")

export const ViewApplicationProfile = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const applications = useSelector((state) => state.applications)
  const config = useSelector((state) => state.config)

  const { profileFields } = config

  // matches for various profile fileds -> Should be named exaclty the same
  const firstNameMatch = "First Name"
  const middleNameMatch = "Middle Name"
  const lastNameMatch = "Last Name"
  const landMatch = "Land Size"
  const dependentsMatch = "Dependents"

  const landField = profileFields.filter(
    (field) => field.fieldTitle === landMatch
  )
  const dependentsField = profileFields.filter(
    (field) => field.fieldTitle === dependentsMatch
  )

  const nameFields = []
  profileFields.forEach((field) => {
    if (
      field.fieldTitle === firstNameMatch ||
      field.fieldTitle === middleNameMatch ||
      field.fieldTitle === lastNameMatch
    ) {
      nameFields.push(field)
    }
  })
  const uniqueField = profileFields?.filter((field) => field.uniqueIdentifier)

  const { id, xformId, xformversion, tab, loggedInUser, mfiId } =
    applications.applicationClicked
  const clickedApplicationDetails = applications.items?.filter((item) => {
    return id === item.id
  })
  const otherApplicationDetails = applications.items?.filter(
    (item) => id !== item.id
  )

  const profileNames = nameFields?.map(
    (nameField) => clickedApplicationDetails[0]?.json[nameField?.questionKey]
  )

  const profileName = profileNames?.join(" ")

  const otherProfileFields = profileFields?.filter(
    (field) =>
      field.fieldTitle !== firstNameMatch &&
      field.fieldTitle !== middleNameMatch &&
      field.fieldTitle !== lastNameMatch &&
      field.fieldTitle !== landMatch &&
      field.fieldTitle !== dependentsMatch
  )

  const profileDetails = otherProfileFields.map((field) => {
    const label = field.fieldTitle
    let value = clickedApplicationDetails[0]?.json[field.questionKey]
    if (label === "Gender") {
      value = value === "0" ? "Male" : "Female"
    }
    return {
      label,
      value,
    }
  })

  const gridRef = useRef()

  let gridApi

  function onGridReady(params) {
    gridApi = params.api
  }

  useEffect(() => {
    if (clickedApplicationDetails[0]?.surveyUuid) {
      dispatch(
        configActions.getProfileFields(clickedApplicationDetails[0].surveyUuid)
      )
    }
  }, [clickedApplicationDetails[0]?.surveyUuid])

  const data = [
    {
      applicationDate: formatDate(
        new Date(clickedApplicationDetails[0]?.submissionTime),
        "d/m/y"
      ),
      amount: clickedApplicationDetails[0]?.approvalAmount,
      creditScore: clickedApplicationDetails[0]?.grade,
      landSize: clickedApplicationDetails[0]?.json[landField[0]?.questionKey],
      tenor: clickedApplicationDetails[0]?.approvalLoanTenor,
      dependents:
        clickedApplicationDetails[0]?.json[dependentsField[0]?.questionKey],
      status: clickedApplicationDetails[0]?.workflowStatus,
      currentUser: clickedApplicationDetails[0]?.currentUser,
      id: clickedApplicationDetails[0]?.id,
    },
  ]

  if (
    clickedApplicationDetails[0]?.grade ||
    clickedApplicationDetails[0]?.grade === ""
  ) {
    otherApplicationDetails.forEach((application) => {
      const appSummary = {
        applicationDate: formatDate(
          new Date(application?.submissionTime),
          "d/m/y"
        ),
        amount: application?.approvalAmount,
        creditScore: application?.grade,
        landSize: application?.json[landField[0]?.questionKey],
        tenor: application?.approvalLoanTenor,
        dependents: application?.json[dependentsField[0]?.questionKey],
        currentUser: application?.currentUser,
        status: application?.workflowStatus,
        id: application?.id,
      }
      data.push(appSummary)
    })
  }

  const handlePageChange = (data) => {
    dispatch(applicationActions.applicationClicked(data))
  }

  const renderViewApplication = (params) => {
    const {
      node: {
        data: { id: applicationId },
      },
    } = params
    return (
      <button
        className="view-btn"
        onClick={(e) =>
          handlePageChange({
            page: "ViewApplication",
            tab,
            history: false,
            id: applicationId,
            xformId,
            xformversion,
            loggedInUser,
          })
        }
      >
        {t(
          "module.applicationManager.component.applicationTableRowsActions.view"
        )}
      </button>
    )
  }
  const {
    profile: { mfi },
  } = useSelector((store) => store.authentication)
  let { currency } = mfi || {}

  const columns = [
    {
      field: "applicationDate",
      width: 150,
      sortable: true,
    },
    {
      headerName: "Amount " + "(" + currency + ")",
      field: "amount",
      type: "number",
      valueFormatter: (params) => {
        const formatter = new Intl.NumberFormat("en-US", {})
        if (params.value === undefined) {
          return formatter.format(0)
        }
        return formatter.format(Number(params.value))
      },

      width: 150,
      sortable: true,
    },
    {
      field: "creditScore",
      width: 150,
      sortable: true,
    },
    {
      headerName: "Land Size (Acres)",
      field: "landSize",
      width: 150,
      sortable: true,
    },
    {
      field: "tenor",
      width: 150,
      sortable: true,
    },
    {
      field: "dependents",
      width: 150,
      sortable: true,
    },
    {
      field: "currentUser",
      width: 150,
      sortable: true,
    },
    {
      field: "status",
      width: 150,
      sortable: true,
    },
    {
      field: "id",
      width: 150,
      sortable: true,
    },
    {
      field: "linkToApplication",
      width: 200,
      cellRenderer: renderViewApplication,
      pinned: "right",
    },
  ]

  useEffect(() => {
    if (uniqueField[0]?.questionKey) {
      dispatch(
        applicationActions.getProfileApplications(
          uniqueField[0]?.questionKey,
          Number(id)
        )
      )
    }
  }, [dispatch, id, profileFields])

  const handleClose = () => {
    history.push("/applications")
    dispatch(
      applicationActions.applicationClicked({
        page: "ListApplication",
        history: true,
      })
    )
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header pt-4 pb-1">
        <Col xs="9">
          <PageTitle title="Client Profile" className="text-sm-left" />
        </Col>
        <Col xs="3">
          <Button
            theme="secondary"
            className="mx-2 d-table ml-auto btn"
            type="submit"
            onClick={() => handleClose()}
          >
            {t(
              "module.applicationManager.component.applicationDetailsActions.close"
            )}
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card className="border">
            <CardBody>
              <Row className="items-center mb-3">
                <Col xs="1" className="user-icon">
                  <Svg
                    className="sm-left"
                    width="60px"
                    height="60px"
                    src={iconSyncSVG}
                  />
                </Col>
                <Col xs="11" className="text-2xl">
                  {profileName}
                </Col>
              </Row>
              <Row className="mb-3 text-base profile-details-row">
                {profileDetails.map((detail, index) => (
                  <Col id={index} xs="4">
                    <Row id={index}>
                      <Col xs="6">{detail.label}:</Col>
                      <Col xs="6" className="text-primary">
                        {detail.value}
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div
                style={{ height: 421, width: "100%" }}
                className="ag-theme-alpine"
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={data}
                  columnDefs={columns}
                  onGridReady={onGridReady}
                  pagination={true}
                  paginationAutoPageSize={true}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

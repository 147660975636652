import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    header: {
        '& *': {
            color: '#fff',
        },
    },
    titleBar: {
        backgroundColor: 'var(--primary)',
        height: '57px',
        paddingLeft: '79px',
        paddingBottom: '0',
        fontSize: '17px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    title: {
        margin: 0,
        fontSize: '21px',
        lineHeight: '1',
    },
    icon: {
        position: 'absolute',
        width: '64px',
        height: '64px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    closeIcon: {
        position: 'absolute',
        right: '0',
        width: '40px',
        height: '57px',
        backgroundColor: 'var(--primary)',
        cursor: 'pointer',
        '& svg': {
            width: '15px',
            height: '57px',
        },
        '& svg path': {
            fill: '#fff',
        },
    },
}));

const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
        <path fill="#107D54" fill-rule="evenodd" d="M7.094.023L4 3.116.906.023.023.906 3.116 4 .023 7.094l.883.883L4 4.884l3.094 3.093.883-.883L4.884 4 7.977.906z" />
    </svg>
)

export default function WidgetTitle(props) {
    const classes = useStyles();
    const {
        title, icon, close, closeHandler, children,
    } = props;

    return (
        <Grid className={`${classes.header} relative`} container>
            <Box className={`${classes.icon} bg-secondary white`}>{icon}</Box>
            <Box className={`${classes.titleBar} bg-primary`}>
                <h4 className={classes.title}>{title}</h4>
                {children}
            </Box>
            { close && <Box className={classes.closeIcon} onClick={() => { closeHandler(); }} ><CloseIcon /></Box>}
        </Grid>
    );
}

import { createStyles, makeStyles } from "@material-ui/core";

export const useStylesClient = makeStyles(() => createStyles({
    formWrapper: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    label: {
        paddingRight: '1rem',
        color: '#3f547d'
    },
    textAreaWrapper: {
        width: '100%'
    },
    textArea: {
        width: '100%',
        border: '1px solid #c3d1de',
        borderRadius: '2px',
        padding: '10px'
    },
    button: {
        left: '90%',
        color: 'white',
        padding: '4px 35px',
        marginTop: '15px',
        textTransform: 'capitalize'
    }
}));

import { reportsConstants } from "."

const storedApplicationData = localStorage.getItem("applicationData")
const applicationData =
  storedApplicationData && JSON.parse(storedApplicationData)

/**
 * Set Default State
 */
const InitialState = {
  uid: applicationData && applicationData.uid,
  reports: [],
  repaymentsReports: [],
  dateInputs: {
    startDate: null,
    endDate: null,
  },
  selectedRepaymentDate: "",
  hierarchyEntityIdInput: null,
  hierarchy: null,
  mfiIdInput: null,
  submittedBy: "",
  paymentsSummary: [],
  loading: false,
  downloading: false,
}

/**
 * Reports
 *
 * @param {*} state
 * @param {*} action
 */
export const reports = (state = InitialState, action) => {
  switch (action.type) {
    case reportsConstants.REPORTS_DOWLOAD_REQUEST:
      return {
        ...state,
        downloading: true,
      }
    case reportsConstants.REPORTS_DOWLOAD_SUCCESS:
      return {
        ...state,
        downloading: false,
      }
    case reportsConstants.REPORTS_DOWLOAD_FAILURE:
      return {
        ...state,
        downloading: false,
      }
    case reportsConstants.REPORTS_GET_SUCCESS:
      return {
        ...state,
        reports: action.data,
      }

    case reportsConstants.REPORTS_GET_FAILURE:
      return {
        ...state,
        reports: [],
      }

    case reportsConstants.PAYMENTS_REPORT_REQUEST:
      return {
        ...state,
        paymentsSummary: [],
        loading: true,
      }
    case reportsConstants.PAYMENTS_REPORT_SUCCESS:
      return {
        ...state,
        paymentsSummary: action.data,
        loading: false,
      }
    case reportsConstants.PAYMENTS_REPORT_FAILURE:
      return {
        ...state,
        paymentsSummary: [],
        loading: false,
      }

    case reportsConstants.REPORTS_FILTER_REQUEST:
      return {
        ...state,
        reports: [],
      }

    case reportsConstants.REPORTS_FILTER_SUCCESS:
      return {
        ...state,
        reports: action.data,
      }

    case reportsConstants.REPORTS_FILTER_FAILURE:
      return {
        ...state,
        reports: [],
      }

    case reportsConstants.REPORTS_DATE_INPUTS:
      return {
        ...state,
        dateInputs: action.data,
      }

    case reportsConstants.SELECTED_REPAYMENT_REPORTS_DATE_INPUTS:
      return {
        ...state,
        selectedRepaymentDate: action.data,
      }

    case reportsConstants.REPORTS_HIERARCHY_INPUTS:
      return {
        ...state,
        hierarchyEntityIdInput: action.data,
      }
    case reportsConstants.REPORTS_SUBMITTED_BY:
      return {
        ...state,
        submittedBy: action.data,
      }

    case reportsConstants.HIERARCHY_GET_SUCCESS:
      return {
        ...state,
        hierarchy: action.data,
      }

    case reportsConstants.HIERARCHY_GET_FAILURE:
      return {
        ...state,
        hierarchy: [],
      }

    case reportsConstants.REPORTS_MFI_ID:
      return {
        ...state,
        mfiIdInput: action.data,
      }

    case reportsConstants.DEFAULT_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
        repaymentsReports: [],
        repaymentsReportsError: null,
      }
    case reportsConstants.DEFAULT_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        repaymentsReports: action.data,
        repaymentsReportsError: null,
      }
    case reportsConstants.DEFAULT_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        repaymentsReports: [],
        repaymentsReportsError: action.error,
      }

    default: {
      return state
    }
  }
}

import React from "react"
import { ManageCancelActions } from "../common/ManageMfiSetupActions"
import AddMenuCustomizationAction from "./AddMenuCustomizationAction"
import AddMpesaCustomizationAction from "./AddMpesaCustomizationAction"
import AddImageAction from "./AddImageAction"
import { menuPanel, mpesaPanel, imagePanel } from "./Constants"

export function ManageCustomization({ panel, id: mfiId }) {
  return (
    <>
      <ManageCancelActions mfiId={mfiId} />
      {panel === menuPanel && (
        <AddMenuCustomizationAction mfiId={mfiId} panel={panel} />
      )}
      {panel === mpesaPanel && (
        <AddMpesaCustomizationAction mfiId={mfiId} panel={panel} />
      )}
      {panel === imagePanel && <AddImageAction mfiId={mfiId} panel={panel} />}
    </>
  )
}

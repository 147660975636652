import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { isEmpty } from "lodash"
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  TextField,
} from "@material-ui/core"
import styles from "./LoadDistributed.styles"
import { mfiManagerActions } from "../../../mfiManager/index"
import { ApplicationDetailsActions } from "../../../applicationManager"

export default withStyles(styles)(({ classes, applicationId }) => {
  const dispatch = useDispatch()
  const {
    loanDistributed = {
      farmSize: 0,
      crop: "",
      inspectionActivity: "",
      totalLoan: 0,
    },
  } = useSelector((store) => store.mfis)
  const getInitialState = () => {
    return isEmpty(loanDistributed)
      ? {
          farmSize: 0,
          crop: "",
          inspectionActivity: "",
          totalLoan: 0,
        }
      : loanDistributed
  }
  const [inputs, setInputs] = useState(getInitialState())
  const { id } = loanDistributed

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputs((prevState) => ({ ...prevState, [name]: value }))
  }

  const addLoanDistributed = () => {
    const payload = {
      farmSize: inputs.farmSize,
      crop: inputs.crop,
      inspectionActivity: inputs.inspectionActivity,
      totalLoan: inputs.totalLoan,
    }
    payload["applicationId"] = applicationId
    dispatch(mfiManagerActions.addLoanDistributed(payload))
  }

  const editLoanDistributed = () => {
    const payload = {
      farmSize: inputs.farmSize,
      crop: inputs.crop,
      inspectionActivity: inputs.inspectionActivity,
      totalLoan: inputs.totalLoan,
    }
    payload["applicationId"] = applicationId
    dispatch(mfiManagerActions.editLoanDistributed(inputs.id, payload))
  }

  useEffect(() => {
    const id = applicationId.toString()
    dispatch(mfiManagerActions.getLoanDistributed(id))
  }, [dispatch, applicationId])

  useEffect(() => {
    setInputs(loanDistributed)
  }, [loanDistributed])

  return (
    <>
      <TableContainer component={Paper} classes={{ root: classes.tableRoot }}>
        <Table aria-label="loan distributed table">
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell classes={{ root: classes.cellRoot }}>Item</TableCell>
              <TableCell classes={{ root: classes.cellRoot }}>
                Description
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell classes={{ root: classes.cellRoot }}>
                Farm size (ha)
              </TableCell>
              <TableCell>
                <TextField
                  value={inputs.farmSize}
                  name="farmSize"
                  variant="outlined"
                  type="number"
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                    classes: { root: classes.inputRoot },
                  }}
                  size="small"
                  className={classes.tableInput}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell classes={{ root: classes.cellRoot }}>Crop</TableCell>
              <TableCell>
                <TextField
                  value={inputs.crop}
                  name="crop"
                  variant="outlined"
                  onChange={handleChange}
                  placeholder="Enter crop names"
                  size="small"
                  className={classes.tableInput}
                  InputProps={{
                    classes: { root: classes.inputRoot },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell classes={{ root: classes.cellRoot }}>
                Inspection activity
              </TableCell>
              <TableCell>
                <TextField
                  value={inputs.inspectionActivity}
                  name="inspectionActivity"
                  variant="outlined"
                  onChange={handleChange}
                  placeholder="Enter inspection activities"
                  size="small"
                  className={classes.tableInput}
                  InputProps={{
                    classes: { root: classes.inputRoot },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell classes={{ root: classes.cellRoot }}>
                Total loan (CFA)
              </TableCell>
              <TableCell>
                <TextField
                  value={inputs.totalLoan}
                  name="totalLoan"
                  variant="outlined"
                  type="number"
                  onChange={handleChange}
                  size="small"
                  className={classes.tableInput}
                  InputProps={{
                    inputProps: { min: 0 },
                    classes: { root: classes.inputRoot },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow classes={{ root: classes.tableRowRoot }}>
              <TableCell />
              <TableCell>
                <div className={classes.btnWrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.submitBtn}
                    onClick={() =>
                      id ? editLoanDistributed() : addLoanDistributed()
                    }
                  >
                    {id ? "Edit" : "Submit"}
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ApplicationDetailsActions id={applicationId} saveDisabled={true} />
    </>
  )
})

/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect, useRef } from "react"
import PersonOutlineOutlinedIcon from "@material-ui/icons/LockOutlined"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { FormGroup, Button } from "shards-react"

import {
  FormWithConstraints,
  Input,
  FieldFeedbacks,
  FieldFeedback,
} from "react-form-with-constraints-bootstrap4"

import { isEmpty, first } from "lodash"
import { useTranslation } from "react-i18next"
import Header from "../../../components/widget/widgetTitle"
import { modalActions } from "../../modals"
import { applicationActions } from ".."

import "./RecalculateScore.scss"

export function RecalculateScores({ modalID, data }) {
  const { t } = useTranslation()
  const form = useRef(null)
  const dispatch = useDispatch()
  const state = useSelector((store) => store)
  const { applicationIds, group } = data
  const { applications } = state
  const { applicationMetadata } = applications

  let loanTenor = {}

  if (!isEmpty(applicationMetadata)) {
    loanTenor = first(
      applicationMetadata.filter(
        (item) =>
          item.type === "LOAN_TENOR" &&
          Number(item.applicationId) === Number(applicationIds[0])
      )
    )
    loanTenor = loanTenor ? loanTenor : {}
  }

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [inputs, setInputs] = useState({
    newLoanTenor: null,
  })

  useEffect(() => {
    const application = first(
      applications.items.filter((item) => item.id === applicationIds[0])
    )
    dispatch(applicationActions.getLoanInformation(application))
  }, [])

  useEffect(() => {
    function shouldEnableSubmitButton() {
      return inputs.newLoanTenor
    }
    setSubmitButtonDisabled(!shouldEnableSubmitButton())
  }, [inputs])

  const handleChange = ({ target, currentTarget }) => {
    const { name, value } = target
    setInputs((prevState) => ({ ...prevState, [name]: value }))
    form.current.validateFields(currentTarget)
  }

  const save = async () => {
    await form.current.validateForm()

    if (isEmpty(inputs)) {
      return false
    }

    // SAVE AND CALCULATE NEW SCORE

    const data = applicationIds.map((applicationId) => ({
      applicationId,
      approvalLoanTenor: parseInt(inputs.newLoanTenor),
      approvalLoanTenorQuestionKey: loanTenor.questionKey,
    }))
    dispatch(applicationActions.editMultipleLoanTenors(data))
    closeModal()
    return false
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  return (
    <div className="site-modal scoring-variables__add-variable">
      <Header
        title={"Adjust Loan Tenor"}
        icon={<PersonOutlineOutlinedIcon />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        <h2 className="pb-0 form-group-section">
          {t("module.applicationManager.modal.recalculateScore.details")}
        </h2>
        <div>
          Adjust loan tenor for applicants in{" "}
          <span className="font-bold">{group[0]}</span>
        </div>

        <h4
          className="pb-1 mb-0 border-0 form-group-section text-base text-lg"
          style={{ fontSize: "20px" }}
        >
          {t("module.applicationManager.modal.recalculateScore.adjusted")}
        </h4>
        <FormWithConstraints ref={form} noValidate className="shadow-none">
          <FormGroup>
            <label htmlFor="newLoanTenor">
              {t("module.applicationManager.modal.recalculateScore.newTenor")}
            </label>
            <Input
              className="form-control mb-2"
              name="newLoanTenor"
              value={inputs.newLoanTenor}
              onChange={handleChange}
              type="number"
              required
            />
            <FieldFeedbacks for="newLoanTenor">
              <FieldFeedback when="*" />
            </FieldFeedbacks>
          </FormGroup>
          <div className="btn-group-actions site-modal__actions flex">
            <Button
              theme="primary"
              disabled={submitButtonDisabled}
              className={`ml-auto ${
                submitButtonDisabled && `btn-recalculate`
              } `}
              onClick={() => save()}
            >
              {t(
                "module.applicationManager.modal.recalculateScore.recalculate"
              )}
            </Button>
            <Button
              theme="secondary"
              className="ml-2"
              onClick={() => {
                closeModal()
              }}
            >
              {t("module.applicationManager.modal.recalculateScore.cancel")}
            </Button>
          </div>
        </FormWithConstraints>
      </div>
    </div>
  )
}

RecalculateScores.propTypes = {
  modalID: PropTypes.string,
  data: PropTypes.object,
}

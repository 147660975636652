export default () => ({
  gpsSubtitle: {
    marginBottom: 5,
    textTransform: "capitalize",
  },
  gpsSubtitleShape: {
    marginBottom: 5,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  gpsLink: {
    marginTop: 5,
  },
  gpsSection: {
    marginBottom: 20,
  },
  mapContainer: {
    height: 300,
    position: "relative",
    marginBottom: 20,
  },
  gpsContainer: {
    pageBreakInside: "avoid",
    breakInside: "avoid",
  },
  gpsTextShape: {
    color: "var(--secondary);",
  },
})

import { axiosInstance, handleResponse } from "../../_helpers/base.service"

export const creditScoreService = {
  getScoreCard,
  getFormulaResult,
  getLoanInformation,
  recalculateCreditScore,
  getJsonChanges,
  getCreditScoreReport,
  getCashFlowAnalysis,
}

const serviceGroup = "creditscore"

/**
 * Get Score Card
 *
 * @param {*} id
 */
function getScoreCard(id) {
  return axiosInstance
    .get(`${serviceGroup}/score-card/application/${id}/`)
    .then(handleResponse)
}

/**
 * Get Formula Results
 *
 * @param {*} application
 */
function getFormulaResult(id) {
  return axiosInstance
    .get(`${serviceGroup}/formula-result/application/${id}/`)
    .then(handleResponse)
}

/**
 * Get loan Information
 *
 * @param {*} application
 */
function getLoanInformation(applicationId) {
  return axiosInstance
    .get(`/workflow/application-loan-details/application-loan-detail`, {
      params: {
        applicationId: applicationId.id,
      },
    })
    .then(handleResponse)
}

/**
 * Recalculate Credit Score
 *
 * @param {*} application
 */
function recalculateCreditScore(applicationID) {
  return axiosInstance
    .get(`${serviceGroup}/calculation/application/${applicationID}/`)
    .then(handleResponse)
}

/**
 * Get credit score report
 *
 * @param {*} applicationId
 */
function getCreditScoreReport(applicationId) {
  return axiosInstance
    .get(`${serviceGroup}/application-credit-score-report`, {
      params: {
        applicationId,
      },
    })
    .then(handleResponse)
}

/**
 * Get cash flow analysis
 *
 * @param {*} applicationId
 */
function getCashFlowAnalysis(applicationId) {
  return axiosInstance
    .get(
      `${serviceGroup}/application-credit-score-report/cash-flow-report/${applicationId}/`
    )
    .then(handleResponse)
}

/**
 * Get Json Changes
 *
 * @param {*} applicationId
 */
function getJsonChanges(applicationId) {
  return axiosInstance
    .get("workflow/applications/application/change-logs", {
      params: {
        applicationId,
      },
    })
    .then(handleResponse)
}

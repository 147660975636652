import React, { useState, useEffect, useRef } from "react"
import PersonOutlineOutlinedIcon from "@material-ui/icons/LockOutlined"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Switch } from "@material-ui/core"
import { FormGroup, FormInput, ButtonGroup, Button } from "shards-react"
import { alertActions } from "../../alert"
import Header from "../../../components/widget/widgetTitle"
import { mfiManagerActions } from ".."
import { modalActions } from "../../modals"

export const AddQuestionnaire = ({ modalID, data }) => {
  const fileInputEl = useRef(null)
  const dispatch = useDispatch()
  const [file, setFile] = useState({})
  const [inputs, setInputs] = useState({
    title: "",
    description: "",
    groupCreation: false,
    collection: false,
  })
  const [description, setDescription] = useState(false)
  const { t } = useTranslation()

  const supportedFilesTypes = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ]

  const isValid = (input) => {
    if (!input) {
      return false
    }
    const { type } = input

    if (supportedFilesTypes.includes(type)) {
      return true
    }
    return false
  }

  const handleFileSelect = (event) => {
    const {
      target: { files },
    } = event
    event.preventDefault()
    const [tempFile] = files
    if (isValid(tempFile)) {
      return setFile(tempFile)
    }
    dispatch(
      alertActions.error(t("module.mfiSetup.editQuestionnaire.uploadExcelFile"))
    )
    return false
  }

  useEffect(() => {
    if (inputs.description.length > 4) {
      setDescription(true)
    } else {
      setDescription(false)
    }
  }, [inputs])

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputs((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSwitch = (e) => {
    const { name } = e.target
    setInputs((prevState) => ({
      ...prevState,
      [name]:
        name === "groupCreation"
          ? !prevState.groupCreation
          : !prevState.collection,
    }))
  }

  const addQuestionnaire = () => {
    const payload = inputs
    payload.submision = file
    payload.mfi_id = data
    payload.message = t("module.mfiSetup.addQuestionnaire.alertMessage")
    payload.error = t("module.mfiSetup.addQuestionnaire.alertError")
    dispatch(mfiManagerActions.addQuestionnaire(payload))
    closeModal()
  }

  const { name } = file

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  return (
    <div className="site-modal">
      <Header
        title={t("module.mfiSetup.addQuestionnaire.title")}
        icon={<PersonOutlineOutlinedIcon />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="select-file p-10">
        <FormGroup>
          <label htmlFor="name">
            {t("module.mfiSetup.editQuestionnaire.titleInput")}
          </label>
          <FormInput
            defaultValue={inputs.title}
            name="title"
            size="md"
            className="mb-2"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="name">
            {t("module.mfiSetup.editQuestionnaire.description")}
          </label>
          <FormInput
            defaultValue={inputs.description}
            name="description"
            size="md"
            aria-describedby="descriptionError"
            className="mb-2"
            onChange={handleChange}
          />
          {!description && (
            <small id="descriptionError" className="form-text text-danger">
              {t("module.mfiSetup.addQuestionnaire.minimumLength")}.
            </small>
          )}
        </FormGroup>
        <FormGroup>
          <label>{t("module.mfiSetup.editQuestionnaire.groupcreation")}</label>
          {inputs.groupCreation}
          <Switch
            onChange={handleSwitch}
            checked={inputs.groupCreation}
            color="primary"
            name="groupCreation"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </FormGroup>
        <FormGroup>
          <label>{t("module.mfiSetup.editQuestionnaire.collection")}</label>
          {inputs.collection}
          <Switch
            onChange={handleSwitch}
            checked={inputs.collection}
            color="primary"
            name="collection"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </FormGroup>
        <FormGroup>
          <input
            className="border p-1 file-upload"
            type="file"
            ref={fileInputEl}
            name="importFile"
            onChange={handleFileSelect}
            accept={supportedFilesTypes.join(",")}
          />
          <label htmlFor="file-import" className="m-0 flex items-center">
            <span>{name || t("module.tools.import.choosexlsxFile")}</span>
            <strong
              className="cursor-pointer"
              onClick={() => fileInputEl.current.click()}
            >
              {t("module.tools.import.uploadFile")}
            </strong>
          </label>
        </FormGroup>
        <ButtonGroup className="btn-group-actions site-modal__actions">
          <Button
            theme="primary"
            className="thin mr-2"
            onClick={() => addQuestionnaire()}
          >
            {t("module.mfiSetup.actions.save")}
          </Button>
          <Button
            theme="secondary"
            className="thin delete"
            onClick={() => closeModal()}
          >
            {t("module.mfiSetup.actions.cancel")}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

import React from 'react';

import { Login as LoginComponent } from '..';
import { Branding } from '../../../components/branding';

export const LoginView = () => (
    <div className="login-page">
        <Branding />
        <LoginComponent />
    </div>
);

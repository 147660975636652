/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react"
import { useDispatch } from "react-redux"
import Svg from "react-inlinesvg"
import { ButtonGroup, Button } from "shards-react"
import Header from "../../../components/widget/widgetTitle"

import { workflowActions } from ".."
import { modalActions } from "../../modals"

import "./DisburseRefuseModal.scss"
import { useTranslation } from "react-i18next"
const headerSVG = require("../../../assets/images/icons/header-icon.svg")

const DisburseRefuseModal = ({ modalID, data }) => {
  const { t } = useTranslation()
  const { applicationId, disburseAction, multiple, applicationIds } = data
  const dispatch = useDispatch()
  const title = disburseAction
    ? t("applicationDisburse.disburseApplication")
    : t("applicationDisburse.refuseApplication")

  const handeSubmit = () => {
    disburseAction &&
      !multiple &&
      dispatch(
        workflowActions.disburseRefuseApplication({
          applicationId,
          action: "Disbursed",
        })
      )

    !disburseAction &&
      !multiple &&
      dispatch(
        workflowActions.disburseRefuseApplication({
          applicationId,
          action: "Refused",
        })
      )

    if (multiple) {
      const payload = applicationIds.map((applicationId) => ({
        applicationId,
        action: "Disbursed",
      }))
      dispatch(workflowActions.disburseMultipleApplications(payload))
    }

    closeModal()
    return null
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }
  return (
    <div className="site-modal">
      <Header
        title={title}
        icon={<Svg className="text-center d-inline-block" src={headerSVG} />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        <p>
          {t("applicationDisburse.areYouSure")}{" "}
          {disburseAction
            ? t("applicationDisburse.disburse")
            : t("applicationDisburse.refuse")}{" "}
          {t("applicationDisburse.selectedApplication")}
        </p>
        <ButtonGroup className="btn-group-actions site-modal__actions">
          <Button
            theme="primary"
            className="thin mr-2"
            onClick={() => {
              handeSubmit()
            }}
          >
            {disburseAction
              ? t("applicationDisburse.yesDisburse")
              : t("applicationDisburse.yesRefuse")}
          </Button>
          <Button
            theme="cancel"
            className="thin delete"
            onClick={() => {
              closeModal()
            }}
          >
            {t("applicationDisburse.cancel")}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default DisburseRefuseModal

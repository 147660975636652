import { kobotoolkitConstants } from './_constants';

const accessTokenString = localStorage.getItem('kobo') ? localStorage.getItem('kobo') : null;
const initialState = accessTokenString ? {
    accessTokenString,
    surveyDeployment: {
        content: {
            settings: {
                id_string: '',
            },
            translations: [],
            choices: [],
            survey: [],
            translated: [],
        },
        content_hash: '',
        date_deployed: '',
        date_modified: '',
        uid: '',
    },
} : {};

export const kobotoolkit = (state = initialState, action) => {
    switch (action.type) {
    case kobotoolkitConstants.KOBO_AUTH_REQUEST:
        return {
            ...state,
            error: null,
            loading: true,
        };

    case kobotoolkitConstants.KOBO_AUTH_FAILURE:
        return {
            ...state,
            error: true,
            loading: false
        };
    case kobotoolkitConstants.KOBO_AUTH_TOKEN:
        return {
            ...state,
            error: null,
            loading: false,
            accessTokenString: action.data,
        };
    case kobotoolkitConstants.KOBO_ASSETS_REQUEST:
      return {
          ...state,
          error: null,
          loading: true
      };
    case kobotoolkitConstants.KOBO_ASSETS_SUCCESS:
        return {
            ...state,
            error: null,
            loading: false,
            assets: action.data,
        };
    case kobotoolkitConstants.KOBO_ASSETS_FAILURE:
        return {
            ...state,
            error: true,
            loading: false
        };
    case kobotoolkitConstants.KOBO_SURVEY_SUCCESS:
        return {
            ...state,
            error: null,
            loading: false,
            surveyDeployment: { ...action.data },
        };

    default:
        return state;
    }
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormSelect } from 'shards-react';
import { mfiManagerActions } from '../..';


export default function (props) {
    const [selectedGroup, setSelectedGroup] = useState('');
    const dispatch = useDispatch();
    const selectedWorkflowGroupId = useSelector(state => state.mfis.selectedWorkflowGroupId);
    const { groups } = props;
    const { t } = useTranslation();

    const handleChange = (e, { name, value }) => {
        e.preventDefault();
        const activeGroup = groups.filter(group => group.id === value);
        setSelectedGroup(activeGroup.code);
        dispatch(mfiManagerActions.filterWorkflowGroups(value));
    };

    return (
        <Form className="flex w-full group-select">
            <span className="group-select-label">{t('workflowGroup.title')}</span>
            <FormSelect name="group" className="mx-2 w-40 group-select-input" defaultValue={selectedGroup} onChange={(e) => { handleChange(e, { name: e.target.name, value: e.target.value }); }}>
                <option name="" value="">{t('workflowGroup.selectGroup')}</option>
                {
                    groups && groups.map((group) => {
                        return (<option key={group.id} name={group.code} value={group.id}  selected={selectedWorkflowGroupId === group.id}>{group.code}</option>);
                    })
                }
            </FormSelect>
        </Form>
    );
}

export const authenticationConstants = {
  USERS_LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  USERS_LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  USERS_LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  USER_PROFILE_REQUEST: "USER_PROFILE_REQUEST",
  USER_PROFILE_SUCCESS: "USER_PROFILE_SUCCESS",
  USER_PROFILE_FAILURE: "USER_PROFILE_FAILURE",

  USER_TOKEN_REFRESH_REQUEST: "USER_TOKEN_REFRESH_REQUEST",
  USER_TOKEN_REFRESH_SUCCESS: "USER_TOKEN_REFRESH_SUCCESS",
  USER_TOKEN_REFRESH_FAILURE: "USER_TOKEN_REFRESH_FAILURE",

  USER_LOGOUT: "USER_LOGOUT",
  USER_LOGIN_REMEMBER_ME: "USER_LOGIN_REMEMBER_ME",

  USER_RESET_LINK_SUCCESS: "USER_RESET_LINK_SUCCESS",

  USER_RESET_SMS_SUCCESS:  "USER_RESET_SMS_SUCCESS",
}

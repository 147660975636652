import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form, FormGroup, FormSelect } from "shards-react";
import { isArray } from 'lodash';

const FilterMfis = ({ selectedValue, className, items, handler, t }) => {
  return (
    <>
        {items?.length === undefined ? (
            selectedValue?.mfiName
        ) : (
            <Form className={`${className} shadow-none bg-transparent m-0`}>
                <FormGroup className="flex items-center mb-0">
                    <FormSelect name="uid" size="sm" className="mr-auto w-auto" onChange={handler} value={selectedValue}>
                        <option key={-1} value="">{selectedValue?.mfiName || t("module.client.client.selectMfi")}</option>
                        {
                            isArray(items) && items?.map((item, idx) => {
                                return <option key={idx} value={`${item.mfiName}`}>{ item.mfiName }</option>;
                            })
                        }
                    </FormSelect>
                </FormGroup>
            </Form>
        )}
    </>
  );
}

const connectedTableFilters = withTranslation()(FilterMfis);
export { connectedTableFilters as FilterMfis };

import React, { useState, useRef } from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useDispatch } from 'react-redux';
import {
    FormGroup,
    ButtonGroup,
    Button,
} from 'shards-react';
import { useTranslation } from 'react-i18next';
import { alertActions } from '../../alert';
import Header from '../../../components/widget/widgetTitle';
import { mfiManagerActions } from '..';
import { modalActions } from '../../modals';

export const EditQuestionnaire = ({ modalID, data }) => {
    const { item } = data
    const fileInputEl = useRef(null);
    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const { t } = useTranslation()

    const supportedFilesTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];

    const [inputs, setInputs] = useState({
        title: item.title,
        description: item.description,
    })

    const isValid = (input) => {
        if (!input) {
            return false;
        }
        const { type } = input;

        if (supportedFilesTypes.includes(type)) {
            return true;
        }
        return false;
    };

    const handleInputOnchange = (e) => {
        const { name, value } = e.target;
        setInputs((prevState) => ({ ...prevState, [name]: value }));
    }

    const handleFileSelect = (event) => {
        const { target: { files } } = event;
        event.preventDefault();
        const [tempFile] = files;
        if (isValid(tempFile)) {
            return setFile(tempFile);
        }
        dispatch(alertActions.error(t('module.mfiSetup.editQuestionnaire.uploadExcelFile')));
        return false;
    };

    const editQuestionnaire = () => {
        const payload = inputs;
        payload.submision = file;
        payload.mfi_id = item.mfi_id;
        payload.success = t('module.mfiSetup.editQuestionnaire.alertMessage');
        payload.error = t('module.mfiSetup.editQuestionnaire.alertError');
        dispatch(mfiManagerActions.editQuestionnaire(payload, item.id));
        closeModal();
    }

    const { name } = file !== null && file;

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal">
            <Header title={t('module.mfiSetup.editQuestionnaire.title')} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal }/>
            <div className="select-file p-10">
                <FormGroup>
                    <label htmlFor="title">{t('module.mfiSetup.editQuestionnaire.titleInput')}</label>
                    <input type="text" className="form-control" name="title" value={inputs?.title} onChange={handleInputOnchange} />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="description">{t('module.mfiSetup.editQuestionnaire.description')}</label>
                    <input type="text" className="form-control" name="description" value={inputs?.description} onChange={handleInputOnchange}  />
                </FormGroup>
                <FormGroup>
                    <input className="border p-1 file-upload" type="file" ref={fileInputEl} name="importFile" onChange={handleFileSelect} accept={supportedFilesTypes.join(',')}/>
                    <label htmlFor="file-import" className="m-0 flex items-center">
                        <span>{name || t('module.tools.import.choosexlsxFile')}</span>
                        <strong className="cursor-pointer" onClick={() => fileInputEl.current.click()}>
                            {t('module.tools.import.uploadFile')}
                        </strong>
                    </label>
                </FormGroup>
                <ButtonGroup className="btn-group-actions site-modal__actions">
                    <Button theme="primary" className="thin mr-2" onClick={ () => editQuestionnaire() } >{t('module.mfiSetup.actions.save')}</Button>
                    <Button theme="secondary" className="thin delete" onClick={ () => { closeModal() } }>{t('module.mfiSetup.actions.cancel')}</Button>
                </ButtonGroup>
            </div>
        </div>
    );
};

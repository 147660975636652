/* eslint array-callback-return: 0 */
import { find, findIndex, isEmpty, uniqBy } from "lodash"
import uuid from "uuid"
import { cslConstants } from "."
import { uniqueBy } from "../../utils/uniqueBy"

const storedApplicationData = localStorage.getItem("applicationData")
const applicationData =
  storedApplicationData && JSON.parse(storedApplicationData)

/**
 * Set Default State
 */
const InitialState = {
  uid: applicationData && applicationData.uid,
  computationCodes: [],
  assumptions: [],
  formulas: [],
  cashflowStatements: [],
  scoringVariables: [],
  scoreGrade: [],
  scoreCardProfile: [],
  groups: [],
  surveyGroupWorkflow: [],
  documentSetup: [],
  documentChecklist: [],
  recalculate: true,
}

/**
 * Update cashflow using code and data
 *
 * @param {*} state
 * @param {*} payload
 */
function updateCashflowStatementsVariables(state, payload) {
  const { cashflowStatements } = state
  cashflowStatements[payload.index] = payload.statement

  return cashflowStatements
}

/**
 * Update scoring card using code and data
 *
 * @param {*} state
 * @param {*} payload
 */
function updateScoringVariables(state, payload) {
  const { scoringVariables } = state
  const { id, category, codes, value, maxScore, title } = payload

  const found = find(scoringVariables, { title: category })
  const index = findIndex(scoringVariables, found)
  const row = index !== -1 && scoringVariables[index]

  const rowItem = find(row.entries, { id })
  const rowItemIndex = findIndex(row.entries, rowItem)

  if (rowItem && codes) {
    const [firstCode] = codes
    rowItem.title = title
    rowItem.code = firstCode
  }

  if (maxScore && value) {
    rowItem.configurations.push({
      id: uuid(),
      value,
      maxScore,
      unsaved: true,
    })
  }

  row.entries[rowItemIndex] = rowItem

  scoringVariables[index] = row

  return scoringVariables
}

/**
 * Add a new entry to category
 * @param {*} state
 * @param {*} payload
 */
function updateScoringVariableEntries(state, payload) {
  const { scoringVariables } = state
  const { id, data } = payload

  if (isEmpty(data)) {
    return scoringVariables
  }

  const { codes } = data

  if (isEmpty(codes)) {
    return scoringVariables
  }

  const found = find(scoringVariables, { id })
  const index = findIndex(scoringVariables, found)
  const row = index !== -1 && scoringVariables[index]

  const [firstCode] = codes

  data.code = firstCode
  data.configurations = []

  row.entries = [...row.entries, data]

  scoringVariables[index] = row
  return scoringVariables
}

/**
 * Add a new entry to category
 * @param {*} state
 * @param {*} payload
 */
function addScoringVariableCategories(state, payload) {
  const { scoringVariables } = state
  const { data } = payload
  const { title, code } = data

  const row = {
    id: uuid(),
    title,
    code,
    entries: [],
    configurations: [],
  }

  return [...scoringVariables, row]
}

/**
 * Update scoring card using code and data
 *
 * @param {*} state
 * @param {*} payload
 */
function updateScoreCard(state, data) {
  const { uid, scoreGrade } = state
  return [...scoreGrade, { ...data, surveyUuid: uid, id: uuid() }]
}

/**
 * Update scoring card using code and data
 *
 * @param {*} state
 * @param {*} payload
 */
function editScoreCard(state, data) {
  const { scoreGrade } = state
  const { id } = data

  if (isEmpty(data)) {
    return scoreGrade
  }

  const found = find(scoreGrade, { id })
  const index = findIndex(scoreGrade, found)
  const row = index !== -1 && scoreGrade[index]

  scoreGrade[index] = { ...row, ...data }

  return scoreGrade
}

/**
 * Score Logic
 *
 * @param {*} state
 * @param {*} action
 */
export const scoreLogic = (state = InitialState, action) => {
  switch (action.type) {
    case cslConstants.CSL_QUESTIONNAIRE_UID: {
      localStorage.setItem(
        "applicationData",
        JSON.stringify({ ...applicationData, uid: action.id })
      )
      return { ...state, uid: action.id }
    }
    case cslConstants.CSL_COMPUTATION_CODES_SUCCESS: {
      return {
        ...state,
        computationCodes: [...action.data],
      }
    }
    case cslConstants.CSL_COMPUTATION_CODES_FAILURE: {
      return {
        ...state,
        computationCodes: [],
      }
    }
    case cslConstants.CSL_ASSUMPTIONS_REQUEST:
    case cslConstants.CSL_FORMULAS_REQUEST:
    case cslConstants.CSL_COMPUTATION_CODES_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case cslConstants.CSL_FORMULAS_SUCCESS: {
      return {
        ...state,
        formulas: action.data,
      }
    }
    case cslConstants.CSL_FORMULA_ADD_SUCCESS: {
      const formulas = uniqBy(
        [...state.formulas, ...action.data.formulas],
        "code"
      )

      return {
        ...state,
        formulas,
      }
    }
    case cslConstants.CSL_FORMULA_UPDATE_SUCCESS: {
      return {
        ...state,
        formulas: state.formulas.map((formula) =>
          formula.id === action.data.id
            ? { id: action.data.id, ...action.data.data }
            : formula
        ),
      }
    }
    case cslConstants.CSL_FORMULA_DELETE_SUCCESS: {
      return {
        ...state,
        formulas: [...state.formulas.filter(({ id }) => id !== action.id)],
      }
    }
    case cslConstants.CSL_ASSUMPTIONS_SUCCESS: {
      return {
        ...state,
        assumptions: action.data,
      }
    }
    case cslConstants.CSL_ASSUMPTION_ADD_SUCCESS: {
      return {
        ...state,
        assumptions: [...state.assumptions, action.data],
      }
    }
    case cslConstants.CSL_ASSUMPTION_UPDATE_SUCCESS: {
      return {
        ...state,
        assumptions: state.assumptions.map((assumption) =>
          assumption.id === action.data.id
            ? { id: action.data.id, ...action.data.assumptionData }
            : assumption
        ),
      }
    }
    case cslConstants.CSL_ASSUMPTION_DELETE_SUCCESS: {
      return {
        ...state,
        assumptions: [
          ...state.assumptions.filter(({ id }) => id !== action.id),
        ],
      }
    }
    case cslConstants.CSL_CASHFLOW_STATEMENTS_SUCCESS: {
      return {
        ...state,
        cashflowStatements: action.data,
      }
    }
    case cslConstants.CSL_CASHFLOW_STATEMENTS_UPDATE_ITEM: {
      const cashflowStatementsUpdated = updateCashflowStatementsVariables(
        state,
        action.data
      )
      return {
        ...state,
        cashflowStatements: [...cashflowStatementsUpdated],
      }
    }
    case cslConstants.CSL_CASHFLOW_STATEMENTS_DELETE_ITEM: {
      const cashflowStatementsUpdated = updateCashflowStatementsVariables(
        state,
        action.data
      )
      return {
        ...state,
        cashflowStatements: [...cashflowStatementsUpdated],
      }
    }
    case cslConstants.CSL_SCORING_VARIABLES_SUCCESS: {
      return {
        ...state,
        scoringVariables: action.data,
      }
    }
    case cslConstants.CSL_SCORING_VARIABLES_ADD_CATEGORY: {
      const scoringVariablesUpdatedWithCats = addScoringVariableCategories(
        state,
        action.data
      )
      return {
        ...state,
        scoringVariables: [...scoringVariablesUpdatedWithCats],
      }
    }
    case cslConstants.CSL_SCORING_VARIABLES_DELETE_ENTRY: {
      return {
        ...state,
        scoringVariables: state.scoringVariables.map((category) => {
          category.entries = category.entries.filter(
            ({ id }) => id !== action.id
          )
          return category
        }),
      }
    }
    case cslConstants.CSL_SCORING_VARIABLES_ADD_ENTRY: {
      const scoringVariables = updateScoringVariableEntries(state, action.data)
      return {
        ...state,
        scoringVariables: [...scoringVariables],
      }
    }
    case cslConstants.CSL_SCORING_VARIABLES_ADD_CONDITION: {
      const scoringVariablesUpdated = updateScoringVariables(state, action.data)
      return {
        ...state,
        scoringVariables: [...scoringVariablesUpdated],
      }
    }
    case cslConstants.CSL_SCORING_VARIABLES_EDIT_CONDITION: {
      return {
        ...state,
        scoringVariables: state.scoringVariables.map((item) => {
          if (item.title === action.data.category) {
            item.entries = item.entries.map((entry) => {
              if (entry.id === action.data.entryId) {
                entry.configurations = entry.configurations.map((config) => {
                  if (config.id === action.data.id) {
                    config = {
                      value: action.data.value,
                      maxScore: action.data.maxScore,
                      id: Number(config.id),
                    }
                  }
                  return config
                })
              }
              return entry
            })
          }
          return item
        }),
      }
    }
    case cslConstants.CSL_SCORING_VARIABLES_DELETE_CONDITION: {
      return {
        ...state,
        scoringVariables: state.scoringVariables.map((category) => {
          const entries = category.entries.map((entry) => {
            const conditions =
              entry.configurations &&
              entry.configurations.filter(({ id }) => id !== action.id)
            if (conditions) {
              entry.configurations = conditions
            } else {
              entry.configurations = []
            }
            return entry
          })
          category.entries = entries
          return category
        }),
      }
    }
    case cslConstants.CSL_SCORE_GRADE_SUCCESS: {
      const scoreGrades = uniqBy([...state.scoreGrade, ...action.data], "grade")

      return {
        ...state,
        scoreGrade: [...scoreGrades],
      }
    }
    case cslConstants.CSL_SCORE_GRADE_ADD: {
      const scoreGradeUpdated = updateScoreCard(state, action.data)
      return {
        ...state,
        scoreGrade: [...scoreGradeUpdated],
      }
    }
    case cslConstants.CSL_SCORE_GRADE_EDIT: {
      const scoreGradeEdited = editScoreCard(state, action.data)
      return {
        ...state,
        scoreGrade: [...scoreGradeEdited],
      }
    }
    case cslConstants.CSL_SCORE_GRADE_DELETE: {
      return {
        ...state,
        scoreGrade: state.scoreGrade.filter(({ id }) => id !== action.id),
      }
    }
    case cslConstants.CSL_SCORE_CARD_PROFILE_SUCCESS: {
      return {
        ...state,
        scoreCardProfile: action.data,
      }
    }

    case cslConstants.CSL_SCORE_CARD_PROFILE_UPDATE_SUCCESS: {
      return {
        ...state,
        scoreCardProfile: state.scoreCardProfile.map((item) =>
          item.id === action.data.id
            ? { id: action.data.id, ...action.data.data }
            : item
        ),
      }
    }
    case cslConstants.CSL_WORKFLOW_GROUPS_GET_SUCCESS:
      return {
        ...state,
        groups: action.data,
      }
    case cslConstants.CSL_SURVEY_WORKFLOW_GROUPS_GET_SUCCESS:
      return {
        ...state,
        surveyGroupWorkflow: action.data,
      }
    case cslConstants.CSL_SURVEY_WORKFLOW_GROUPS_GET_FAILURE:
      return {
        ...state,
        surveyGroupWorkflow: [],
      }
    case cslConstants.CSL_WORKFLOW_GROUPS_SAVE_SUCCESS:
      return {
        ...state,
        surveyGroupWorkflow: { ...state.surveyGroupWorkflow, ...action.data },
      }
    case cslConstants.CSL_CHECKLIST_STATE_ADD_SUCCESS:
      let newDocumentSetup = state.documentSetup
      const key = Object.keys(action.data)
      newDocumentSetup[key] = action.data[key]
      return {
        ...state,
        documentSetup: { ...newDocumentSetup },
      }
    case cslConstants.CSL_CHECKLIST_GET_SUCCESS:
      return {
        ...state,
        documentChecklist: action.data,
      }
    case cslConstants.CSL_CHECKLIST_SAVE_SUCCESS:
      return {
        ...state,
        documentChecklist: [
          ...state.documentChecklist,
          ...uniqueBy([...[action.data]], "id"),
        ],
      }
    case cslConstants.CSL_CHECKLIST_EDIT_SUCCESS:
      let newDocumentChecklistState = state.documentChecklist
      state.documentChecklist.map((item, index) => {
        if (String(item.id) === String(action.data.id)) {
          newDocumentChecklistState[index] = { ...action.data }
        }
      })
      return {
        ...state,
        documentChecklist: newDocumentChecklistState,
      }
    case cslConstants.CSL_CHECKLIST_DELETE_SUCCESS:
      return {
        ...state,
        documentChecklist: [
          ...state.documentChecklist.filter(({ id }) => id !== action.id),
        ],
      }

    case cslConstants.CSL_TEST_SCORE_LOGIC_SUCCESS:
      return {
        ...state,
        recalculate: false,
      }
    case cslConstants.CSL_TEST_SCORE_LOGIC_FAILURE:
      return {
        ...state,
        recalculate: true,
      }

    case cslConstants.CSL_RECALCULATE_SCORE_LOGIC_SUCCESS:
      return {
        ...state,
        recalculate: true,
      }
    case cslConstants.CSL_RECALCULATE_SCORE_LOGIC_FAILURE:
      return {
        ...state,
        recalculate: true,
      }

    default: {
      return state
    }
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const MainFooter = ({ copyrightR, t, i18n }) => (
    <footer
        className="main-footer d-flex p-2 px-3 border-top"
        style={{ backgroundColor: 'white', zIndex: '1000' }}
    >
        <span className="copyright mr-auto my-auto">{t("component.layout.mainFooter.copyrightL")}</span>
        <span className="copyright ml-auto my-auto">{copyrightR}</span>
    </footer>
);

MainFooter.propTypes = {
    /**
   * Whether the content is contained, or not.
   */
    contained: PropTypes.bool,
    /**
   * The copyrightL info.
   */
    copyrightL: PropTypes.string,

    /**
   * The copyrightL info.
   */
    copyrightR: PropTypes.string,
};

MainFooter.defaultProps = {
    contained: false,
    copyrightR: `Financial Access. 2002 - ${new Date().getFullYear()}`,
};

export default withTranslation() (MainFooter);

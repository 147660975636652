import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import { Container, Row, Col, Button } from 'shards-react';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import { ApplicationTableFilters } from '..';
import { ApplicationEditor } from '../../applicationEditor';
import PageTitle from '../../../components/common/PageTitle';
import { applicationActions } from '..';


const AddApplication = ({ applications, surveys, dispatch, t }) => {
    const { surveyDeployment, uid } = applications;
    const { assets } = surveys
    const [state, setState] = useState({ uid: uid });
    const [tab, setTab] = useState(0);

    useEffect(() => {
        let xFormId = '';
        if (state.uid) {
          [xFormId] =  state.uid.split(':');
          dispatch(applicationActions.getFormFields(xFormId));
        }
    }, [dispatch, state.uid, assets]);

    const handleQuestionnareChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };

    /**
     * Set Questionnaire uid and Get formulas by UID
     */
    useEffect(() => {
        state.uid && dispatch(applicationActions.setQuestionnaire(state.uid));
    }, [state.uid, dispatch]);

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header pt-4 pb-1">
                <PageTitle sm="4" title={t('module.applicationManager.view.add.pageTitle')} className="text-sm-left" />
            </Row>
            <Row>
                <Col>
                    <Tabs>
                        <TabList>
                            <Tab defaultFocus={1}>{t('module.applicationManager.view.add.add')}</Tab>
                        </TabList>
                        <TabPanel>
                            { tab === 0 && <div className="p-10">
                              <ApplicationTableFilters className="table-filters ml-auto" selectedValue={state.uid} handler={handleQuestionnareChange} />
                              <div className="wizard-actions">
                                <Button className="ml-auto" onClick={e => setTab(1)} disabled={!state.uid ? true : false}>{t('module.scoreLogic.component.wizardActions.continue')}</Button>
                              </div>
                            </div> }
                            { state.uid && tab === 1 && !isEmpty(surveyDeployment.uid) && <ApplicationEditor { ...surveyDeployment } /> }
                        </TabPanel>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state) => {
    const { applications, surveys } = state;
    return { applications, surveys };
};

const connectedAddApplication = withTranslation()(connect(mapStateToProps)(AddApplication));
export { connectedAddApplication as AddApplication };

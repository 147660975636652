export const cslConstants = {
    CSL_QUESTIONNAIRE_UID: 'CSL_QUESTIONNAIRE_UID',

    CSL_COMPUTATION_CODES_REQUEST: 'CSL_COMPUTATION_CODES_REQUEST',
    CSL_COMPUTATION_CODES_SUCCESS: 'CSL_COMPUTATION_CODES_SUCCESS',
    CSL_COMPUTATION_CODES_FAILURE: 'CSL_COMPUTATION_CODES_FAILURE',

    CSL_ASSUMPTIONS_REQUEST: 'CSL_ASSUMPTIONS_REQUEST',
    CSL_ASSUMPTIONS_SUCCESS: 'CSL_ASSUMPTIONS_SUCCESS',
    CSL_ASSUMPTIONS_FAILURE: 'CSL_ASSUMPTIONS_FAILURE',

    CSL_ASSUMPTION_ADD_SUCCESS: 'CSL_ASSUMPTION_ADD_SUCCESS',
    CSL_ASSUMPTION_ADD_FAILURE: 'CSL_ASSUMPTION_ADD_FAILURE',
    CSL_ASSUMPTION_UPDATE_SUCCESS: 'CSL_ASSUMPTION_UPDATE_SUCCESS',
    CSL_ASSUMPTION_UPDATE_FAILURE: 'CSL_ASSUMPTION_UPDATE_FAILURE',
    CSL_ASSUMPTION_DELETE_SUCCESS: 'CSL_ASSUMPTION_DELETE_SUCCESS',
    CSL_ASSUMPTION_DELETE_FAILURE: 'CSL_ASSUMPTION_DELETE_FAILURE',

    CSL_FORMULAS_REQUEST: 'CSL_FORMULAS_REQUEST',
    CSL_FORMULAS_SUCCESS: 'CSL_FORMULAS_SUCCESS',
    CSL_FORMULAS_FAILURE: 'CSL_FORMULAS_FAILURE',

    CSL_FORMULA_ADD_SUCCESS: 'CSL_FORMULA_ADD_SUCCESS',
    CSL_FORMULA_ADD_FAILURE: 'CSL_FORMULA_ADD_FAILURE',

    CSL_FORMULA_UPDATE_SUCCESS: 'CSL_FORMULA_UPDATE_SUCCESS',
    CSL_FORMULA_UPDATE_FAILURE: 'CSL_FORMULA_UPDATE_FAILURE',

    CSL_FORMULA_DELETE_SUCCESS: 'CSL_FORMULA_DELETE_SUCCESS',
    CSL_FORMULA_DELETE_FAILURE: 'CSL_FORMULA_DELETE_FAILURE',

    CSL_CASHFLOW_STATEMENTS_REQUEST: 'CSL_CASHFLOW_STATEMENTS_REQUEST',
    CSL_CASHFLOW_STATEMENTS_SUCCESS: 'CSL_CASHFLOW_STATEMENTS_SUCCESS',
    CSL_CASHFLOW_STATEMENTS_FAILURE: 'CSL_CASHFLOW_STATEMENTS_FAILURE',

    CSL_CASHFLOW_STATEMENTS_UPDATE_ITEM: 'CSL_CASHFLOW_STATEMENTS_UPDATE_ITEM',
    CSL_CASHFLOW_STATEMENTS_DELETE_ITEM: 'CSL_CASHFLOW_STATEMENTS_DELETE_ITEM',

    CSL_SCORING_VARIABLES_REQUEST: 'CSL_SCORING_VARIABLES_REQUEST',
    CSL_SCORING_VARIABLES_SUCCESS: 'CSL_SCORING_VARIABLES_SUCCESS',
    CSL_SCORING_VARIABLES_FAILURE: 'CSL_SCORING_VARIABLES_FAILURE',

    CSL_SCORING_VARIABLES_ADD_CONDITION: 'CSL_SCORING_VARIABLES_ADD_CONDITION',
    CSL_SCORING_VARIABLES_EDIT_CONDITION: 'CSL_SCORING_VARIABLES_EDIT_CONDITION',
    CSL_SCORING_VARIABLES_DELETE_CONDITION: 'CSL_SCORING_VARIABLES_DELETE_CONDITION',

    CSL_SCORING_VARIABLES_ADD_CATEGORY: 'CSL_SCORING_VARIABLES_ADD_CATEGORY',
    CSL_SCORING_VARIABLES_DELETE_ENTRY: 'CSL_SCORING_VARIABLES_DELETE_ENTRY',
    CSL_SCORING_VARIABLES_ADD_ENTRY: 'CSL_SCORING_VARIABLES_ADD_ENTRY',

    CSL_SCORE_GRADE_REQUEST: 'CSL_SCORE_GRADE_REQUEST',
    CSL_SCORE_GRADE_SUCCESS: 'CSL_SCORE_GRADE_SUCCESS',
    CSL_SCORE_GRADE_FAILURE: 'CSL_SCORE_GRADE_FAILURE',

    CSL_SCORE_GRADE_ADD: 'CSL_SCORE_GRADE_ADD',
    CSL_SCORE_GRADE_EDIT: 'CSL_SCORE_GRADE_EDIT',
    CSL_SCORE_GRADE_DELETE: 'CSL_SCORE_GRADE_DELETE',

    CSL_SCORE_CARD_PROFILE_SUCCESS: 'CSL_SCORE_CARD_PROFILE_SUCCESS',
    CSL_SCORE_CARD_PROFILE_FAILURE: 'CSL_SCORE_CARD_PROFILE_FAILURE',

    CSL_SCORE_CARD_PROFILE_UPDATE_SUCCESS: 'CSL_SCORE_CARD_PROFILE_UPDATE_SUCCESS',
    CSL_SCORE_CARD_PROFILE_UPDATE_FAILURE: 'CSL_SCORE_CARD_PROFILE_UPDATE_FAILURE',

    CSL_SUBMIT_REQUEST: 'CSL_SUBMIT_REQUEST',
    CSL_SUBMIT_SUCCESS: 'CSL_SUBMIT_SUCCESS',
    CSL_SUBMIT_FAILURE: 'CSL_SUBMIT_FAILURE',

    CSL_WORKFLOW_GROUPS_GET_REQUEST: 'CSL_WORKFLOW_GROUPS_GET_REQUEST',
    CSL_WORKFLOW_GROUPS_GET_SUCCESS: 'CSL_WORKFLOW_GROUPS_GET_SUCCESS',
    CSL_WORKFLOW_GROUPS_GET_FAILURE: 'CSL_WORKFLOW_GROUPS_GET_FAILURE',

    CSL_WORKFLOW_GROUPS_SAVE_REQUEST: 'CSL_WORKFLOW_GROUPS_SAVE_REQUEST',
    CSL_WORKFLOW_GROUPS_SAVE_SUCCESS: 'CSL_WORKFLOW_GROUPS_SAVE_SUCCESS',

    CSL_SURVEY_WORKFLOW_GROUPS_GET_REQUEST: 'CSL_SURVEY_WORKFLOW_GROUPS_GET_REQUEST',
    CSL_SURVEY_WORKFLOW_GROUPS_GET_SUCCESS: 'CSL_SURVEY_WORKFLOW_GROUPS_GET_SUCCESS',
    CSL_SURVEY_WORKFLOW_GROUPS_GET_FAILURE: 'CSL_SURVEY_WORKFLOW_GROUPS_GET_FAILURE',

    CSL_CHECKLIST_DELETE_REQUEST: 'CSL_CHECKLIST_DELETE_REQUEST',
    CSL_CHECKLIST_DELETE_SUCCESS: 'CSL_CHECKLIST_DELETE_SUCCESS',
    CSL_CHECKLIST_DELETE_FAILURE: 'CSL_CHECKLIST_DELETE_FAILURE',

    CSL_CHECKLIST_GET_REQUEST: 'CSL_CHECKLIST_GET_REQUEST',
    CSL_CHECKLIST_GET_SUCCESS: 'CSL_CHECKLIST_GET_SUCCESS',
    CSL_CHECKLIST_GET_FAILURE: 'CSL_CHECKLIST_GET_FAILURE',

    CSL_CHECKLIST_SAVE_REQUEST: 'CSL_CHECKLIST_SAVE_REQUEST',
    CSL_CHECKLIST_SAVE_SUCCESS: 'CSL_CHECKLIST_SAVE_SUCCESS',
    CSL_CHECKLIST_SAVE_FAILURE: 'CSL_CHECKLIST_SAVE_FAILURE',

    CSL_CHECKLIST_EDIT_REQUEST: 'CSL_CHECKLIST_EDIT_REQUEST',
    CSL_CHECKLIST_EDIT_SUCCESS: 'CSL_CHECKLIST_EDIT_SUCCESS',
    CSL_CHECKLIST_EDIT_FAILURE: 'CSL_CHECKLIST_EDIT_FAILURE',

    CSL_CHECKLIST_STATE_ADD_SUCCESS: 'CSL_CHECKLIST_STATE_ADD_SUCCESS',

    CSL_SYNC_DOCUMENT_CHECKLIST_REQUEST: 'CSL_SYNC_DOCUMENT_CHECKLIST_REQUEST',
    CSL_SYNC_DOCUMENT_CHECKLIST_SUCCESS: 'CSL_SYNC_DOCUMENT_CHECKLIST_SUCCESS',
    CSL_SYNC_DOCUMENT_CHECKLIST_FAILURE: 'CSL_SYNC_DOCUMENT_CHECKLIST_FAILURE',

    CSL_TEST_SCORE_LOGIC_REQUEST: 'CSL_TEST_SCORE_LOGIC_REQUEST',
    CSL_TEST_SCORE_LOGIC_SUCCESS: 'CSL_TEST_SCORE_LOGIC_SUCCESS',
    CSL_TEST_SCORE_LOGIC_FAILURE: 'CSL_TEST_SCORE_LOGIC_FAILURE',

    CSL_RECALCULATE_SCORE_LOGIC_REQUEST: 'CSL_RECALCULATE_SCORE_LOGIC_REQUEST',
    CSL_RECALCULATE_SCORE_LOGIC_SUCCESS: 'CSL_RECALCULATE_SCORE_LOGIC_SUCCESS',
    CSL_RECALCULATE_SCORE_LOGIC_FAILURE: 'CSL_RECALCULATE_SCORE_LOGIC_FAILURE',
};

import React from 'react';
import slugify from 'slugify';
import { Badge, Button } from 'shards-react';
import { isEmpty, replace } from 'lodash';
import BasicTable from '../../../components/baseTable/BasicTable';

export function JobsTable({ data }) {
    const columns = [];

    if (isEmpty(data)) {
        return null;
    }

    Object.keys(data[0]).map((item) => {
        const payload = {
            key: item,
            dataKey: item,
            title: replace(replace(item, '_', ' '), '/', ' '),
            width: 150,
            resizable: true,
            className: ({ column }) => {
                const { key } = column;
                return `${slugify(key).toLowerCase()}`;
            },
            headerRenderer: (cell) => {
                const { column } = cell;
                const { key } = column;
                switch (key) {
                default:
                    return replace(replace(key, '_', ' '), '/', ' ');
                }
            },
            cellRenderer: (cell) => {
                const {
                    rowData, cellData, column,
                } = cell;

                const { download: exportUrl } = rowData;
                const cellRender = cellData;
                const { key } = column;
                const status = exportUrl ? 'completed' : 'pending';
                const activeClass = exportUrl ? 'pending' : 'complete';
                const buttonClass = exportUrl ? 'secondary' : 'tertiary';

                switch (key) {
                case 'filename':
                    return <div className="data-title">{cellData}</div>;
                case 'status':
                    return <Badge theme={activeClass} className={`badge-xs ${slugify(status).toLowerCase()}` }>{ status && replace(status.toLowerCase(), new RegExp('_', 'g'), ' ') }</Badge>;
                case 'download':
                    return <Button theme={buttonClass} disabled={activeClass === 'complete'}><a href={exportUrl} className="text-white" onClick={(e) => { if (isEmpty(exportUrl)) { e.preventDefault(); } }}>Download</a></Button>;
                default:
                    return cellRender;
                }
            },
        };

        columns.push(payload);

        return item;
    });

    return (
        <BasicTable
            columns={columns}
            data={data}
            headerHeight={0}
            className={'shadow-none'}
        />
    );
}

import { VillageBanksApplication } from "."
import { applicationConstants } from "./_constants"
const storedApplicationData = localStorage.getItem("applicationData")
const applicationData =
  storedApplicationData && JSON.parse(storedApplicationData)

/**
 * Set Default State
 */
const InitialState = {
  uid: applicationData && applicationData.uid,
  applicationMetadata: [],
  applicationColumns: [],
  dateColumnFilters: [],
  loading: false,
  error: false,
  userFilter: "",
  officeFilter: [],
  groupFilter: [],
  applicationClicked: "",
  filterState: {
    user: "",
    status: "",
    offices: [],
    group: [],
  },
  surveyDeployment: [],
  payments: [],
  myApplicationItems: [],
  myPendigTasksItems: [],
  assignWorkflowUser: "",
  purchaseActiveFilters: [],
  applicantName: "",
  ugandaBanks: [],
  entityStatusList: {},
  usersToAssign: {},
  repaymentSchedule: [],
}

export const applications = (state = InitialState, action) => {
  switch (action.type) {
    case applicationConstants.APPLICATIONS_QUESTIONNAIRE_UID: {
      let applicationUID = []
      if (action.id) {
        applicationUID = action.id.split(":")
      }

      const [xFormId, xFormVersion] = applicationUID

      localStorage.setItem(
        "applicationData",
        JSON.stringify({ ...applicationData, uid: action.id })
      )

      return {
        ...state,
        uid: action.id,
        xFormId,
        xFormVersion,
      }
    }
    case applicationConstants.APPLICATIONS_REQUEST: {
      return { ...state, error: null, loading: true }
    }
    case applicationConstants.APPLICATIONS_SUCCESS: {
      return { ...state, loading: null, items: action.data }
    }
    case applicationConstants.APPLICATIONS_FAILURE: {
      return { ...state, loading: null, error: action.error }
    }
    case applicationConstants.WORKFLOW_USER_REQUEST: {
      return { ...state, error: null, loading: true }
    }
    case applicationConstants.WORKFLOW_USER_SUCCESS: {
      return { ...state, loading: null, assignWorkflowUser: action.data }
    }
    case applicationConstants.WORKFLOW_USER_FAILURE: {
      return { ...state, loading: null, error: action.error }
    }
    case applicationConstants.MY_APPLICATIONS_REQUEST: {
      return { ...state, error: null, loading: true }
    }
    case applicationConstants.MY_APPLICATIONS_SUCCESS: {
      return { ...state, loading: null, myApplicationItems: action.data }
    }
    case applicationConstants.MY_APPLICATIONS_FAILURE: {
      return { ...state, loading: null, error: action.error }
    }
    case applicationConstants.MY_TASKS_REQUEST: {
      return { ...state, error: null, loading: true }
    }
    case applicationConstants.MY_TASKS_SUCCESS: {
      return { ...state, loading: null, myPendigTasksItems: action.data }
    }
    case applicationConstants.MY_TASKS_FAILURE: {
      return { ...state, loading: null, error: action.error }
    }
    case applicationConstants.PAYMENTS_UPLOAD_REQUEST: {
      return { ...state, error: null, loading: true }
    }
    case applicationConstants.PAYMENTS_UPLOAD_SUCCESS: {
      return { ...state, loading: null, payments: action.data || [] }
    }
    case applicationConstants.PAYMENTS_UPLOAD_FAILURE: {
      return { ...state, loading: null, error: action.error }
    }
    case applicationConstants.MOBILE_UPDATE_SUCCESS: {
      const index = state.payments.findIndex(
        (payment) => payment.id === action.data.id
      )
      const newPayments = [...state.payments]
      newPayments[index].mobileNo = action.data.mobileNo
      return {
        ...state,
        loading: null,
        payments: newPayments,
      }
    }
    case applicationConstants.GET_BANKS_REQUEST: {
      return { ...state, error: null, loading: true }
    }
    case applicationConstants.GET_BANKS_SUCCESS: {
      return { ...state, loading: null, ugandaBanks: action.data || [] }
    }
    case applicationConstants.GET_BANKS_FAILURE: {
      return { ...state, loading: null, error: action.error }
    }
    case applicationConstants.GET_PAYMENTS_REQUEST: {
      return { ...state, error: false, loading: true }
    }
    case applicationConstants.GET_PAYMENTS_SUCCESS: {
      return { ...state, loading: false, payments: action.data }
    }
    case applicationConstants.GET_PAYMENTS_FAILURE: {
      return { ...state, loading: false, error: action.error }
    }
    case applicationConstants.PAYMENTS_FILTER: {
      return { ...state, purchaseActiveFilters: action.data }
    }
    case applicationConstants.APPLICATION_ASSIGN: {
      return {
        ...state,
        items: state.items.map((application) =>
          application.id === action.id
            ? { ...application, stage: action.stage }
            : application
        ),
      }
    }
    case applicationConstants.APPLICATION_METADATA_REQUEST: {
      return { ...state, metaDataloading: true }
    }
    case applicationConstants.APPLICATION_METADATA_SUCCESS: {
      const applicantName =
        action.data.find((item) => item.type === "APPLICANT_NAME")?.value || ""
      return {
        ...state,
        applicationMetadata: [...action.data],
        metaDataloading: null,
        applicantName,
      }
    }
    case applicationConstants.APPLICATION_METADATA_FAILURE: {
      return { ...state, metaDataloading: null }
    }
    case applicationConstants.APPLICATION_COLUMN_GET_SUCCESS:
      return {
        ...state,
        applicationColumns: action.data,
      }
    case applicationConstants.APPLICATION_DATE_COLUMN_FILTER:
      return {
        ...state,
        dateColumnFilters: action.data,
      }
    case applicationConstants.APPLICATION_DATE_COLUMN_FILTER_RESET:
      return {
        ...state,
        dateColumnFilters: [],
      }
    case applicationConstants.APPLICATION_USER_FILTER:
      return {
        ...state,
        userFilter: action.data,
      }
    case applicationConstants.APPLICATION_OFFICE_FILTER:
      return {
        ...state,
        officeFilter: action.data,
      }
    case applicationConstants.APPLICATION_GROUP_FILTER:
      return {
        ...state,
        groupFilter: action.data,
      }
    case applicationConstants.APPLICATION_CLICKED:
      return {
        ...state,
        applicationClicked: action.data,
      }
    case applicationConstants.APPLICATION_STATE_FILTER:
      return {
        ...state,
        filterState: action.data,
      }
    case applicationConstants.GET_FORM_FIELDS_SUCCESS:
      return {
        ...state,
        surveyDeployment: action.data,
      }
    case applicationConstants.SEARCH_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true,
        searchApplications: [],
      }
    case applicationConstants.SEARCH_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        searchApplications: action.data,
      }
    case applicationConstants.SEARCH_APPLICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default: {
      return state
    }

    case applicationConstants.VILLAGE_BANKS_REQUEST:
      return {
        ...state,
        villageBanks: [],
        loading: true,
      }
    case applicationConstants.VILLAGE_BANKS_SUCCESS:
      return {
        ...state,
        villageBanks: action.data,
        loading: false,
      }
    case applicationConstants.VILLAGE_BANKS_FAILURE:
      return {
        ...state,
        villageBanks: [],
        loading: false,
      }

    case applicationConstants.ADD_VILLAGE_BANK_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case applicationConstants.ADD_VILLAGE_BANK_SUCCESS: {
      return { ...state, loading: false }
    }
    case applicationConstants.ADD_VILLAGE_BANK_FAILURE: {
      return { ...state, loading: false, error: action.error }
    }
    case applicationConstants.GET_ENTITY_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case applicationConstants.GET_ENTITY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        entityStatusList: action.data,
        error: null,
      }
    case applicationConstants.GET_ENTITY_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        entityStatusList: {},
        error: action.payload,
      }
    case applicationConstants.USERS_TO_ASSIGN_REQUEST:
      return { ...state, loading: true }

    case applicationConstants.USERS_TO_ASSIGN_SUCCESS:
      return { ...state, loading: false, usersToAssign: action.data }

    case applicationConstants.USERS_TO_ASSIGN_FAILURE:
      return {
        ...state,
        usersToAssign: {},
        loading: false,
        error: action.error,
      }

    case applicationConstants.REPAYMENT_SCHEDULE_SUCCESS:
      return { ...state, repaymentSchedule: action.data }

    case applicationConstants.REPAYMENT_SCHEDULE_FAILURE:
      return {
        ...state,
        repaymentSchedule: [],
        error: action.error,
      }
  }
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup } from 'shards-react';
import { history } from '../../../../_helpers';


export function ManageCancelActions({ mfi }) {
    const { t } = useTranslation();
    const RedirectToParent = () => {
        history.push('/mfis');
    };

    return (
        <FormGroup className="pb-10 action-group application-action-group">
            <div className="inline-flex">
                <Button theme="secondary" className="mx-2 d-table ml-auto btn" type="submit" onClick={ () => RedirectToParent() }>
                    {t('module.mfiSetup.actions.cancel')}
                </Button>
            </div>
        </FormGroup>
    );
}

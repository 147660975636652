export default () => ({
  listContainer: {
    margin: 10,
  },
  rowRoot: {
    height: 60,
  },
  buttonRoot: {
    color: "#FF2E2E",
  },
  imgWidth: {
    width: 124,
  },
})

import React, { useEffect } from "react"
import { Button } from "shards-react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { GroupsTable } from "./GroupsTable"
import { modalActions } from "../../../modals"
import { mfiManagerActions } from "../.."

export function ManageGroups({ id: mfiId, mfi }) {
  const { mfiCode } = mfi
  const dispatch = useDispatch()
  const { groups = [] } = useSelector((store) => store.mfis)
  const { t } = useTranslation()

  const tableGroups = groups.map((item) => ({ ...{ action: "" }, ...item }))

  useEffect(() => {
    dispatch(mfiManagerActions.getWorkflowGroups(mfiId))
  }, [dispatch, mfiId])

  return (
    <>
      <GroupsTable data={tableGroups} />
      <Button
        className="m-4"
        onClick={() => {
          dispatch(
            modalActions.manageWorkflowGroups({
              mfiId,
              mfiCode,
              title: t("module.mfiSetup.workflow.title"),
            })
          )
        }}
      >
        {t("module.mfiSetup.workflow.addWorkflow")}
      </Button>
    </>
  )
}

import { authenticationConstants, authenticationService } from "."

import { kobotoolkitActions } from "../kobotoolkit"
import { settingsActions } from "../settings"
import { alertActions } from "../alert"

// import { userRoleConstants } from '../../_helpers';

export const authenticationActions = {
  login,
  logout,
  refreshToken,
  rememberMe,
  getProfile,
  resetPassword,
  forgotPassword,
  forgotPasswordSMS,
  resetPasswordSms,
}

function login(username, password, redirect) {
  return (dispatch) => {
    dispatch(request({ username }))
    try {
      authenticationService
        .login(username, password)
        .then((user) => {
          dispatch(success(user))
          return user
        })
        .then((user) => {
          window.location.href = redirect
        })
        .catch((error) => {
          dispatch(
            failure(
              error?.response?.data?.errors && error?.response?.data?.errors[0]
            )
          )
          // error here
          dispatch(alertActions.error(error.response.data.errors[0]))
        })
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request(user) {
    return { type: authenticationConstants.USERS_LOGIN_REQUEST, user }
  }
  function success(user) {
    return { type: authenticationConstants.USERS_LOGIN_SUCCESS, user }
  }
  function failure(error) {
    return { type: authenticationConstants.USERS_LOGIN_FAILURE, error }
  }
}

function resetPassword(data) {
  return (dispatch) => {
    try {
      authenticationService
        .resetPassword(data)
        .then(() => {
          dispatch(alertActions.success("Password Reset Succesful"))
          window.location.href = "/login"
        })
        .catch((error) => {
          dispatch(alertActions.error(error))
        })
    } catch (error) {
      dispatch(alertActions.error(error))
    }
  }
}

function resetPasswordSms(data) {
  return (dispatch) => {
    try {
      authenticationService
        .resetPasswordSms(data)
        .then(() => {
          dispatch(alertActions.success("Password Reset Succesful"))
          window.location.href = "/login"
        })
        .catch((error) => {
          dispatch(alertActions.error(error))
        })
    } catch (error) {
      dispatch(alertActions.error(error))
    }
  }
}

function forgotPassword(data) {
  return (dispatch) => {
    try {
      authenticationService
        .forgotPassword(data)
        .then(() => {
          dispatch(success())
        })
        .catch((error) => {
          dispatch(alertActions.error(error))
        })
    } catch (error) {
      dispatch(alertActions.error(error))
    }
  }

  function success() {
    return { type: authenticationConstants.USER_RESET_LINK_SUCCESS }
  }
}

function forgotPasswordSMS(data) {
  return (dispatch) => {
    try {
      authenticationService
        .forgotPasswordSMS(data)
        .then(() => {
          dispatch(success())
          dispatch(
            alertActions.success("An OTP Code has been sent to your phone")
          )
        })
        .catch((error) => {
          dispatch(alertActions.error(error))


        })
    } catch (error) {
      dispatch(alertActions.error(error))
    }
  }

  function success() {
    return { type: authenticationConstants.USER_RESET_SMS_SUCCESS }
  }
}

function logout() {
  return { type: authenticationConstants.USER_LOGOUT }
}

function rememberMe(userData) {
  return (dispatch) => {
    const boolData = Boolean(userData)
    dispatch(success(boolData))
    authenticationService.rememberMe(boolData)
  }
  function success(data) {
    return { type: authenticationConstants.USER_LOGIN_REMEMBER_ME, data }
  }
}

function refreshToken(token) {
  return (dispatch) => {
    dispatch(request({}))

    try {
      authenticationService.refreshToken(token).then(
        (data) => {
          dispatch(success(data))
          dispatch(kobotoolkitActions.getAccessToken())
        },
        (error) => {
          dispatch(failure(error))
          // error here
          dispatch(alertActions.error(error.response.data.message))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request(user) {
    return { type: authenticationConstants.USER_TOKEN_REFRESH_REQUEST, user }
  }
  function success(user) {
    return { type: authenticationConstants.USER_TOKEN_REFRESH_SUCCESS, user }
  }
  function failure(error) {
    return { type: authenticationConstants.USER_TOKEN_REFRESH_FAILURE, error }
  }
}

function getProfile() {
  return (dispatch) => {
    dispatch(request({}))
    authenticationService.getProfile().then(
      (data) => {
        dispatch(success(data))
        // set currency
        dispatch(settingsActions.setCurrency(data.mfiCurrency))
      },
      (error) => {
        dispatch(failure(error))
        // error here
        dispatch(alertActions.error(error.response.data.message))
      }
    )
  }

  function request(data) {
    return { type: authenticationConstants.USER_PROFILE_REQUEST, data }
  }
  function success(data) {
    return { type: authenticationConstants.USER_PROFILE_SUCCESS, data }
  }
  function failure(error) {
    return { type: authenticationConstants.USER_PROFILE_FAILURE, error }
  }
}

import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import PersonOutlineOutlinedIcon from "@material-ui/icons/FilterList"
import { ButtonGroup, Button, FormGroup, FormSelect } from "shards-react"
import { FormWithConstraints } from "react-form-with-constraints-bootstrap4"
import { useTranslation } from "react-i18next"
import Header from "../../../components/widget/widgetTitle"
import "./OfficeFilter.scss"

import { userRoleConstants, hasAuthority } from "../../../_helpers"
import { reportsActions } from ".."
import { modalActions } from "../../modals"
import { userManagerActions } from "../../userManager"
import { mfiManagerActions } from "../../mfiManager"

export function OfficeFilter({ modalID, data }) {
  const { surveyUuid, byOfficer } = data
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    dateInputs,
    hierarchyEntityIdInput,
    mfiIdInput,
    hierarchy,
    submittedBy,
  } = useSelector((store) => store.reports)
  const { items: mfiList, subRoles } = useSelector((store) => store.mfis)
  const { profile } = useSelector((store) => store.authentication)
  const loUsers = useSelector((state) => state.users.loItems)

  const [hierarchyLayers, setHierarchyLayers] = useState([])
  const [hierarchyLayerActive, setHierarchyLayerActive] = useState("")
  const [officeActive, setOfficeActive] = useState("")
  const [officeConfig, setOfficeConfig] = useState([])
  const [selectedOfficer, setSelectedOfficer] = useState("")
  const [officerConfig, setOfficerConfig] = useState([])

  const [from, setFrom] = useState()
  const [to, setTo] = useState()

  const [selectMfi, setSelectMfi] = useState(false)
  const [loading, setLoading] = useState(false)
  const [reseting, setReseting] = useState(false)

  useEffect(() => {
    if (byOfficer && officeConfig.length > 0 && subRoles.length > 0) {
      const loSubrole = subRoles.filter((sr) => sr.role === "MFI_LOAN_OFFICER")
      const hierarchyEntityIds = officeConfig.map((cf) => cf.id)
      if (hierarchyEntityIds && hierarchyEntityIds.length > 0) {
        const subRoleId = Number(loSubrole[0].id)
        const data = {
          mfiId: profile.mfi.id,
          subRoleId,
          hierarchyEntityIds,
        }
        dispatch(userManagerActions.getLoanOfficersByHierarchy(data))
      }
    }
  }, [byOfficer, subRoles, officeConfig, profile.mfi.id, dispatch])

  useEffect(() => {
    if (byOfficer) {
      if (officeActive === "All" || !officeActive) setOfficerConfig(loUsers)
      else {
        setOfficerConfig(
          loUsers?.filter(
            (user) => user.hierarchyEntityId === Number(officeActive)
          )
        )
      }
    }
  }, [officeActive, byOfficer, loUsers])

  useEffect(() => {
    if (byOfficer && submittedBy) {
      setSelectedOfficer(submittedBy)
    }
  }, [submittedBy, byOfficer])

  useEffect(() => {
    if (byOfficer) {
      dispatch(mfiManagerActions.getMFISubRoles(profile.mfi.id))
    }
  }, [byOfficer, dispatch, profile.mfi.id])

  useEffect(() => {
    if (dateInputs.startDate) {
      setFrom(new Date(dateInputs.startDate))
      setTo(new Date(dateInputs.endDate))
    } else {
      setFrom(null)
      setTo(null)
    }
  }, [dateInputs])

  useEffect(() => {
    if (hierarchyEntityIdInput && hierarchy) {
      setOfficeActive(hierarchyEntityIdInput)
      hierarchy.map((item, i) => {
        const hierarchyInput = item.entities.filter(
          (entity, i) => entity.id === Number(hierarchyEntityIdInput)
        )
        if (hierarchyInput.length > 0) {
          setHierarchyLayerActive(item.id.toString())
        }
        return item
      })
    }
  }, [hierarchyEntityIdInput, hierarchy])

  useEffect(() => {
    // get hierarchy data.
    if (
      hasAuthority(userRoleConstants.FA_ANALYST) ||
      hasAuthority(userRoleConstants.FA_ADMIN)
    ) {
      if (mfiIdInput) {
        dispatch(reportsActions.getHierarchy(mfiIdInput))
      } else {
        setSelectMfi(true)
      }
    } else if (profile.mfi) {
      dispatch(reportsActions.getHierarchy(profile.mfi.id))
    }
  }, [dispatch, profile, mfiList, mfiIdInput])

  useEffect(() => {
    // populate hierarchy to be displayed
    if (hierarchy) {
      const hierarchyLayerUpdated = []
      hierarchy.map((item) =>
        hierarchyLayerUpdated.push({ name: item.name, id: item.id })
      )
      setHierarchyLayers(hierarchyLayerUpdated)
    }
  }, [hierarchy])

  useEffect(() => {
    // populate entities/offices to be displayed
    if (hierarchyLayerActive) {
      const selectedHierarchyLayer = hierarchy.filter(
        (item, i) => item.id === Number(hierarchyLayerActive)
      )
      if (selectedHierarchyLayer && selectedHierarchyLayer.length > 0) {
        setOfficeConfig(selectedHierarchyLayer[0].entities)
      }
    }
  }, [hierarchyLayerActive, hierarchy])

  useEffect(() => {
    if (byOfficer && hierarchy) {
      const hierarchyLayer = hierarchy.filter(
        (item, i) => item.name === "Branches"
      )
      if (hierarchyLayer && hierarchyLayer.length > 0) {
        setOfficeConfig(hierarchyLayer[0].entities)
      }
    }
  }, [byOfficer, hierarchy])

  const resetInputs = () => {
    setReseting(true)
    dispatch(reportsActions.resetHierarchyInputs())

    let payload = {
      mfiId: mfiIdInput,
      surveyUuid,
    }

    if (from || to) {
      const start = from.toLocaleDateString().split("/")
      const end = to.toLocaleDateString().split("/")
      payload.startDate = `${start[2]}-${start[1]}-${start[0]}`
      payload.endDate = `${end[2]}-${end[1]}-${end[0]}`
    }

    dispatch(reportsActions.filterReports(payload))

    setOfficeActive("")
    setHierarchyLayerActive("")
    setTimeout(() => {
      setReseting(false)
      closeModal()
    }, 900)
  }

  const handleFilter = () => {
    setLoading(true)
    let payload = {
      mfiId: mfiIdInput,
      surveyUuid,
    }

    if (!byOfficer) {
      payload.hierarchyEntityId = Number(officeActive)
    }

    if (byOfficer) {
      payload.submittedBy = selectedOfficer
    }

    if (from || to) {
      const start = from.toLocaleDateString().split("/")
      const end = to.toLocaleDateString().split("/")
      payload.startDate = `${start[2]}-${start[1]}-${start[0]}`
      payload.endDate = `${end[2]}-${end[1]}-${end[0]}`
    }

    dispatch(reportsActions.filterReports(payload))
    setTimeout(() => {
      setLoading(false)
      closeModal()
    }, 900)
  }

  const closeModal = () => dispatch(modalActions.hideModal(modalID))

  return (
    <div className="site-modal">
      <Header
        title={
          byOfficer
            ? t("module.report.filterByLoanOfficer")
            : t("module.report.filterByOffice")
        }
        icon={<PersonOutlineOutlinedIcon />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        <FormWithConstraints noValidate className="shadow-none">
          {selectMfi && (
            <p className="mfi-error">
              {t("module.report.selectMfiToContinue")}
            </p>
          )}
          {!byOfficer && (
            <FormGroup>
              <label htmlFor="role">{t("module.report.hierarchy")}</label>
              <FormSelect
                name="role"
                value={hierarchyLayerActive}
                onChange={(e) => setHierarchyLayerActive(e.currentTarget.value)}
              >
                <option key={-1} value="">
                  {t("module.report.selectHierarchy")}
                </option>
                {hierarchyLayers.map((item, idx) => (
                  <option key={idx} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </FormSelect>
            </FormGroup>
          )}
          {!byOfficer && (
            <FormGroup>
              <label htmlFor="role">{t("module.report.office")}</label>
              <FormSelect
                name="role"
                value={officeActive}
                onChange={(e) => setOfficeActive(e.currentTarget.value)}
              >
                <option key={-1} value="">
                  {t("module.report.selectOffice")}
                </option>
                {officeConfig.map((item, idx) => (
                  <option key={idx} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </FormSelect>
            </FormGroup>
          )}
          {byOfficer && (
            <FormGroup>
              <label htmlFor="role">{t("module.report.office")}</label>
              <FormSelect
                name="role"
                value={officeActive}
                onChange={(e) => setOfficeActive(e.currentTarget.value)}
              >
                <option key={-1} value="All">
                  All
                </option>
                {officeConfig.map((item, idx) => (
                  <option key={idx} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </FormSelect>
            </FormGroup>
          )}
          {byOfficer && (
            <FormGroup>
              <label htmlFor="role">{t("module.report.officer")}</label>
              <FormSelect
                name="officer"
                value={selectedOfficer}
                onChange={(e) => setSelectedOfficer(e.currentTarget.value)}
              >
                <option key={-1} value="">
                  {t("module.report.selectOfficer")}
                </option>
                {officerConfig?.map((item) => (
                  <option key={item.id} value={item.username}>
                    {item.username}
                  </option>
                ))}
              </FormSelect>
            </FormGroup>
          )}
          <div className="btn-group-actions site-modal__actions flex relative">
            <ButtonGroup className="btn-group-actions site-modal__actions">
              <Button
                theme="primary"
                className="thin mr-2"
                onClick={() => handleFilter()}
                disabled={selectMfi || (!selectedOfficer && byOfficer)}
              >
                {loading
                  ? t("module.report.filtering")
                  : t("module.report.filter")}
              </Button>
              <Button
                theme="secondary"
                className="thin delete"
                onClick={() => resetInputs()}
                disabled={selectMfi}
              >
                {reseting
                  ? t("module.report.reseting")
                  : t("module.report.reset")}
              </Button>
            </ButtonGroup>
          </div>
        </FormWithConstraints>
      </div>
    </div>
  )
}

import React from "react"
import { Icon } from "@material-ui/core"
import DownloadIcon from "../../../assets/images/icons/download-icon.svg"

export const CustomIcon = () => {
  return (
    <Icon>
      <img src={DownloadIcon} />
    </Icon>
  )
}

export const mpesaScoreCodes = [
  {
    name: "LTL Risk",
    code: "LTL Risk",
  },
  {
    name: "ML Risk",
    code: "ML Risk",
  },
]

import React, { Component } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from 'shards-react';

import { connect } from 'react-redux';
import { modalActions } from '../../modals';
import { userRoleConstants, hasAuthority } from '../../../_helpers';
import { withTranslation } from 'react-i18next';

class TableRowsActions extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);

        this.state = {
            id: props.id,
            actionsMenuOpen: false,
        };
    }

    toggle(which) {
        const newState = { ...this.state };
        newState[which] = !this.state[which];
        this.setState(newState);
    }

    activateUser() {
        this.props.dispatch(
            modalActions.activateUser(this.props.id),
        );
    }

    deactivateUser() {
        this.props.dispatch(
            modalActions.deactivateUser(this.props.id),
        );
    }

    render() {
        const { id, status, roles, t } = this.props;
        let disabled = false;

        if (roles) {
          if (roles.includes(userRoleConstants.FA_ANALYST) || roles.includes(userRoleConstants.FA_ADMIN)) {
              disabled = true;
          }
        }

        return (
            <Dropdown
                open={this.state.actionsMenuOpen}
                toggle={() => this.toggle('actionsMenuOpen')}
                disabled={disabled}
            >
                <DropdownToggle caret>Actions</DropdownToggle>
                <DropdownMenu small>
                  {
                    !(hasAuthority(userRoleConstants.FA_ANALYST) || hasAuthority(userRoleConstants.FA_ADMIN)) && (
                      <>
                        <div className="dropdown-item">
                            <a href={ `/users/edit/${id}` }>{t('module.users.userAccounts.edit')}</a>
                        </div>
                        <DropdownItem divider />
                      </>
                    )
                  }

                    {(status === 'inactive' || status === 'inactif') && <div className="dropdown-item">
                            <div onClick={ () => { this.activateUser(); } }>{t('module.users.userAccounts.activate')}</div>
                        </div>
                    }

                    {(status === 'active' || status === 'actif') && <div className="dropdown-item">
                            <div onClick={ () => { this.deactivateUser(); } }>{t('module.users.userAccounts.deactivate')}</div>
                        </div>
                    }
                </DropdownMenu>
            </Dropdown>
        );
    }
}

const mapStateToProps = (state) => {
    const { users } = state;
    return {
        users,
    };
};

const connectedtableRowsActions = withTranslation()(connect(mapStateToProps)(TableRowsActions));
export { connectedtableRowsActions as TableRowsActions };

import uuid from 'uuid';
import { alertConstants } from './_constants';

export const alerts = (state = [], action) => {
    switch (action.type) {
    case alertConstants.SUCCESS:
        return [
            ...state,
            {
                message: action.message,
                type: alertConstants.SUCCESS,
                id: uuid(),
            },
        ];

    case alertConstants.ERROR:
        return [
            ...state,
            {
                message: action.message,
                type: alertConstants.ERROR,
                id: uuid(),
            },
        ];

    case alertConstants.INFO:
        return [
            ...state,
            {
                message: action.message,
                type: alertConstants.INFO,
                id: uuid(),
            },
        ];    

    case alertConstants.REMOVE:
        return state.filter((alert) => {
            if (alert.id === action.id) {
                return false;
            }
            return true;
        });

    case alertConstants.CLEAR:
        return [];
    default:
        return state;
    }
};

export const mfiManagerConstants = {
  /** MFIS */
  GETALL_REQUEST: "MFIMANAGER_GETALL_REQUEST",
  GETALL_SUCCESS: "MFIMANAGER_GETALL_SUCCESS",
  GETALL_FAILURE: "MFIMANAGER_GETALL_FAILURE",

  CREATE_MFI_REQUEST: "CREATE_MFI_REQUEST",
  CREATE_MFI_SUCCESS: "CREATE_MFI_SUCCESS",
  CREATE_MFI_FAILURE: "CREATE_MFI_FAILURE",

  /** SUBROLES */
  MFI_SUBROLES_GETALL_REQUEST: "MFI_SUBROLES_GETALL_REQUEST",
  MFI_SUBROLES_GETALL_SUCCESS: "MFI_SUBROLES_GETALL_SUCCESS",
  MFI_SUBROLES_GETALL_FAILURE: "MFI_SUBROLES_GETALL_FAILURE",

  MFI_SUBROLES_ADD_REQUEST: "MFI_SUBROLES_ADD_REQUEST",
  MFI_SUBROLES_ADD_SUCCESS: "MFI_SUBROLES_ADD_SUCCESS",
  MFI_SUBROLES_ADD_FAILURE: "MFI_SUBROLES_ADD_FAILURE",

  MFI_SUBROLES_EDIT_REQUEST: "MFI_SUBROLES_EDIT_REQUEST",
  MFI_SUBROLES_EDIT_SUCCESS: "MFI_SUBROLES_EDIT_SUCCESS",
  MFI_SUBROLES_EDIT_FAILURE: "MFI_SUBROLES_EDIT_FAILURE",

  MFI_SUBROLE_DELETE_REQUEST: "MFI_SUBROLE_DELETE_REQUEST",
  MFI_SUBROLE_DELETE_SUCCESS: "MFI_SUBROLE_DELETE_SUCCESS",
  MFI_SUBROLE_DELETE_FAILURE: "MFI_SUBROLE_DELETE_FAILURE",

  MFI_SUBROLES_LIMITS_GETALL_REQUEST: "MFI_SUBROLES_LIMITS_GETALL_REQUEST",
  MFI_SUBROLES_LIMITS_GETALL_SUCCESS: "MFI_SUBROLES_LIMITS_GETALL_SUCCESS",
  MFI_SUBROLES_LIMITS_GETALL_FAILURE: "MFI_SUBROLES_LIMITS_GETALL_FAILURE",

  MFI_SUBROLES_LIMIT_ADD_SUCCESS: "MFI_SUBROLES_LIMIT_ADD_SUCCESS",

  MFI_SUBROLES_LIMIT_DELETE_REQUEST: "MFI_SUBROLES_LIMIT_DELETE_REQUEST",
  MFI_SUBROLES_LIMIT_DELETE_SUCCESS: "MFI_SUBROLES_LIMIT_DELETE_SUCCESS",
  MFI_SUBROLES_LIMIT_DELETE_FAILURE: "MFI_SUBROLES_LIMIT_DELETE_FAILURE",

  MFI_SUBROLES_LIMIT_FLOW_ADD_SUCCESS: "MFI_SUBROLES_LIMIT_FLOW_ADD_SUCCESS",

  MFI_SUBROLES_LIMIT_FLOW_UPDATE_REQUEST:
    "MFI_SUBROLES_LIMIT_FLOW_UPDATE_REQUEST",
  MFI_SUBROLES_LIMIT_FLOW_UPDATE_SUCCESS:
    "MFI_SUBROLES_LIMIT_FLOW_UPDATE_SUCCESS",
  MFI_SUBROLES_LIMIT_FLOW_UPDATE_FAILURE:
    "MFI_SUBROLES_LIMIT_FLOW_UPDATE_FAILURE",

  MFI_SUBROLES_LIMIT_UPDATE_SUCCESS: "MFI_SUBROLES_LIMIT_UPDATE_SUCCESS",

  MFI_SUBROLES_LIMITS_UPDATE_REQUEST: "MFI_SUBROLES_LIMITS_UPDATE_REQUEST",

  MFI_SUBROLES_LIMITS_ADD_REQUEST: "MFI_SUBROLES_LIMITS_ADD_REQUEST",

  MFI_SUBROLES_LIMIT_FLOW_SUBMIT_REQUEST:
    "MFI_SUBROLES_LIMIT_FLOW_SUBMIT_REQUEST",
  MFI_SUBROLES_LIMIT_FLOW_SUBMIT_FAILURE:
    "MFI_SUBROLES_LIMIT_FLOW_SUBMIT_FAILURE",

  REFRESH_TABLE: "REFRESH_TABLE",

  MFI_GROUPS_ADD_REQUEST: "MFI_GROUPS_ADD_REQUEST",
  MFI_GROUPS_ADD_SUCCESS: "MFI_GROUPS_ADD_SUCCESS",
  MFI_GROUPS_ADD_FAILURE: "MFI_GROUPS_ADD_FAILURE",

  APP_LOAN_DISTRIBUTED_ADD_REQUEST: "APP_LOAN_DISTRIBUTED_ADD_REQUEST",
  APP_LOAN_DISTRIBUTED_ADD_SUCCESS: "APP_LOAN_DISTRIBUTED_ADD_SUCCESS",
  APP_LOAN_DISTRIBUTED_ADD_FAILURE: "APP_LOAN_DISTRIBUTED_ADD_FAILURE",

  APP_LOAN_DISTRIBUTED_GET_REQUEST: "APP_LOAN_DISTRIBUTED_GET_REQUEST",
  APP_LOAN_DISTRIBUTED_GET_SUCCESS: "APP_LOAN_DISTRIBUTED_GET_SUCCESS",
  APP_LOAN_DISTRIBUTED_GET_FAILURE: "APP_LOAN_DISTRIBUTED_GET_FAILURE",

  APP_LOAN_DISTRIBUTED_EDIT_REQUEST: "APP_LOAN_DISTRIBUTED_EDIT_REQUEST",
  APP_LOAN_DISTRIBUTED_EDIT_SUCCESS: "APP_LOAN_DISTRIBUTED_EDIT_SUCCESS",
  APP_LOAN_DISTRIBUTED_EDIT_FAILURE: "APP_LOAN_DISTRIBUTED_EDIT_FAILURE",

  MFI_MENUS_ADD_REQUEST: "MFI_MENUS_ADD_REQUEST",
  MFI_MENUS_ADD_SUCCESS: "MFI_MENUS_ADD_SUCCESS",
  MFI_MENUS_ADD_FAILURE: "MFI_MENUS_ADD_FAILURE",

  MFI_GROUPS_GETALL_REQUEST: "MFI_GROUPS_GETALL_REQUEST",
  MFI_GROUPS_GETALL_SUCCESS: "MFI_GROUPS_GETALL_SUCCESS",
  MFI_GROUPS_GETALL_FAILURE: "MFI_GROUPS_GETALL_FAILURE",

  MFI_MENUS_GETALL_REQUEST: "MFI_MENUS_GETALL_REQUEST",
  MFI_MENUS_GETALL_SUCCESS: "MFI_MENUS_GETALL_SUCCESS",
  MFI_MENUS_GETALL_FAILURE: "MFI_MENUS_GETALL_FAILURE",

  MFI_MENUS_EDIT_REQUEST: "MFI_MENUS_EDIT_REQUEST",
  MFI_MENUS_EDIT_SUCCESS: "MFI_MENUS_EDIT_SUCCESS",
  MFI_MENUS_EDIT_FAILURE: "MFI_MENUS_EDIT_FAILURE",

  MPESA_DETAILS_ADD_REQUEST: "MPESA_DETAILS_ADD_REQUEST",
  MPESA_DETAILS_ADD_SUCCESS: "MPESA_DETAILS_ADD_SUCCESS",
  MPESA_DETAILS_ADD_FAILURE: "MPESA_DETAILS_ADD_FAILURE",

  MPESA_DETAILS_EDIT_REQUEST: "MPESA_DETAILS_EDIT_REQUEST",
  MPESA_DETAILS_EDIT_SUCCESS: "MPESA_DETAILS_EDIT_SUCCESS",
  MPESA_DETAILS_EDIT_FAILURE: "MPESA_DETAILS_EDIT_FAILURE",

  MPESA_DETAILS_GETALL_REQUEST: "MPESA_DETAILS_GETALL_REQUEST",
  MPESA_DETAILS_GETALL_SUCCESS: "MPESA_DETAILS_GETALL_SUCCESS",
  MPESA_DETAILS_GETALL_FAILURE: "MPESA_DETAILS_GETALL_FAILURE",

  MFI_GROUPS_EDIT_REQUEST: "MFI_GROUPS_EDIT_REQUEST",
  MFI_GROUPS_EDIT_SUCCESS: "MFI_GROUPS_EDIT_SUCCESS",
  MFI_GROUPS_EDIT_FAILURE: "MFI_GROUPS_EDIT_FAILURE",

  MFI_WORKFLOW_FILTER_GROUPS: "MFI_WORKFLOW_FILTER_GROUPS",

  GET_HIERARCHY_REQUEST: "GET_HIERARCHY_REQUEST",
  GET_HIERARCHY_SUCCESS: "GET_HIERARCHY_SUCCESS",
  GET_HIERARCHY_FAILURE: "GET_HIERARCHY_FAILURE",

  EDIT_HIERARCHY_ENTITY_REQUEST: "EDIT_HIERARCHY_ENTITY_REQUEST",
  EDIT_HIERARCHY_ENTITY_SUCCESS: "EDIT_HIERARCHY_ENTITY_SUCCESS",
  EDIT_HIERARCHY_ENTITY_FAILURE: "EDIT_HIERARCHY_ENTITY_FAILURE",

  EDIT_HIERARCHY_LAYER_REQUEST: "EDIT_HIERARCHY_LAYER_REQUEST",
  EDIT_HIERARCHY_LAYER_SUCCESS: "EDIT_HIERARCHY_LAYER_SUCCESS",
  EDIT_HIERARCHY_LAYER_FAILURE: "EDIT_HIERARCHY_LAYER_FAILURE",

  ADD_HIERARCHY_ENTITY_REQUEST: "ADD_HIERARCHY_ENTITY_REQUEST",
  ADD_HIERARCHY_ENTITY_SUCCESS: "ADD_HIERARCHY_ENTITY_SUCCESS",
  ADD_HIERARCHY_ENTITY_FAILURE: "ADD_HIERARCHY_ENTITY_FAILURE",

  ADD_HIERARCHY_LAYER_REQUEST: "ADD_HIERARCHY_LAYER_REQUEST",
  ADD_HIERARCHY_LAYER_SUCCESS: "ADD_HIERARCHY_LAYER_SUCCESS",
  ADD_HIERARCHY_LAYER_FAILURE: "ADD_HIERARCHY_LAYER_FAILURE",

  MFI_ROLES_GETALL_REQUEST: "MFI_ROLES_GETALL_REQUEST",
  MFI_ROLES_GETALL_SUCCESS: "MFI_ROLES_GETALL_SUCCESS",
  MFI_ROLES_GETALL_FAILURE: "MFI_ROLES_GETALL_FAILURE",

  GET_QUESTIONNAIRES_REQUEST: "GET_QUESTIONNAIRES_REQUEST",
  GET_QUESTIONNAIRES_SUCCESS: "GET_QUESTIONNAIRES_SUCCESS",
  GET_QUESTIONNAIRES_FAILURE: "GET_QUESTIONNAIRES_FAILURE",

  GET_MFI_IMAGES_REQUEST: "GET_MFI_IMAGES_REQUEST",
  GET_MFI_IMAGES_SUCCESS: "GET_MFI_IMAGES_SUCCESS",
  GET_MFI_IMAGES_FAILURE: "GET_MFI_IMAGES_FAILURE",

  ADD_QUESTIONNAIRE_REQUEST: "ADD_QUESTIONNAIRE_REQUEST",
  ADD_QUESTIONNAIRE_SUCCESS: "ADD_QUESTIONNAIRE_SUCCESS",
  ADD_QUESTIONNAIRE_FAILURE: "ADD_QUESTIONNAIRE_FAILURE",

  EDIT_QUESTIONNAIRE_REQUEST: "EDIT_QUESTIONNAIRE_REQUEST",
  EDIT_QUESTIONNAIRE_SUCCESS: "EDIT_QUESTIONNAIRE_SUCCESS",
  EDIT_QUESTIONNAIRE_FAILURE: "EDIT_QUESTIONNAIRE_FAILURE",

  DELETE_QUESTIONNAIRE_REQUEST: "DELETE_QUESTIONNAIRE_REQUEST",
  DELETE_QUESTIONNAIRE_SUCCESS: "DELETE_QUESTIONNAIRE_SUCCESS",
  DELETE_QUESTIONNAIRE_FAILURE: "DELETE_QUESTIONNAIRE_FAILURE",

  DEACTIVATE_QUESTIONNAIRE_REQUEST: "DEACTIVATE_QUESTIONNAIRE_REQUEST",
  DEACTIVATE_QUESTIONNAIRE_SUCCESS: "DEACTIVATE_QUESTIONNAIRE_SUCCESS",
  DEACTIVATE_QUESTIONNAIRE_FAILURE: "DEACTIVATE_QUESTIONNAIRE_FAILURE",

  RESET_QUESTIONNAIRE_REQUEST: "RESET_QUESTIONNAIRE_REQUEST",
  RESET_QUESTIONNAIRE_SUCCESS: "RESET_QUESTIONNAIRE_SUCCESS",
  RESET_QUESTIONNAIRE_FAILURE: "RESET_QUESTIONNAIRE_FAILURE",

  ADD_EXTERNAL_INTERGRATION_REQUEST: "ADD_EXTERNAL_INTEGRATION_REQUEST",
  ADD_EXTERNAL_INTERGRATION_SUCCESS: "ADD_EXTERNAL_INTEGRATION_SUCCESS",
  ADD_EXTERNAL_INTERGRATION_FAILURE: "ADD_EXTERNAL_INTEGRATION_FAILURE",

  EDIT_EXTERNAL_INTERGRATION_REQUEST: "EDIT_EXTERNAL_INTEGRATION_REQUEST",
  EDIT_EXTERNAL_INTERGRATION_SUCCESS: "EDIT_EXTERNAL_INTEGRATION_SUCCESS",
  EDIT_EXTERNAL_INTERGRATION_FAILURE: "EDIT_EXTERNAL_INTEGRATION_FAILURE",

  GET_EXTERNAL_INTERGRATION_REQUEST: "GET_EXTERNAL_INTEGRATION_REQUEST",
  GET_EXTERNAL_INTERGRATION_SUCCESS: "GET_EXTERNAL_INTEGRATION_SUCCESS",
  GET_EXTERNAL_INTERGRATION_FAILURE: "GET_EXTERNAL_INTEGRATION_FAILURE",
}

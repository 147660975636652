import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import uuid from 'uuid';
import { FormGroup, Button } from 'shards-react';
import { useTranslation } from 'react-i18next';
import {
    FormWithConstraints, Input, Async, FieldFeedbacks, FieldFeedback,
} from 'react-form-with-constraints-bootstrap4';
import { cslActions } from '../../_actions';

function AddTDCV({id, type, selection, scoreLogic, expression, resultHandler, resultStatus: resultError, dispatch}) {
    const { uid, formulas } = scoreLogic;
    const form = useRef(null);
    const code = useRef(null);
    const { t } = useTranslation();

    let typetitle = 'TDCV';

    if (type === 'EVERY_MONTH_CALCULATED_VARIABLE') {
        typetitle = t('module.scoreLogic.component.emcv.emcv');
    } else if (type === 'SINGLE_CALCULATION') {
        typetitle = t('module.scoreLogic.component.emcv.formula');
    }

    function getInitialInputsState() {
        return {
            code: '',
            name: '',
            expression: '',
        };
    }

    const [inputs, setInputs] = useState(getInitialInputsState());
    const [resetButtonDisabled, setResetButtonDisabled] = useState(false);
    const [changeField, setChangeField] = useState(false);

    useEffect(() => {
        setInputs((prevState) => ({ ...prevState, expression }));
        shouldDisableResetButton();
    }, [expression]);

    async function checkCodeAvailability(value) {
        return isEmpty(formulas.filter((item) => item.surveyUuid === uid && item.code.toUpperCase() === value.toUpperCase() ))
    }

    function shouldDisableResetButton() {
        return setResetButtonDisabled(false);
    }

    const handleChange = async ({ target }) => {
        setChangeField(true);
        const { name, value } = target;
        setInputs((prevState) => ({ ...prevState, [name]: value }));

        await form.current.validateFields(target);

        shouldDisableResetButton();
    };

    async function handleReset() {
        setChangeField(false);
        setInputs(getInitialInputsState());
        form.current.resetFields();
        shouldDisableResetButton();
    }

    async function handleSubmit(e) {
        e.preventDefault();

        await form.current.validateForm();
        const formIsValid = form.current.isValid();

        shouldDisableResetButton();

        if (!formIsValid || isEmpty(inputs.expression)) {
            return false;
        }

        const data = {
            id: uuid(),
            name: inputs.name,
            expression: inputs.expression,
            code: inputs.code,
            surveyUuid: uid,
            type,
        };

        if (type === 'TIME_DEPENDENCY_FLAG') {
            data.type = 'TD_TO_TIME_DEPENDENCY_FLAG';
            data.parentFormulaCode = id;
        }

        dispatch(cslActions.addFormula([data]));
        resultHandler();
        handleReset();

        return false;
    }

    // https://stackoverflow.com/questions/23774231/how-do-i-remove-all-null-and-empty-string-values-from-a-json-object/23774287
    const removeEmpty = (obj) => {
        // eslint-disable-next-line no-param-reassign
        Object.keys(obj).forEach((k) => (!obj[k] && obj[k] !== undefined) && delete obj[k]);
        return obj;
    };

    return (
        <FormWithConstraints ref={form} noValidate className="shadow-none">
            <div className="add-tdcv px-6">
                <FormGroup>
                    <div className="flex">
                        <label>{typetitle} {t('module.scoreLogic.view.formula.addTDCV.code')}</label>
                        <Input
                            innerRef={code}
                            type="text"
                            className="form-control"
                            name="code"
                            required
                            value={inputs.code}
                            onChange={handleChange}
                        />
                    </div>
                    <FieldFeedbacks for="code">
                        <FieldFeedback when="*" />
                        <Async
                            promise={checkCodeAvailability}
                            pending={<span className="d-block">...</span>}
                            then={(available) => (available ? (
                                <FieldFeedback key="1" info style={{ color: '#28a745' }}>{t('module.scoreLogic.view.formula.addTDCV.fieldFeedback')}</FieldFeedback>
                            ) : (
                                <FieldFeedback key="2">{t('module.scoreLogic.view.formula.addTDCV.fieldFeedback2')}</FieldFeedback>
                            ))
                            }
                        />
                    </FieldFeedbacks>
                </FormGroup>

                <FormGroup>
                    <div className="flex">
                        <label>{t('module.scoreLogic.view.formula.addTDCV.description')}</label>
                        <Input type="text" className="form-control" name="name" required value={inputs.name} onChange={handleChange}/>
                    </div>
                    <FieldFeedbacks for="name">
                        <FieldFeedback when="*" />
                    </FieldFeedbacks>
                </FormGroup>

                <FormGroup>
                    <label>{t('module.scoreLogic.view.formula.addTDCV.formula')}</label>
                    <div className="formula-build items-holder flex flex-wrap">
                        { !isEmpty(inputs.expression) && inputs.expression.map((item, idx) => {
                            const keys = Object.keys(removeEmpty(selection)); // remove empty values, get keys
                            return (<div key={idx} className={`item variable-item item-${keys[idx]}`}>{item}</div>);
                        }) }
                    </div>
                    { isEmpty(inputs.expression) && changeField && <div className="invalid-feedback d-block">{t('module.scoreLogic.view.formula.addTDCV.isEmpty')}</div> }
                </FormGroup>

                <FormGroup className="flex">
                    <Button onClick={ handleReset } disabled={ resetButtonDisabled } className="thin clear-code mr-auto">{t('module.scoreLogic.view.formula.addTDCV.clearFormula')}</Button>
                    <Button onClick={ handleSubmit } className="thin save ml-auto">{t('module.scoreLogic.view.formula.addTDCV.save')}</Button>
                </FormGroup>

            </div>
        </FormWithConstraints>
    );
}

const mapStateToProps = (state) => {
    const { scoreLogic } = state;
    return { scoreLogic };
};

const connectedAddTDCV = connect(mapStateToProps)(AddTDCV);
export { connectedAddTDCV as AddTDCV };

import React from "react"
import { useDispatch } from "react-redux"
import TooltipTrigger from "react-popper-tooltip"
import { useTranslation } from "react-i18next"
import { modalActions } from "../../../modals"

export function TableRowsActions(props) {
  const {
    id: subroleID,
    code,
    description,
    mfiCode,
    role,
    mfiID,
    hierarchyLayer,
    canDisburse,
  } = props

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const editSubRole = () => {
    dispatch(
      modalActions.manageMFISubRoles({
        subroleID,
        code,
        description,
        mfiCode,
        mfiID,
        role,
        hierarchyLayer,
        canDisburse,
      })
    )
  }

  const deleteRole = () => {
    dispatch(modalActions.deleteMFISubRole(subroleID))
  }

  function Trigger({ getTriggerProps, triggerRef }) {
    return (
      <button
        {...getTriggerProps({
          ref: triggerRef,
          className: "trigger dropdown-toggle btn btn-primary",
        })}
      >
        Actions
      </button>
    )
  }

  function Tooltip({
    getTooltipProps,
    getArrowProps,
    tooltipRef,
    arrowRef,
    placement,
  }) {
    return (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: "tooltip-container table-actions",
        })}
      >
        <div
          {...getArrowProps({
            ref: arrowRef,
            "data-placement": placement,
            className: "tooltip-arrow",
          })}
        />
        <div className="tooltip-body">
          <div className="dropdown dropdown-right">{items}</div>
        </div>
      </div>
    )
  }

  const items = (
    <>
      <div className="dropdown-item">
        <div
          onClick={() => {
            editSubRole()
          }}
        >
          {t("module.mfiSetup.actions.edit")}
        </div>
      </div>
      <div className="dropdown-item">
        <div
          onClick={() => {
            deleteRole()
          }}
        >
          {t("module.mfiSetup.actions.delete")}
        </div>
      </div>
    </>
  )

  return (
    <>
      {items && (
        <TooltipTrigger placement="right" trigger="click" tooltip={Tooltip}>
          {Trigger}
        </TooltipTrigger>
      )}
    </>
  )
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlowChartWithState } from '@mrblenny/react-flow-chart';
import styled from 'styled-components';
import Svg from 'react-inlinesvg';
import { modalActions } from '../../../modals';
import { mfiManagerActions } from '../..';
import { chartDefaultData } from './chartDefaultData';
import './HierarchyDiagram.scss';
import { useTranslation } from 'react-i18next';

const editEntitySvg = require('./../../../../assets/images/icons/edit-entity.svg');
const addEntitySvg = require('./../../../../assets/images/icons/add-entity.svg');


const Outer = styled.div`
  padding: 0px;
`;

export function HierarchyDiagram(props) {
    const { hierarchy: data, loading } = useSelector((store) => store.mfis);
    const { mfiId } = props;
    const [hierarchies, setHierarchies] = useState([]);
    const [chartData, setChartData] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(mfiManagerActions.getHierarchy(mfiId));
    }, [dispatch, mfiId]);

    // structure hierarchy data before passing it to the diagram
    // 150 units between layers/levels
    // 320 units between entities
    useEffect(() => {
      if (data && !loading) {
        let maxLength = 0
        data.forEach((item, i) => {
          if (item.entities && item.entities.length > maxLength) {
            maxLength = item.entities.length
          }
        });

        // order layers by id(smallest to biggest)
        let sortedData = data.sort(function(a, b){return a.id-b.id});
        // add nodes
        let nodes = {};
        let updatedHierarchies = [];
        let links = {};
        let prevEntities = [];
        let entityLength = {};
        sortedData.forEach((item, i) => {
          if (item.entities) {
            if (item.layerType === "Start") {
              prevEntities = item.entities;
              entityLength[item.entities[0].id] = (maxLength*320)/2;
              nodes['node'+item.entities[0].id] = {
                id: `node${item.entities[0].id}`,
                type: "output-only",
                text: item.entities[0].name,
                layer: {id: item.id, name: item.name},
                position: {
                  x: (maxLength*320)/2,
                  y: 100
                },
                ports: {
                  port2: {
                    id: "port2",
                    type: "output",
                    properties: {
                      value: "no",
                      linkColor: '#00b38e',
                    }
                  }
                }
              }
            } else {
              let orderedEntities = [];
              prevEntities.forEach((prevEntity, i) => {
                orderedEntities = [...orderedEntities, ...item.entities.filter((entity) => entity.topHierarchyEntityId === prevEntity.id)];
              });

              // check length of entities
              if (orderedEntities.length === 1) {
                entityLength[orderedEntities[0].id] = Number(entityLength[orderedEntities[0].topHierarchyEntityId]);

                nodes['node'+orderedEntities[0].id] = {
                  id: `node${orderedEntities[0].id}`,
                  type: "input-output",
                  text: orderedEntities[0].name,
                  layer: {id: item.id, name: item.name},
                  position: {
                    x: entityLength[orderedEntities[0].topHierarchyEntityId],
                    y: 100+(200*i)
                  },
                  ports: {
                    port1: {
                      id: "port1",
                      type: "input",
                      properties: {
                        linkColor: '#00b38e',
                      }
                    },
                    port2: {
                      id: "port2",
                      type: "output",
                      properties: {
                        linkColor: '#00b38e',
                      }
                    }
                  }
                };
                // link name derived from entity it is connecting to
                if (orderedEntities[0].topHierarchyEntityId) {
                  links['link'+orderedEntities[0].id] = {
                      id: 'link'+orderedEntities[0].id,
                      from: {
                        nodeId: 'node'+orderedEntities[0].topHierarchyEntityId,
                        portId: "port2"
                      },
                      to: {
                        nodeId: 'node'+orderedEntities[0].id,
                        portId: "port1"
                      }
                  }
                }
              } else {
                // loop through entities
                orderedEntities.forEach((entity, idx) => {
                  let entitiesSameParent = orderedEntities.filter((etty) => { return etty.topHierarchyEntityId === entity.topHierarchyEntityId; });
                  if (entitiesSameParent.length === 1) {
                    entityLength[entity.id] = Number(entityLength[entity.topHierarchyEntityId]);
                  } else {
                    // get index of child with same parent
                    entitiesSameParent.forEach((entitySameParent, entityIndex) => {
                      if (entitySameParent.id === entity.id) {
                          entityLength[entity.id] = (entityLength[entity.topHierarchyEntityId] - (entitiesSameParent.length*320)/2)+320*entityIndex;
                      }
                    });
                  }

                  nodes['node'+entity.id] = {
                    id: `node${entity.id}`,
                    type: "input-output",
                    text: entity.name,
                    layer: {id: item.id, name: item.name},
                    position: {
                      x: entityLength[entity.id],
                      y: 100+(200*i)
                    },
                    ports: {
                      port1: {
                        id: "port1",
                        type: "input",
                        properties: {
                          linkColor: '#00b38e',
                        }
                      },
                      port2: {
                        id: "port2",
                        type: "output",
                        properties: {
                          linkColor: '#00b38e',
                        }
                      }
                    }
                  }

                  // link name derived from entity it is connecting to
                  if (entity.topHierarchyEntityId) {
                    links['link'+entity.id] = {
                        id: 'link'+entity.id,
                        from: {
                          nodeId: 'node'+entity.topHierarchyEntityId,
                          portId: "port2"
                        },
                        to: {
                          nodeId: 'node'+entity.id,
                          portId: "port1"
                        }
                    }
                  }
                });
              }

              prevEntities = orderedEntities;
            }
          }

          // add nodes
          chartDefaultData.nodes = {...nodes};
          // add links
          chartDefaultData.links = links;
          updatedHierarchies.push(item.name);
        });

        // check if there is a node under the layers or past them
        let leastPosition = 300;
        const nodeKeys = Object.keys(chartDefaultData.nodes);
        Object.values(chartDefaultData.nodes).forEach((item, i) => {
          if (item.position.x < leastPosition) {
            leastPosition = item.position.x;
          }
        });
        // move diagram to start at 300
        if (leastPosition < 300) {
          const shiftLength = 300 - leastPosition;
          Object.values(chartDefaultData.nodes).forEach((item, i) => {
            chartDefaultData.nodes[nodeKeys[i]].position.x = chartDefaultData.nodes[nodeKeys[i]].position.x + shiftLength;
          });
        }

        // if entity position clashes with last horizontal entity position, move it and its parents right 320 units
        let prevPosition = {x: 0, y: 0};
        Object.values(chartDefaultData.nodes).forEach((item, i) => {
          if (item.position.y === prevPosition.y && (item.position.x >= prevPosition.x) &&  (prevPosition.x + 320 > item.position.x)) {
            // we have a clash so move it 320 units
            chartDefaultData.nodes[nodeKeys[i]].position.x= chartDefaultData.nodes[nodeKeys[i]].position.x + 320;
            //  move the parent as well
            let targetLayerId = chartDefaultData.nodes[nodeKeys[i]].layer.id;
            let targetEntityId = Number(item.id.replace("node", ""))
            let reversedArray = [];
            data.map((val) => reversedArray.unshift(val));
            reversedArray.forEach((dataItem, idx) => {
              if (dataItem.id === targetLayerId) {
                const targetEntity = dataItem.entities.filter((entityItem) => entityItem.id === targetEntityId);
                if (targetEntity[0] && targetEntity[0].topHierarchyEntityId) {
                  chartDefaultData.nodes['node'+targetEntity[0].topHierarchyEntityId].position.x = chartDefaultData.nodes['node'+targetEntity[0].topHierarchyEntityId].position.x + 320;
                  // get topHierarchyEntity layerId
                  targetLayerId = data[idx+1].id;
                  targetEntityId = targetEntity[0].topHierarchyEntityId;
                }
              }
            });
          }
          prevPosition = item.position;
        });

        // check if passed previous
        prevPosition = {x: 0, y: 0};
        Object.values(chartDefaultData.nodes).forEach((item, i) => {
          if (item.position.y === prevPosition.y && item.position.x < prevPosition.x) {
            chartDefaultData.nodes[nodeKeys[i]].position.x= chartDefaultData.nodes[nodeKeys[i-1]].position.x + 320;
          }
          prevPosition = item.position;
        });

        // check if firstchild is way ahead of parent
        prevPosition = {x: 0, y: 0};
        Object.values(chartDefaultData.nodes).forEach((item, i) => {
            let targetLayerId = chartDefaultData.nodes[nodeKeys[i]].layer.id;
            let targetEntityId = Number(item.id.replace("node", ""))
            let reversedArray = [];
            data.map((val) => reversedArray.unshift(val));
            reversedArray.forEach((dataItem, idx) => {
              if (dataItem.id === targetLayerId) {
                const targetEntity = dataItem.entities.filter((entityItem) => entityItem.id === targetEntityId);
                if (targetEntity[0] && targetEntity[0].topHierarchyEntityId && chartDefaultData.nodes['node'+targetEntity[0].topHierarchyEntityId].position.x < item.position.x) {
                  chartDefaultData.nodes['node'+targetEntity[0].topHierarchyEntityId].position.x = item.position.x;
                  // get topHierarchyEntity layerId
                  targetLayerId = data[idx+1].id;
                  targetEntityId = targetEntity[0].topHierarchyEntityId;
                }
              }
            });
          prevPosition = item.position;
        });

        // check if only child is left way behind parent
        prevPosition = {x: 0, y: 0};
        Object.values(chartDefaultData.nodes).forEach((item, i) => {
            let targetLayerId = chartDefaultData.nodes[nodeKeys[i]].layer.id;
            let targetEntityId = Number(item.id.replace("node", ""))
            let reversedArray = [];
            data.map((val) => reversedArray.unshift(val));
            reversedArray.forEach((dataItem, idx) => {
              if (dataItem.id === targetLayerId) {
                const targetEntity = dataItem.entities.filter((entityItem) => entityItem.id === targetEntityId);
                // check if parent has other children
                if (targetEntity[0] && targetEntity[0].topHierarchyEntityId) {
                  let sameParent = dataItem.entities.filter((entityItem) => entityItem.topHierarchyEntityId === targetEntity[0].topHierarchyEntityId);
                  if (sameParent.length === 1 && chartDefaultData.nodes['node'+targetEntity[0].topHierarchyEntityId].position.x > item.position.x) {
                    chartDefaultData.nodes[nodeKeys[i]].position.x = chartDefaultData.nodes['node'+targetEntity[0].topHierarchyEntityId].position.x;
                    targetLayerId = data[idx+1].id;
                    targetEntityId = targetEntity[0].topHierarchyEntityId;
                  }
                }
              }
            });
          prevPosition = item.position;
        });

        setChartData(chartDefaultData);
        setHierarchies(updatedHierarchies);
      }
    },[data, loading]);


    const handleEdit = (id, name, layer) => {
      dispatch(modalActions.editHierarchy({ mfiId, id, name, layer }));
    }

    const handleAdd = (id, layer) => {
      dispatch(modalActions.addHierarchy({mfiId, id, layer}));
    }

    // Custom component
    const NodeInnerCustom = ({ node, config }: INodeInnerDefaultProps) => {
      return (
        <Outer>
          <div className="entity-grid">
            <div className="entity-grid-text">
              {node.text}
            </div>
            <div className="entity-grid-icon">
              {node.id !== 'node1' && <Svg className="edit-entity-icon" onMouseDown={(e) => handleEdit(node.id, node.text, node.layer)} src={editEntitySvg} />}
            </div>
          </div>
          <Svg className="add-entity-icon" onMouseDown={(e) => handleAdd(node.id, node.layer)} src={addEntitySvg} />
        </Outer>
      )
    }

    const { t } = useTranslation();

    useEffect(() => {
      // display hierarchy layers on left
      // get elements with style rgba(0, 0, 0, 0.1) dashed 1px;
      if (document.getElementById('chart-container') && document.getElementById('chart-container').getElementsByTagName('div')[3]) {
        // loop through layers and list them
        hierarchies.forEach((item, i) => {
          let para = document.createElement("div");
          let node = document.createTextNode(item);
          para.appendChild(node);
          let styleAttr = document.createAttribute("style");
          styleAttr.value = `position: absolute; font-size:18px; left: 10px; top:${120+(200*i)}px`;
          para.setAttributeNode(styleAttr);
          document.getElementById('chart-container').getElementsByTagName('div')[3].appendChild(para);
        });
      }

      // if (document.getElementsByClassName('sc-AxjAm') && document.getElementsByClassName('sc-AxjAm')[0]) {
      //   // loop through layers and list them
      //   hierarchies.forEach((item, i) => {
      //     let para = document.createElement("div");
      //     let node = document.createTextNode(item);
      //     para.appendChild(node);
      //     let styleAttr = document.createAttribute("style");
      //     styleAttr.value = `position: absolute; font-size:18px; left: 10px; top:${120+(200*i)}px`;
      //     para.setAttributeNode(styleAttr);
      //     document.getElementsByClassName('sc-AxjAm')[0].appendChild(para);
      //   });
      // }
    });

    return (
        <>
        {chartData && !loading && (
          <div id="chart-container">
          <FlowChartWithState
            initialValue={chartData}
            config={{ readonly: true }}
            Components={{
              NodeInner: NodeInnerCustom,
            }}
            />
          </div>
        )}
        {loading && (<div className="text-center">{t('component.exceptions.loading.title')}...</div>)}
        </>
    );
}

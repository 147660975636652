/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { isEmpty, forEach, range } from "lodash"
import { FormInput } from "shards-react"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"

function MatrixTable({ scoreLogic, xTitle, yTitle, data, handleGridState }) {
  const { uid, computationCodes } = scoreLogic // Get all assets and computation codes and uid

  // Matrix incoming data setup
  const { matrix } = data
  const {
    yComputationCode,
    xComputationCode,
    yType,
    xType,
    xBinWidth,
    xMaxValue,
    yBinWidth,
    yMaxValue,
    grid,
  } = matrix

  // State Setup
  const [rowsConfig, SetRowsConfig] = useState([]) // copy grid into state so that new rows can be added and tracked
  const [state, setState] = useState({
    headerCells: [], // Table header columns
    bodyRows: [], // Generate rows from `grid`
    bodyLegend: [], // Generate row legend from `grid`
    fieldData: grid, // Save field input data
    form: "", // fetched form item
  })

  const getComputationCodeKey = (code) => {
    const surveyComputationCodes = computationCodes.filter(
      (item) => item.surveyUuid === uid
    )
    const [codeConfig] = surveyComputationCodes.filter(
      (item) => item.questionCode === code
    ) // find code that matched selected computation code
    const { questionKey } = codeConfig // retrieve question key
    return questionKey
  }

  /**
   * Group survey questions
   */
  const groupSurveyQuestions = (survey) => {
    const groups = []
    let counter = 0

    survey.map((field) => {
      const { type, name, label } = field

      if (type === "begin_group" || type === "start_group") {
        groups[counter] = {
          label,
          name,
          items: [],
        }
      }

      if (
        groups[counter] &&
        groups[counter].items &&
        type !== "begin_group" &&
        type !== "end_group" &&
        type !== "today" &&
        type !== "deviceid" &&
        type !== "profile"
      ) {
        groups[counter].items.push(field)
      }

      if (type === "end_group") {
        counter += 1
      }

      return field
    })

    return groups
  }

  /**
   * Get Computation codes
   * survey id = qui_id???
   */
  const getComputationCodeValues = (code) => {
    const { content } = state.form
    const { choices, survey } = content

    const questionKey = getComputationCodeKey(code)
    const splitKey = questionKey.split("/")
    const [groupKey, itemKey] = splitKey

    // Find specific question
    const groups = groupSurveyQuestions(survey)
    const [groupItems] = groups.filter((group) => group.name === groupKey)
    const questions = groupItems.items.filter((item) => item.name === itemKey)
    const [question] = questions
    const { select_from_list_name } = question

    const choiceItems = choices.filter(
      (choice) => choice.list_name === select_from_list_name
    )
    return choiceItems
  }

  useEffect(() => {
    const tableBodyRows = []
    let bodyLegend = []

    if (yType === "type" && state.form) {
      getComputationCodeValues(yComputationCode)
        .map((row) => {
          bodyLegend.push(row.label)
          return row
        })
        .map((row) => {
          tableBodyRows.push([])
          return row
        })
    } else if (xType === "numeric") {
      range(0, yMaxValue, yBinWidth).map((row) => {
        tableBodyRows.push([])
        return row
      })
    }

    if (xType === "type" && state.form) {
      const xAxisRows = getComputationCodeValues(xComputationCode)
      xAxisRows &&
        tableBodyRows.map((axis, rowIdx) => {
          xAxisRows.map((row, idx) => {
            tableBodyRows[rowIdx] = {
              ...tableBodyRows[rowIdx],
              x: {
                ...tableBodyRows[rowIdx].x,
                [idx + 1]: "",
              },
              y: rowIdx + 1,
            }

            return row
          })
          return axis
        })
    } else if (xType === "numeric") {
      tableBodyRows.map((_axis, rowIdx) => {
        range(0, xMaxValue, xBinWidth).map((row, idx) => {
          tableBodyRows[rowIdx] = {
            ...tableBodyRows[rowIdx],
            x: {
              ...tableBodyRows[rowIdx].x,
              [idx + 1]: "",
            },
            y: rowIdx + 1,
          }
          return row
        })

        if (yType === "numeric") {
          tableBodyRows[rowIdx] = {
            ...tableBodyRows[rowIdx],
            x: {
              ...tableBodyRows[rowIdx].x,
              [`>${xMaxValue}`]: "",
            },
          }
        }
        return _axis
      })
    }

    if (yType === "numeric") {
      bodyLegend = []
      tableBodyRows.map((row) => {
        bodyLegend.push(row.y)
        return row
      })
    }

    const headerCells = []
    !isEmpty(tableBodyRows) &&
      Object.keys(tableBodyRows[0].x).map((value, idx) => {
        headerCells.push(
          <TableCell key={idx}>
            {yType === "numeric" && value}
            {yType === "type" && "Value"}
          </TableCell>
        )
        return value
      })

    const rows = []
    tableBodyRows.map((_axis, rowIdx) => {
      rows[rowIdx] = []
      forEach(tableBodyRows[rowIdx].x, (_value, key) => {
        let value = null
        try {
          value = state.fieldData[rowIdx].x[key]
        } catch {
          value = ""
        }

        const cell = (
          <TableCell key={key}>
            <FormInput
              className={`row-${rowIdx}-col-${key}`}
              type="number"
              name={`${rowIdx}:${key}`}
              defaultValue={value}
              onChange={(e) => {
                handleChange({ name: e.target.name, value: e.target.value })
              }}
            />
          </TableCell>
        )
        rows[rowIdx].push(cell)
      })
      return _axis
    })

    setState((prevState) => ({
      ...prevState,
      bodyLegend,
      headerCells,
      bodyRows: rows,
    }))

    SetRowsConfig(tableBodyRows)
  }, [
    state.form,
    state.fieldData,
    xBinWidth,
    xComputationCode,
    xMaxValue,
    xType,
    yBinWidth,
    yComputationCode,
    yMaxValue,
    yType,
  ])

  /**
   * Update item by row and index within the row
   *
   * @param {*} event
   */
  const handleChange = ({ name, value }) => {
    const config = name.split(":")
    const [row, itemIndex] = config

    setState((prevState) => {
      const rowLabel =
        (prevState.bodyLegend &&
          prevState.bodyLegend[row] &&
          prevState.bodyLegend[row][0] &&
          prevState.bodyLegend[row][0]) ||
        ""
      try {
        prevState.fieldData[row] = {
          x: { [itemIndex]: value },
          y: rowLabel,
        }
      } catch {
        if (!prevState.fieldData[row]) {
          prevState.fieldData[row] = {
            x: { [itemIndex]: value },
            y: rowLabel,
          }
        }
        return {
          ...prevState,
          fieldData: {
            ...prevState.fieldData,
            [row]: {
              ...prevState.fieldData[row],
              x: {
                ...prevState.fieldData[row].x,
                [itemIndex]: value,
              },
              y: rowLabel,
            },
          },
        }
      }
      return prevState
    })

    handleGridState(state.fieldData)
  }

  const addRow = () => {
    const tableBodyRow = []
    forEach(state.bodyRows[0], (_value, key) => {
      const cell = (
        <TableCell key={key}>
          <FormInput
            className={`row-${state.bodyRows.length}-col-${key}`}
            type="number"
            name={`${state.bodyRows.length}:${key}`}
            onChange={(e) => {
              handleChange({ name: e.target.name, value: e.target.value })
            }}
          />
        </TableCell>
      )
      tableBodyRow.push(cell)
    })

    setState((prevState) => ({
      ...prevState,
      bodyRows: [...state.bodyRows, tableBodyRow],
      bodyLegend: [...state.bodyLegend, state.bodyLegend.length + 1],
      fieldData: [...state.fieldData, rowsConfig[0]],
    }))

    SetRowsConfig((prevState) => [...prevState, rowsConfig[0]])
  }

  if (isEmpty(state.bodyRows)) {
    return null
  }

  return (
    <Paper className="relative">
      <h2 className="legend-title x-legend-title">{xTitle} (x)</h2>
      <div className="legend-label-rotate-right">
        <h2 className="legend-title y-legend-title">{yTitle} (y)</h2>
      </div>
      <div className="flex relative ml-3">
        <dl className="legend w-1/5">
          <dt className="legend-head"></dt>
          {state.bodyLegend.map((row, idx) => (
            <dt className="legend-row" key={idx}>
              {row}
            </dt>
          ))}
          {yType === "numeric" && (
            <div
              onClick={() => {
                addRow()
              }}
              className="legend-row"
            >
              + Add
            </div>
          )}
        </dl>
        <Table className="w-4/5">
          <TableHead>
            <TableRow>{state.headerCells}</TableRow>
          </TableHead>
          <TableBody>
            {state.bodyRows.map((row, rowIdx) => (
              <TableRow key={rowIdx}>{row}</TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

function mapStateToProps(state) {
  const { scoreLogic } = state
  return { scoreLogic }
}

const connectedMatrixTable = connect(mapStateToProps)(MatrixTable)
export { connectedMatrixTable as MatrixTable }

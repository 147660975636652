import React from 'react';
import {
    Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { Container, Row, Col } from 'shards-react';
import { connect } from 'react-redux';
import { filter } from 'lodash';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../components/common/PageTitle';
import { ManageMFI } from '..';

const MFIAddView = ({ mfis: { items }, match: { params: { id } } }) => {
    const [mfi] = filter(items, (item) => String(item.id) === String(id));
    const { t } = useTranslation()
    return (
        <Container fluid className="main-content-container px-4 mb-4">
            <Row noGutters className="page-header pt-4 pb-1">
                <PageTitle sm="4" title={t('module.mfiSetup.setup')} className="text-sm-left" />
            </Row>
            <Row>
                <Col>
                    <Tabs>
                        <TabList>
                            <Tab>{t('module.mfiSetup.menu.details')}</Tab>
                        </TabList>
                        <TabPanel>
                            <ManageMFI id={id} mfi={Object(mfi)} />
                        </TabPanel>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state) => {
    const { mfis } = state;
    return { mfis };
};

const connectedMFIAddView = connect(mapStateToProps)(MFIAddView);
export { connectedMFIAddView as MFIAddView };

import { kobotoolkitConstants, kobotoolkitService } from '.';

import { alertActions } from '../alert';

export const kobotoolkitActions = {
    getAccessToken,
    getAssets,
    getSurveyDeploymentByVersion,
    getSurveyDeployment,
};

function getAccessToken(credentials, redirect) {
    return (dispatch) => {
        dispatch(request({}));

        kobotoolkitService.getAccessToken(credentials)
            .then((data) => {
                dispatch(success(data));
            }).then(() => {
                window.location.href= redirect;
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            });
    };

    function request(data) { return { type: kobotoolkitConstants.KOBO_AUTH_REQUEST, data }; }
    function success(data) { return { type: kobotoolkitConstants.KOBO_AUTH_TOKEN, data }; }
    function failure(data) { return { type: kobotoolkitConstants.KOBO_AUTH_FAILURE, data }; }
}

function getAssets() {
    return (dispatch) => {
        dispatch(request({}));
        kobotoolkitService.getAssets().then((data) => {
                dispatch(success(data));
            }, (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            });
    };

    function request(data) { return { type: kobotoolkitConstants.KOBO_ASSETS_REQUEST, data }; }
    function success(data) { return { type: kobotoolkitConstants.KOBO_ASSETS_SUCCESS, data }; }
    function failure(error) { return { type: kobotoolkitConstants.KOBO_ASSETS_FAILURE, error }; }
}

function getSurveyDeploymentByVersion(xformIdString, xformversion) {
    return (dispatch) => {
        dispatch(request({}));
        kobotoolkitService.getSurveyDeploymentByVersion(xformIdString, xformversion)
            .then(
                (data) => {
                    dispatch(success(data));
                },
                (error) => {
                    dispatch(failure(error));
                },
            );
    };

    function request(data) { return { type: kobotoolkitConstants.KOBO_SURVEY_REQUEST, data }; }
    function success(data) { return { type: kobotoolkitConstants.KOBO_SURVEY_SUCCESS, data }; }
    function failure(error) { return { type: kobotoolkitConstants.KOBO_SURVEY_FAILURE, error }; }
}

function getSurveyDeployment(xformIdString) {
    return (dispatch) => {
        dispatch(request({}));

        kobotoolkitService.getSurveyDeployment(xformIdString)
            .then(
                (data) => {
                    dispatch(success(data));
                },
                (error) => {
                    dispatch(failure(error));
                },
            );
    };

    function request(data) { return { type: kobotoolkitConstants.KOBO_SURVEY_REQUEST, data }; }
    function success(data) { return { type: kobotoolkitConstants.KOBO_SURVEY_SUCCESS, data }; }
    function failure(error) { return { type: kobotoolkitConstants.KOBO_SURVEY_FAILURE, error }; }
}

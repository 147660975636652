import React, { useState } from 'react';
import { FormControl, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { clientActions } from '../../../../_actions';
import { useStylesClient } from '../viewClient/clientModalStyles';

const SendActionReminder = ({ mfiId, loanId }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [state, setState] = useState({
        mfiId,
        loanId,
        message: ''
    });
    const classes = useStylesClient();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => {
            return { ...prevState, mfiId, loanId, [name]: value}
        })
    };

    const handleSubmit = () => {
        return dispatch(clientActions.BMSendReminder(state));
    }

    return (
        <div>
           {t('module.client.arrears.action.sendReminder')}
            <br />
            <br />
            <FormControl className={classes.textAreaWrapper}>
                <textarea
                    aria-label="Send Message"
                    rows={4}
                    placeholder={t('module.client.arrears.action.modal.composeMessage')}
                    name="message"
                    className={classes.textArea}
                    onChange={handleChange}
                />
            </FormControl>
            <br />
            <br />
            <Button
                className={classes.button}
                onClick={handleSubmit}
                style={{
                    backgroundColor: `${state.message.length >= 1 ? '#008d6a' : '#c0c0c0'}`,
                    cursor: `${state.message.length >= 1 ? 'pointer' : 'not-allowed'}`
                }}
            >
                {t('module.client.arrears.action.modal.send')}
            </Button>
        </div>
    )
}

export default SendActionReminder;

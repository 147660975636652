import { clientConstants } from "./_constants";

const initialState = {
    loading: false,
    clients: [],
    overview: [],
    notes: [],
    clientAssignees: null,
    notesError: null,
};

/**
 * Data Tools
 *
 * @param {*} state
 * @param {*} action
 */
export const clients = (state = initialState, action) => {
    switch (action.type) {
        case clientConstants.GETALL_CLIENT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                clients: [],
            };
        case clientConstants.GETALL_CLIENT_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                clients: action.data,
            };
        case clientConstants.GETALL_CLIENT_FAILURE:
            return {
                ...state,
                loading: null,
                error: true,
                clientError: action.error,
                clients: [],
            };

        case clientConstants.GET_CLIENT_OVERVIEW_GRAPH_REQUEST:
            return {
                ...state,
                loadingGraph: true,
                error: null,
                overviewGraph: [],
            };
        case clientConstants.GET_CLIENT_OVERVIEW_GRAPH_SUCCESS:
            return {
                ...state,
                loadingGraph: null,
                error: null,
                overviewGraph: action.data,
            };
        case clientConstants.GET_CLIENT_OVERVIEW_GRAPH_FAILURE:
            return {
                ...state,
                loadingGraph: null,
                error: true,
                overviewGraphError: action.error,
                overviewGraph: {},
            };
        case clientConstants.GET_CLIENT_OVERVIEW_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                overview: [],
            };
        case clientConstants.GET_CLIENT_OVERVIEW_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                overview: action.data,
            };
        case clientConstants.GET_CLIENT_OVERVIEW_FAILURE:
            return {
                ...state,
                loading: null,
                error: true,
                overviewError: action.error,
                overview: {},
            };
        case clientConstants.GET_CLIENT_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                profile: {},
            };
        case clientConstants.GET_CLIENT_PROFILE_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                profile: action.data,
            };
        case clientConstants.GET_CLIENT_PROFILE_FAILURE:
            return {
                ...state,
                loading: null,
                error: true,
                profile: {},
            };
        case clientConstants.GET_NOTES_REQUEST:
            return {
                ...state,
                loading: true,
                notesError: null,
                notes: [],
            };
        case clientConstants.GET_NOTES_SUCCESS:
            return {
                ...state,
                loading: null,
                notesError: null,
                notes: action.data,
            };
        case clientConstants.GET_NOTES_FAILURE:
            return {
                ...state,
                loading: null,
                notesError: action.error,
                notes: [],
            };
        case clientConstants.SEND_REMINDER_REQUEST:
            return {
                ...state,
                loading: true,
                sendReminderError: null,
                notes: [],
            };
        case clientConstants.SEND_REMINDER_SUCCESS:
            return {
                ...state,
                loading: null,
                sendReminderError: null,
                notes: action.data,
            };
        case clientConstants.SEND_REMINDER_FAILURE:
            return {
                ...state,
                loading: null,
                sendReminderError: action.error,
                notes: [],
            };
        case clientConstants.CREATE_NOTE_REQUEST:
            return {
                ...state,
                loading: true,
                newNoteError: null,
                newNote: {},
            };
        case clientConstants.CREATE_NOTE_SUCCESS:
            return {
                ...state,
                loading: null,
                newNoteError: null,
                newNote: action.data,
            };
        case clientConstants.CREATE_NOTE_FAILURE:
            return {
                ...state,
                loading: null,
                newNoteError: action.error,
                newNote: {},
            };
        case clientConstants.CREATE_UPLOAD_REQUEST:
            return {
                ...state,
                loading: true,
                uploadError: null,
                upload: {},
            };
        case clientConstants.CREATE_UPLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                uploadError: null,
                upload: action.data,
            };
        case clientConstants.CREATE_UPLOAD_FAILURE:
            return {
                ...state,
                loading: false,
                uploadError: action.error,
                upload: {},
            };
        case clientConstants.SEARCH_CLIENT_REQUEST:
            return {
                ...state,
                loading: true,
                uploadError: null,
                searchClient: {},
            };
        case clientConstants.SEARCH_CLIENT_SUCCESS:
            return {
                ...state,
                loading: false,
                uploadError: null,
                searchClient: action.data,
            };
        case clientConstants.SEARCH_CLIENT_FAILURE:
            return {
                ...state,
                loading: false,
                uploadError: action.error,
                searchClient: {},
            };
        case clientConstants.FILTER_CLIENT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                filteredClients: {},
            };
        case clientConstants.FILTER_CLIENT_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                filteredClients: action.data,
            };
        case clientConstants.FILTER_CLIENT_FAILURE:
            return {
                ...state,
                loading: null,
                error: true,
                filterClientError: action.error,
                filteredClients: {},
            };
        case clientConstants.GET_CLIENT_ASSIGNEES_REQUEST:
            return {
                ...state,
                assigneeloading: true,
                error: null,
                clientAssignees: null,
            };
        case clientConstants.GET_CLIENT_ASSIGNEES_SUCCESS:
            return {
                ...state,
                assigneeloading: false,
                error: null,
                clientAssignees: action.data,
            };
        case clientConstants.GET_CLIENT_ASSIGNEES_FAILURE:
            return {
                ...state,
                assigneeloading: false,
                error: true,
                clientAssigneesError: action.error,
                clientAssignees: [],
            };
        case clientConstants.ASSIGN_TO_REQUEST:
            return {
                ...state,
                assigneeloading: true,
                error: null,
                assignToMessage: null,
            };
        case clientConstants.ASSIGN_TO_SUCCESS:
            return {
                ...state,
                assigneeloading: false,
                error: null,
                assignToMessage: action.data,
            };
        case clientConstants.ASSIGN_TO_FAILURE:
            return {
                ...state,
                assigneeloading: false,
                error: true,
                AssignToError: action.error,
                assignToMessage: []
            };
        case clientConstants.ASSIGN_TO_ON_CLIENT_PROFILE_REQUEST:
            return {
                ...state,
                assigneeloading: true,
                error: null,
                assignToMessage: null,
            };
        case clientConstants.ASSIGN_TO_ON_CLIENT_PROFILE_SUCCESS:
            return {
                ...state,
                assigneeloading: false,
                error: null,
                assignToMessage: action.data,
            };
        case clientConstants.ASSIGN_TO_ON_CLIENT_PROFILE_FAILURE:
            return {
                ...state,
                assigneeloading: false,
                error: true,
                AssignToError: action.error,
                assignToMessage: []
            };
        case clientConstants.GET_CLIENT_TO_ASSIGN_BACK_REQUEST:
            return {
                ...state,
                assignbackloading: true,
                error: null,
                assignBackMessage: null,
            };
        case clientConstants.GET_CLIENT_TO_ASSIGN_BACK_SUCCESS:
            return {
                ...state,
                assignbackloading: false,
                error: null,
                clientsToAssignBack: action.data,
                assignBackMessage: null,
            };
        case clientConstants.GET_CLIENT_TO_ASSIGN_BACK_FAILURE:
            return {
                ...state,
                assignbackloading: false,
                error: true,
                assigBackError: action.error,
                assigBackMessage: null,
                clientsToAssignBack: []
            };
        case clientConstants.ASSIGN_BACK_REQUEST:
            return {
                ...state,
                assignbackloading: true,
                error: null,
                assignBackMessage: null,
            };
        case clientConstants.ASSIGN_BACK_SUCCESS:
            return {
                ...state,
                assignbackloading: false,
                error: null,
                assignBackMessage: action.data,
            };
        case clientConstants.ASSIGN_BACK_FAILURE:
            return {
                ...state,
                assignbackloading: false,
                error: true,
                AssignBackError: action.error,
                assignBackMessage: []
            };
        default:
            return state;
    }
}

import { settingsConstants } from '.';
import getSidebarNavItems from '../../data/sidebar-nav-items';

export const settingsActions = {
    toggleSidebarDropdown,
    toggleSidebar,
    navItems,
    setCurrency,
};

function toggleSidebarDropdown(item) {
    return (dispatch) => {
        dispatch(success(item));
    };
    function success(data) { return { type: settingsConstants.TOGGLE_SIDEBAR_DROPDOWN, data }; }
}

function toggleSidebar() {
    return (dispatch) => {
        dispatch(success({}));
    };
    function success(data) { return { type: settingsConstants.TOGGLE_SIDEBAR, data }; }
}

function navItems() {
    return (dispatch) => {
        dispatch(success(getSidebarNavItems()));
    };
    function success(data) { return { type: settingsConstants.NAV_ITEMS, data }; }
}

function setCurrency(currency) {
    return (dispatch) => {
        dispatch(success(currency));
    };
    function success(data) { return { type: settingsConstants.CURRENCY, data }; }
}

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
    FormGroup,
    FormTextarea,
    Button,
} from 'shards-react';

import {
    FormWithConstraints,
    Async,
    FieldFeedbacks,
    FieldFeedback,
} from 'react-form-with-constraints-bootstrap4';

import { isEmpty } from 'lodash';

import { commentActions } from '..';

const AddComment = ({ id, dispatch, t }) => {
    const form = useRef(null);
    const maxCommentLength = 500;
    const [comment, SetComment] = useState();
    const [commentLength, SetCommentLength] = useState(0);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

    useEffect(() => {
        const shouldEnableSubmitButton = () => {
            const formIsValid = form.current.isValid();
            return (formIsValid && !isEmpty(comment) && !form.current.hasFeedbacks());
        };
        setSubmitButtonDisabled(!shouldEnableSubmitButton());
    }, [comment, form]);

    async function commentTooLong() {
        return (commentLength < maxCommentLength);
    }

    const commentForm = async ({ target, currentTarget }) => {
        const { value } = target;
        SetComment(value);
        SetCommentLength(value.length);
        // eslint-disable-next-line no-unused-expressions
        currentTarget.tagName !== 'LABEL' && await form.current.validateFields(currentTarget);
    };

    const submitComment = async (e) => {
        e.preventDefault();

        await form.current.validateForm();

        if (!form.current.isValid()) {
            return;
        }

        dispatch(commentActions.addComment(id, comment));
        SetComment(null);
    };

    return (
        <FormWithConstraints ref={form} className="shadow-none" onSubmit={submitComment}>
            <FormGroup>
                <label htmlFor="comment">{t("module.comments.component.addComment.enterComments")}</label>
                <FormTextarea id="comment" name="comment" placeholder={t("module.comments.component.addComment.placeholder")} onChange={commentForm} value={comment} required/>
                <FieldFeedbacks for="comment">
                    <FieldFeedback when="*" />
                    <Async
                        promise={commentTooLong}
                        pending={<span className="d-block">...</span>}
                        then={(available) => (available ? (
                            ''
                        ) : (
                            <FieldFeedback key="2">{t("module.comments.component.addComment.tooLong")}</FieldFeedback>
                        ))
                        }
                    />
                </FieldFeedbacks>
            </FormGroup>

            <FormGroup className="pb-10">
                <Button theme="primary" className="d-table ml-auto" type="submit" disabled={submitButtonDisabled}>{t("module.comments.component.addComment.submit")}</Button>
            </FormGroup>
        </FormWithConstraints>
    );
};

const mapStateToProps = (state) => {
    const { comments } = state;
    return {
        comments,
    };
};

const connectedAddComment = withTranslation() (connect(mapStateToProps)(AddComment));
export { connectedAddComment as AddComment };

import React, { Component } from 'react';
import { Container, Row, Col } from 'shards-react';
import {
    Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Comments, AddComment } from '../../comments';
import PageTitle from '../../../components/common/PageTitle';
import { CreditScoreCard } from '../components/CreditScoreCard';
import { CashflowReport } from '../components/CashflowReport';
import { DocumentChecklist } from '../../documentChecklist/views/List';

class CreditReport extends Component {
    render() {
        const { match, t } = this.props;
        const { params } = match;
        const { id, xformId, xformversion } = params;

        const clickedApplication = this.props.applications.items && this.props.applications.items.filter(item => {
          return id === item.id;
        });
        const loggedInUser = this.props.authentication.user.user_name;

        return (
            <Container fluid className="main-content-container px-4 mb-4">
                <Row noGutters className="page-header pt-4 pb-1">
                    <PageTitle sm="4" title={t('module.creditScore.views.creditReport.pageTitle')} className="text-sm-left" />
                </Row>
                <Row>
                    <Col>
                        <Tabs>
                            <TabList>
                                <Link to={`/applications/view/${id}/${xformId}/${xformversion}`} className="react-tabs__tab view-application">{t('module.creditScore.views.creditReport.viewData')}</Link>
                                <Tab>{t('module.creditScore.views.creditReport.creditScore')}</Tab>
                                <Tab>{t('module.creditScore.views.creditReport.cashflowReport')}</Tab>
                                <Tab>{t('module.creditScore.views.creditReport.documentChecklist')}</Tab>
                                <Tab>{t('module.creditScore.views.creditReport.comments')}</Tab>
                            </TabList>

                            <TabPanel>
                                <CreditScoreCard id={ id } xformId={ xformId } xformversion={ xformversion }/>
                            </TabPanel>

                            <TabPanel>
                                <CashflowReport id={ id } />
                            </TabPanel>

                            <TabPanel>
                                <div className="p-10">
                                    <DocumentChecklist id={ id } />
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="p-10">
                                    <h2 className="section__title">{t('module.creditScore.views.creditReport.recommendations')}</h2>
                                    <Comments id={ id }/>
                                    {clickedApplication && clickedApplication[0].currentUser === loggedInUser && <AddComment id={ id }/>}
                                </div>
                            </TabPanel>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    const { applications, authentication } = state;
    return { applications, authentication };
};

const translatedCreditReport = withTranslation()(connect(mapStateToProps)(CreditReport));
export { translatedCreditReport as CreditReport };

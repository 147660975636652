import { applicationEditorConstants } from './_constants';

export const applicationEditor = (state = {}, action) => {
    switch (action.type) {
    case applicationEditorConstants.APPLICATION_EDITOR_REQUEST:
        return { ...state, error: null, loading: true };

    case applicationEditorConstants.APPLICATION_EDITOR_SUCCESS:
        return { ...state, loading: null, items: action.data };

    case applicationEditorConstants.APPLICATION_EDITOR_FAILURE:
        return { ...state, loading: null, error: action.error };
    default:
        return state;
    }
};

import { documentChecklistConstants } from '.';

export const documentChecklist = (state = { data: []}, action) => {
    switch (action.type) {
        case documentChecklistConstants.DOCUMENT_CHECKLIST_REQUEST:
            return { ...state, loading: true };
        case documentChecklistConstants.DOCUMENT_CHECKLIST_SUCCESS:
            return {...state,  loading: false, data: action.data};
        case documentChecklistConstants.DOCUMENT_CHECKLIST_FAILURE:
            return {...state,  loading: false, error: action.error};
        case documentChecklistConstants.DOCUMENT_CHECKLIST_CREATE_REQUEST:
            return { ...state, loading: true };
        case documentChecklistConstants.DOCUMENT_CHECKLIST_CREATE_SUCCESS:
            state.data.forEach((item, index) => {
              if (item.id === action.data.checklistId) {
                item.confirmed = action.data.confirmed;
              }
            });
            return { ...state, loading: false, };
        case documentChecklistConstants.DOCUMENT_CHECKLIST_CREATE_FAILURE:
            return { ...state, loading: false,  error: action.error };
        case documentChecklistConstants.DOCUMENT_CHECKLIST_DELETE_REQUEST:
            return { ...state, loading: true };
        case documentChecklistConstants.DOCUMENT_CHECKLIST_DELETE_SUCCESS:
            return { ...state, loading: false,  message: action.data };
        case documentChecklistConstants.DOCUMENT_CHECKLIST_DELETE_FAILURE:
            return { ...state, loading: false,  error: action.error };
        case documentChecklistConstants.DOCUMENT_CHECKLIST_UPLOAD_REQUEST:
            return { ...state, loading: true };
        case documentChecklistConstants.DOCUMENT_CHECKLIST_UPLOAD_SUCCESS:
            return { ...state, loading: false,  message: action.data };
        case documentChecklistConstants.DOCUMENT_CHECKLIST_UPLOAD_FAILURE:
            return { ...state, loading: false,  error: action.error };
        default:
            return state;
    }
}

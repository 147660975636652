/* eslint-disable no-console */
import React, { useState, useEffect, createRef } from "react"
import { connect, useSelector } from "react-redux"
import { filter, isEmpty } from "lodash"
import uuid from "uuid"
import { FormGroup, FormCheckbox, FormRadio, DatePicker } from "shards-react"
import Divider from "@material-ui/core/Divider"
import {
  FormWithConstraints,
  Input,
  FieldFeedbacks,
  FieldFeedback,
} from "react-form-with-constraints-bootstrap4"
import { useTranslation } from "react-i18next"

import { shortenedViewData } from "../../../utils/templates"
import { applicationEditorActions } from "../_actions"
import {
  applicationActions,
  ApplicationDetailsActions,
} from "../../applicationManager"
import { mfiManagerActions } from "../../mfiManager"

import "./Editor.scss"
import { Loading } from "../../../components/exceptions"
import GpsSection from "./GpsSection"
import GpsShape from "./GpsShape"
import { Grid } from "@material-ui/core"
import cx from "classnames"

function ApplicationEditor(props) {
  const {
    mfiId,
    id,
    authentication,
    applications,
    content,
    readonly,
    dispatch,
  } = props
  const { items } = applications
  const [choices, setChoices] = useState()
  const [survey, setSurvey] = useState()

  const { mfiImages = [], menus = [] } = useSelector((store) => store.mfis)

  const shortViewData =
    !!readonly &&
    menus.length > 0 &&
    menus.find(
      (menu) => menu.componentName === shortenedViewData.label && menu.enabled
    ) !== undefined

  useEffect(() => {
    dispatch(mfiManagerActions.getMfiImages(mfiId))
  }, [dispatch, mfiId])

  useEffect(() => {
    if (content) {
      setChoices(content.choices)
      setSurvey(content.survey)
    }
  }, [content])

  const disabled = !!readonly
  const [inputs, setInputs] = useState({})
  const [mediaFiles, setMediaFiles] = useState([])
  const [fullSubmission, setFullSubmission] = useState({})
  // const [submitButtonDisabled, setSubmitButtonDisabled] = useState(disabled);
  const formEl = createRef()
  const { t } = useTranslation()

  const fieldGroups = []
  const groups = []
  let counter = 0

  useEffect(() => {
    isEmpty(fullSubmission) &&
      dispatch(applicationActions.getApplicationbyID(id))
    !isEmpty(fullSubmission) &&
      dispatch(applicationActions.getLoanInformation(fullSubmission))
  }, [dispatch, fullSubmission, id])

  useEffect(() => {
    const foundApplication = filter(items, { id })
    const [application] = foundApplication

    foundApplication && setFullSubmission(application)
    application && setInputs({ ...application.json })
  }, [id, items])

  // useEffect(() => {
  //     const shouldEnableSubmitButton = () => {
  //         const formIsValid = formEl.current && formEl.current.isValid();
  //         return (formIsValid && !formEl.current.hasFeedbacks());
  //     };
  //     setSubmitButtonDisabled(!shouldEnableSubmitButton());
  // }, [inputs, formEl]);

  /**
   * Convert any date into yyyy-mm-dd
   *
   * @param {*} date
   */
  const formatDate = (date) => {
    const d = new Date(date)
    let month = `${d.getMonth() + 1}`
    let day = `${d.getDate()}`
    const year = d.getFullYear()

    if (month.length < 2) month = `0${month}`
    if (day.length < 2) day = `0${day}`

    return [year, month, day].join("-")
  }

  /**
   * Save any changes into the inputs state
   *
   * @param {*} event
   */
  const handleChange =
    (autoname, repeatIndex) =>
    async ({ target, currentTarget }) => {
      const { name, value } = target
      setInputs((prevState) => {
        if (autoname) {
          if (!prevState[name]) {
            const key = `${name}/${autoname}`
            return { ...prevState, [name]: [{ [key]: value }] }
          }
          const newValues = prevState[name]?.map((item, index) => {
            const key = `${name}/${autoname}`
            if (index === repeatIndex) {
              return { ...item, [key]: value }
            }
            return item
          })
          return { ...prevState, [name]: newValues }
        }
        return { ...prevState, [name]: value }
      })
      // eslint-disable-next-line no-unused-expressions
      currentTarget.tagName !== "LABEL" &&
        (await formEl.current.validateFields(currentTarget))
    }

  /**
   * SSave selected file into inputs state
   *
   * @param {*} event
   */
  const handleFileSelect = (event) => {
    event.preventDefault()
    const {
      target: { files, name },
    } = event
    // const [tempFile] = files;
    setInputs((prevState) => ({
      ...prevState,
      [name]: files[0] && files[0].name,
    }))
    return setMediaFiles([...mediaFiles, { files: files[0] }])
    // setInputs((prevState) => ({ ...prevState, [name]: files[0] && files[0].name }));
    // return setMediaFiles(files[0]);
  }

  /**
   * Toggle Inputs update state
   *
   * @param {*} name
   * @param {*} value
   */
  const handleToggleChange = async (name, value, autoname, repeatIndex) => {
    setInputs((prevState) => {
      if (!prevState[name] && autoname) {
        const key = `${name}/${autoname}`
        return { ...prevState, [name]: [{ [key]: value }] }
      }
      if (autoname) {
        const newValues = prevState[name]?.map((item, index) => {
          const key = `${name}/${autoname}`
          if (index === repeatIndex) {
            return { ...item, [key]: value }
          }
          return item
        })
        return { ...prevState, [name]: newValues }
      }
      return { ...prevState, [name]: value }
    })
  }

  /**
   * Handle checkbox Edit
   * State value is a string like so, "1, 3, 4, 7" etc
   *
   * First we convert it into an array, the manipulate the array, adding and removing items
   * afterwards we join it into a string and save state.
   *
   * @param {*} name
   * @param {*} which
   */
  const handleMultipleChange = async (name, which, autoname, repeatIndex) => {
    setInputs((prevState) => {
      if (!prevState[name] && autoname) {
        const key = `${name}/${autoname}`
        return { ...prevState, [name]: [{ [key]: which }] }
      }
      const stateValue = autoname
        ? prevState[name][repeatIndex][`${name}/${autoname}`]
        : prevState[name]
      const newState = stateValue ? stateValue.split(" ") : []
      const index = newState.indexOf(which)

      if (index === -1) {
        newState.push(which)
      } else {
        newState.splice(index, 1)
      }
      const value = newState.join(" ")

      if (autoname) {
        const newValues = prevState[name]?.map((item, index) => {
          const key = `${name}/${autoname}`
          if (index === repeatIndex) {
            return { ...item, [key]: value }
          }
          return item
        })
        return { ...prevState, [name]: newValues }
      }

      return { ...prevState, [name]: value }
    })
  }

  /**
   * Handle the Datepicker input by converting the date into the yyyy-mm-dd date format
   *
   * @param {*} name
   * @param {*} value
   */
  const handleDateChange = (name, value) => {
    const date = formatDate(value) // Convert date to yyyy-mm-dd
    setInputs((prevState) => ({ ...prevState, [name]: date }))
  }

  /**
   * Make calculation setup in the questionnaires
   *
   * @param {*} field
   */
  const handleCalculations = (group, field, value) => {
    // const { calculation } = field;
    // const rawCalculation = calculation.split(' ');
    return value
  }

  /**
   * Handle field conditions
   *
   * @param {*} field
   */
  const fieldIsActive = (group, field) => {
    // const { name: groupName } = group;
    const { relevant } = field

    if (relevant) {
      // return false;
      // const relevantLiteral = `${relevant}`;
      // const rawCalculation = relevant.replace(/[${]/g, '').replace(/[{}]/g, ' ').replace(/[>]/g, '> ').replace(/[ {2}]/g, ' ');
      // const checks = rawCalculation.split(' ').filter(Boolean);
      // const validate = checks.map( item => {
      //     if ( ! isNaN( item ) ) {
      //         Number(item)
      //     }
      //     return item;
      // })
      // const [firstCondition, secondCondition] = checks;
      // const firstValue = inputs[firstCondition];
      // const secondValue = inputs[secondCondition];
    }

    return true
  }

  /**
   * Handle field conditions
   *
   * @param {*} field
   */

  const fieldIsRelevant = ($autoname, field) => {
    if ("relevant" in field) {
      if (inputs && parseInt(inputs["personal/new_borrower"]) === 0) {
        return false
      }
    }
    return true
  }
  /**
   * Cancel and close editor
   */
  // const handleCancel = () => {
  //     history.push('/applications');
  // };

  /**
   * Submit editor data
   *
   * @param {*} e
   */
  const handleSubmit = async () => {
    // await formEl.current.validateForm();

    // if (!formEl.current.isValid()) {
    //     return;
    // }

    const { id, uid } = props
    const { user } = authentication
    const { xFormVersion, xFormId } = applications
    const { user_name } = user
    const submissionUuid = uuid()
    const timeNow = new Date()

    const newPayload = {
      __version__: xFormVersion,
      _submission_time: timeNow.toISOString(),
      _submitted_by_username: user_name,
      _submitted_by_userid: authentication.user.id,
      _uuid: submissionUuid,
      _xform_id_string: uid,
      _xform_uuid: xFormId,
      "meta/instanceID": `uuid:${submissionUuid}`,
      today: timeNow.toLocaleDateString().replaceAll("/", "-"),
    }

    const alertMessages = {
      applicationSuccess: t("alertActions.applications.applicationSaved"),
      editSuccess: t("alertActions.applications.editSuccessful"),
    }

    !id &&
      dispatch(
        applicationEditorActions.saveApplication(
          { ...inputs, ...newPayload },
          mediaFiles,
          alertMessages
        )
      )
    id &&
      dispatch(
        applicationEditorActions.updateApplication(
          id,
          { ...inputs },
          alertMessages
        )
      )
  }

  groups[counter] = {
    items: [
      {
        type: "mfi_image",
      },
    ],
  }

  counter += 1

  survey &&
    survey.map((field) => {
      const { type, name, label } = field
      const hasNoBeginGroup =
        type === "begin_repeat" && !(groups[counter] && groups[counter].items)
      if (
        type === "begin_group" ||
        type === "start_group" ||
        type === "start" ||
        type === "today" ||
        hasNoBeginGroup
      ) {
        groups[counter] = {
          label,
          name,
          items: [],
          hasNoBeginGroup,
        }
      }

      if (
        groups[counter] &&
        groups[counter].items &&
        (type !== "begin_group" || type !== "start") &&
        (type !== "end_group" || type !== "end" || type !== "end_repeat") &&
        type !== "today" &&
        type !== "deviceid" &&
        type !== "profile" &&
        !hasNoBeginGroup
      ) {
        if (
          groups[counter].name === "general" &&
          groups[counter].items.length === 1
        ) {
          const branchField = survey.find(
            (field) => field.type === "hidden" && field.name === "branch"
          )
          if (branchField !== undefined) {
            groups[counter].items.push(branchField)
          }
        }
        groups[counter].items.push(field)
      }

      if (
        type === "end_group" ||
        type === "end" ||
        (type === "end_repeat" && hasNoBeginGroup)
      ) {
        counter += 1
      }

      return field
    })

  const gpsGroup = groups.filter((group) =>
    group.name?.toLowerCase().includes("gps")
  )
  let gpsIdx = 0

  const components = groups
    .filter((group) => !group.name?.toLowerCase().includes("gps"))
    .map((group, idx) => {
      const { name: groupPrefix, label, items, hasNoBeginGroup } = group
      const title = label && (
        <h2 className="form-group-section" key={idx}>
          {label[0]}
        </h2>
      )

      const hasbeginRepeat =
        items.some((item) => item.type === "begin_repeat") || hasNoBeginGroup

      let repeatValues = []
      let fieldName = ""

      if (hasbeginRepeat) {
        const beginReapeatItem = items.find(
          (item) => item.type === "begin_repeat"
        )
        const { $autoname } = beginReapeatItem || {}
        fieldName = !hasNoBeginGroup
          ? `${groupPrefix}/${$autoname}`
          : `${groupPrefix}`
        repeatValues = inputs[fieldName]
      } else {
        repeatValues = [...repeatValues, ["non-repeat"]]
      }

      if (repeatValues === undefined) repeatValues = [...[], ["non-repeat"]]

      const itemsH = repeatValues?.map((rv, index) => {
        const repeatItems = items.map((field, innerIndex) => {
          const {
            // eslint-disable-next-line no-shadow
            $kuid,
            type,
            $autoname,
            label,
            required,
            select_from_list_name,
            hint,
            default: _default,
          } = field
          let $fieldName = `${groupPrefix}/${$autoname}`
          let value = null
          let autoname = ""
          const hintIndex = hint !== undefined ? innerIndex : 0
          const beginRepeatIndex = items.findIndex(
            (item) => item.type === "begin_repeat"
          )
          const endRepeatIndex = items.findIndex(
            (item) => item.type === "end_repeat"
          )

          if (
            hasbeginRepeat &&
            innerIndex > beginRepeatIndex &&
            innerIndex < endRepeatIndex
          ) {
            value =
              rv[`${fieldName}/${$autoname}`] !== undefined
                ? rv[`${fieldName}/${$autoname}`]
                : _default
            $fieldName = fieldName
            autoname = $autoname
          } else if (type === "hidden") {
            value = inputs[$autoname]
          } else {
            value =
              inputs[$fieldName] !== undefined ? inputs[$fieldName] : _default
          }

          // don't show input component if value is undefined and we need to shorten view data

          if (
            value === undefined &&
            !["mfi_image", "start", "end", "today"].includes(type) &&
            shortViewData
          )
            return null

          const options =
            choices && filter(choices, { list_name: select_from_list_name })

          if (!fieldIsRelevant(groupPrefix, field)) {
            return null
          }
          if (!fieldIsActive(groupPrefix, field)) {
            return null
          }

          // interpolate label text '${some_text}' to insert values infered on survey. we will assume the question key will always be 'some/some_text'
          let interpolatedLabel = ""

          if (label) {
            let currentLabel = label[0]
            const textMatches = currentLabel.match(/\${[a-zA-Z0-9_]*}/g)

            if (textMatches) {
              textMatches.forEach((variableText) => {
                const startIndex = variableText.indexOf("{") + 1
                const endIndex = variableText.indexOf("_")
                const bracketIndex = variableText.indexOf("}")
                let prefix = variableText.substring(startIndex, endIndex)
                const suffix = variableText.substring(startIndex, bracketIndex)

                let labelValue
                let fullPath

                for (let key in inputs) {
                  if (
                    inputs.hasOwnProperty(key) &&
                    Array.isArray(inputs[key])
                  ) {
                    const arrayElements = inputs[key]
                    for (let element of arrayElements) {
                      fullPath = `${key}/${suffix}`
                      if (element && element[fullPath] !== undefined) {
                        labelValue = element[fullPath]
                        break
                      }
                    }
                    if (labelValue !== undefined) {
                      break
                    }
                  }
                }

                if (labelValue === undefined) {
                  fullPath = `${prefix}/${suffix}`
                  labelValue = inputs[fullPath]
                }

                currentLabel = currentLabel.replace(
                  variableText,
                  labelValue !== undefined ? labelValue : variableText
                )
              })
              interpolatedLabel = currentLabel
            } else {
              interpolatedLabel = label
            }
          }

          switch (type) {
            case "start":
            case "end":
            case "today":
              return (
                <Input
                  key={$kuid}
                  type="text"
                  defaultValue={new Date().toISOString().substring(0, 10)}
                  disabled={disabled}
                />
              )
            case "mfi_image":
              return (
                mfiImages.length > 0 && (
                  <Grid container spacing={1}>
                    {mfiImages.map((image) => (
                      <Grid item sm={6}>
                        <img src={image.cloud_storage_url} alt={image.title} />
                      </Grid>
                    ))}
                  </Grid>
                )
              )
            case "deviceid":
            case "profile":
              return null

            case "note":
              return index === 0 ? (
                <FormGroup key={$kuid}>
                  <div className="form-input-note" htmlFor={$kuid}>
                    {interpolatedLabel}
                  </div>
                  <FieldFeedbacks for={$fieldName}>
                    <FieldFeedback when="*" />
                  </FieldFeedbacks>
                </FormGroup>
              ) : null
            case "date":
              return (
                <FormGroup key={$kuid} className="form-date-input">
                  <label htmlFor={$kuid}>{interpolatedLabel}</label>
                  <br />
                  <p className="m-0 form-date-value">
                    {value && value.toString()}
                  </p>
                  <DatePicker
                    id={$kuid}
                    size="sm"
                    // eslint-disable-next-line no-shadow
                    onChange={(value) => handleDateChange($fieldName, value)}
                    className="text-center"
                  />
                  <FieldFeedbacks for={$fieldName}>
                    <FieldFeedback when="*" />
                  </FieldFeedbacks>
                </FormGroup>
              )
            case "hidden":
              return (
                <FormGroup key={$kuid}>
                  <label htmlFor={$kuid}>{interpolatedLabel}</label>
                  <Input
                    className="form-control"
                    id={$kuid}
                    name={$fieldName}
                    defaultValue={value}
                    disabled
                  />
                </FormGroup>
              )
            case "calculate":
              return null
            // const result = handleCalculations(group, field, value)
            // return !hasbeginRepeat ? (
            //   <FormGroup key={$kuid}>
            //     <label htmlFor={$kuid}>{interpolatedLabel}</label>
            //     <Input
            //       className="form-control"
            //       id={$kuid}
            //       type="number"
            //       name={$fieldName}
            //       defaultValue={result}
            //       required={required}
            //       onChange={handleChange}
            //       disabled={disabled}
            //     />
            //     <FieldFeedbacks for={$fieldName}>
            //       <FieldFeedback when="*" />
            //     </FieldFeedbacks>
            //   </FormGroup>
            // ) : null
            case "int":
            case "integer":
              return (
                (!hint || hintIndex > beginRepeatIndex || index === 0) && (
                  <FormGroup key={$kuid}>
                    <label htmlFor={$kuid}>{interpolatedLabel}</label>
                    <Input
                      className="form-control"
                      id={$kuid}
                      type="number"
                      name={$fieldName}
                      defaultValue={value}
                      required={required}
                      onChange={handleChange(autoname, index)}
                      disabled={disabled}
                    />
                    <FieldFeedbacks for={$fieldName}>
                      <FieldFeedback when="*" />
                    </FieldFeedbacks>
                  </FormGroup>
                )
              )
            case "select_one":
              return (
                <FormGroup
                  key={$kuid}
                  className={cx({ "form-one": shortViewData })}
                >
                  <label htmlFor={$kuid} className="label-form">
                    {interpolatedLabel}
                  </label>
                  {options.map((option) => {
                    // eslint-disable-next-line no-shadow
                    const { $kuid, $autovalue, label } = option
                    const checked =
                      value !== undefined && $autovalue === value.toString()
                    if (!checked && shortViewData) return null
                    return (
                      <FormRadio
                        name={`${$fieldName}${index + 1}`}
                        key={$kuid}
                        onChange={() =>
                          handleToggleChange(
                            $fieldName,
                            $autovalue,
                            autoname,
                            index
                          )
                        }
                        disabled={disabled}
                        checked={checked}
                      >
                        {label[0]}
                      </FormRadio>
                    )
                  })}
                  <FieldFeedbacks for={$fieldName}>
                    <FieldFeedback when="*" />
                  </FieldFeedbacks>
                </FormGroup>
              )
            case "select_multiple":
              let values
              if (value) {
                values = Number.isInteger(value)
                  ? [value.toString()]
                  : value.split(" ")
              } else {
                values = []
              }
              return (
                <FormGroup
                  key={$kuid}
                  className={cx({ "form-one": shortViewData })}
                >
                  <label htmlFor={$kuid} className="label-form">
                    {interpolatedLabel}
                  </label>
                  {options.map((option) => {
                    // eslint-disable-next-line no-shadow
                    const { $kuid, $autovalue, label } = option
                    const checked = !!values.includes($autovalue)
                    if (!checked && shortViewData) return null
                    return (
                      <FormCheckbox
                        name={$fieldName}
                        key={$kuid}
                        onChange={() =>
                          handleMultipleChange(
                            $fieldName,
                            $autovalue,
                            autoname,
                            index
                          )
                        }
                        disabled={disabled}
                        checked={checked}
                        className="checkbox"
                      >
                        {label[0]}
                      </FormCheckbox>
                    )
                  })}
                  <FieldFeedbacks for={$fieldName}>
                    <FieldFeedback when="*" />
                  </FieldFeedbacks>
                </FormGroup>
              )
            case "text":
              return (
                (!hint || hintIndex > beginRepeatIndex || index === 0) && (
                  <FormGroup key={$kuid}>
                    <label htmlFor={$kuid}>{interpolatedLabel}</label>
                    <Input
                      className="form-control"
                      id={$kuid}
                      name={$fieldName}
                      defaultValue={value}
                      required={required}
                      onChange={handleChange(autoname, index)}
                      disabled={disabled}
                    />
                    <FieldFeedbacks for={$fieldName}>
                      <FieldFeedback when="*" />
                    </FieldFeedbacks>
                  </FormGroup>
                )
              )

            case "decimal":
              return (
                <FormGroup key={$kuid}>
                  <label htmlFor={$kuid}>{interpolatedLabel}</label>
                  <Input
                    type="number"
                    step="0.1"
                    className="form-control"
                    id={$kuid}
                    name={$fieldName}
                    defaultValue={value}
                    required={required}
                    onChange={handleChange(autoname, index)}
                    disabled={disabled}
                  />
                  <FieldFeedbacks for={$fieldName}>
                    <FieldFeedback when="*" />
                  </FieldFeedbacks>
                </FormGroup>
              )
            case "file":
            case "image":
              return (
                <FormGroup key={$kuid}>
                  <label htmlFor={$kuid}>{interpolatedLabel}</label>
                  <Input
                    type="file"
                    className="form-control"
                    id={$kuid}
                    name={$fieldName}
                    required={required}
                    onChange={handleFileSelect}
                  />
                  <FieldFeedbacks for={$fieldName}>
                    <FieldFeedback when="*" />
                  </FieldFeedbacks>
                </FormGroup>
              )
            case "begin_repeat":
              return null
            default:
              return !hasbeginRepeat ? (
                <label key={$kuid} htmlFor={$kuid}>
                  {interpolatedLabel}
                </label>
              ) : null
          }
        })

        //if group does not have child components return null
        if (repeatItems.every((item) => item === null)) return null

        return (
          <>
            {repeatItems}
            {hasbeginRepeat && index !== repeatValues.length - 1 && (
              <Divider className="mb-4" light />
            )}
          </>
        )
      })

      gpsIdx += 1

      // again if group does not have child components return null
      if (itemsH.every((item) => item === null)) return null

      return (
        <div
          key={idx}
          className="fields__group"
          ref={fieldGroups[idx]}
          style={{ height: fieldGroups[idx] && fieldGroups[idx].clientHeight }}
        >
          {title}
          {itemsH}
        </div>
      )
    })

  if (isEmpty(fullSubmission) && readonly) {
    return <Loading />
  }

  const renderGpsNotCaptured = (label, type, key) => {
    return <GpsSection key={key} label={label} type={type} />
  }

  const renderGpsComponents = (gpsGroup) => {
    const gpsComponents = gpsGroup.map((group) => {
      const { items, name: groupPrefix, hasNoBeginGroup } = group
      const hasBeginRepeat =
        items.some((item) => item.type === "begin_repeat") || hasNoBeginGroup

      let repeatValues = []
      let fieldName = ""

      const geopointIndex = items.findIndex(
        (field) => field.type === "geopoint"
      )
      const beginRepeatIndex = items.findIndex(
        (field) => field.type === "begin_repeat"
      )

      const skipRepeatLoop =
        geopointIndex !== -1 &&
        beginRepeatIndex !== -1 &&
        geopointIndex < beginRepeatIndex

      if (!skipRepeatLoop && hasBeginRepeat) {
        const beginRepeatItem = items.find(
          (item) => item.type === "begin_repeat"
        )
        const { $autoname } = beginRepeatItem || {}
        fieldName = !hasNoBeginGroup
          ? `${groupPrefix}/${$autoname}`
          : `${groupPrefix}`
        repeatValues = inputs[fieldName]
      } else {
        repeatValues = [...repeatValues, ["non-repeat"]]
      }

      if (repeatValues === undefined) repeatValues = [...[], ["non-repeat"]]
      const repeatComponents = repeatValues.map((rv) => {
        const componentItems = items.map((field) => {
          const { $autoname, type, $kuid, label, default: _default } = field

          // fetch the text description included for gps if available
          const textField = items.find((item) => item.type === "text")
          const { $autoname: textAutoname } = textField || {}
          const textFieldName = `${groupPrefix}/${textAutoname}`

          const $fieldName = `${groupPrefix}/${$autoname}`
          let value = null
          let textDescription = null
          if (!skipRepeatLoop && hasBeginRepeat) {
            value =
              rv[`${fieldName}/${$autoname}`] !== undefined
                ? rv[`${fieldName}/${$autoname}`]
                : _default
            textDescription =
              rv[`${fieldName}/${textAutoname}`] !== undefined
                ? rv[`${fieldName}/${textAutoname}`]
                : ""
          } else {
            value =
              inputs[$fieldName] !== undefined ? inputs[$fieldName] : _default
            textDescription =
              inputs[textFieldName] !== undefined ? inputs[textFieldName] : ""
          }

          switch (type) {
            case "geopoint":
              if (!value)
                return renderGpsNotCaptured(
                  ["Geopoint was not captured."],
                  "geopoint",
                  $kuid
                )
              const coordinates = value?.split(" ")
              const lat = coordinates && coordinates[0]
              const long = coordinates && coordinates[1]
              return (
                <GpsSection
                  key={$kuid}
                  kuid={$kuid}
                  lat={lat}
                  long={long}
                  label={label}
                  type={type}
                  map
                />
              )
            case "geoshape":
              if (!value)
                return renderGpsNotCaptured(
                  ["Geoshape was not captured."],
                  "geoshape",
                  $kuid
                )
              return (
                <GpsShape
                  key={$kuid}
                  kuid={$kuid}
                  type={type}
                  label={
                    textDescription
                      ? [textDescription]
                      : ["Map of the Geoshape."]
                  }
                  value={value}
                  polygon
                />
              )
            case "calculate":
              if (!Number.parseFloat(value))
                return renderGpsNotCaptured(
                  ["Geoshape area was not captured."],
                  "geoshape area",
                  $kuid
                )
              return (
                <GpsSection
                  key={$kuid}
                  kuid={$kuid}
                  name={$autoname}
                  area={value}
                  label={label || ["Geoshape area in acres."]}
                />
              )
            default:
              return null
          }
        })
        return componentItems
      })
      return repeatComponents
    })

    return gpsComponents
  }

  const gpsTitle = <h2 className="form-group-section">GPS Information</h2>

  const gpsComponents = gpsGroup && renderGpsComponents(gpsGroup)
  const gpsCWrapper = (
    <div
      key={gpsIdx}
      className="fields__group"
      ref={fieldGroups[gpsIdx]}
      style={{
        height: fieldGroups[gpsIdx] && fieldGroups[gpsIdx].clientHeight,
      }}
    >
      {gpsComponents && gpsTitle}
      {gpsComponents}
    </div>
  )
  components.push(gpsCWrapper)

  return (
    <>
      <FormWithConstraints
        ref={formEl}
        noValidate
        className="application-edit-form shadow-none"
      >
        <div className="p-10 fields__group-wrapper">{components}</div>
      </FormWithConstraints>
      <ApplicationDetailsActions
        id={id}
        handleSubmit={handleSubmit}
        formRef={formEl}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const { applications, authentication } = state
  return {
    authentication,
    applications,
  }
}

const connectedApplicationEditor = connect(mapStateToProps)(ApplicationEditor)
export { connectedApplicationEditor as ApplicationEditor }

import React, { useState, useEffect, useRef } from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { connect, useSelector } from 'react-redux';
import { FormGroup, Button } from 'shards-react';
import { isEmpty } from 'lodash';
import { FieldFeedbacks, FormWithConstraints } from 'react-form-with-constraints';
import { FieldFeedback, Input } from 'react-form-with-constraints-bootstrap4';
import { useTranslation } from 'react-i18next';
import { cslActions } from '..';
import { modalActions } from '../../modals';
import Header from '../../../components/widget/widgetTitle';

function AddScoringVariablesCondition({ modalID, data, dispatch }) {
    const { id } = data;

    const { scoringVariables } = useSelector((state) => state.scoreLogic);
    const [inputs, setInputs] = useState({ maxScore: '', value: '' });
    const [fieldDataType, setFieldDataType] = useState();
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const form = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        const filterGroup = scoringVariables.filter((item) => item.title === data?.category);
        if(filterGroup) {
            const dataType = filterGroup[0]?.entries?.filter((item) => item.id === data?.id);
            setFieldDataType(dataType[0]);
        }
    }, [scoringVariables, data]);

    useEffect(() => {
        function shouldEnableSubmitButton() {
            return inputs.maxScore && inputs.value;
        }
        setSubmitButtonDisabled(!shouldEnableSubmitButton());
    }, [inputs]);


    const handleChange = ({ target, currentTarget }) => {
        const { name, value } = target;
        setInputs((prevState) => ({ ...prevState, [name]: value }));
        form.current.validateFields(currentTarget);
    };

    const isValidData = () => {
        if (!isEmpty(inputs.maxScore) && !isEmpty(inputs.value) && !form.current.hasFeedbacks()) {
            return true;
        }
        return false;
    };

    const save = async () => {
        await form.current.validateForm();

        if (!isValidData() || !form.current.isValid()) {
            return;
        }

        dispatch(cslActions.addScoringVariablesCondition(id, { ...data, ...inputs }));
        closeModal();
    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal scoring-variables__add-variable">
            <Header title={t('module.scoreLogic.modal.addScoringVariablesCondition.headerTitle')} icon={<PersonOutlineOutlinedIcon />}/>
            <div className="w-full p-6">
                <FormWithConstraints ref={form} noValidate className="shadow-none">
                    <FormGroup>
                        <label htmlFor="threshold">{t('module.scoreLogic.modal.addScoringVariablesCondition.value')}</label>
                        <Input
                            className="form-control mb-2"
                            name="value"
                            defaultValue={inputs.value}
                            onChange={handleChange}
                            type={fieldDataType?.dataType === 'TEXT' ? 'text' : 'number' }
                            min="0"
                            step="0.01"
                            required
                        />
                        <FieldFeedbacks for="value">
                            <FieldFeedback when="*" />
                        </FieldFeedbacks>
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="maxScore">{t('module.scoreLogic.modal.addScoringVariablesCondition.maximumScore')}</label>
                        <Input
                            className="form-control mb-2"
                            name="maxScore"
                            defaultValue={inputs.maxScore}
                            onChange={handleChange}
                            type="number"
                            required
                        />
                        <FieldFeedbacks for="maxScore">
                            <FieldFeedback when="*" />
                        </FieldFeedbacks>
                    </FormGroup>
                </FormWithConstraints>
            </div>

            <div className="flex pb-6 pr-6">
                <Button theme="primary" disabled={submitButtonDisabled} className="ml-auto" onClick={ () => { save(); } }>{t('module.scoreLogic.modal.addScoringVariablesCondition.save')}</Button>
                <Button theme="secondary" className="ml-2" onClick={ () => { closeModal(); } }>{t('module.scoreLogic.modal.addScoringVariablesCondition.cancel')}</Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { scoreLogic, modals } = state;
    return { scoreLogic, modals };
};

const connectedAddScoringVariablesCondition = connect(mapStateToProps)(AddScoringVariablesCondition);
export { connectedAddScoringVariablesCondition as AddScoringVariablesCondition };

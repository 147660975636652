export const modalConstants = {
  CONFIRM: "CONFIRM",
  DELETE: "DELETE",
  NOTE: "NOTE",
  CLEAR: "CLEAR",
  ASSIGN: "ASSIGN",
  APPROVE: "APPROVE",
  DISBURSE: "DISBURSE",
  REFUSE: "REFUSE",
  HIDE_MODAL: "HIDE_MODAL",
  ACTIVATE_USER: "ACTIVATE_USER",
  DEACTIVATE_USER: "DEACTIVATE_USER",
  ADD_FORMULA: "ADD_FORMULA",
  EDIT_FORMULA: "EDIT_FORMULA",
  DELETE_FORMULA: "DELETE_FORMULA",
  ADD_CASHFLOW_VARIABLE: "ADD_CASHFLOW_VARIABLE",
  DELETE_CASHFLOW_VARIABLE: "DELETE_CASHFLOW_VARIABLE",
  ADD_SCORING_VARIABLES_CONDITION: "ADD_SCORING_VARIABLES_CONDITION",
  EDIT_SCORING_VARIABLES_CONDITION: "EDIT_SCORING_VARIABLES_CONDITION",
  DELETE_SCORING_VARIABLES_CONDITION: "DELETE_SCORING_VARIABLES_CONDITION",
  ADD_SCORING_VARIABLES_VARIABLE: "ADD_SCORING_VARIABLES_VARIABLE",
  DELETE_SCORING_VARIABLES_ENTRY: "DELETE_SCORING_VARIABLES_ENTRY",
  ADD_SCORING_VARIABLES_ENTRY: "ADD_SCORING_VARIABLES_ENTRY",
  ADD_SCORE_CARD_CONDITION: "ADD_SCORE_CARD_CONDITION",
  DELETE_SCORE_CARD_CONDITION: "DELETE_SCORE_CARD_CONDITION",
  ADD_ASSUMPTION: "ADD_ASSUMPTION",
  EDIT_ASSUMPTION: "EDIT_ASSUMPTION",
  DELETE_ASSUMPTION: "DELETE_ASSUMPTION",
  RECALCULATE_SCORE: "RECALCULATE_SCORE",
  RECALCULATE_SCORES: "RECALCULATE_SCORES",
  MANAGE_MFI_SUBROLES: "MANAGE_MFI_SUBROLES",
  DELETE_MFI_SUBROLES: "DELETE_MFI_SUBROLES",
  DELETE_APPLICATION_CHECKLIST: "DELETE_APPLICATION_CHECKLIST",
  UPLOAD_APPLICATION_CHECKLIST: "UPLOAD_APPLICATION_CHECKLIST",
  PREVIEW_APPLICATION_CHECKLIST: "PREVIEW_APPLICATION_CHECKLIST",
  MANAGE_WORKFLOW_GROUPS: "MANAGE_WORKFLOW_GROUPS",
  DELETE_DOCUMENT_CHECKLIST: "DELETE_DOCUMENT_CHECKLIST",
  EXPORT_APPLICATIONS: "EXPORT_APPLICATIONS",
  IMPORT_PAYMENTS: "IMPORT_PAYMENTS",
  PAY_DECLINE: "PAY_DECLINE",
  COLLECTION: "COLLECTION",
  ADD_VILLAGE_BANK: "ADD_VILLAGE_BANK",
  PAYMENT_METHOD: "PAYMENT_METHOD",
  MOBILE_UPDATE: "MOBILE_UPDATE",
  ADD_BENEFICIARY: "ADD_BENEFICIARY",
  DELETE_APPLICATION_COLUMN: "DELETE_APPLICATION_COLUMN",
  DELETE_REPORT_COLUMN: "DELETE_REPORT_COLUMN",
  DATE_COLUMN_FILTER_MODAL: "DATE_COLUMN_FILTER_MODAL",
  DELETE_CREDIT_SCORE_REPORT_MODAL: "DELETE_CREDIT_SCORE_REPORT_MODAL",
  USER_FILTER_MODAL: "USER_FILTER_MODAL",
  OFFICE_FILTER_MODAL: "OFFICE_FILTER_MODAL",
  PURCHASE_FILTER_MODAL: "PURCHASE_FILTER_MODAL",
  EDIT_HIERARCHY_MODAL: "EDIT_HIERARCHY_MODAL",
  ADD_HIERARCHY_MODAL: "ADD_HIERARCHY_MODAL",
  REPORTS_DATE_FILTER_MODAL: "REPORTS_DATE_FILTER_MODAL",
  REPAYMMENT_REPORTS_DATE_FILTER_MODAL: "REPAYMMENT_REPORTS_DATE_FILTER_MODAL",
  PAYMENT_REPORT_FILTER: "PAYMENT_REPORT_FILTER",
  REPORTS_OFFICE_FILTER_MODAL: "REPORTS_OFFICE_FILTER_MODAL",
  ADD_QUESTIONNAIRE_MODAL: "ADD_QUESTIONNAIRE_MODAL",
  EDIT_QUESTIONNAIRE_MODAL: "EDIT_QUESTIONNAIRE_MODAL",
  DELETE_QUESTIONNAIRE_MODAL: "DELETE_QUESTIONNAIRE_MODAL",
  DEACTIVATE_QUESTIONNAIRE_MODAL: "DEACTIVATE_QUESTIONNAIRE_MODAL",
  ACTIVATE_QUESTIONNAIRE_MODAL: "ACTIVATE_QUESTIONNAIRE_MODAL",
  RESET_QUESTIONNAIRE_MODAL: "RESET_QUESTIONNAIRE_MODAL",
  GET_CLIENT_DETAILS: "GET_CLIENT_DETAILS",
  PREVIEW_CLIENT_UPLOAD: "PREVIEW_CLIENT_UPLOAD",
  CLIENT_ASSIGN_TO: "CLIENT_ASSIGN_TO",
  CLIENT_ASSIGN_BACK: "CLIENT_ASSIGN_BACK",
  CLIENT_ASSIGN_TO_ON_PROFILE: "CLIENT_ASSIGN_TO_ON_PROFILE",
  SEND_REMINDER: "SEND_REMINDER",
  MANAGE_CUSTOMIZATION: "MANAGE_CUSTOMIZATION",
  AUTO_LOGOUT: "AUTO_LOGOUT",
}

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ManageCancelActions } from '../common/ManageMfiSetupActions';
import QuestionnairesTable from './QuestionnairesTable'

import { mfiManagerActions } from '../../';
import './ManageQuestionnaires.scss';


export function ManageQuestionnaires({ id: mfiId, mfi }) {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(mfiManagerActions.getQuestionnaires(mfiId));
    }, [dispatch, mfiId]);

    return (
        <>
          <ManageCancelActions mfiId={mfiId} />
          <QuestionnairesTable mfiId={mfiId}  />
        </>
    );
}

import React, { useState, useEffect } from "react"
import { Button } from "shards-react"
import { Container, Row, Col } from "shards-react"
import { withStyles } from "@material-ui/core/styles"
import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import SearchIcon from "@material-ui/icons/Search"
import { useSelector } from "react-redux"
import PageTitle from "../../../components/common/PageTitle"
import { LoadingView } from "../../../views"
import { useDispatch } from "react-redux"
import styles from "./ListPayment.styles"
import { alertActions } from "../../alert"
import "./Members.scss"
import { applicationActions } from "../_actions"
import { modalActions } from "../../modals"

export const VillageBanksApplication = withStyles(styles)(({ classes }) => {
  const { villageBanks, loading } = useSelector((state) => state.applications)
  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.authentication)
  const [filteredData, setFilteredData] = useState(villageBanks)

  useEffect(() => {
    if (profile && profile.hierarchyEntity && profile.hierarchyEntity.name) {
      dispatch(applicationActions.getVillageBanks(profile.hierarchyEntity))
    }
  }, [profile, dispatch])

  useEffect(() => {
    setFilteredData(villageBanks)
  }, [villageBanks])

  const handleSearchChange = (event) => {
    const searchText = event.target.value.toLowerCase()

    const filtered = villageBanks.filter((bank) => {
      const idMatch = bank.id.toLowerCase().includes(searchText)
      const nameMatch = bank.name.toLowerCase().includes(searchText)
      const openingDateMatch = bank.openingDate
        .toLowerCase()
        .includes(searchText)
      return idMatch || nameMatch || openingDateMatch
    })

    setFilteredData(filtered)
  }

  const handleSearchIconClick = () => {}

  const columnDefs = [
    { headerName: "Village ID", field: "id", width: 350 },
    { headerName: "Village Name", field: "name", width: 350 },
    { headerName: "External ID", field: "externalId", width: 350 },
    {
      headerName: "Opening Date",
      field: "openingDate",
      sortable: true,
      width: 350,
      valueFormatter: ({ value }) => {
        const [year, month, day] = value.split("-")
        return `${day}-${month}-${year}`
      },
    },
    { headerName: "Parent ID", field: "parentId", width: 350 },
  ]

  const handleCreateNew = () => {
    const parentId =
      filteredData.length > 0 ? parseInt(filteredData[0].parentId) : null

    if (parentId === null || isNaN(parentId)) {
      console.error("Parent ID is missing or invalid.")
      dispatch(alertActions.error("Parent ID is missing or invalid."))
      return
    }

    dispatch(modalActions.addVillageBank({ parentId }))
  }

  return (
    <Container maxWidth="xl" fluid className="main-content-container px-4">
      <Row noGutters className="page-header pt-4 flex">
        <PageTitle sm="4" title={"Village Banks"} className="text-sm-left" />
      </Row>
      <Row noGutters className="page-header pt-4 flex">
        <div className="application__actions ml-auto flex content-center">
          <Button
            theme="primary"
            className="mx-2 d-table ml-auto btn"
            onClick={handleCreateNew}
          >
            CREATE NEW
          </Button>

          <div
            style={{
              display: "flex",
              paddingRight: "10px",
            }}
          >
            <input
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              style={{
                fontFamily: "inherit",
                fontSize: "inherit",
                marginLeft: 10,
                paddingTop: "2px",
                padding: "4px 12px",
                borderRadius: "4px 0 0 4px",
                border: "1px solid #185597",
                outline: "none",
              }}
              className="inputSearch"
              onChange={handleSearchChange}
              autoFocus
            />
            <span
              style={{
                borderRadius: "0 3px 3px 0",
                borderTop: "1px solid",
                borderRight: "1px solid",
                borderBottom: "1px solid",
                marginLeft: "-2px",
                paddingLeft: "4px",
              }}
            >
              <SearchIcon
                style={{
                  color: "#a1b6ca",
                  marginTop: "4px",
                  cursor: "pointer",
                }}
                onClick={handleSearchIconClick}
              />
            </span>
          </div>
        </div>
      </Row>
      {loading ? (
        <LoadingView />
      ) : (
        <div
          className="ag-theme-alpine"
          style={{
            height: "600px",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <AgGridReact
            rowData={filteredData}
            columnDefs={columnDefs}
            pagination={true}
            paginationAutoPageSize={true}
            suppressRowClickSelection={true}
            stopEditingWhenGridLosesFocus={true}
          />
        </div>
      )}
    </Container>
  )
})

export default VillageBanksApplication

import { axiosInstance, handleResponse } from '../../_helpers/base.service';

export const documentChecklistService = {
    getApplicationChecklist,
    saveApplicationChecklist,
    createSurveryChecklist,
    deleteApplicationChecklist,
    uploadApplicationChecklist,
};

const serviceGroup = 'documentchecklist';


function getApplicationChecklist(id) {
    const requestConfig = {
        params: {
            applicationId: `${id}`,
        },
    };
    return axiosInstance.get(`${serviceGroup}/application-document-checklist/`, requestConfig).then(handleResponse);
}

function saveApplicationChecklist(data) {
    return axiosInstance.patch(`${serviceGroup}/application-document-checklist/`, data).then(handleResponse);
}

function createSurveryChecklist(data) {
    return axiosInstance.post(`${serviceGroup}/checklist/`, data).then(handleResponse);
}


function deleteApplicationChecklist(data) {
  return axiosInstance.patch(`${serviceGroup}/application-document-checklist/`, data).then(handleResponse);
}

function uploadApplicationChecklist(data) {
  // Initialize the form data
  let formData = new FormData();
  formData.append('applicationId', data.applicationId);
  formData.append('checklistId', data.checklistId);
  formData.append('file', data.file);
  const requestConfig = {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  };
  return axiosInstance.post(`${serviceGroup}/application-document-checklist/`, formData, requestConfig).then(handleResponse);
}

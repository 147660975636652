import { toolsConstants } from "."

const fileSaver = require("file-saver")

/**
 * Data Tools
 *
 * @param {*} state
 * @param {*} action
 */
export const tools = (
  state = {
    export: {},
    exportFormat: "xlsx",
    includeStatus: true,
    importJobs: [],
    rasterFiles: [],
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case toolsConstants.EXPORT_QUESTIONNAIRE_UID: {
      return {
        ...state,
        export: { ...action.data },
      }
    }
    case toolsConstants.EXPORT_FILE_FORMAT: {
      return {
        ...state,
        exportFormat: action.data,
      }
    }
    case toolsConstants.EXPORT_INCLUDE_STATUS: {
      return {
        ...state,
        includeStatus: action.data,
      }
    }
    case toolsConstants.EXPORT_APPLICATIONS_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case toolsConstants.EXPORT_APPLICATIONS_SUCCESS: {
      // eslint-disable-next-line no-case-declarations
      const date = new Date(Date.now())
      // eslint-disable-next-line no-case-declarations
      const fileName = `${
        state.export.surveryName ? state.export.surveryName : action.surveyName
      }-applications-${date.toLocaleString("en-GB", { timeZone: "UTC" })}.${
        action.data.config.params.format
      }`

      fileSaver.saveAs(action.data.data, fileName)

      return {
        ...state,
        loading: null,
        export: {},
      }
    }

    case toolsConstants.IMPORT_APPLICATIONS_JOBS_REQUEST: {
      return { ...state, error: null, loading: true }
    }
    case toolsConstants.IMPORT_APPLICATIONS_JOBS_SUCCESS: {
      return { ...state, loading: null, importJobs: action.data }
    }
    case toolsConstants.IMPORT_APPLICATIONS_JOBS_FAILURE: {
      return { ...state, loading: null, error: action.error }
    }

    case toolsConstants.SYNC_MUSONI_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case toolsConstants.SYNC_MUSONI_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        musoniSyncData: action.data,
      }
    case toolsConstants.SYNC_MUSONI_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        musoniSyncData: null,
      }

    case toolsConstants.GET_RASTER_FILES:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case toolsConstants.GET_RASTER_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        rasterFiles: action.data,
      }

    case toolsConstants.GET_RASTER_FILES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        rasterFiles: [],
      }

    case toolsConstants.ADD_RASTER_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        rasterFiles: [...state.rasterFiles, action.data],
      }

    case toolsConstants.EDIT_RASTER_FILES_SUCCESS:
      let newRasterFilesState = state.rasterFiles
      state.rasterFiles.map((item, index) => {
        if (String(item.id) === String(action.data.id)) {
          newRasterFilesState[index] = { ...action.data }
        }
        return
      })
      return {
        ...state,
        loading: null,
        error: null,
        rasterFiles: newRasterFilesState,
      }

    default: {
      return state
    }
  }
}

import { alertConstants } from '.';
import { handleResponseError } from '../../_helpers/base.service';

export const alertActions = {
    success,
    error,
    info,
    clear,
    remove,
};

function success(message) {
    return { type: alertConstants.SUCCESS, message };
}

function error(response) {
    const message = response ? handleResponseError(response) : response;
    return { type: alertConstants.ERROR, message };
}

function info(message) {
    return { type: alertConstants.INFO, message };
}


function remove(id) {
    return { type: alertConstants.REMOVE, id };
}

function clear() {
    return { type: alertConstants.CLEAR };
}

import {
  axiosInstance,
  handleResponse,
  integrationsInstance,
  axiosInstanceODK,
} from "../../_helpers/base.service"

const serviceGroup = "workflow"

const exportApplications = (payload) =>
  axiosInstance
    .get(`${serviceGroup}/export/applications/`, {
      responseType: "blob",
      timeout: 30000,
      params: {
        ...payload,
      },
    })
    .then((res) => res)

/**
 * Get all import jobs
 */
const getAllImportBulkApplications = () =>
  axiosInstance.get("bulkcreditscoring/bulk-upload/").then(handleResponse)

/**
 * Upload import form
 *
 * @param {*} data
 */
const importBulkApplications = (data) => {
  // Initialize the form data
  const formData = new window.FormData()
  formData.append("surveyUuid", data.surveyUuid)
  formData.append("file", data.file)
  const requestConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }
  return axiosInstance
    .post("bulkcreditscoring/bulk-upload/", formData, requestConfig)
    .then(handleResponse)
}

const syncMusoniData = async (mfiId) => {
  const res = await integrationsInstance.get(`musoni/sync/client-data/${mfiId}`)
  if (res.status === 200 || res.status === 201) {
    const { data } = res
    return data
  }
  const { response } = res
  const { data } = response
  const { message } = data
  return Promise.reject(message)
}

const repayMusoniLoan = async (payload) => {
  const res = await integrationsInstance.post(
    `musoni/sync/repay-musoni-loan`,
    payload
  )
  if (res.status === 200 || res.status === 201) {
    const { data } = res
    return data
  }
  const { response } = res
  const { data } = response
  const { message } = data
  return Promise.reject(message)
}

/**
 * bulk initialise applications
 */
const bulkInitApplications = (surveyUuid) =>
  axiosInstance
    .get(`${serviceGroup}/initialise/survey?surveyUuid=${surveyUuid}`)
    .then(handleResponse)

const getRasterFiles = () =>
  axiosInstanceODK.get("/raster-files/").then(handleResponse)

const addRasterFile = (data) => {
  // Initialize the form data
  let formData = new FormData()
  formData.append("country", data.country)
  formData.append("title", data.title)
  formData.append("submission", data.submission)
  return axiosInstanceODK.post("/raster-files/", formData).then(handleResponse)
}

const editHazardData = (data) => {
  const {
    id,
    title,
    country,
    long_title,
    min_value,
    max_value,
    increment,
    stress_low,
    stress_medium,
    tooltip,
  } = data
  const payload = {
    title,
    country,
    long_title,
    min_value,
    max_value,
    increment,
    stress_low,
    stress_medium,
    tooltip,
  }
  return axiosInstanceODK
    .put(`/raster-files/${id}/`, payload)
    .then(handleResponse)
}

const deleteRasterFile = (id) =>
  axiosInstanceODK.delete(`/raster-files/${id}`).then(handleResponse)

const listActivitiesLog = (payload) =>
  axiosInstance.post("/workflow/users/user-log", payload).then((res) => res)

export const toolsService = {
  exportApplications,
  getAllImportBulkApplications,
  importBulkApplications,
  bulkInitApplications,
  syncMusoniData,
  repayMusoniLoan,
  getRasterFiles,
  addRasterFile,
  deleteRasterFile,
  listActivitiesLog,
  editHazardData,
}

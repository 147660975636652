import React from "react"
import { Typography } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import cx from "classnames"
import styles from "./Editor.styles"

const GOOGLE_MAPS_API_URL = "https://www.google.com/maps/search/?api=1&query="

const GpsSection = ({
  kuid,
  type,
  label,
  lat,
  long,
  classes,
  area,
  map,
  name,
}) => {
  let areaText = ""
  if (area && (name === "geoshape_m2" || name === "gps_polygon_m2"))
    areaText = "The area in meters squared is: "
  if (area && (name === "geoshape_acre" || name === "gps_polygon_acre"))
    areaText = "The area in acres is: "
  return (
    <>
      <div key={kuid} className={classes.gpsSection}>
        {lat && (
          <Typography
            className={cx(classes.gpsSubtitle, {
              [classes.gpsSubtitleShape]: map,
            })}
          >
            {type}
          </Typography>
        )}
        {lat && <div className="form-input-note">{label[0]}</div>}
        {area && (
          <Typography className={classes.gpsTextShape}>
            <span className="font-italic">{areaText}</span>
            <span className="font-bold">
              {Number.parseFloat(area).toFixed(2)}
            </span>
          </Typography>
        )}
        {lat && (
          <>
            <Typography className={classes.gpsLink}>
              Click{" "}
              <a
                target="_blank"
                href={`${GOOGLE_MAPS_API_URL}${lat},${long}`}
                rel="noopener noreferrer"
              >
                this link{" "}
              </a>
              to navigate to map and see location.
            </Typography>
            <Typography>
              Coordinates:{" "}
              <span className="font-bold">{`${lat}, ${long}`}</span>
            </Typography>
          </>
        )}
      </div>
    </>
  )
}

export default withStyles(styles)(GpsSection)

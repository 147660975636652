import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Grid,
  withStyles,
  Paper,
  Tabs,
  Tab,
  Slider,
  Typography,
  Tooltip,
} from "@material-ui/core"
import { creditScoreActions } from "../../creditScore"
import styles from "./ClimateProfile.styles"
import HeatStressPlotKenya from "../../../assets/images/climate/kenya/Thermal-Heat-Index-plot.png"
import DroughtPlotKenya from "../../../assets/images/climate/kenya/Drought-plot.png"
import FloodPlotKenya from "../../../assets/images/climate/kenya/Flood-plot.png"
import HumanHeatStressPlotZambia from "../../../assets/images/climate/zambia/LendXS-Zambia-Human-HS.png"
import MaizeHeatStressPlotZambia from "../../../assets/images/climate/zambia/LendXS-Zambia-Maize-HS.png"
import DryDaysPlotZambia from "../../../assets/images/climate/zambia/LendXS-Zambia-NDD.png"
import SoilWaterStressPlotZambia from "../../../assets/images/climate/zambia/LendXS-Zambia-NDWS.png"
import HeatIcon from "../../../assets/images/climate/icons/thermometer.svg"
import DroughtIcon from "../../../assets/images/climate/icons/drought.svg"
import FloodIcon from "../../../assets/images/climate/icons/flood.svg"
import HazardPlotMap from "./HazardPlotMap"
import ScoreChart from "./ScoreChart"

const HAZARD_ICONS = {
  HSH: HeatIcon,
  HSM: HeatIcon,
  "Heat Stress": HeatIcon,
  NDD: DroughtIcon,
  Drought: DroughtIcon,
  NDWS: FloodIcon,
  Flood: FloodIcon,
}

const PLOT_MAPS = {
  "Heat Stress": HeatStressPlotKenya,
  Flood: FloodPlotKenya,
  Drought: DroughtPlotKenya,
  HSH: HumanHeatStressPlotZambia,
  HSM: MaizeHeatStressPlotZambia,
  NDD: DryDaysPlotZambia,
  NDWS: SoilWaterStressPlotZambia,
}

const ClimateRiskProfile = ({ classes, id }) => {
  const dispatch = useDispatch()
  const applications = useSelector((state) => state.applications)
  const score = useSelector((state) => {
    const {
      creditScore: { score: data },
    } = state
    return data
  })
  const climateScoreCard = score?.scoreCard.filter(
    (sc) => sc.title === "CLIMATE"
  )
  let climateFinalScore = 0
  let climateMaxScore = 0
  let hazardMaxScore = 0
  let vulnerabilityMaxScore = 0
  let exposureMaxScore = 0
  let hazardScore = 0
  let vulnerabilityScore = 0
  let exposureScore = 0
  if (!!climateScoreCard) {
    Object.keys(climateScoreCard[0].entries).forEach((key) => {
      climateFinalScore += Number(climateScoreCard[0].entries[key].score)
      climateMaxScore += Number(climateScoreCard[0].entries[key].maxScore)
      if (
        key.toLowerCase().includes("thermal") ||
        key.toLowerCase().includes("heat") ||
        key.toLowerCase().includes("drought") ||
        key.toLowerCase().includes("flood") ||
        key.toLowerCase().includes("ndd") ||
        key.toLowerCase().includes("ndws")
      ) {
        hazardScore += Number(climateScoreCard[0].entries[key].score)
        hazardMaxScore += Number(climateScoreCard[0].entries[key].maxScore)
      } else if (
        key.toLowerCase().includes("adaptive") ||
        key.toLowerCase().includes("sensitivity")
      ) {
        vulnerabilityScore += Number(climateScoreCard[0].entries[key].score)
        vulnerabilityMaxScore += Number(
          climateScoreCard[0].entries[key].maxScore
        )
      } else if (key.toLowerCase().includes("exposure")) {
        exposureScore += Number(climateScoreCard[0].entries[key].score)
        exposureMaxScore += Number(climateScoreCard[0].entries[key].maxScore)
      }
    })
  }

  const { items } = applications
  const application = items.filter((item) => item.id === id)
  const { json } = application[0]

  //iterate through the application json and get climate data
  const hazard_list = []
  for (const key in json) {
    // handle legacy climate solution which had climate data in;
    //const heatStressIndicator = json["climate/heat_stress"]
    //const droughtIndicator = json["climate/drought"]
    //const floodIndicator = json["climate/flood"]
    if (key.includes("climate/heat_stress")) {
      const heatStressIndicator = json["climate/heat_stress"]
      json[
        "climate_data_1"
      ] = `${heatStressIndicator},+ Heat Stress,+ Heat Distribution - Kenya,+ 50,+ 90,+ 10,+ 73,+ 79,+ Heat stress in livestock occurs when they face environmental conditions surpassing their comfort zone, causing physiological strain and decreased productivity. A practical index employed to gauge heat stress is the "Thermal Humidity Index (THI). This index takes into account temperature and humidity, providing insight into the extended periods of heightened heat stress experienced by the livestock.`
    }

    if (key.includes("climate/drought")) {
      const droughtIndicator = json["climate/drought"]
      json[
        "climate_data_2"
      ] = `${droughtIndicator},+ Drought,+ Drought Distribution - Kenya,+ 0,+ 30,+ 10,+ 15,+ 20,+ Drought stress in livestock occurs when they are subjected to prolonged periods of insufficient water supply due to reduced rainfall, resulting in limited forage availability and decreased overall well-being. A practical index used to gauge drought stress is the "Number of dry days (P < 1 mm) per month". This index quantifies the frequency of days with very low precipitation, indicating the scarcity of water sources and the potential impact on forage growth.`
    }

    if (key.includes("climate/flood")) {
      const floodIndicator = json["climate/flood"]
      json[
        "climate_data_3"
      ] = `${floodIndicator},+ Flood,+ Flood Distribution - Kenya,+ 0,+ 0.5,+ 0.1,+ 0.2,+ 0.5,+ Flood stress in livestock occurs when they are exposed to conditions where their habitat becomes waterlogged due to excessive rainfall or inadequate drainage, leading to detrimental effects on their health and well-being. An established index to measure flood stress is the "Number of days with soil logged by water." This index considers waterlogging as a day when soil moisture surpasses its field capacity and is moving towards saturation. By utilizing this information and assuming soil moisture is at 50% of the way to saturation, the index quantifies the frequency of waterlogging events.`
    }

    if (key.includes("climate_data")) {
      hazard_list.push(json[key].split(",+ "))
    }
  }

  const coordinatesInfo =
    json["gps/gps_coordinates"] || json["gps_home/gps_home_coordinates"]
  const country = json["country"]
  const coordinates = coordinatesInfo?.split(" ")
  const lat = coordinates && coordinates[0]
  const long = coordinates && coordinates[1]

  const [currentIndex, setCurrentIndex] = useState(0)
  const [plotMap, setPlotMap] = useState(HeatStressPlotKenya)
  const [marks, setMarks] = useState([])
  const [hazardSubHeader, setHazardSubHeader] = useState("")
  const handleTabChange = (e, tabIndex) => {
    setCurrentIndex(tabIndex)
  }

  useEffect(() => {
    dispatch(creditScoreActions.getScoreCard(id))
  }, [dispatch, id])

  useEffect(() => {
    if (hazard_list.length > 0) {
      setPlotMap(PLOT_MAPS[hazard_list[currentIndex][1]])
      const lowValue =
        Number(hazard_list[currentIndex][3]) < 1
          ? Number(hazard_list[currentIndex][3])
          : Math.floor(Number(hazard_list[currentIndex][3]))
      const highValue =
        Number(hazard_list[currentIndex][4]) < 1
          ? Number(hazard_list[currentIndex][4])
          : Math.floor(Number(hazard_list[currentIndex][4]))
      const increment = Number(hazard_list[currentIndex][5])
      setMarks(getMarks(lowValue, highValue, increment))
      setHazardSubHeader(hazard_list[currentIndex][2])
    }
  }, [currentIndex, hazard_list.length])

  const getMarks = (min, max, increment) => {
    let result = []
    for (let value = min; value <= max; value += increment) {
      result.push({
        value: value,
        label: increment < 1 ? value.toFixed(1).toString() : value.toString(),
      })
    }

    return result
  }

  const getSeverity = (value, low, medium) => {
    if (value < low) return "Low"
    else if (value < medium) return "Medium"
    else if (value >= medium) return "High"
  }

  const getSeverityMargin = (value, high) => {
    return `${(value / high) * 100 + 5}%`
  }

  const CustomTab = withStyles({
    root: {
      backgroundColor: "",
    },
    selected: {
      backgroundColor: "",
    },
  })(Tab)

  const CustomTooltip = withStyles({
    tooltip: {
      color: "black",
      backgroundColor: "#D3D3D3",
    },
  })(Tooltip)

  return (
    <Grid container className={`m-2 ${classes.container}`} spacing={3}>
      <Grid item md={12}>
        <Grid container spacing={1}>
          <Grid item md={5} className={classes.hazardInfoItem}>
            <Typography classes={{ body1: classes.hazardHeader }}>
              Hazard Analysis
            </Typography>
            <Paper elevation={2}>
              <Typography className={classes.hazardSubHeader}>
                {hazardSubHeader}
              </Typography>
              {!lat || !long ? (
                <Typography className={classes.notAvailableText} variant="h5">
                  Climate Risk Profile Data not Available
                </Typography>
              ) : (
                <>
                  <HazardPlotMap
                    plotMap={plotMap}
                    longitude={long}
                    latitude={lat}
                    country={country}
                    hazard_list={hazard_list}
                  />
                  <Typography className="text-center mb-2">
                    Coordinates:{" "}
                    <span className="font-bold">{`${lat}, ${long}`}</span>
                  </Typography>
                </>
              )}
            </Paper>
          </Grid>
          <Grid item md={7} className={classes.hazardInfoItem}>
            <Paper elevation={2} className={classes.indicatorPaper}>
              <Tabs
                value={currentIndex}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                aria-label="climate profile"
                classes={{ flexContainer: classes.tabContainer }}
              >
                {hazard_list.map((item) => {
                  const icon = HAZARD_ICONS[item[1]]
                  return (
                    <CustomTab
                      lable="One"
                      icon={
                        <img
                          className={classes.iconImage}
                          width={40}
                          src={icon}
                          alt="Icon"
                        />
                      }
                      label={item[1]}
                      classes={{ root: classes.tabWrapper }}
                    />
                  )
                })}
              </Tabs>
              {hazard_list.map((item, index) => {
                const hazardValue = Number(item[0])
                const lowIndicator = Number(item[6])
                const mediumIndicator = Number(item[7])
                if (currentIndex === index) {
                  if (hazardValue !== NaN && hazardValue) {
                    const lowValue =
                      Number(item[3]) < 1
                        ? Number(item[3])
                        : Math.floor(Number(item[3]))
                    const highValue =
                      Number(item[4]) < 1
                        ? Number(item[4])
                        : Math.floor(Number(item[4]))
                    return (
                      <div className={classes.sliderContainer}>
                        <Typography
                          className="text-center mt-3"
                          classes={{ body1: classes.sliderHeader }}
                          id="heat-slider"
                          gutterBottom
                        >
                          {`${item[1]} (${lowValue} - ${highValue})`}
                        </Typography>
                        <Typography
                          classes={{ body1: classes.severityText }}
                          style={{
                            marginLeft: getSeverityMargin(
                              hazardValue,
                              Number(highValue)
                            ),
                          }}
                          gutterBottom
                        >
                          {getSeverity(
                            hazardValue,
                            lowIndicator,
                            mediumIndicator
                          )}
                        </Typography>
                        <Slider
                          classes={{ track: classes.track, rail: classes.rail }}
                          className="mt-5"
                          defaultValue={hazardValue.toFixed(2)}
                          aria-labelledby="climate-slider"
                          step={10}
                          marks={marks}
                          valueLabelDisplay="on"
                          min={Number(lowValue)}
                          max={Number(highValue)}
                          disabled
                        />
                      </div>
                    )
                  } else {
                    return (
                      <Typography
                        className={classes.notAvailableText}
                        variant="h5"
                      >
                        {`${item[1]} Indicator Not Available`}
                      </Typography>
                    )
                  }
                }
              })}
            </Paper>
            <Paper elevation={2}>
              <Typography gutterBottom className={classes.streesIndexHeader}>
                Stress Index Indicators
              </Typography>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography
                    gutterBottom
                    className={`p-2 ${classes.stressIndexText}`}
                  >
                    Indicator
                  </Typography>
                  {hazard_list.map((item) => {
                    return (
                      <Typography
                        classes={{ body1: classes.pageTextSecondary }}
                        gutterBottom
                        className="p-2"
                      >
                        {item[2]}
                        <CustomTooltip title={item[8]} placement="right" arrow>
                          <sup className={classes.superscript}>?</sup>
                        </CustomTooltip>
                      </Typography>
                    )
                  })}
                </Grid>
                <Grid item md={6}>
                  <Grid container>
                    <Grid item md={4}>
                      <Typography
                        gutterBottom
                        className={`p-2 ${classes.stressIndexText}`}
                      >
                        Low
                      </Typography>
                      {hazard_list.map((item) => {
                        const lowThreshold =
                          Number(item[6]) < 1
                            ? Number(item[6])
                            : Math.floor(Number(item[6]))
                        return (
                          <Typography
                            classes={{ body1: classes.finalScore }}
                            gutterBottom
                            className="p-2"
                          >
                            {`<${lowThreshold}`}
                          </Typography>
                        )
                      })}
                    </Grid>
                    <Grid item md={4}>
                      <Typography
                        gutterBottom
                        className={`p-2 ${classes.stressIndexText}`}
                      >
                        Medium
                      </Typography>
                      {hazard_list.map((item) => {
                        const lowThreshold =
                          Number(item[6]) < 1
                            ? Number(item[6])
                            : Math.floor(Number(item[6]))
                        const mediumThreshold =
                          Number(item[7]) < 1
                            ? Number(item[7])
                            : Math.floor(Number(item[7]))
                        return (
                          <Typography
                            classes={{ body1: classes.finalScore }}
                            gutterBottom
                            className="p-2"
                          >
                            {`${lowThreshold}>${mediumThreshold}`}
                          </Typography>
                        )
                      })}
                    </Grid>
                    <Grid item md={4}>
                      <Typography
                        gutterBottom
                        className={`p-2 ${classes.stressIndexText}`}
                      >
                        High
                      </Typography>
                      {hazard_list.map((item) => {
                        const mediumThreshold =
                          Number(item[7]) < 1
                            ? Number(item[7])
                            : Math.floor(Number(item[7]))
                        const thresholdAdd = mediumThreshold < 1 ? 0.1 : 1
                        return (
                          <Typography
                            classes={{ body1: classes.finalScore }}
                            gutterBottom
                            className="p-2"
                          >
                            {`>=${mediumThreshold + thresholdAdd}`}
                          </Typography>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Grid container className="border-solid border-4 border-current mt-2">
          <Grid item md={12}>
            <Typography gutterBottom className={classes.streesIndexHeader}>
              Climate Risk Score
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Grid container>
              <Grid item md={3}>
                <ScoreChart
                  climateFinalScore={climateFinalScore}
                  climateMaxScore={climateMaxScore}
                />
              </Grid>
              <Grid item md={3} className="mt-3">
                <Typography
                  gutterBottom
                  classes={{ body1: classes.totalPoints }}
                >
                  Total Points
                </Typography>
                <Typography classes={{ body1: classes.finalScore }}>
                  {climateFinalScore}
                </Typography>
                <Typography classes={{ body1: classes.maxScore }}>
                  points
                </Typography>
                <Typography gutterBottom classes={{ body1: classes.maxScore }}>
                  {`out of ${climateMaxScore}`}
                </Typography>
              </Grid>
              <Grid item md={3}>
                <Typography
                  gutterBottom
                  classes={{ body1: classes.emptyRisk }}
                ></Typography>
                <Typography gutterBottom classes={{ body1: classes.risk }}>
                  Hazard{" "}
                  <CustomTooltip
                    title="The hazard score represents the combined level of risk associated with the climate-related challenges described under each stress category."
                    placement="right"
                    arrow
                  >
                    <sup className={classes.superscript}>?</sup>
                  </CustomTooltip>
                </Typography>
                <Typography gutterBottom classes={{ body1: classes.risk }}>
                  Vulnerability{" "}
                  <CustomTooltip
                    title="The vulnerability score signifies one's susceptibility to the adverse effects of the specified hazards. This score includes an assessment of the entity's sensitivity and adaptive capacity. Adaptive capacity evaluates the available resources, knowledge, and strategies for climate risk management, while sensitivity assesses the degree to which an entity is influenced by climate stressors. climate condition changes."
                    placement="right"
                    arrow
                  >
                    <sup className={classes.superscript}>?</sup>
                  </CustomTooltip>
                </Typography>
                <Typography gutterBottom classes={{ body1: classes.risk }}>
                  Exposure{" "}
                  <CustomTooltip
                    title="The exposure score signifies the extent to which the entity is at risk from the specified hazards due to its location and characteristics."
                    placement="right"
                    arrow
                  >
                    <sup className={classes.superscript}>?</sup>
                  </CustomTooltip>
                </Typography>
              </Grid>
              <Grid item md={3}>
                <Typography
                  gutterBottom
                  className="underline"
                  classes={{ body1: classes.totalPoints }}
                >
                  Score
                </Typography>
                <Typography classes={{ body1: classes.finalScore }}>
                  {`${hazardScore}/${hazardMaxScore}`}
                </Typography>
                <Typography classes={{ body1: classes.finalScore }}>
                  {`${vulnerabilityScore}/${vulnerabilityMaxScore}`}
                </Typography>
                <Typography classes={{ body1: classes.finalScore }}>
                  {`${exposureScore}/${exposureMaxScore}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(ClimateRiskProfile)

import React, { useEffect } from "react"
import { withStyles } from "@material-ui/core/styles"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { Container, Row, Col } from "shards-react"
import { useDispatch, useSelector } from "react-redux"
import { filter, first, isEmpty } from "lodash"

import PageTitle from "../../../components/common/PageTitle"
import {
  ManageMFI,
  ManageHierarchy,
  ManageRoles,
  ManageWorkflow,
  ManageGroups,
  ManageQuestionnaires,
  ManageCustomization,
  mfiManagerActions,
} from ".."
import { ErrorsView, LoadingView, EmptyContentView } from "../../../views"
import { useTranslation } from "react-i18next"
import { ExternalIntegration } from "../components/Externalntegration"
import { ScoreCardShare } from "../components/ScoreCardShare"
import {
  menuPanel,
  mpesaPanel,
  imagePanel,
} from "../components/customization/Constants"
import styles from "./Edit.styles"

export const MFIEditView = withStyles(styles)(
  ({
    match: {
      params: { id },
    },
    classes,
  }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { items, loading, error, externalIntegration } = useSelector(
      (state) => state.mfis
    )
    const mfi = first(filter(items, (item) => String(item.id) === String(id)))
    // const externalProfile = useSelector((state) => state.mfis);
    // const { externalIntegration } = externalProfile;
    useEffect(() => {
      dispatch(mfiManagerActions.getAllMFIs())
    }, [dispatch])

    useEffect(() => {
      dispatch(mfiManagerActions.getExternalIntegration(id))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id])

    return (
      <Container fluid className="main-content-container px-4 mb-4">
        <Row noGutters className="page-header pt-4 pb-1">
          <PageTitle
            sm="4"
            title={t("module.mfiSetup.setup")}
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col>
            <Tabs>
              <TabList>
                <Tab>{t("module.mfiSetup.menu.details")}</Tab>
                <Tab>{t("module.mfiSetup.menu.hierarchy")}</Tab>
                <Tab>{t("module.mfiSetup.menu.userRoles")}</Tab>
                <Tab>{t("module.mfiSetup.menu.workflowGroups")}</Tab>
                <Tab>{t("module.mfiSetup.menu.workflow")}</Tab>
                <Tab>{t("module.mfiSetup.menu.questionnaires")}</Tab>
                <Tab>External Integration</Tab>
                <Tab>Score Card Share Url</Tab>
                <Tab>{t("module.mfiSetup.menu.customization")}</Tab>
              </TabList>
              <TabPanel>
                {loading && <LoadingView />}
                {!error && !loading && isEmpty(mfi) && (
                  <EmptyContentView title={t("module.mfiSetup.noMfis")} />
                )}
                {!isEmpty(error) && <ErrorsView error={error} />}
                {!isEmpty(mfi) && <ManageMFI id={id} mfi={Object(mfi)} />}
              </TabPanel>
              <TabPanel>
                <ManageHierarchy id={id} mfi={Object(mfi)} />
              </TabPanel>
              <TabPanel>
                <ManageRoles id={id} mfi={Object(mfi)} />
              </TabPanel>
              <TabPanel>
                <ManageGroups id={id} mfi={Object(mfi)} />
              </TabPanel>
              <TabPanel>
                <ManageWorkflow id={id} mfi={Object(mfi)} />
              </TabPanel>
              <TabPanel>
                <ManageQuestionnaires id={id} mfi={Object(mfi)} />
              </TabPanel>
              <TabPanel>
                <ExternalIntegration
                  id={id}
                  mfi={Object(mfi)}
                  integrationData={externalIntegration}
                />
              </TabPanel>
              <TabPanel>
                <ScoreCardShare id={id} mfi={Object(mfi)} />
              </TabPanel>
              <TabPanel>
                <Tabs className={classes.customizationTabs}>
                  <TabList>
                    <Tab>{t("module.mfiSetup.menu.menus")}</Tab>
                    <Tab>{t("module.mfiSetup.menu.mpesa")}</Tab>
                    <Tab>{t("module.mfiSetup.menu.images")}</Tab>
                  </TabList>
                  <TabPanel>
                    <ManageCustomization id={id} panel={menuPanel} />
                  </TabPanel>
                  <TabPanel>
                    <ManageCustomization id={id} panel={mpesaPanel} />
                  </TabPanel>
                  <TabPanel>
                    <ManageCustomization id={id} panel={imagePanel} />
                  </TabPanel>
                </Tabs>
              </TabPanel>
            </Tabs>
          </Col>
        </Row>
      </Container>
    )
  }
)

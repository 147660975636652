import { clientInstance, handleResponse } from "../../../../../_helpers";

const getAllPendingTasks = async (mfiId, size, page) => {
  const res = await clientInstance
        .get(`overview/all-pending-tasks/${mfiId}?size=${size}&page=${page}`);
    return handleResponse(res);
};

const getBMAllPendingTasks = async (mfiId, size, page) => {
  const res = await clientInstance
        .get(`overview/pending-tasks/${mfiId}?size=${size}&page=${page}`);
    return handleResponse(res);
};

/**
 *
 * @param {string} mfiId
 * @param {string} size
 * @param {string} page
 * @param {object} payload
 * @returns
 */
const getSinglePendingTask = async (mfiId, size, page, payload) => {
    const res = await clientInstance
        .post(`overview/pending-tasks/view/${mfiId}?size=${size}&page=${page}`, payload);
    return handleResponse(res);
};

/**
 * Pending tasks service
 */
export const PendingTasksService = {
  getAllPendingTasks,
  getSinglePendingTask,
  getBMAllPendingTasks,
};

/* eslint-disable no-unused-expressions */
import React, { useState, useRef } from "react"
import { connect } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import {
  Container,
  FormGroup,
  FormCheckbox,
  Button,
  FormSelect,
} from "shards-react"
import CssBaseline from "@material-ui/core/CssBaseline"
import Svg from "react-inlinesvg"
import {
  FormWithConstraints,
  Input,
  FieldFeedbacks,
  FieldFeedback,
} from "react-form-with-constraints-bootstrap4"
import Header from "../../../components/widget/widgetTitle"
import { authenticationActions } from "../_actions"
import { useTranslation } from "react-i18next"
import i18next from "i18next"

const hidePasswordSVG = require("../../../assets/images/icons/hide-password.svg")
const showPasswordSVG = require("../../../assets/images/icons/show-password.svg")
const userHeaderSVG = require("../../../assets/images/icons/user-login-icon.svg")

const useStyles = makeStyles((theme) => ({
  container: {
    height: "calc(100vh - 46px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "400px",
    padding: 0,
    backgroundColor: theme.palette.common.white,
    borderRadius: "4px",
    boxShadow: "0 30px 50px 0 rgba(119, 151, 178, 0.2)",
    border: "solid 1px #cee0e9",
    color: "#002060",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    padding: "35px",
    paddingTop: "40px",
  },
  submit: {
    backgroundColor: "var(--primary)",
    margin: theme.spacing(3, 0, 2),
  },
  metaLinks: {
    "& a": {
      display: "block",
    },
  },
}))

const Login = ({ rememberMe, error, dispatch }) => {
  const classes = useStyles()

  const [username, SetUsername] = useState()
  const [password, SetPassword] = useState()
  const [passwordHidden, SetPasswordHidden] = useState(true)
  const [submitted, SetSubmitted] = useState(false)
  const [rememberPassword, SetRememberPassword] = useState(rememberMe || false)

  const form = useRef(null)

  function handleUsernameChange(e) {
    SetUsername(e.target.value)
    SetSubmitted(false)
  }

  function handlePasswordChange(e) {
    SetPassword(e.target.value)
    SetSubmitted(false)
  }

  function handleRememberPassword() {
    SetRememberPassword(!rememberPassword)
    SetSubmitted(false)
  }

  function handleSubmit(e) {
    e.preventDefault()
    SetSubmitted(true)
    if (submitted) {
      return
    }
    if (username && password) {
      dispatch(authenticationActions.login(username, password, "/"))
      dispatch(authenticationActions.rememberMe(rememberPassword))
    }
  }

  function togglePasswordShow() {
    SetPasswordHidden(!passwordHidden)
  }
  const { t } = useTranslation()

  function handleClickLocale(lang) {
    i18next.changeLanguage(lang)
  }

  function handleLocaleChange(e) {
    const {
      target: { value },
    } = e
    handleClickLocale(value)
  }

  return (
    <Container className={classes.container} component="main">
      <CssBaseline />
      <div className={classes.paper}>
        <Header
          title={t("module.authentication.component.login.headerTitle")}
          icon={
            <Svg className="text-center d-inline-block" src={userHeaderSVG} />
          }
        />
        <FormWithConstraints
          ref={form}
          noValidate
          name="form"
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <p>{t("module.authentication.component.login.instructions")}</p>
          <FormGroup className={error && "has-error"}>
            <label htmlFor="username">
              {t("module.authentication.component.login.username")}
            </label>
            <Input
              type="text"
              className="form-control"
              name="username"
              autoComplete="username"
              value={username}
              onChange={handleUsernameChange}
            />
            <FieldFeedbacks for="username">
              <FieldFeedback when="*" />
              <FieldFeedback when={submitted && !username}>
                {t("module.authentication.component.login.usernameError")}
              </FieldFeedback>
            </FieldFeedbacks>
          </FormGroup>

          <FormGroup className={error ? "has-error relative" : "relative"}>
            <label htmlFor="password">
              {t("module.authentication.component.login.password")}
            </label>
            <Input
              type={passwordHidden ? "password" : "text"}
              className="form-control"
              name="password"
              autoComplete="current-password"
              value={password}
              onChange={handlePasswordChange}
            />

            <div className="toggle-password" onClick={togglePasswordShow}>
              {passwordHidden && (
                <Svg
                  className="text-center d-inline-block"
                  src={hidePasswordSVG}
                />
              )}
              {!passwordHidden && (
                <Svg
                  className="text-center d-inline-block"
                  src={showPasswordSVG}
                />
              )}
            </div>
            <div className="language-selector__dropdown">
              <label htmlFor="language">
                {t("module.authentication.component.login.language")}
              </label>
              <FormSelect onChange={(e) => handleLocaleChange(e)}>
                <option value="" disabled selected hidden>
                  {(() => {
                    switch (localStorage.getItem("i18nextLng")) {
                      case "en":
                        return "English"
                      case "fr":
                        return "French"
                      case "my":
                        return "ဗမာ"
                      default:
                        return "Change Language"
                    }
                  })()}
                </option>
                <option value="en">English</option>
                <option value="fr">French</option>
                <option value="my">ဗမာ</option>
              </FormSelect>
            </div>
            <FieldFeedbacks for="password">
              <FieldFeedback when="*" />
              <FieldFeedback when={submitted && !password}>
                {t("module.authentication.component.login.passwordError")}
              </FieldFeedback>
            </FieldFeedbacks>
            {error && (
              <div className="invalid-feedback d-block mt-3">
                {error.error_description}
              </div>
            )}
          </FormGroup>

          <FormGroup>
            <FormCheckbox
              checked={rememberPassword}
              onChange={() => {
                handleRememberPassword()
              }}
            >
              {t("module.authentication.component.login.remember")}
            </FormCheckbox>
          </FormGroup>

          <FormGroup className="flex justify-between">
            <Link to="/forgot-password">
              {t("module.authentication.component.login.resetPassword")}
            </Link>
            <Button theme="primary" className="d-table btn" type="submit">
              {t("module.authentication.component.login.label")}
            </Button>
          </FormGroup>
        </FormWithConstraints>
      </div>
    </Container>
  )
}

function mapStateToProps(state) {
  const { loggingIn, error, rememberMe } = state.authentication
  return {
    loggingIn,
    rememberMe,
    error,
  }
}

const connectedLoginPage = connect(mapStateToProps)(Login)
export { connectedLoginPage as Login }

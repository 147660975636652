/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'rc-tooltip';
import { useTranslation } from 'react-i18next';
import 'rc-tooltip/assets/bootstrap_white.css';

function ComputationCodesSetup({scoreLogic, selectOne, selected = [], handler}) {
    const { uid, computationCodes } = scoreLogic;
    const surveyComputationCodes = computationCodes.filter((item) => item.surveyUuid === uid);
    const { t } = useTranslation();

    const [state, setState] = useState(selected);
    const returnType = 'computationCode';

    useEffect(() => {
        handler(returnType, { [returnType]: state });
    }, [state]);

    const selectItem = (which) => {
        let newState = [...state];

        if (selectOne) {
            newState = [];
        }

        const index = newState.indexOf(which);

        if (index === -1) {
            if (selectOne) {
                newState = [which];
            } else {
                newState.push(which);
            }
        } else {
            newState.splice(index, 1);
        }

        setState(newState);
    };

    return (
        <div className="computation-codes items-group mb-2 w-100">
            <h4>{t('module.scoreLogic.view.formula.computationCodes.title')}</h4>
            <div className="items-holder flex flex-wrap">
                {
                    surveyComputationCodes.map((code) => {
                        const { questionLabel, questionCode } = code;
                        const active = state && state.includes(questionCode) && 'variable-item-active';
                        return (
                            <Tooltip key={questionCode} placement="top" overlay={questionLabel} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                <div className={`item variable-item ${active}` } onClick={ () => { selectItem(code.questionCode); } }>{ questionCode }</div>
                            </Tooltip>
                        );
                    })
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { scoreLogic } = state;
    return {
        scoreLogic,
    };
};

const connectedComputationCodesSetup = connect(mapStateToProps)(ComputationCodesSetup);
export { connectedComputationCodesSetup as ComputationCodesSetup };

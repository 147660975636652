import { userManagerConstants } from "./_constants"

export const users = (state = {}, action) => {
  switch (action.type) {
    case userManagerConstants.GETALL_REQUEST:
      return { ...state, loading: true, error: null, items: null }
    case userManagerConstants.GETALL_SUCCESS:
      return { ...state, loading: null, error: null, items: action.users }
    case userManagerConstants.GETALL_FAILURE:
      return { ...state, loading: null, error: action.error, items: null }
    case userManagerConstants.GETALL_LO_REQUEST:
      return { ...state, loading: true, error: null, loItems: null }
    case userManagerConstants.GETALL_LO_SUCCESS:
      return { ...state, loading: null, error: null, loItems: action.users }
    case userManagerConstants.GETALL_LO_FAILURE:
      return { ...state, loading: null, error: action.error, loItems: null }
    case userManagerConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map((user) =>
          user.id === action.id ? { ...user, deleting: true } : user
        ),
      }
    case userManagerConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return state.items.filter((user) => user.id !== action.id)
    case userManagerConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map((user) => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error }
          }
          return user
        }),
      }
    case userManagerConstants.USER_UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map((user) =>
          Number(user.id) === Number(action.data.id)
            ? { ...user, ...action.data }
            : user
        ),
      }
    case userManagerConstants.USER_ACTIVATE:
      return {
        ...state,
        items: state.items.map((user) =>
          user.id === action.id ? { ...user, status: true } : user
        ),
      }
    case userManagerConstants.USER_DEACTIVATE:
      return {
        ...state,
        items: state.items.map((user) =>
          user.id === action.id ? { ...user, status: false } : user
        ),
      }
    default:
      return state
  }
}

import React, { useState, useRef, useEffect } from 'react';
import {
  FormWithConstraints, Input, FieldFeedbacks, FieldFeedback,
} from 'react-form-with-constraints-bootstrap4';
// import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, FormGroup } from 'shards-react';
import { mfiManagerActions } from '../../_actions';

export function ExternalIntegration({ id, mfi, integrationData }) {
  const form = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(mfiManagerActions.getExternalIntegration(id));
  }, [dispatch, id]);

  const [musoni, setMusoni] = useState({
    exportId: integrationData.musoniPlatform?.exportId,
    dataTableName: integrationData.musoniPlatform?.dataTableName,
    clientSurveyTitle: integrationData.musoniPlatform?.clientSurveyTitle,
    clientSurveyDescription: integrationData.musoniPlatform?.clientSurveyDescription,
  });

  const handleChange = async ({ target, currentTarget }) => {
    const { name, value } = currentTarget;
    setMusoni((prevState) => ({ ...prevState, [name]: value }));
    await form.current.validateFields(target);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await form.current.validateForm();
    const formIsValid = form.current.isValid();

    if (!formIsValid) {
      return false;
    }
    const payload = {
      mfiId: Number(id),
      name: 'MUSONI',
      musoniPlatform: musoni,
    }

    integrationData.musoniPlatform !== null && dispatch(mfiManagerActions.editExternalIntegration(Number(integrationData?.id), payload));
    integrationData.musoniPlatform === null && dispatch(mfiManagerActions.addExternalIntegration(payload));

    return false;
  };

  return (
    <FormWithConstraints ref={form} noValidate className="mfi-add-form shadow-none p-10" onSubmit={handleSubmit}>
      <h2 className="form-group-section">External Platform Integration</h2>
      <div className="flex">
        <div className="w-1/2 pr-10">
          <div>
            <FormGroup>
              <label htmlFor="exportId">Export ID</label>
              <Input type="text" id="exportId" name="exportId" className="form-control" required defaultValue={musoni.exportId} onChange={handleChange} />
              <FieldFeedbacks for="exportId">
                <FieldFeedback when="*" />
              </FieldFeedbacks>
            </FormGroup>

            <FormGroup>
              <label htmlFor="dataTableName">Data Table Name</label>
              <Input type="text" id="dataTableName" name="dataTableName" required className="form-control" defaultValue={musoni.dataTableName} onChange={handleChange} />
              <FieldFeedbacks for="dataTableName">
                <FieldFeedback when="*" />
              </FieldFeedbacks>
            </FormGroup>

            <FormGroup>
              <label htmlFor="clientSurveyTitle">Title</label>
              <Input type="text" id="clientSurveyTitle" name="clientSurveyTitle" required className="form-control" defaultValue={musoni.clientSurveyTitle} onChange={handleChange} />
              <FieldFeedbacks for="clientSurveyTitle">
                <FieldFeedback when="*" />
              </FieldFeedbacks>
            </FormGroup>

            <FormGroup>
              <label htmlFor="clientSurveyDescription">Description</label>
              <Input type="text" id="clientSurveyDescription" name="clientSurveyDescription" className="form-control" defaultValue={musoni.clientSurveyDescription} onChange={handleChange} />
            </FormGroup>

            <Button>{integrationData.musoniPlatform !== null ? 'Edit' : 'Add'} Integration</Button>
          </div>
        </div>
      </div>
    </FormWithConstraints>
  );
}

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
    ButtonGroup,
    Button,
} from 'shards-react';
import Header from '../../../components/widget/widgetTitle';
import { Loading } from '../../../components/exceptions/Loading';
import { modalActions } from '../../modals/_actions';
import { documentChecklistActions } from '..';

export function DeleteApplicationChecklist({ modalID, data }) {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const deleteApplicationChecklist = async () => {
        setIsLoading(true);
        await dispatch(documentChecklistActions.deleteApplicationChecklist(data));
        setIsLoading(false);
        closeModal();
    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal">
            <Header title={'Delete'} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal }/>
            <div className="p-10">
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <p>Are you sure you want to delete the selected File?</p>
                    <ButtonGroup className="btn-group-actions site-modal__actions">
                        <Button
                          theme="primary"
                          className="thin delete mr-2"
                          onClick={ () => { deleteApplicationChecklist(); } }>
                          Yes
                        </Button>
                        <Button theme="secondary" className="thin" onClick={ () => { closeModal(); } }>Cancel</Button>
                    </ButtonGroup>
                  </>
                )
                }

            </div>
        </div>
    );
}

import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import Chart from '../../../utils/chart';
import { useTranslation } from 'react-i18next';

export function CashflowGraph({
    title, subTitle, chartData, options,
}) {
    const canvasRef = useRef();
    const settingsCurrency = useSelector(state => state.settings.currency);

    const {
        averageNetCashFlow, averageHouseholdOperatingMargin, averageFarmOperatingMargin, farmOperatingMargin, householdOperatingMargin, netCashFlow, projectionPeriod,
    } = chartData;
    const { t } = useTranslation();

    useEffect(() => {
        const dataSet = {
            farmOperatingMargin,
            householdOperatingMargin,
            netCashFlow,
        };

        const datasets = Object.keys(dataSet).map((key) => {
            let config = {};

            switch (key) {
            case 'farmOperatingMargin':
                config = {
                    label: t('module.creditScore.component.cashflowGraph.farmOperatingMargin'),
                    borderColor: 'rgba(0,123,255,1)',
                    backgroundColor: 'rgba(0,123,255,1)',
                    pointBackgroundColor: '#ffffff',
                    pointHoverBackgroundColor: 'rgb(0,123,255)',
                };

                break;

            case 'householdOperatingMargin':
                config = {
                    label: t('module.creditScore.component.cashflowGraph.householdOperatingMargin'),
                    borderColor: '#107D54',
                    backgroundColor: '#107D54',
                    pointBackgroundColor: '#ffffff',
                    pointHoverBackgroundColor: '#107D54',
                };
                break;

            case 'netCashFlow':
                config = {
                    label: t('module.creditScore.component.cashflowGraph.netCashflow'),
                    borderColor: '#FAA05A',
                    backgroundColor: '#FAA05A',
                    pointBackgroundColor: '#ffffff',
                    pointHoverBackgroundColor: '#FAA05A',
                };
                break;
            default:
                break;
            }

            const itemConfig = {
                fill: false,
                borderWidth: 4,
                pointRadius: 0,
                pointHoverRadius: 3,
                data: dataSet[key],
            };

            return { ...itemConfig, ...config };
        });

        const chartData2 = {
            labels: projectionPeriod,
            datasets,
        };

        const chartOptions = {
            ...{
                responsive: true,
                legend: {
                    position: 'bottom',
                },
                elements: {
                    line: {
                        // A higher value makes the line look skewed at this ratio.
                        tension: 0.3,
                    },
                    point: {
                        radius: 0,
                    },
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: false,
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: t('module.creditScore.component.cashflowGraph.projectionPeriod'),
                            },
                            ticks: {
                                callback(tick, index) {
                                    // Jump every 1 values on the X axis labels to avoid clutter.
                                    return index % 1 !== 0 ? '' : tick;
                                },
                            },
                        },
                    ],
                    yAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: t('module.creditScore.component.cashflowGraph.amount'),
                            },
                            ticks: {
                                suggestedMax: 45,
                                callback(tick) {
                                    if (tick === 0) {
                                        return tick;
                                    }
                                    // Format the amounts using Ks for thousands.
                                    return tick > 999 ? `${(tick / 1000).toFixed(1)}K` : tick;
                                },
                            },
                        },
                    ],
                },
                hover: {
                    mode: 'nearest',
                    intersect: false,
                },
                tooltips: {
                    custom: false,
                    mode: 'nearest',
                    intersect: false,
                },
            },
            ...options,
        };

        const dataGraph = new Chart(canvasRef.current, {
            type: 'line',
            data: chartData2,
            options: chartOptions,
        });

        // They can still be triggered on hover.
        const buoMeta = dataGraph.getDatasetMeta(0);

        if (buoMeta.data[0]) {
            // eslint-disable-next-line no-underscore-dangle
            buoMeta.data[0]._model.radius = 0;
            // eslint-disable-next-line no-underscore-dangle
            buoMeta.data[chartData2.datasets[0].data.length - 1]._model.radius = 0;
        }

        // Render the chart.
        dataGraph.render();
    }, [chartData, farmOperatingMargin, householdOperatingMargin, netCashFlow, options, projectionPeriod, t]);


    // TODO: change this to update based on mfi
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: settingsCurrency && settingsCurrency.trim() ? settingsCurrency : 'KES',
        minimumFractionDigits: 2,
    });

    return (
        <div className="p-10">
            <div className="flex graph-key mb-4">
                <div className="title">
                    <h4 className="mb-1">{t('module.creditScore.component.cashflowGraph.cashflowStatements')}</h4>
                    <h6 className="m-0 text-sm">{t('module.creditScore.component.cashflowGraph.comparisonOfCashflows')}</h6>
                </div>

                <div className="averages-group ml-auto">
                    <h5 className="title mb-2">{t('module.creditScore.component.cashflowGraph.averages')}</h5>
                    <div className="flex">
                        <div className="net-cashflow ml-auto mr-4">
                            <h5 className="value mb-1">{ formatter.format(averageNetCashFlow) }</h5>
                            <p className="title mb-0 text-xs">{t('module.creditScore.component.cashflowGraph.netCashflow')}</p>
                        </div>

                        <div className="household-operating-margin mx-4">
                            <h5 className="value mb-1">{formatter.format(averageHouseholdOperatingMargin)}</h5>
                            <p className="title mb-0 text-xs">{t('module.creditScore.component.cashflowGraph.householdOperatingMargin')}</p>
                        </div>

                        <div className="farm-operating-margin">
                            <h5 className="value mb-1">{formatter.format(averageFarmOperatingMargin)}</h5>
                            <p className="title mb-0 text-xs">{t('module.creditScore.component.cashflowGraph.farmOperatingMargin')}</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className="graph">
                <canvas
                    height="120"
                    ref={canvasRef}
                    style={{ maxWidth: '100% !important' }}
                />
            </div>
        </div>
    );
}

CashflowGraph.propTypes = {

    /**
   * The component's title.
   */
    title: PropTypes.string,
    /**
   * The chart dataset.
   */
    chartData: PropTypes.object,
    /**
   * The Chart.js options.
   */
    chartOptions: PropTypes.object,
};

CashflowGraph.defaultProps = {
    title: 'Cashflow Statements',
    subTitle: 'Comparison of cashflows',
    chartData: {
        labels: Array.from(new Array(30), (_, i) => (i === 0 ? 1 : i)),
        datasets: [
            {
                label: 'Net Cashflow',
                fill: 'start',
                data: [
                    500,
                    800,
                    320,
                    180,
                    240,
                    320,
                    230,
                    650,
                    590,
                    1200,
                    750,
                    940,
                    1420,
                    1200,
                    960,
                    1450,
                    1820,
                    2800,
                    2102,
                    1920,
                    3920,
                    3202,
                    3140,
                    2800,
                    3200,
                    3200,
                    3400,
                    2910,
                    3100,
                    4250,
                ],
                backgroundColor: 'rgba(0,123,255,0.0)',
                borderColor: 'rgba(0,123,255,1)',
                pointBackgroundColor: '#ffffff',
                pointHoverBackgroundColor: 'rgb(0,123,255)',
                borderWidth: 3,
                pointRadius: 0,
                pointHoverRadius: 3,
            },
            {
                label: 'Household operating margin',
                fill: 'start',
                data: [
                    380,
                    430,
                    120,
                    230,
                    410,
                    740,
                    472,
                    219,
                    391,
                    229,
                    400,
                    203,
                    301,
                    380,
                    291,
                    620,
                    700,
                    300,
                    630,
                    402,
                    320,
                    380,
                    289,
                    410,
                    300,
                    530,
                    630,
                    720,
                    780,
                    1200,
                ],
                backgroundColor: 'rgba(255,65,105,0.0)',
                borderColor: 'rgba(255,65,105,1)',
                pointBackgroundColor: '#ffffff',
                pointHoverBackgroundColor: 'rgba(255,65,105,1)',
                borderWidth: 3,
                pointRadius: 0,
                pointHoverRadius: 3,
                pointBorderColor: 'rgba(255,65,105,1)',
            },
            {
                label: 'Farm Operating margin',
                fill: 'start',
                data: [
                    180,
                    430,
                    120,
                    230,
                    450,
                    740,
                    472,
                    219,
                    321,
                    229,
                    500,
                    203,
                    311,
                    380,
                    241,
                    620,
                    720,
                    400,
                    730,
                    232,
                    820,
                    570,
                    589,
                    210,
                    400,
                    830,
                    730,
                    520,
                    980,
                    1200,
                ],
                backgroundColor: 'rgba(50, 210, 150,0.0)',
                borderColor: '#32d296',
                pointBackgroundColor: '#ffffff',
                pointHoverBackgroundColor: '#32d296',
                borderWidth: 3,
                pointRadius: 0,
                pointHoverRadius: 3,
                pointBorderColor: '#32d296',
            },
        ],
    },
};

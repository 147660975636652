import React from 'react';
import { WithWizard } from 'react-albus';
import { connect } from 'react-redux';
import { findIndex, reverse, dropRight } from 'lodash';

function Navigation({ titles, scoreLogic }) {
    const { uid } = scoreLogic;

    return (
        <WithWizard
            render={({ push, step, steps }) => {
                const reversed = reverse([...steps]); // copy steps array and reverse order
                const index = findIndex(reversed, step); // find index of first item in reversed array
                const dropped = dropRight(steps, index); // Drop from end of array, drop items based on index found.

                return (
                    <div key={ index } className="score-logic-setup__navigation h-full">
                        {
                            steps.map((item, idx) => {
                                const { id } = item;
                                const current = step.id === id ? 'current' : '';
                                const active = findIndex(dropped, item) !== -1 ? 'active' : ''; // cross check current step with dropped array
                                // eslint-disable-next-line no-unused-expressions
                                return (<div key={ idx } className={ `link ${active} ${current}` } onClick={ () => { uid && push(item.id); } }><span className="counter">{idx + 1}</span> <span className="label">{ titles[id] }</span></div>);
                            })
                        }
                    </div>
                );
            }}
        />
    );
}

function mapStateToProps(state) {
    const { scoreLogic } = state;
    return {
        scoreLogic,
    };
}

const connectedNavigation = connect(mapStateToProps)(Navigation);
export { connectedNavigation as Navigation };

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';

import './index.scss';

function ComputationCodes({ scoreLogic }) {
    const { uid, computationCodes } = scoreLogic;
    const surveyComputationCodes = computationCodes.filter((item) => item.surveyUuid === uid);
    const { t } = useTranslation();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
      // scroll page to top on displaying component
      const wrapper = document.getElementsByClassName("main-content");
      wrapper[0].scrollIntoView();
    }, []);

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, surveyComputationCodes.length - page * rowsPerPage);

    const generateRows = (data) => data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => (
        <TableRow key={idx}>
            <TableCell align="left" component="th" style={{ width: '15%' }} scope="row">{row.questionCode}</TableCell>
            <TableCell align="left">{row.questionLabel}</TableCell>
            <TableCell align="left">{row.answerType}</TableCell>
            <TableCell align="right">{row.questionKey}</TableCell>
        </TableRow>
    ));

    return (
        <Paper>
            <div className="table-wrapper">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" component="th" style={{ width: '15%' }}>{t('module.scoreLogic.view.computationCodes.questionCode')}</TableCell>
                            <TableCell align="left">{t('module.scoreLogic.view.computationCodes.description')}</TableCell>
                            <TableCell align="left">{t('module.scoreLogic.view.computationCodes.type')}</TableCell>
                            <TableCell align="right">{t('module.scoreLogic.view.computationCodes.questionKey')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { generateRows(surveyComputationCodes) }
                        { emptyRows > 0 && (
                            <TableRow style={{ height: 25 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={surveyComputationCodes.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                onPageChange={handleChangePage}
                labelRowsPerPage={t('module.client.arrears.rowsPerPage')}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

const mapStateToProps = (state) => {
    const { scoreLogic } = state;
    return {
        scoreLogic,
    };
};

const connectedComputationCodes = connect(mapStateToProps)(ComputationCodes);
export { connectedComputationCodes as ComputationCodes };

import { alertActions } from '../alert';
import { isEmpty } from 'lodash';
import { documentChecklistConstants, documentChecklistService } from '.';

export const documentChecklistActions = {
    getApplicationChecklist,
    saveApplicationChecklist,
    createSurveryChecklist,
    deleteApplicationChecklist,
    uploadApplicationChecklist,
};

function getApplicationChecklist(id) {
    return (dispatch) => {
        dispatch(request({}));

        try {
            if (isEmpty(id)) {
                throw new Error('Application ID cannot be empty');
            }

            documentChecklistService.getApplicationChecklist(id)
                .then(
                    (data) => {
                        dispatch(success(data));
                    },
                    (error) => {
                        dispatch(failure(error));
                    },
                );
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: documentChecklistConstants.DOCUMENT_CHECKLIST_REQUEST, data }; }
    function success(data) { return { type: documentChecklistConstants.DOCUMENT_CHECKLIST_SUCCESS, data }; }
    function failure(error) { return { type: documentChecklistConstants.DOCUMENT_CHECKLIST_FAILURE, error }; }
}

function saveApplicationChecklist(applicationData) {
    return (dispatch) => {
        dispatch(request({}));

        try {
            if (isEmpty(applicationData)) {
                throw new Error('Invalid Data');
            }

            documentChecklistService.saveApplicationChecklist(applicationData)
                .then(
                    (data) => {
                        dispatch(success(applicationData));
                    },
                    (error) => {
                        dispatch(failure(error));
                    },
                );
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: documentChecklistConstants.DOCUMENT_CHECKLIST_CREATE_REQUEST, data }; }
    function success(data) { return { type: documentChecklistConstants.DOCUMENT_CHECKLIST_CREATE_SUCCESS, data }; }
    function failure(error) { return { type: documentChecklistConstants.DOCUMENT_CHECKLIST_CREATE_FAILURE, error }; }
}

function createSurveryChecklist(applicationData) {
    return (dispatch) => {
        dispatch(request({}));

        try {
            if (isEmpty(applicationData)) {
                throw new Error('Invalid Data');
            }

            documentChecklistService.saveApplicationChecklist(applicationData)
                .then(
                    (data) => {
                        dispatch(success(data));
                    },
                    (error) => {
                        dispatch(failure(error));
                    },
                );
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: documentChecklistConstants.DOCUMENT_CHECKLIST_CREATE_REQUEST, data }; }
    function success(data) { return { type: documentChecklistConstants.DOCUMENT_CHECKLIST_CREATE_SUCCESS, data }; }
    function failure(error) { return { type: documentChecklistConstants.DOCUMENT_CHECKLIST_CREATE_FAILURE, error }; }
}

function deleteApplicationChecklist(data) {
    return (dispatch) => {
        dispatch({ type: documentChecklistConstants.DOCUMENT_CHECKLIST_DELETE_REQUEST, data: {} });
        documentChecklistService.deleteApplicationChecklist(data)
            .then(
                (res) => {
                    dispatch(alertActions.success('Delete successful'));
                    dispatch({ type: documentChecklistConstants.DOCUMENT_CHECKLIST_DELETE_SUCCESS, data: res });
                    // update checklist data
                    dispatch(getApplicationChecklist(data.applicationId));
                },
                (error) => {
                    dispatch(alertActions.error('Delete failed: ', error));
                    dispatch({ type: documentChecklistConstants.DOCUMENT_CHECKLIST_DELETE_FAILURE, error });
                },
            );
    };
}

function uploadApplicationChecklist(file) {
    return (dispatch) => {
        dispatch({ type: documentChecklistConstants.DOCUMENT_CHECKLIST_UPLOAD_REQUEST, data: {} });
        documentChecklistService.uploadApplicationChecklist(file)
            .then(
                (data) => {
                    dispatch(alertActions.success('Upload successful'));
                    dispatch({ type: documentChecklistConstants.DOCUMENT_CHECKLIST_UPLOAD_SUCCESS, data });
                    // update checklist data
                    dispatch(getApplicationChecklist(file.applicationId));
                },
                (error) => {
                    dispatch(alertActions.error('Upload failed', error));
                    dispatch({ type: documentChecklistConstants.DOCUMENT_CHECKLIST_UPLOAD_FAILURE, error });
                },
            );
    };
}

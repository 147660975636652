import React from "react"
import { useDispatch } from "react-redux"
import PersonOutlineOutlinedIcon from "@material-ui/icons/LockOutlined"
import { Box, Typography } from "@material-ui/core"
import Header from "../../../components/widget/widgetTitle"
import { modalActions } from "../../modals/_actions"

export function PreviewApplicationChecklist({ modalID, data }) {
  const dispatch = useDispatch()
  const date = new Date(data.application?.submissionTime)

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  return (
    <div className="site-modal">
      <Header
        title={data.modalTitle}
        icon={<PersonOutlineOutlinedIcon />}
        close={true}
        closeHandler={closeModal}
      />
      <Box
        position="relative"
        display="inline-block"
        className="site-modal-body"
      >
        <img
          className="preview_file"
          alt={data.modalTitle}
          src={data.documentUrl}
        />
        {/* Overlay Text */}
        <Typography
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white", // Text color
            backgroundColor: "rgba(0, 0, 0, 0.2)", // Optional semi-transparent background
            padding: "7px",
            borderRadius: "5px", // Rounded corners for background
            fontSize: "small",
          }}
        >
          {data.application?.submittedBy} {date.toLocaleDateString()}
        </Typography>
      </Box>
    </div>
  )
}

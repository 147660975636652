/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { surveyActions } from '../../surveys';
import { userManagerActions } from '../../userManager';
import { authenticationActions } from '../../authentication';
import { applicationActions } from '..';


import { Container} from 'shards-react';
import { ComponentSwitch } from './ComponentSwitch';

export const Applications = () => {
    const applications = useSelector(state => state.applications);
    const surveys = useSelector(state => state.surveys);
    const authentication = useSelector(state => state.authentication);
    const dispatch = useDispatch();


    useEffect(() => {
      // set default page to ListApplication
      dispatch(applicationActions.applicationClicked({page: 'ListApplication', history: false}));
      // filter applications by loged in user by default
      dispatch(applicationActions.filterState({...applications.filterState, user: JSON.parse(localStorage.getItem('user')).user_name}));
    }, []);

    /**
     * Fetch Assets, Applications and Users on first load
     */
    useEffect(() => {
        dispatch(userManagerActions.getAll());
        dispatch(authenticationActions.getProfile());
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
      if (authentication.profile.mfi) {
        dispatch(surveyActions.getSurveys(authentication.profile.mfi.id));
      }
    }, [authentication.profile]);


    return (
        <Container fluid className="main-content-container px-4">
            <ComponentSwitch tag={applications.applicationClicked.page} />
        </Container>
    );
};

import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, filter } from 'lodash';
import { Projection } from './components/Projection';
import { Loan } from './components/Loan';

export const ManageProjectionPeriod = () => {
    const scoreLogic = useSelector((store) => store.scoreLogic);
    const {uid, computationCodes, formulas, scoreCardProfile} = scoreLogic;
    const [surveyComputationCodes, setSurveyComputationCodes] = useState([]);

    useEffect(() => {
      // scroll page to top on displaying component
      const wrapper = document.getElementsByClassName("main-content");
      wrapper[0].scrollIntoView();
    }, []);

    useEffect(() => {
        const codes = computationCodes && computationCodes.filter(({ surveyUuid }) => surveyUuid === uid);
        if (!isEmpty(codes)) {
            setSurveyComputationCodes([...codes]);
        } else {
            setSurveyComputationCodes([]);
        }
    }, [computationCodes, uid]);

    const getCodeLabel = (xCode) => {
        const [filtered] = filter(surveyComputationCodes, { questionCode: xCode });
        const questionCode = Object(filtered);
        return `${questionCode.questionLabel} (${xCode})`;
    };

    return (
        <Fragment>
            <Projection uid={uid} surveyComputationCodes={surveyComputationCodes} formulas={formulas} scoreCardProfile={scoreCardProfile} getCodeLabel={getCodeLabel} />
            <Loan uid={uid} surveyComputationCodes={surveyComputationCodes} formulas={formulas} getCodeLabel={getCodeLabel}/>
        </Fragment>
    );
};

export const notificationConstants = {
    FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
    FETCH_NOTIFICATIONS_FAILURE: 'FETCH_NOTIFICATIONS_FAILURE',
    FETCH_NOTIFICATIONS_REQUEST: 'FETCH_NOTIFICATIONS_REQUEST',

    GET_REALTIME_NOTIFICATIONS: 'GET_REALTIME_NOTIFICATIONS',


    MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS: 'MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS',
    MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE: 'MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE',
    MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST: 'MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST',

    CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
}

import { isEmpty } from "lodash"
import { creditScoreConstants, creditScoreService } from "."
import { alertActions } from "../alert"

export const creditScoreActions = {
  getScoreCard,
  getFormulaResult,
  getJsonChanges,
  getCreditScoreReport,
  getCashFlowAnalysis,
}

function getScoreCard(id) {
  return (dispatch) => {
    dispatch(request(id))
    try {
      if (isEmpty(id)) {
        throw new Error("ID cannot be empty")
      }
      creditScoreService.getScoreCard(id).then(
        (data) => {
          dispatch(success(data))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(alertActions.error(error.response.data.message))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request(data) {
    return { type: creditScoreConstants.CSL_SCORE_CARD_REQUEST, data }
  }
  function success(data) {
    return { type: creditScoreConstants.CSL_SCORE_CARD_SUCCESS, data }
  }
  function failure(error) {
    return { type: creditScoreConstants.CSL_SCORE_CARD_FAILURE, error }
  }
}

function getFormulaResult(id) {
  return (dispatch) => {
    dispatch(request(id))
    try {
      if (isEmpty(id)) {
        throw new Error("ID cannot be empty")
      }
      creditScoreService.getFormulaResult(id).then(
        (data) => {
          dispatch(success(data))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request(data) {
    return { type: creditScoreConstants.CSL_FORMULA_RESULTS_REQUEST, data }
  }
  function success(data) {
    return { type: creditScoreConstants.CSL_FORMULA_RESULTS_SUCCESS, data }
  }
  function failure(error) {
    return { type: creditScoreConstants.CSL_FORMULA_RESULTS_FAILURE, error }
  }
}

function getJsonChanges(id) {
  return (dispatch) => {
    dispatch(request(id))
    try {
      if (isEmpty(id)) {
        throw new Error("ID cannot be empty")
      }
      creditScoreService.getJsonChanges(id).then(
        (data) => {
          dispatch(success(data))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request(data) {
    return { type: creditScoreConstants.CSL_JSON_CHANGES_REQUEST, data }
  }
  function success(data) {
    return { type: creditScoreConstants.CSL_JSON_CHANGES_SUCCESS, data }
  }
  function failure(error) {
    return { type: creditScoreConstants.CSL_JSON_CHANGES_FAILURE, error }
  }
}

function getCreditScoreReport(id) {
  return (dispatch) => {
    dispatch(request(id))
    try {
      if (isEmpty(id)) {
        throw new Error("ID cannot be empty")
      }
      creditScoreService.getCreditScoreReport(id).then(
        (data) => {
          dispatch(success(data))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request(data) {
    return { type: creditScoreConstants.CSL_CREDIT_SCORE_REPORT_REQUEST, data }
  }
  function success(data) {
    return { type: creditScoreConstants.CSL_CREDIT_SCORE_REPORT_SUCCESS, data }
  }
  function failure(error) {
    return { type: creditScoreConstants.CSL_CREDIT_SCORE_REPORT_FAILURE, error }
  }
}

function getCashFlowAnalysis(id) {
  return (dispatch) => {
    dispatch(request(id))
    try {
      if (isEmpty(id)) {
        throw new Error("ID cannot be empty")
      }
      creditScoreService.getCashFlowAnalysis(id).then(
        (data) => {
          dispatch(success(data))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request(data) {
    return { type: creditScoreConstants.CASH_FLOW_ANALYSIS_REQUEST, data }
  }
  function success(data) {
    return { type: creditScoreConstants.CASH_FLOW_ANALYSIS_SUCCESS, data }
  }
  function failure(error) {
    return { type: creditScoreConstants.CASH_FLOW_ANALYSIS_FAILURE, error }
  }
}

import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'shards-react';
import Svg from 'react-inlinesvg';
import { publisherActions } from '../../publisher';
import { useTranslation } from 'react-i18next';

const iconSyncSVG = require('../../../assets/images/icons/sync-icon.svg');

function ApplicationsSync(props) {
    const { className, applications, dispatch } = props;
    const { loading, uid } = applications;
    const { t } = useTranslation();
    const Sync = () => {
        const [xFormId] = uid.split(':');
        dispatch(publisherActions.syncApplications(xFormId));
    };

    return (
        <Button onClick={ () => { Sync(); } } className={`${className}`}><span className="mr-1">{!loading && <Svg className="text-center d-inline-block h-3" src={ iconSyncSVG } /> }</span><span>{t("module.applicationManager.component.applicationsSync.button")}</span></Button>
    );
}

const mapStateToProps = (state) => {
    const { applications } = state;
    return { applications };
};

const connectedApplicationsSync = connect(mapStateToProps)(ApplicationsSync);
export { connectedApplicationsSync as ApplicationsSync };

export const userRoleConstants = {
    MFI_CREDIT_ANALYST: 'MFI_CREDIT_ANALYST',
    MFI_LOAN_OFFICER: 'MFI_LOAN_OFFICER',
    MFI_ADMIN: 'MFI_ADMIN',
    MFI_SUPERVISOR: 'MFI_SUPERVISOR',
    FA_ADMIN: 'FA_ADMIN',
    FA_ANALYST: 'FA_ANALYST',
};

export const mfiRoleConstants = {
    MFI_CREDIT_ANALYST: 'MFI_CREDIT_ANALYST',
    MFI_LOAN_OFFICER: 'MFI_LOAN_OFFICER',
    MFI_ADMIN: 'MFI_ADMIN',
    MFI_SUPERVISOR: 'MFI_SUPERVISOR',
};

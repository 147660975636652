// formatNumericValue.js
const formatNumericValue = (value) => {
  if (value !== undefined && value !== null) {
    const cleanedValue = value.replace(/['"]+/g, "")

    if (!isNaN(cleanedValue)) {
      const formattedValue = Number(cleanedValue).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })

      return formattedValue
    } else {
      return cleanedValue
    }
  }

  return "null" // Return "null" if the value is undefined or null
}

export default formatNumericValue

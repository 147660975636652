import React, { useEffect } from 'react';
import { Button } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RolesTable } from './RolesTable';
import { modalActions } from '../../../modals';
import { mfiManagerActions } from '../..';

export function ManageRoles({ id: mfiID, mfi }) {
    const { mfiCode } = mfi;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { subRoles = [] } = useSelector((store) => store.mfis);

    const tableSubRoles = subRoles.map((item) => ({ ...{ action: '' }, ...item, ...{ status: 'active' } }));

    useEffect(() => {
        dispatch(mfiManagerActions.getMFISubRoles(mfiID));
    }, [dispatch, mfiID]);

    return (
        <>
            <RolesTable data={ tableSubRoles } mfiID={mfiID} />
            <Button className="m-4" onClick={ () => { dispatch(modalActions.manageMFISubRoles({ mfiID, mfiCode })); } }>{t('module.mfiSetup.manageRole.addRole')}</Button>
        </>
    );
}

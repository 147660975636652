import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, FormCheckbox, FormRadio } from "shards-react"
import Header from "../../../components/widget/widgetTitle"
import { useTranslation } from "react-i18next"
import { modalActions } from "../../modals/_actions"
import { applicationActions } from ".."
import Svg from "react-inlinesvg"
import { alertActions } from "../../alert"
import { hasAuthority, userRoleConstants } from "../../../_helpers"
import { reportsActions } from "../../reports"

const toolsIcon = require("../../../assets/images/icons/tools-icon.svg")

export function ApplicationOfficeFilter({ modalID, data }) {
  const applications = useSelector((state) => state.applications)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { hierarchy, mfiIdInput } = useSelector((store) => store.reports)
  const { profile } = useSelector((store) => store.authentication)

  const { isGroupFilter } = data || {}

  const [officeConfig, setOfficeConfig] = useState([])
  const [officeActive, setOfficeActive] = useState(
    applications.filterState.offices
  )
  const [groupConfig, setGroupConfig] = useState([])
  const [groupActive, setGroupActive] = useState(applications.filterState.group)

  useEffect(() => {
    if (hierarchy && !isGroupFilter) {
      const hierarchyLayer = hierarchy.filter(
        (item, i) =>
          item.name.localeCompare("Branch", undefined, {
            sensitivity: "accent",
          }) === 0
      )
      if (hierarchyLayer && hierarchyLayer.length > 0) {
        setOfficeConfig(hierarchyLayer[0].entities)
      }
    }
  }, [hierarchy])

  //populate group config list
  useEffect(() => {
    if (isGroupFilter && applications.items?.length > 0) {
      const groups = applications.items
        .filter((item) => item.json?.Group !== (undefined && ""))
        .map((item) => item.json?.Group)
      const distinctGroups = [...new Set(groups)]
      if (distinctGroups.length > 0) setGroupConfig(distinctGroups)
    }
  }, [applications, isGroupFilter])

  useEffect(() => {
    // get hierarchy data.
    if (
      (hasAuthority(userRoleConstants.FA_ANALYST) ||
        hasAuthority(userRoleConstants.FA_ADMIN)) &&
      !isGroupFilter
    ) {
      if (mfiIdInput) {
        dispatch(reportsActions.getHierarchy(mfiIdInput))
      }
    } else if (profile.mfi) {
      dispatch(reportsActions.getHierarchy(profile.mfi.id))
    }
  }, [dispatch, profile, mfiIdInput])

  const handleClick = (name) => {
    if (!isGroupFilter) {
      if (officeActive.includes(name)) {
        const newOffices = officeActive.filter((office) => office !== name)
        setOfficeActive(newOffices)
      } else {
        setOfficeActive([...officeActive, name])
      }
    } else {
      setGroupActive([name])
    }
  }

  const officeFilter = (reset) => {
    if (reset) {
      dispatch(alertActions.success(t("alertActions.applications.filterReset")))
      dispatch(applicationActions.officeFilter(""))
      dispatch(
        applicationActions.filterState({
          ...applications.filterState,
          offices: [],
          user: "",
          group: [],
        })
      )
    } else {
      dispatch(
        alertActions.success(
          `Displaying applications from ${officeActive.join()}`
        )
      )
      dispatch(applicationActions.officeFilter(officeActive))
      dispatch(
        applicationActions.filterState({
          ...applications.filterState,
          offices: officeActive,
          group: [],
        })
      )
    }
    closeModal()
  }

  const groupFilter = (reset) => {
    if (reset) {
      dispatch(alertActions.success(t("alertActions.applications.filterReset")))
      dispatch(applicationActions.groupFilter(""))
      dispatch(
        applicationActions.filterState({
          ...applications.filterState,
          offices: [],
          user: "",
          group: [],
        })
      )
    } else {
      dispatch(
        alertActions.success(
          `Displaying applications from ${groupActive.join()}`
        )
      )
      dispatch(applicationActions.groupFilter(groupActive))
      dispatch(
        applicationActions.filterState({
          ...applications.filterState,
          offices: [],
          group: groupActive,
        })
      )
    }
    closeModal()
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  return (
    <div className="site-modal">
      <Header
        title={
          isGroupFilter
            ? t("module.applications.filterByGroup")
            : t("module.applications.filterByOffice")
        }
        icon={<Svg className="text-center d-inline-block" src={toolsIcon} />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        {!isGroupFilter && officeConfig && officeConfig.length > 0 && (
          <>
            {officeConfig.map((item, idx) => (
              <FormCheckbox
                key={idx}
                name="offices"
                checked={officeActive.includes(item.name.trim())}
                onChange={() => handleClick(item.name.trim())}
              >
                {item.name}
              </FormCheckbox>
            ))}
            <br />
            <div className="btn-group-actions site-modal__actions flex justify-end">
              <Button
                theme="light"
                className="mr-2"
                onClick={() => officeFilter(true)}
              >
                {t("module.applications.reset")}
              </Button>
              <Button
                theme="primary"
                className="delete mr-2"
                onClick={() => officeFilter()}
              >
                {t("module.applications.filter")}
              </Button>
            </div>
          </>
        )}
        {isGroupFilter && groupConfig && groupConfig.length > 0 && (
          <>
            {groupConfig.map((item, idx) => (
              <FormRadio
                key={idx}
                name="group"
                checked={groupActive.includes(item.trim())}
                onChange={() => handleClick(item.trim())}
              >
                {item}
              </FormRadio>
            ))}
            <br />
            <div className="btn-group-actions site-modal__actions flex justify-end">
              <Button
                theme="light"
                className="mr-2"
                onClick={() => groupFilter(true)}
              >
                {t("module.applications.reset")}
              </Button>
              <Button
                theme="primary"
                className="delete mr-2"
                onClick={() => groupFilter()}
              >
                {t("module.applications.filter")}
              </Button>
            </div>
          </>
        )}
        {groupConfig.length === 0 && officeConfig.length === 0 && (
          <div>{t("module.applications.nothingToFilter")}.</div>
        )}
      </div>
    </div>
  )
}

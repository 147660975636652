import { filter, isEmpty, first } from "lodash"
import { axiosInstance, handleResponse } from "../../_helpers/base.service"
const serviceGroup = "creditscore"

/**
 * Export functions as in one module
 * usage example cslService.getComputationCodes(`uid`)
 */
export const cslService = {
  // generateComputationCodes,
  getComputationCodes,
  saveComputationCodes,
  getAssumptions,
  saveAssumptions,
  editAssumption,
  deleteAssumption,
  getFormulas,
  editFormula,
  saveFormulas,
  deleteFormula,
  getSurveyCashflowStatements,
  saveSurveyCashflowStatements,
  updateSurveyCashflowStatements,
  getScoreCardCategories,
  saveScoreCardCategories,
  getScoreCardGrade,
  saveScoreCardGrade,
  editScoreCardGrade,
  deleteScoreCardGrade,
  saveScoreCardEntries,
  uploadScoreCardEntries,
  updateScoreCardEntries,
  deleteScoreCardEntry,
  saveScoreCardCategoryEntryConfiguration,
  updateScoreCardCategoryEntryConfiguration,
  deleteScoreCardCategoryEntryConfiguration,
  getScoreCardProfiles,
  addScoreCardProfiles,
  editScoreCardProfileItem,
  submitData,
  getSurveyWorkflowGroups,
  setSurveyWorkflowGroups,
  updateSurveyWorkflowGroups,
  getWorkflowGroups,
  deleteDocumentChecklist,
  getDocumentChecklist,
  addDocumentChecklist,
  editDocumentChecklist,
  syncDocumentChecklist,
  testScoreLogic,
  recalculateScoreLogic,
}

/**
 * Get Computation codes
 *
 * @param surveyUuid Survey UUID
 */
function getComputationCodes(surveyUuid) {
  return axiosInstance
    .get(`${serviceGroup}/computation-codes/`, {
      params: {
        surveyUuid,
      },
    })
    .then(handleResponse)
}

/**
 * Save Computation codes
 *
 * @param surveyUuid Survey UUID
 */
function saveComputationCodes(data) {
  return axiosInstance
    .post(`${serviceGroup}/computation-codes/`, data)
    .then(handleResponse)
}

/**
 * Get Assumptions
 *
 * @param surveyUuid Survey UUID
 */
function getAssumptions(surveyUuid) {
  const requestConfig = {
    params: {
      surveyUuid,
    },
  }

  return axiosInstance
    .get(`${serviceGroup}/assumptions/`, requestConfig)
    .then(handleResponse)
}

/**
 * Save Assumptions
 *
 * @param data Survey UUID
 */
function saveAssumptions(data) {
  return axiosInstance
    .post(`${serviceGroup}/assumptions/`, data)
    .then(handleResponse)
}

/**
 * Update Assumptions
 *
 * @param data Survey UUID
 */
function editAssumption(id, data) {
  return axiosInstance
    .patch(`${serviceGroup}/assumptions/${id}/`, data)
    .then(handleResponse)
}

/**
 * Delete Assumptions
 *
 * @param id UUID
 */
function deleteAssumption(id) {
  return axiosInstance
    .delete(`${serviceGroup}/assumptions/${id}/`)
    .then(handleResponse)
}

/**
 * Get Formulas
 *
 * @param surveyUuid Survey UUID
 */
function getFormulas(surveyUuid) {
  const requestConfig = {
    params: {
      surveyUuid,
    },
  }

  return axiosInstance
    .get(`${serviceGroup}/formulas/`, requestConfig)
    .then(handleResponse)
}

/**
 * Edit Formulas
 *
 * @param surveyUuid Survey UUID, ID
 */
function editFormula(id, data) {
  return axiosInstance
    .patch(`${serviceGroup}/formulas/${id}/`, data)
    .then(handleResponse)
}

/**
 * Save Formulas
 *
 * @param surveyUuid Survey UUID
 */
function saveFormulas(data) {
  return axiosInstance
    .post(`${serviceGroup}/formulas/`, data)
    .then(handleResponse)
}

/**
 * Delete Assumptions
 *
 * @param id UUID
 */
function deleteFormula(id) {
  return axiosInstance
    .delete(`${serviceGroup}/formulas/${id}/`)
    .then(handleResponse)
}

/**
 * Get Survey Cashflow Statements
 *
 * @param surveyUuid Survey UUID
 */
function getSurveyCashflowStatements(surveyUuid) {
  const requestConfig = {
    params: {
      surveyUuid,
    },
  }

  return axiosInstance
    .get(`${serviceGroup}/survey-cashflow-statement/`, requestConfig)
    .then(handleResponse)
}

/**
 * Get Cashflow Statements
 *
 * @param data
 */
function saveSurveyCashflowStatements(data) {
  return axiosInstance
    .post(`${serviceGroup}/survey-cashflow-statement/`, data)
    .then(handleResponse)
}

/**
 * Update Cashflow Statements
 *
 * @param data
 */
function updateSurveyCashflowStatements(data) {
  const { id } = data
  return axiosInstance
    .patch(`${serviceGroup}/survey-cashflow-statement/${id}/`, data)
    .then(handleResponse)
}

/**
 * Get Score Card Categories
 *
 * @param surveyUuid Survey UUID
 */
function getScoreCardCategories(surveyUuid) {
  const requestConfig = {
    params: {
      surveyUuid,
    },
  }

  return axiosInstance
    .get(`${serviceGroup}/score-card-categories/`, requestConfig)
    .then(handleResponse)
}

/**
 * Get Score Card Categories
 *
 * @param surveyUuid Survey UUID
 */
function saveScoreCardCategories(title) {
  const requestConfig = {
    title,
  }
  return axiosInstance
    .post(`${serviceGroup}/score-card-categories/`, requestConfig)
    .then(handleResponse)
}

/**
 * Get Score Card Categories
 *
 * @param surveyUuid Survey UUID
 */
function getScoreCardGrade(surveyUuid) {
  const requestConfig = {
    params: {
      surveyUuid,
    },
  }
  return axiosInstance
    .get(`${serviceGroup}/score-card-grade/`, requestConfig)
    .then(handleResponse)
}

/**
 * Get Score Card Categories
 *
 * @param surveyUuid Survey UUID
 */
function saveScoreCardGrade(requestConfig) {
  return axiosInstance
    .post(`${serviceGroup}/score-card-grade/`, requestConfig)
    .then(handleResponse)
}

/**
 * Save Score Card Profiles
 *
 * @param id Entry ID
 * @param data Entry Data
 */
function editScoreCardGrade(id, data) {
  return axiosInstance
    .put(`${serviceGroup}/score-card-grade/${id}/`, data)
    .then(handleResponse)
}

/**
 * Delete Score Card Profiles
 *
 * @param id ID
 */
function deleteScoreCardGrade(id) {
  return axiosInstance
    .delete(`${serviceGroup}/score-card-grade/${id}/`)
    .then(handleResponse)
}

/**
 * Save Score Card Entries
 *
 * @param data Entry Data
 */
function saveScoreCardEntries(data) {
  const { id, title, surveyUuid, code, dataType } = data

  const requestConfig = {
    title,
    scoreCardCategoryId: id,
    code,
    dataType,
    surveyUuid,
  }

  return axiosInstance
    .post(`${serviceGroup}/score-card-category-entries/`, requestConfig)
    .then(handleResponse)
}

/**
 * Upload Score Card Entries
 *
 * @param data Entries Data
 */
function uploadScoreCardEntries(data) {
  return axiosInstance
    .post(
      `${serviceGroup}/score-card-category-entries/upload-score-card-entries/`,
      data
    )
    .then(handleResponse)
}

/**
 * Save Score Card Entries
 *
 * @param data Entry Data
 */
function updateScoreCardEntries(id, data) {
  const { title, code, dataType } = data

  const requestConfig = {
    title,
    code,
    dataType,
  }

  return axiosInstance
    .put(`${serviceGroup}/score-card-category-entries/${id}/`, requestConfig)
    .then(handleResponse)
}

/**
 * Delete Score Card Entries
 *
 * @param data Entry Data
 */
function deleteScoreCardEntry(id) {
  return axiosInstance
    .delete(`${serviceGroup}/score-card-category-entries/${id}/`)
    .then(handleResponse)
}

/**
 * Save Score Card Conditions
 *
 * @param data Entry Data
 */
function saveScoreCardCategoryEntryConfiguration(data) {
  return axiosInstance
    .post(`${serviceGroup}/score-card-category-entry-configurations/`, data)
    .then(handleResponse)
}

/**
 * Save Score Card Conditions
 *
 * @param data Entry Data
 */
function updateScoreCardCategoryEntryConfiguration(id, data) {
  const { value, maxScore } = data
  const requestConfig = {
    value,
    maxScore,
  }

  return axiosInstance
    .put(
      `${serviceGroup}/score-card-category-entry-configurations/${id}/`,
      requestConfig
    )
    .then(handleResponse)
}

/**
 * Delete Score Card Entries
 *
 * @param data Entry Data
 */
function deleteScoreCardCategoryEntryConfiguration(id) {
  return axiosInstance
    .delete(`${serviceGroup}/score-card-category-entry-configurations/${id}/`)
    .then(handleResponse)
}

/**
 * Get Score Card Profiles
 *
 * @param data Entry Data
 */
function getScoreCardProfiles(surveyUuid) {
  return axiosInstance
    .get(`workflow/loan-detail-configurations/`, {
      params: {
        surveyUuid,
      },
    })
    .then(handleResponse)
}

/**
 * Save Score Card Profiles
 *
 * @param data Entry Data
 */
function addScoreCardProfiles(data) {
  return axiosInstance
    .post(`workflow/loan-detail-configurations/`, data)
    .then(handleResponse)
}

/**
 * Save Score Card Profiles
 *
 * @param data Entry Data
 */
function editScoreCardProfileItem(id, data) {
  return axiosInstance
    .patch(`workflow/loan-detail-configurations/${id}/`, data)
    .then(handleResponse)
}

/**
 * Get Score Card Categories
 *
 * Use step id to process each setup step
 *
 * @param step Wizard step
 * @param data Wizard data
 */
function submitData(step, data) {
  const { id } = step
  const {
    uid,
    scoreCardProfile,
    computationCodes,
    assumptions,
    formulas,
    cashflowStatements,
    scoringVariables,
    scoreGrade,
    documentSetup,
  } = data

  // TODO: Remove blacklist
  if (
    uid === "ajuqcTpPPaCRozamPaL4Ft:voCcLCBkocM9gXACirjvnL" ||
    uid === "aq6heytXzkuS44EKjykuCA:vJvQKGNh5WAkbNiHjywqp8"
  ) {
    return Promise.reject(
      new Error("Overiting test questionnaire score disabled")
    )
  }

  switch (id) {
    case "questionnaire":
      return Promise.resolve({})
    case "computationCodes":
      return cslService.saveComputationCodes(
        filter(computationCodes, { surveyUuid: uid })
      )
    case "assumptions":
      filter(assumptions, { surveyUuid: uid }).map((item) =>
        cslService.saveAssumptions(item)
      )

      return Promise.resolve({})
    case "projectionPeriod":
      const pp = first(
        filter([...formulas], { surveyUuid: uid, type: "PROJECTION_PERIOD" })
      )

      if (pp && !isEmpty(pp.id)) {
        cslService.editFormula(pp.id, pp)
      } else if (!isEmpty(pp)) {
        cslService.saveFormulas(pp)
      }

      const el = first(
        filter([...formulas], { surveyUuid: uid, type: "EXISTING_LOAN" })
      )

      if (el && !isEmpty(el.id)) {
        cslService.editFormula(el.id, pp)
      } else if (!isEmpty(el)) {
        cslService.saveFormulas(el)
      }

      const scoreCardProfileSanitized = scoreCardProfile.map((item) => {
        const { id, title, computationCode, questionKey, type } = item

        const payload = {
          title,
          questionKey,
          computationCode,
          type,
        }

        if (!id) {
          return payload
        }

        id && cslService.editScoreCardProfileItem(id, payload)

        return item
      })

      const ScoreCardPayload = {
        surveyUuid: uid,
        profileListLoanDetailConfiguration: scoreCardProfileSanitized,
      }

      scoreCardProfileSanitized &&
        cslService.addScoreCardProfiles(ScoreCardPayload)

      return Promise.resolve({})
    case "tdv":
      const tdv = filter(formulas, {
        surveyUuid: uid,
        type: "TIME_DEPENDENCY_FLAG",
      })
      tdv.map((item) => {
        cslService.saveFormulas(item)
        return item
      })
      return Promise.resolve({})
    case "tdcv":
      const tdcv = filter(formulas, {
        surveyUuid: uid,
        type: "TIME_DEPENDENT_CALCULATED_VARIABLE",
      })
      tdcv.map((item) => {
        const { id } = item
        !id && cslService.saveFormulas(item)
        id && cslService.editFormula(item)
        return item
      })

      return Promise.resolve({})
    case "emcv":
      const emcv = filter(formulas, {
        surveyUuid: uid,
        type: "EVERY_MONTH_CALCULATED_VARIABLE",
      })
      emcv.map((item) => {
        const { id } = item
        !id && cslService.saveFormulas(item)
        id && cslService.editFormula(item)
        return item
      })
      return Promise.resolve({})
    case "cashflow":
      cashflowStatements.map((item) => {
        const {
          id,
          code,
          description,
          timeDependentCalculatedVariables,
          everyMonthCalculatedVariables,
          variableCodes,
          formulaExpression,
        } = item
        const payload = {
          id,
          surveyUuid: uid,
          code,
          description,
          timeDependentCalculatedVariables,
          everyMonthCalculatedVariables,
          variableCodes,
          formulaExpression,
        }

        switch (code) {
          case "C":
            payload.formulaExpression = ["A", "-", "B"]
            break
          case "F":
            payload.formulaExpression = ["C", "+", "D", "-", "E"]
            break
          case "H":
            payload.formulaExpression = ["F", "-", "G"]
            break
          case "K":
            payload.formulaExpression = ["J", "/", "I"]
            break
          case "I":
            payload.variableCodes = payload.variableCodes[0]
            break
          default:
            break
        }

        id !== -1 && cslService.updateSurveyCashflowStatements(payload)
        id === -1 && cslService.saveSurveyCashflowStatements(payload)

        return item
      })

      return Promise.resolve({})
    case "otherCalculations":
      const otherCalculations = filter(formulas, {
        surveyUuid: uid,
        type: "SINGLE_CALCULATION",
      })

      otherCalculations.map((item) => {
        const { id } = item
        !id && cslService.saveFormulas(item)
        id && cslService.editFormula(item)
        return item
      })

      return Promise.resolve({})
    case "sv":
      // scoringVariables
      scoringVariables.map((item) => {
        const { entries } = item

        return entries.map((item) => {
          const {
            id: scoreCardCategoryEntryId,
            title: scoreCardCategoryEntryTitle,
            configurations,
            code,
          } = item

          const scoreCardCategoryPayload = {
            id: scoreCardCategoryEntryId,
            title: scoreCardCategoryEntryTitle,
            surveyUuid: uid,
            code,
          }

          if (scoreCardCategoryEntryId) {
            cslService.updateScoreCardEntries(
              scoreCardCategoryEntryId,
              scoreCardCategoryPayload
            )
          } else {
            cslService.saveScoreCardEntries(scoreCardCategoryPayload)
          }

          configurations.map((config) => {
            const { id: configID, unsaved } = config

            const newScoreCardCategoryEntryPayload = {
              scoreCardCategoryEntryId,
              configs: [config],
            }

            unsaved &&
              cslService.saveScoreCardCategoryEntryConfiguration(
                newScoreCardCategoryEntryPayload
              )
            Number(configID) &&
              cslService.updateScoreCardCategoryEntryConfiguration(
                Number(configID),
                config
              )
            return config
          })

          return item
        })
      })

      return Promise.resolve({})
    case "scoreCard":
      const payload = {
        surveyUuid: uid,
        gradeList: scoreGrade,
      }
      return cslService.saveScoreCardGrade(payload)
    case "approvalWorkflow":
      if (data.surveyGroupWorkflow.id) {
        // update
        const payload = {
          assignBackToLoanOfficer:
            data.surveyGroupWorkflow.assignBackToLoanOfficer,
          skipLoanOfficer: data.surveyGroupWorkflow.skipLoanOfficer,
          approveAssignForward: data.surveyGroupWorkflow.approveAssignForward,
          workflowGroupId: data.surveyGroupWorkflow.workflowGroupId,
        }
        cslService.updateSurveyWorkflowGroups(
          data.surveyGroupWorkflow.id,
          payload
        )
      } else {
        // add
        const payload = {
          surveyUuid: data.surveyGroupWorkflow.surveyUuid,
          workflowGroupId: data.surveyGroupWorkflow.workflowGroupId,
          assignBackToLoanOfficer:
            data.surveyGroupWorkflow.assignBackToLoanOfficer,
          approveAssignForward: data.surveyGroupWorkflow.approveAssignForward,
          skipLoanOfficer: data.surveyGroupWorkflow.skipLoanOfficer,
        }
        cslService.setSurveyWorkflowGroups(payload)
      }
      return Promise.resolve({})

    case "documentChecklist":
      if (documentSetup.edit) {
        cslService.editDocumentChecklist(documentSetup.edit, documentSetup)
      } else {
        delete documentSetup.edit
        documentSetup["surveyUuid"] = uid
        cslService.addDocumentChecklist(documentSetup)
      }
      return Promise.resolve({})
    default:
      return Promise.reject(new Error("No data saved"))
  }
}

/**
 * Get workflow groups
 *
 *
 */
function getWorkflowGroups(mfiId) {
  return axiosInstance
    .get("workflow/workflow-groups/", {
      params: {
        mfiId,
      },
    })
    .then(handleResponse)
}

/**
 * Get Survey workflow group
 *
 *
 */
function getSurveyWorkflowGroups(surveyUuid) {
  return axiosInstance
    .get("workflow/survey-workflow-groups/", {
      params: {
        surveyUuid,
      },
    })
    .then(handleResponse)
}

/**
 * Save Survey workflow group
 *
 * @param data object
 */
function setSurveyWorkflowGroups(data) {
  return axiosInstance
    .post(`workflow/survey-workflow-groups/`, data)
    .then(handleResponse)
}

/**
 * update Survey workflow group
 *
 * @param id id
 * @param data object
 */
function updateSurveyWorkflowGroups(id, data) {
  return axiosInstance
    .patch(`workflow/survey-workflow-groups/survey-workflow-group/${id}`, data)
    .then(handleResponse)
}

/**
 * Delete documentChecklist
 *
 * @param id
 */
function deleteDocumentChecklist(id) {
  return axiosInstance
    .delete(`documentchecklist/document-checklists/document-checklist/${id}`)
    .then(handleResponse)
}

/**
 * Get documentChecklist
 *
 * @param id
 */
function getDocumentChecklist(surveyUuid) {
  return axiosInstance
    .get("documentchecklist/document-checklists/survey", {
      params: {
        surveyUuid,
      },
    })
    .then(handleResponse)
}

/**
 * Save DocumentChecklist
 *
 * @param data object
 */
function addDocumentChecklist(data) {
  return axiosInstance
    .post(`documentchecklist/document-checklists/`, data)
    .then(handleResponse)
}

/**
 * edit DocumentChecklist
 *
 * @param data object
 */
function editDocumentChecklist(id, data) {
  delete data.edit
  return axiosInstance
    .patch(
      `documentchecklist/document-checklists/document-checklist-setup/${id}`,
      data
    )
    .then(handleResponse)
}

function syncDocumentChecklist(surveyUuid) {
  return axiosInstance
    .get(
      `/documentchecklist/kobo-document-checklist/survey?surveyUuid=${surveyUuid}`
    )
    .then(handleResponse)
}

function testScoreLogic(surveyUuid) {
  return axiosInstance
    .get(`${serviceGroup}/calculation/test/${surveyUuid}`)
    .then(handleResponse)
}

function recalculateScoreLogic(surveyUuid) {
  return axiosInstance
    .get(`${serviceGroup}/calculation/recalculate/${surveyUuid}`)
    .then(handleResponse)
}

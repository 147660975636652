import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ComputationCodesSetup } from '../Formula/ComputationCodes';
import { DocumentSetup } from './DocumentSetup';
import './documentchecklist.scss';

export function DocumentChecklist({ id, type }) {
    const [result, setResult] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
      // scroll page to top on displaying component
      const wrapper = document.getElementsByClassName("main-content");
      wrapper[0].scrollIntoView();
    }, []);

    const handleSelection = (selectionType, data) => {
        setResult(...data.computationCode);
    };

    const resetComputationCode = () => {
        setResult('');
        return;
    };

    const updateComputationCode = (data) => {
        setResult(data);
        return;
    };


    return (
        <div className="formula p-4">
            <h4 className="mini-heading-top">
                {t('module.scoreLogic.view.scoreLogic.documentChecklist.documentChecklistSetup')}
            </h4>
            <div className="flex">
                <div className="w-1/2 pr-2">
                  <ComputationCodesSetup handler={ handleSelection } selected={result} selectOne={true}/>
                </div>
                <div className="w-1/2 pl-2">
                  <DocumentSetup
                    result={result}
                    resetComputationCodeFn={resetComputationCode}
                    updateComputationCode={updateComputationCode}
                    />
                </div>
            </div>
        </div>
    );
}

export const applicationConstants = {
  APPLICATIONS_QUESTIONNAIRE_UID: "APPLICATIONS_QUESTIONNAIRE_UID",
  APPLICATIONS_REQUEST: "APPLICATIONS_GETALL_REQUEST",
  APPLICATIONS_SUCCESS: "APPLICATIONS_GETALL_SUCCESS",
  APPLICATIONS_FAILURE: "APPLICATIONS_GETALL_FAILURE",

  MY_APPLICATIONS_REQUEST: "MY_APPLICATIONS_REQUEST",
  MY_APPLICATIONS_SUCCESS: "MY_APPLICATIONS_SUCCESS",
  MY_APPLICATIONS_FAILURE: "MY_APPLICATIONS_FAILURE",

  MY_TASKS_REQUEST: "MY_TASKS_REQUEST",
  MY_TASKS_SUCCESS: "MY_TASKS_SUCCESS",
  MY_TASKS_FAILURE: "MY_TASKS_FAILURE",

  PAYMENTS_UPLOAD_REQUEST: "PAYMENTS_UPLOAD_REQUEST",
  PAYMENTS_UPLOAD_SUCCESS: "PAYMENTS_UPLOAD_SUCCESS",
  PAYMENTS_UPLOAD_FAILURE: "PAYMENTS_UPLOAD_FAILURE",

  MOBILE_UPDATE_SUCCESS: "MOBILE_UPDATE_SUCCESS",

  GET_BANKS_REQUEST: "GET_BANKS_REQUEST",
  GET_BANKS_SUCCESS: "GET_BANKS_SUCCESS",
  GET_BANKS_FAILURE: "GET_BANKS_FAILURE",

  WORKFLOW_USER_REQUEST: "WORKFLOW_USER_REQUEST",
  WORKFLOW_USER_SUCCESS: "WORKFLOW_USER_SUCCESS",
  WORKFLOW_USER_FAILURE: "WORKFLOW_USER_FAILURE",

  GET_PAYMENTS_REQUEST: "GET_PAYMENTS_REQUEST",
  GET_PAYMENTS_SUCCESS: "GET_PAYMENTS_SUCCESS",
  GET_PAYMENTS_FAILURE: "GET_PAYMENTS_FAILURE",

  PAYMENTS_FILTER: "PAYMENTS_FILTER",

  APPLICATION_METADATA_REQUEST: "APPLICATION_METADATA_REQUEST",
  APPLICATION_METADATA_SUCCESS: "APPLICATION_METADATA_SUCCESS",
  APPLICATION_METADATA_FAILURE: "APPLICATION_METADATA_FAILURE",

  APPLICATION_EDIT_LOAN_DETAILS_REQUEST:
    "APPLICATION_EDIT_LOAN_DETAILS_REQUEST",
  APPLICATION_EDIT_LOAN_DETAILS_SUCCESS:
    "APPLICATION_EDIT_LOAN_DETAILS_SUCCESS",
  APPLICATION_EDIT_LOAN_DETAILS_FAILURE:
    "APPLICATION_EDIT_LOAN_DETAILS_FAILURE",

  RECALCULATE_CREDIT_SCORE_REQUEST: "RECALCULATE_CREDIT_SCORE_REQUEST",
  RECALCULATE_CREDIT_SCORE_SUCCESS: "RECALCULATE_CREDIT_SCORE_SUCCESS",
  RECALCULATE_CREDIT_SCORE_FAILURE: "RECALCULATE_CREDIT_SCORE_FAILURE",

  APPLICATION_ASSIGN: "APPLICATION_ASSIGN",

  APPLICATION_COLUMN_GET_REQUEST: "APPLICATION_COLUMN_GET_REQUEST",
  APPLICATION_COLUMN_GET_SUCCESS: "APPLICATION_COLUMN_GET_SUCCESS",
  APPLICATION_COLUMN_GET_FAILURE: "APPLICATION_COLUMN_GET_FAILURE",

  APPLICATION_DATE_COLUMN_FILTER: "APPLICATION_DATE_COLUMN_FILTER",
  APPLICATION_DATE_COLUMN_FILTER_RESET: "APPLICATION_DATE_COLUMN_FILTER_RESET",

  APPLICATION_COLUMN_SORT: "APPLICATION_COLUMN_SORT",

  APPLICATION_USER_FILTER: "APPLICATION_USER_FILTER",
  APPLICATION_OFFICE_FILTER: "APPLICATION_OFFICE_FILTER",
  APPLICATION_GROUP_FILTER: "APPLICATION_GROUP_FILTER",

  APPLICATION_CLICKED: "APPLICATION_CLICKED",

  APPLICATION_STATE_FILTER: "APPLICATION_STATE_FILTER",

  GET_FORM_FIELDS_REQUEST: "GET_FORM_FIELDS_REQUEST",
  GET_FORM_FIELDS_SUCCESS: "GET_FORM_FIELDS_SUCCESS",
  GET_FORM_FIELDS_FAILURE: "GET_FORM_FIELDS_FAILURE",

  SEARCH_APPLICATION_REQUEST: "SEARCH_APPLICATION_REQUEST",
  SEARCH_APPLICATION_SUCCESS: "SEARCH_APPLICATION_SUCCESS",
  SEARCH_APPLICATION_FAILURE: "SEARCH_APPLICATION_FAILURE",

  VILLAGE_BANKS_REQUEST: "VILLAGE_BANKS_REQUEST",
  VILLAGE_BANKS_SUCCESS: "VILLAGE_BANKS_SUCCESS",
  VILLAGE_BANKS_FAILURE: "VILLAGE_BANKS_FAILURE",

  ADD_VILLAGE_BANK_REQUEST: "ADD_VILLAGE_BANK_REQUEST",
  ADD_VILLAGE_BANK_SUCCESS: "ADD_VILLAGE_BANK_SUCCESS",
  ADD_VILLAGE_BANK_FAILURE: "ADD_VILLAGE_BANK_FAILURE",

  GET_ENTITY_STATUS_REQUEST: "GET_ENTITY_STATUS_REQUEST",
  GET_ENTITY_STATUS_SUCCESS: "GET_ENTITY_STATUS_SUCCESS",
  GET_ENTITY_STATUS_FAILURE: "GET_ENTITY_STATUS_FAILURE",

  USERS_TO_ASSIGN_REQUEST: "USERS_TO_ASSIGN_REQUEST",
  USERS_TO_ASSIGN_SUCCESS: "USERS_TO_ASSIGN_SUCCESS",
  USERS_TO_ASSIGN_FAILURE: "USERS_TO_ASSIGN_FAILURE",

  REPAYMENT_SCHEDULE_REQUEST: "REPAYMENT_SCHEDULE_REQUEST",
  REPAYMENT_SCHEDULE_SUCCESS: "REPAYMENT_SCHEDULE_SUCCESS",
  REPAYMENT_SCHEDULE_FAILURE: "REPAYMENT_SCHEDULE_FAILURE",
}

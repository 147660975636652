import { mfiManagerConstants, mfiManagerService } from "."
import { alertActions } from "../alert"

/**
 * Get all MFIs
 */
const getAllMFIs = () => {
  return (dispatch) => {
    dispatch(request({}))

    mfiManagerService.getAllMFIs().then(
      (mfis) => dispatch(success(mfis)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return { type: mfiManagerConstants.GETALL_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.GETALL_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.GETALL_FAILURE, error }
  }
}

/**
 *
 * @param {*} id
 */
const getMFIById = (id) => (dispatch) => {}

/**
 *
 * @param {*} data
 */
const createMFI = (mfi, RedirectToParent) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.createMFI(mfi).then(
      (res) => {
        dispatch(success())
        dispatch(alertActions.success("MFI Creation successful"))
        setTimeout(() => {
          RedirectToParent()
        }, 3000)
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("MFI Creation failed"))
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.CREATE_MFI_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.CREATE_MFI_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.CREATE_MFI_FAILURE, error }
  }
}

/**
 * Update paylod
 *
 * @param {*} id
 * @param {*} mfi
 */
const updateMFI = (id, mfi, RedirectToParent) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.updateMFI(id, mfi).then(
      (res) => {
        dispatch(success())
        dispatch(alertActions.success("MFI Update successful"))
        setTimeout(() => {
          RedirectToParent()
        }, 3000)
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("MFI Update failed"))
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.CREATE_MFI_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.CREATE_MFI_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.CREATE_MFI_FAILURE, error }
  }
}
/**
 * Update paylod
 *
 * @param {*} id
 * @param {*} mfi
 */
const updateMFIScoreUrl = (id, mfi) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.updateMFI(id, mfi).then(
      (res) => {
        dispatch(success())
        dispatch(alertActions.success("MFI Update successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("MFI Update failed"))
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.CREATE_MFI_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.CREATE_MFI_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.CREATE_MFI_FAILURE, error }
  }
}

/**
 * Delete MFI
 *
 * @param {*} id
 */
// eslint-disable-next-line no-underscore-dangle
const deleteMFI = (id) => (dispatch) => {}

/**
 * Add Mfi Sub Roles
 *
 * @param {*} mfiCode int
 */
const addMFISubRoles = (payload) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.addMFISubRoles(payload).then(
      () => dispatch(success([payload])),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return { type: mfiManagerConstants.MFI_SUBROLES_ADD_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.MFI_SUBROLES_ADD_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.MFI_SUBROLES_ADD_FAILURE, error }
  }
}

/**
 * Add Mfi Sub Roles
 *
 * @param {*} mfiCode int
 */
const updateMFISubRole = (id, payload) => {
  return (dispatch) => {
    dispatch(request({}))

    mfiManagerService.updateMFISubRole(id, payload).then(
      () => {
        dispatch(success({ id, data: payload }))
        dispatch(mfiManagerActions.getMFISubRoles(payload.mfiId))
      },
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return { type: mfiManagerConstants.MFI_SUBROLES_EDIT_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.MFI_SUBROLES_EDIT_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.MFI_SUBROLES_EDIT_FAILURE, error }
  }
}

/**
 * Get Mfi Sub Roles
 *
 * @param {*} mfiCode int
 */
const getMFISubRoles = (mfiID) => {
  return (dispatch) => {
    dispatch(request({}))

    mfiManagerService.getMFISubRoles(mfiID).then(
      (subRoles) => dispatch(success(subRoles)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return { type: mfiManagerConstants.MFI_SUBROLES_GETALL_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.MFI_SUBROLES_GETALL_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.MFI_SUBROLES_GETALL_FAILURE, error }
  }
}

const getMFIRoles = () => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.getMFIRoles().then(
      (roles) => dispatch(success(roles)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return { type: mfiManagerConstants.MFI_ROLES_GETALL_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.MFI_ROLES_GETALL_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.MFI_ROLES_GETALL_FAILURE, error }
  }
}

/**
 * Delete Mfi Sub Roles
 *
 * @param {*} id
 */
const deleteMFISubRole = (subRoleID) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.deleteMFISubRole(subRoleID).then(
      () => dispatch(success(subRoleID)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return { type: mfiManagerConstants.MFI_SUBROLE_DELETE_REQUEST }
  }
  function success(id) {
    return { type: mfiManagerConstants.MFI_SUBROLE_DELETE_SUCCESS, id }
  }
  function failure(error) {
    return { type: mfiManagerConstants.MFI_SUBROLE_DELETE_FAILURE, error }
  }
}

/**
 * Get Workflow Groups
 *
 * @param {*} mfiId int
 */
const getWorkflowGroups = (mfiId) => {
  return (dispatch) => {
    dispatch(request({}))

    mfiManagerService.getWorkflowGroups(mfiId).then(
      (groups) => dispatch(success(groups)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return { type: mfiManagerConstants.MFI_GROUPS_GETALL_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.MFI_GROUPS_GETALL_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.MFI_GROUPS_GETALL_FAILURE, error }
  }
}

/**
 * Add Workflow group
 *
 * @param {*} payload object
 */
const addWorkflowGroup = (payload) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.addWorkflowGroup(payload).then(
      () => {
        dispatch(success([payload]))
        dispatch(alertActions.success("Workflow Group created successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Workflow Group create failed:", error))
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.MFI_GROUPS_ADD_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.MFI_GROUPS_ADD_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.MFI_GROUPS_ADD_FAILURE, error }
  }
}

/**
 * Edit workflow Group
 *
 * @param {*} payload object
 */
const editWorkflowGroup = (payload) => {
  return (dispatch) => {
    dispatch(request({}))

    mfiManagerService.editWorkflowGroup(payload.id, payload.description).then(
      () => {
        dispatch(success({ ...payload }))
        dispatch(alertActions.success("Workflow Group edited successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Workflow Group edit failed:", error))
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.MFI_GROUPS_EDIT_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.MFI_GROUPS_EDIT_SUCCESS, data: data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.MFI_GROUPS_EDIT_FAILURE, error }
  }
}

/**
 * filter Workflow on Groups
 *
 * @param {*} workflowGroupId int
 */
const filterWorkflowGroups = (workflowGroupId) => {
  return (dispatch) => {
    dispatch(filter(workflowGroupId))
  }

  function filter(data) {
    return { type: mfiManagerConstants.MFI_WORKFLOW_FILTER_GROUPS, data }
  }
}

/**
 * Add loan distributed item
 *
 * @param {*} payload object
 */
const addLoanDistributed = (payload) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.addLoanDistributed(payload).then(
      (data) => {
        dispatch(success(data))
        dispatch(
          alertActions.success("Loan distributed item created successful")
        )
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error("Loan distributed item create failed:", error)
        )
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.APP_LOAN_DISTRIBUTED_ADD_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.APP_LOAN_DISTRIBUTED_ADD_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.APP_LOAN_DISTRIBUTED_ADD_FAILURE, error }
  }
}

/**
 * Edit loan distributed
 *
 * @param {*} payload object
 * @param {*} id int
 */
const editLoanDistributed = (id, payload) => {
  return (dispatch) => {
    dispatch(request({}))

    mfiManagerService.editLoanDistributed(id, payload).then(
      (data) => {
        dispatch(success(data))
        dispatch(
          alertActions.success("Loan distributed item edited successful")
        )
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error("Loan distributed item edit failed:", error)
        )
      }
    )
  }
  function request() {
    return { type: mfiManagerConstants.APP_LOAN_DISTRIBUTED_EDIT_REQUEST }
  }
  function success(data) {
    return {
      type: mfiManagerConstants.APP_LOAN_DISTRIBUTED_EDIT_SUCCESS,
      data: data,
    }
  }
  function failure(error) {
    return {
      type: mfiManagerConstants.APP_LOAN_DISTRIBUTED_EDIT_FAILURE,
      error,
    }
  }
}

/**
 * Get loan distribute item
 *
 * @param {*} applicationId string
 */
const getLoanDistributed = (applicationId) => {
  return (dispatch) => {
    dispatch(request({}))

    mfiManagerService.getLoanDistributed(applicationId).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return { type: mfiManagerConstants.APP_LOAN_DISTRIBUTED_GET_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.APP_LOAN_DISTRIBUTED_GET_SUCCESS, data }
  }
  function failure(error) {
    return {
      type: mfiManagerConstants.APP_LOAN_DISTRIBUTED_GET_FAILURE,
      error,
    }
  }
}

/**
 * Add Mpesa details
 *
 * @param {*} payload object
 */
const addMpesaDetails = (payload) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.addMpesaDetails(payload).then(
      () => {
        dispatch(success([payload]))
        dispatch(alertActions.success("Mpesa details created successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Mpesa details creation failed:", error))
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.MPESA_DETAILS_ADD_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.MPESA_DETAILS_ADD_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.MPESA_DETAILS_ADD_FAILURE, error }
  }
}

/**
 * Edit Mpesa Details
 *
 * @param {*} payload object
 */
const editMpesaDetails = (payload) => {
  return (dispatch) => {
    dispatch(request({}))

    mfiManagerService.editMpesaDetails(payload).then(
      () => {
        dispatch(success({ ...payload }))
        dispatch(alertActions.success("Mpesa details edited successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Mpesa details edit failed:", error))
      }
    )
  }
  function request() {
    return { type: mfiManagerConstants.MPESA_DETAILS_EDIT_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.MPESA_DETAILS_EDIT_SUCCESS, data: data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.MPESA_DETAILS_EDIT_FAILURE, error }
  }
}

/**
 * Get mpesa details
 *
 * @param {*} mfiId int
 */
const getMpesaDetails = (mfiId) => {
  return (dispatch) => {
    dispatch(request({}))

    mfiManagerService.getMpesaDetails(mfiId).then(
      (menus) => dispatch(success(menus)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return { type: mfiManagerConstants.MPESA_DETAILS_GETALL_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.MPESA_DETAILS_GETALL_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.MPESA_DETAILS_GETALL_FAILURE, error }
  }
}

/**
 * Add Custom Menu
 *
 * @param {*} payload object
 */
const addCustomMenu = (payload) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.addCustomMenu(payload).then(
      () => {
        dispatch(success([payload]))
        dispatch(alertActions.success("Custom menu created successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Custom menu create failed:", error))
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.MFI_MENUS_ADD_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.MFI_MENUS_ADD_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.MFI_MENUS_ADD_FAILURE, error }
  }
}

/**
 * Edit custom menu
 *
 * @param {*} payload object
 */
const editCustomMenus = (payload) => {
  return (dispatch) => {
    dispatch(request({}))

    mfiManagerService.editCustomMenus(payload).then(
      () => {
        dispatch(success({ ...payload }))
        dispatch(alertActions.success("Custom menu edited successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Custom menu edit failed:", error))
      }
    )
  }
  function request() {
    return { type: mfiManagerConstants.MFI_MENUS_EDIT_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.MFI_MENUS_EDIT_SUCCESS, data: data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.MFI_MENUS_EDIT_FAILURE, error }
  }
}

/**
 * Get custom menus
 *
 * @param {*} mfiId int
 */
const getCustomMenus = (mfiId) => {
  return (dispatch) => {
    dispatch(request({}))

    mfiManagerService.getCustomMenus(mfiId).then(
      (menus) => dispatch(success(menus)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return { type: mfiManagerConstants.MFI_MENUS_GETALL_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.MFI_MENUS_GETALL_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.MFI_MENUS_GETALL_FAILURE, error }
  }
}

/**
 * Get Mfi Sub Roles
 *
 * @param {*} mfiCode int
 */
const getMFISubRoleLimits = (mfiCode) => {
  return (dispatch) => {
    dispatch(request({}))

    mfiManagerService.getMFISubRoleLimits(mfiCode).then(
      (subRoles) => dispatch(success(subRoles)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return { type: mfiManagerConstants.MFI_SUBROLES_LIMITS_GETALL_REQUEST }
  }
  function success(data) {
    return {
      type: mfiManagerConstants.MFI_SUBROLES_LIMITS_GETALL_SUCCESS,
      data,
    }
  }
  function failure(error) {
    return {
      type: mfiManagerConstants.MFI_SUBROLES_LIMITS_GETALL_FAILURE,
      error,
    }
  }
}

/**
 * Add Mfi Sub Role Limit
 *
 * @param {*} mfiCode int
 */
const addMFISubRoleLimit = (mfiCode, id) => {
  return (dispatch) => {
    dispatch(success({ id, mfiCode }))
  }

  function success(data) {
    return { type: mfiManagerConstants.MFI_SUBROLES_LIMIT_ADD_SUCCESS, data }
  }
}

/**
 * Add Mfi Sub Role Limit
 *
 * @param {*} mfiCode int
 */
const updateMFISubRoleLimit = (id, key, data) => {
  return (dispatch) => {
    if (isNaN(Number(data))) {
      dispatch(alertActions.error("Enter valid Number value for loan limits."))
    } else {
      dispatch(success({ id, key, data }))
    }
  }

  function success(data) {
    return { type: mfiManagerConstants.MFI_SUBROLES_LIMIT_UPDATE_SUCCESS, data }
  }
}
/**
 * Delete Mfi Sub Role Limit
 *
 * @param {*} subRoleLimitID int
 */
const deleteMFISubRoleLimit = (subRoleLimitID) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.deleteMFISubRoleLimits(subRoleLimitID).then(
      () => {
        dispatch(success(subRoleLimitID))
      },
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return { type: mfiManagerConstants.MFI_SUBROLES_LIMIT_DELETE_REQUEST }
  }
  function success(id) {
    return { type: mfiManagerConstants.MFI_SUBROLES_LIMIT_DELETE_SUCCESS, id }
  }
  function failure(error) {
    return {
      type: mfiManagerConstants.MFI_SUBROLES_LIMIT_DELETE_FAILURE,
      error,
    }
  }
}

/**
 * Add Mfi Sub Role Limit to state
 *
 * @param {*} payload object
 */
const addMFISubRoleLimitFlow = (mfiID) => {
  return (dispatch) => {
    dispatch(success(mfiID))
  }

  function success(id) {
    return { type: mfiManagerConstants.MFI_SUBROLES_LIMIT_FLOW_ADD_SUCCESS, id }
  }
}

/**
 * update state Mfi Sub Role Limit Flow
 *
 * @param {*} payload object
 */
const updateMFISubRoleLimitFlow = (id, flowData, index) => {
  return (dispatch, getState) => {
    dispatch(request({}))
    const { subRoleLimits } = getState().mfis
    // ensure limits are not empty
    if (!flowData.subRole) {
      dispatch(failure("Ensure you selected a subrole"))
    } else {
      const subRoleLimit = subRoleLimits.filter((obj) => {
        return obj.id === id
      })
      subRoleLimit[0].categoryWorkflow.forEach((item, i) => {
        if (i === index) {
          const data = {
            id: id,
            subRole: flowData.subRole,
          }
          subRoleLimit[0].categoryWorkflow[i] = data
        }
      })

      dispatch(success(subRoleLimit))
    }
  }

  function request() {
    return { type: mfiManagerConstants.MFI_SUBROLES_LIMIT_FLOW_UPDATE_REQUEST }
  }
  function success(data) {
    return {
      type: mfiManagerConstants.MFI_SUBROLES_LIMIT_FLOW_UPDATE_SUCCESS,
      data,
    }
  }
  function failure(error) {
    return {
      type: mfiManagerConstants.MFI_SUBROLES_LIMIT_FLOW_UPDATE_FAILURE,
      error,
    }
  }
}

/**
 * submit Mfi Sub Role Limit Flow
 *
 * @param {*} payload object
 */
const submitMFISubRoleLimitFlow = (responseData, loanLimitCategoryIdPrev) => {
  return (dispatch, getState) => {
    const loanLimitCategoryId = responseData.id
    const { subRoleLimits } = getState().mfis

    subRoleLimits.forEach((item) => {
      let data = {
        loanLimitCategoryId: loanLimitCategoryId,
        workflow: [],
      }
      if (item.id === loanLimitCategoryIdPrev) {
        const updateCheck = item.categoryWorkflow.filter((obj) => {
          return obj.id === loanLimitCategoryId
        })

        item.categoryWorkflow.forEach((itemData, i) => {
          data.workflow.push({
            subRoleCode: itemData.subRole,
            loanLimitCategoryId: loanLimitCategoryId,
          })
        })

        if (updateCheck) {
          // update workflow
          dispatch(requestUpdate({}))
          mfiManagerService.updateMFISubRoleLimitFlow(data).then(
            () => {
              dispatch(getAllMFIs())
            },
            (error) => {
              dispatch(alertActions.error(error.response.data.message))
              dispatch(failureUpdate(error.response.data.message))
            }
          )
        } else {
          // Add workflow
          dispatch(requestSubmit({}))
          mfiManagerService.submitMFISubRoleLimitFlow(data).then(
            () => {
              dispatch(getAllMFIs())
            },
            (error) => {
              dispatch(alertActions.error(error.response.data.message))
              dispatch(failureSubmit(error.response.data.message))
            }
          )
        }
      }
    })
  }

  function requestSubmit() {
    return { type: mfiManagerConstants.MFI_SUBROLES_LIMIT_FLOW_SUBMIT_REQUEST }
  }
  function requestUpdate() {
    return { type: mfiManagerConstants.MFI_SUBROLES_LIMIT_FLOW_UPDATE_REQUEST }
  }
  function failureSubmit(error) {
    return {
      type: mfiManagerConstants.MFI_SUBROLES_LIMIT_FLOW_SUBMIT_FAILURE,
      error,
    }
  }
  function failureUpdate(error) {
    return {
      type: mfiManagerConstants.MFI_SUBROLES_LIMIT_FLOW_UPDATE_FAILURE,
      error,
    }
  }
}

/**
 * Add and update Mfi Sub Role Limit
 * Dispatch Add flow
 *
 * @param {*} mfiCode int
 */
const submitMFISubRoleLimitCategoryAndFlow = (mfiCode, messages) => {
  return async (dispatch, getState) => {
    // get sub role limit data from state.
    const { subRoleLimits, selectedWorkflowGroupId } = getState().mfis
    subRoleLimits.forEach((item) => {
      let data = {
        lowerLimit: item.lowerLimit,
        upperLimit: item.upperLimit,
        automaticApproval: item.automaticApproval,
      }

      if (item.id) {
        // update limit
        dispatch(requestUpdate({}))
        data["workflowGroupId"] = item.workflowGroupId
        mfiManagerService.updateMFISubRoleLimits(item.id, data).then((res) => {
          const responseData = {
            id: res.id,
            mfiCode: res.mfiCode,
            lowerLimit: res.lowerLimit,
            upperLimit: res.upperLimit,
            categoryWorkflow: res.categoryWorkflow,
          }
          // Post/Update workflow
          dispatch(submitMFISubRoleLimitFlow(responseData, item.id))
        })
      } else {
        // add limit
        dispatch(requestAdd({}))
        data["workflowGroupId"] = selectedWorkflowGroupId
        mfiManagerService.addMFISubRoleLimits(data).then(
          (res) => {
            const responseData = {
              id: res.id,
              mfiCode: res.mfiCode,
              lowerLimit: res.lowerLimit,
              upperLimit: res.upperLimit,
              automaticApproval: res.automaticApproval,
              categoryWorkflow: res.categoryWorkflow,
            }
            // Post/Update workflow
            dispatch(submitMFISubRoleLimitFlow(responseData, item.id))
            dispatch(
              alertActions.success(
                `Limit ${"lowerLimit:" + data.lowerLimit} and ${
                  "UpperLimit:" + data.upperLimit
                } ${messages.addSuccess}`
              )
            )
          },
          (error) => {
            dispatch(
              alertActions.error(
                `Limit ${"lowerLimit:" + data.lowerLimit} and ${
                  "UpperLimit:" + data.upperLimit
                } ${messages.addFail} ${error.response.data.errors}`
              )
            )
          }
        )
      }
    })
  }

  function requestUpdate() {
    return { type: mfiManagerConstants.MFI_SUBROLES_LIMITS_UPDATE_REQUEST }
  }
  function requestAdd() {
    return { type: mfiManagerConstants.MFI_SUBROLES_LIMITS_ADD_REQUEST }
  }
}

const refreshTable = () => {
  return (dispatch) => {
    dispatch(request({}))
  }

  function request() {
    return { type: mfiManagerConstants.REFRESH_TABLE }
  }
}

/**
 * Get mfi Hierarchy Data
 */
const getHierarchy = (mfiId) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.getHierarchy(mfiId).then(
      (res) => dispatch(success(res)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return { type: mfiManagerConstants.GET_HIERARCHY_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.GET_HIERARCHY_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.GET_HIERARCHY_FAILURE, error }
  }
}

/**
 * update hierarchy entity
 *
 * @param {*} payload object
 */
const editHierarchy = (data, id, layerId) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.editHierarchy(data, id).then(
      (res) => {
        dispatch(success(res, layerId))
        dispatch(alertActions.success(`Edit successful`))
        // make a fetch
        dispatch(mfiManagerActions.getMFISubRoles(id))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Edit failed"))
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.EDIT_HIERARCHY_ENTITY_REQUEST }
  }
  function success(data, layerId) {
    return {
      type: mfiManagerConstants.EDIT_HIERARCHY_ENTITY_SUCCESS,
      data,
      layerId,
    }
  }
  function failure(error) {
    return { type: mfiManagerConstants.EDIT_HIERARCHY_ENTITY_FAILURE, error }
  }
}

/**
 * update hierarchy layer
 *
 * @param {*} data object
 */
const editHierarchyLayer = (data, id) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.editHierarchyLayer(data, id).then(
      (res) => {
        dispatch(success(res))
        dispatch(alertActions.success(`Edit successful`))
        document.getElementById("react-tabs-0").click()
        document.getElementById("react-tabs-2").click()
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Edit failed"))
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.EDIT_HIERARCHY_LAYER_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.EDIT_HIERARCHY_LAYER_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.EDIT_HIERARCHY_LAYER_FAILURE, error }
  }
}

/**
 * add hierarchy entity
 *
 * @param {*} payload object
 */
const addHierarchy = (data) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.addHierarchy(data).then(
      (res) => {
        dispatch(success(res, data.hierarchyLayerId))
        dispatch(alertActions.success(`Add successful`))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error("Add failed: " + error.response.data.message)
        )
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.ADD_HIERARCHY_ENTITY_REQUEST }
  }
  function success(data, layerId) {
    return {
      type: mfiManagerConstants.ADD_HIERARCHY_ENTITY_SUCCESS,
      data,
      layerId,
    }
  }
  function failure(error) {
    return { type: mfiManagerConstants.ADD_HIERARCHY_ENTITY_FAILURE, error }
  }
}

/**
 * add hierarchy level
 *
 * @param {*} payload object
 */
const addLayer = (data) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.addLayer(data).then(
      (res) => {
        dispatch(success(res))
        dispatch(alertActions.success(`Add successful`))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error("Add failed: " + error.response.data.message)
        )
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.ADD_HIERARCHY_LAYER_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.ADD_HIERARCHY_LAYER_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.ADD_HIERARCHY_LAYER_FAILURE, error }
  }
}

/**
 * get Questionnaires
 *
 */
const getQuestionnaires = (id) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.getQuestionnaires(id).then(
      (res) => {
        dispatch(success(res))
      },
      (error) => {
        dispatch(failure(error))
        if (error && error.response && error.response.data) {
          dispatch(
            alertActions.error("Fetch failed: " + error.response.data.message)
          )
        }
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.GET_QUESTIONNAIRES_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.GET_QUESTIONNAIRES_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.GET_QUESTIONNAIRES_FAILURE, error }
  }
}

/**
 * add Questionnaire
 *
 * @param {*} payload object
 */
const addQuestionnaire = (payload) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.addQuestionnaire(payload).then(
      (res) => {
        dispatch(mfiManagerActions.getQuestionnaires(payload.mfi_id))
        dispatch(success(res))
        dispatch(alertActions.success(payload.message))
      },
      (error) => {
        dispatch(failure(error))
        if (error && error.response && error.response.data) {
          dispatch(alertActions.error(payload.error))
        }
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.ADD_QUESTIONNAIRE_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.ADD_QUESTIONNAIRE_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.ADD_QUESTIONNAIRE_FAILURE, error }
  }
}

/**
 * edit Questionnaire
 *
 * @param {*} payload object
 */
const editQuestionnaire = (data, id) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.editQuestionnaire(data, id).then(
      (res) => {
        if (res.status === "Error")
          dispatch(alertActions.error("Edit failed: " + res.message))
        dispatch(mfiManagerActions.getQuestionnaires(data.mfi_id))
        dispatch(success(res))
        dispatch(alertActions.success(data.success))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(data.error))
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.EDIT_QUESTIONNAIRE_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.EDIT_QUESTIONNAIRE_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.EDIT_QUESTIONNAIRE_FAILURE, error }
  }
}

/**
 * edit Questionnaire
 *
 * @param {*} payload id
 */
const deleteQuestionnaire = (data, message) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.deleteQuestionnaire(data.id).then(
      (res) => {
        dispatch(success(res))
        dispatch(alertActions.success(message.success))
        dispatch(mfiManagerActions.getQuestionnaires(data.mfiId))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(message.error))
        dispatch(mfiManagerActions.getQuestionnaires(data.mfiId))
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.DELETE_QUESTIONNAIRE_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.DELETE_QUESTIONNAIRE_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.DELETE_QUESTIONNAIRE_FAILURE, error }
  }
}

/**
 * deactivate Questionnaire
 *
 * @param {*} payload id
 */
const deactivateQuestionnaire = (data, id) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.deactivateQuestionnaire(data, id).then(
      (res) => {
        dispatch(success(res))
        dispatch(mfiManagerActions.getQuestionnaires(data.mfi_id))
        dispatch(alertActions.success(data.success))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(mfiManagerActions.getQuestionnaires(data.mfi_id))
        dispatch(alertActions.error(data.error))
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.DEACTIVATE_QUESTIONNAIRE_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.DEACTIVATE_QUESTIONNAIRE_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.DEACTIVATE_QUESTIONNAIRE_FAILURE, error }
  }
}

/**
 * Reset Questionnaire
 *
 * @param {*} payload object
 */
const resetQuestionnaire = (data, message) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.resetQuestionnaire(data, data.id).then(
      (res) => {
        dispatch(success(res))
        dispatch(alertActions.success(message.success))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(message.error))
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.RESET_QUESTIONNAIRE_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.RESET_QUESTIONNAIRE_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.RESET_QUESTIONNAIRE_FAILURE, error }
  }
}

/**
 * upload Mfi Image
 *
 * @param {*} payload object
 */
const addMfiImage = (payload) => {
  return (dispatch) => {
    mfiManagerService.addMfiImage(payload).then(
      (res) => {
        dispatch(mfiManagerActions.getMfiImages(payload.mfiId))
        dispatch(alertActions.success("Image upload successful"))
      },
      (error) => {
        if (error && error.response && error.response.data) {
          dispatch(alertActions.error("Image upload failed"))
        }
      }
    )
  }
}

/**
 * get Mfi Images
 *
 */
const getMfiImages = (id) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.getMfiImages(id).then(
      (res) => {
        dispatch(success(res))
      },
      (error) => {
        dispatch(failure(error))
        if (error && error.response && error.response.data) {
          dispatch(
            alertActions.error("Fetch failed: " + error.response.data.message)
          )
        }
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.GET_MFI_IMAGES_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.GET_MFI_IMAGES_SUCCESS, data }
  }
  function failure(error) {
    return { type: mfiManagerConstants.GET_MFI_IMAGES_FAILURE, error }
  }
}

/**
 * edit Questionnaire
 *
 * @param {*} payload id
 */
const deleteMfiImage = (item) => {
  return (dispatch) => {
    mfiManagerService.deleteMfiImage(item.id).then(
      (res) => {
        dispatch(alertActions.success("Image deleted successfully"))
        dispatch(mfiManagerActions.getMfiImages(item.mfi_id))
      },
      (error) => {
        dispatch(alertActions.error("Image deletion failed"))
        dispatch(mfiManagerActions.getMfiImages(item.mfi_id))
      }
    )
  }
}

const addExternalIntegration = (data) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.addExternalIntegration(data).then(
      (res) => {
        dispatch(success(res))
        dispatch(
          alertActions.success(
            "External Integration data has been added successfully"
          )
        )
      },
      (error) => {
        dispatch(failure(error))
        if (error.response.data && error.response.data.message) {
          dispatch(alertActions.error(error.response.data.message))
        }
        // dispatch(alertActions.error(message.error));
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.ADD_EXTERNAL_INTERGRATION_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.ADD_EXTERNAL_INTERGRATION_SUCCESS, data }
  }
  function failure(error) {
    return {
      type: mfiManagerConstants.ADD_EXTERNAL_INTERGRATION_FAILURE,
      error,
    }
  }
}

const editExternalIntegration = (id, data) => {
  const payload = { id, ...data }
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.editExternalIntegration(payload).then(
      (res) => {
        dispatch(success(res))
        dispatch(
          alertActions.success(
            "External Integration data has been updated successfully"
          )
        )
      },
      (error) => {
        dispatch(failure(error))
        if (error.response.data && error.response.data.message) {
          dispatch(alertActions.error(error.response.data.message))
        }
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.EDIT_EXTERNAL_INTERGRATION_REQUEST }
  }
  function success(data) {
    return {
      type: mfiManagerConstants.EDIT_EXTERNAL_INTERGRATION_SUCCESS,
      data,
    }
  }
  function failure(error) {
    return {
      type: mfiManagerConstants.EDIT_EXTERNAL_INTERGRATION_FAILURE,
      error,
    }
  }
}

const getExternalIntegration = (id) => {
  return (dispatch) => {
    dispatch(request({}))
    mfiManagerService.getExternalIntegration(id).then(
      (res) => {
        dispatch(success(res))
      },
      (error) => {
        dispatch(failure(error))
        if (error && error.response && error.response.data) {
          dispatch(
            alertActions.error("Fetch failed: " + error.response.data.message)
          )
        }
      }
    )
  }

  function request() {
    return { type: mfiManagerConstants.GET_EXTERNAL_INTERGRATION_REQUEST }
  }
  function success(data) {
    return { type: mfiManagerConstants.GET_EXTERNAL_INTERGRATION_SUCCESS, data }
  }
  function failure(error) {
    return {
      type: mfiManagerConstants.GET_EXTERNAL_INTERGRATION_FAILURE,
      error,
    }
  }
}

export const mfiManagerActions = {
  getAllMFIs,
  getMFIById,
  createMFI,
  updateMFI,
  updateMFIScoreUrl,
  deleteMFI,
  addMFISubRoles,
  updateMFISubRole,
  getMFISubRoles,
  deleteMFISubRole,
  getMFISubRoleLimits,
  updateMFISubRoleLimit,
  addMFISubRoleLimit,
  deleteMFISubRoleLimit,
  addMFISubRoleLimitFlow,
  updateMFISubRoleLimitFlow,
  submitMFISubRoleLimitCategoryAndFlow,
  refreshTable,
  getWorkflowGroups,
  addWorkflowGroup,
  editWorkflowGroup,
  filterWorkflowGroups,
  getHierarchy,
  editHierarchy,
  editHierarchyLayer,
  addHierarchy,
  addLayer,
  getMFIRoles,
  getQuestionnaires,
  getMfiImages,
  addQuestionnaire,
  addMfiImage,
  deleteMfiImage,
  editQuestionnaire,
  deleteQuestionnaire,
  deactivateQuestionnaire,
  resetQuestionnaire,
  addExternalIntegration,
  editExternalIntegration,
  getExternalIntegration,
  addCustomMenu,
  getCustomMenus,
  editCustomMenus,
  addMpesaDetails,
  editMpesaDetails,
  getMpesaDetails,
  addLoanDistributed,
  getLoanDistributed,
  editLoanDistributed,
}

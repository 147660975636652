import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import slugify from 'slugify';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { modalActions } from '../../../modals';

function CashflowStatements({ scoreLogic, dispatch }) {
    const { cashflowStatements } = scoreLogic;
    const { t } = useTranslation();

    useEffect(() => {
      // scroll page to top on displaying component
      const wrapper = document.getElementsByClassName("main-content");
      wrapper[0].scrollIntoView();
    }, []);

    cashflowStatements.sort((a, b) => a.code.localeCompare(b.code));

    /**
     * Select variable
     *
     * @param {*} slug
     * @param {*} type
     */
    const addVariable = (slug, types, selectedCodes, title, selectOne) => {
        dispatch(modalActions.addCashFlowVariable({
            codes: selectedCodes, types, title, id: slug, selectOne,
        }));
    };

    /**
     * Select variable
     *
     * @param {*} slug
     * @param {*} type
     */
    const deleteVariable = (slug, types, selectedCodes) => {
        dispatch(modalActions.deleteCashFlowVariable({ codes: selectedCodes, types, id: slug }));
    };

    /**
     * Display operation cell
     *
     * @param {*} slug
     * @param {*} item
     */
    const getOperation = (slug, item) => {
        // eslint-disable-next-line prefer-const
        let { timeDependentCalculatedVariables, everyMonthCalculatedVariables, variableCodes } = item;

        if (isEmpty(variableCodes)) {
            variableCodes = ['', '', ''];
        }

        switch (slug) {
        case 'D': // household-revenues
        case 'E': // household-expenses
        case 'B': // farm-expenses
        case 'A': // farm-revenue
        case 'G': // outstanding loans
            const tdcv = (
              <div className="flex variable-holder">
                { timeDependentCalculatedVariables && timeDependentCalculatedVariables.map((item, idx) => {
                  return (
                    <div key={idx} className="item variable-item mx-1">
                        <span className="variable-item__label">
                            {item}
                        </span>
                        <span className="variable-item__delete" onClick={ (e) => { deleteVariable(slug, ['tdcv'], [item]); } }>x</span>
                    </div>
                )}) }
              </div>
            );
            const emcv = (
              <div className="flex variable-holder">
                  { everyMonthCalculatedVariables && everyMonthCalculatedVariables.map((item, idx) => {
                    return (
                        <div key={idx} className="item variable-item mx-1">
                        <span className="variable-item__label">{item}</span>
                        <span className="variable-item__delete" onClick={ (e) => { deleteVariable(slug, ['emcv'], [item]); } }>x</span>
                        </div>
                  )})
                  }
              </div>
            );

            return (
                <div className={`flex w-100 ${slug}`}>
                    <div className="cashflow-statements__action--add cashflow-statements__action--add-tdcv flex items-center w-12">{tdcv}<div className="cashflow-statements__action--add--plus ml-auto" onClick={ (e) => { addVariable(slug, ['tdcv'], timeDependentCalculatedVariables, 'Select Item'); } }>+</div></div>
                    <div className="cashflow-statements__action--add cashflow-statements__action--add-emcv flex items-center w-12">{emcv} <div className="cashflow-statements__action--add--plus ml-auto" onClick={ (e) => { addVariable(slug, ['emcv'], everyMonthCalculatedVariables, 'Select Item'); } }>+</div></div>
                </div>
            );
        case 'I': // average-cashflow
            let content = [];
            if (variableCodes && variableCodes[0]) {
              content.push(
                <div className="item variable-item">
                  <span className="variable-item__label">{variableCodes[0]}</span>
                  <span className="variable-item__delete" onClick={ (e) => { deleteVariable(slug, ['code'], [variableCodes[0]]); } }>x</span>
                </div>
              );
            }

            const code = (
              <div className="flex variable-holder">
              {content}
              </div>);
            return (
                <div className={`flex w-100 ${slug}`}>
                    <span className="cashflow-statements__expression ml-auto">∑n (H/Tenor)</span>
                    <div className="cashflow-statements__action--add cashflow-statements__action--add-code flex items-center w-6">{code} <div className="cashflow-statements__action--add--plus ml-auto" onClick={ (e) => { addVariable(slug, ['code'], variableCodes[0], 'Select Item'); } }>+</div></div>
                </div>
            );
        case 'J': // average-new-loan
            let contentJ0 = [];
            let contentJ1 = [];
            let contentJ2 = [];

            if (variableCodes && variableCodes[0]) {
              contentJ0.push(
                   <div className="item variable-item">
                     <span className="variable-item__label">{variableCodes[0]}</span>
                     <span className="variable-item__delete" onClick={ (e) => { deleteVariable(slug, ['code'], [variableCodes[0]]); } }>x</span>
                   </div>
              );
            }

            if (variableCodes && variableCodes[1]) {
              contentJ1.push(
                   <div className="item variable-item">
                     <span className="variable-item__label">{variableCodes[1]}</span>
                     <span className="variable-item__delete" onClick={ (e) => { deleteVariable(slug, ['code1'], [variableCodes[1]]); } }>x</span>
                   </div>
              );
            }

            if (variableCodes && variableCodes[2]) {
              contentJ2.push(
                   <div className="item variable-item">
                     <span className="variable-item__label">{variableCodes[2]}</span>
                     <span className="variable-item__delete" onClick={ (e) => { deleteVariable(slug, ['code2'], [variableCodes[2]]); } }>x</span>
                   </div>
              );
            }
            const code0 = (<div className="flex variable-holder"> { contentJ0 }</div>);
            const code1 = (<div className="flex variable-holder"> { contentJ1 }</div>);
            const code2 = (<div className="flex variable-holder"> { contentJ2 }</div>);

            return (
                <div className={`flex w-100 items-center ${slug}`}>
                    <div className="cashflow-statements__action--add cashflow-statements__action--add-code1 flex items-center w-6">{code0}<div className="cashflow-statements__action--add--plus ml-auto" onClick={ (e) => { addVariable(slug, ['assumptions'], variableCodes[0], 'Interest Rate', { code: true, assumptions: true }); } }>+</div></div>
                    <div className="cashflow-statements__action--add cashflow-statements__action--add-code2 flex items-center w-6">{code1}<div className="cashflow-statements__action--add--plus ml-auto" onClick={ (e) => { addVariable(slug, ['code1'], variableCodes[1], 'Loan Tenor'); } }>+</div></div>
                    <div className="cashflow-statements__action--add cashflow-statements__action--add-code3 flex items-center w-6">{code2}<div className="cashflow-statements__action--add--plus ml-auto" onClick={ (e) => { addVariable(slug, ['code2'], variableCodes[2], 'Loan Amount'); } }>+</div></div>
                </div>
            );
        default:
            return null;
        }
    };

    return (
        <div className="cashflow-statements shadow-none p-6">
            <div className="cashflow-statements__title-bar flex">
                <div className="cashflow-statements__label cashflow-statements__label--code"></div>
                <div className="cashflow-statements__label cashflow-statements__label--description">{t('module.scoreLogic.view.scoreLogic.cashflowStatements.description')}</div>
                <div className="cashflow-statements__label cashflow-statements__label--tdcv ml-auto">{t('module.scoreLogic.view.scoreLogic.cashflowStatements.timeDependent')}</div>
                <div className="cashflow-statements__label cashflow-statements__label--emcv">{t('module.scoreLogic.view.scoreLogic.cashflowStatements.emcv')}</div>
            </div>
            {
                cashflowStatements.map((item, idx) => {
                    const { code, description, formulaExpression } = item;
                    const slug = slugify(code);
                    return (
                        <div className={`cashflow-statements__row flex ${slug}`} key={idx}>
                            <div className="cashflow-statements__code">{code}</div>
                            <div className="cashflow-statements__description">{description}</div>
                            <div className="cashflow-statements__operations ml-auto">
                                { isEmpty(formulaExpression) && getOperation(slug, item) }
                                { formulaExpression && <span className="cashflow-statements__expression ml-auto">{ formulaExpression }</span> }
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    const { scoreLogic } = state;
    return { scoreLogic };
};

const connectedCashflowStatements = connect(mapStateToProps)(CashflowStatements);
export { connectedCashflowStatements as CashflowStatements };

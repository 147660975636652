import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { creditScoreActions } from '../_actions';
import { CashflowGraph } from './CashflowGraph';
import { Loading } from '../../../components/exceptions';
import { ApplicationDetailsActions } from '../../applicationManager';

export const CashflowReport = ({ id }) => {
    const graph = useSelector((state) => {
        const { creditScore: { graph: data } } = state;
        return data;
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(creditScoreActions.getFormulaResult(id));
    }, [dispatch, id]);

    if (!graph) { return <Loading />; }

    return (
        <>
            <CashflowGraph id={id} chartData={graph} options={{}} />
            <ApplicationDetailsActions id={id} saveDisabled={ true }/>
        </>);
};

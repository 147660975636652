import React, { useState, useEffect, useRef } from 'react';
import { FormGroup, FormRadio } from 'shards-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    FormWithConstraints,
    Input,
    Async,
    FieldFeedbacks,
    FieldFeedback,
} from 'react-form-with-constraints-bootstrap4';
import { isEmpty, find, merge } from 'lodash';
import { NumericAssumptionCodes } from './Assumptions/NumericAssumptionCodes';
import { MatrixAssumptionCodes } from './Assumptions/MatrixAssumptionCodes';

import { cslActions } from '..';
import { modalActions } from '../../modals';

function ManageAssumption({id, type = 'numeric', savedData, scoreLogic, dispatch}) {
    const { uid, assumptions } = scoreLogic;
    const [codeInputDisabled, setCodeInputDisabled] = useState(true);
    const form = useRef(null);
    const { t } = useTranslation();

    const getInitialState = (assumptionType) => ({
        surveyUuid: uid,
        assumptionType,
        name: '',
        code: '',
        numeric: '',
        matrix: {
            grid: [
                {
                    x: { },
                    y: '',
                },
            ],
            xBinWidth: '',
            xComputationCode: '',
            xMaxValue: '',
            xType: '',
            yBinWidth: '',
            yComputationCode: '',
            yMaxValue: '',
            yType: '',
        },
    });

    const defaultData = getInitialState(type);

    const mergedData = merge(defaultData, savedData);

    const [inputs, SetInputs] = useState({ ...mergedData });

    useEffect(() => {
        const shouldEnableCodeInput = () => !id;
        setCodeInputDisabled(!shouldEnableCodeInput());
    }, [inputs, id]);

    async function checkCodeAvailability(value) {
        return isEmpty(find(assumptions.filter((item) => item.surveyUuid === uid), { code: value.toUpperCase() }));
    }

    const handleChange = async ({ target, currentTarget }) => {
        const { name, value } = target;
        SetInputs((prevState) => ({ ...prevState, [name]: value }));
        // eslint-disable-next-line no-unused-expressions
        currentTarget.tagName !== 'LABEL' && await form.current.validateFields(currentTarget);
    };

    const resetHandler = () => {
        SetInputs(() => {
            const defaults = getInitialState(type);
            form.current.reset();
            return ({ ...defaults });
        });
    };

    const submissionData = () => {
        const data = {
            name: inputs.description,
            description: inputs.description,
            code: inputs.code,
            surveyUuid: inputs.surveyUuid,
        };

        if (inputs.type === 'matrix') {
            data.matrix = inputs.matrix;
            data.numeric = 0.0;
        } else {
            data.numeric = Number(inputs.numeric);
        }

        data.type = inputs.type;

        return data;
    };

    const saveAssumption = (clear) => {
        const data = submissionData();
        // eslint-disable-next-line no-unused-expressions
        id && dispatch(cslActions.editAssumption(id, data));
        // eslint-disable-next-line no-unused-expressions
        !id && dispatch(cslActions.addAssumption(data));
        // eslint-disable-next-line no-unused-expressions
        !clear && dispatch(modalActions.hideModal(1));
        // eslint-disable-next-line no-unused-expressions
        clear && resetHandler();
    };

    return (
        <FormWithConstraints
            ref={form}
            noValidate
            className="assumptions-editor shadow-none"
        >
            <div className="p-4">
                <FormGroup className="flex">
                    <label className="w-1/2 pr-4">Type</label>
                    <FormRadio
                        checked={inputs.type === 'numeric'}
                        name="type"
                        className="form-control"
                        onChange={handleChange}
                        required
                        value="numeric"
                    >{t('module.scoreLogic.component.manageAssumption.numeric')}</FormRadio>
                    <FormRadio
                        checked={inputs.type === 'matrix'}
                        name="type"
                        className="form-control"
                        onChange={handleChange}
                        value="matrix"
                    >Matrix</FormRadio>
                    <FieldFeedbacks for="type">
                        <FieldFeedback when="*" />
                    </FieldFeedbacks>
                </FormGroup>

                <FormGroup>
                    <div className="flex">
                        <label className="w-1/2 pr-4">{t('module.scoreLogic.component.manageAssumption.code')}</label>
                        <Input
                            className="form-control mb-2"
                            defaultValue={inputs.code}
                            disabled={codeInputDisabled}
                            name="code"
                            onChange={handleChange}
                            required
                            size="sm"
                            type="text"
                        />
                    </div>
                    <FieldFeedbacks for="code">
                        <FieldFeedback when="*" />
                        <Async
                            promise={checkCodeAvailability}
                            pending={<span className="d-block">...</span>}
                            then={(available) => (available ? (
                                ''
                            ) : (
                                <FieldFeedback key="2">{t('module.scoreLogic.component.manageAssumption.codeIsTaken')}</FieldFeedback>
                            ))
                            }
                        />
                    </FieldFeedbacks>
                </FormGroup>

                <FormGroup>
                    <div className="flex">
                        <label className="w-1/2 pr-4">{t('module.scoreLogic.component.manageAssumption.description')}</label>
                        <Input
                            className="form-control mb-2"
                            defaultValue={inputs.description}
                            name="description"
                            onChange={handleChange}
                            required
                            size="sm"
                            type="text"
                        />
                    </div>
                    <FieldFeedbacks for="name">
                        <FieldFeedback when="*" />
                    </FieldFeedbacks>
                </FormGroup>

                {inputs.type === 'numeric' && (
                    <NumericAssumptionCodes
                        formRef={form}
                        id={id}
                        inputs={inputs}
                        resetHandler={resetHandler}
                        saveAssumption={saveAssumption}
                        setInputs={SetInputs}
                        reRender={inputs}
                    />
                )}
                {inputs.type === 'matrix' && (
                    <MatrixAssumptionCodes
                        formRef={form}
                        id={id}
                        inputs={inputs}
                        resetHandler={resetHandler}
                        saveAssumption={saveAssumption}
                        setInputs={SetInputs}
                    />
                )}
            </div>
        </FormWithConstraints>
    );
}

const mapStateToProps = (state) => {
    const { scoreLogic, modals } = state;
    return { scoreLogic, modals };
};

const connectedManageAssumption = connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true },
)(ManageAssumption);
export { connectedManageAssumption as ManageAssumption };

import React from "react"
import PropTypes from "prop-types"
import { NavLink as RouteNavLink } from "react-router-dom"
import { NavItem, NavLink } from "shards-react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

class MenuItem extends React.Component {
  isAuthorised() {
    const { roles, authorities } = this.props

    if (!roles || !authorities) {
      return false
    }

    if (authorities) {
      const newAuthorities = [...authorities]
      newAuthorities.push("PUBLIC")
      if (newAuthorities.some((authority) => roles.indexOf(authority) >= 0)) {
        return true
      }
    }
    return false
  }

  canShowPaymentsMenu() {
    const { profile } = this.props
    const mfiName = profile?.mfi?.mfiName
    const roles = profile?.roles
    if (
      mfiName === "UGACOF" &&
      (roles.includes("MFI_CREDIT_ANALYST") || roles.includes("MFI_SUPERVISOR"))
    ) {
      return true
    } else {
      return false
    }
  }

  render() {
    const { item, t } = this.props
    if (!this.canShowPaymentsMenu() && item?.name === "payments") {
      return null
    }
    //alert(item)
    const divStyle = { lineHeight: "27px" }

    if (!item || !this.isAuthorised()) {
      return null
    }

    return (
      <NavItem>
        <NavLink
          tag={RouteNavLink}
          to={item.to}
          className="text-center h-100"
          style={divStyle}
        >
          {t(`data.sidebarNavItems.${item.name}`)}
        </NavLink>
      </NavItem>
    )
  }
}

MenuItem.propTypes = {
  /**
   * The menu item object.
   */
  item: PropTypes.object,
}

const mapStateToProps = (state) => {
  const { settings, authentication } = state
  const { user, profile } = authentication
  const { authorities } = user || ""
  return { settings, authorities, profile }
}

const connectedMenuItem = withTranslation()(connect(mapStateToProps)(MenuItem))
export { connectedMenuItem as MenuItem }

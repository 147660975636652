import { publisherConstants } from "."

export const publishers = (state = {}, action) => {
  switch (action.type) {
    case publisherConstants.PUBLISHER_SYNC_REQUEST:
      return { ...state, error: null, loading: true }
    case publisherConstants.PUBLISHER_SYNC_SUCCESS:
      return { ...state, error: null, loading: false }
    case publisherConstants.PUBLISHER_LAST_SYNC:
      return { ...state, lastModifiedSyncTime: action.data }
    case publisherConstants.PUBLISHER_SYNC_FAILURE:
      return { ...state, error: true, loading: false }
    default:
      return state
  }
}

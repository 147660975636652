/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Svg from "react-inlinesvg"
import {
  Typography,
  TextField,
  Grid,
  CircularProgress,
} from "@material-ui/core"
import { ButtonGroup, Button, FormSelect } from "shards-react"
import Header from "../../../components/widget/widgetTitle"

import { modalActions } from "../../modals"

import "./PayDecline.scss"
import { useTranslation } from "react-i18next"
import { applicationActions } from ".."
const headerSVG = require("../../../assets/images/icons/header-icon.svg")

const AddBeneficiary = ({ modalID, data }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { ugandaBanks, loading } = useSelector((state) => state.applications)
  const [bankId, setBankId] = useState(0)
  const [inputs, setInputs] = useState({
    accountName: "",
    accountNo: null,
    email: "",
  })

  useEffect(() => {
    dispatch(applicationActions.getUgandaBanks())
  }, [])

  const handeSubmit = () => {
    const bank = ugandaBanks.filter((bank) => bank.id === Number(bankId))
    data.payload[0]["bankId"] = Number(bankId)
    data.payload[0]["accountName"] = inputs.accountName
    data.payload[0]["accountNo"] = Number(inputs.accountNo)
    data.payload[0]["email"] = inputs.email
    data["bankPayment"] = true
    data["bankName"] = bank[0].name
    dispatch(modalActions.payDecline(data))
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSelectChange = (e) => {
    setBankId(e.target.value)
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  const payDisabled =
    !inputs.accountName || !inputs.accountNo || !inputs.email || !bankId

  return (
    <div className="site-modal">
      <Header
        title="Beneficiary Bank Details"
        icon={<Svg className="text-center d-inline-block" src={headerSVG} />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        <p className="text-2xl beneficiary-header">Add a Beneficiary</p>
        <p className="required-text">
          <sup className="text-red-500">*</sup> required field
        </p>
        {loading && <CircularProgress size={20} />}
        {!loading && ugandaBanks.length > 0 && (
          <>
            <Grid container className="mb-1">
              <Grid item sm={3}>
                <Typography>
                  Bank Name<sup className="text-red-500">*</sup>:
                </Typography>
              </Grid>
              <Grid item sm={7}>
                <FormSelect
                  id="bankName"
                  name="bankName"
                  defaultValue={bankId}
                  onChange={handleSelectChange}
                >
                  <option value={0}>Select Bank</option>
                  {ugandaBanks.map((bank) => (
                    <option key={bank.id} value={bank.id}>
                      {bank.name}
                    </option>
                  ))}
                </FormSelect>
              </Grid>
            </Grid>
            <Grid container className="mb-1">
              <Grid item sm={3}>
                <Typography>
                  Bank ID<sup className="text-red-500">*</sup>:
                </Typography>
              </Grid>
              <Grid item sm={7}>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  id="bankId"
                  name="bankId"
                  value={bankId}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container className="mb-1">
              <Grid item sm={3}>
                <Typography>
                  Account Name<sup className="text-red-500">*</sup>:
                </Typography>
              </Grid>
              <Grid item sm={7}>
                <TextField
                  variant="outlined"
                  size="small"
                  id="accountName"
                  name="accountName"
                  value={inputs.accountName}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container className="mb-1">
              <Grid item sm={3}>
                <Typography>
                  Account No<sup className="text-red-500">*</sup>:
                </Typography>
              </Grid>
              <Grid item sm={7}>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  id="accountNo"
                  name="accountNo"
                  value={inputs.accountNo}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container className="mb-2">
              <Grid item sm={3}>
                <Typography>
                  Email<sup className="text-red-500">*</sup>:
                </Typography>
              </Grid>
              <Grid item sm={7}>
                <TextField
                  variant="outlined"
                  type="email"
                  size="small"
                  id="email"
                  name="email"
                  value={inputs.email}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        )}
        <ButtonGroup className="btn-group-actions site-modal__actions">
          <Button
            theme="primary"
            className="thin mr-2"
            onClick={() => {
              handeSubmit()
            }}
            disabled={payDisabled}
          >
            Pay
          </Button>
          <Button
            theme="cancel"
            className="thin delete"
            onClick={() => {
              closeModal()
            }}
          >
            {t("applicationDisburse.cancel")}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default AddBeneficiary

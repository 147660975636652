import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Header from '../../../components/widget/widgetTitle';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    '@global': {
        body: {
            backgroundColor: '#f1f8ff',
        },
    },
    container: {
        width: '400px',
    },
    paper: {
        padding: 0,
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        boxShadow: '0 30px 50px 0 rgba(119, 151, 178, 0.2)',
        border: 'solid 1px #cee0e9',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        padding: '35px',
        paddingTop: '12px',
    },
    submit: {
        backgroundColor: 'var(--primary)',
        margin: theme.spacing(3, 0, 2),
    },
}));

export function ForgotUsername() {
    const classes = useStyles();
    const [email, SetEmail] = useState('');

    function handleEmailChange(e) {
        SetEmail(e.target.value);
    }

    function handleLoginPress() {
    }
    const { t } = useTranslation();

    return (
        <Container className={classes.container} component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Header title={t("module.authentication.component.forgotUsername.headerTitle")} icon={<PersonOutlineOutlinedIcon />} />
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <Grid container direction="row" justify="flex-end">
                        <Grid item xs={4} >
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={ (event) => handleLoginPress(event)}
                                disabled={ !email }
                            >
                    {t("module.authentication.component.forgotUsername.submit")}
                            </Button>
                        </Grid>
                    </Grid>

                </form>
            </div>
        </Container>
    );
}

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { filter } from 'lodash';
import { useTranslation } from 'react-i18next';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';

import { modalActions } from '../../../modals';
import { TableRowsActions } from './Actions';

import './TimeDependent.scss';

function TimeDependent({ scoreLogic, dispatch }) {
    const { uid, formulas } = scoreLogic;
    const surveyFormulas = formulas.filter((item) => item.surveyUuid === uid);
    const timeDependentFlags = filter(surveyFormulas, { type: 'TIME_DEPENDENCY_FLAG' });
    const { t } = useTranslation();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
      // scroll page to top on displaying component
      const wrapper = document.getElementsByClassName("main-content");
      wrapper[0].scrollIntoView();
    }, []);

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(+e.target.value);
        setPage(0);
    };

    const addVariable = () => {
        dispatch(modalActions.addFormula());
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, timeDependentFlags.length - page * rowsPerPage);

    const generateRows = (data) => data
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, idx) => {
            const {
                id, name, code, expression,
            } = row;
            return (
                <TableRow key={idx}>
                    <TableCell component="th" scope="row">{code}</TableCell>
                    <TableCell>{expression}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell align="right"><TableRowsActions id={id}/></TableCell>
                </TableRow>
            );
        });

    return (
        <Paper>
            <div className="table-wrapper">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th">{t('module.scoreLogic.view.timeDependent.TDVCode')}</TableCell>
                            <TableCell>{t('module.scoreLogic.view.timeDependent.computationCode')}</TableCell>
                            <TableCell>{t('module.scoreLogic.view.timeDependent.description')}</TableCell>
                            <TableCell align="right">{t('module.scoreLogic.view.timeDependent.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { generateRows(timeDependentFlags) }
                        { emptyRows > 0 && (
                            <TableRow style={{ height: 25 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell colSpan={6}><div className="btn-add-variable" onClick={ () => { addVariable(); } }>+ {t('module.scoreLogic.view.timeDependent.addVariable')}</div></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={timeDependentFlags.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                onPageChange={handleChangePage}
                labelRowsPerPage={t('module.client.arrears.rowsPerPage')}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

const mapStateToProps = (state) => {
    const { scoreLogic } = state;
    return {
        scoreLogic,
    };
};

const connectedTimeDependent = connect(mapStateToProps)(TimeDependent);
export { connectedTimeDependent as TimeDependent };

import React from "react"
import { ListApplication } from "./List"
import { AddApplication } from "./Add"
import { EditApplication } from "./Edit"
import { ViewApplication } from "./View"
import { ViewApplicationProfile } from "./ViewProfile"
import { ListApplicationPayment } from "./ListPayment"

export const ComponentSwitch = (props) => {
  const components = {
    ListApplication,
    AddApplication,
    EditApplication,
    ViewApplication,
    ViewApplicationProfile,
    ListApplicationPayment,
  }

  const TagName = components[props.tag || "ListApplication"]
  return <TagName />
}
export default ComponentSwitch

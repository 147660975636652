import React, { useState } from "react"
import { connect, useSelector } from "react-redux"
import { Button } from "shards-react"
import Svg from "react-inlinesvg"
import { useTranslation } from "react-i18next"
import { modalActions } from "../../modals"
import "./ApplicationsExport.scss"

const downloadSyncSVG = require("../../../assets/images/icons/download-icon.svg")

function ApplicationsImport(props) {
  const { className, applications, dispatch } = props
  const { loading } = applications

  const importPayments = () => {
    dispatch(modalActions.importPayments({}))
  }

  return (
    <div>
      <Button
        onClick={importPayments}
        className={`${className}`}
        style={{
          height: "fit-content",
          alignSelf: "center",
        }}
      >
        <span className="mr-1">
          {!loading && (
            <Svg
              className="text-center d-inline-block h-3"
              src={downloadSyncSVG}
            />
          )}
        </span>
        <span>Import Payments</span>
      </Button>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { applications } = state
  return { applications }
}

const connectedApplicationsImport = connect(mapStateToProps)(ApplicationsImport)
export { connectedApplicationsImport as ApplicationsPaymentImport }

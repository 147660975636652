export const documentChecklistConstants = {
    DOCUMENT_CHECKLIST_REQUEST: 'DOCUMENT_CHECKLIST_REQUEST',
    DOCUMENT_CHECKLIST_SUCCESS: 'DOCUMENT_CHECKLIST_SUCCESS',
    DOCUMENT_CHECKLIST_FAILURE: 'DOCUMENT_CHECKLIST_FAILURE',

    DOCUMENT_CHECKLIST_CREATE_REQUEST: 'DOCUMENT_CHECKLIST_CREATE_REQUEST',
    DOCUMENT_CHECKLIST_CREATE_SUCCESS: 'DOCUMENT_CHECKLIST_CREATE_SUCCESS',
    DOCUMENT_CHECKLIST_CREATE_FAILURE: 'DOCUMENT_CHECKLIST_CREATE_FAILURE',

    DOCUMENT_CHECKLIST_DELETE_REQUEST: 'DOCUMENT_CHECKLIST_DELETE_REQUEST',
    DOCUMENT_CHECKLIST_DELETE_SUCCESS: 'DOCUMENT_CHECKLIST_DELETE_SUCCESS',
    DOCUMENT_CHECKLIST_DELETE_FAILURE: 'DOCUMENT_CHECKLIST_DELETE_FAILURE',

    DOCUMENT_CHECKLIST_UPLOAD_REQUEST: 'DOCUMENT_CHECKLIST_UPLOAD_REQUEST',
    DOCUMENT_CHECKLIST_UPLOAD_SUCCESS: 'DOCUMENT_CHECKLIST_UPLOAD_SUCCESS',
    DOCUMENT_CHECKLIST_UPLOAD_FAILURE: 'DOCUMENT_CHECKLIST_UPLOAD_FAILURE'
};

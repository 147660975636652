import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Button } from "shards-react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { useTranslation } from "react-i18next"
import uuid from "uuid"
import XLSX from "xlsx"
import PageTitle from "../../../components/common/PageTitle"
import { applicationEditorActions } from "../../applicationEditor"
import { SetQuestionnaire } from "../../scoreLogic/views/SetQuestionnaire"
import { SetUploadFile } from "../components/SetUploadFile"
import { ListJobs } from "../components/ListJobs"
import { toolsActions } from ".."
import { workflowActions } from "../../workflow"

const AGGREGATED_DATA_HEADERS = [
  "farmerCode",
  "noDelivery",
  "quantity",
  "totalAmount",
  "landSize",
  "yieldPerAcre",
  "coffeeGrade",
  "deliveryDuration",
  "deliveryAmount",
  "pricePerKg",
  "repaidLoanStatus",
  "loanBorrowingSize",
]

const REPAYMENT_DATA_HEADERS = ["farmerCode", "transactionAmount"]

export const Import = () => {
  const scoreLogic = useSelector((state) => state.scoreLogic)
  const authentication = useSelector((state) => state.authentication)
  const dispatch = useDispatch()
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [uploadFile, setUploadFile] = useState()
  const { t } = useTranslation()

  const alertMessages = {
    applicationSuccess: t("alertActions.applications.applicationSaved"),
    editSuccess: t("alertActions.applications.editSuccessful"),
  }

  useEffect(() => {
    if (scoreLogic.uid && uploadFile && uploadFile.name) {
      setSubmitButtonDisabled(false)
    }
  }, [scoreLogic.uid, uploadFile])

  const staticPayload = () => {
    const { uid } = scoreLogic
    const timeNow = new Date()
    const surveyInfo = uid.split(":")
    const xFormVersion = surveyInfo[1]
    const survey_uuid = surveyInfo[0]
    const {
      user: { user_name, id },
    } = authentication
    const submissionTime = timeNow.toISOString()
    return {
      __version__: xFormVersion,
      _submission_time: submissionTime,
      _submitted_by_username: user_name,
      _submitted_by_userid: id,
      _xform_id_string: survey_uuid,
      _xform_uuid: survey_uuid,
      today: timeNow.toLocaleDateString().replaceAll("/", "-"),
    }
  }

  const readXlsxFile = (actionItem) => {
    setSubmitButtonDisabled(true)
    const reader = new FileReader()
    reader.onload = (event) => {
      const binaryStr = event.target.result
      const workbook = XLSX.read(binaryStr, { type: "binary" })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const data = XLSX.utils.sheet_to_json(worksheet)

      // assuming the first row in the sheet contains the headers
      const headers = Object.keys(data[0])

      const payload = data.map((row) =>
        headers.reduce((obj, header, index) => {
          //hard coded these headers -> to do: should be dynamic
          if (
            header === "applicant/application_date" ||
            header === "applicant/farmer_dob"
          ) {
            const dateValue = new Date(
              XLSX.SSF.format("yyyy-mm-dd", row[header])
            ).toLocaleDateString("fr-CA")
            obj[header] = dateValue
          } else if (actionItem === "applicationData") {
            obj[header] = row[header]
          } else if (actionItem === "transactionData") {
            obj[AGGREGATED_DATA_HEADERS[index]] = row[header]
          } else if (actionItem === "repaymentData") {
            obj[REPAYMENT_DATA_HEADERS[index]] = row[header]
          }
          if (actionItem === "applicationData") {
            const submissionUuid = uuid()
            return {
              ...staticPayload(),
              ...obj,
              _uuid: submissionUuid,
              "meta/instanceID": `uuid:${submissionUuid}`,
            }
          } else {
            return obj
          }
        }, {})
      )
      if (actionItem === "applicationData") {
        dispatch(
          applicationEditorActions.saveApplication(payload, [], alertMessages)
        )
      } else if (actionItem === "transactionData") {
        dispatch(workflowActions.addTransactionData(payload))
      } else if (actionItem === "repaymentData") {
        dispatch(toolsActions.repayMusoniLoan(payload))
      }
    }
    reader.readAsBinaryString(uploadFile)
  }

  const importData = () => {
    const payload = {
      surveyUuid: scoreLogic.uid,
      file: uploadFile,
    }

    if (scoreLogic.uid && uploadFile) {
      dispatch(toolsActions.importBulkApplications(payload))
    }

    setSubmitButtonDisabled(true)
  }

  const uploadFileHandler = (file) => {
    setUploadFile(file)
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={t("module.tools.import.title")}
          className="text-sm-left mb-3"
        />
      </Row>
      <Col md="12" lg="12" className="mt-4 p-0 relative w-full h-full">
        <Tabs>
          <TabList>
            <Tab>{t("module.tools.import.details")}</Tab>
            <Tab>{t("module.tools.import.processDownload")}</Tab>
          </TabList>
          <TabPanel>
            <div className="p-4">
              <h4 className="font-bold text-base border-bottom section-title pb-1 pt-4">
                {t("module.tools.import.importSettings")}
              </h4>
              <div className="ml-3">
                <SetQuestionnaire desc={t("module.tools.import.subtitle")} />
                <SetUploadFile handler={uploadFileHandler} />
              </div>
            </div>
            <div className="wizard-actions">
              <Button
                onClick={() => readXlsxFile("repaymentData")}
                disabled={submitButtonDisabled}
              >
                {t("module.tools.import.uploadRepaymentData")}
              </Button>
              <Button
                className="ml-12"
                onClick={() => readXlsxFile("transactionData")}
                disabled={submitButtonDisabled}
              >
                {t("module.tools.import.uploadTransactionData")}
              </Button>
              <Button
                className="ml-12"
                onClick={() => readXlsxFile("applicationData")}
                disabled={submitButtonDisabled}
              >
                {t("module.tools.import.uploadApplications")}
              </Button>
              <Button className="ml-12" onClick={importData} disabled>
                {t("module.tools.import.uploadFile")}
              </Button>
            </div>
          </TabPanel>
          <TabPanel>
            <ListJobs id={scoreLogic.uid} />
          </TabPanel>
        </Tabs>
      </Col>
    </Container>
  )
}

import React, { Component } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
} from 'shards-react';

import { connect } from 'react-redux';
import { modalActions } from '../../../modals';
import { withTranslation } from 'react-i18next';

class TableRowsActions extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            id: props.id,
            actionsMenuOpen: false,
        };
    }

    toggle(which) {
        const newState = { ...this.state };
        newState[which] = !this.state[which];
        this.setState(newState);
    }

    editFormula() {
        this.props.dispatch(
            modalActions.editFormula({
                id: this.props.id,
            }),
        );
    }

    deleteFormula() {
        this.props.dispatch(
            modalActions.deleteFormula({
                id: this.props.id,
            }),
        );
    }

    render() {
        const { t } = this.props;
        return (
            <Dropdown
                open={this.state.actionsMenuOpen}
                toggle={() => this.toggle('actionsMenuOpen')}
            >
                <DropdownToggle caret>Actions</DropdownToggle>
                <DropdownMenu small>
                    <div className="dropdown-item">
                        <div onClick={() => { this.editFormula(); }}>{t('module.mfiSetup.actions.edit')}</div>
                    </div>
                    <div className="dropdown-item">
                        <div onClick={() => { this.deleteFormula(); }}>{t('module.mfiSetup.actions.delete')}</div>
                    </div>
                </DropdownMenu>

            </Dropdown>
        );
    }
}

const mapStateToProps = (state) => {
    const { workflow, users, authentication } = state;
    return {
        workflow,
        users,
        authentication,
    };
};

const connectedtableRowsActions = withTranslation()(connect(mapStateToProps)(TableRowsActions));
export { connectedtableRowsActions as TableRowsActions };

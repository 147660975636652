import React, { useState, useRef } from 'react';
import {
    FormWithConstraints, Input, FieldFeedbacks, FieldFeedback,
} from 'react-form-with-constraints-bootstrap4';
import { useTranslation } from 'react-i18next';
import {
    Button,
    FormGroup,
    FormSelect,
} from 'shards-react';
import { useDispatch } from 'react-redux';
import { history } from '../../../../_helpers';
import { currencies } from '../../../../utils/currencies';

import { mfiManagerActions } from '../..';

export function ManageMFI({
    id, mfi = {},
}) {
    const fieldDisable = false;
    const form = useRef(null);
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState({
        ...{
            mfiName: '', country: '', mfiCode: '', currency: '',
        },
        ...mfi,
    });
    const { t } = useTranslation()

    const RedirectToParent = () => {
        history.push('/mfis');
    };

    const handleChange = async ({ target, currentTarget }) => {
        const { name, value } = currentTarget;
        setInputs((prevState) => ({ ...prevState, [name]: value }));
        await form.current.validateFields(target);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        await form.current.validateForm();
        const formIsValid = form.current.isValid();

        if (!formIsValid) {
            return false;
        }

        id && dispatch(mfiManagerActions.updateMFI(id, inputs, RedirectToParent));
        !id && dispatch(mfiManagerActions.createMFI(inputs, RedirectToParent));

        return false;
    };


    return (
        <FormWithConstraints ref={form} noValidate className="mfi-add-form shadow-none p-10" onSubmit={ handleSubmit }>
            <h2 className="form-group-section">{t('module.mfiSetup.manageMfi.mfiDetails')}</h2>
            <div className="flex">
                <div className="w-1/2 pr-10">
                    <FormGroup>
                        <label htmlFor="mfiName">{t('module.mfiSetup.manageMfi.mfiCode')}</label>
                        <Input disabled={id ? true : false} type="number" id="mfiCode" name="mfiCode" className="form-control" defaultValue={inputs.mfiCode} required onChange={handleChange}/>
                        <FieldFeedbacks for="mfiCode">
                            <FieldFeedback when="*" />
                        </FieldFeedbacks>
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="mfiName">{t('module.mfiSetup.manageMfi.mfiName')}</label>
                        <Input disabled={id ? true : false} type="text" id="mfiName" name="mfiName" className="form-control" defaultValue={inputs.mfiName} required onChange={handleChange}/>
                        <FieldFeedbacks for="mfiName">
                            <FieldFeedback when="*" />
                        </FieldFeedbacks>
                    </FormGroup>

                    <FormGroup>
                        <label htmlFor="country">{t('module.mfiSetup.manageMfi.mfiCountry')}</label>
                        <Input disabled={fieldDisable} type="text" id="country" name="country" className="form-control" defaultValue={inputs.country} required onChange={handleChange}/>
                    </FormGroup>

                    <FormGroup>
                        <label htmlFor="currency">{t('module.mfiSetup.manageMfi.currency')}</label>
                        <FormSelect name="currency" className="form-control" defaultValue={inputs.currency} onChange={handleChange}>
                            <option name="">{t('module.mfiSetup.manageMfi.selectCurrency')}</option>
                            {
                                currencies && currencies.map((item, index) => {
                                    return (<option key={index} name={item}>{item}</option>);
                                })
                            }
                        </FormSelect>
                    </FormGroup>
                </div>
            </div>

            <FormGroup className="action-group application-action-group">
                <div className="inline-flex">
                    <Button theme="primary" className="mx-2 d-table ml-auto btn" type="submit">{t('module.mfiSetup.manageMfi.saveClose')}</Button>
                    <Button theme="secondary" className="mx-2 d-table ml-auto btn" onClick={ RedirectToParent }>{t('module.mfiSetup.manageMfi.close')}</Button>
                </div>
            </FormGroup>
        </FormWithConstraints>
    );
}

import { find, findIndex, isEmpty, first } from "lodash"
import { cslConstants, cslService } from "."
import { alertActions } from "../alert"

/**
 * Export modules
 */
export const cslActions = {
  setQuestionnaire,
  getComputationCodes,
  // generateComputationCodes,
  getAssumptions,
  addAssumption,
  editAssumption,
  deleteAssumption,
  getFormulas,
  addFormula,
  addLoanFormula,
  editFormula,
  deleteFormula,
  updateFormulaExpression,
  getSurveyCashflowStatements,
  updateCashflowStatementsVariables,
  deleteCashflowStatementsVariables,
  getScoreCardCategories,
  deleteScoringVariablesEntry,
  editScoringVariablesCondition,
  deleteScoringVariablesCondition,
  updateScoringVariables,
  addScoringVariablesEntry,
  uploadScoringVariablesEntries,
  addScoringVariablesCategory,
  getScoreCardGrade,
  addScoreCardGrade,
  editScoreCardGrade,
  deleteScoreCardGrade,
  getScoreCardProfiles,
  addScoreCardProfileItem,
  editScoreCardProfileItem,
  submitData,
  getSurveyWorkflowGroups,
  getWorkflowGroups,
  setSurveyWorkflowGroups,
  deleteDocumentChecklist,
  getDocumentChecklist,
  addDocumentChecklist,
  editDocumentChecklist,
  addDocumentChecklistState,
  syncDocumentChecklist,
  addScoringVariablesCondition,
  testScoreLogic,
  recalculateScoreLogic,
}

/**
 * Update cashflow using code and data
 *
 * @param {*} state
 * @param {*} payload
 */
function structureCashflowStatementsVariables(cashflowStatements, payload) {
  const { id, data, types } = payload
  const { assumptions, computationCode, tdcv, emcv } = data

  const found = find(cashflowStatements, { code: id })
  const index = findIndex(cashflowStatements, found)
  const statement = index !== -1 && cashflowStatements[index]

  if (statement && tdcv) {
    statement.timeDependentCalculatedVariables = tdcv
  }

  if (statement && emcv) {
    statement.everyMonthCalculatedVariables = emcv
  }

  if (statement.code === "I") {
    statement.variableCodes = [computationCode]
  }

  if (statement.code === "J") {
    if (isEmpty(statement.variableCodes)) {
      statement.variableCodes = ["", "", ""]
    }

    if (
      types.includes("code") &&
      !isEmpty(computationCode) &&
      !isEmpty(computationCode[0])
    ) {
      statement.variableCodes[0] = first(computationCode)
    }

    if (
      types.includes("assumptions") &&
      !isEmpty(assumptions) &&
      !isEmpty(assumptions[0])
    ) {
      statement.variableCodes[0] = first(assumptions)
    }

    if (types.includes("code1") && !isEmpty(computationCode[0])) {
      statement.variableCodes[1] = first(computationCode)
    }

    if (types.includes("code2") && !isEmpty(computationCode[0])) {
      statement.variableCodes[2] = first(computationCode)
    }
  }
  return { statement, index }
}

function removeCashflowStatementsVariablesFromState(
  cashflowStatements,
  payload
) {
  const { id, types, codes } = payload

  const found = find(cashflowStatements, { code: id })
  const index = findIndex(cashflowStatements, found)
  const statement = index !== -1 && cashflowStatements[index]

  if (types.includes("tdcv")) {
    statement.timeDependentCalculatedVariables =
      statement.timeDependentCalculatedVariables.filter(
        (item) => !codes.includes(item)
      )
  }

  if (types.includes("emcv")) {
    statement.everyMonthCalculatedVariables =
      statement.everyMonthCalculatedVariables.filter(
        (item) => !codes.includes(item)
      )
  }

  if (id === "I") {
    statement.variableCodes = []
  }

  if (id === "J") {
    if (isEmpty(statement.variableCodes)) {
      statement.variableCodes = ["", "", ""]
    }

    if (types.includes("code")) {
      statement.variableCodes[0] = ""
    }

    if (types.includes("code1")) {
      statement.variableCodes[1] = ""
    }

    if (types.includes("code2")) {
      statement.variableCodes[2] = ""
    }
  }

  return { statement, index }
}

/**
 * Save selected questionnaire ID to store
 */
function setQuestionnaire(uid) {
  return (dispatch) => {
    dispatch({
      type: cslConstants.CSL_QUESTIONNAIRE_UID,
      id: uid,
    })
  }
}

/**
 * Retrieve existing computation codes from API, if none found
 * Generate computation codes
 */
function getComputationCodes(uid) {
  return (dispatch) => {
    dispatch(request())
    try {
      if (!uid) {
        throw new Error("Survey UID not found.")
      }
      cslService.getComputationCodes(uid).then(
        (data) => {
          !isEmpty(data) && dispatch(success(data))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request(data) {
    return { type: cslConstants.CSL_COMPUTATION_CODES_REQUEST, data }
  }
  function success(data) {
    return { type: cslConstants.CSL_COMPUTATION_CODES_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_COMPUTATION_CODES_FAILURE, error }
  }
}

/**
 * Add new assumptions
 *
 * @param {*} data
 */
function addAssumption(assumptionData) {
  return (dispatch) => {
    try {
      if (isEmpty(assumptionData) || isEmpty(assumptionData.code)) {
        throw new Error("Invalid data")
      }
      cslService.saveAssumptions(assumptionData).then(
        () => {
          dispatch(success(assumptionData))
          dispatch(getAssumptions(assumptionData.surveyUuid))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_ASSUMPTION_ADD_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_ASSUMPTION_ADD_FAILURE, error }
  }
}

/**
 * Edit new assumptions
 *
 * @param {*} data
 */
function editAssumption(id, assumptionData) {
  return (dispatch) => {
    try {
      if (isEmpty(assumptionData) || isEmpty(id)) {
        throw new Error("Invalid data")
      }
      dispatch(success({ id, assumptionData }))
      cslService.editAssumption(id, assumptionData)
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_ASSUMPTION_UPDATE_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_ASSUMPTION_UPDATE_FAILURE, error }
  }
}

/**
 * Delete assumption
 *
 * @param {*} code
 */
function deleteAssumption(assumptionID) {
  return (dispatch) => {
    try {
      dispatch(success(assumptionID))
      cslService.deleteAssumption(assumptionID)
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(id) {
    return { type: cslConstants.CSL_ASSUMPTION_DELETE_SUCCESS, id }
  }
  function failure(error) {
    return { type: cslConstants.CSL_ASSUMPTION_DELETE_FAILURE, error }
  }
}

/**
 * Retrieve assumptions
 */
function getAssumptions(id) {
  return (dispatch) => {
    dispatch(request())

    cslService.getAssumptions(id).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request(data) {
    return { type: cslConstants.CSL_ASSUMPTIONS_REQUEST, data }
  }
  function success(data) {
    return { type: cslConstants.CSL_ASSUMPTIONS_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_ASSUMPTIONS_FAILURE, error }
  }
}

/**
 * Retrieve existing formulas from API
 */
function getFormulas(uid) {
  return (dispatch) => {
    dispatch(request())
    cslService.getFormulas(uid).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request(data) {
    return { type: cslConstants.CSL_FORMULAS_REQUEST, data }
  }
  function success(data) {
    return { type: cslConstants.CSL_FORMULAS_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_FORMULAS_FAILURE, error }
  }
}

/**
 * Add new formula
 */
function addFormula(formulaData) {
  return (dispatch) => {
    const [formula] = formulaData
    try {
      if (isEmpty(formula) || isEmpty(formula.code)) {
        throw new Error("Invalid data")
      }
      cslService.saveFormulas(formula).then(
        (data) => {
          dispatch(success(data))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_FORMULA_ADD_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_FORMULA_ADD_FAILURE, error }
  }
}

/**
 * Add new Loan formula
 */
function addLoanFormula(formulaData) {
  return (dispatch) => {
    try {
      if (isEmpty(formulaData) || isEmpty(formulaData.code)) {
        throw new Error("Invalid data")
      }
      cslService.saveFormulas(formulaData).then(
        (data) => {
          dispatch(success(data))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_FORMULA_ADD_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_FORMULA_ADD_FAILURE, error }
  }
}

/**
 * Edit new assumptions
 *
 * @param {*} data
 */
function editFormula(id, formulaData) {
  return (dispatch) => {
    try {
      if (isEmpty(formulaData) || isEmpty(id)) {
        throw new Error("Invalid data")
      }
      cslService.editFormula(id, formulaData).then(
        () => {
          dispatch(success({ id, data: formulaData }))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_FORMULA_UPDATE_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_FORMULA_UPDATE_FAILURE, error }
  }
}

/**
 * Delete formula
 * @param {*} code
 */
function deleteFormula(formulaId) {
  return (dispatch) => {
    try {
      if (isEmpty(formulaId)) {
        throw new Error("Invalid id")
      }
      cslService.deleteFormula(formulaId).then(
        () => {
          dispatch(success(formulaId))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(id) {
    return { type: cslConstants.CSL_FORMULA_DELETE_SUCCESS, id }
  }
  function failure(error) {
    return { type: cslConstants.CSL_FORMULA_DELETE_FAILURE, error }
  }
}

/**
 * Update formula expression
 *
 * @param {*} data
 */
function updateFormulaExpression(formulaData) {
  return (dispatch) => {
    try {
      if (isEmpty(formulaData)) {
        throw new Error("Invalid data")
      }
      dispatch(success(formulaData))
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_FORMULAS_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_CASHFLOW_STATEMENTS_FAILURE, error }
  }
}

/**
 * Retrieve existing Cashflow Statement from API
 */
function getSurveyCashflowStatements(uid) {
  return (dispatch) => {
    dispatch(request())

    cslService.getSurveyCashflowStatements(uid).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request(data) {
    return { type: cslConstants.CSL_CASHFLOW_STATEMENTS_REQUEST, data }
  }
  function success(data) {
    return { type: cslConstants.CSL_CASHFLOW_STATEMENTS_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_CASHFLOW_STATEMENTS_FAILURE, error }
  }
}

/**
 * Update cashflow statement
 *
 * @param {*} code
 * @param {*} data
 */
function updateCashflowStatementsVariables(data) {
  return (dispatch, getState) => {
    const scoreLogicData = getState().scoreLogic
    try {
      if (isEmpty(data)) {
        throw new Error("Invalid data")
      }

      const structuredData = structureCashflowStatementsVariables(
        scoreLogicData.cashflowStatements,
        data
      )
      const {
        id,
        code,
        description,
        timeDependentCalculatedVariables,
        everyMonthCalculatedVariables,
        variableCodes,
        formulaExpression,
      } = structuredData.statement
      let payload = {
        id,
        surveyUuid: scoreLogicData.uid,
        code,
        description,
        timeDependentCalculatedVariables,
        everyMonthCalculatedVariables,
        variableCodes,
        formulaExpression,
      }

      switch (code) {
        case "C":
          payload.formulaExpression = ["A", "-", "B"]
          break
        case "F":
          payload.formulaExpression = ["C", "+", "D", "-", "E"]
          break
        case "H":
          payload.formulaExpression = ["F", "-", "G"]
          break
        case "K":
          payload.formulaExpression = ["J", "/", "I"]
          break
        case "I":
          payload.variableCodes = payload.variableCodes[0]
          break
        default:
          break
      }

      if (id !== -1) {
        cslService.updateSurveyCashflowStatements(payload).then(
          () => {
            dispatch(success(structuredData))
          },
          (error) => {
            dispatch(failure(error))
          }
        )
      } else if (id === -1) {
        cslService.saveSurveyCashflowStatements(payload).then(
          () => {
            dispatch(success(structuredData))
          },
          (error) => {
            dispatch(failure(error))
          }
        )
      }
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_CASHFLOW_STATEMENTS_UPDATE_ITEM, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_CASHFLOW_STATEMENTS_FAILURE, error }
  }
}

/**
 * Update cashflow statement
 *
 * @param {*} code
 * @param {*} data
 */
function deleteCashflowStatementsVariables(data) {
  return (dispatch, getState) => {
    const scoreLogicData = getState().scoreLogic
    try {
      if (isEmpty(data)) {
        throw new Error("Invalid data")
      }
      const { cashflowStatements } = scoreLogicData
      let structuredData = removeCashflowStatementsVariablesFromState(
        cashflowStatements,
        data
      )
      const {
        id,
        code,
        description,
        timeDependentCalculatedVariables,
        everyMonthCalculatedVariables,
        variableCodes,
        formulaExpression,
      } = structuredData.statement
      let payload = {
        id,
        surveyUuid: scoreLogicData.uid,
        code,
        description,
        timeDependentCalculatedVariables,
        everyMonthCalculatedVariables,
        variableCodes,
        formulaExpression,
      }

      switch (code) {
        case "C":
          payload.formulaExpression = ["A", "-", "B"]
          break
        case "F":
          payload.formulaExpression = ["C", "+", "D", "-", "E"]
          break
        case "H":
          payload.formulaExpression = ["F", "-", "G"]
          break
        case "K":
          payload.formulaExpression = ["J", "/", "I"]
          break
        case "I":
          payload.variableCodes = payload.variableCodes[0]
          break
        default:
          break
      }
      cslService.updateSurveyCashflowStatements(payload).then(
        () => {
          dispatch(success(structuredData))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_CASHFLOW_STATEMENTS_DELETE_ITEM, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_CASHFLOW_STATEMENTS_FAILURE, error }
  }
}

/**
 * Add Variable Category
 *
 * @param {*} id
 * @param {*} data
 */
function addScoringVariablesCategory(id, data) {
  return (dispatch) => {
    try {
      if (isEmpty(id) || isEmpty(data)) {
        throw new Error("Invalid data")
      }
      dispatch(success({ id, data }))
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_ADD_CATEGORY, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_FAILURE, error }
  }
}

/**
 * Add Variable Entry
 *
 * @param {*} id
 * @param {*} data
 */
function addScoringVariablesEntry(data) {
  return (dispatch, getState) => {
    const scoreLogicData = getState().scoreLogic
    const { id, codes } = data

    try {
      if (isEmpty(id)) {
        throw new Error("Invalid data")
      }
      const [firstCode] = codes
      data.id = id
      data.code = firstCode

      cslService.saveScoreCardEntries(data).then(
        () => {
          dispatch(success({ id, data }))
          dispatch(getScoreCardCategories(scoreLogicData.uid))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_ADD_ENTRY, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_FAILURE, error }
  }
}

/**
 * Upload Scoring Variables Entries
 *
 * @param {*} data
 */
function uploadScoringVariablesEntries(data) {
  return (dispatch, getState) => {
    const scoreLogicData = getState().scoreLogic
    try {
      cslService.uploadScoreCardEntries(data).then(
        () => {
          dispatch(getScoreCardCategories(scoreLogicData.uid))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function failure(error) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_FAILURE, error }
  }
}

/**
 * Retrieve existing score card categories from API
 */
function getScoreCardCategories(uid) {
  return (dispatch) => {
    dispatch(request())

    cslService.getScoreCardCategories(uid).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request(data) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_REQUEST, data }
  }
  function success(data) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_FAILURE, error }
  }
}

/**
 * Delete Score Card Conditions statement
 *
 * @param {*} data
 */
function deleteScoringVariablesEntry(id) {
  return (dispatch) => {
    try {
      if (isEmpty(id)) {
        throw new Error("Invalid id")
      }

      cslService.deleteScoreCardEntry(id).then(
        () => {
          dispatch(success(id))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(id) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_DELETE_ENTRY, id }
  }
  function failure(error) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_FAILURE, error }
  }
}

/**
 * edit Score Card Conditions statement
 *
 * @param {*} data
 */
function editScoringVariablesCondition(data) {
  return (dispatch, getState) => {
    const scoreLogicData = getState().scoreLogic
    try {
      if (isEmpty(data)) {
        throw new Error("Invalid data")
      }
      cslService.updateScoreCardCategoryEntryConfiguration(data.id, data).then(
        () => {
          dispatch(success(data))
          dispatch(getScoreCardCategories(scoreLogicData.uid))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_EDIT_CONDITION, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_FAILURE, error }
  }
}

/**
 * edit Score Card Conditions statement
 *
 * @param {*} data
 */
function addScoringVariablesCondition(id, data) {
  return (dispatch, getState) => {
    const scoreLogicData = getState().scoreLogic
    try {
      if (isEmpty(data)) {
        throw new Error("Invalid data")
      }
      const payload = {
        configs: [
          {
            maxScore: data.maxScore,
            scoreCardCategoryEntryId: data.id,
            value: data.value,
          },
        ],
        scoreCardCategoryEntryId: data.id,
      }
      cslService.saveScoreCardCategoryEntryConfiguration(payload).then(
        () => {
          dispatch(success(data))
          dispatch(getScoreCardCategories(scoreLogicData.uid))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_ADD_CONDITION, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_FAILURE, error }
  }
}

/**
 * Delete Score Card Conditions statement
 *
 * @param {*} data
 */
function deleteScoringVariablesCondition(id) {
  return (dispatch) => {
    try {
      if (isEmpty(id)) {
        throw new Error("Invalid id")
      }
      cslService.deleteScoreCardCategoryEntryConfiguration(id).then(
        () => {
          dispatch(success(id))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(id) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_DELETE_CONDITION, id }
  }
  function failure(error) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_FAILURE, error }
  }
}

/**
 * Update Score Card statement
 *
 * @param {*} data
 */
function updateScoringVariables(id, data) {
  return (dispatch, getState) => {
    const scoreLogicData = getState().scoreLogic
    try {
      if (isEmpty(id)) {
        throw new Error("Invalid id")
      }

      if (isEmpty(data)) {
        throw new Error("Invalid data")
      }

      const scoreCardCategoryPayload = {
        id: data.id,
        dataType: data.dataType,
        title: data.title,
        surveyUuid: scoreLogicData.uid,
        code: data.codes[0],
      }

      if (data.id) {
        cslService
          .updateScoreCardEntries(data.id, scoreCardCategoryPayload)
          .then(
            () => {
              dispatch(success(data))
              dispatch(getScoreCardCategories(scoreLogicData.uid))
            },
            (error) => {
              dispatch(failure(error))
            }
          )
      } else {
        cslService.saveScoreCardEntries(scoreCardCategoryPayload).then(
          () => {
            dispatch(success(data))
            dispatch(getScoreCardCategories(scoreLogicData.uid))
          },
          (error) => {
            dispatch(failure(error))
          }
        )
      }
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_ADD_CONDITION, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_SCORING_VARIABLES_FAILURE, error }
  }
}

/**
 * Retrieve existing score card categories from API
 */
function getScoreCardGrade(uid) {
  return (dispatch) => {
    dispatch(request())

    cslService.getScoreCardGrade(uid).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request(data) {
    return { type: cslConstants.CSL_SCORE_GRADE_REQUEST, data }
  }
  function success(data) {
    return { type: cslConstants.CSL_SCORE_GRADE_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_SCORE_GRADE_FAILURE, error }
  }
}

/**
 * Add Score Card Grade statement
 *
 * @param {*} data
 */
function addScoreCardGrade(data) {
  return (dispatch) => {
    if (!isEmpty(data)) {
      dispatch(success(data))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_SCORE_GRADE_ADD, data }
  }
}

/**
 * Edit Score Card Grade statement
 *
 * @param {*} data
 */
function editScoreCardGrade(id, data) {
  data["maximum"] = Number(data.maximum)
  return (dispatch) => {
    try {
      if (isEmpty(id)) {
        throw new Error("Invalid id")
      }

      if (isEmpty(data)) {
        throw new Error("Invalid data")
      }
      dispatch(success(data))
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_SCORE_GRADE_EDIT, data }
  }
  function failure(data) {
    return { type: cslConstants.CSL_SCORE_GRADE_FAILURE, data }
  }
}

/**
 * Delete Score Card Grade statement
 *
 * @param {*} data
 */
function deleteScoreCardGrade(id) {
  return (dispatch) => {
    try {
      if (isEmpty(id)) {
        throw new Error("Invalid id")
      }
      cslService.deleteScoreCardGrade(id).then(
        () => {
          dispatch(success(id))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(id) {
    return { type: cslConstants.CSL_SCORE_GRADE_DELETE, id }
  }
  function failure(data) {
    return { type: cslConstants.CSL_SCORE_GRADE_FAILURE, data }
  }
}

/**
 * get scorecard profile
 *
 * @param {*} data
 */
function getScoreCardProfiles(uid) {
  return (dispatch) => {
    try {
      if (isEmpty(uid)) {
        throw new Error("Invalid uid")
      }
      cslService.getScoreCardProfiles(uid).then(
        (data) => {
          try {
            if (isEmpty(data)) {
              throw new Error("Invalid data")
            }
            dispatch(success(data))
          } catch (error) {
            dispatch(failure(error))
          }
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_SCORE_CARD_PROFILE_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_SCORE_CARD_PROFILE_FAILURE, error }
  }
}

/**
 * add score card profile item
 *
 * @param {*} data
 */
function addScoreCardProfileItem(data) {
  return (dispatch, getState) => {
    try {
      if (isEmpty(data)) {
        throw new Error("Invalid data")
      }
      const scoreLogicData = getState().scoreLogic

      const payload = {
        surveyUuid: scoreLogicData.uid,
        profileListLoanDetailConfiguration: data,
      }

      cslService.addScoreCardProfiles(payload).then(
        () => {
          dispatch(success(data))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_SCORE_CARD_PROFILE_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_SCORE_CARD_PROFILE_FAILURE, error }
  }
}

/**
 * Edit Loan Information
 *
 * @param {*} data
 */
function editScoreCardProfileItem(id, data) {
  return (dispatch) => {
    try {
      if (isEmpty(data)) {
        throw new Error("Invalid data")
      }
      cslService.editScoreCardProfileItem(id, data).then(
        () => {
          dispatch(success({ id, data }))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function success(data) {
    return { type: cslConstants.CSL_SCORE_CARD_PROFILE_UPDATE_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_SCORE_CARD_PROFILE_UPDATE_FAILURE, error }
  }
}

/**
 * Submit data to remote API
 */
function submitData(step, data) {
  return (dispatch) => {
    dispatch(request())

    cslService.submitData(step, data).then(
      (data) => {
        dispatch(success(data))
        dispatch(alertActions.success(`${step.id} setup successful`))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request() {
    return { type: cslConstants.CSL_SUBMIT_REQUEST }
  }
  function success(data) {
    return { type: cslConstants.CSL_SUBMIT_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_SUBMIT_FAILURE, error }
  }
}

/**
 * Retrieve workflow groups
 */
function getWorkflowGroups(mfiId) {
  return (dispatch) => {
    dispatch(request())

    cslService.getWorkflowGroups(mfiId).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request() {
    return { type: cslConstants.CSL_WORKFLOW_GROUPS_GET_REQUEST }
  }
  function success(data) {
    return { type: cslConstants.CSL_WORKFLOW_GROUPS_GET_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_WORKFLOW_GROUPS_GET_FAILURE, error }
  }
}

/**
 * Retrieve survey workflow groups
 */
function getSurveyWorkflowGroups(surveyUuid) {
  return (dispatch) => {
    dispatch(request(surveyUuid))
    cslService.getSurveyWorkflowGroups(surveyUuid).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request(surveyUuid) {
    return {
      type: cslConstants.CSL_SURVEY_WORKFLOW_GROUPS_GET_REQUEST,
      surveyUuid,
    }
  }
  function success(data) {
    return { type: cslConstants.CSL_SURVEY_WORKFLOW_GROUPS_GET_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_SURVEY_WORKFLOW_GROUPS_GET_FAILURE, error }
  }
}

/**
 * Save survey workflow group on the state
 */
function setSurveyWorkflowGroups(data) {
  return (dispatch) => {
    dispatch(request())
    dispatch(success(data))
  }

  function request() {
    return { type: cslConstants.CSL_WORKFLOW_GROUPS_SAVE_REQUEST }
  }
  function success(data) {
    return { type: cslConstants.CSL_WORKFLOW_GROUPS_SAVE_SUCCESS, data }
  }
}

/**
 * Delete Document checklist
 *
 * @param {*} id
 */
function deleteDocumentChecklist(id) {
  return (dispatch) => {
    dispatch(request())
    try {
      cslService.deleteDocumentChecklist(id).then(
        () => {
          dispatch(success(id))
          dispatch(alertActions.success("Delete succesful"))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(alertActions.error("Delete failed: ", error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request() {
    return { type: cslConstants.CSL_CHECKLIST_DELETE_REQUEST }
  }
  function success(id) {
    return { type: cslConstants.CSL_CHECKLIST_DELETE_SUCCESS, id }
  }
  function failure(error) {
    return { type: cslConstants.CSL_CHECKLIST_DELETE_FAILURE, error }
  }
}

/**
 * get Document checklist
 *
 * @param {*} surveyUuid
 */
function getDocumentChecklist(surveyUuid) {
  return (dispatch) => {
    dispatch(request())
    cslService.getDocumentChecklist(surveyUuid).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Fetching checklist failed: ", error))
      }
    )
  }

  function request() {
    return { type: cslConstants.CSL_CHECKLIST_GET_REQUEST }
  }
  function success(data) {
    return { type: cslConstants.CSL_CHECKLIST_GET_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_CHECKLIST_GET_FAILURE, error }
  }
}

/**
 * add Document checklist to state
 *
 * @param {*} payload
 */
function addDocumentChecklistState(payload) {
  return (dispatch) => {
    dispatch(success(payload))
  }

  function success(payload) {
    return { type: cslConstants.CSL_CHECKLIST_STATE_ADD_SUCCESS, data: payload }
  }
}

/**
 * add Document checklist
 *
 * @param {*} payload
 */
function addDocumentChecklist(payload) {
  return (dispatch) => {
    dispatch(request())
    cslService.addDocumentChecklist(payload).then(
      (data) => {
        dispatch(success(data))
        dispatch(alertActions.success("Add successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Add failed: ", error))
      }
    )
  }

  function request() {
    return { type: cslConstants.CSL_CHECKLIST_SAVE_REQUEST }
  }
  function success(data) {
    return { type: cslConstants.CSL_CHECKLIST_SAVE_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_CHECKLIST_SAVE_FAILURE, error }
  }
}

/**
 * edit Document checklist
 *
 * @param {*} payload
 */
function editDocumentChecklist(id, payload) {
  return (dispatch) => {
    dispatch(request())
    cslService.editDocumentChecklist(id, payload).then(
      (data) => {
        dispatch(success(data))
        dispatch(alertActions.success("Edit successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Edit failed: ", error))
      }
    )
  }

  function request() {
    return { type: cslConstants.CSL_CHECKLIST_EDIT_REQUEST }
  }
  function success(data) {
    return { type: cslConstants.CSL_CHECKLIST_EDIT_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_CHECKLIST_EDIT_FAILURE, error }
  }
}

/**
 * sync Kobo Document checklist data
 *
 *
 */
function syncDocumentChecklist(surveyUuid) {
  return (dispatch) => {
    dispatch(request())
    cslService.syncDocumentChecklist(surveyUuid).then(
      (data) => {
        dispatch(success(data))
        dispatch(alertActions.success("Syncing succesful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Syncing failed: ", error))
      }
    )
  }

  function request() {
    return { type: cslConstants.CSL_SYNC_DOCUMENT_CHECKLIST_REQUEST }
  }
  function success(data) {
    return { type: cslConstants.CSL_SYNC_DOCUMENT_CHECKLIST_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_SYNC_DOCUMENT_CHECKLIST_FAILURE, error }
  }
}

/**
 * Test score logic
 *
 */
function testScoreLogic(uid) {
  return (dispatch) => {
    dispatch(request())
    try {
      if (!uid) {
        throw new Error("Survey UID not found.")
      }
      cslService.testScoreLogic(uid).then(
        () => {
          dispatch(success(true))
          dispatch(
            alertActions.success("Test Passed. You can now recalculateScore.")
          )
        },
        (error) => {
          dispatch(failure(error))
          dispatch(alertActions.error(error.response.data.message))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request(data) {
    return { type: cslConstants.CSL_TEST_SCORE_LOGIC_REQUEST, data }
  }
  function success(data) {
    return { type: cslConstants.CSL_TEST_SCORE_LOGIC_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_TEST_SCORE_LOGIC_FAILURE, error }
  }
}

/**
 * recalculate score logic
 *
 */
function recalculateScoreLogic(uid) {
  return (dispatch) => {
    dispatch(request())
    try {
      if (!uid) {
        throw new Error("Survey UID not found.")
      }
      cslService.recalculateScoreLogic(uid).then(
        () => {
          dispatch(success(true))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(alertActions.error(error.response.data.message))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request(data) {
    return { type: cslConstants.CSL_RECALCULATE_SCORE_LOGIC_REQUEST, data }
  }
  function success(data) {
    return { type: cslConstants.CSL_RECALCULATE_SCORE_LOGIC_SUCCESS, data }
  }
  function failure(error) {
    return { type: cslConstants.CSL_RECALCULATE_SCORE_LOGIC_FAILURE, error }
  }
}

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formula } from '../Formula/index';

function EveryMonthCalculated() {
  const { t } = useTranslation();

  useEffect(() => {
    // scroll page to top on displaying component
    const wrapper = document.getElementsByClassName("main-content");
    wrapper[0].scrollIntoView();
  }, []);

  return (<Formula type='EVERY_MONTH_CALCULATED_VARIABLE' title={t('module.scoreLogic.view.formula.headerTitle')} />);
}

const connectedEveryMonthCalculated = connect(null)(EveryMonthCalculated);
export { connectedEveryMonthCalculated as EveryMonthCalculated };

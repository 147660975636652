import React, { useEffect } from 'react';
import { AssumptionsList } from '../../component/Assumptions/AssumptionsList';

export function Assumptions() {
  useEffect(() => {
    // scroll page to top on displaying component
    const wrapper = document.getElementsByClassName("main-content");
    wrapper[0].scrollIntoView();
  }, []);

  return (<AssumptionsList />);
}

import { surveyConstants, surveyService } from '.';

import { alertActions } from '../alert';

export const surveyActions = {
    getSurveys,
};


function getSurveys(mfiId) {
    return (dispatch) => {
        dispatch(request({}));
        surveyService.getSurveys(mfiId).then((data) => {
                dispatch(success(data));
            }, (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            });
    };

    function request(data) { return { type: surveyConstants.GET_SURVEY_REQUEST, data }; }
    function success(data) { return { type: surveyConstants.GET_SURVEY_SUCCESS, data }; }
    function failure(error) { return { type: surveyConstants.GET_SURVEY_FAILURE, error }; }
}

import React, { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { isEmpty } from "lodash"
import { Form, FormGroup, Dropdown, Button } from "shards-react"
import { MenuItem, Select, withStyles } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { modalActions } from "../../modals"
import { commentActions } from "../../comments"
import { applicationActions } from ".."

import "./ApplicationTableFilters.scss"
import { error } from "jquery"

// utility function
const getBase64ImageFromURL = async (url) => {
  const response = await fetch(url)
  const blob = await response.blob()
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })
}

const PrintSelect = withStyles({
  root: { color: "#002060", fontSize: 11, paddingTop: 7, paddingBottom: 7 },
})(Select)

const ApplicationTableFilters = ({
  className,
  applications,
  selectedValue,
  data,
  authentication,
  t,
  setFilteredData,
}) => {
  const [open, setOpen] = useState(false)
  const [sortStatus, setSortStatus] = useState(applications.filterState.status)
  const [selectedEntity, setSelectedEntity] = useState("")
  const dispatch = useDispatch()
  const [searchInput, setSearchInput] = useState("")
  const { profile } = authentication
  const { mfi, roles } = profile
  const { mfiName } = mfi || {}
  const { repaymentSchedule, error } = applications

  const comments = useSelector((state) => state.comments)
  const showPrint =
    !isEmpty(data) &&
    data?.every((item) => item.subRoleStageFinal) &&
    (applications.filterState.group.length > 0 || data.length === 1) &&
    mfiName === "AMZ" &&
    roles.includes("MFI_SUPERVISOR")

  useEffect(() => {
    if (showPrint) {
      const applicationIds = data.map((item) => item.id)
      if (applicationIds.length > 0)
        dispatch(applicationActions.getRepaymentSchedule(applicationIds))
    }
  }, [
    applications.groupFilter,
    applications.userFilter,
    !isEmpty(data) &&
      data?.every((item) => item.subRoleStageFinal) &&
      repaymentSchedule.length === 0 &&
      !error,
  ])

  // Generate footerInfo with base64 images
  const generatecommitteeInfo = async () => {
    const committeeInfoPromises = comments.map(async (comment) => [
      comment.name,
      "Approved",
      await getBase64ImageFromURL(comment.signatureCloudUrl),
      new Date(comment.timestamp).toLocaleString(),
    ])
    return Promise.all(committeeInfoPromises)
  }

  /* Download committee decision for a group
   ******************
   ******************
   ******************
   ******************
   ******************
   ******************
   */
  const vbName = data?.length > 0 ? data[0]["VB_NAME"] : ""
  const groupName = data?.length > 0 ? data[0]["Group"] : ""
  const branch = data?.length > 0 ? data[0]["Branch"] : ""
  const submittedBy = data?.length > 0 ? data[0]["_submittedBy"] : ""
  const submissionDate = data?.length > 0 ? data[0]["_submissionDate"] : ""

  const generateCommitteePDF = async () => {
    const doc = new jsPDF()

    // Set font size for custom content
    doc.setFontSize(12)

    const committeeInfo = await generatecommitteeInfo()

    committeeInfo.forEach((info) => (info[2] = info[2].split(",")[1]))

    const formHeader = [["AMZ CREDIT COMMITTEE DECISION"]]

    doc.autoTable({
      startY: 20,
      theme: "grid",
      headStyles: { fillColor: [211, 211, 211] },
      body: formHeader,
      styles: { halign: "center" },
      didParseCell: (data) => {
        if (data.row.index === 0) {
          data.cell.styles.fontStyle = "bold"
          data.cell.styles.fillColor = [211, 211, 211]
        }
      },
    })

    const docFontSize = 6
    doc.setFontSize(docFontSize)

    const loanTenor = data[0]["Loan Tenor"]

    const branchDetails = [
      [
        "Branch",
        branch,
        "Date of Credit Committee",
        committeeInfo.length > 0 && committeeInfo[committeeInfo.length - 1][3],
      ],
      ["Group Name", groupName, "Loan Duration", loanTenor],
    ]

    doc.autoTable({
      startY: doc.autoTable.previous.finalY,
      body: branchDetails,
      styles: { fontSize: 10, cellPadding: 5 },
      tableLineWidth: 0.1, // Border thickness
      theme: "plain",
      didParseCell: (data) => {
        if (data.column.index === 0 || data.column.index === 2) {
          data.cell.styles.fontStyle = "bold"
        }
        // Removing inner borders
        data.cell.styles.lineWidth = 0
        // Setting the outer border
        if (data.row.index === 0) {
          data.cell.styles.lineTopWidth = 0.1
        }
        if (data.row.index === branchDetails.length - 1) {
          data.cell.styles.lineBottomWidth = 0.1
        }
        if (data.column.index === 0) {
          data.cell.styles.lineLeftWidth = 0.1
        }
        if (data.column.index === branchDetails[0].length - 1) {
          data.cell.styles.lineRightWidth = 0.1
        }
      },
    })

    const loanDetailsBody = data.map((detail, index) => {
      const clientLoanData = []
      clientLoanData.push(index + 1)
      clientLoanData.push(detail["Applicant Name"])
      clientLoanData.push("") // to do => previous loan amount
      clientLoanData.push("") // to do => previous loan product
      clientLoanData.push(detail["Cash Received"])
      clientLoanData.push(detail["Grossed Up"])
      clientLoanData.push(detail["Arrangement Fees"])
      clientLoanData.push(detail["Principal Amount"])
      clientLoanData.push(detail["Cash Received"])
      clientLoanData.push(detail["Loan Tenor"])
      return clientLoanData
    })

    const emptyRow = ["", "", "", "", "", "", "", "", "", ""]
    loanDetailsBody.push(emptyRow)

    const loanApprovedTotal = data.reduce(
      (acc, detail) => acc + Number(detail["Cash Received"]),
      0
    )
    const arrangementFeeTotal = data.reduce(
      (acc, detail) => acc + Number(detail["Arrangement Fees"]),
      0
    )
    const principalTotal = data.reduce(
      (acc, detail) => acc + Number(detail["Principal Amount"]),
      0
    )

    const totalRow = [
      "",
      "Total",
      "",
      "",
      loanApprovedTotal,
      "",
      arrangementFeeTotal,
      principalTotal,
      loanApprovedTotal,
      "",
    ]
    loanDetailsBody.push(totalRow)
    loanDetailsBody.push(emptyRow)

    const loanDetailsHeader = [
      ["Number", "Client Name", "Previous", "Current Loan Cycle"],
      [
        "",
        "",
        "Previous Loan Amount",
        "Previous Loan Type",
        "Current Loan Approved",
        "Grossed Up or Not",
        "Arrangement Fee Paid",
        "Loan Amount After Grossing UP",
        "Actual Cash To Be Received by Client",
        "Loan Term",
      ],
      [`${vbName ? vbName : branch}: ${groupName ? groupName : ""}`],
      ...loanDetailsBody,
    ]

    const processLoanDetailsHeader = () => {
      return loanDetailsHeader.map((row, rowIndex) => {
        return row.map((cell, colIndex) => {
          if (rowIndex === 0 && colIndex === 2) {
            return {
              content: cell,
              colSpan: 2,
              styles: { halign: "center" },
            }
          } else if (rowIndex === 0 && colIndex === 3) {
            return {
              content: cell,
              colSpan: 6,
              styles: { halign: "center" },
            }
          } else if (rowIndex === 2) {
            return {
              content: cell,
              colSpan: 10,
              styles: { halign: "left" },
            }
          } else {
            return { content: cell, colSpan: 1, styles: { halign: "center" } }
          }
        })
      })
    }

    doc.autoTable({
      startY: doc.autoTable.previous.finalY,
      theme: "grid",
      headStyles: { fillColor: [211, 211, 211] },
      body: processLoanDetailsHeader(),
      styles: { fontSize: docFontSize },
      didParseCell: (data) => {
        if (data.row.index === 0 || data.row.index === 1) {
          data.cell.styles.fontStyle = "bold"
          data.cell.styles.fillColor = [211, 211, 211]
        }
        if (data.row.index === loanDetailsHeader.length - 2) {
          data.cell.styles.fontStyle = "bold"
          data.cell.styles.fillColor = [211, 211, 211]
        }
        if (
          data.row.index === loanDetailsHeader.length - 2 &&
          data.column.index === 1
        ) {
          data.cell.styles.halign = "right"
        }
      },
    })

    const presentedBy = [
      ["Presented to credit committee by:"],
      [
        "Client Officer Name:",
        submittedBy,
        "Signature:",
        "",
        "Date:",
        submissionDate,
      ],
    ]

    const processPresentedBy = () => {
      return presentedBy.map((row, rowIndex) => {
        return row.map((cell, colIndex) => {
          if (rowIndex === 0) {
            return {
              content: cell,
              colSpan: 6,
              styles: { halign: "left" },
            }
          } else {
            return { content: cell, colSpan: 1, styles: { halign: "center" } }
          }
        })
      })
    }

    doc.autoTable({
      startY: doc.autoTable.previous.finalY,
      theme: "grid",
      headStyles: { fillColor: [211, 211, 211] },
      body: processPresentedBy(),
      styles: { fontSize: docFontSize },
      columnStyles: {
        3: { cellWidth: 50 },
      },
      didParseCell: (data) => {
        if (data.row.index === 0) {
          data.cell.styles.fontStyle = "bold"
        }
      },
    })

    const committeeApproval = [
      ["Credit Committee Approval"],
      ["Name of Member", "Decision made", "Signature", "Date"],
      ...committeeInfo,
      [""],
    ]

    const processCommitteeApproval = () => {
      return committeeApproval.map((row, rowIndex) => {
        return row.map((cell, colIndex) => {
          if (rowIndex === 0) {
            return {
              content: cell,
              colSpan: 4,
              styles: { halign: "center" },
            }
          } else if (rowIndex === committeeApproval.length - 1) {
            return {
              content: cell,
              colSpan: 4,
              styles: { fillColor: [211, 211, 211] },
            }
          } else {
            return { content: cell, colSpan: 1, styles: { halign: "center" } }
          }
        })
      })
    }

    doc.autoTable({
      startY: doc.autoTable.previous.finalY,
      theme: "grid",
      headStyles: { fillColor: [211, 211, 211] },
      body: processCommitteeApproval(),
      styles: { fontSize: docFontSize },
      columnStyles: {
        3: { cellWidth: 50 },
      },
      didParseCell: (data) => {
        if (data.row.index === 0) {
          data.cell.styles.fontStyle = "bold"
          data.cell.styles.fillColor = [211, 211, 211]
        }
        if (data.column.index === 2 && data.row.index !== 1) {
          // Add image for specific cell
          data.cell.text = ""
          data.cell.styles.cellWidth = 50 // Adjust as necessary
          data.cell.styles.cellHeight = 20 // Adjust as necessary
        }
      },
      didDrawCell: (data) => {
        if (data.column.index === 2 && ![0, 1].includes(data.row.index)) {
          const imgData = data.cell.raw.content
          doc.addImage(
            imgData,
            "PNG",
            data.cell.x + 1,
            data.cell.y + 1,
            data.cell.width - 2,
            data.cell.height - 2
          )
        }
      },
    })

    // Save the PDF
    doc.save("Printable Committee.pdf")
  }

  /* End download committee decision form
   **********************
   **********************
   **********************
   **********************
   **********************
   **********************
   */

  /* Group disbursement form data
   ***********************
   ***********************
   ***********************
   ***********************
   ***********************
   ***********************
   * This data will be used to print the disbursement form
   */

  const generateDisbursementPDF = async () => {
    const doc = new jsPDF()

    // Set font size for document header
    doc.setFontSize(12)

    const committeeInfo = await generatecommitteeInfo()

    committeeInfo.forEach((info) => (info[2] = info[2].split(",")[1]))

    const formHeader = [["DISBURSEMENT FORM"]]

    doc.autoTable({
      startY: 20,
      theme: "grid",
      headStyles: { fillColor: [211, 211, 211] },
      body: formHeader,
      styles: { halign: "center" },
      didParseCell: (data) => {
        if (data.row.index === 0) {
          data.cell.styles.fontStyle = "bold"
          data.cell.styles.fillColor = [211, 211, 211]
        }
      },
    })

    const docFontSize = 6
    doc.setFontSize(docFontSize)

    const subHeader = [
      [
        `Branch ${branch}`,
        `VB Name ${vbName}`,
        `Group ${groupName}`,
        "First Collection Date 07/June/2024",
        "Last Collection Date 07/November/2024",
      ],
    ]

    doc.autoTable({
      startY: doc.autoTable.previous.finalY,
      theme: "grid",
      headStyles: { fillColor: [211, 211, 211] },
      body: subHeader,
      styles: { fontSize: docFontSize, halign: "center" },
      didParseCell: (data) => {
        if (data.row.index === 0) {
          data.cell.styles.fontStyle = "bold"
        }
        if (data.column.index === 0) {
          data.cell.text = ["Branch", branch ? branch : ""]
        }
        if (data.column.index === 1) {
          data.cell.text = ["VB Name", vbName ? vbName : ""]
        }
        if (data.column.index === 2) {
          data.cell.text = ["Group", groupName ? groupName : ""]
        }
        if (data.column.index === 3) {
          data.cell.text = [
            "First Collection Date",
            !isEmpty(repaymentSchedule)
              ? repaymentSchedule[0].repaymentSchedule[1].dueDate
              : "",
          ]
        }
        if (data.column.index === 4) {
          data.cell.text = [
            "Last Collection Date",
            !isEmpty(repaymentSchedule)
              ? repaymentSchedule[0].repaymentSchedule[
                  repaymentSchedule[0].repaymentSchedule.length - 1
                ].dueDate
              : "",
          ]
        }
      },
    })

    const disbursementData = data.map((detail) => {
      const clientData = []
      clientData.push(detail["Applicant Name"])
      clientData.push(detail["NRC No"])
      clientData.push(detail["Gender"])
      clientData.push(detail["Loan Product"])
      clientData.push(detail["Loan Tenor"])
      clientData.push("16%")
      clientData.push(detail["Cash Received"])
      clientData.push(
        detail["Grossed Up"] === "Yes" ? 0 : detail["Arrangement Fees"]
      )
      clientData.push(
        detail["Grossed Up"] === "Yes" ? detail["Arrangement Fees"] : 0
      )
      clientData.push(detail["Cash Received"])
      clientData.push(detail["Principal Amount"]) // reminder to include credit life insuarance.
      clientData.push("")
      return clientData
    })
    const emptyRow = ["", "", "", "", "", "", "", "", "", "", "", ""]
    disbursementData.push(emptyRow)

    const feePaidUpfront = data.reduce((acc, detail) => {
      const current =
        detail["Grossed Up"] === "Yes" ? 0 : detail["Arrangement Fees"]
      return acc + Number(current)
    }, 0)

    const feeAdvanced = data.reduce((acc, detail) => {
      const current =
        detail["Grossed Up"] === "Yes" ? detail["Arrangement Fees"] : 0
      return acc + Number(current)
    }, 0)

    const totalAmountReceived = data.reduce(
      (acc, detail) => acc + Number(detail["Cash Received"]),
      0
    )

    const totalLoan = data.reduce(
      (acc, detail) => acc + Number(detail["Principal Amount"]),
      0
    )

    const totalRow = [
      "",
      "TOTAL",
      "",
      feePaidUpfront,
      feeAdvanced,
      totalAmountReceived,
      totalLoan,
      "",
    ]
    disbursementData.push(totalRow)
    const emptySpannedRow = [""]
    disbursementData.push(emptySpannedRow)
    disbursementData.push(emptySpannedRow)

    const disbursementDetails = [
      [
        "Client Name",
        "Client NRC",
        "Gender",
        "Loan Type",
        "Loan Term (months)",
        "Arrangement Fees + CLA (%)",
        "Loan Amount (before grossing up)",
        "Arrangement Fee Amount Paid by Client",
        "Arrangement Fee Amount Advanced to Client",
        "Actual Cash Recived by Client",
        "Total Loan Obtained by Client",
        "Client Signature (in agreement)",
      ],
      ...disbursementData,
    ]

    const processDisbursementDetails = () => {
      return disbursementDetails.map((row, rowIndex) => {
        return row.map((cell, colIndex) => {
          if (rowIndex === disbursementDetails.length - 3 && colIndex === 0) {
            return {
              content: cell,
              colSpan: 5,
            }
          } else if (rowIndex === disbursementDetails.length - 2) {
            return {
              content: cell,
              colSpan: 12,
            }
          } else if (rowIndex === disbursementDetails.length - 1) {
            return {
              content: cell,
              colSpan: 12,
              styles: { fillColor: [211, 211, 211] },
            }
          } else {
            return { content: cell, colSpan: 1, styles: { halign: "left" } }
          }
        })
      })
    }

    doc.autoTable({
      startY: doc.autoTable.previous.finalY,
      theme: "grid",
      headStyles: { fillColor: [211, 211, 211] },
      body: processDisbursementDetails(),
      styles: { fontSize: docFontSize, halign: "center" },
      didParseCell: (data) => {
        if (data.row.index === 0) {
          data.cell.styles.fontStyle = "bold"
        }
        if ([7, 8, 9].includes(data.column.index)) {
          data.cell.styles.fillColor = [211, 211, 211]
        }
      },
    })

    // footer information
    const formFooterInfo = [
      [submittedBy, committeeInfo[0][0], "", ""],
      [
        "Client Officer Name",
        "Branch/Area Manager",
        "VB President Name",
        "Cashier's Name",
      ],
      ["", committeeInfo[0][2], "", ""],
      [
        "CO Signature",
        "Branch/Area Manager Signature",
        "VB President Signature",
        "Cashier's Signature",
      ],
    ]

    doc.autoTable({
      startY: doc.autoTable.previous.finalY,
      body: formFooterInfo,
      styles: { fontSize: 10, cellPadding: 5 },
      tableLineWidth: 0.1, // Border thickness
      theme: "plain",
      didParseCell: (data) => {
        if (data.row.index === 1 || data.row.index === 3) {
          data.cell.styles.fontStyle = "bold"
        }
        // Removing inner borders
        data.cell.styles.lineWidth = 0
        // Setting the outer border
        if (data.row.index === 0) {
          data.cell.styles.lineTopWidth = 0.1
        }
        if (data.row.index === formFooterInfo.length - 1) {
          data.cell.styles.lineBottomWidth = 0.1
        }
        if (data.column.index === 0) {
          data.cell.styles.lineLeftWidth = 0.1
        }
        if (data.column.index === formFooterInfo[0].length - 1) {
          data.cell.styles.lineRightWidth = 0.1
        }
        if (data.row.index === 2 && data.column.index === 1) {
          // Add image for specific cell
          data.cell.text = ""
          data.cell.styles.cellWidth = 50 // Adjust as necessary
          data.cell.styles.cellHeight = 20 // Adjust as necessary
        }
      },
      didDrawCell: (data) => {
        if (data.row.index === 2 && data.column.index === 1) {
          const imgData = committeeInfo[0][2] // Base64 image data
          doc.addImage(
            imgData,
            "PNG",
            data.cell.x + 1,
            data.cell.y + 1,
            data.cell.width - 2,
            data.cell.height - 2
          )
        }
      },
    })

    // Save the PDF
    doc.save("Loan Disbursement Form.pdf")
  }

  /* End group disbursement form data
   *****************
   *****************
   *****************
   *****************
   *****************
   *****************
   *****************
   */

  /* Key Fact Statement Form Data
   ******************
   ******************
   ******************
   ******************
   ******************
   ******************
   * This data will be used to print the Client Key Fact Statement
   */

  const generateKeyFactStatement = async () => {
    const doc = new jsPDF()

    data.map((detail, index) => {
      doc.setFontSize(12)
      doc.text(
        "CLIENT KEY FACTS STATEMENT AND REPAYMENT SCHEDULE FOR CONSUMER CREDIT",
        10,
        10
      )

      const docFontSize = 8
      doc.setFontSize(docFontSize)

      // Add disclaimer text
      const disclaimer = [
        [
          "Client should carefully review before agreeing to a loan. You have the right to get a copy of the full loan agreement.",
        ],
      ]

      doc.autoTable({
        startY: 20,
        theme: "grid",
        headStyles: { fillColor: [211, 211, 211] },
        body: disclaimer,
        styles: { fontSize: docFontSize },
      })

      const clientDetailsHeader = [["CLIENT DETAILS"]]

      doc.autoTable({
        startY: doc.autoTable.previous.finalY,
        theme: "grid",
        headStyles: { fillColor: [211, 211, 211] },
        body: clientDetailsHeader,
        styles: { fontSize: docFontSize, fillColor: [211, 211, 211] },
        didParseCell: (data) => {
          data.cell.styles.fontStyle = "bold"
        },
      })

      const clientDetails = [
        ["VB Name", detail["VB_NAME"]],
        ["Client Name", detail["Applicant Name"]],
        ["Product Type", detail["Loan Product"]],
        ["NRC Number", detail["NRC No"]],
        ["Branch", detail["Branch"]],
        ["Loan Officer", detail["_submittedBy"]],
        [
          "Client ID",
          !isEmpty(repaymentSchedule) ? repaymentSchedule[index].clientId : "",
        ],
        [
          "Loan ID",
          !isEmpty(repaymentSchedule) ? repaymentSchedule[index].loanId : "",
        ],
      ]

      doc.autoTable({
        startY: doc.autoTable.previous.finalY,
        theme: "grid",
        headStyles: { fillColor: [211, 211, 211] },
        body: clientDetails,
        styles: { fontSize: docFontSize },
        didParseCell: (data) => {
          if (data.column.index === 0) {
            data.cell.styles.fontStyle = "bold"
          }
        },
      })

      const keyTermsHeader = [["KEY TERMS"]]

      doc.autoTable({
        startY: doc.autoTable.previous.finalY,
        theme: "grid",
        headStyles: { fillColor: [211, 211, 211] },
        body: keyTermsHeader,
        styles: { fontSize: docFontSize },
        didParseCell: (data) => {
          data.cell.styles.fontStyle = "bold"
        },
      })

      const A = Number(detail["Principal Amount"]) // Principal amount
      const B = 307 // to do get the actual interest paid
      const C =
        detail["Grossed Up"] === "Yes" ? Number(detail["Arrangement Fees"]) : 0 // Arrangement fees
      const D = Number(detail["Cash Received"]) // Actual amount received
      const E = Number(detail["Life Insurance"]) //Credit Life Insurance (ZMW)
      const F = Number(detail["Cash Received"]) // net loan
      const costOfCredit = B + C + E
      const totalAmoutDue = F + costOfCredit

      const keyTerms = [
        ["Loan Summary", "", "Cost of Credit", "", "Repayment Schedule"],
        [
          "(A) Total Loan Amount",
          A,
          "",
          "(B) Total Interest to be paid",
          B,
          "",
          "Disbursement Date",
          !isEmpty(repaymentSchedule)
            ? repaymentSchedule[0].repaymentSchedule[0].dueDate
            : "",
        ],
        [
          "Duration of loan (Months)",
          detail["Loan Tenor"],
          "",
          "(C) Arrangement fees (ZMW)",
          C,
          "",
          "Loan Maturity Date",
          !isEmpty(repaymentSchedule)
            ? repaymentSchedule[0].repaymentSchedule[
                repaymentSchedule[0].repaymentSchedule.length - 1
              ].dueDate
            : "",
        ],
        [
          "(D)Net amount received after fees",
          D,
          "",
          "(E) Credit Life Insurance (ZMW)",
          E,
          "",
          "First Instalment Payment Date",
          !isEmpty(repaymentSchedule)
            ? repaymentSchedule[0].repaymentSchedule[1].dueDate
            : "",
        ],
        [
          "Payment frequency",
          "Monthly",
          "",
          "Amount per payment (P+I)",
          "See Repayment Schedule (pg 2)",
          "",
          "Number of payments",
          detail["Loan Tenor"],
        ],
        [
          "Fees Grossed Up? (Yes/No)",
          detail["Grossed Up"],
          "",
          "Annual Interest rate (declining balance)",
          "",
          "",
          "Annual Percentage Rate (APR) %",
          0,
        ],
        [
          "Net loan (F)",
          F,
          "",
          `Total cost of credit
          (B+C+E)`,
          costOfCredit,
          "",
          `Total amount DUE
          (F+B+C+E)`,
          totalAmoutDue,
        ],
      ]

      const processKeyTerms = () => {
        return keyTerms.map((row, rowIndex) => {
          return row.map((cell, colIndex) => {
            if (
              rowIndex === 0 &&
              (colIndex === 0 || colIndex === 2 || colIndex === 4)
            ) {
              return {
                content: cell,
                colSpan: 2,
                styles: { halign: "center", fillColor: [211, 211, 211] },
              }
            } else if (cell === "") {
              return { content: cell, colSpan: 1 }
            } else {
              return { content: cell, colSpan: 1, styles: { halign: "left" } }
            }
          })
        })
      }

      doc.autoTable({
        startY: doc.autoTable.previous.finalY,
        theme: "grid",
        headStyles: { fillColor: [211, 211, 211] },
        body: processKeyTerms(),
        styles: { fontSize: docFontSize },
        didParseCell: (data) => {
          if (data.row.index === 0) {
            data.cell.styles.fontStyle = "bold"
            if (
              data.column.index === 0 ||
              data.column.index === 2 ||
              data.column.index === 4
            )
              data.cell.styles.colSpan = 2
          } else if ([0, 3, 6].includes(data.column.index)) {
            data.cell.styles.fontStyle = "bold"
          } else if (data.row.index === 5 && data.column.index === 1) {
            data.cell.styles.fontStyle = "bold"
          }
        },
      })

      const riskDecl = [
        ["RISK TO YOU"],
        [
          "1. Late or missing payments may be reported to a credit reference bureau and may severely affect your financial situation, collateral, and ability to re-borrow.",
        ],
        [
          "2. Your interest rate will change based on changes in the Bank of Zambia's Policy Rate. This Change will affect the duration of your loan and your repayment. NOT APPLICABLE TO AMZ CLIENTS",
        ],
      ]

      doc.autoTable({
        startY: doc.autoTable.previous.finalY,
        theme: "grid",
        headStyles: { fillColor: [211, 211, 211] },
        body: riskDecl,
        styles: { fontSize: docFontSize },
        didParseCell: (data) => {
          if (data.row.index === 0) {
            data.cell.styles.fontStyle = "bold"
            data.cell.styles.fillColor = [211, 211, 211]
          }
        },
      })

      const rightsDecl = [
        ["YOUR RIGHTS AND OBLIGATIONS"],
        [
          "Any questions or complaints? Call Head office 0974177649",
          "Or Branch Manager on",
          "",
        ],
        [
          "Or write to AMZ Post net 745, Manda Hill, Lusaka, ZAMBIA Email: complaints@agoramicrofinance.co.zm",
        ],
        ["Unsatisfied with our response to your question or complaint?"],
        [
          "Contact the Bank of Zambia for help at +260 211 399300/0971-270090/0963-884820",
        ],
        [
          "You are required to make payments on your loan according to your loan agreement and to notify us of any important changes in your situation.",
        ],
      ]

      const processRightsDecl = () => {
        return rightsDecl.map((row, rowIndex) => {
          return row.map((cell, colIndex) => {
            if (rowIndex === 0) {
              return {
                content: cell,
                colSpan: 3,
                styles: { halign: "left", fillColor: [211, 211, 211] },
              }
            } else if (rowIndex !== 1) {
              return {
                content: cell,
                colSpan: 3,
              }
            } else {
              return {
                content: cell,
                colSpan: 1,
                styles: { halign: "left", width: 50 },
              }
            }
          })
        })
      }

      doc.autoTable({
        startY: doc.autoTable.previous.finalY,
        theme: "grid",
        headStyles: { fillColor: [211, 211, 211] },
        body: processRightsDecl(),
        styles: { fontSize: docFontSize },
        columnStyles: {
          2: { cellWidth: 50 },
        },
        didParseCell: (data) => {
          if ([0, 3, 5].includes(data.row.index)) {
            data.cell.styles.fontStyle = "bold"
          }
        },
      })

      const feeDecl = [
        ["UPFRONT AND RECURRING FEES"],
        [
          "Arrangement Fees (%)",
          "15",
          "Amount",
          detail["Arrangement Fees"],
          "Credit life insurance (%)",
          "1.00",
          "Amount",
          E,
        ],
        [
          "Total upfront and recurring fees and charges (excluding interest) ZMW",
        ],
      ]

      const processFeeDecl = () => {
        return feeDecl.map((row, rowIndex) => {
          return row.map((cell, colIndex) => {
            if (![0, 1].includes(rowIndex)) {
              return {
                content: cell,
                colSpan: 8,
              }
            } else if (rowIndex === 0) {
              return {
                content: cell,
                colSpan: 8,
                styles: { halign: "left", fillColor: [211, 211, 211] },
              }
            } else {
              return { content: cell, colSpan: 1, styles: { halign: "center" } }
            }
          })
        })
      }

      doc.autoTable({
        startY: doc.autoTable.previous.finalY,
        theme: "grid",
        headStyles: { fillColor: [211, 211, 211] },
        body: processFeeDecl(),
        styles: { fontSize: docFontSize },
        didParseCell: (data) => {
          if (data.row.index === 0) {
            data.cell.styles.fontStyle = "bold"
          }
        },
      })

      const importantTerms = [
        ["IMPORTANT TERMS AND CONDITIONS TO CONSIDER"],
        ["Late payment penalties"],
        ["1. Late fees", "", "NON"],
        [
          "2. AMZ will continue calculating interest on outstanding loan balance.",
          "",
          "YES",
        ],
      ]

      const processImportantTerms = () => {
        return importantTerms.map((row, rowIndex) => {
          return row.map((cell, colIndex) => {
            if (rowIndex === 1) {
              return {
                content: cell,
                colSpan: 3,
                styles: { halign: "left" },
              }
            } else if (rowIndex === 0) {
              return {
                content: cell,
                colSpan: 3,
                styles: { halign: "left", fillColor: [211, 211, 211] },
              }
            } else {
              return { content: cell, colSpan: 1, styles: { halign: "left" } }
            }
          })
        })
      }

      doc.autoTable({
        startY: doc.autoTable.previous.finalY,
        theme: "grid",
        headStyles: { fillColor: [211, 211, 211] },
        body: processImportantTerms(),
        styles: { fontSize: docFontSize },
        columnStyles: {
          1: { cellWidth: 50 },
        },
        didParseCell: (data) => {
          if ([0, 1].includes(data.row.index)) {
            data.cell.styles.fontStyle = "bold"
          }
        },
      })

      doc.addPage()

      const termsAndConditions = [
        ["Terms and conditions"],
        ["1. Cash deposit/mandatory savings", "", "NON"],
        [
          "2. Collateral; You are committing the following as collateral:",
          "",
          "",
        ],
        ["3. Variable interest fare applies", "", "NON"],
      ]

      const processTermsAndConditions = () => {
        return termsAndConditions.map((row, rowIndex) => {
          return row.map((cell, colIndex) => {
            if (rowIndex === 0) {
              return {
                content: cell,
                colSpan: 3,
                styles: { halign: "left", fillColor: [211, 211, 211] },
              }
            } else {
              return { content: cell, colSpan: 1, styles: { halign: "left" } }
            }
          })
        })
      }

      doc.autoTable({
        startY: 20,
        theme: "grid",
        headStyles: { fillColor: [211, 211, 211] },
        body: processTermsAndConditions(),
        styles: { fontSize: docFontSize },
        columnStyles: {
          1: { cellWidth: 50 },
        },
        didParseCell: (data) => {
          if (data.row.index === 0) {
            data.cell.styles.fontStyle = "bold"
          }
        },
      })

      const repaymentScheduleData = repaymentSchedule[
        index
      ].repaymentSchedule.map((schedule, scheduleIndex) => {
        const rowData = []
        rowData.push(`${scheduleIndex}`)
        rowData.push(schedule.dueDate)
        rowData.push(schedule.principalDue)
        rowData.push(schedule.interestDue)
        rowData.push(schedule.feesDue)
        rowData.push(schedule.totalDue)
        rowData.push("0")
        rowData.push("0")
        rowData.push(schedule.principalOutstanding)
        rowData.push(schedule.totalOutstanding)
        rowData.push("")
        return rowData
      })

      const emptyScheduleRow = ["", "", "", "", "", "", "", "", "", "", ""]
      repaymentScheduleData.push(emptyScheduleRow)
      const principalDueTotal = repaymentSchedule[
        index
      ].repaymentSchedule.reduce(
        (acc, shedule) => acc + Number(shedule.interestDue),
        0
      )
      const interestDueTotal = repaymentSchedule[
        index
      ].repaymentSchedule.reduce(
        (acc, shedule) => acc + Number(shedule.principalDue),
        0
      )
      const feesDueDueTotal = repaymentSchedule[index].repaymentSchedule.reduce(
        (acc, shedule) => acc + Number(shedule.feesDue),
        0
      )
      const totalDueTotal = repaymentSchedule[index].repaymentSchedule.reduce(
        (acc, shedule) => acc + Number(shedule.totalDue),
        0
      )

      const totalOutstandingTotal = repaymentSchedule[
        index
      ].repaymentSchedule.reduce(
        (acc, shedule) => acc + Number(shedule.totalOutstanding),
        0
      )

      const totalScheduleRow = [
        "Total",
        principalDueTotal,
        interestDueTotal,
        feesDueDueTotal,
        totalDueTotal,
        "0",
        "0",
        "",
        totalOutstandingTotal,
        "",
      ]

      repaymentScheduleData.push(totalScheduleRow)

      const repaymentScheduleInfo = [
        ["REPAYMENT SCHEDULE"],
        [
          "Payment Number",
          "Payment Due Date",
          "Principal Due (A)",
          "Interest Due (B)",
          "Fees (C)",
          "Total due (A + B + C)",
          "Total Paid In Advance",
          "Total Paid",
          "Principle Oustanding",
          "Total Outstanding",
          "CO Signature",
        ],
        ...repaymentScheduleData,
        [
          "NB: I acknowledge receipt of this statement prior to signing the guarantee.",
        ],
      ]

      const processRepaymentSchedule = () => {
        return repaymentScheduleInfo.map((row, rowIndex) => {
          return row.map((cell, colIndex) => {
            if (rowIndex === 0) {
              return {
                content: cell,
                colSpan: 11,
                styles: { halign: "center", fillColor: [211, 211, 211] },
              }
            } else if (rowIndex === repaymentScheduleInfo.length - 1) {
              return {
                content: cell,
                colSpan: 11,
                styles: { halign: "left" },
              }
            } else if (
              rowIndex === repaymentScheduleInfo.length - 2 &&
              colIndex === 0
            ) {
              return {
                content: cell,
                colSpan: 2,
                styles: { halign: "center", fontStyle: "bold" },
              }
            } else {
              return { content: cell, colSpan: 1, styles: { halign: "center" } }
            }
          })
        })
      }

      doc.autoTable({
        startY: doc.autoTable.previous.finalY,
        theme: "grid",
        headStyles: { fillColor: [211, 211, 211] },
        body: processRepaymentSchedule(),
        styles: { fontSize: docFontSize },
        didParseCell: (data) => {
          if (data.row.index === 0 || data.row.index === 1) {
            data.cell.styles.fontStyle = "bold"
          }
          if (data.row.index === 1 && data.column.index === 5) {
            data.cell.text = ["Total due", "(A + B + C)"]
          }
          if (data.row.index === 1 && data.column.index === 4) {
            data.cell.text = ["Fees", "(C)"]
          }
          if (data.row.index === 1 && data.column.index === 3) {
            data.cell.text = ["Interest Due", "(B)"]
          }
          if (data.row.index === 1 && data.column.index === 2) {
            data.cell.text = ["Principal Due", "(A)"]
          }
        },
      })

      const signOffs = [
        ["Certified correct (AMZ Branch Manager)"],
        ["Name:", "", "Signatue:", ""],
        [
          `BORROWER "I acknowledge receipt of this statement prior to signing the loan agreement. I further agree and confirm that Agora Microfinance Zambia may source, share and disclose any
information relating to my business and credit situation (past and present) to any third party (including credit reference bureaus and any other institutions or persons permitted by law, e.g. debt
collectors, lenders etc.) for the purpose of evaluating my credit worthiness or for any other lawful purpose."`,
        ],
        ["Borrower Name:", "", "Signature", "", "Date", ""],
      ]

      const processSignOffs = () => {
        return signOffs.map((row, rowIndex) => {
          return row.map((cell, colIndex) => {
            if (rowIndex === 0 || rowIndex === 2) {
              return {
                content: cell,
                colSpan: 6,
                styles: { halign: "left" },
              }
            } else if (rowIndex === 1 && colIndex === 3) {
              return {
                content: cell,
                colSpan: 3,
                styles: { halign: "left" },
              }
            } else {
              return { content: cell, colSpan: 1, styles: { halign: "center" } }
            }
          })
        })
      }

      doc.autoTable({
        startY: doc.autoTable.previous.finalY,
        theme: "grid",
        headStyles: { fillColor: [211, 211, 211] },
        body: processSignOffs(),
        styles: { fontSize: docFontSize },
        columnStyles: {
          1: { cellWidth: 30 },
          3: { cellWidth: 30 },
          5: { cellWidth: 30 },
        },
        didParseCell: (data) => {
          if (data.row.index === 0 || data.row.index === 2) {
            data.cell.styles.fontStyle = "bold"
          }
        },
      })

      const gurantorInfo = [
        ["Guarantor (if applicable)"],
        [
          "I acknowledge receipt of this statement prior to signing the guarantee (signature):",
          "",
        ],
        [
          "-> AMZ does not work with any agents in its operations. Our operations are done only by AMZ staff.",
        ],
        [
          "-> No person should pay any money to join a group or register to be an AMZ client. AMZ does not request for any amount in order to have access to our loans. Report any person requesting you to pay to the Police or contact AMZ on 0777406945.",
        ],
        ["-> An AMZ staff will always have an ID for identification."],
        ["-> Branch Manager phone number:"],
        [
          "-> Check your loan details and other AMZ products by dialing *808# for free",
        ],
      ]

      const processGurantorInfo = () => {
        return gurantorInfo.map((row, rowIndex) => {
          return row.map((cell, colIndex) => {
            if (rowIndex !== 1) {
              return {
                content: cell,
                colSpan: 2,
                styles: { halign: "left" },
              }
            } else {
              return { content: cell, colSpan: 1, styles: { halign: "left" } }
            }
          })
        })
      }

      doc.autoTable({
        startY: doc.autoTable.previous.finalY,
        theme: "grid",
        headStyles: { fillColor: [211, 211, 211] },
        body: processGurantorInfo(),
        styles: { fontSize: docFontSize },
        columnStyles: {
          1: { cellWidth: 50 },
        },
        didParseCell: (data) => {
          if (data.row.index === 0) {
            data.cell.styles.fontStyle = "bold"
          }
        },
      })

      // add page for each client
      if (index < data.length - 1) doc.addPage()
    })

    // Save the PDF
    doc.save("Client Key Fact Statement.pdf")
  }

  /* End Key Fact Statement Form Data
   *****************
   *****************
   *****************
   *****************
   *****************
   *****************
   *****************
   */

  const { hierarchyEntityIdInput, hierarchy } = useSelector(
    (store) => store.reports
  )

  useEffect(() => {
    if (
      (applications.filterState.group.length > 0 || data?.length === 1) &&
      !isEmpty(data)
    ) {
      dispatch(commentActions.getAll(data[0].id))
    }
  }, [applications.filterState.group, data])

  const sortStatusFn = useCallback(() => {
    sortStatus &&
      dispatch(
        applicationActions.filterState({
          ...applications.filterState,
          status: sortStatus,
        })
      )
  }, [sortStatus])

  useEffect(() => {
    sortStatusFn()
  }, [sortStatusFn])

  useEffect(() => {
    // populate entities/offices to be displayed
    if (hierarchy && hierarchyEntityIdInput) {
      hierarchy.map((item, i) => {
        const selectedHierarchyEntity = item.entities.filter(
          (entity, i) => entity.id === Number(hierarchyEntityIdInput)
        )
        if (selectedHierarchyEntity && selectedHierarchyEntity.length > 0) {
          setSelectedEntity(selectedHierarchyEntity[0].name)
        }
        return item
      })
    } else {
      setSelectedEntity("")
    }
  }, [hierarchy, hierarchyEntityIdInput])

  const userFilter = () => dispatch(modalActions.userFilter())

  const handleChange = (e) => setSortStatus(e.target.value)
  const currentPage = window.location.pathname.split("/").pop()

  const handleSearchInput = (e) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchInput(searchTerm)

    const filtered = data.filter((item) => {
      // Flatten item values to a single string
      const allValues = Object.values(item).reduce((acc, value) => {
        return acc + " " + (value ? value.toString().toLowerCase() : "")
      }, "")

      return allValues.includes(searchTerm)
    })

    setFilteredData(filtered)
  }

  useEffect(() => {
    // Clear search input when a new questionnaire is selected
    if (selectedValue) {
      setSearchInput("")
    }
  }, [selectedValue, setSearchInput])

  const handleSubmitSearch = () => {
    dispatch(applicationActions.searchApplications(selectedValue, searchInput))
  }

  const handleKeyPressOnSubmit = (e) => {
    if (e.keyCode === 13) {
      dispatch(
        applicationActions.searchApplications(selectedValue, searchInput)
      )
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          paddingRight: "10px",
        }}
      >
        <input
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
          style={{
            fontFamily: "inherit",
            fontSize: "inherit",
            marginLeft: 10,
            paddingTop: "2px",
            padding: "4px 12px",
            borderRadius: "4px 0 0 4px",
            border: "1px solid #185597",
            outline: "none",
          }}
          className="inputSearch"
          onChange={handleSearchInput}
          onKeyDown={handleKeyPressOnSubmit}
          value={searchInput}
          autoFocus
        />
        <span
          style={{
            borderRadius: "0 3px 3px 0",
            borderTop: "1px solid",
            borderRight: "1px solid",
            borderBottom: "1px solid",
            marginLeft: "-2px",
            paddingLeft: "4px",
          }}
        >
          <SearchIcon
            style={{
              color: "#a1b6ca",
              marginTop: "4px",
              cursor: "pointer",
            }}
            onClick={handleSubmitSearch}
          />
        </span>
      </div>
      <Form className={`${className} shadow-none bg-transparent m-0`}>
        <FormGroup className="flex items-center mb-0">
          {applications.applicationClicked.page === "ListApplication" &&
            currentPage === "applications" && (
              <>
                {mfiName === "AMZ" && (
                  <>
                    &nbsp;&nbsp;
                    <Button
                      theme="secondary"
                      className="mx-2 d-table ml-auto btn btn-filter"
                      onClick={() =>
                        dispatch(
                          modalActions.officeFilter({ isGroupFilter: true })
                        )
                      }
                    >
                      {t("module.applications.filterByGroup")}
                      {applications.filterState.group &&
                        applications.filterState.group.length > 0 &&
                        `: ${applications.filterState.group.join()}`}
                    </Button>
                  </>
                )}
                &nbsp;&nbsp;
                <Button
                  theme="secondary"
                  className="mx-2 d-table ml-auto btn btn-filter"
                  onClick={() => dispatch(modalActions.officeFilter())}
                >
                  {t("module.report.filterByOffice")}
                  {applications.filterState.offices &&
                    applications.filterState.offices.length > 0 &&
                    `: ${applications.filterState.offices.join()}`}
                </Button>
                <Button
                  theme="secondary"
                  className="mx-2 d-table ml-auto btn btn-user-filter"
                  onClick={() => userFilter()}
                >
                  {t("module.applications.filterByUser")}
                  {applications.filterState.user &&
                    `: ${applications.filterState.user}`}
                </Button>
                <Dropdown
                  open={open}
                  size="sm"
                  toggle={(e) => {
                    setOpen(!open)
                  }}
                  group
                >
                  {/* <DropdownToggle caret className="btn-dropdown-custom">
                    {t("module.applications.selectStatus")}
                    {sortStatus && sortStatus !== "reset" && `: ${sortStatus}`}
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-custom" right>
                    <div className="dropdown-item">
                      <input
                        type="radio"
                        id="review"
                        name="status"
                        onChange={(e) => handleChange(e)}
                        value="review"
                        checked={sortStatus === "review"}
                      />
                      <label htmlFor="review">
                        &nbsp;{t("module.applications.review")}
                      </label>
                      <br />
                      <input
                        type="radio"
                        id="approved"
                        name="status"
                        onChange={(e) => handleChange(e)}
                        value="approved"
                        checked={sortStatus === "approved"}
                      />
                      <label htmlFor="approved">
                        &nbsp;{t("module.applications.approved")}
                      </label>
                      <br />
                      <input
                        type="radio"
                        id="declined"
                        name="status"
                        onChange={(e) => handleChange(e)}
                        value="declined"
                        checked={sortStatus === "declined"}
                      />
                      <label htmlFor="declined">
                        &nbsp;{t("module.applications.declined")}
                      </label>
                      <br />
                      <input
                        type="radio"
                        id="disbursed"
                        name="status"
                        onChange={(e) => handleChange(e)}
                        value="disbursed"
                        checked={sortStatus === "disbursed"}
                      />
                      <label htmlFor="disbursed">
                        &nbsp;{t("module.applications.disbursed")}
                      </label>
                      <br />
                      <input
                        type="radio"
                        id="refused"
                        name="status"
                        onChange={(e) => handleChange(e)}
                        value="refused"
                        checked={sortStatus === "refused"}
                      />
                      <label htmlFor="refused">
                        &nbsp;{t("module.applications.refused")}
                      </label>
                      <br />
                      <hr />
                      <input
                        type="radio"
                        id="reset"
                        name="status"
                        onChange={(e) => handleChange(e)}
                        value="reset"
                        checked={sortStatus === "reset"}
                      />
                      <label htmlFor="reset">
                        &nbsp;{t("module.applications.reset")}
                      </label>
                      <br />
                    </div>
                  </DropdownMenu> */}
                </Dropdown>
                {showPrint && (
                  <PrintSelect
                    className="ml-2"
                    variant="outlined"
                    value="print"
                    disableUnderline
                  >
                    <MenuItem value="print">Print</MenuItem>
                    <MenuItem>
                      <button
                        className="btn btn-primary"
                        onClick={generateCommitteePDF}
                      >
                        {t(
                          "module.applicationManager.component.committeeDecision.button"
                        )}
                      </button>
                    </MenuItem>
                    <MenuItem>
                      <button
                        className="btn btn-primary"
                        onClick={generateDisbursementPDF}
                      >
                        {t(
                          "module.applicationManager.component.disbursementForm.button"
                        )}
                      </button>
                    </MenuItem>
                    <MenuItem>
                      <button
                        className="btn btn-primary"
                        onClick={generateKeyFactStatement}
                      >
                        {t(
                          "module.applicationManager.component.keyFact.button"
                        )}
                      </button>
                    </MenuItem>
                  </PrintSelect>
                )}
              </>
            )}
        </FormGroup>
      </Form>
    </>
  )
}

const mapStateToProps = (state) => {
  const { surveys, applications, authentication } = state
  const { assets, loading } = surveys
  return { assets, applications, loading, authentication }
}

const connectedTableFilters = withTranslation()(
  connect(mapStateToProps)(ApplicationTableFilters)
)
export { connectedTableFilters as ApplicationTableFilters }

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import NotificationItem from "./notificationItem";
import CardTitle from "./cardTitle";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "49%",
    backgroundColor: "white",
    boxShadow: "1px 3px 3px #888888",
    borderRadius: '4px'
  },
  root: {
    width: "100%",
    minWidth: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

const Notifications = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const notifications = useSelector((state) => state.notifications);
  const { notificationsData } = notifications

  return (
    <div className={classes.wrapper}>

        <CardTitle title={`${t('module.client.overview.myNotificationsTitle')}(${notificationsData?.content?.length})`} />
        <PerfectScrollbar
          options={perfectScrollbarOptions}
          // className="navi navi-hover scroll"
          style={{ height: "42vh", position: "relative", padding: '0 20px 0 0' }}
        >
        <List className={classes.root}>
          {notificationsData && notificationsData.content.map((item, i) => (
            <NotificationItem key={item.id} index={i} row={item} />
          ))}
          {(notificationsData && notificationsData.content.length === 0) &&
            <h3 style={{ padding: '0 10px', color: 'lightgrey', textAlign: 'center', paddingTop: '25%' }}>
              {t('module.notifications.noNotification')}
            </h3>
          }
        </List>
      </PerfectScrollbar>

    </div>
  );
};

export default Notifications;

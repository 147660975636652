import axios from "axios"
import qs from "qs"
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode"
import {
  axiosInstance,
  requestDefaults,
  handleResponse,
} from "../../_helpers/base.service"

const serviceGroup = "workflow"

export const authenticationService = {
  login,
  logout,
  getProfile,
  rememberMe,
  resetPassword,
  resetPasswordSms,
  forgotPassword,
  forgotPasswordSMS,
}

function login(username, password) {
  const args = {
    method: "post",
    url: "/oauth/token",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      "Accept-Language": localStorage.getItem("i18nextLng"),
    },
    data: qs.stringify({
      username,
      password,
      grant_type: "password",
    }),
  }

  const requestConfig = {
    ...requestDefaults,
    ...args,
  }
  return axios(requestConfig)
    .then((res) => {
      const { status } = res
      if (status === 200) {
        // Save to localStorage
        // Set token to localStorage
        const { access_token: accessTokenString, refresh_token: refreshToken } =
          res.data

        // Decode token to get user data
        const user = jwt_decode(accessTokenString)

        user.accessTokenString = accessTokenString
        user.refresh_token = refreshToken

        return user
      }
      if (status === 401) {
        // auto logout if 401 response returned from api
        logout()
        window.location.reload(true)
      }
      const error = (res && res.message) || res.statusText
      return Promise.reject(error)
    })
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user))
      return user
    })
}

function logout() {
  // clear all data in the local storage.
  window.localStorage.removeItem("user")
  window.localStorage.removeItem("rememberMe")
  window.localStorage.removeItem("_expiredTime")
  window.location.href = "/login"
  return true
}

/**
 * Get current user details
 */
function getProfile() {
  return axiosInstance.get(`${serviceGroup}/users/me/`).then(handleResponse)
}

/**
 * Reset Password
 */
function resetPassword(data) {
  return axiosInstance
    .post(`${serviceGroup}/users/forgot-password`, data)
    .then(handleResponse)
}

function resetPasswordSms(data) {
  return axiosInstance
    .post(`${serviceGroup}/users/forgot-password-otp`, data)
    .then(handleResponse)
}

function forgotPassword(data) {
  return axiosInstance
    .post(`${serviceGroup}/users/forgot-password-email`, data)
    .then(handleResponse)
}

function forgotPasswordSMS(data) {
  return axiosInstance
    .post(`${serviceGroup}/users/forgot-password-sms`, data)
    .then(handleResponse)
}

function rememberMe(data) {
  localStorage.setItem("rememberMe", data)
}

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    Form,
    FormGroup,
    FormSelect,
    FormCheckbox,
} from 'shards-react';
import { toolsActions } from '../_actions';

export const SetExportFormat = () => {
    const tools = useSelector((state) => state.tools);
    const { exportFormat, includeStatus } = tools;
    const { t } = useTranslation()

    const dispatch = useDispatch();
    const [status, setStatus] = useState(includeStatus);
    const [format, setFormat] = useState(exportFormat);

    useEffect(() => {
        dispatch(toolsActions.exportIncludeStatus(status));
    }, [dispatch, status]);

    useEffect(() => {
        dispatch(toolsActions.exportFileFormat(format));
    }, [dispatch, format]);

    const onSelectChanged = (e) => {
        const { currentTarget } = e;
        const { value: newFormat } = currentTarget;
        setFormat(newFormat);
    };

    const handleStatusChange = () => {
        setStatus(!status);
    };

    return (
        <Form className="select-format shadow-none pr-6 pl-6">
            <FormGroup className="flex items-center">
                <label htmlFor="format" className="pr-10 mb-0">{t('module.tools.export.fileFormat')}</label>
                <FormSelect name="format" size="sm" className="mr-auto w-auto" onChange={onSelectChanged} defaultValue={exportFormat}>
                    <option value="">{t('module.tools.export.select')}</option>
                    <option value="csv">CSV</option>
                    <option value="xlsx">Excel</option>
                </FormSelect>
            </FormGroup>
            <FormGroup className="flex items-center">
                <label htmlFor="format" className="pr-10 mb-0">{t('module.tools.export.status')}</label>
                <FormCheckbox
                    checked={!!status}
                    id="status"
                    onChange={handleStatusChange}
                >
                    {t('module.tools.export.statusDescription')}
                </FormCheckbox>

            </FormGroup>
        </Form>
    );
};

import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Jhxlsx } from "./jhxlsx"
import { FormRadio, ButtonGroup, Button } from "shards-react"
import Svg from "react-inlinesvg"
import Header from "../../../components/widget/widgetTitle"
import { modalActions } from "../../modals/_actions"
import { toolsActions } from "../../tools"
import "./ExportApplications.scss"
const icon = require("../../../assets/images/icons/export-options-icon.svg")

export function ExportApplications({ modalID, data }) {
  const { surveyUuid, exportFormat, includeStatus, surveyName } = data
  const [variables, setVariables] = useState("visible")
  const dispatch = useDispatch()

  const exportApplications = () => {
    if (variables === "visible") {
      // get table data and download it
      const data = JSON.parse(localStorage.getItem("tableData"))
      const tableData = [data[0]]
      Jhxlsx.export(tableData, {
        fileName: surveyName,
      })
    } else {
      dispatch(
        toolsActions.exportApplications({
          surveyUuid,
          exportFormat,
          includeStatus,
          surveyName,
        })
      )
    }
    closeModal()
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  return (
    <div className="site-modal">
      <Header
        title={"Export Options"}
        icon={<Svg className="text-center d-inline-block" src={icon} />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        <div className="items-center">
          <label htmlFor="skipLoanOfficer" className="pr-10 mb-0"></label>
          <FormRadio
            name="skipLoanOfficer"
            checked={variables === "visible"}
            className="mr-auto w-auto"
            onChange={(e) => setVariables("visible")}
          >
            Export data only for the visible variables
          </FormRadio>
        </div>
        <div className="items-center">
          <label htmlFor="assignLoanOfficer" className="pr-10 mb-0"></label>
          <FormRadio
            name="assignLoanOfficer"
            checked={variables === "all"}
            className="mr-auto w-auto"
            onChange={(e) => setVariables("all")}
          >
            Export data only for all the variables in the report columns
          </FormRadio>
        </div>
        <ButtonGroup className="btn-group-actions site-modal__actions flex justify-end">
          <Button
            theme="primary"
            className="delete mr-2"
            onClick={() => {
              exportApplications()
            }}
          >
            Export Data
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

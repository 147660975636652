import React from 'react';
import PropTypes from 'prop-types';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { replace } from 'lodash';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    th: {
        textTransform: 'capitalize',
    },
    action: {
      position: 'absolute',
      right: '10px',
      background: '#eef4f9',
      textTransform: 'capitalize',
      width: '100px'
    },
    sizeSmall: {
        padding: 0,
        lineHeight: 1,
    },
});

class EnhancedTableHead extends React.Component {
    createSortHandler = (property) => (event) => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {classes, data, order, orderBy, t} = this.props;

        const columns = data && data.length > 0 && Object.keys(data[0]).map((column) => {
            if (column !== 'status') {
              const columnData = {
                  id: column,
                  label: replace(replace(column, '_', ' '), '/', ' '),
              };
              return columnData;
            } else {
              return 'status';
            }
        });

        const selectedLanguage = localStorage.getItem('i18nextLng');

        // TODO: Find a good solution to support other languages
        const tools = [
            `${selectedLanguage === 'fr' ? 'Statut' : 'Status'}`,
            `${t(`module.users.userAccounts.Actions`)}`,
        ];

        return (
            <TableHead>
                <TableRow>
                    {columns && columns.length> 0  && columns.map((column) => <TableCell
                        key={column.id}
                        align={column.numeric ? 'right' : 'left'}
                        padding={column.disablePadding ? 'none' : 'default'}
                        size="small"
                        sortDirection={orderBy === column.id ? order : false}
                        className={classes.th}
                    >
                        <Tooltip
                            title="Sort"
                            placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                            enterDelay={300}
                        >
                            <TableSortLabel
                                active={orderBy === column.id}
                                direction={order}
                                onClick={this.createSortHandler(column.id)}
                            >
                                {column.label !== undefined && t(`module.users.userAccounts.${column.label}`)}
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>)}
                    {tools.map((column) => <TableCell
                        key={column}
                        align="left"
                        padding="normal"
                        size="small"
                        className={column === 'Actions' ? classes.action : classes.th}
                    >
                        {column}
                    </TableCell>)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default withTranslation()(withStyles(styles)(EnhancedTableHead));

import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

function AudioPlayer({ documentUrl }) {

    return (
        <ReactAudioPlayer
            src={documentUrl}
            autoPlay
            controls
        />
    );
}

export default AudioPlayer;

import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { ComputationCodesSetup } from "../Formula/ComputationCodes"
import { ApplicationSetup } from "./ApplicationSetup"
import { ProfileSetup } from "./ProfileSetup"
import { CbsColumnsSetup } from "./CbsColumnsSetup"
import "./applicationColumns.scss"

export function ApplicationColumns({ id, type, isProfileView, isCbsView }) {
  const [result, setResult] = useState("")
  const { t } = useTranslation()

  const handleSelection = (selectionType, data) => {
    setResult(...data.computationCode)
  }

  const resetComputationCode = () => {
    setResult("")
    return
  }

  const updateComputationCode = (data) => {
    setResult(data)
    return
  }

  return (
    <div className="formula">
      <h4 className="mini-heading-top">
        {isProfileView
          ? t("module.scoreLogic.view.configuration.profileFields.title")
          : t("module.scoreLogic.view.configuration.applicationColumns.title")}
      </h4>
      <div className="flex">
        <div className="w-1/2 pr-2">
          <ComputationCodesSetup
            handler={handleSelection}
            selected={result}
            selectOne={true}
          />
        </div>
        <div className="w-1/2 pl-2">
          {!isProfileView && !isCbsView && (
            <ApplicationSetup
              result={result}
              resetComputationCodeFn={resetComputationCode}
              updateComputationCode={updateComputationCode}
            />
          )}
          {isProfileView && (
            <ProfileSetup
              result={result}
              resetComputationCodeFn={resetComputationCode}
              updateComputationCode={updateComputationCode}
            />
          )}
          {isCbsView && (
            <CbsColumnsSetup
              result={result}
              resetComputationCodeFn={resetComputationCode}
              updateComputationCode={updateComputationCode}
            />
          )}
        </div>
      </div>
    </div>
  )
}

/* eslint array-callback-return: 0 */
import { mfiManagerConstants } from "."
import { uniqueBy } from "../../utils/uniqueBy"

const initialState = {
  loading: false,
  items: [],
  subRoles: [],
  subRoleLimits: [],
  groups: [],
  filteredSubroleLimits: null,
  selectedWorkflowGroupId: "",
  hierarchy: [],
  roles: [],
  questionnaires: [],
  mfiImages: [],
  menus: [],
  mpesaDetails: [],
  loanDistributed: {},
}

/**
 * Data Tools
 *
 * @param {*} state
 * @param {*} action
 */
export const mfis = (state = initialState, action) => {
  switch (action.type) {
    case mfiManagerConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        items: [],
      }
    case mfiManagerConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        items: uniqueBy([...action.data], "id"),
      }
    case mfiManagerConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: null,
        error: true,
        items: [],
      }

    case mfiManagerConstants.CREATE_MFI_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case mfiManagerConstants.CREATE_MFI_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
      }
    case mfiManagerConstants.CREATE_MFI_FAILURE:
      return {
        ...state,
        loading: null,
        error: true,
      }
    case mfiManagerConstants.MFI_SUBROLES_ADD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case mfiManagerConstants.MFI_SUBROLES_ADD_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
        subRoles: [...state.subRoles, ...uniqueBy([...action.data], "id")],
      }
    case mfiManagerConstants.MFI_SUBROLES_ADD_FAILURE:
      return {
        ...state,
        loading: null,
        error: true,
      }
    case mfiManagerConstants.MFI_SUBROLES_GETALL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        subRoles: [],
      }
    case mfiManagerConstants.MFI_SUBROLES_GETALL_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        subRoles: uniqueBy([...action.data], "id"),
      }
    case mfiManagerConstants.MFI_SUBROLES_GETALL_FAILURE:
      return {
        ...state,
        loading: null,
        error: true,
        subRoles: [],
      }

    case mfiManagerConstants.MFI_ROLES_GETALL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case mfiManagerConstants.MFI_ROLES_GETALL_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        roles: uniqueBy([...action.data], "id"),
      }
    case mfiManagerConstants.MFI_ROLES_GETALL_FAILURE:
      return {
        ...state,
        loading: null,
        error: true,
      }

    case mfiManagerConstants.MFI_SUBROLE_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case mfiManagerConstants.MFI_SUBROLE_DELETE_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        subRoles: state.subRoles.filter(
          (item) => String(item.id) !== String(action.id)
        ),
      }

    case mfiManagerConstants.MFI_SUBROLE_DELETE_FAILURE:
      return {
        ...state,
      }
    case mfiManagerConstants.MFI_GROUPS_GETALL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        groups: [],
      }
    case mfiManagerConstants.MFI_GROUPS_GETALL_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        groups: uniqueBy([...action.data], "id"),
      }
    case mfiManagerConstants.MFI_GROUPS_GETALL_FAILURE:
      return {
        ...state,
        loading: null,
        error: true,
        groups: [],
      }
    case mfiManagerConstants.MPESA_DETAILS_GETALL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        mpesaDetails: [],
      }
    case mfiManagerConstants.MPESA_DETAILS_GETALL_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        mpesaDetails: uniqueBy([...action.data], "id"),
      }
    case mfiManagerConstants.MPESA_DETAILS_GETALL_FAILURE:
      return {
        ...state,
        loading: null,
        error: true,
        mpesaDetails: [],
      }
    case mfiManagerConstants.MFI_MENUS_GETALL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        menus: [],
      }
    case mfiManagerConstants.MFI_MENUS_GETALL_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        menus: uniqueBy([...action.data], "id"),
      }
    case mfiManagerConstants.MFI_MENUS_GETALL_FAILURE:
      return {
        ...state,
        loading: null,
        error: true,
        menus: [],
      }
    case mfiManagerConstants.MFI_GROUPS_ADD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case mfiManagerConstants.MFI_GROUPS_ADD_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
        groups: [...state.groups, ...uniqueBy([...action.data], "id")],
      }
    case mfiManagerConstants.MFI_GROUPS_ADD_FAILURE:
      return {
        ...state,
        loading: null,
        error: true,
      }
    case mfiManagerConstants.APP_LOAN_DISTRIBUTED_ADD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case mfiManagerConstants.APP_LOAN_DISTRIBUTED_ADD_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
        loanDistributed: action.data,
      }
    case mfiManagerConstants.APP_LOAN_DISTRIBUTED_ADD_FAILURE:
      return {
        ...state,
        loading: null,
        error: true,
      }
    case mfiManagerConstants.APP_LOAN_DISTRIBUTED_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case mfiManagerConstants.APP_LOAN_DISTRIBUTED_EDIT_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        loanDistributed: action.data,
      }

    case mfiManagerConstants.APP_LOAN_DISTRIBUTED_EDIT_FAILURE:
      return {
        ...state,
      }
    case mfiManagerConstants.APP_LOAN_DISTRIBUTED_GET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        loanDistributed: {},
      }
    case mfiManagerConstants.APP_LOAN_DISTRIBUTED_GET_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        loanDistributed: action.data,
      }
    case mfiManagerConstants.APP_LOAN_DISTRIBUTED_GET_FAILURE:
      return {
        ...state,
        loading: null,
        error: true,
        loanDistributed: {},
      }
    case mfiManagerConstants.MPESA_DETAILS_ADD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case mfiManagerConstants.MPESA_DETAILS_ADD_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
        mpesaDetails: [
          ...state.mpesaDetails,
          ...uniqueBy([...action.data], "id"),
        ],
      }
    case mfiManagerConstants.MPESA_DETAILS_ADD_FAILURE:
      return {
        ...state,
        loading: null,
        error: true,
      }
    case mfiManagerConstants.MFI_MENUS_ADD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case mfiManagerConstants.MFI_MENUS_ADD_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
        menus: [...state.menus, ...uniqueBy([...action.data], "id")],
      }
    case mfiManagerConstants.MFI_MENUS_ADD_FAILURE:
      return {
        ...state,
        loading: null,
        error: true,
      }
    case mfiManagerConstants.MPESA_DETAILS_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case mfiManagerConstants.MPESA_DETAILS_EDIT_SUCCESS:
      let newMpesaDetailsState = state.mpesaDetails
      state.mpesaDetails.map((item, index) => {
        if (String(item.id) === String(action.data.id)) {
          newMpesaDetailsState[index] = { ...action.data }
        }
        return
      })
      return {
        ...state,
        loading: null,
        error: null,
        mpesaDetails: newMpesaDetailsState,
      }

    case mfiManagerConstants.MPESA_DETAIL_EDIT_FAILURE:
      return {
        ...state,
        loading: null,
      }
    case mfiManagerConstants.MFI_MENUS_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case mfiManagerConstants.MFI_MENUS_EDIT_SUCCESS:
      let newMenusState = state.menus
      state.menus.map((item, index) => {
        if (String(item.id) === String(action.data.id)) {
          newMenusState[index] = { ...action.data }
        }
        return
      })
      return {
        ...state,
        loading: null,
        error: null,
        menus: newMenusState,
      }

    case mfiManagerConstants.MFI_MENUS_EDIT_FAILURE:
      return {
        ...state,
      }
    case mfiManagerConstants.MFI_GROUPS_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case mfiManagerConstants.MFI_GROUPS_EDIT_SUCCESS:
      let newGroupsState = state.groups
      state.groups.map((item, index) => {
        if (String(item.id) === String(action.data.id)) {
          newGroupsState[index] = { ...action.data }
        }
        return
      })
      return {
        ...state,
        loading: null,
        error: null,
        groups: newGroupsState,
      }

    case mfiManagerConstants.MFI_GROUPS_EDIT_FAILURE:
      return {
        ...state,
      }
    case mfiManagerConstants.MFI_WORKFLOW_FILTER_GROUPS:
      let newFilteredSubroleLimits = []
      if (action.data) {
        // filter
        newFilteredSubroleLimits = state.subRoleLimits.filter((limit) => {
          return limit.workflowGroupId === action.data
        })
        return {
          ...state,
          loading: null,
          error: null,
          filteredSubroleLimits: newFilteredSubroleLimits,
          selectedWorkflowGroupId: action.data,
        }
      } else {
        // default
        return {
          ...state,
          loading: null,
          error: null,
          filteredSubroleLimits: null,
          selectedWorkflowGroupId: "",
        }
      }

    case mfiManagerConstants.MFI_SUBROLES_LIMITS_GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case mfiManagerConstants.MFI_SUBROLES_LIMITS_GETALL_SUCCESS:
      let updatedFilteredSubroleLimits = []
      if (state.selectedWorkflowGroupId) {
        updatedFilteredSubroleLimits = action.data.filter((limit) => {
          return limit.workflowGroupId === state.selectedWorkflowGroupId
        })
      } else {
        updatedFilteredSubroleLimits = state.filteredSubroleLimits
      }

      return {
        ...state,
        loading: null,
        error: null,
        subRoleLimits: [...action.data],
        filteredSubroleLimits: updatedFilteredSubroleLimits,
      }

    case mfiManagerConstants.MFI_SUBROLES_LIMITS_GETALL_FAILURE:
      return {
        ...state,
      }

    case mfiManagerConstants.MFI_SUBROLES_LIMIT_ADD_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        subRoleLimits: [
          ...state.subRoleLimits,
          ...[
            {
              id: "",
              mfiCode: action.data.mfiCode,
              lowerLimit: "",
              upperLimit: "",
              automaticApproval: false,
              categoryWorkflow: [],
            },
          ],
        ],
        filteredSubroleLimits: state.filteredSubroleLimits
          ? [
              ...state.filteredSubroleLimits,
              ...[
                {
                  id: "",
                  mfiCode: action.data.mfiCode,
                  lowerLimit: "",
                  upperLimit: "",
                  automaticApproval: false,
                  categoryWorkflow: [],
                },
              ],
            ]
          : state.filteredSubroleLimits,
      }

    case mfiManagerConstants.MFI_SUBROLES_LIMIT_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case mfiManagerConstants.MFI_SUBROLES_LIMIT_DELETE_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        subRoleLimits: state.subRoleLimits.filter(
          (item) => String(item.id) !== String(action.id)
        ),
        filteredSubroleLimits: state.filteredSubroleLimits
          ? state.filteredSubroleLimits.filter(
              (item) => String(item.id) !== String(action.id)
            )
          : state.filteredSubroleLimits,
      }

    case mfiManagerConstants.MFI_SUBROLES_LIMIT_DELETE_FAILURE:
      return {
        ...state,
      }

    case mfiManagerConstants.MFI_SUBROLES_LIMIT_FLOW_ADD_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        subRoleLimits: state.subRoleLimits.map((item) => {
          if (String(item.id) === String(action.id)) {
            return {
              ...item,
              categoryWorkflow: [
                ...item.categoryWorkflow,
                ...[{ id: action.id, subRole: "" }],
              ],
            }
          }
          return item
        }),
        filteredSubroleLimits: state.filteredSubroleLimits
          ? state.filteredSubroleLimits.map((item) => {
              if (String(item.id) === String(action.id)) {
                return {
                  ...item,
                  categoryWorkflow: [
                    ...item.categoryWorkflow,
                    ...[{ id: action.id, subRole: "" }],
                  ],
                }
              }
              return item
            })
          : state.filteredSubroleLimits,
      }

    case mfiManagerConstants.MFI_SUBROLES_LIMIT_FLOW_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case mfiManagerConstants.MFI_SUBROLES_LIMIT_FLOW_UPDATE_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        subRoleLimits: state.subRoleLimits.map((item) => {
          if (String(item.id) === String(action.data.id)) {
            return [...state.subRoleLimits, ...[action.data]]
          }
          return item
        }),
        filteredSubroleLimits: state.filteredSubroleLimits
          ? state.filteredSubroleLimits.map((item) => {
              if (String(item.id) === String(action.data.id)) {
                return [...state.filteredSubroleLimits, ...[action.data]]
              }
              return item
            })
          : state.filteredSubroleLimits,
      }

    case mfiManagerConstants.MFI_SUBROLES_LIMIT_FLOW_UPDATE_FAILURE:
      return {
        ...state,
      }

    case mfiManagerConstants.MFI_SUBROLES_LIMIT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        subRoleLimits: state.subRoleLimits.map((item) => {
          if (String(item.id) === String(action.data.id)) {
            return { ...item, [action.data.key]: action.data.data }
          }
          return item
        }),
        filteredSubroleLimits: state.filteredSubroleLimits
          ? state.filteredSubroleLimits.map((item) => {
              if (String(item.id) === String(action.data.id)) {
                return { ...item, [action.data.key]: action.data.data }
              }
              return item
            })
          : state.filteredSubroleLimits,
      }
    case mfiManagerConstants.MFI_SUBROLES_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case mfiManagerConstants.MFI_SUBROLES_EDIT_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        subRoles: state.subRoles.map((subRole) => {
          if (subRole.id === action.data.id) {
            return { ...subRole, ...action.data.data }
          }
          return subRole
        }),
      }

    case mfiManagerConstants.MFI_SUBROLES_EDIT_FAILURE:
      return {
        ...state,
      }

    case mfiManagerConstants.REFRESH_TABLE:
      return {
        ...state,
        refreshTable: !state.refreshTable,
      }

    case mfiManagerConstants.GET_HIERARCHY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case mfiManagerConstants.GET_HIERARCHY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        hierarchy: action.data,
      }
    case mfiManagerConstants.GET_HIERARCHY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }

    case mfiManagerConstants.ADD_HIERARCHY_LAYER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case mfiManagerConstants.ADD_HIERARCHY_LAYER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        hierarchy: [...state.hierarchy, action.data],
      }
    case mfiManagerConstants.ADD_HIERARCHY_LAYER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }

    case mfiManagerConstants.EDIT_HIERARCHY_LAYER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case mfiManagerConstants.EDIT_HIERARCHY_LAYER_SUCCESS:
      let newHierarchyState = state.hierarchy
      newHierarchyState.map((item, index) => {
        if (String(item.id) === String(action.data.id)) {
          newHierarchyState[index].name = action.data.name
        }
        return
      })
      return {
        ...state,
        loading: false,
        error: null,
        hierarchy: newHierarchyState,
      }
    case mfiManagerConstants.EDIT_HIERARCHY_LAYER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }

    case mfiManagerConstants.ADD_HIERARCHY_ENTITY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case mfiManagerConstants.ADD_HIERARCHY_ENTITY_SUCCESS:
      let newHierarchy = state.hierarchy
      newHierarchy.map((item, index) => {
        if (String(item.id) === String(action.layerId)) {
          if (newHierarchy[index].entities) {
            newHierarchy[index].entities.push(action.data)
          } else {
            newHierarchy[index].entities = [action.data]
          }
        }
        return
      })
      return {
        ...state,
        loading: false,
        error: null,
        hierarchy: newHierarchy,
      }
    case mfiManagerConstants.ADD_HIERARCHY_ENTITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }

    case mfiManagerConstants.EDIT_HIERARCHY_ENTITY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case mfiManagerConstants.EDIT_HIERARCHY_ENTITY_SUCCESS:
      let updatedHierarchyState = state.hierarchy
      updatedHierarchyState.map((item, index) => {
        if (String(item.id) === String(action.layerId)) {
          updatedHierarchyState[index].entities.forEach((entity, i) => {
            if (
              updatedHierarchyState[index].entities[i].id === action.data.id
            ) {
              updatedHierarchyState[index].entities[i].name = action.data.name
            }
          })
        }
        return
      })
      return {
        ...state,
        loading: false,
        error: null,
        hierarchy: updatedHierarchyState,
      }
    case mfiManagerConstants.EDIT_HIERARCHY_ENTITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }

    case mfiManagerConstants.GET_MFI_IMAGES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case mfiManagerConstants.GET_MFI_IMAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        mfiImages: action.data,
      }
    case mfiManagerConstants.GET_MFI_IMAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }

    case mfiManagerConstants.GET_QUESTIONNAIRES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case mfiManagerConstants.GET_QUESTIONNAIRES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        questionnaires: action.data,
      }
    case mfiManagerConstants.GET_QUESTIONNAIRES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }

    case mfiManagerConstants.ADD_EXTERNAL_INTERGRATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case mfiManagerConstants.ADD_EXTERNAL_INTERGRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        externalIntegration: action.data,
      }
    case mfiManagerConstants.ADD_EXTERNAL_INTERGRATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case mfiManagerConstants.EDIT_EXTERNAL_INTERGRATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case mfiManagerConstants.EDIT_EXTERNAL_INTERGRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        editExternalIntegration: action.data,
      }
    case mfiManagerConstants.EDIT_EXTERNAL_INTERGRATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case mfiManagerConstants.GET_EXTERNAL_INTERGRATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case mfiManagerConstants.GET_EXTERNAL_INTERGRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        externalIntegration: action.data,
      }
    case mfiManagerConstants.GET_EXTERNAL_INTERGRATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }

    default:
      return state
  }
}

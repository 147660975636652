import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import PersonOutlineOutlinedIcon from "@material-ui/icons/FilterList"
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DatePicker,
  Button,
  ButtonGroup,
} from "shards-react"
import Svg from "react-inlinesvg"
import { FormWithConstraints } from "react-form-with-constraints-bootstrap4"
import { useTranslation } from "react-i18next"
import Header from "../../../components/widget/widgetTitle"
import { userRoleConstants, hasAuthority } from "../../../_helpers"
import "./DateFilter.scss"

import { reportsActions } from ".."
import { modalActions } from "../../modals"
import { alertActions } from "../../alert"

const datePickerIcon = require("../../../assets/images/icons/date-picker-icon.svg")

export function DateFilter({ modalID, data }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { dateInputs, hierarchyEntityIdInput, mfiIdInput, submittedBy } =
    useSelector((store) => store.reports)
  const surveyUuid = data
  const { purchaseSummary } = data
  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const [loading, setLoading] = useState(false)
  const [reseting, setReseting] = useState(false)
  const [selectMfi, setSelectMfi] = useState(false)

  useEffect(() => {
    if (dateInputs.startDate) {
      setFrom(new Date(dateInputs.startDate))
      setTo(new Date(dateInputs.endDate))
    } else {
      setFrom(null)
      setTo(null)
    }
  }, [dateInputs])

  useEffect(() => {
    if (
      hasAuthority(userRoleConstants.FA_ANALYST) ||
      hasAuthority(userRoleConstants.FA_ADMIN)
    ) {
      if (!mfiIdInput) {
        setSelectMfi(true)
      }
    }
  }, [mfiIdInput])

  const dateColumnFilter = () => {
    if ((!from || !to) && !purchaseSummary) {
      dispatch(alertActions.error(t("module.report.selectMfiToContinue")))
      return
    }
    if ((!from || !to) && purchaseSummary) {
      dispatch(alertActions.error("Please select a date range"))
      return
    }
    setLoading(true)
    const start = from.toLocaleDateString().split("/")
    const end = to.toLocaleDateString().split("/")
    if (purchaseSummary) {
      const startDate = `${start[2]}-${start[1]}-${start[0]}`
      const endDate = `${end[2]}-${end[1]}-${end[0]}`
      dispatch(reportsActions.filterPaymentSummaryReport(startDate, endDate))
      setLoading(false)
      closeModal()
    } else {
      const payload = {
        mfiId: mfiIdInput,
        startDate: `${start[2]}-${start[1]}-${start[0]}`,
        endDate: `${end[2]}-${end[1]}-${end[0]}`,
        hierarchyEntityId: Number(hierarchyEntityIdInput),
        surveyUuid,
        submittedBy,
      }
      dispatch(reportsActions.filterReports(payload))
      setTimeout(() => {
        setLoading(false)
        closeModal()
      }, 900)
    }
  }

  const resetDateInputs = () => {
    if (purchaseSummary) {
      setReseting(true)
      dispatch(reportsActions.getPaymentsSummaryReport())
      setReseting(false)
      closeModal()
    } else {
      dispatch(reportsActions.resetDateInputs())
      setReseting(true)

      let payload = {
        mfiId: mfiIdInput,
        hierarchyEntityId: Number(hierarchyEntityIdInput),
        surveyUuid,
        submittedBy,
      }

      dispatch(reportsActions.filterReports(payload))

      setTimeout(() => {
        setReseting(false)
        closeModal()
      }, 900)
    }
  }

  const closeModal = () => dispatch(modalActions.hideModal(modalID))

  return (
    <div className="site-modal">
      <Header
        title={t("module.report.filterByDate")}
        icon={<PersonOutlineOutlinedIcon />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        <FormWithConstraints noValidate className="shadow-none">
          {selectMfi && (
            <p className="mfi-error">
              {t("module.report.selectMfiToContinue")}
            </p>
          )}
          <InputGroup seamless className="mb-2" size="lg">
            <DatePicker
              className="form-control"
              placeholderText={t("module.report.clickToSelectStartDate")}
              selected={from}
              onChange={(e) => {
                setFrom(e)
              }}
              dropdownMode={t("module.report.select")}
            />
            <InputGroupAddon type="append">
              <InputGroupText>
                <Svg
                  className="text-center d-inline-block"
                  src={datePickerIcon}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <InputGroup seamless className="mb-2" size="lg">
            <DatePicker
              className="form-control"
              placeholderText={t("module.report.clickToSelectEndDate")}
              selected={to}
              onChange={(e) => setTo(e)}
              dropdownMode={t("module.report.select")}
            />
            <InputGroupAddon type="append">
              <InputGroupText>
                <Svg
                  className="text-center d-inline-block"
                  src={datePickerIcon}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <div className="btn-group-actions site-modal__actions flex relative">
            <ButtonGroup className="btn-group-actions site-modal__actions">
              <Button
                theme="primary"
                className="thin mr-2"
                onClick={() => dateColumnFilter()}
                disabled={selectMfi}
              >
                {loading
                  ? t("module.report.filtering")
                  : t("module.report.filter")}
              </Button>
              <Button
                theme="secondary"
                className="thin delete"
                onClick={() => resetDateInputs()}
                disabled={selectMfi}
              >
                {reseting
                  ? t("module.report.reseting")
                  : t("module.report.reset")}
              </Button>
            </ButtonGroup>
          </div>
        </FormWithConstraints>
      </div>
    </div>
  )
}

import React from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { connect } from 'react-redux';
import { Button, ButtonGroup } from 'shards-react';

import { modalActions } from '../../modals';
import { cslActions } from '..';
import Header from '../../../components/widget/widgetTitle';

function DeleteAssumption({ modalID, data, dispatch }) {
    const { id } = data;

    // eslint-disable-next-line no-underscore-dangle
    const _delete = () => {
        dispatch(cslActions.deleteAssumption(id));
        closeModal();
    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal assumptions-delete">
            <Header title={'Delete Assumption'} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal }/>
            <div className="w-full p-3">
                <p>Are you sure you want to delete this selected assumption?</p>
                <ButtonGroup className="btn-group-actions site-modal__actions">
                    <Button theme="primary" className="thin delete mr-2" onClick={ () => { _delete(); } } >Yes</Button>
                    <Button theme="secondary" className="thin ml-2" onClick={ () => { closeModal(); } }>Cancel</Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

const connectedDeleteAssumption = connect(null)(DeleteAssumption);
export { connectedDeleteAssumption as DeleteAssumption };

import React, { useEffect, useState, useCallback } from "react"
import { connect, useSelector } from "react-redux"
import { FormGroup, FormCheckbox, Button } from "shards-react"
import { useTranslation } from "react-i18next"
import { Loading } from "../../../components/exceptions/Loading"
import { documentChecklistActions } from ".."
import { modalActions } from "../../modals/_actions"
import { ApplicationDetailsActions } from "../../applicationManager"

import "./List.scss"

function DocumentChecklist({
  id: applicationId,
  documentChecklist,
  readonly,
  dispatch,
  location,
}) {
  const applications = useSelector((state) => state.applications)
  const application = applications.items.find(
    (item) => item.id === applicationId
  )
  const date = new Date(application?.submissionTime)
  const textOverlay = `${application?.submittedBy} ${date.toLocaleDateString()}`

  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const disabled = !!readonly
  const { state } = location || {}

  const { groupID } = state || {}

  const fetchApplicationChecklist = useCallback(async () => {
    setIsLoading(true)
    await dispatch(
      documentChecklistActions.getApplicationChecklist(applicationId || groupID)
    )
    setIsLoading(false)
  }, [dispatch, applicationId, groupID])

  useEffect(() => {
    fetchApplicationChecklist()
  }, [fetchApplicationChecklist])

  const handleChange = async (checklistId, confirmed) => {
    setIsLoading(true)
    await dispatch(
      documentChecklistActions.saveApplicationChecklist({
        applicationId,
        checklistId,
        confirmed: !confirmed,
      })
    )
    setIsLoading(false)
  }

  // Handle modal opening
  const deleteFileModal = (checklistId) => {
    dispatch(
      modalActions.deleteApplicationChecklist({
        applicationId,
        checklistId,
        documentUrl: "",
      })
    )
  }

  const uploadFileModal = (id) => {
    dispatch(modalActions.uploadApplicationChecklist(id, applicationId))
  }

  const previewFileModal = (id, modalTitle, documentUrl) => {
    dispatch(
      modalActions.previewApplicationChecklist(
        id,
        modalTitle,
        documentUrl,
        application
      )
    )
  }

  const downloadFile = (documentUrl, name) => {
    const image = new Image()
    image.crossOrigin = "anonymous"
    image.src = documentUrl

    // Once the image loads, create the canvas and add text
    image.onload = function () {
      // Create an off-screen canvas
      const canvas = document.createElement("canvas")
      const ctx = canvas.getContext("2d")

      // Set canvas dimensions to match the image
      canvas.width = image.width
      canvas.height = image.height

      // Draw the image onto the canvas
      ctx.drawImage(image, 0, 0)

      // Set text styles
      const text = textOverlay
      const fontSize = 16
      ctx.font = `${fontSize}px Arial`
      ctx.textAlign = "center" // Center horizontally
      ctx.textBaseline = "middle" // Center vertically
      ctx.fillStyle = "white" // White text color

      // Calculate text position
      const textX = canvas.width / 2
      const textY = canvas.height / 2

      // Add background color behind text (optional)
      const textPadding = 7
      const textWidth = ctx.measureText(text).width + textPadding * 2
      const textHeight = fontSize + textPadding * 2
      ctx.fillStyle = "rgba(0, 0, 0, 0.2)" // Semi-transparent black background
      ctx.fillRect(
        textX - textWidth / 2,
        textY - fontSize / 2 - textPadding,
        textWidth,
        textHeight
      )

      // Add text over the background
      ctx.fillStyle = "white" // Text color
      ctx.fillText(text, textX, textY) // Draw text at the center

      // Convert the canvas to an image and download
      const link = document.createElement("a")
      link.href = canvas.toDataURL("image/png")
      link.download = `${name}.png`
      link.click()
    }
  }

  return (
    <>
      <div className="document-checklist">
        <h4 className="document-checklist__section-title">
          {t("module.documentChecklist.view.list.requiredDocs")}
          {`${documentChecklist.data.length}`}
        </h4>
        <div className="document-checklist__wrapper">
          {isLoading ? (
            <Loading />
          ) : (
            documentChecklist.data.map((item) => {
              const { id, name, documentUrl, confirmed } = item
              return (
                <div key={id} className="document-checklist__item flex">
                  <div className="document-checklist__title mr-auto">
                    {name}
                  </div>
                  <div className="document-checklist__actions">
                    <Button
                      theme="secondary"
                      className={!documentUrl && "inactive-secondary"}
                      onClick={() => previewFileModal(id, name, documentUrl)}
                    >
                      {t("module.documentChecklist.view.list.preview")}
                    </Button>
                    {!documentUrl ? (
                      <Button
                        theme="secondary"
                        onClick={() => uploadFileModal(id)}
                      >
                        {t("module.documentChecklist.view.list.upload")}
                      </Button>
                    ) : (
                      <Button
                        theme="secondary"
                        onClick={() => downloadFile(documentUrl, name)}
                      >
                        {t("module.documentChecklist.view.list.download")}
                      </Button>
                    )}
                    <Button
                      theme="danger"
                      className={!documentUrl && "inactive-danger"}
                      onClick={() => deleteFileModal(id)}
                    >
                      {t("module.documentChecklist.view.list.delete")}
                    </Button>
                    <FormGroup key={id} className="flex mb-0">
                      <FormCheckbox
                        name={id}
                        onChange={() => {
                          handleChange(id, confirmed)
                        }}
                        checked={confirmed}
                        disabled={disabled}
                      >
                        {t("module.documentChecklist.view.list.confirmed")}
                      </FormCheckbox>
                    </FormGroup>
                  </div>
                </div>
              )
            })
          )}
        </div>
      </div>

      <ApplicationDetailsActions id={applicationId} saveDisabled={true} />
    </>
  )
}

const mapStateToProps = (state) => {
  const { documentChecklist } = state
  return { documentChecklist }
}

const connectedDocumentChecklist = connect(mapStateToProps)(DocumentChecklist)
export { connectedDocumentChecklist as DocumentChecklist }

import Chart from "chart.js";
import React, { useEffect, useRef } from "react";

function getRandomColor(data) {
    var backgroundColor = [];
    for(let i=0; i < data?.length; i++) {
        let color = "rgba(" + Math.floor(Math.random() * 255) + "," + Math.floor(Math.random() * 255) + "," + Math.floor(Math.random() * 255) + ",";

        // We push this new color to both background color arrays
        // .. a lighter color is used for the background
        backgroundColor.push(color + "1)");
    }
    return backgroundColor;
}

export const RepaymentReportChart = ({ data, label }) => {
    const canvasRef = useRef();

    useEffect(() => {
        const dataGraph = new Chart(canvasRef.current, {
            type: "bar",
            data: {
                labels: data?.map(a => a?.label.charAt(0).toUpperCase() + a?.label.slice(1).toLowerCase()),
                datasets: [
                    {
                        label: label.charAt(0).toUpperCase() + label.slice(1).toLowerCase(),
                        data: data?.map(a => a?.count),
                        backgroundColor: getRandomColor(data),
                    }
                ]
            },
            options: {
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            stepSize: 20
                        }
                    },
                    xAxes: [{
                        ticks: {
                            autoSkip: false,
                            maxRotation: 60,
                            minRotation: 60
                        }
                    }]
                },
                tooltips: {
                    enabled: false
                }
            },
        });

        // Render the chart.
        dataGraph.render();
    }, [label, data]);

    return (
        <div className="graph" style={{ height: '400px', width: '50%' }}>
            <canvas
                height="120"
                ref={canvasRef}
                style={{ maxWidth: "100% !important", width: "100% " }}
            />
        </div>
    );
};

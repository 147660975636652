import { combineReducers } from "redux"

import { alerts } from "../modules/alert/_reducer"
import { applicationEditor } from "../modules/applicationEditor/_reducer"
import { applications } from "../modules/applicationManager/_reducer"
import { authentication } from "../modules/authentication/_reducer"
import { comments } from "../modules/comments/_reducer"
import { creditScore } from "../modules/creditScore/_reducer"
import { documentChecklist } from "../modules/documentChecklist/_reducer"
import { kobotoolkit } from "../modules/kobotoolkit/_reducer"
import { mfis } from "../modules/mfiManager/_reducer"
import { clients } from "../modules/client/_reducer"
import { pendingTasks } from "../modules/client/views/pendingTasks/store/_reducer"
import { modals } from "../modules/modals/_reducer"
import { registration } from "../modules/userManager/registration.reducer"
import { scoreLogic } from "../modules/scoreLogic/_reducer"
import { settings } from "../modules/settings/_reducer"
import { tools } from "../modules/tools/_reducer"
import { users } from "../modules/userManager/_reducer"
import { workflow } from "../modules/workflow/_reducer"
import { config } from "../modules/config/_reducer"
import { reports } from "../modules/reports/_reducer"
import { surveys } from "../modules/surveys/_reducer"
import { notifications } from "../modules/client/views/notifications/store/reducer"
import { publishers } from "../modules/publisher/_reducer"

const rootReducer = combineReducers({
  alerts,
  applicationEditor,
  applications,
  authentication,
  comments,
  creditScore,
  documentChecklist,
  kobotoolkit,
  mfis,
  modals,
  registration,
  scoreLogic,
  settings,
  tools,
  users,
  workflow,
  config,
  reports,
  surveys,
  clients,
  pendingTasks,
  notifications,
  publishers,
})

export default rootReducer

/* eslint-disable react/display-name */
import React, { useEffect } from "react"
import { Router, Route } from "react-router-dom"

import { Provider, useDispatch } from "react-redux"
import { publicRoutes, privateRoutes } from "./routes"
import { store, history } from "./_helpers"

import Authenticate from "./authenticate"
import { PrivateRoute } from "./privateRoute"

import { Alert as AlertRoot } from "./modules/alert"
import { modalActions, ModalRoot } from "./modules/modals"
import WebSocketProvider from "./modules/webSocket"
import { authenticationActions } from "./modules/authentication"
import IdleTimer from "./IdleTimer"
import "react-perfect-scrollbar/dist/css/styles.css"

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

const App = () => {
  const token = JSON.parse(localStorage.getItem("user"))
  const dispatch = useDispatch()

  useEffect(() => {
    const path = window.location.pathname
    if (path !== "/login") {
      const timer = new IdleTimer({
        timeout: 1200,
        onTimeout: () => {
          dispatch(authenticationActions.logout())
        },
        onExpired: () => {
          dispatch(authenticationActions.logout())
        },
        onWarning: () => {
          dispatch(modalActions.autoLogout({}))
        },
        login: false,
      })
      return () => {
        timer.cleanup()
      }
    } else {
      const timer = new IdleTimer({
        timeout: 10,
        onTimeout: null,
        onExpired: null,
        onWarning: null,
        login: true,
      })
    }
  }, [])

  return (
    <>
      {token !== null && <WebSocketProvider />}
      <Router basename={process.env} history={history}>
        <>
          {publicRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={Authenticate(
                (props) => (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                ),
                { roles: route.roles }
              )}
            />
          ))}

          {privateRoutes.map((route, index) => (
            <PrivateRoute
              key={index}
              path={route.path}
              exact={route.exact}
              component={Authenticate(
                (props) => (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                ),
                { roles: route.roles }
              )}
            />
          ))}
          <AlertRoot />
          <ModalRoot />
        </>
      </Router>
    </>
  )
}

export default AppWrapper

import React, { useState, useEffect, useRef } from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { connect } from 'react-redux';
import {
    FormWithConstraints,
    Input,
    FieldFeedbacks,
    FieldFeedback,
} from 'react-form-with-constraints-bootstrap4';
import { FormGroup, Button } from 'shards-react';
import { useTranslation } from 'react-i18next';

import { find, findIndex, isEmpty } from 'lodash';
import Header from '../../../components/widget/widgetTitle';
import { modalActions } from '../../modals';
import { cslActions } from '..';

function AddScoreCardCondition({scoreLogic, modalID, data: id, dispatch}) {
    const { scoreGrade } = scoreLogic;
    const { t } = useTranslation();

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const form = useRef(null);
    let savedData = {};

    if (id) {
        const found = find(scoreGrade, { id });
        const index = findIndex(scoreGrade, found);
        savedData = index !== -1 && scoreGrade[index];
    }

    const [inputs, setInputs] = useState({ ...{ maximum: '', grade: '' }, ...savedData });

    useEffect(() => {
        function shouldEnableSubmitButton() {
            return inputs.grade && inputs.maximum;
        }
        setSubmitButtonDisabled(!shouldEnableSubmitButton());
    }, [inputs]);

    const handleChange = ({ target, currentTarget }) => {
        const { name, value } = target;
        setInputs((prevState) => ({ ...prevState, [name]: value }));
        form.current.validateFields(currentTarget);
    };

    const save = async () => {
        await form.current.validateForm();

        if (isEmpty(inputs)) {
            return false;
        }

        // eslint-disable-next-line no-unused-expressions
        !id && dispatch(cslActions.addScoreCardGrade({ ...inputs }));
        // eslint-disable-next-line no-unused-expressions
        id && dispatch(cslActions.editScoreCardGrade(id, { ...inputs }));

        closeModal();

        return false;
    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal scoring-variables__add-variable">
            <Header title={t('module.scoreLogic.modal.addScoreCardCondition.headerTitle')} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal }/>
            <div className="w-full p-6">
                <FormWithConstraints ref={form} noValidate className="shadow-none">
                    <FormGroup>
                        <label htmlFor="maximum">{t('module.scoreLogic.modal.addScoreCardCondition.threshold')}</label>
                        <Input
                            className="form-control mb-2"
                            name="maximum"
                            defaultValue={inputs.maximum}
                            onChange={handleChange}
                            type="number"
                            min="0"
                            step="0.1"
                            max="1"
                            required
                        />
                        <FieldFeedbacks for="maximum">
                            <FieldFeedback when="*" />
                        </FieldFeedbacks>
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="grade">{t('module.scoreLogic.modal.addScoreCardCondition.grade')}</label>
                        <Input
                            className="form-control mb-2"
                            name="grade"
                            defaultValue={inputs.grade}
                            onChange={handleChange}
                            type="text"
                            required
                        />
                        <FieldFeedbacks for="grade">
                            <FieldFeedback when="*" />
                        </FieldFeedbacks>
                    </FormGroup>
                </FormWithConstraints>
            </div>

            <div className="flex pb-6 pr-6">
                <Button theme="primary" disabled={submitButtonDisabled} className="ml-auto" onClick={ () => { save(); } }>{t('module.scoreLogic.modal.addScoreCardCondition.save')}</Button>
                <Button theme="secondary" className="ml-2" onClick={ () => { closeModal(); } }>{t('module.scoreLogic.modal.addScoreCardCondition.cancel')}</Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { scoreLogic, modals } = state;
    return { scoreLogic, modals };
};

const connectedAddScoreCardCondition = connect(mapStateToProps)(AddScoreCardCondition);
export { connectedAddScoreCardCondition as AddScoreCardCondition };

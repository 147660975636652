import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  Typography,
} from "@material-ui/core";
import "./PaymentDetailsDialog.scss";

const PaymentDetailsDialog = ({ isOpen, onClose, data, tabIndex }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="payment-details-dialog">
      <DialogTitle>Payment Details</DialogTitle>
      <DialogContent>
        <Paper className="paper">
          <Typography className="bold-text">GRN Number:</Typography>{" "}
          {data.grnNo}
          <Typography className="bold-text">Farmer Name:</Typography>{" "}
          {data.farmerName}
          <Typography className="bold-text">Mobile Number:</Typography>{" "}
          {data.mobileNo}
          <Typography className="bold-text">Quantity (Kgs):</Typography>{" "}
          {data.quantity}
          <Typography className="bold-text">Price/KG:</Typography>{" "}
          {data.priceKg}
          <Typography className="bold-text">Total Amount:</Typography>{" "}
          {data.totalAmount}
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentDetailsDialog;

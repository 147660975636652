import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, FormRadio } from "shards-react"
import Header from "../../../components/widget/widgetTitle"
import { useTranslation } from "react-i18next"
import { modalActions } from "../../modals/_actions"
import { applicationActions } from ".."
import Svg from "react-inlinesvg"
import { alertActions } from "../../alert"

const toolsIcon = require("../../../assets/images/icons/tools-icon.svg")

export function UserFilter({ modalID, data }) {
  const applications = useSelector((state) => state.applications)
  const [user, setUser] = useState(
    applications.filterState.user && applications.filterState.user
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()
  let users = []
  applications.items &&
    applications.items.forEach((item, i) => {
      !users.includes(item.currentUser) && users.push(item.currentUser)
    })

  const handleClick = (item) => {
    user === item ? setUser("") : setUser(item)
  }

  const userFilter = (reset) => {
    if (reset) {
      dispatch(alertActions.success(t("alertActions.applications.filterReset")))
      dispatch(applicationActions.userFilter(""))
      dispatch(
        applicationActions.filterState({
          ...applications.filterState,
          user: "",
        })
      )
    } else {
      dispatch(
        alertActions.success(`Displaying applications assigned to ${user}`)
      )
      dispatch(applicationActions.userFilter(user))
      dispatch(
        applicationActions.filterState({ ...applications.filterState, user })
      )
    }
    closeModal()
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  return (
    <div className="site-modal">
      <Header
        title={t("module.applications.filterByUser")}
        icon={<Svg className="text-center d-inline-block" src={toolsIcon} />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        {users && users.length > 0 ? (
          users.map((item, idx) => (
            <FormRadio
              key={idx}
              name="users"
              checked={user === item}
              onChange={() => handleClick(item)}
            >
              {item}
            </FormRadio>
          ))
        ) : (
          <div>{t("module.applications.nothingToFilter")}.</div>
        )}
        <br />
        {users && users.length > 0 && (
          <div className="btn-group-actions site-modal__actions flex justify-end">
            <Button
              theme="light"
              className="mr-2"
              onClick={() => userFilter(true)}
            >
              {t("module.applications.reset")}
            </Button>
            <Button
              theme="primary"
              className="delete mr-2"
              onClick={() => userFilter()}
            >
              {t("module.applications.filter")}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

import React from 'react';
import slugify from 'slugify';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ClientEnhancedTableHead from '../../components/client/EnhancedTableHead';
import { TableRowsActionsClients } from '../../components/client/rowActions/TableRowActionsClients';
import { makeStyles, Paper, TablePagination } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        marginTop: '1.3rem',
        backgroundColor: '#fff',
        overflowX: 'scroll'
    },
    table: {
        minWidth: 700,
        padding: '10px',
        overflowX: 'auto',
        display: 'block',
        whiteSpace: 'nowrap',
    }
}));

export const ListClients = ({ data, setState, state, paginationData, refetchClients, roles }) => {
    const classes = useStyles();
    const token = JSON.parse(localStorage.getItem('user'));
    const { t } = useTranslation();

    const colorCodes = (dataRow) => {
        if (dataRow?.daysInArrears === 0) {
            return '#eff9fe';
        } else if (dataRow?.daysInArrears > 0 && dataRow?.daysInArrears <= 7) {
            return '#fff6ee';
        } else if (dataRow?.daysInArrears > 7 && dataRow?.daysInArrears <= 14) {
            return '#d8eafc';
        } else if (dataRow?.daysInArrears > 14 && dataRow?.daysInArrears <= 21) {
            return '#edfbf6';
        } else if (dataRow?.daysInArrears > 21 && dataRow?.daysInArrears <= 30) {
            return '#e2a5f0';
        } else {
            return '#ff4d4d';
        }
    }

    const handleChange = (event, page) => {
        setState({ ...state, page });
    };

    const handlePerPageChange = (event, value) => {
        setState({ ...state, perPage: event.target.value });
    };

    const generateRows = () => {
        return data?.map((row) => {
                const { id, mfiId } = row;
                const meta = [];

                // handle the column data within each row
                const cells = Object.entries(row).map((colData, index) => <TableCell key={index} className={ `table-${slugify(colData[0], { replacement: '-' })}` }>{colData[1]}</TableCell>);

                meta.push(<TableCell><div style={{width: '100px'}}></div></TableCell>);
                meta.push(
                    <TableCell className="table-action tableAction">
                        <TableRowsActionsClients userId={token && token.id} refetchClients={refetchClients} { ...row } id={row.id} mfiCode={mfiId} roles={roles} />
                    </TableCell>
                )

                cells.push(meta);

                return <TableRow style={{backgroundColor: colorCodes(row)}} hover role="checkbox" tabIndex={-1} key={ id }>{cells}</TableRow>;
            });
    }

    return (
        <Paper className={classes.root}>
            <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                width="100%"
                style={{
                    display: 'table'
                }}
            >
                <ClientEnhancedTableHead data={data} />
                <TableBody>
                    { generateRows() }
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
                component="div"
                count={paginationData?.totalElements}
                rowsPerPage={paginationData?.numberOfElements}
                page={paginationData?.number}
                backIconButtonProps={{ 'aria-label': t('module.client.arrears.previousPage') }}
                nextIconButtonProps={{ 'aria-label': t('module.client.arrears.nextPage') }}
                SelectProps={{
                    inputProps: { 'aria-label': t('module.client.arrears.rowsPerPage') },
                    native: true,
                }}
                labelRowsPerPage={t('module.client.arrears.rowsPerPage')}
                onPageChange={handleChange}
                onRowsPerPageChange={handlePerPageChange}
            />
        </Paper>
    );
};

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'lodash';
import Header from '../../../components/widget/widgetTitle';
import { DateFilter } from '../components/DateFilter';
import { modalActions } from '../../modals/_actions';
import { applicationActions } from '..';
import { alertActions } from '../../alert';
import PersonOutlineOutlinedIcon from '@material-ui/icons/FilterList';
import { useTranslation } from 'react-i18next';


export function DateColumnFilter({ modalID, data }) {
    const dispatch = useDispatch();
    const applications = useSelector(state => state.applications);
    let dateColumnFilters;
    if (applications) {
      dateColumnFilters = applications.dateColumnFilters
    }
    const { t } = useTranslation()
    const dateToday = new Date();
    const [inputs, setInputs] = useState({
        from: dateToday,
        to: dateToday,
    });

    useEffect(() => {
      if (dateColumnFilters) {
        if (dateColumnFilters.key === data.key) {
          setInputs({
              from: dateColumnFilters.from,
              to: dateColumnFilters.to,
          })
        }
      }
    }, [dateColumnFilters, data.key])

    const handleChangeFrom = (val) => {
        if (val.toJSON().slice(0, 10) >  dateToday.toJSON().slice(0, 10)) {
          dispatch(alertActions.error(t('alertActions.applications.onlyFilterBeforeToday')));
          return null;
        }
        if (val.toJSON().slice(0, 10) > inputs.to.toJSON().slice(0, 10)) {
          dispatch(alertActions.error(t('alertActions.applications.fromDateBefore')));
          return null
        }
        setInputs((prevState) => ({ ...prevState, from: new Date(val) }));
    };

    const handleChangeTo = (val) => {
        if (val.toJSON().slice(0, 10) >  dateToday.toJSON().slice(0, 10)) {
          dispatch(alertActions.error(t('alertActions.applications.onlyFilterBeforeToday')));
          return null;
        }
        if (val.toJSON().slice(0, 10) < inputs.from.toJSON().slice(0, 10)) {
          dispatch(alertActions.error(t('alertActions.applications.toDateBefore')));
          return null
        }
        setInputs((prevState) => ({ ...prevState, to: new Date(val) }));
    };

    const dateColumnFilter = (reset) => {
        if (reset) {
          dispatch(alertActions.success(t('alertActions.applications.filterSuccessful')));
          dispatch(applicationActions.dateColumnFilter());
          closeModal();
          return null;
        };
        const { from, to } = inputs;
        // check if there is an application in this range
        const applicationTrue = data.applicationsData.filter((item, i) => {
          const columnKey = data.key
          if (item[columnKey]) {
            const rowDate = new Date(item[columnKey]).toJSON().slice(0, 10);
            const fromDate = new Date(from).toJSON().slice(0, 10);
            const toDate = new Date(to).toJSON().slice(0, 10);
            return (rowDate >= fromDate && toDate >= rowDate);
          } else {
            return null;
          }

        });
        if (applicationTrue.length === 0) {
          dispatch(alertActions.error(t('alertActions.applications.noApplicationWithDateRange')));
        } else {
          dispatch(alertActions.success(t('alertActions.applications.dateFilter')));
          dispatch(applicationActions.dateColumnFilter(data.key, from, to));
          closeModal();
        }
    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal">
            <Header
              title={`Filter Column: ${replace(replace(data.key, '_', ' '), '/', ' ')}`}
              icon={<PersonOutlineOutlinedIcon />}
              close={true}
              closeHandler={ closeModal }/>
            <div className="p-10">
                <DateFilter
                  from={inputs.from}
                  to={inputs.to}
                  handleChangeFrom={handleChangeFrom}
                  handleChangeTo={handleChangeTo}
                  dateColumnFilter={dateColumnFilter}
                  />
            </div>
        </div>
    );
}

import { isEmpty } from "lodash"
import { workflowConstants, workflowService } from "."
import { alertActions } from "../alert"
import { applicationActions, applicationConstants } from "../applicationManager"
import { commentActions } from "../comments"

export const workflowActions = {
  assignApplication,
  approveApplication,
  declineApplication,
  getAssignees,
  disburseRefuseApplication,
  addTransactionData,
  disburseMultipleApplications,
}

function assignApplication(
  id,
  assignee,
  target,
  comment,
  base64Image,
  messages
) {
  return (dispatch, getState) => {
    const { uid } = getState().applications
    dispatch(request())

    workflowService.assignApplication(id, assignee).then(
      (data) => {
        dispatch(success(data))
        dispatch(applicationActions.assignApplication(id, target))
        dispatch(alertActions.success(messages.assignSuccessful))
        // only add comments once assigning succeeds
        dispatch(commentActions.addComment(id, comment, base64Image))
        // trigger applications state update
        dispatch(applicationActions.getApplicationBySurveyUuid(uid))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error))
        dispatch(commentActions.addComment(id, comment))
      }
    )
  }

  function request(data) {
    return { type: workflowConstants.WORKFLOW_REQUEST, data }
  }
  function success(data) {
    return { type: workflowConstants.WORKFLOW_SUCCESS, data }
  }
  function failure(error) {
    return { type: workflowConstants.WORKFLOW_FAILURE, error }
  }
}

function approveApplication(id, messages) {
  return (dispatch, getState) => {
    const { uid } = getState().applications
    dispatch(request())

    workflowService.approveApplication(id).then(
      (data) => {
        dispatch(success(data))
        // trigger applications state update
        dispatch(applicationActions.getApplicationBySurveyUuid(uid))
        dispatch(alertActions.success(messages.editSuccessful))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error))
      }
    )
  }

  function request(data) {
    return { type: workflowConstants.WORKFLOW_REQUEST, data }
  }
  function success(data) {
    return { type: workflowConstants.WORKFLOW_SUCCESS, data }
  }
  function failure(error) {
    return { type: workflowConstants.WORKFLOW_FAILURE, error }
  }
}

function declineApplication(id) {
  return (dispatch, getState) => {
    const { uid } = getState().applications
    dispatch(request())

    workflowService.declineApplication(id).then(
      (data) => {
        dispatch(success(data))
        // trigger applications state update
        dispatch(applicationActions.getApplicationBySurveyUuid(uid))
        dispatch(alertActions.success("Decline successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error))
      }
    )
  }

  function request(data) {
    return { type: workflowConstants.WORKFLOW_REQUEST, data }
  }
  function success(data) {
    return { type: workflowConstants.WORKFLOW_SUCCESS, data }
  }
  function failure(error) {
    return { type: workflowConstants.WORKFLOW_FAILURE, error }
  }
}

/**
 * Get list of users to assign to
 *
 * @param {*} applicationID
 */
function getAssignees(applicationID) {
  return (dispatch) => {
    dispatch(request())

    try {
      if (isEmpty(applicationID)) {
        throw new Error("Invalid applicationID")
      }
      workflowService.getAssignees(applicationID).then(
        (data) => {
          const newDataForward =
            data &&
            data.forward.map((item) => {
              const uniqueKey = Number(`${item.id}${applicationID}`)
              return {
                ...item,
                applicationID: Number(applicationID),
                uniqueKey,
              }
            })

          const newDataBack =
            data &&
            data.back.map((item) => {
              const uniqueKey = Number(`${item.id}${applicationID}`)
              return {
                ...item,
                applicationID: Number(applicationID),
                uniqueKey,
              }
            })
          dispatch(successForward(newDataForward))
          dispatch(successBack(newDataBack))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(alertActions.error(error.response.data.message))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request(data) {
    return { type: workflowConstants.WORKFLOW_ASSIGNEES_REQUEST, data }
  }
  function successForward(data) {
    return { type: workflowConstants.WORKFLOW_ASSIGNEES_FORWARD_SUCCESS, data }
  }
  function successBack(data) {
    return { type: workflowConstants.WORKFLOW_ASSIGNEES_BACK_SUCCESS, data }
  }
  function failure(error) {
    return { type: workflowConstants.WORKFLOW_ASSIGNEES_FAILURE, error }
  }
}

function disburseRefuseApplication(data) {
  return (dispatch, getState) => {
    const { uid } = getState().applications
    dispatch(request())

    workflowService.disburseRefuseApplication(data).then(
      (data) => {
        dispatch(success(data))
        // trigger applications state update
        dispatch(applicationActions.getApplicationBySurveyUuid(uid))
        dispatch(alertActions.success("Disburse successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error))
      }
    )
  }

  function request(data) {
    return { type: applicationConstants.APPLICATIONS_REQUEST, data }
  }
  function success(data) {
    return { type: workflowConstants.DISBURSE_REFUSE_SUCCESS, data }
  }
  function failure(error) {
    return { type: workflowConstants.DISBURSE_REFUSE_FAILURE, error }
  }
}

function disburseMultipleApplications(data) {
  return (dispatch, getState) => {
    const { uid } = getState().applications
    dispatch(request())

    workflowService.disburseMultipleApplications(data).then(
      (data) => {
        dispatch(success(data))
        // trigger applications state update
        dispatch(applicationActions.getApplicationBySurveyUuid(uid))
        dispatch(alertActions.success("Disburse successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error))
      }
    )
  }

  function request(data) {
    return { type: applicationConstants.APPLICATIONS_REQUEST, data }
  }
  function success(data) {
    return { type: workflowConstants.DISBURSE_REFUSE_SUCCESS, data }
  }
  function failure(error) {
    return { type: workflowConstants.DISBURSE_REFUSE_FAILURE, error }
  }
}

function addTransactionData(data) {
  return (dispatch) => {
    workflowService.addTransactionData(data).then(
      (data) => {
        dispatch(alertActions.success("Transaction data added successfully"))
      },
      (error) => {
        dispatch(alertActions.error(error))
      }
    )
  }
}

import React from 'react';
import { Container, Button } from 'shards-react';
import { withTranslation } from 'react-i18next';

const ClientErrorsView = ( { t, errorData } ) => (
    <Container fluid className="main-content-container px-4 pb-4">
        <div className="error">
            <div className="error__content">
                <h2>{errorData?.status_code}</h2>
                <h3>{errorData?.errors && errorData?.errors[0]}</h3>
                <p>{errorData?.message}</p>
                <Button pill>&larr; {t("view.errors.button")}</Button>
            </div>
        </div>
    </Container>
);

const translatedClientErrorsView = withTranslation()(ClientErrorsView);
export { translatedClientErrorsView as ClientErrorsView };

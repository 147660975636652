import React, { useState, useEffect } from "react"
import { Container, Row } from "shards-react"
import { useDispatch, useSelector } from "react-redux"
import "../../reports/views/Overview.scss"
import { clientActions } from "../_actions"
import { applicationActions } from "../../applicationManager"
import ClientCard from "../components/Overview/card"
import PageTitle from "../../../components/common/PageTitle"
import { FilterMfis } from "../components/filterMfis"
import { authenticationActions } from "../../authentication"
import { mfiManagerActions } from "../../mfiManager"
import { ErrorsView, LoadingView } from "../../../views"
// import { ClientErrorsView } from "../components/ClientError";
import { TasksChart } from "../components/Overview/chart"
import Notifications from "../components/Overview/notifications"
import { useTranslation } from "react-i18next"
import { isEmpty } from "lodash"

const IconRight = require("../../../assets/images/client/application-icon.svg")
const IconDocs = require("../../../assets/images/client/icon-docs.svg")
const IconChecklist = require("../../../assets/images/client/icon-checklist.svg")

export const ClientOverview = () => {
  const client = useSelector((state) => state.clients)
  const user = useSelector((state) => state.authentication)
  const mfis = useSelector((state) => state.mfis)
  const applications = useSelector((state) => state.applications)

  applications.myPendigTasksItems = applications?.myPendigTasksItems?.filter(
    (item) => !item.groupCreation
  )

  const { t } = useTranslation()
  const {
    profile: { mfi, roles },
  } = user
  const allMfis =
    (roles?.includes("FA_ANALYST") || roles?.includes("FA_ADMIN")) &&
    mfis?.items
  const dispatch = useDispatch()
  const [selectedMfi, setSelectedMfi] = useState({})
  const {
    clients,
    overview,
    overviewGraph,
    loading,
    error,
    overviewError,
    loadingGraph,
  } = client

  const myApplicationsUid =
    applications?.myApplicationItems?.length > 0 &&
    applications?.myApplicationItems[
      applications?.myApplicationItems.length - 1
    ].surveyUuid

  const myTasksUid =
    applications?.myPendigTasksItems?.length > 0 &&
    applications?.myPendigTasksItems[
      applications?.myPendigTasksItems.length - 1
    ].surveyUuid

  useEffect(() => {
    dispatch(authenticationActions.getProfile())
  }, [dispatch])

  useEffect(() => {
    dispatch(mfiManagerActions.getAllMFIs())
  }, [dispatch])

  const selectQuestionnaire = (e) => {
    const { value } = e.target
    const mfi = allMfis.filter((mfiItem) => {
      return mfiItem.mfiName === value
    })
    setSelectedMfi(mfi[0])
  }

  useEffect(() => {
    dispatch(clientActions.getClientOverview(selectedMfi?.id || mfi?.id))
  }, [dispatch, mfi, selectedMfi])

  useEffect(() => {
    dispatch(applicationActions.getMyApplications())
  }, [dispatch, mfi, selectedMfi])

  useEffect(() => {
    dispatch(applicationActions.getMyPendingTasks())
  }, [dispatch, mfi, selectedMfi])

  useEffect(() => {
    dispatch(clientActions.getClientOverviewGraph(selectedMfi?.id || mfi?.id))
  }, [dispatch, mfi, selectedMfi])

  return (
    <Container
      fluid
      className="main-content-container"
      style={{ paddingTop: "13px" }}
    >
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={t("module.report.overview")}
          subtitle={t("module.report.dashboard")}
          className="text-sm-left mb-3"
        />
      </Row>
      <Row noGutters>
        <FilterMfis
          handler={selectQuestionnaire}
          selectedValue={selectedMfi?.mfiName !== undefined ? selectedMfi : mfi}
          items={allMfis?.length > 0 ? allMfis : clients[0]}
        />
      </Row>
      {loading && <LoadingView />}
      {!isEmpty(error) && <ErrorsView error={overviewError} />}
      {/* {error && <ClientErrorsView errorData={overviewError?.response?.data} />} */}
      {!loading && (
        <div
          className="graph-container"
          style={{ display: "flex", marginTop: "10px" }}
        >
          {/* pending tasks */}

          <ClientCard
            title={t("module.client.overview.myApplications")}
            subtitle={t("module.client.overview.intheLast7days")}
            count={applications?.myApplicationItems?.length}
            icon={IconRight}
            unique={false}
            pageUid={myApplicationsUid}
            all
            link="/applications"
          />

          <ClientCard
            title={t("module.client.overview.myPendingTasks")}
            subtitle={t("module.client.overview.intheLast7days")}
            count={applications?.myPendigTasksItems?.length}
            icon={IconRight}
            unique={false}
            pageUid={myTasksUid}
            link="/applications"
          />

          {/* loan arrears */}
          {/* <ClientCard
            title={t('module.client.overview.loansinArrears')}
            subtitle={t('module.client.overview.intheLast7days')}
            count={overview?.loansInArrears}
            link="/clients/arrears" "/clients/my-pending-tasks"
            icon={IconRight}
            unique={false}
          /> */}

          {/* Loans Recovery */}
          <ClientCard
            title={t("module.client.overview.loansRecovery")}
            subtitle={t("module.client.overview.past7Days")}
            count={overview?.loansRecovery}
            icon={IconDocs}
            unique={true}
          />

          {/* Loans Repaid */}
          <ClientCard
            title={t("module.client.overview.loansRepaid")}
            subtitle={t("module.client.overview.amountPaidinFull")}
            count={overview?.loansRepaid}
            icon={IconChecklist}
            unique={true}
          />
        </div>
      )}
      {!loading && (
        <div>
          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {loadingGraph && (
              <p style={{ color: "gray" }}>
                {t("module.client.overview.loadingGraphData")}...
              </p>
            )}
            {overviewGraph?.length && !loadingGraph ? (
              <TasksChart data={overviewGraph} />
            ) : (
              ""
            )}

            <Notifications />
          </div>
          <br />
        </div>
      )}
    </Container>
  )
}

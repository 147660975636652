import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import TooltipTrigger from 'react-popper-tooltip';
import { modalActions } from '../../../../modals';
import { TableRowActionsAssignTo } from './TableRowActionsAssignTo';
import { TableRowActionsAssignBack } from './TableRowActionsAssignBack';

export function TableRowsActionsClients({ id, mfiCode, refetchClients, roles, userId }) {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const viewClientModalHandler = () => {
        dispatch(modalActions.viewClientDetails(mfiCode, id, refetchClients));
    }

    const sendReminderModalHandler = () => {
        dispatch(modalActions.sendReminder(id, mfiCode));
    }

    function Trigger({ getTriggerProps, triggerRef }) {
        return (
            <button
                {...getTriggerProps({
                    ref: triggerRef,
                    className: 'trigger dropdown-toggle btn btn-primary',
                })}
            >
                {t('module.mfiSetup.actions.actions')}
            </button>
        );
    }

    function Tooltip({
        getTooltipProps,
        getArrowProps,
        tooltipRef,
        arrowRef,
        placement,
    }) {
        return (
            <div
                {...getTooltipProps({
                    ref: tooltipRef,
                    className: 'tooltip-container table-actions',
                })}
            >
                <div
                    {...getArrowProps({
                        ref: arrowRef,
                        'data-placement': placement,
                        className: 'tooltip-arrow',
                    })}
                />
                <div className="tooltip-body">
                    <div className="dropdown dropdown-right">{items}</div>
                </div>
            </div>
        );
    }

    const items = (
        <div className="dropdown-item">
            {/* eslint-disable jsx-a11y/anchor-is-valid */}
            <a><div onClick={viewClientModalHandler}>{t('module.client.arrears.action.viewClient')}</div></a>
            <a>{roles?.includes('MFI_SUPERVISOR') && (
                <div onClick={sendReminderModalHandler}>{t('module.client.arrears.action.sendReminder')}</div>
            )}</a>
            <a><TableRowActionsAssignTo userId={userId} refetchClients={refetchClients} id={id} mfiCode={mfiCode} /></a>
            <a><TableRowActionsAssignBack userId={userId} refetchClients={refetchClients && refetchClients} id={id} mfiCode={mfiCode} /></a>
        </div>
    );

    return (
        <>
            {items && <TooltipTrigger placement="right" trigger="click" tooltip={Tooltip}>
                {Trigger}
            </TooltipTrigger>}
        </>
    );
}

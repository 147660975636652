import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { settingsActions } from '../../../modules/settings';

const SidebarIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <g fill="#FFF" fill-rule="nonzero">
            <path d="M19.5 9.5h-11v2.034h11zM19.5 13.534h-11v2.033h11zM19.5 17.567h-11v2.034h11z"/>
            <path d="M14.084.5C6.594.5.5 6.594.5 14.084c0 7.49 6.094 13.585 13.584 13.585 7.49 0 13.585-6.095 13.585-13.585C27.669 6.594 21.575.5 14.084.5zm0 25.575c-6.611 0-11.99-5.38-11.99-11.99 0-6.612 5.379-11.99 11.99-11.99 6.612 0 11.99 5.378 11.99 11.99 0 6.61-5.378 11.99-11.99 11.99z"/>
        </g>
    </svg>
)

class SidebarMainNavbar extends React.Component {
    constructor(props) {
        super(props);

        this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
    }

    handleToggleSidebar() {
        this.props.dispatch(settingsActions.toggleSidebar());
    }

    render() {
        const title = this.props.settings.menuVisible ? <div className="navbar-title mr-auto ml-2 self-center">LendXS</div> : '';

        return (
            <div className="main-navbar bg-primary align-items-stretch flex flex-no-wrap items-center px-3 py-2 content-center" type="dark">
                { title }
                {/* eslint-disable-next-line */}
                <a
                    className="toggle-sidebar flex"
                    onClick={this.handleToggleSidebar}
                    style={{ alignItems: 'center' }}
                >
                    <SidebarIcon />
                </a>
            </div>
        );
    }
}

SidebarMainNavbar.propTypes = {
    /**
   * Whether to hide the logo text, or not.
   */
    hideLogoText: PropTypes.bool,
};

SidebarMainNavbar.defaultProps = {
    hideLogoText: false,
};

const mapStateToProps = (state) => {
    const { settings } = state;
    return {
        settings,
    };
};

const connectedSidebarMainNavbar = connect(mapStateToProps)(SidebarMainNavbar);
export { connectedSidebarMainNavbar as SidebarMainNavbar };

export const toolsConstants = {
  EXPORT_QUESTIONNAIRE_UID: "EXPORT_QUESTIONNAIRE_UID",
  EXPORT_FILE_FORMAT: "EXPORT_FILE_FORMAT",
  EXPORT_INCLUDE_STATUS: "EXPORT_INCLUDE_STATUS",

  EXPORT_APPLICATIONS_REQUEST: "EXPORT_APPLICATIONS_REQUEST",
  EXPORT_APPLICATIONS_SUCCESS: "EXPORT_APPLICATIONS_SUCCESS",
  EXPORT_APPLICATIONS_FAILURE: "EXPORT_APPLICATIONS_FAILURE",

  IMPORT_APPLICATIONS_BULK_REQUEST: "IMPORT_APPLICATIONS_BULK_REQUEST",
  IMPORT_APPLICATIONS_BULK_SUCCESS: "IMPORT_APPLICATIONS_BULK_SUCCESS",
  IMPORT_APPLICATIONS_BULK_FAILURE: "IMPORT_APPLICATIONS_BULK_FAILURE",

  IMPORT_APPLICATIONS_JOBS_REQUEST: "IMPORT_APPLICATIONS_JOBS_REQUEST",
  IMPORT_APPLICATIONS_JOBS_SUCCESS: "IMPORT_APPLICATIONS_JOBS_SUCCESS",
  IMPORT_APPLICATIONS_JOBS_FAILURE: "IMPORT_APPLICATIONS_JOBS_FAILURE",

  TOOLS_BULK_INIT_REQUEST: "TOOLS_BULK_INIT_REQUEST",
  TOOLS_BULK_INIT_SUCCESS: "TOOLS_BULK_INIT_SUCCESS",
  TOOLS_BULK_INIT_FAILURE: "TOOLS_BULK_INIT_FAILURE",

  SYNC_MUSONI_DATA_REQUEST: "SYNC_MUSONI_DATA_REQUEST",
  SYNC_MUSONI_DATA_SUCCESS: "SYNC_MUSONI_DATA_SUCCESS",
  SYNC_MUSONI_DATA_FAILURE: "SYNC_MUSONI_DATA_FAILURE",

  GET_RASTER_FILES: "GET_RASTER_FILES",
  GET_RASTER_FILES_SUCCESS: "GET_RASTER_FILES_SUCCESS",
  GET_RASTER_FILES_FAILURE: "GET_RASTER_FILES_FAILURE",

  ADD_RASTER_FILES_SUCCESS: "ADD_RASTER_FILES_SUCCESS",

  EDIT_RASTER_FILES_SUCCESS: "EDIT_RASTER_FILES_SUCCESS",
}

import React from 'react';
import slugify from 'slugify';
import { isEmpty, isObject } from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ClientEnhancedTableHead from '../../components/client/EnhancedTableHead';
import { makeStyles, Paper, TablePagination, Button } from '@material-ui/core';
import { pendingTasksActions } from './store/_actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        marginTop: '1.3rem',
        backgroundColor: '#fff',
        overflowX: 'scroll'
    },
    table: {
        minWidth: 700,
        padding: '10px',
        overflowX: 'auto',
        display: 'block',
        whiteSpace: 'nowrap',
    }
}));

export const PendingTasksList = ({ mfiId, data, setState, state, paginationData }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    let history = useHistory();
    const { t } = useTranslation();

    if (isEmpty(data)) {
        return null;
    }

    const colorCodes = (dataRow) => {
        if (dataRow === 1) {
            return '#eff9fe';
        } else if (dataRow === 2) {
            return '#fff6ee';
        } else if (dataRow === 3) {
            return '#d8eafc';
        } else if (dataRow === 4) {
            return '#edfbf6';
        } else if (dataRow === 5) {
            return '#e2a5f0';
        } else {
            return '#ff4d4d';
        }
    }

    const getSinglePendingTask = (row) => {
        dispatch(pendingTasksActions.getSinglePendingTask(mfiId, 10, 0, {
            "statusType": row?.status?.type,
            "loanOfficerName": row?.loanOfficerName
          }));
        history.push({
            pathname: '/clients/arrears',
            search: '',
            state: { pendingTasks: row }
        });
    }

    const handleChange = (event, page) => {
        setState({ ...state, page });
    };

    const handlePerPageChange = (event) => {
        setState({ ...state, perPage: event.target.value });
    };

    const generateRows = () => {
        return data.map((row) => {
                const meta = [];

                // handle the column data within each row
                const cells = Object.entries(row).map((colData, index) =>
                    <TableCell
                        key={index}
                        className={ `table-${slugify(colData[0], { replacement: '-' })}` }
                        style={{
                            backgroundColor: isObject(colData[1]) && colorCodes(colData[1]?.type),
                            border: isObject(colData[1]) && `1px solid ${colorCodes(colData[1]?.type)}`,
                        }}
                    >
                        {isObject(colData[1]) ? colData[1].label : colData[1]}
                    </TableCell>
                );

                meta.push(<TableCell><div style={{width: '100px'}}></div></TableCell>);
                meta.push(
                    <TableCell className="table-action tableAction">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => getSinglePendingTask(row)}
                        >
                            {t('module.client.pendingTask.view')}
                        </Button>
                    </TableCell>
                )

                cells.push(meta);

                return <TableRow hover role="checkbox" tabIndex={-1}>{cells}</TableRow>;
            });
    }

    return (
        <Paper className={classes.root}>
            <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                width="100%"
                style={{
                    display: 'table'
                }}
            >
                <ClientEnhancedTableHead data={data} isUniqueAction={true} />
                <TableBody>
                    { generateRows() }
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
                component="div"
                count={paginationData?.totalElements}
                rowsPerPage={paginationData?.numberOfElements}
                page={paginationData?.number}
                backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' }, // TODO: translate later
                    native: true,
                }}
                onPageChange={handleChange}
                labelRowsPerPage={t('module.client.arrears.rowsPerPage')}
                onRowsPerPageChange={handlePerPageChange}
            />
        </Paper>
    );
};

import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col } from "shards-react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  IconButton,
} from "@material-ui/core"
import { Delete, Edit } from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import PageTitle from "../../../components/common/PageTitle"
import { imagePanel } from "../../mfiManager/components/customization/Constants"
import { toolsActions } from ".."
import { modalActions } from "../../modals"
import { LoadingView } from "../../../views"

export const UploadRasterFile = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { rasterFiles, loading } = useSelector((store) => store.tools)

  useEffect(() => {
    dispatch(toolsActions.getRasterFiles())
  }, [dispatch])

  const addRasterFile = () => {
    dispatch(
      modalActions.manageCustomization({
        panel: imagePanel,
        raster: true,
      })
    )
  }

  const deleteRasterFile = (file) => {
    dispatch(toolsActions.deleteRasterFile(file))
  }

  const editRasterFile = (file) => {
    dispatch(
      modalActions.manageCustomization({
        panel: imagePanel,
        raster: true,
        hazardData: file,
      })
    )
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={t("module.tools.rasterfiles.title")}
          className="text-sm-left mb-3"
        />
      </Row>
      <Col md="12" lg="12" className="mt-4 p-0 relative w-full h-full">
        <Tabs>
          <TabList>
            <Tab>{t("module.tools.rasterfiles.details")}</Tab>
          </TabList>
          {loading && <LoadingView />}
          {!loading && (
            <TabPanel>
              <TableContainer component={Paper}>
                <Table aria-label="menu table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Country</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Long Title</TableCell>
                      <TableCell>Min Value</TableCell>
                      <TableCell>Max Value</TableCell>
                      <TableCell>Increment</TableCell>
                      <TableCell>Low Threshold</TableCell>
                      <TableCell>Medium Threshold</TableCell>
                      <TableCell>Tooltip</TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rasterFiles.map((file) => (
                      <TableRow key={file.id}>
                        <TableCell>{file.id}</TableCell>
                        <TableCell>{file.country}</TableCell>
                        <TableCell>{file.title}</TableCell>
                        <TableCell>{file.long_title}</TableCell>
                        <TableCell>{file.min_value}</TableCell>
                        <TableCell>{file.max_value}</TableCell>
                        <TableCell>{file.increment}</TableCell>
                        <TableCell>{file.stress_low}</TableCell>
                        <TableCell>{file.stress_medium}</TableCell>
                        <TableCell>{file.tooltip}</TableCell>
                        <TableCell>
                          <IconButton
                            color="secondary"
                            onClick={() => editRasterFile(file)}
                          >
                            <Edit />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="secondary"
                            onClick={() => deleteRasterFile(file)}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                onClick={addRasterFile}
                color="primary"
                variant="contained"
                className="my-3 mx-1"
              >
                {t("module.tools.rasterfiles.uploadFile")}
              </Button>
            </TabPanel>
          )}
        </Tabs>
      </Col>
    </Container>
  )
}

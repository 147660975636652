import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import InfoIcon from "@material-ui/icons/Info"
import { Tooltip } from "@material-ui/core"
import { Container, Row, Col, Button } from "shards-react"
import PageTitle from "../../../components/common/PageTitle"
import { publisherActions } from "../../publisher"
import { LoadingView } from "../../../views"

export const SyncExternalIntegration = () => {
  const mfis = useSelector((state) => state.authentication)
  const { loading, lastModifiedSyncTime } = useSelector(
    (state) => state.publishers
  )
  let tooltipTitle = ""

  if (lastModifiedSyncTime !== undefined) {
    const date = new Date(lastModifiedSyncTime)
    tooltipTitle = `Last coffee purchase sync time: ${date.toLocaleString()}`
  }

  const dispatch = useDispatch()

  /* const syncIntegrationData = () => {
      dispatch(toolsActions.syncMusoniData(mfis?.profile?.mfi?.id));
    }; */
  const syncIntegrationData = () => {
    dispatch(publisherActions.syncIncompleteCropinApplications())
  }

  const syncCropinApplications = () => {
    dispatch(publisherActions.syncCropinApplications())
  }

  const syncCoffeePurchases = () => {
    dispatch(publisherActions.fetchCoffeePurchasesCropin())
  }

  useEffect(() => {
    dispatch(publisherActions.getPurchaseSyncLastModified())
  }, [])

  return (
    <Container fluid className="main-content-container px-4">
      {loading && <LoadingView />}
      <Row noGutters className="page-header py-4">
        <PageTitle title="External Data Sync" className="text-sm-left mb-3" />
      </Row>
      <Col md="12" lg="12" className="mt-4 p-0 relative w-full h-full">
        <Button className="ml-auto" onClick={syncIntegrationData}>
          Sync Incomplete Applications
        </Button>
        <Button className="ml-2" onClick={syncCropinApplications}>
          Sync Applications
        </Button>
        <Button className="ml-2" onClick={syncCoffeePurchases}>
          Sync Coffee Purchases
        </Button>
        {lastModifiedSyncTime !== undefined && (
          <Tooltip title={tooltipTitle}>
            <InfoIcon color="secondary" />
          </Tooltip>
        )}
        <br />
        <br />
        <p>This will manually pull data automatically.</p>
      </Col>
    </Container>
  )
}

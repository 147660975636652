import React, { useRef, useState, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { Button, FormGroup, FormCheckbox } from "shards-react"
import {
  FormWithConstraints,
  Input,
} from "react-form-with-constraints-bootstrap4"
import { useTranslation } from "react-i18next"
import { modalActions } from "../../../modals"
import { cslActions } from "../../"
import { alertActions } from "../../../alert"

function DocumentSetup(props) {
  // get document checklist from store
  const {
    computationCodes,
    uid: surveyUuid,
    documentChecklist,
  } = props.scoreLogic
  const [blank, setBlank] = useState(false)
  const [inputs, setInputs] = useState({
    documentCode: "",
    description: "",
  })
  const [edit, setEdit] = useState("")
  const [result, setResult] = useState(props.result || "")
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const form = useRef(null)
  const code = useRef(null)

  useEffect(() => {
    dispatch(
      cslActions.addDocumentChecklistState({ computationCode: props.result })
    )
    setResult(props.result)
    // scroll to form
    if (props.result) {
      const form = document.getElementById("form")
      form.scrollIntoView()
    }
  }, [props.result, dispatch])

  useEffect(() => {
    dispatch(cslActions.addDocumentChecklistState({ edit: edit }))
  }, [edit, dispatch])

  useEffect(() => {
    dispatch(cslActions.addDocumentChecklistState({ blank: blank }))
  }, [blank, dispatch])

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputs((prevState) => ({ ...prevState, [name]: value }))
    dispatch(cslActions.addDocumentChecklistState({ [name]: value }))
  }

  const validateInputs = (payload) => {
    let valid = true
    Object.keys(payload).forEach((item) => {
      if (!payload[item]) {
        dispatch(
          alertActions.error(`${item} ${t("alertActions.report.emptyField")}`)
        )
        valid = false
      }
    })
    return valid
  }

  const handleSave = (e) => {
    let payload = inputs
    payload["computationCode"] = result
    if (!validateInputs(payload)) {
      return
    }
    let validPayload = payload
    // validPayload['blank'] = blank;
    const selectedComputationCode = computationCodes.filter((item) => {
      return item.questionCode === validPayload.computationCode
    })
    const questionKey = selectedComputationCode[0].questionKey
    const groups = []
    for (let i = 0; i < computationCodes.length; i++) {
      if (
        groups.length > 0 &&
        computationCodes[i].answerType === "end_repeat"
      ) {
        break
      }
      if (groups.length > 0 && computationCodes[i].answerType === "image") {
        groups.push(computationCodes[i])
      }
      if (
        computationCodes[i].questionKey === questionKey &&
        computationCodes[i].answerType === "begin_repeat"
      ) {
        groups.push(computationCodes[i])
      }
    }
    const documentChecklistGroups = groups
      .filter((item) => item.answerType === "image")
      .map((item) => ({ questionKey: item.questionKey }))
    if (documentChecklistGroups.length > 0)
      validPayload["documentChecklistGroups"] = documentChecklistGroups
    validPayload["questionKey"] = questionKey
    if (edit) {
      dispatch(cslActions.editDocumentChecklist(edit, validPayload))
      // scroll back to checklist being edited
      const list = document.getElementById("setup" + edit)
      list.scrollIntoView()
    } else {
      validPayload["surveyUuid"] = surveyUuid
      dispatch(cslActions.addDocumentChecklist(validPayload))
    }
    reset()
  }

  const promptEdit = (item) => {
    // put clicked content on the form
    setEdit(item.id)
    setInputs({
      documentCode: item.documentCode,
      description: item.description,
    })
    setResult(item.computationCode)
    // set result on the computationCodes component
    props.updateComputationCode(item.computationCode)
    // send data to be edited to the state
    dispatch(
      cslActions.addDocumentChecklistState({
        computationCode: item.computationCode,
      })
    )
    dispatch(
      cslActions.addDocumentChecklistState({ documentCode: item.documentCode })
    )
    dispatch(
      cslActions.addDocumentChecklistState({ description: item.description })
    )
    dispatch(
      cslActions.addDocumentChecklistState({ questionKey: item.questionKey })
    )
    // scroll to form
    const form = document.getElementById("form")
    form.scrollIntoView()
  }

  const promptDelete = (id) => {
    // prompt delete modal
    dispatch(modalActions.deleteDocumentChecklist({ id }))
  }

  const reset = () => {
    setInputs({
      documentCode: "",
      description: "",
    })
    setBlank(false)
    setEdit("")
    // set result on the computationCodes component
    props.resetComputationCodeFn()
  }

  return (
    <div className="formula-list items-group relative mb-2">
      <h4 className="mini-heading-top">
        {t("module.scoreLogic.view.scoreLogic.documentChecklist.documentSetup")}
      </h4>
      {documentChecklist &&
        documentChecklist.map((item, index) => (
          <div
            key={index}
            id={`setup${item.id}`}
            className="formula-list__item mx-6 pb-2"
          >
            <div className="formula-list__item--detail flex mb-4">
              <div className="label w-1/2">
                {t(
                  "module.scoreLogic.view.scoreLogic.documentChecklist.documentCode"
                )}
                :
              </div>{" "}
              <div className="detail">{item.documentCode}</div>
            </div>
            <div className="formula-list__item--detail flex mb-4">
              <div className="label w-1/2">
                {t(
                  "module.scoreLogic.view.scoreLogic.documentChecklist.description"
                )}
                :
              </div>{" "}
              <div className="detail">{item.description}</div>
            </div>
            <div className="formula-list__item--detail flex mb-4">
              <div className="label w-1/2">
                {t(
                  "module.scoreLogic.view.scoreLogic.documentChecklist.computationCode"
                )}
                :
              </div>{" "}
              <div className="detail">{item.computationCode}</div>
            </div>
            <div className="flex document-setup_button-group">
              <Button
                onClick={(e) => promptEdit(item)}
                theme="secondary"
                className="thin mr-2 edit"
              >
                {t("module.scoreLogic.view.scoreLogic.documentChecklist.Edit")}
              </Button>
              <Button
                theme="cancel"
                className="ml-auto thin delete"
                onClick={() => promptDelete(item.id)}
              >
                {t(
                  "module.scoreLogic.view.scoreLogic.documentChecklist.Delete"
                )}
              </Button>
            </div>
          </div>
        ))}

      <div id="form" className="add-tdcv px-6">
        <FormWithConstraints ref={form} noValidate className="shadow-none">
          <FormGroup>
            <div className="flex">
              <label>
                {t(
                  "module.scoreLogic.view.scoreLogic.documentChecklist.documentCode"
                )}{" "}
                *
              </label>
              <Input
                type="text"
                innerRef={code}
                className="form-control"
                name="documentCode"
                value={inputs.documentCode}
                required
                onChange={handleChange}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <div className="flex">
              <label>
                {t(
                  "module.scoreLogic.view.scoreLogic.documentChecklist.description"
                )}{" "}
                *
              </label>
              <Input
                type="text"
                className="form-control"
                name="description"
                value={inputs.description}
                required
                onChange={handleChange}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <label>
              {t(
                "module.scoreLogic.view.scoreLogic.documentChecklist.computationCode"
              )}{" "}
              *
            </label>
            <div className="items-holder flex flex-wrap">
              {result && (
                <div className="variable-item item item-computationCode">
                  {result}
                </div>
              )}
            </div>
          </FormGroup>

          <FormGroup>
            <FormCheckbox checked={blank} onChange={(e) => setBlank(!blank)}>
              {t(
                "module.scoreLogic.view.scoreLogic.documentChecklist.addBlank"
              )}
            </FormCheckbox>
          </FormGroup>

          <FormGroup className="flex document-setup_button-group">
            <Button
              onClick={() => reset()}
              theme="cancel"
              className="thin delete"
            >
              {t("module.scoreLogic.view.scoreLogic.documentChecklist.clear")}
            </Button>
            <Button
              onClick={(e) => handleSave(e)}
              theme="secondary"
              className="thin mr-2"
            >
              {t("module.scoreLogic.view.scoreLogic.documentChecklist.save")}
            </Button>
          </FormGroup>
        </FormWithConstraints>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { scoreLogic } = state
  return {
    scoreLogic,
  }
}

const connectedDocumentSetup = connect(mapStateToProps)(DocumentSetup)
export { connectedDocumentSetup as DocumentSetup }

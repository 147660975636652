import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, FormRadio } from 'shards-react';
import {
    FormWithConstraints,
} from 'react-form-with-constraints-bootstrap4';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AssumptionsTable } from './AssumptionsTable';
import { modalActions } from '../../../modals';

function AssumptionsList({ scoreLogic, dispatch }) {
    const { uid, assumptions } = scoreLogic;
    const surveyAssumptions = assumptions.filter((item) => item.surveyUuid === uid);
    const form = useRef(null);
    const [inputs, SetInputs] = useState({ type: 'numeric' });

    const handleChange = async ({ target, currentTarget }) => {
        const { name, value } = target;
        SetInputs((prevState) => ({ ...prevState, [name]: value }));
        // eslint-disable-next-line no-unused-expressions
        currentTarget.tagName !== 'LABEL' && await form.current.validateFields(currentTarget);
    };

    const { t } = useTranslation();

    return (
        <>
            <AssumptionsTable data={ surveyAssumptions } />
            { isEmpty(surveyAssumptions) && <div className="form-wrapper pb-2">
                <FormWithConstraints
                    ref={form}
                    noValidate
                    className="assumptions-add-new shadow-none"
                >
                    <label className="w-auto font-bold">{t('module.scoreLogic.component.assumptions.assumptionsList.select')}</label>
                    <FormGroup className="pl-3">
                        <FormRadio
                            checked={inputs.type === 'numeric'}
                            name="type"
                            className="form-control"
                            onChange={handleChange}
                            required
                            value="numeric"
                        >{t('module.scoreLogic.component.assumptions.assumptionsList.numeric')}</FormRadio>
                        <FormRadio
                            checked={inputs.type === 'matrix'}
                            name="type"
                            className="form-control"
                            onChange={handleChange}
                            value="matrix"
                        >{t('module.scoreLogic.component.assumptions.assumptionsList.matrix')}</FormRadio>
                    </FormGroup>
                </FormWithConstraints>
            </div> }
            <Button className="mt-3" onClick={ () => { dispatch(modalActions.addAssumption(inputs)); } }>
            {t('module.scoreLogic.component.assumptions.assumptionsList.addNew')}
            </Button>
        </>
    );
}

function mapStateToProps(state) {
    const { scoreLogic } = state;
    return { scoreLogic };
}

const connectedAssumptionsList = connect(mapStateToProps)(AssumptionsList);
export { connectedAssumptionsList as AssumptionsList };

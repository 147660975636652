import React, { useEffect } from 'react';
import { Container, Row, Col } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import PageTitle from '../../../components/common/PageTitle';
import { mfiManagerActions, MFITable } from '..';
import { userManagerActions } from '../../userManager';

import { LoadingView, ErrorsView, EmptyContentView } from '../../../views';
import { useTranslation } from 'react-i18next';

export const MFIListView = () => {
    const mfis = useSelector((state) => state.mfis);
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const { loading, items, error } = mfis;

    const tableRoles = items.map((item) => ({ ...{ action: '' }, ...item, ...{ status: 'active' } }));

    useEffect(() => {
        dispatch(mfiManagerActions.getAllMFIs());
        dispatch(userManagerActions.getAll());
    }, [dispatch]);

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header pt-4 flex">
                <PageTitle sm="4" title={t('module.mfiSetup.mfiSetupList')} className="text-sm-left" />
            </Row>
            <Row className="mt-4">
                <Col>
                    { loading && <LoadingView/> }
                    { error && <ErrorsView error={error}/> }
                    { !loading && !error && isEmpty(items) && <EmptyContentView title="No mfi found"/> }
                    { items && <MFITable data={tableRoles}/> }
                </Col>
            </Row>
        </Container>
    );
};

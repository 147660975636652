import React, { useState, forwardRef, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from 'react-i18next';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import Header from '../../../../../components/widget/widgetTitle';
import PdfViewer from '../modalComponents/viewClient/documentPreviewTypes/pdfviewer';
import AudioPlayer from '../modalComponents/viewClient/documentPreviewTypes/audioplayer';
import VideoPlayer from '../modalComponents/viewClient/documentPreviewTypes/videoplayer';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ClientDocumentPreview({ filename, documentUrl }) {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const [fileType, setFileTYpe] = useState(null)

    function extension(fname) {
        const pos = fname.lastIndexOf('.');
        const strlen = fname.length;
        if (pos !== -1 && strlen !== pos + 1) {
            const ext = fname.split('.');
            const len = ext.length;
            var extension = ext[len - 1].toLowerCase();
        } else {
            extension = t('module.client.arrears.action.modal.profile.noExtensionFound');
        }
        return extension;
    }

    function DisplayByType() {
        switch (fileType) {
            case 'pdf':
                return (
                    <PdfViewer modalTitle={filename} documentUrl={documentUrl}></PdfViewer>
                );
            case 'mp4':
                return (
                    <VideoPlayer documentUrl={documentUrl} />
                );
            case 'ogg':
            case 'mp3':
            case 'mogg':
                return (
                    <AudioPlayer documentUrl={documentUrl} />
                );
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'svg':
                return (
                    <img className="preview_file" src={documentUrl} alt={filename} loading="lazy" />
                );
            default:
                return (
                    <img className="preview_file" src={documentUrl} alt={filename} loading="lazy" />
                );
        }
    }

    useEffect(() => {
        extension(filename)
        setFileTYpe(extension(filename))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentUrl, filename])


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <button onClick={handleClickOpen}>{t('module.client.arrears.action.modal.preview')}</button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth={'lg'}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{ padding: 0 }} id="alert-dialog-slide-title">
                    <Header title={filename} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={handleClose} />
                </DialogTitle>
                <DialogContent style={{ margin: 'auto' }}>
                    {DisplayByType()}
                </DialogContent>
            </Dialog>
        </div>
    );
}

import React from 'react';

import LogoSVG from './../../assets/images/branding/logo.png';

export const Branding = () => (
    <div className="site-brand">
        <img
            className="branding-image mr-2"
            src={LogoSVG}
            alt="Financial Access Logo"
        />
    </div>
);

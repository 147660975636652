import React, { useState, useRef, useEffect } from "react"
import {
  FormWithConstraints,
  Input,
  FieldFeedbacks,
  FieldFeedback,
} from "react-form-with-constraints-bootstrap4"
// import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux"
import { Button, FormGroup } from "shards-react"
import { mfiManagerActions } from "../../_actions"

export function ScoreCardShare({ id, mfi }) {
  const form = useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(mfiManagerActions.getExternalIntegration(id))
  }, [dispatch, id])

  const [scoreCardShareUrl, setUrl] = useState()

  const handleChange = async ({ target, currentTarget }) => {
    const { value } = currentTarget
    setUrl(value)
    await form.current.validateFields(target)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    await form.current.validateForm()
    const formIsValid = form.current.isValid()

    if (!formIsValid) {
      return false
    }
    const payload = {
      scoreCardShareUrl,
      currency: mfi.currency,
    }

    dispatch(
      mfiManagerActions.updateMFIScoreUrl(Number(mfi?.id), payload, null)
    )

    return false
  }

  return (
    <FormWithConstraints
      ref={form}
      noValidate
      className="mfi-add-form shadow-none p-10"
      onSubmit={handleSubmit}
    >
      <h2 className="form-group-section">Score Card Share Url</h2>
      <div className="flex">
        <div className="w-1/2 pr-10">
          <div>
            <FormGroup>
              <label htmlFor="scoreCardShareUrl">Score Card Share Url</label>
              <Input
                type="url"
                as="url"
                id="scoreCardShareUrl"
                name="scoreCardShareUrl"
                className="form-control"
                required
                defaultValue={mfi.scoreCardShareUrl}
                onChange={handleChange}
              />
              <FieldFeedbacks for="scoreCardShareUrl">
                <FieldFeedback when="*" />
              </FieldFeedbacks>
              <p style={{ marginTop: "10px" }}>
                Example: <i>https://api.service.com/callback</i>
              </p>
            </FormGroup>
            <Button>{mfi.scoreCardShareUrl !== null ? "Edit" : "Add"}</Button>
          </div>
        </div>
      </div>
    </FormWithConstraints>
  )
}

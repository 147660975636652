import React from 'react';
import { Container } from 'shards-react';

export const EmptyContentView = ({ title }) => (
    <Container fluid className="main-content-container px-4 pb-4">
        <div className="error">
            <div className="error__content">
                <h2>{title}</h2>
            </div>
        </div>
    </Container>
);

import React, { useState, useEffect } from 'react';
import {
    Button, FormGroup,
} from 'shards-react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { history } from '../../../../_helpers';
import { mfiManagerActions } from '../..';

export function WorkFlowDataActions({ mfi }) {
    const { mfiCode } = mfi;
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        function shouldEnableSubmitButton() {
            return !submitButtonDisabled;
        }
        setSubmitButtonDisabled(!shouldEnableSubmitButton());
    }, [submitButtonDisabled, setSubmitButtonDisabled]);

    const handleCancel = () => {
        history.push('/mfis');
    };

    const submitData = () => {
        const messages = {
            addSuccess: t('alertActions.creditScore.addSuccess'),
            addFail: t('alertActions.creditScore.addError')
        }
        dispatch(mfiManagerActions.submitMFISubRoleLimitCategoryAndFlow(mfiCode, messages));
    };

    return (
        <FormGroup className="pb-10 action-group application-action-group">
            <div className="inline-flex">
                <Button theme="primary" className="mx-2 d-table ml-auto btn" type="submit" onClick={ () => submitData() } disabled={submitButtonDisabled}>{t('module.mfiSetup.actions.save')}</Button>
                <Button theme="secondary" className="mx-2 d-table ml-auto btn" type="submit" onClick={ () => handleCancel() }>{t('module.mfiSetup.actions.cancel')}</Button>
            </div>
        </FormGroup>
    );
}

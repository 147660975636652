import React from "react"
import { useSelector } from "react-redux"
import ReactApexChart from "react-apexcharts"
import { withStyles } from "@material-ui/core/styles"
import styles from "./Chart.styles"
import { CircularProgress, Typography } from "@material-ui/core"

export default withStyles(styles)(function ApexChart({
  cashFlow,
  classes,
  loading,
  type,
}) {
  const {
    profile: {
      mfi: { currency },
    },
  } = useSelector((store) => store.authentication)

  const getLabels = () => {
    return cashFlow?.map((cf) => cf.label)
  }

  const colors = getLabels()?.map((lb) =>
    lb.toLowerCase().includes("exp") ? "#FF0000" : "#008d6a"
  )

  const series = cashFlow?.map((cf) => Number(cf?.value[0]))

  const options = {
    chart: {
      width: "100%",
      type: "pie",
    },
    colors,
    labels: getLabels(),
    tooltip: {
      y: {
        formatter: (value) => `${currency} ${value.toLocaleString()}`,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  }

  if (loading)
    return (
      <div className={classes.noChartLoaded}>
        <CircularProgress />
      </div>
    )

  const seriesTotal = series?.reduce((ac, cv) => ac + cv, 0)

  if (!cashFlow || cashFlow?.length < 1 || seriesTotal === 0)
    return (
      <div className={classes.noChartLoaded}>
        <Typography className={classes.notAvailableText} variant="h5">
          {`${type} cash flow analysis not available`}
        </Typography>
      </div>
    )

  return (
    <div id="chart" className={classes.chartContainer}>
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        width="100%"
      />
    </div>
  )
})

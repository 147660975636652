import React from 'react';
import {
    Dropdown,
    DropdownToggle,
    Collapse,
    DropdownItem,
    NavLink
} from 'shards-react';
import i18next from 'i18next';

const LanguagesIconSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="#185597" fill-rule="nonzero" d="M22.235 18.265A11.944 11.944 0 0 0 24 12a11.944 11.944 0 0 0-1.765-6.265l-.006-.01a12 12 0 0 0-20.458 0l-.006.01a12.004 12.004 0 0 0 0 12.53l.006.01a12 12 0 0 0 20.458 0l.006-.01zm-8.701 3.678c-.229.221-.492.403-.78.539a1.759 1.759 0 0 1-1.509 0 3.673 3.673 0 0 1-1.361-1.198 10.726 10.726 0 0 1-1.55-3.363 58.607 58.607 0 0 1 7.332 0 13.537 13.537 0 0 1-.657 1.81 6.958 6.958 0 0 1-1.475 2.212zM1.372 12.674h4.84c.031 1.35.178 2.696.438 4.022a60.862 60.862 0 0 0-3.96.48 10.58 10.58 0 0 1-1.318-4.502zm1.318-5.85a60.08 60.08 0 0 0 3.96.48 23.77 23.77 0 0 0-.438 4.022h-4.84A10.58 10.58 0 0 1 2.69 6.824zm7.776-4.767c.229-.221.492-.403.78-.539a1.759 1.759 0 0 1 1.509 0c.546.28 1.014.692 1.361 1.198a10.726 10.726 0 0 1 1.55 3.363 58.612 58.612 0 0 1-7.332 0c.176-.618.395-1.223.657-1.81a6.958 6.958 0 0 1 1.475-2.212zm12.162 9.269h-4.84a23.814 23.814 0 0 0-.438-4.022 60.854 60.854 0 0 0 3.96-.48 10.58 10.58 0 0 1 1.318 4.502zM8.006 16.59a22.3 22.3 0 0 1-.445-3.917h8.879a22.295 22.295 0 0 1-.445 3.917 59.8 59.8 0 0 0-7.99 0zm7.988-9.182c.264 1.29.413 2.6.445 3.917H7.56c.033-1.316.181-2.627.445-3.917a59.822 59.822 0 0 0 7.99 0zm1.794 5.265h4.84a10.579 10.579 0 0 1-1.318 4.502 60.054 60.054 0 0 0-3.96-.48 23.77 23.77 0 0 0 .438-4.022zm2.713-7.09c-1.15.165-2.304.297-3.462.394a15.376 15.376 0 0 0-.802-2.264 9.568 9.568 0 0 0-1.116-1.9 10.65 10.65 0 0 1 5.38 3.77zM4.468 4.467a10.648 10.648 0 0 1 4.41-2.654l-.075.097a12.308 12.308 0 0 0-1.84 4.067 60.408 60.408 0 0 1-3.464-.395c.297-.393.62-.766.97-1.115zm-.97 13.949a58.221 58.221 0 0 1 3.462-.395c.208.775.477 1.532.803 2.264a9.569 9.569 0 0 0 1.116 1.9 10.65 10.65 0 0 1-5.38-3.77zm16.034 1.115a10.648 10.648 0 0 1-4.41 2.654l.075-.097a12.308 12.308 0 0 0 1.84-4.067c1.157.098 2.312.23 3.464.395a10.74 10.74 0 0 1-.97 1.115z"/>
    </svg>
)

export default class LanguageSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            activeLanguage: localStorage.getItem('i18nextLng'),
        };

        this.toggleNotifications = this.toggleNotifications.bind(this);
        this.handleClickLocale = this.handleClickLocale.bind(this);
    }

    toggleNotifications() {
        this.setState({
            visible: !this.state.visible,
        });
    }

    handleClickLocale(lang) {
        i18next.changeLanguage(lang);
        this.setState((prevState) => ({
            ...prevState,
            activeLanguage: lang,
        }));
    }

    render() {
        return (
            <Dropdown
                open={this.state.visible}
                toggle={this.toggleNotifications}
                className="border-right dropdown notifications language-selector"
            >
                <DropdownToggle caret tag={NavLink} className="flex text-nowrap relative lang_selector">
                    <div className="nav-link-icon__wrapper language-selector__wrapper">
                        <LanguagesIconSVG />
                        &nbsp;
                        <p>
                            {(() => {
                                switch (this.state.activeLanguage) {
                                    case 'en': return 'English';
                                    case 'fr': return 'French';
                                    case 'my': return 'ဗမာ';
                                    default: return 'English';
                                }
                            })()}
                        </p>
                    </div>
                </DropdownToggle>
                <Collapse
                    className="dropdown-menu-small"
                    right small open={this.state.visible}
                >
                    <DropdownItem>
                        <div className="notification__icon-wrapper" onClick={() => this.handleClickLocale('en')}>
                            English
                        </div>
                    </DropdownItem>
                    <DropdownItem>
                        <div className="notification__icon-wrapper" onClick={() => this.handleClickLocale('fr')}>
                            French
                        </div>
                    </DropdownItem>
                    <DropdownItem>
                        <div className="notification__icon-wrapper" onClick={() => this.handleClickLocale('my')}>
                            ဗမာ
                        </div>
                    </DropdownItem>
                </Collapse>
            </Dropdown>
        );
    }
}

// add thousand mark commas
const formatNumber = num => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

const tableSort = (dataType, mutableData, key, dir) => {
  // check if column has some data
  const emptyCheck = mutableData.filter((item) => !item[key] && key);
  if (emptyCheck.length === mutableData.length) {
    return null;
  }

  if (dataType === 'NUMBER') {
    let numberOnlyData = mutableData;
    mutableData.forEach((item, i) =>
      mutableData[i][key]
        ? numberOnlyData[i][key] = numberOnlyData[i][key].toString().includes(",") ? numberOnlyData[i][key].replace(/,/g, '') : numberOnlyData[i][key]
        : numberOnlyData[i][key] = 0
    );

    numberOnlyData.sort((a, b) => dir === 'DESC' ? a[key] - b[key] : b[key] - a[key]);
    numberOnlyData.forEach((item, i) =>
      numberOnlyData[i][key]
        ? numberOnlyData[i][key] = formatNumber(numberOnlyData[i][key])
        : numberOnlyData[i][key] = ''
    );
    mutableData = numberOnlyData;
  } else if (dataType === 'ID') {
    mutableData.forEach((item, i) => (!mutableData[i][key]) && (mutableData[i][key] = ''));
    mutableData.sort((a, b) => dir === 'DESC' ? a[key] - b[key] : b[key] - a[key]);
  } else if (dataType === 'TEXT') {
    mutableData.forEach((item, i) => (!mutableData[i][key]) && (mutableData[i][key] = ''));
    mutableData.sort((a, b) => {
      if (dir === 'ASC') {
        if (a[key] < b[key]) { return -1; }
        if (a[key] > b[key]) { return 1; }
      } else {
        if (a[key] > b[key]) { return -1; }
        if (a[key] < b[key]) { return 1; }
      }
     // names must be equal
     return 0;
   })
  }
  return mutableData;
}

export default tableSort;

import { surveyConstants } from './_constants';

const initialState = {};

export const surveys = (state = initialState, action) => {
    switch (action.type) {
    case surveyConstants.GET_SURVEY_REQUEST:
      return {
          ...state,
          error: null,
          loading: true
      };
    case surveyConstants.GET_SURVEY_SUCCESS:
        return {
            ...state,
            error: null,
            loading: false,
            assets: action.data,
        };
    case surveyConstants.GET_SURVEY_FAILURE:
        return {
            ...state,
            error: true,
            loading: false
        };


    default:
        return state;
    }
};

import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { withStyles } from "@material-ui/core"
import { Button } from "shards-react"
import { modalActions } from "../../../modals"
import { mfiManagerActions } from "../.."
import styles from "./CustomizationList.style"
import ListTable from "./ListTable"

export default withStyles(styles)(function AddMpesaCustomizationAction({
  mfiId,
  classes,
  panel,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { mpesaDetails = [] } = useSelector((store) => store.mfis)

  useEffect(() => {
    dispatch(mfiManagerActions.getMpesaDetails(mfiId))
  }, [dispatch, mfiId])

  const addCustomization = () => {
    dispatch(
      modalActions.manageCustomization({
        mfiId,
        panel,
      })
    )
  }

  return (
    <>
      <ListTable data={mpesaDetails} mfiId={mfiId} panel={panel} />
      {mpesaDetails.length < 1 && (
        <Button
          className={classes.listContainer}
          theme="primary"
          onClick={() => addCustomization()}
        >
          {t("module.mfiSetup.addCustomization.mpesaTitle")}
        </Button>
      )}
    </>
  )
})

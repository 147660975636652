export const configConstants = {
  CONFIG_QUESTIONNAIRE_UID: "CONFIG_QUESTIONNAIRE_UID",

  CONFIG_APPLICATION_COLUMN_STATE_ADD_SUCCESS:
    "CONFIG_APPLICATION_COLUMN_STATE_ADD_SUCCESS",

  CONFIG_APPLICATION_COLUMN_GET_REQUEST:
    "CONFIG_APPLICATION_COLUMN_GET_REQUEST",
  CONFIG_APPLICATION_COLUMN_GET_SUCCESS:
    "CONFIG_APPLICATION_COLUMN_GET_SUCCESS",
  CONFIG_APPLICATION_COLUMN_GET_FAILURE:
    "CONFIG_APPLICATION_COLUMN_GET_FAILURE",

  CONFIG_REPORT_COLUMN_STATE_ADD_SUCCESS:
    "CONFIG_REPORT_COLUMN_STATE_ADD_SUCCESS",

  CONFIG_REPORT_COLUMN_GET_REQUEST: "CONFIG_REPORT_COLUMN_GET_REQUEST",
  CONFIG_REPORT_COLUMN_GET_SUCCESS: "CONFIG_REPORT_COLUMN_GET_SUCCESS",
  CONFIG_REPORT_COLUMN_GET_FAILURE: "CONFIG_REPORT_COLUMN_GET_FAILURE",

  CONFIG_PROFIE_FIELD_GET_REQUEST: "CONFIG_PROFIE_FIELD_GET_REQUEST",
  CONFIG_PROFIE_FIELD_GET_SUCCESS: "CONFIG_PROFIE_FIELD_GET_SUCCESS",
  CONFIG_PROFIE_FIELD_GET_FAILURE: "CONFIG_PROFIE_FIELD_GET_FAILURE",

  CONFIG_CBS_FIELD_GET_REQUEST: "CONFIG_CBS_FIELD_GET_REQUEST",
  CONFIG_CBS_FIELD_GET_SUCCESS: "CONFIG_CBS_FIELD_GET_SUCCESS",
  CONFIG_CBS_FIELD_GET_FAILURE: "CONFIG_CBS_FIELD_GET_FAILURE",

  CONFIG_CBS_FIELD_SAVE_REQUEST: "CONFIG_CBS_FIELD_SAVE_REQUEST",
  CONFIG_CBS_FIELD_SAVE_SUCCESS: "CONFIG_CBS_FIELD_SAVE_SUCCESS",
  CONFIG_CBS_FIELD_SAVE_FAILURE: "CONFIG_CBS_FIELD_SAVE_FAILURE",

  CONFIG_CBS_FIELD_EDIT_REQUEST: "CONFIG_CBS_FIELD_EDIT_REQUEST",
  CONFIG_CBS_FIELD_EDIT_SUCCESS: "CONFIG_CBS_FIELD_EDIT_SUCCESS",
  CONFIG_CBS_FIELD_EDIT_FAILURE: "CONFIG_CBS_FIELD_EDIT_FAILURE",

  CONFIG_CBS_FIELD_DELETE_REQUEST: "CONFIG_CBS_FIELD_DELETE_REQUEST",
  CONFIG_CBS_FIELD_DELETE_SUCCESS: "CONFIG_CBS_FIELD_DELETE_SUCCESS",
  CONFIG_CBS_FIELD_DELETE_FAILURE: "CONFIG_CBS_FIELD_DELETE_FAILURE",

  CONFIG_APPLICATION_COLUMN_SAVE_REQUEST:
    "CONFIG_APPLICATION_COLUMN_SAVE_REQUEST",
  CONFIG_APPLICATION_COLUMN_SAVE_SUCCESS:
    "CONFIG_APPLICATION_COLUMN_SAVE_SUCCESS",
  CONFIG_APPLICATION_COLUMN_SAVE_FAILURE:
    "CONFIG_APPLICATION_COLUMN_SAVE_FAILURE",

  CONFIG_REPORT_COLUMN_SAVE_REQUEST: "CONFIG_REPORT_COLUMN_SAVE_REQUEST",
  CONFIG_REPORT_COLUMN_SAVE_SUCCESS: "CONFIG_REPORT_COLUMN_SAVE_SUCCESS",
  CONFIG_REPORT_COLUMN_SAVE_FAILURE: "CONFIG_REPORT_COLUMN_SAVE_FAILURE",

  CONFIG_PROFIE_FIELD_SAVE_REQUEST: "CONFIG_PROFIE_FIELD_SAVE_REQUEST",
  CONFIG_PROFILE_FIELD_SAVE_SUCCESS: "CONFIG_PROFILE_FIELD_SAVE_SUCCESS",
  CONFIG_PROFILE_FIELD_SAVE_FAILURE: "CONFIG_PROFILE_FIELD_SAVE_FAILURE",

  CONFIG_APPLICATION_COLUMN_EDIT_REQUEST:
    "CONFIG_APPLICATION_COLUMN_EDIT_REQUEST",
  CONFIG_APPLICATION_COLUMN_EDIT_SUCCESS:
    "CONFIG_APPLICATION_COLUMN_EDIT_SUCCESS",
  CONFIG_APPLICATION_COLUMN_EDIT_FAILURE:
    "CONFIG_APPLICATION_COLUMN_EDIT_FAILURE",

  CONFIG_REPORT_COLUMN_EDIT_REQUEST: "CONFIG_REPORT_COLUMN_EDIT_REQUEST",
  CONFIG_REPORT_COLUMN_EDIT_SUCCESS: "CONFIG_REPORT_COLUMN_EDIT_SUCCESS",
  CONFIG_REPORT_COLUMN_EDIT_FAILURE: "CONFIG_REPORT_COLUMN_EDIT_FAILURE",

  CONFIG_PROFILE_FIELD_EDIT_REQUEST: "CONFIG_PROFILE_FIELD_EDIT_REQUEST",
  CONFIG_PROFILE_FIELD_EDIT_SUCCESS: "CONFIG_PROFILE_FIELD_EDIT_SUCCESS",
  CONFIG_PROFILE_FIELD_EDIT_FAILURE: "CONFIG_PROFILE_FIELD_EDIT_FAILURE",

  CONFIG_APPLICATION_COLUMN_DELETE_REQUEST:
    "CONFIG_APPLICATION_COLUMN_DELETE_REQUEST",
  CONFIG_APPLICATION_COLUMN_DELETE_SUCCESS:
    "CONFIG_APPLICATION_COLUMN_DELETE_SUCCESS",
  CONFIG_APPLICATION_COLUMN_DELETE_FAILURE:
    "CONFIG_APPLICATION_COLUMN_DELETE_FAILURE",

  CONFIG_REPORT_COLUMN_DELETE_REQUEST: "CONFIG_REPORT_COLUMN_DELETE_REQUEST",
  CONFIG_REPORT_COLUMN_DELETE_SUCCESS: "CONFIG_REPORT_COLUMN_DELETE_SUCCESS",
  CONFIG_REPORT_COLUMN_DELETE_FAILURE: "CONFIG_REPORT_COLUMN_DELETE_FAILURE",

  CONFIG_PROFILE_FIELD_DELETE_REQUEST: "CONFIG_PROFILE_FIELD_DELETE_REQUEST",
  CONFIG_PROFILE_FIELD_DELETE_SUCCESS: "CONFIG_PROFILE_FIELD_DELETE_SUCCESS",
  CONFIG_PROFILE_FIELD_DELETE_FAILURE: "CONFIG_PROFILE_FIELD_DELETE_FAILURE",

  CONFIG_CREDIT_SCORE_REPORT_SETUP_STATE_ADD_SUCCESS:
    "CONFIG_CREDIT_SCORE_REPORT_SETUP_STATE_ADD_SUCCESS",

  CONFIG_CREDIT_SCORE_REPORT_SETUP_GET_REQUEST:
    "CONFIG_CREDIT_SCORE_REPORT_SETUP_GET_REQUEST",
  CONFIG_CREDIT_SCORE_REPORT_SETUP_GET_SUCCESS:
    "CONFIG_CREDIT_SCORE_REPORT_SETUP_GET_SUCCESS",
  CONFIG_CREDIT_SCORE_REPORT_SETUP_GET_FAILURE:
    "CONFIG_CREDIT_SCORE_REPORT_SETUP_GET_FAILURE",

  CONFIG_CREDIT_SCORE_REPORT_SETUP_SAVE_REQUEST:
    "CONFIG_CREDIT_SCORE_REPORT_SETUP_SAVE_REQUEST",
  CONFIG_CREDIT_SCORE_REPORT_SETUP_SAVE_SUCCESS:
    "CONFIG_CREDIT_SCORE_REPORT_SETUP_SAVE_SUCCESS",
  CONFIG_CREDIT_SCORE_REPORT_SETUP_SAVE_FAILURE:
    "CONFIG_CREDIT_SCORE_REPORT_SETUP_SAVE_FAILURE",

  CONFIG_CREDIT_SCORE_REPORT_SETUP_EDIT_REQUEST:
    "CONFIG_CREDIT_SCORE_REPORT_SETUP_EDIT_REQUEST",
  CONFIG_CREDIT_SCORE_REPORT_SETUP_EDIT_SUCCESS:
    "CONFIG_CREDIT_SCORE_REPORT_SETUP_EDIT_SUCCESS",
  CONFIG_CREDIT_SCORE_REPORT_SETUP_EDIT_FAILURE:
    "CONFIG_CREDIT_SCORE_REPORT_SETUP_EDIT_FAILURE",

  CONFIG_CREDIT_SCORE_REPORT_SETUP_DELETE_REQUEST:
    "CONFIG_CREDIT_SCORE_REPORT_SETUP_DELETE_REQUEST",
  CONFIG_CREDIT_SCORE_REPORT_SETUP_DELETE_SUCCESS:
    "CONFIG_CREDIT_SCORE_REPORT_SETUP_DELETE_SUCCESS",
  CONFIG_CREDIT_SCORE_REPORT_SETUP_DELETE_FAILURE:
    "CONFIG_CREDIT_SCORE_REPORT_SETUP_DELETE_FAILURE",

  CONFIG_ADD_FIRST_COMMENT_REQUEST: "CONFIG_ADD_FIRST_COMMENT_REQUEST",
  CONFIG_ADD_FIRST_COMMENT_SUCCESS: "CONFIG_ADD_FIRST_COMMENT_SUCCESS",
  CONFIG_ADD_FIRST_COMMENT_FAILURE: "CONFIG_ADD_FIRST_COMMENT_FAILURE",

  CONFIG_GET_FIRST_COMMENTS_REQUEST: "CONFIG_GET_FIRST_COMMENTS_REQUEST",
  CONFIG_GET_FIRST_COMMENTS_SUCCESS: "CONFIG_GET_FIRST_COMMENTS_SUCCESS",
  CONFIG_GET_FIRST_COMMENTS_FAILURE: "CONFIG_GET_FIRST_COMMENTS_FAILURE",

  CONFIG_EDIT_FIRST_COMMENT_REQUEST: "CONFIG_EDIT_FIRST_COMMENT_REQUEST",
  CONFIG_EDIT_FIRST_COMMENT_SUCCESS: "CONFIG_EDIT_FIRST_COMMENT_SUCCESS",
  CONFIG_EDIT_FIRST_COMMENT_FAILURE: "CONFIG_EDIT_FIRST_COMMENT_FAILURE",

  CONFIG_DELETE_FIRST_COMMENT_REQUEST: "CONFIG_DELETE_FIRST_COMMENT_REQUEST",
  CONFIG_DELETE_FIRST_COMMENT_SUCCESS: "CONFIG_DELETE_FIRST_COMMENT_SUCCESS",
  CONFIG_DELETE_FIRST_COMMENT_FAILURE: "CONFIG_DELETE_FIRST_COMMENT_FAILURE",

  GET_REPORTS_QUESTION_REQUEST: "GET_REPORTS_QUESTION_REQUEST",
  GET_REPORTS_QUESTION_SUCCESS: "GET_REPORTS_QUESTION_SUCCESS",
  GET_REPORTS_QUESTION_FAILURE: "GET_REPORTS_QUESTION_FAILURE",

  UPDATE_APPLICATION_GENDER_STATE: "UPDATE_APPLICATION_GENDER_STATE",

  UPDATE_APPLICATION_AGE_STATE: "UPDATE_APPLICATION_AGE_STATE",

  UPDATE_APPLICATION_MARITAL_STATUS_STATE:
    "UPDATE_APPLICATION_MARITAL_STATUS_STATE",

  UPDATE_APPLICATION_LOAN_STATE: "UPDATE_APPLICATION_LOAN_STATE",

  UPDATE_NEW_APPLICATION_STATE: "UPDATE_NEW_APPLICATION_STATE",

  UPDATE_NEW_APPLICATION_CUSTOMER_PROFILE_STATE:
    "UPDATE_NEW_APPLICATION_CUSTOMER_PROFILE_STATE",
  UPDATE_APPLICATION_CUSTOMER_PROFILE_STATE:
    "UPDATE_APPLICATION_CUSTOMER_PROFILE_STATE",

  GET_CUSTOMER_PROFILE_QUESTION_REQUEST:
    "GET_CUSTOMER_PROFILE_QUESTION_REQUEST",
  GET_CUSTOMER_PROFILE_QUESTION_SUCCESS:
    "GET_CUSTOMER_PROFILE_QUESTION_SUCCESS",
  GET_CUSTOMER_PROFILE_QUESTIONN_FAILURE:
    "GET_CUSTOMER_PROFILE_QUESTION_FAILURE",

  UPDATE_NEW_LAND_SIZE_STATE: "UPDATE_NEW_LAND_SIZE_STATE",

  ADD_REPORT_QUESTION_REQUEST: "ADD_REPORT_QUESTION_REQUEST",
  ADD_REPORT_QUESTION_SUCCESS: "ADD_REPORT_QUESTION_SUCCESS",
  ADD_REPORT_QUESTION_FAILURE: "ADD_REPORT_QUESTION_FAILURE",
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Navbar, NavbarBrand } from 'shards-react';
import { NavbarNav } from './NavbarNav/NavbarNav';

const MainNavbar = ({ stickyTop }) => {
    const isHeaderNav = false;
    const classes = classNames(
        'main-navbar',
        'bg-white',
        stickyTop && 'sticky-top',
    );

    return (
        <div className={classes}>
            <Navbar type="light" className="align-items-stretch flex-md-nowrap">
                {isHeaderNav && (
                    <NavbarBrand href="#" style={{ lineHeight: '25px' }}>
                        <div className="d-table m-auto">
                            <span className="d-none d-md-inline ml-1">LendXS</span>
                        </div>
                    </NavbarBrand>
                )}
                <NavbarNav />
            </Navbar>
        </div>
    );
};

MainNavbar.propTypes = {
    /**
    * Whether the main navbar is sticky to the top, or not.
    */
    stickyTop: PropTypes.bool,
};

MainNavbar.defaultProps = {
    stickyTop: true,
};

export default MainNavbar;

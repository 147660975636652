import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TooltipTrigger from 'react-popper-tooltip';
import { clientActions } from '../../../_actions';
import { modalActions } from '../../../../modals';
import { useTranslation } from 'react-i18next';

export function TableRowActionsAssignTo({ id, mfiCode, refetchClients, userId }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { clientAssignees } = useSelector((state) => state.clients);


    useEffect(() => {
        dispatch(clientActions.getClientAssignees(mfiCode))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mfiCode])

    const AssignTo = (name, identification ) => {
        const payload = {
            mfiId: mfiCode,
            loanId: id,
            userId: identification,
            name

        }
        dispatch(modalActions.clientAssignTo({ id, mfiCode, payload, refetchClients }));
}

function Trigger({ getTriggerProps, triggerRef }) {
    return (
        <div
            {...getTriggerProps({
                ref: triggerRef,
                // className: 'trigger dropdown-toggle btn btn-primary',
            })}
        >
            {t('module.client.arrears.action.assignTo')}
        </div>
    );
}

function Tooltip({
    getTooltipProps,
    getArrowProps,
    tooltipRef,
    arrowRef,
    placement,
}) {
    return (
        <div
            {...getTooltipProps({
                ref: tooltipRef,
                className: 'tooltip-container table-actions',
            })}
        >
            <div
                {...getArrowProps({
                    ref: arrowRef,
                    'data-placement': placement,
                    className: 'tooltip-arrow',
                })}
            />
            <div className="tooltip-body">
                <div className="dropdown dropdown-right">{items}</div>
            </div>
        </div>
    );
}

const items = (
    <div className="dropdown-item">
        {(clientAssignees !== null) ? (
            <>
                {clientAssignees && clientAssignees.map((assignee) =>
                (
                    <>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="#" onClick={() => AssignTo(assignee.name, assignee.id)}>{assignee.name}</a> <br />
                    </>
                ))}
            </>
        ) : <p style={{color: '#000'}}>{t('module.client.arrears.action.noData')}</p>}

        {/* eslint-disable jsx-a11y/anchor-is-valid */}
    </div>
);



return (
    <>
        {items && <TooltipTrigger placement="right" trigger="click" tooltip={Tooltip}>
            {Trigger}
        </TooltipTrigger>}
    </>
);
}

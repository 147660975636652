import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import slugify from 'slugify';

import { isEmpty, sortBy } from 'lodash';
import { TableRowsActions } from './AssumptionsTableActions';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        backgroundColor: '#fff',
    },
    table: {
        minWidth: 700,
        padding: '10px',
    },
});

class EnhancedTable extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'title',
        data: [],
        actions: [],
        page: 0,
        rowsPerPage: 10,
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    generateRows() {
        const { data } = this.props;
        const { rowsPerPage, page } = this.state;
        const orderedData = sortBy(data, 'code');

        if (isEmpty(orderedData)) {
            return null;
        }

        return orderedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, idx) => {
                const rowTable = { ...row };
                const { id } = row;

                const meta = [];
                meta.push(<TableCell key="1000" className="table-action" align="right"><TableRowsActions id={id}/></TableCell>);

                delete rowTable.surveyUuid;
                delete rowTable.name;
                delete rowTable.id;
                delete rowTable.matrix;
                delete rowTable.parentFormulaCode;

                const cells = Object.entries(rowTable).map((colData, idx2) => <TableCell key={ idx2 } className={ `table-${slugify(colData[0], { replacement: '-' })}` }>{colData[1]}</TableCell>);

                cells.push(meta);

                return <TableRow hover tabIndex={-1} key={ idx }>{cells}</TableRow>;
            });
    }

    render() {
        const { classes, data, t } = this.props;
        const { rowsPerPage, page } = this.state;

        if (isEmpty(data)) {
            return null;
        }

        return (
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <TableHead>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell>{t('module.scoreLogic.component.manageAssumption.description')}</TableCell>
                                <TableCell>{t('module.scoreLogic.component.manageAssumption.code')}</TableCell>
                                <TableCell>{t('module.scoreLogic.component.manageAssumption.value')}</TableCell>
                                <TableCell>{t('module.scoreLogic.component.manageAssumption.type')}</TableCell>
                                <TableCell align="right">{t('module.scoreLogic.component.manageAssumption.action')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { this.generateRows() }
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                    nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                    onPageChange={this.handleChangePage}
                    labelRowsPerPage={t('module.client.arrears.rowsPerPage')}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

EnhancedTable.propTypes = {
    data: PropTypes.array.isRequired,
};

const AssumptionsTable = withTranslation()(withStyles(styles)(EnhancedTable));

export { AssumptionsTable };

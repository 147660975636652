import React, { useState, useEffect, useSelector } from "react"
import { Container, Row, Col } from "shards-react"
import { isEmpty, forEach } from "lodash"
import { connect } from "react-redux"
import { useLocation } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { reportsActions } from "../../reports"

import {
  applicationActions,
  ApplicationTable,
  ApplicationTableFilters,
  ApplicationsExport,
} from ".."
import { formatDate } from "../../../utils/FormatDate"
import PageTitle from "../../../components/common/PageTitle"
import { ErrorsView, LoadingView, EmptyContentView } from "../../../views"
import { userRoleConstants, hasAuthority } from "../../../_helpers"
import { FilterApplicationQuestionnaire } from "../components/filterQuestionnaire"

const ListApplication = ({
  applications,
  authentication,
  reports,
  assets,
  loading,
  assinging,
  error,
  dispatch,
  t,
}) => {
  const { uid, applicationColumns, applicationClicked } = applications
  const { profile } = authentication
  const { subRole } = profile

  // format grossed up and gender fields (or any other yes or no question) from thier integer values to readable string
  applications.items &&
    applications.items.forEach((item) => {
      if (item.json["Grossed Up"] !== undefined) {
        item.json["Grossed Up"] =
          item.json["Grossed Up"] === "0" || item.json["Grossed Up"] === "Yes"
            ? "Yes"
            : "No"
      }
      if (item.json["Gender"] !== undefined) {
        item.json["Gender"] =
          item.json["Gender"] === "0" || item.json["Gender"] === "Male"
            ? "Male"
            : "Female"
      }
    })

  const [sortStatus, setSortStatus] = useState(applications.filterState.status)
  const { hierarchy, mfiIdInput } = reports
  const [officeConfig, setOfficeConfig] = useState([])
  const [searchInput, setSearchInput] = useState("")

  useEffect(() => {
    if (hierarchy) {
      const hierarchyLayer = hierarchy.filter(
        (item, i) =>
          item.name.localeCompare("Branch", undefined, {
            sensitivity: "accent",
          }) === 0
      )
      if (hierarchyLayer && hierarchyLayer.length > 0) {
        setOfficeConfig(hierarchyLayer[0].entities)
      }
    }
  }, [hierarchy])

  useEffect(() => {
    // get hierarchy data.
    if (
      hasAuthority(userRoleConstants.FA_ANALYST) ||
      hasAuthority(userRoleConstants.FA_ADMIN)
    ) {
      if (mfiIdInput) {
        dispatch(reportsActions.getHierarchy(mfiIdInput))
      }
    } else if (profile.mfi) {
      dispatch(reportsActions.getHierarchy(profile.mfi.id))
    }
  }, [dispatch, profile, mfiIdInput])

  useEffect(() => {
    setSortStatus(applications.filterState.status)
  }, [applications.filterState.status])

  const [state, setState] = useState({
    uid,
    results: [],
    actions: ["assign", "edit", "view-credit-score"],
    filters: [
      "date",
      "name",
      "surveyStatus",
      "_date",
      "_name",
      "_instanceid",
      "_id",
      "_uuid",
      "_status",
      "deviceid",
      "_notes",
      "formhub/uuid",
      "_submitted_by",
      "today",
      "end",
      "start",
      "__version__",
      "_userform_id",
      "_meta/instanceID",
      "meta/instanceID",
      "meta/instanceName",
      "_bamboo_dataset_id",
      "_validation_status",
    ],
  })

  const location = useLocation()
  const [locationData, setLocationData] = useState(location.state)
  const paramUid = locationData?.pageUid
  const showAllMySubmission = locationData?.all

  useEffect(() => {
    if (paramUid) {
      setState((prevState) => ({
        ...prevState,
        uid: paramUid,
      }))
    }
  }, [location])

  const sleep = (milliseconds) =>
    new Promise((resolve) => setTimeout(resolve, milliseconds))

  useEffect(() => {
    state.uid && dispatch(applicationActions.getApplicationColumns(state.uid))
  }, [state.uid, dispatch])
  /**
   * Set Questionnaire uid and Get formulas by UID
   */
  useEffect(() => {
    state.uid && dispatch(applicationActions.setQuestionnaire(state.uid))
  }, [state.uid, dispatch])

  // set first questionaire uid by default
  useEffect(() => {
    !applicationClicked.history &&
      assets &&
      assets.length > 0 &&
      !paramUid &&
      setState((prevState) => ({
        ...prevState,
        uid: `${assets[0].uid}:${assets[0].versionId}`,
      }))
  }, [assets, applicationClicked.history])

  /**
   * Set questionnaire on select
   */
  useEffect(() => {
    /**
     * Get applications from store and filter by UID
     * Reorder actions and status
     *
     * @return {Array} results
     */
    state.uid &&
      dispatch(applicationActions.getApplicationBySurveyUuid(state.uid))
  }, [state.uid, state.filters, state.actions, dispatch])

  const useData =
    applications?.searchApplications !== undefined
      ? applications.searchApplications
      : applications?.items

  /**
   * Set questionnaire on select
   */
  useEffect(() => {
    // const useData = applications?.items
    let results = useData?.map((application) => {
      const {
        id,
        stage,
        submissionTime,
        submittedBy,
        surveyStatus,
        workflowStatus,
        json,
        xformIdString,
        version,
        currentUser,
        approvalAmount,
        subRoleStageFinal,
        subRoleStageSecondFinal,
        // approvalLoanTenor,
      } = application

      const rowData = {
        id,
        _stage: stage,
        _submissionDate: formatDate(new Date(submissionTime), "y-m-d"),
        _submittedBy: submittedBy,
        currentUser,
        _surveyStatus: surveyStatus,
        _workflowStatus: workflowStatus,
        approvalAmount,
        // approvalLoanTenor,
        ...json,
        _xformId: xformIdString,
        _xformversion: version,
        subRoleStageFinal,
        subRoleStageSecondFinal,
        actions: state.actions,
        actions2: state.actions,
        action: "",
      }
      const row = {}

      /**
       * Validate data can be displayed on table, check if string or number, otherwise discard.
       * filter data based on users
       */
      if (
        (applications.filterState.offices.length === 0 &&
          applications.filterState.group.length === 0 &&
          (applications.filterState.user === rowData.currentUser ||
            (showAllMySubmission &&
              applications.filterState.user === rowData._submittedBy))) /*&&
          officeConfig
            ?.map((office) => office.name)
            .includes(rowData["Group"]))*/ ||
        applications.filterState.user === ""
      ) {
        forEach(rowData, (item, key) => {
          if (state.filters.includes(key)) {
            return
          }

          if (
            typeof item === "string" ||
            typeof item === "number" ||
            typeof item === "boolean"
          ) {
            row[key] = item
          }
        })
      }

      /**
       * Filter based on offices/branches
       * Getting the branch from the json
       */
      if (
        applications.filterState.offices.length > 0 &&
        applications.filterState.user === rowData.currentUser &&
        applications.filterState.offices.includes(
          rowData["Branch"] // The application column config for branch MUST match the key i.e. Branch
        )
      ) {
        forEach(rowData, (item, key) => {
          if (state.filters.includes(key)) {
            return
          }

          if (
            typeof item === "string" ||
            typeof item === "number" ||
            typeof item === "boolean"
          ) {
            row[key] = item
          }
        })
      }

      /**
       * Filter based on groups
       * Getting the group from the json
       */
      if (
        applications.filterState.group.length > 0 &&
        applications.filterState.user === rowData.currentUser &&
        applications.filterState.group.includes(
          rowData["Group"] // The application column config for branch MUST match the key i.e. Group
        )
      ) {
        forEach(rowData, (item, key) => {
          if (state.filters.includes(key)) {
            return
          }

          if (
            typeof item === "string" ||
            typeof item === "number" ||
            typeof item === "boolean"
          ) {
            row[key] = item
          }
        })
      }

      return row
    })

    // remove empty objects from results
    results =
      results && results.filter((value) => Object.keys(value).length !== 0)

    /**
     * THIS IS A HACK :(
     *
     * HACKY way to rerender react-virtualized with same number of rows
     *
     * First we clear the results, the compenent will render a blank table, we wait, 1ms then add new data. Redrawing the table.
     * Works for now :) but need improvement.
     */
    localStorage.setItem(
      "staticApplicationsData",
      JSON.stringify({ ...results })
    )
    setState((prevState) => ({ ...prevState, results: {} }))

    if (isEmpty(results)) {
      // store tableData in the local storage
      localStorage.setItem("tableData", JSON.stringify({}))
    }

    sleep(1).then(() => {
      setState((prevState) => ({ ...prevState, results }))
    })
  }, [applications, state.actions, state.filters, useData])

  /**
   * Select questionnaire
   *
   * @param {*} e Event
   */
  const selectQuestionnaire = (e) => {
    const { value } = e.target
    setState((prevState) => ({ ...prevState, uid: value }))
    setLocationData({ pageUid: null, all: null })
    setSearchInput("")
  }

  const [filteredData, setFilteredData] = useState(state.results) // Initialize filteredData with state.results

  // Effect to update filteredData whenever state.results changes
  useEffect(() => {
    setFilteredData(state.results)
  }, [state.results])

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header pt-4 flex">
        <PageTitle
          sm="4"
          title={t("module.applicationManager.view.list.pageTitle")}
          className="text-sm-left"
        />
      </Row>
      <Row noGutters className="page-header pt-4 flex">
        <FilterApplicationQuestionnaire
          handler={selectQuestionnaire}
          selectedValue={state.uid}
        />
        <div className="application__actions ml-auto flex content-center">
          <ApplicationTableFilters
            className="table-filters ml-auto"
            selectedValue={state.uid}
            data={state.results}
            setFilteredData={setFilteredData}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          {(hasAuthority(userRoleConstants.MFI_SUPERVISOR) ||
            hasAuthority(userRoleConstants.MFI_CREDIT_ANALYST)) && (
            <ApplicationsExport className="btn-actions ml-2" />
          )}
        </div>
      </Row>
      <Row>
        <Col className="mt-4">
          {!error &&
            !loading &&
            state.uid &&
            (isEmpty(state.results) ||
              (applications?.searchApplications !== undefined &&
                applications?.searchApplications.length < 1)) && (
              <EmptyContentView
                title={t("module.applicationManager.view.list.notFound")}
              />
            )}
          {!isEmpty(error) && <ErrorsView error={error} />}
          {(loading || assinging) && <LoadingView />}
          {!isEmpty(filteredData) && (
            <ApplicationTable
              uid={state.uid}
              dateColumnFilters={applications.dateColumnFilters}
              applications={applications.items}
              data={filteredData} // Use filteredData instead of state.results
              applicationColumns={applicationColumns}
              actions={state.actions}
              hasWorkflow={!isEmpty(subRole)}
              sortStatus={sortStatus}
              assets={assets}
              group={applications.filterState.group}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => {
  const { applications, authentication, surveys, reports, workflow } = state
  const { assets, loading, error } = surveys
  const { loading: assinging } = workflow
  return {
    applications,
    authentication,
    assets,
    reports,
    loading,
    error,
    assinging,
  }
}

const connectedListApplication = withTranslation()(
  connect(mapStateToProps)(ListApplication)
)
export { connectedListApplication as ListApplication }

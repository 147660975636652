import { pendingTasksConstants } from "./_constants";
import { PendingTasksService } from "./_service";

/**
 *
 * @param {string} mfiId
 * @param {interger} size
 * @param {interger} page
 * @returns
 */
const getAllPendingTasks = (mfiId, size, page) => {
  return (dispatch) => {
    dispatch(request({}));
    PendingTasksService.getAllPendingTasks(mfiId, size, page).then(
      (res) => {
        dispatch(success(res));
        // dispatch(alertActions.success(message.success));
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(message.error));
      }
    );
  };

  function request() {
    return { type: pendingTasksConstants.GETALL_PENDING_TASKS_REQUEST };
  }
  function success(data) {
    return { type: pendingTasksConstants.GETALL_PENDING_TASKS_SUCCESS, data };
  }
  function failure(error) {
    return { type: pendingTasksConstants.GETALL_PENDING_TASKS_FAILURE, error };
  }
};

/**
 *
 * @param {string} mfiId
 * @param {interger} size
 * @param {interger} page
 * @returns
 */
 const getBMAllPendingTasks = (mfiId, size, page) => {
  return (dispatch) => {
    dispatch(request({}));
    PendingTasksService.getBMAllPendingTasks(mfiId, size, page).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: pendingTasksConstants.GETALL_BM_PENDING_TASKS_REQUEST };
  }
  function success(data) {
    return { type: pendingTasksConstants.GETALL_BM_PENDING_TASKS_SUCCESS, data };
  }
  function failure(error) {
    return { type: pendingTasksConstants.GETALL_BM_PENDING_TASKS_FAILURE, error };
  }
};

/**
 *
 * @param {string} mfiId
 * @param {interger} size
 * @param {interger} page
 * @param {object} payload
 * @returns
 */
 const getSinglePendingTask = (mfiId, size, page, payload) => {
    return (dispatch) => {
      dispatch(request({}));
      PendingTasksService.getSinglePendingTask(mfiId, size, page, payload).then(
        (res) => {
          dispatch(success(res));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
    };

    function request() {
      return { type: pendingTasksConstants.GET_SINGLE_PENDING_TASK_REQUEST };
    }
    function success(data) {
      return { type: pendingTasksConstants.GET_SINGLE_PENDING_TASK_SUCCESS, data };
    }
    function failure(error) {
      return { type: pendingTasksConstants.GET_SINGLE_PENDING_TASK_FAILURE, error };
    }
  };

export const pendingTasksActions = {
    getAllPendingTasks,
    getSinglePendingTask,
    getBMAllPendingTasks,
};

import React, { useState, useEffect } from "react"
import PersonOutlineOutlinedIcon from "@material-ui/icons/LockOutlined"
import { connect } from "react-redux"
import { Button, FormGroup, FormInput, FormSelect } from "shards-react"
import { isEmpty } from "lodash"
import { useTranslation } from "react-i18next"

import { modalActions } from "../../modals"
import { cslActions } from ".."
import Header from "../../../components/widget/widgetTitle"
import { ComputationCodesSetup } from "../views/Formula/ComputationCodes"
import { Assumptions } from "../views/Formula/Assumptions"
import { OtherCalculations } from "../views/Formula/OtherCalculations"
import { Tdcv } from "../views/Formula/Tdcv"
import { CashFlowVariable } from "../component/CashFlowVariable"

const AddScoringVariablesVariable = ({
  scoreLogic,
  modalID,
  data,
  dispatch,
}) => {
  const { t } = useTranslation()
  const {
    id,
    types,
    codes: selectedCodes,
    selectOne = {
      code: true,
      assumptions: true,
      tdcv: false,
      emcv: false,
      otherCalculations: true,
    },
  } = data
  const [inputs, setInputs] = useState(data)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)

  useEffect(() => {
    function shouldEnableResetButton(fields) {
      return !isEmpty(fields.codes)
    }
    setSubmitButtonDisabled(!shouldEnableResetButton(inputs))
  }, [inputs])

  const childComponentHandler = (type, payload) => {
    let codes = payload[type]
    if (payload[type].length > 1) {
      codes = payload[type][payload[type].length - 1]
    }
    setInputs((prevState) => ({ ...prevState, codes }))
  }

  const scoringVariablesDataTypes = [
    {
      label: "Text",
      value: "TEXT",
    },
    {
      label: "Numeric",
      value: "NUMERIC",
    },
  ]

  const addSelection = () => {
    dispatch(cslActions.updateScoringVariables(id, inputs))
    closeModal()
  }

  const handleChange = async (e) => {
    const { name, value } = e.target
    setInputs((prevState) => ({ ...prevState, [name]: value }))
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  return (
    <div className="site-modal cashflow-statements__add-variable">
      <Header
        title={"Select item"}
        icon={<PersonOutlineOutlinedIcon />}
        close={true}
        closeHandler={closeModal}
      />

      <div className="w-full p-3">
        <div className="block">
          <FormGroup>
            <label htmlFor="title">Title</label>
            <FormInput
              name="title"
              size="lg"
              value={inputs.title ?? ""}
              className="mb-2"
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="dataType">
              {t(
                "module.scoreLogic.modal.addScoringVariablesEntry.scorecardDataTypeLabel"
              )}
            </label>
            <FormSelect
              name="dataType"
              size="sm"
              className={"select"}
              onChange={handleChange}
            >
              <option key={-1} value="">
                {t(
                  "module.scoreLogic.modal.addScoringVariablesEntry.scorecardDataType"
                )}
              </option>
              {scoringVariablesDataTypes.map((item) => {
                return (
                  <option
                    key={item.value}
                    selected={inputs.dataType === item.value}
                    value={item.value}
                  >
                    {item.label}
                  </option>
                )
              })}
            </FormSelect>
          </FormGroup>
          {types.includes("code") && (
            <div className="flex">
              <ComputationCodesSetup
                scoreLogic={scoreLogic}
                modalID={modalID}
                content={data}
                handler={childComponentHandler}
                selected={inputs.computationCodes ?? inputs.codes}
                selectOne={selectOne.code}
              />
            </div>
          )}

          {types.includes("assumptions") && (
            <div className="flex">
              <Assumptions
                scoreLogic={scoreLogic}
                modalID={modalID}
                content={data}
                handler={childComponentHandler}
                selected={selectedCodes}
                selectOne={selectOne.assumptions}
              />
            </div>
          )}

          {types.includes("tdcv") && (
            <div className="flex">
              <Tdcv
                type="TIME_DEPENDENT_CALCULATED_VARIABLE"
                scoreLogic={scoreLogic}
                modalID={modalID}
                content={data}
                handler={childComponentHandler}
                selected={selectedCodes}
                selectOne={selectOne.tdcv}
              />
            </div>
          )}

          {types.includes("emcv") && (
            <div className="flex">
              <Tdcv
                type="EVERY_MONTH_CALCULATED_VARIABLE"
                scoreLogic={scoreLogic}
                modalID={modalID}
                content={data}
                handler={childComponentHandler}
                selected={selectedCodes}
                selectOne={selectOne.emcv}
              />
            </div>
          )}

          {types.includes("otherCalculations") && (
            <div className="flex">
              <OtherCalculations
                type="SINGLE_CALCULATION"
                scoreLogic={scoreLogic}
                modalID={modalID}
                content={data}
                handler={childComponentHandler}
                selected={selectedCodes}
                selectOne={selectOne.otherCalculations}
              />
            </div>
          )}

          {types.includes("cashflowVariable") && (
            <div className="flex">
              <CashFlowVariable
                type="CASHFLOW_VARIABLE"
                scoreLogic={scoreLogic}
                modalID={modalID}
                content={data}
                handler={childComponentHandler}
                selected={selectedCodes}
                selectOne={selectOne.cashflowVariable}
              />
            </div>
          )}
        </div>
        <div className="flex">
          <Button
            theme="primary"
            disabled={submitButtonDisabled}
            className="ml-auto"
            onClick={() => {
              addSelection()
            }}
          >
            Add Selection
          </Button>
          <Button
            theme="secondary"
            className="ml-2"
            onClick={() => {
              closeModal()
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { scoreLogic, modals } = state
  return {
    scoreLogic,
    modals,
  }
}

const connectedAddScoringVariablesVariable = connect(mapStateToProps)(
  AddScoringVariablesVariable
)
export { connectedAddScoringVariablesVariable as AddScoringVariablesVariable }

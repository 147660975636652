import React from "react"
import PropTypes from "prop-types"
import { Nav } from "shards-react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { find } from "lodash"

import { MenuItem } from "./MenuItem"
import { publicRoutes, privateRoutes } from "../../../../routes"

class HeaderNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.allRoutes = { ...publicRoutes, ...privateRoutes }
  }

  getPathRoles(item) {
    const { to } = item
    const route = find(this.allRoutes, { path: to })

    if (!route) {
      return false
    }
    const { roles } = route

    return roles
  }

  render() {
    const { settings, location } = this.props
    const { navItems: items } = settings

    const menu = find(items[0].items, { to: location.pathname })

    let menu1 = null

    if (location.pathname === "/applications") {
      menu1 = find(items[0].items, { to: "/credit-score" })
    }

    return (
      <div className="w-100 header-navbar d-lg-flex p-0 bg-white border-top">
        <Nav tabs className="border-0 flex-column flex-lg-row h-100 pl-2">
          {menu &&
            menu.headerMenus &&
            menu.headerMenus.map((item, idx) => (
              <MenuItem key={idx} item={item} roles={this.getPathRoles(item)} />
            ))}
          <MenuItem />
        </Nav>
        <Nav tabs className="border-0 flex-column flex-lg-row h-100 pl-2">
          {menu1 &&
            menu1.headerMenus &&
            menu1.headerMenus.map((item, idx) => (
              <MenuItem key={idx} item={item} roles={this.getPathRoles(item)} />
            ))}
          <MenuItem />
        </Nav>
      </div>
    )
  }
}

HeaderNavbar.propTypes = {
  /**
   * The array of header navbar items.
   */
  items: PropTypes.array,
}

const mapStateToProps = (state) => {
  const { settings } = state
  return { settings }
}

const connectedHeaderNavbar = withRouter(connect(mapStateToProps)(HeaderNavbar))
export { connectedHeaderNavbar as HeaderNavbar }

import Chart from "chart.js";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import CardTitle from "./cardTitle";

export const TasksChart = ({ data }) => {
  const canvasRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    const dataGraph = new Chart(canvasRef.current, {
      type: "horizontalBar",
      data: {
        labels: data?.map(a => a?.date),
        datasets: [
          {
            label: t('module.client.overview.pendingChartLabel'),
            data: data?.map(a => a?.pendingTasks),
            backgroundColor: '#32D296',
            borderRadius: '150px',
            width: '4px',
          },
          {
            label: t('module.client.overview.daysLateChartLabel'),
            data: data?.map(a => a?.daysLate),
            backgroundColor: '#1E87F0',
            borderRadius: 15
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        indexAxis: 'y',
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        }
      },
    });

    // Render the chart.
    dataGraph.render();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="graph"
      style={{
        height: '40vh',
        maxHeight: '700px',
        width: '50%',
        backgroundColor: 'white',
        boxShadow: '1px 3px 3px #888888'
      }}
    >
      <CardTitle title={t('module.client.overview.pendingTasksDueDate')} />
      <br />
      <canvas
        height="120"
        ref={canvasRef}
        style={{
          maxWidth: "100% !important",
          width: "100% ",
          backgroundColor: 'white',
          boxShadow: '1px 3px 3px #888888'
        }}
      />
    </div>
  );
};

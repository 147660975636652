import { axiosInstance, handleResponse } from "../../_helpers/base.service"
const serviceGroup = "workflow"

/**
 * Export functions as in one module
 *
 */
export const configService = {
  getApplicationColumns,
  addApplicationColumn,
  editProfieFields,
  addProfileField,
  deleteProfileField,
  getProfileFields,
  editApplicationColumn,
  editReportColumn,
  deleteReportColumn,
  getReportColumns,
  addReportColumn,
  getCreditScoreReportSetup,
  deleteApplicationColumn,
  getCreditScoreReportSetup,
  addCreditScoreReportSetup,
  editCreditScoreReportSetup,
  deleteCreditScoreReportSetup,
  addFirstComment,
  getFirstComments,
  editFirstComment,
  deleteFirstComments,
  getReportQuestion,
  addReportQuestion,
  editReportQuestion,
  searchApplications,
  getProfileQuestion,
  addProfileQuestion,
  addCbsField,
  editCbsField,
  deleteCbsField,
  getCbsFields,
}

/**
 * add customer profile question
 *
 * @param data object
 */
function addProfileQuestion(data) {
  return axiosInstance
    .post(`${serviceGroup}/client-profile-mapping`, data)
    .then(handleResponse)
}

/**
 * edit customer profile question
 *
 * @param data object
 */
function getProfileQuestion(id) {
  return axiosInstance
    .get(`${serviceGroup}/client-profile-mapping?surveyUuid=${id}`)
    .then(handleResponse)
}

/**
 * Get ApplicationColumns
 *
 * @param id
 */
function getApplicationColumns(surveyUuid) {
  return axiosInstance
    .get(`${serviceGroup}/application-listed-columns/`, {
      params: {
        surveyUuid,
      },
    })
    .then(handleResponse)
}

/**
 * Get ReportColumns
 *
 * @param id
 */
function getReportColumns(surveyUuid) {
  return axiosInstance
    .get(`${serviceGroup}/report-listed-columns/`, {
      params: {
        surveyUuid,
      },
    })
    .then(handleResponse)
}

/**
 * Save ApplicationColumn
 *
 * @param data object
 */
function addApplicationColumn(data) {
  return axiosInstance
    .post(`${serviceGroup}/application-listed-columns/`, data)
    .then(handleResponse)
}

/**
 * Save ReportColumn
 *
 * @param data object
 */
function addReportColumn(data) {
  return axiosInstance
    .post(`${serviceGroup}/report-listed-columns/`, data)
    .then(handleResponse)
}

/**
 * edit ApplicationColumn
 *
 * @param data object
 */
function editApplicationColumn(id, data) {
  delete data.edit
  return axiosInstance
    .patch(
      `${serviceGroup}/application-listed-columns/application-listed-column/${id}`,
      data
    )
    .then(handleResponse)
}

/**
 * edit ReportColumn
 *
 * @param data object
 */
function editReportColumn(id, data) {
  delete data.edit
  return axiosInstance
    .patch(`${serviceGroup}/report-listed-columns/report-column/${id}`, data)
    .then(handleResponse)
}

/**
 * Delete ApplicationColumn
 *
 * @param id
 */
function deleteApplicationColumn(id) {
  return axiosInstance
    .delete(
      `${serviceGroup}/application-listed-columns/application-listed-column/${id}`
    )
    .then(handleResponse)
}

/**
 * Delete ReportColumn
 *
 * @param id
 */
function deleteReportColumn(id) {
  return axiosInstance
    .delete(`${serviceGroup}/report-listed-columns/report-column/${id}`)
    .then(handleResponse)
}

/**
 * Get profile fields
 *
 * @param id
 */
function getProfileFields(surveyUuid) {
  return axiosInstance
    .get(`${serviceGroup}/profile-listed-fields/`, {
      params: {
        surveyUuid,
      },
    })
    .then(handleResponse)
}

/**
 * Save Profile Field
 *
 * @param data object
 */
function addProfileField(data) {
  return axiosInstance
    .post(`${serviceGroup}/profile-listed-fields/`, data)
    .then(handleResponse)
}

/**
 * edit Profile Field
 *
 * @param data object
 */
function editProfieFields(id, data) {
  delete data.edit
  return axiosInstance
    .patch(
      `${serviceGroup}/profile-listed-fields/profile-listed-field/${id}`,
      data
    )
    .then(handleResponse)
}

/**
 * Delete Profile Field
 *
 * @param id
 */
function deleteProfileField(id) {
  return axiosInstance
    .delete(`${serviceGroup}/profile-listed-fields/profile-listed-field/${id}`)
    .then(handleResponse)
}

/**
 * Get CBS fields
 *
 * @param id
 */
function getCbsFields(surveyUuid) {
  return axiosInstance
    .get(`${serviceGroup}/cbs-fields/`, {
      params: {
        surveyUuid,
      },
    })
    .then(handleResponse)
}

/**
 * Save CBS Field
 *
 * @param data object
 */
function addCbsField(data) {
  return axiosInstance
    .post(`${serviceGroup}/cbs-fields/`, data)
    .then(handleResponse)
}

/**
 * edit CBS Field
 *
 * @param data object
 */
function editCbsField(id, data) {
  delete data.edit
  return axiosInstance
    .patch(`${serviceGroup}/cbs-fields/cbs-field/${id}`, data)
    .then(handleResponse)
}

/**
 * Delete CBS Field
 *
 * @param id
 */
function deleteCbsField(id) {
  return axiosInstance
    .delete(`${serviceGroup}/cbs-fields/cbs-field/${id}`)
    .then(handleResponse)
}

/**
 * Get Credit score setup
 *
 * @param id
 */
function getCreditScoreReportSetup(surveyUuid) {
  return axiosInstance
    .get("/creditscore/credit-score-report-configurations", {
      params: {
        surveyUuid,
      },
    })
    .then(handleResponse)
}

/**
 * Save Credit score setup
 *
 * @param data object
 */
function addCreditScoreReportSetup(data) {
  return axiosInstance
    .post("/creditscore/credit-score-report-configurations", data)
    .then(handleResponse)
}

/**
 * edit Credit score setup
 *
 * @param data object
 */
function editCreditScoreReportSetup(id, data) {
  delete data.edit
  return axiosInstance
    .patch(
      `/creditscore/credit-score-report-configurations/credit-score-report-configuration/${id}`,
      data
    )
    .then(handleResponse)
}

/**
 * Delete Credit score setup
 *
 * @param id
 */
function deleteCreditScoreReportSetup(id) {
  return axiosInstance
    .delete(
      `/creditscore/credit-score-report-configurations/credit-score-report-configuration/${id}`
    )
    .then(handleResponse)
}

/**
 * Save First comment setup
 *
 * @param data object
 */
function addFirstComment(data) {
  return axiosInstance
    .post(`${serviceGroup}/survey-first-comment-configuration`, data)
    .then(handleResponse)
}

/**
 * Get First comments
 *
 * @param id
 */
function getFirstComments(surveyUuid) {
  return axiosInstance
    .get(`${serviceGroup}/survey-first-comment-configuration`, {
      params: {
        surveyUuid,
      },
    })
    .then(handleResponse)
}

/**
 * edit First comment setup
 *
 * @param data id
 */
function editFirstComment(surveyUuid, data) {
  return axiosInstance
    .patch(
      `${serviceGroup}/survey-first-comment-configuration/${surveyUuid}`,
      data
    )
    .then(handleResponse)
}

/**
 * delete First comments
 *
 * @param id
 */
function deleteFirstComments(id) {
  // prevent removal of content-type
  const data = { data: null }
  return axiosInstance
    .delete(`${serviceGroup}/survey-first-comment-configuration/${id}`, data)
    .then(handleResponse)
}

/**
 * Get report question
 *
 * @param id
 */
function getReportQuestion(surveyUuid) {
  return axiosInstance
    .get(`${serviceGroup}/report-columns`, {
      params: {
        surveyUuid,
      },
    })
    .then(handleResponse)
}

/**
 * Save report question
 *
 * @param data object
 */
function addReportQuestion(data) {
  return axiosInstance
    .post(`${serviceGroup}/report-columns/report-column`, data)
    .then(handleResponse)
}

/**
 * edit report question
 *
 * @param data id
 */
function editReportQuestion(id, data) {
  return axiosInstance
    .patch(`${serviceGroup}/report-columns/report-column/${id}`, data)
    .then(handleResponse)
}

/**
 * SEARCH APPLICATIONS
 *
 * @param {*} surveyUuid
 * @param {*} query
 *
 */
function searchApplications(surveyUuid, query) {
  return axiosInstance
    .get(
      `${serviceGroup}/applications/?surveyUuid=${surveyUuid}&query=${query}`
    )
    .then(handleResponse)
}

import { authenticationConstants } from "./_constants"
import { authenticationService } from "./_service"

const user = JSON.parse(localStorage.getItem("user"))
const rememberMe = localStorage.getItem("rememberMe")

const initialState = user ? { loggedIn: true, user, profile: {} } : {}

export const authentication = (
  state = { ...initialState, rememberMe, resetEmailSuccess: false },
  action
) => {
  switch (action.type) {
    case authenticationConstants.USER_LOGIN_REMEMBER_ME: {
      return {
        ...state,
        rememberMe: action.data,
      }
    }
    case authenticationConstants.USERS_LOGIN_REQUEST: {
      return {
        ...state,
        loggingIn: true,
        user: { ...action.user },
      }
    }
    case authenticationConstants.USERS_LOGIN_SUCCESS: {
      const { exp } = action.user
      const expiresAt = exp * 1000 + new Date().getTime()
      return {
        ...state,
        loggedIn: true,
        expiresAt,
        user: { ...action.user },
      }
    }
    case authenticationConstants.USERS_LOGIN_FAILURE: {
      // eslint-disable-next-line no-case-declarations
      const data = action.error
      return {
        ...state,
        loggedIn: false,
        user: {},
        profile: {},
        expiresAt: "",
        error: data,
      }
    }
    case authenticationConstants.USER_LOGOUT: {
      authenticationService.logout()
      // clear store
      return initialState
    }
    case authenticationConstants.USER_PROFILE_SUCCESS: {
      return {
        ...state,
        profile: { ...action.data },
      }
    }
    case authenticationConstants.USER_RESET_LINK_SUCCESS: {
      return {
        ...state,
        resetEmailSuccess: true,
      }
    }
    case authenticationConstants.USER_RESET_SMS_SUCCESS: {
      return {
        ...state,
        resetSmsSuccess: true,
      }
    }
    default: {
      return state
    }
  }
}

import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "shards-react"
import { isEmpty } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { Select, MenuItem } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import { withTranslation } from "react-i18next"
import cx from "classnames"
import { applicationActions, ApplicationPaymentTable } from ".."
import PageTitle from "../../../components/common/PageTitle"
import { ErrorsView, LoadingView, EmptyContentView } from "../../../views"
import { userRoleConstants, hasAuthority } from "../../../_helpers"
import styles from "./ListPayment.styles"
import { modalActions } from "../../modals"

const STATUS = {
  "My Pending Approvals": "RM_APPROVAL",
  "RM Pending Approvals": "RM_APPROVAL",
  "Pending Disbursements": "PENDING_DISBURSEMENT",
  Incomplete: "INCOMPLETE",
  Disbursed: "DISBURSED",
  Declined: "DECLINED",
}

const ListPayment = ({ t, classes }) => {
  const { payments, loading, error, purchaseActiveFilters } = useSelector(
    (state) => state.applications
  )

  const [filterValue, setFilterValue] = useState("filterPayments")

  const [filterdPurchasePayments, setFilterdPurchasePayments] = useState([])

  const { profile } = useSelector((state) => state.authentication)
  const { username, hierarchyEntity, roles } = profile

  const topHierarchyEntityId = hierarchyEntity?.topHierarchyEntityId

  const headOfficeAcc =
    topHierarchyEntityId === null && roles?.includes("MFI_CREDIT_ANALYST")

  const managementAccountant = headOfficeAcc && username === "ugacof_namayanja"

  const defaultUsername = headOfficeAcc ? "" : username

  const dispatch = useDispatch()

  const [selectedTabTextState, setSelectedTabTextState] = useState("")

  useEffect(() => {
    if (defaultUsername !== undefined)
      dispatch(applicationActions.fetchPayments(defaultUsername))
  }, [username])

  useEffect(() => {
    setFilterdPurchasePayments(
      payments.filter((payment) =>
        purchaseActiveFilters.length === 0
          ? true
          : purchaseActiveFilters.includes(payment[filterValue].toLowerCase())
      )
    )
  }, [payments, purchaseActiveFilters])

  const [downloadPayments, setDownloadPayments] = useState([])
  const [searchInput, setSearchInput] = useState("")

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value)
  }

  const handleSubmitSearch = () => {
    if (searchInput === "") {
      dispatch(applicationActions.fetchPayments(defaultUsername))
    } else {
      dispatch(applicationActions.searchPayments(searchInput))
    }
  }

  const handleKeyPressOnSubmit = (e) => {
    if (e.keyCode === 13) {
      if (searchInput === "") {
        dispatch(applicationActions.fetchPayments(defaultUsername))
      } else {
        dispatch(applicationActions.searchPayments(searchInput))
      }
    }
  }

  function getUniqueByKey(arr, key) {
    const seen = new Set()
    return arr.filter((obj) => {
      const keyValue = obj[key]
      if (keyValue === "") return false
      if (!seen.has(keyValue)) {
        seen.add(keyValue)
        return true
      }
      return false
    })
  }

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value
    setFilterValue(selectedValue)
    if (selectedValue === "reset" || selectedValue === "filterPayments") {
      dispatch(applicationActions.filterPayments([]))
      setFilterValue("filterPayments")
    } else {
      const uniqueData = getUniqueByKey(payments, selectedValue)
      const filterData = uniqueData.map((payment) => payment[selectedValue])
      dispatch(
        modalActions.purchaseFilter({
          filterData,
          selectedValue,
        })
      )
    }
  }

  const handleDownloadPayments = (e) => {
    const value = e.target.value
    if (value !== "xlsx" && value !== "csv") return
    dispatch(
      applicationActions.generatePaymentsSummaryDownload(
        value,
        selectedTabTextState
      )
    )
  }

  const setTabText = () => {
    const selectedTabText = document.getElementsByClassName(
      "react-tabs__tab--selected"
    )[0]?.innerText
    const status = STATUS[selectedTabText]

    setSelectedTabTextState(status)
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header pt-4 flex">
        <PageTitle
          sm="4"
          title={t("module.applicationManager.view.list.pageTitlePayment")}
          className="text-sm-left"
        />
      </Row>
      <Row noGutters className="page-header pt-4 flex">
        <div className="application__actions ml-auto flex content-center">
          {(hasAuthority(userRoleConstants.MFI_SUPERVISOR) ||
            hasAuthority(userRoleConstants.MFI_CREDIT_ANALYST)) && (
            <Select
              classes={{ root: classes.selectRoot }}
              variant="outlined"
              value={filterValue}
              className={cx("ml-2")}
              onChange={handleSelectChange}
            >
              <MenuItem value="filterPayments">Filter Payments</MenuItem>
              <MenuItem value="fieldOfficer">By Field Officer</MenuItem>
              <MenuItem value="cwsName">By Branch</MenuItem>
              <MenuItem value="reset">Reset</MenuItem>
            </Select>
          )}
          <div
            style={{
              display: "flex",
              paddingRight: "10px",
            }}
          >
            <input
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              style={{
                fontFamily: "inherit",
                fontSize: "inherit",
                marginLeft: 10,
                paddingTop: "2px",
                padding: "4px 12px",
                borderRadius: "4px 0 0 4px",
                border: "1px solid #185597",
                outline: "none",
              }}
              className="inputSearch"
              onChange={handleSearchInput}
              onKeyDown={handleKeyPressOnSubmit}
              autoFocus
            />
            <span
              style={{
                borderRadius: "0 3px 3px 0",
                borderTop: "1px solid",
                borderRight: "1px solid",
                borderBottom: "1px solid",
                marginLeft: "-2px",
                paddingLeft: "4px",
              }}
            >
              <SearchIcon
                style={{
                  color: "#a1b6ca",
                  marginTop: "4px",
                  cursor: "pointer",
                }}
                onClick={handleSubmitSearch}
              />
            </span>
          </div>
          {/* {(hasAuthority(userRoleConstants.MFI_SUPERVISOR) ||
            hasAuthority(userRoleConstants.MFI_CREDIT_ANALYST)) && (
            <ApplicationsPaymentImport className="btn-actions ml-2" />
          )} */}
          {(hasAuthority(userRoleConstants.MFI_SUPERVISOR) ||
            hasAuthority(userRoleConstants.MFI_CREDIT_ANALYST)) && (
            <Select
              classes={{ root: classes.selectRoot }}
              variant="outlined"
              value="Download Payments"
              onChange={handleDownloadPayments}
              className={cx("ml-2")}
            >
              <MenuItem value="Download Payments">Download Payments</MenuItem>
              <MenuItem value="csv">csv</MenuItem>
              <MenuItem value="xlsx">xlsx</MenuItem>
            </Select>
          )}
        </div>
      </Row>
      <Row>
        <Col className="mt-4">
          {!error && !loading && isEmpty(filterdPurchasePayments) && (
            <EmptyContentView
              title={t("module.applicationManager.view.list.paymentsNotFound")}
            />
          )}
          {!isEmpty(error) && <ErrorsView error={error} />}
          {loading && <LoadingView />}
          {!isEmpty(filterdPurchasePayments) && (
            <ApplicationPaymentTable
              payments={filterdPurchasePayments}
              headOfficeAcc={headOfficeAcc}
              managementAccountant={managementAccountant}
              downloadPayments={downloadPayments}
              setTabText={setTabText}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

const ApplicationPayment = withStyles(styles)(withTranslation()(ListPayment))
export { ApplicationPayment as ListApplicationPayment }

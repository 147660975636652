/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { filter } from "lodash"
import Tooltip from "rc-tooltip"
import "rc-tooltip/assets/bootstrap_white.css"
import { useTranslation } from "react-i18next"
import { mpesaScoreCodes } from "./MpesaScoreCodes"

function OtherCalculations({
  type,
  selected = [],
  selectOne,
  scoreLogic,
  handler,
  mpesa,
}) {
  // Set component state
  const [state, setState] = useState(selected)
  const { t } = useTranslation()
  const { formulas } = scoreLogic
  const filterConfig = { type }
  const title = mpesa
    ? t("module.scoreLogic.view.scoreLogic.titles.mpesaScoreCodes")
    : t("module.scoreLogic.view.scoreLogic.titles.otherCalculations")
  const returnType = "otherCalculations"
  const Flags = mpesa ? mpesaScoreCodes : filter(formulas, filterConfig)

  useEffect(() => {
    handler(returnType, { [returnType]: state })
  }, [state])

  const selectItem = (which) => {
    let newState = [...state]

    if (selectOne) {
      newState = []
    }

    const index = newState.indexOf(which)

    if (index === -1) {
      if (selectOne) {
        newState = [which]
      } else {
        newState.push(which)
      }
    } else {
      newState.splice(index, 1)
    }

    setState(newState)
  }

  return (
    <div className="items-group mb-2 other-calculations w-full">
      <h4>{title}</h4>
      <div className="items-holder flex flex-wrap">
        {Flags.map((formula) => {
          const { name, code } = formula
          const active = state && state.includes(code) && "variable-item-active"
          const variableMpesa = mpesa && "variable-mpesa"

          return (
            <Tooltip
              key={code}
              placement="top"
              overlay={name}
              arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
            >
              <div
                className={`item variable-item ${active} ${variableMpesa}`}
                onClick={() => {
                  selectItem(code)
                }}
                key={code}
              >
                {code}
              </div>
            </Tooltip>
          )
        })}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { scoreLogic } = state
  return {
    scoreLogic,
  }
}

const connectedOtherCalculations = connect(mapStateToProps)(OtherCalculations)
export { connectedOtherCalculations as OtherCalculations }

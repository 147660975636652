/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ApplicationProfile } from '../../../scoreLogic/views/ProjectionPeriod/components/ApplicationProfile';
import { cslActions } from '../../../scoreLogic/';
import { FormSelect, FormGroup } from 'shards-react';
import { isEmpty, filter, first } from 'lodash';

import './LoanDetails.scss';

export function LoanDetails() {
    const scoreLogic = useSelector((store) => store.scoreLogic);
    const { uid, computationCodes, scoreCardProfile } = scoreLogic;
    const [surveyComputationCodes, setSurveyComputationCodes] = useState([]);
    const [submit, setSubmit] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation()

    const defaultLoanTenorState = {
        computationCode: '',
        title: t('module.applications.applicationProfile.loanTenor'),
        type: 'LOAN_TENOR',
    };
    const [loanTenorState, setLoanTenorState] = useState(defaultLoanTenorState);

    useEffect(() => {
        if (!submit) {
          const loanTenor = first(scoreCardProfile.filter((item) => {
              const { type, surveyUuid } = item;
              return type === 'LOAN_TENOR' && surveyUuid === uid;
          }));
          setLoanTenorState({ ...defaultLoanTenorState, ...loanTenor });
        }
    }, []);

    const submitData = useCallback(() => {
      if (submit) {
        loanTenorState.id && dispatch(cslActions.editScoreCardProfileItem(loanTenorState.id, loanTenorState));
        !loanTenorState.id && loanTenorState.computationCode && dispatch(cslActions.addScoreCardProfileItem([loanTenorState]));
        setSubmit(false);
      }
    }, [submit, dispatch, loanTenorState]);

    useEffect(() => {
        submitData();
    }, [submit, submitData]);

    const handleLoanTenorChange = e => {
      const { value } = e.target;
      const ComputationCodes = first(surveyComputationCodes.filter(({ questionCode }) => questionCode === value));
      setLoanTenorState({
          ...loanTenorState,
          computationCode: value,
          questionKey: ComputationCodes.questionKey,
          surveyUuid: uid,
      });
      setSubmit(true);
    }

    useEffect(() => {
        const codes = computationCodes && computationCodes.filter(({ surveyUuid }) => surveyUuid === uid);
        if (!isEmpty(codes)) {
            setSurveyComputationCodes([...codes]);
        } else {
            setSurveyComputationCodes([]);
        }
    }, [computationCodes, uid]);

    const getCodeLabel = (xCode) => {
        const [filtered] = filter(surveyComputationCodes, { questionCode: xCode });
        const questionCode = Object(filtered);
        return `${questionCode.questionLabel} (${xCode})`;
    };

    return (
        <div>
          <ApplicationProfile uid={uid} surveyComputationCodes={surveyComputationCodes} scoreCardProfile={scoreCardProfile} getCodeLabel={getCodeLabel} />
          <div className="group p-4 w-1/2">
              <h4 className="mini-heading-top">{t('module.applications.applicationProfile.loanTenor')}</h4>
              <FormGroup className="group flex">
                  <label className="pr-4">{t('module.applications.applicationProfile.question')}</label>
                  <FormSelect name="expression1" value={loanTenorState.computationCode && loanTenorState.computationCode} size="sm" className="mr-auto max-w-md" onChange={e => handleLoanTenorChange(e)}>
                      <option key={-1} value="">{t('module.applications.applicationProfile.selectQuestion')}</option>
                      {
                          surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => {
                              return <option key={idx} value={questionnaire.questionCode}>{getCodeLabel(questionnaire.questionCode)}</option>;
                          })
                      }
                  </FormSelect>
              </FormGroup>
              <FormGroup className="flex">
                  <label className="pr-4" style={{ width: '138px' }}>{t('module.applications.applicationProfile.computationCode')}</label>
                  <div className="projectionState-expression">{loanTenorState.computationCode && loanTenorState.computationCode}</div>
              </FormGroup>
          </div>
        </div>
    );
}

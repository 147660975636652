import {
  axiosInstance,
  axiosInstanceODK,
  integrationsInstance,
} from "../../_helpers/base.service"

export const applicationService = {
  getBySurveyUuid,
  getApplicationbyID,
  getFormFields,
  uploadPayments,
  fetchPayments,
  processFarmerCoffeePayments,
  getProfileApplications,
  getMyApplications,
  getMyPendingTasks,
  getNextPurchasesWorkflowUser,
  searchPayments,
  generatePurchaseSummaryReport,
  generatePaymentsSummaryDownload,
  getUgandaBanks,
  updateMobileNo,
  getVillageBanks,
  addVillageBank,
  fetchEntityStatus,
  updateCollectionsStatus,
  getUsersToAssign,
  getRepaymentSchedule,
}

const serviceGroup = "workflow"

/**
 * NOTE:having this here fixes wierd bug whereby sometimes the application data is blank.
 * This bug only affets GetAll Application as far as I can tell
 *
 * @param {*} res
 */

const handleResponse = (res) => {
  console.log(res)
  if (res.status === 200) {
    const { data } = res.data
    return data
  }
  const { response } = res

  const { data } = response
  const { message } = data
  return Promise.reject(message)
}

const handlePagedResponse = (res) => {
  if (res.status === 200) {
    const { content } = res.data
    return content
  }
  const { response } = res
  const { data } = response
  const { message } = data
  return Promise.reject(message)
}

function getBySurveyUuid(surveyUuid) {
  const requestConfig = {
    params: {
      surveyUuid,
    },
  }
  return axiosInstance
    .get(`${serviceGroup}/applications/`, requestConfig)
    .then(handleResponse)
}

function updateCollectionsStatus(collectionIds, surveyUuid) {
  const payload = { collectionIds, surveyUuid }
  return axiosInstance
    .post(`${serviceGroup}/applications/collections`, payload)
    .then(handleResponse)
}

function getApplicationbyID(applicationId) {
  return axiosInstance
    .get(`${serviceGroup}/applications/application/${applicationId}/`)
    .then(handleResponse)
}

function getProfileApplications(fieldName, id) {
  return axiosInstance
    .get(`${serviceGroup}/applications/application/profile-applications/`, {
      params: {
        fieldName,
        id,
      },
    })
    .then(handleResponse)
}

function getNextPurchasesWorkflowUser(username) {
  return axiosInstance
    .get(`${serviceGroup}/exposed/hierarchy-entity/username`, {
      params: {
        username,
      },
    })
    .then(handleResponse)
}

function getMyApplications() {
  return axiosInstance
    .get(`${serviceGroup}/applications/my-applications/`)
    .then(handleResponse)
}

function getMyPendingTasks() {
  return axiosInstance
    .get(`${serviceGroup}/applications/my-pending-tasks/`)
    .then(handleResponse)
}

function getFormFields(surveyUuid) {
  return axiosInstanceODK
    .get(`/forms/content?uuid=${surveyUuid}`)
    .then(handleResponse)
}

function uploadPayments(payload) {
  return integrationsInstance
    .post("musoni/payments", payload)
    .then(handleResponse)
}

function processFarmerCoffeePayments(payload) {
  return integrationsInstance
    .post("musoni/payments/coffee-purchase-payments", payload)
    .then(handleResponse)
}

function updateMobileNo(payload) {
  return integrationsInstance
    .post("musoni/payments/coffee-purchase-client-mobile-update", payload)
    .then(handleResponse)
}

function getUgandaBanks() {
  return integrationsInstance
    .get("musoni/payments/get-banks")
    .then(handleResponse)
}

function fetchPayments(username) {
  return integrationsInstance
    .get(`musoni/payments?search=${username}`)
    .then(handlePagedResponse)
}

function searchPayments(search) {
  return integrationsInstance
    .get(`musoni/payments/search-purchases?search=${search}`)
    .then(handleResponse)
}

function generatePurchaseSummaryReport(format) {
  return integrationsInstance
    .get(`musoni/payments/purchases-summary-report?format=${format}`, {
      responseType: "blob",
      timeout: 30000,
    })
    .then((res) => res)
}

function generatePaymentsSummaryDownload(format, status) {
  return integrationsInstance
    .get(
      `musoni/payments/download-payments?format=${format}&status=${status}`,
      {
        responseType: "blob",
        timeout: 30000,
      }
    )
    .then((res) => res)
    .catch((error) => {
      throw error
    })
}

/**
 * GET village banks
 *
 * @param {string} parentName - The parent name to filter village banks by.
 * @returns {Promise} A Promise that resolves with the response data.
 */
function getVillageBanks(hierarchyEntity) {
  const parentName = hierarchyEntity?.name
  return integrationsInstance
    .get(`musoni/exposed/offices?parentName=${encodeURIComponent(parentName)}`)
    .then(handleResponse)
}

/**
 * Add a new village bank.
 *
 * @param {object} villageBankData - Object containing the name and parentId of the new village bank.
 * @returns {Promise} A Promise that resolves with the response data.
 */
function addVillageBank(villageBankData) {
  // Ensure parentId is treated as an integer
  villageBankData.parentId = parseInt(villageBankData.parentId)

  return integrationsInstance
    .post("musoni/exposed/offices", villageBankData)
    .then(handleResponse)
}

/**
 * Fetch entity status based on NRC numbers from the API.
 *
 * @param {array} externalClientIds
 * @returns {Promise}
 */
export function fetchEntityStatus(externalClientIds) {
  const query = externalClientIds.join(",")
  return integrationsInstance
    .get(`musoni/exposed/statuses`, {
      params: { externalClientIds: query },
    })
    .then(handleResponse)
}

/**
 * Get Repayment Schedule
 *
 * @param {*} applicationIds
 *
 */
function getRepaymentSchedule(applicationIds) {
  const query = applicationIds.join(",")
  return integrationsInstance
    .get("musoni/exposed/repayment-schedule", {
      params: {
        applicationIds: query,
      },
    })
    .then(handleResponse)
}

/**
 * Get users who can be assigned an application
 *
 */
export function getUsersToAssign(applicationId) {
  return axiosInstance
    .get(`/workflow/workflow/application/${applicationId}/users-can-approve`)
    .then(handleResponse)
}

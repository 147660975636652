import React, { useState, useEffect } from 'react';
import { first, isEmpty, pick } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormSelect } from 'shards-react';
import Svg from 'react-inlinesvg';
import { mfiManagerActions } from '../..';

const nextFlowSVG = require('./../../../../assets/images/icons/workflow-next-arrow-icon.svg');

export function FlowEditor({ rowData: { id } }) {
    const { subRoles, subRoleLimits } = useSelector((store) => store.mfis);
    const [subRolesData, setSubRolesData] = useState(null);
    const allowedFlowDataItems = ['id', 'categoryWorkflow'];
    const flowData = subRoleLimits.map((item) => pick(item, allowedFlowDataItems));
    const dispatch = useDispatch();
    const row = first(flowData.filter((item) => String(item.id) === String(id)));
    const categoryWorkflow = !isEmpty(row) ? row.categoryWorkflow : [];
    // NOTE: this ensure the flow inputs are displayed based on their stepNumber
    categoryWorkflow.sort(function(a, b){return a.stepNumber - b.stepNumber});

    useEffect(() => {
        setSubRolesData(subRoles);
    }, [subRoles]);

    const isLastRole = (index) => (categoryWorkflow.length === (index + 1));

    const handleChange = (e, { value }, index) => {
        e.preventDefault();
        dispatch(mfiManagerActions.updateMFISubRoleLimitFlow(id, {subRole: value}, index));
    };

    return (
        <Form className="flex subrole-flow w-full shadow-none">
            {
                categoryWorkflow.map((flowItem, idx) => {
                    const { subRole } = flowItem;
                    return (
                        <React.Fragment key={idx}>
                            <FormSelect key={idx} name="subRole" className="mx-2 w-20" defaultValue={subRole} onChange={(e) => { handleChange(e, { name: e.target.name, value: e.target.value }, idx); }}>
                                <option name="">Select role</option>
                                {
                                    subRolesData && subRolesData.map((item) => {
                                        const { id: subRoleID, code } = item;
                                        return (<option key={subRoleID} name={code} selected={subRole === code}>{code}</option>);
                                    })
                                }
                            </FormSelect>
                            {!isLastRole(idx) && <div className="subrole-flow__divider flex content-center items-center">
                                <Svg className="text-center d-inline-block item-icon-wrapper" src={nextFlowSVG} />
                            </div>}
                        </React.Fragment>
                    );
                })
            }
        </Form>
    );
}

import { isEmpty } from "lodash"
import { applicationConstants } from "./_constants"
import { applicationService } from "./_service"
import { creditScoreService } from "../creditScore"
import { applicationEditorService } from "../applicationEditor"
import { creditScoreActions } from "../creditScore/_actions"
import { configService } from "../config"
import { alertActions } from "../alert"
const fileSaver = require("file-saver")

export const applicationActions = {
  getApplicationbyID,
  getApplicationBySurveyUuid,
  getLoanInformation,
  setQuestionnaire,
  assignApplication,
  editApplicationLoanDetails,
  recalculateCreditScore,
  getApplicationColumns,
  dateColumnFilter,
  columnSort,
  userFilter,
  officeFilter,
  groupFilter,
  applicationClicked,
  filterState,
  getFormFields,
  searchApplications,
  uploadPayments,
  fetchPayments,
  processFarmerCoffeePayments,
  updateMobileNo,
  getProfileApplications,
  getMyApplications,
  getMyPendingTasks,
  getNextPurchasesWorkflowUser,
  searchPayments,
  filterPayments,
  generatePurchaseSummaryReport,
  generatePaymentsSummaryDownload,
  getUgandaBanks,
  getVillageBanks,
  addVillageBank,
  fetchEntityStatus,
  editMultipleLoanTenors,
  updateCollectionsStatus,
  fetchUsersToAssign,
  getRepaymentSchedule,
}

/**
 * Save selected questionnaire ID to store
 */
function setQuestionnaire(uid) {
  return (dispatch) => {
    dispatch({
      type: applicationConstants.APPLICATIONS_QUESTIONNAIRE_UID,
      id: uid,
    })
  }
}

/**
 * Get Loan Application
 */
function getApplicationbyID(applicationId) {
  return (dispatch) => {
    dispatch(request())

    applicationService.getApplicationbyID(applicationId).then(
      (data) => {
        dispatch(success([data]))
        return data
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request(data) {
    return { type: applicationConstants.APPLICATIONS_REQUEST, data }
  }
  function success(data) {
    return { type: applicationConstants.APPLICATIONS_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.APPLICATIONS_FAILURE, error }
  }
}

/**
 * Get profile Loan Application
 */
function getProfileApplications(fieldName, id) {
  return (dispatch) => {
    dispatch(request())

    applicationService.getProfileApplications(fieldName, id).then(
      (data) => {
        dispatch(success(data))
        return data
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request(data) {
    return { type: applicationConstants.APPLICATIONS_REQUEST, data }
  }
  function success(data) {
    return { type: applicationConstants.APPLICATIONS_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.APPLICATIONS_FAILURE, error }
  }
}

/**
 * Get my applications
 */
function getMyApplications() {
  return (dispatch) => {
    dispatch(request())

    applicationService.getMyApplications().then(
      (data) => {
        dispatch(success(data))
        return data
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request(data) {
    return { type: applicationConstants.MY_APPLICATIONS_REQUEST, data }
  }
  function success(data) {
    return { type: applicationConstants.MY_APPLICATIONS_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.MY_APPLICATIONS_FAILURE, error }
  }
}

/**
 * Get my pending tasks
 */
function getMyPendingTasks() {
  return (dispatch) => {
    dispatch(request())

    applicationService.getMyPendingTasks().then(
      (data) => {
        dispatch(success(data))
        return data
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request(data) {
    return { type: applicationConstants.MY_TASKS_REQUEST, data }
  }
  function success(data) {
    return { type: applicationConstants.MY_TASKS_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.MY_TASKS_FAILURE, error }
  }
}

function processFarmerCoffeePayments(payload) {
  return (dispatch) => {
    dispatch(request())

    applicationService.processFarmerCoffeePayments(payload).then(
      (data) => {
        dispatch(success(data))
        window.location.reload()
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }
  function request(data) {
    return { type: applicationConstants.PAYMENTS_UPLOAD_REQUEST, data }
  }
  function success(data) {
    return { type: applicationConstants.PAYMENTS_UPLOAD_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.PAYMENTS_UPLOAD_FAILURE, error }
  }
}

function updateMobileNo(payload) {
  return (dispatch) => {
    dispatch(request())

    applicationService.updateMobileNo(payload).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }
  function request(data) {
    return { type: applicationConstants.PAYMENTS_UPLOAD_REQUEST, data }
  }
  function success(data) {
    return { type: applicationConstants.MOBILE_UPDATE_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.PAYMENTS_UPLOAD_FAILURE, error }
  }
}

function getUgandaBanks() {
  return (dispatch) => {
    dispatch(request())

    applicationService.getUgandaBanks().then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }
  function request(data) {
    return { type: applicationConstants.GET_BANKS_REQUEST, data }
  }
  function success(data) {
    return { type: applicationConstants.GET_BANKS_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.GET_BANKS_FAILURE, error }
  }
}

function uploadPayments(payload) {
  return (dispatch) => {
    dispatch(request())

    applicationService.uploadPayments(payload).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }
  function request(data) {
    return { type: applicationConstants.PAYMENTS_UPLOAD_REQUEST, data }
  }
  function success(data) {
    return { type: applicationConstants.PAYMENTS_UPLOAD_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.PAYMENTS_UPLOAD_FAILURE, error }
  }
}

function getNextPurchasesWorkflowUser(username) {
  return (dispatch) => {
    dispatch(request())

    applicationService.getNextPurchasesWorkflowUser(username).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }
  function request(data) {
    return { type: applicationConstants.WORKFLOW_USER_REQUEST, data }
  }
  function success(data) {
    return { type: applicationConstants.WORKFLOW_USER_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.WORKFLOW_USER_FAILURE, error }
  }
}

function fetchPayments(username) {
  return (dispatch) => {
    dispatch(request())

    applicationService.fetchPayments(username).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }
  function request(data) {
    return { type: applicationConstants.GET_PAYMENTS_REQUEST, data }
  }
  function success(data) {
    return { type: applicationConstants.GET_PAYMENTS_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.GET_PAYMENTS_FAILURE, error }
  }
}

function searchPayments(search) {
  return (dispatch) => {
    dispatch(request())

    applicationService.searchPayments(search).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }
  function request(data) {
    return { type: applicationConstants.GET_PAYMENTS_REQUEST, data }
  }
  function success(data) {
    return { type: applicationConstants.GET_PAYMENTS_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.GET_PAYMENTS_FAILURE, error }
  }
}

function filterPayments(data) {
  return { type: applicationConstants.PAYMENTS_FILTER, data }
}

function generatePurchaseSummaryReport(format) {
  return (dispatch) => {
    applicationService.generatePurchaseSummaryReport(format).then(
      (data) => {
        if (!isEmpty(data)) {
          // eslint-disable-next-line no-case-declarations
          const date = new Date(Date.now())
          dispatch(alertActions.success("Downloading..."))
          // eslint-disable-next-line no-case-declarations
          const fileName = `coffee-purchases-summary-report-${date.toLocaleString(
            "en-GB",
            {
              timeZone: "UTC",
            }
          )}.${format}`
          fileSaver.saveAs(data.data, fileName)
        }
      },
      (error) => {
        dispatch(alertActions.error("Download failed"))
      }
    )
  }
}

function generatePaymentsSummaryDownload(format, status) {
  return (dispatch) => {
    applicationService.generatePaymentsSummaryDownload(format, status).then(
      (data) => {
        if (!isEmpty(data)) {
          // eslint-disable-next-line no-case-declarations
          const date = new Date(Date.now())
          dispatch(alertActions.success("Downloading..."))
          // eslint-disable-next-line no-case-declarations
          const fileName = `payments-summary-${date.toLocaleString("en-GB", {
            timeZone: "UTC",
          })}.${format}`
          fileSaver.saveAs(data.data, fileName)
        }
      },
      (error) => {
        dispatch(alertActions.error("Download failed"))
      }
    )
  }
}

function getApplicationBySurveyUuid(surveyUuid) {
  return (dispatch) => {
    dispatch(request())
    applicationService.getBySurveyUuid(surveyUuid).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request(data) {
    return { type: applicationConstants.APPLICATIONS_REQUEST, data }
  }
  function success(data) {
    return { type: applicationConstants.APPLICATIONS_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.APPLICATIONS_FAILURE, error }
  }
}

/**
 * Update collection repayment status and fetch the updated collections
 *
 * @param {*} collectionIds
 * @param {*} surveyUuid
 */

function updateCollectionsStatus(collectionIds, surveyUuid) {
  return (dispatch) => {
    dispatch(request())
    applicationService.updateCollectionsStatus(collectionIds, surveyUuid).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request(data) {
    return { type: applicationConstants.APPLICATIONS_REQUEST, data }
  }
  function success(data) {
    return { type: applicationConstants.APPLICATIONS_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.APPLICATIONS_FAILURE, error }
  }
}

/**
 * Get applications
 */
function getLoanInformation(application) {
  return (dispatch) => {
    dispatch(request())

    try {
      if (isEmpty(application)) {
        throw new Error("Invalid application")
      }

      creditScoreService.getLoanInformation(application).then(
        (data) => {
          dispatch(
            success(
              data.map((item) => {
                const newItem = { ...item, applicationId: application.id }
                return newItem
              })
            )
          )
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request(data) {
    return { type: applicationConstants.APPLICATION_METADATA_REQUEST, data }
  }
  function success(data) {
    return { type: applicationConstants.APPLICATION_METADATA_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.APPLICATION_METADATA_FAILURE, error }
  }
}

/**
 * Change application stage
 *
 * @param {*} applicationId
 * @param {*} stage
 */
function assignApplication(applicationId, stage) {
  return (dispatch) => {
    dispatch({
      type: applicationConstants.APPLICATION_ASSIGN,
      applicationId,
      stage,
    })
  }
}

/**
 * Recalculate Credit Score
 *
 * @param {*} application
 */
function editApplicationLoanDetails(data) {
  return (dispatch, getState) => {
    const { items } = getState().applications
    const foundApplication = items.filter(
      (item) => item.id === data.applicationId
    )
    const [application] = foundApplication
    dispatch(request())
    try {
      applicationEditorService.updateApplication(data).then(
        () => {
          dispatch(success(data))

          dispatch(recalculateCreditScore(data.applicationId))
          dispatch(creditScoreActions.getScoreCard(data.applicationId))
          dispatch(creditScoreActions.getJsonChanges(data.applicationId))
          dispatch(creditScoreActions.getFormulaResult(data.applicationId))
          dispatch(creditScoreActions.getCreditScoreReport(data.applicationId))

          dispatch(applicationActions.getLoanInformation(application))
        },
        (error) => {
          if (
            error.response.data &&
            error.response.data.message &&
            error.response.data.message.includes(
              "Approval Amount/Tenor adjusted"
            )
          ) {
            dispatch(alertActions.success(error.response.data.message))
            dispatch(success(data))
            dispatch(recalculateCreditScore(data.applicationId))
            dispatch(creditScoreActions.getScoreCard(data.applicationId))
            dispatch(creditScoreActions.getJsonChanges(data.applicationId))
            dispatch(creditScoreActions.getFormulaResult(data.applicationId))
            dispatch(
              creditScoreActions.getCreditScoreReport(data.applicationId)
            )
            dispatch(applicationActions.getLoanInformation(application))
          } else {
            dispatch(failure(error))
          }
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request() {
    return { type: applicationConstants.APPLICATION_EDIT_LOAN_DETAILS_REQUEST }
  }
  function success(data) {
    return {
      type: applicationConstants.APPLICATION_EDIT_LOAN_DETAILS_SUCCESS,
      data,
    }
  }
  function failure(error) {
    return {
      type: applicationConstants.APPLICATION_EDIT_LOAN_DETAILS_FAILURE,
      error,
    }
  }
}

/**
 * Edit multiple loan tenors
 *
 * @param {*} application
 */
function editMultipleLoanTenors(data) {
  return (dispatch, getState) => {
    const { items } = getState().applications
    const foundApplication = items.filter(
      (item) => item.id === data.applicationId
    )
    const [application] = foundApplication
    dispatch(request())
    try {
      applicationEditorService.updateMultipleApplication(data).then(
        () => {
          dispatch(success(data))
        },
        (error) => {
          if (
            error.response.data &&
            error.response.data.message &&
            error.response.data.message.includes(
              "Approval Amount/Tenor adjusted"
            )
          ) {
            dispatch(alertActions.success(error.response.data.message))
            dispatch(success(data))
          } else {
            dispatch(failure(error))
          }
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request() {
    return { type: applicationConstants.APPLICATION_EDIT_LOAN_DETAILS_REQUEST }
  }
  function success(data) {
    return {
      type: applicationConstants.APPLICATION_EDIT_LOAN_DETAILS_SUCCESS,
      data,
    }
  }
  function failure(error) {
    return {
      type: applicationConstants.APPLICATION_EDIT_LOAN_DETAILS_FAILURE,
      error,
    }
  }
}

/**
 * Recalculate Credit Score
 *
 * @param {*} applicationID
 */
function recalculateCreditScore(applicationID) {
  return (dispatch) => {
    dispatch(request())

    try {
      if (isEmpty(applicationID)) {
        throw new Error("Invalid applicationID")
      }
      creditScoreService.recalculateCreditScore(applicationID).then(
        (data) => {
          dispatch(success(data))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request(data) {
    return { type: applicationConstants.RECALCULATE_CREDIT_SCORE_REQUEST, data }
  }
  function success(data) {
    return { type: applicationConstants.RECALCULATE_CREDIT_SCORE_SUCCESS, data }
  }
  function failure(error) {
    return {
      type: applicationConstants.RECALCULATE_CREDIT_SCORE_FAILURE,
      error,
    }
  }
}

/**
 * get Application Colums
 *
 * @param {*} surveyUuid
 */
function getApplicationColumns(surveyUuid) {
  return (dispatch) => {
    dispatch(request())
    configService.getApplicationColumns(surveyUuid).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request() {
    return { type: applicationConstants.APPLICATION_COLUMN_GET_REQUEST }
  }
  function success(data) {
    return { type: applicationConstants.APPLICATION_COLUMN_GET_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.APPLICATION_COLUMN_GET_FAILURE, error }
  }
}

/**
 * add date filter to state
 */
function dateColumnFilter(key, from, to) {
  return (dispatch) => {
    if (isEmpty(key)) {
      dispatch({
        type: applicationConstants.APPLICATION_DATE_COLUMN_FILTER_RESET,
      })
    } else {
      dispatch({
        type: applicationConstants.APPLICATION_DATE_COLUMN_FILTER,
        data: { key, from, to },
      })
    }
  }
}

/**
 * add column sort to state
 */
function columnSort(key, type, direction) {
  return (dispatch) => {
    dispatch({
      type: applicationConstants.APPLICATION_COLUMN_SORT,
    })
  }
}

/**
 * filter by user
 */
function userFilter(data) {
  return (dispatch) => {
    dispatch({
      type: applicationConstants.APPLICATION_USER_FILTER,
      data,
    })
  }
}

/**
 *
 * filter by office
 */
function officeFilter(data) {
  return (dispatch) => {
    dispatch({
      type: applicationConstants.APPLICATION_OFFICE_FILTER,
      data,
    })
  }
}

/**
 *
 * filter by group
 */
function groupFilter(data) {
  return (dispatch) => {
    dispatch({
      type: applicationConstants.APPLICATION_GROUP_FILTER,
      data,
    })
  }
}

/**
 * add clicked application data to store
 */
function applicationClicked(data) {
  return (dispatch) => {
    dispatch({
      type: applicationConstants.APPLICATION_CLICKED,
      data,
    })
  }
}

/**
 * add filter state to data
 */
function filterState(data) {
  return (dispatch) => {
    dispatch({
      type: applicationConstants.APPLICATION_STATE_FILTER,
      data,
    })
  }
}

function getFormFields(surveyUuid) {
  return (dispatch) => {
    dispatch(request())
    applicationService.getFormFields(surveyUuid).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request() {
    return { type: applicationConstants.GET_FORM_FIELDS_REQUEST }
  }
  function success(data) {
    return { type: applicationConstants.GET_FORM_FIELDS_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.GET_FORM_FIELDS_FAILURE, error }
  }
}

/**
 * SEARCH APPLICATIONS
 *
 * @param {*} surveyUuid
 * @param {*} query
 *
 */
function searchApplications(surveyUuid, query) {
  return (dispatch) => {
    dispatch(request())
    configService.searchApplications(surveyUuid, query).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request() {
    return { type: applicationConstants.SEARCH_APPLICATION_REQUEST }
  }
  function success(data) {
    return { type: applicationConstants.SEARCH_APPLICATION_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.SEARCH_APPLICATION_FAILURE, error }
  }
}

function getVillageBanks(parentName) {
  return (dispatch) => {
    dispatch(request())
    applicationService.getVillageBanks(parentName).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error.response.data.message))
      }
    )
  }

  function request(data) {
    return { type: applicationConstants.VILLAGE_BANKS_REQUEST, data }
  }
  function success(data) {
    return { type: applicationConstants.VILLAGE_BANKS_SUCCESS, data }
  }
  function failure(error) {
    return { type: applicationConstants.VILLAGE_BANKS_FAILURE, error }
  }
}

/**
 * Add Village Bank
 *
 * @param {*} villageBankData
 */
function addVillageBank(villageBankData) {
  return (dispatch) => {
    dispatch(request())

    applicationService.addVillageBank(villageBankData).then(
      (data) => {
        dispatch(success(data))
        dispatch(alertActions.success("Village Bank Added"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error.response.data.message))
      }
    )
  }

  function request() {
    return { type: applicationConstants.ADD_VILLAGE_BANK_REQUEST }
  }

  function success(data) {
    return { type: applicationConstants.ADD_VILLAGE_BANK_SUCCESS, data }
  }

  function failure(error) {
    return { type: applicationConstants.ADD_VILLAGE_BANK_FAILURE, error }
  }
}

function fetchEntityStatus(externalClientIds) {
  return (dispatch) => {
    dispatch(request())
    return applicationService.fetchEntityStatus(externalClientIds).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error.response.data.message))
      }
    )
  }

  function request() {
    return { type: applicationConstants.GET_ENTITY_STATUS_REQUEST }
  }

  function success(data) {
    return { type: applicationConstants.GET_ENTITY_STATUS_SUCCESS, data }
  }

  function failure(error) {
    return { type: applicationConstants.GET_ENTITY_STATUS_FAILURE, error }
  }
}

function fetchUsersToAssign(applicationId) {
  return (dispatch) => {
    dispatch(request())

    return applicationService.getUsersToAssign(applicationId).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        const errorMsg = error.response?.data?.message || error.toString()
        dispatch(failure(errorMsg))
        dispatch(alertActions.error(errorMsg))
      }
    )
  }

  function request() {
    return { type: applicationConstants.USERS_TO_ASSIGN_REQUEST }
  }

  function success(data) {
    return { type: applicationConstants.USERS_TO_ASSIGN_SUCCESS, data }
  }

  function failure(error) {
    return { type: applicationConstants.USERS_TO_ASSIGN_FAILURE, error }
  }
}

/**
 * Get Repayment Schedule
 *
 * @param {*} applicationIds
 *
 */
function getRepaymentSchedule(applicationIds) {
  return (dispatch) => {
    dispatch(request())

    return applicationService.getRepaymentSchedule(applicationIds).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        const errorMsg = error.response?.data?.message || error.toString()
        dispatch(failure(errorMsg))
        dispatch(alertActions.error(errorMsg))
      }
    )
  }

  function request() {
    return { type: applicationConstants.REPAYMENT_SCHEDULE_REQUEST }
  }

  function success(data) {
    return { type: applicationConstants.REPAYMENT_SCHEDULE_SUCCESS, data }
  }

  function failure(error) {
    return { type: applicationConstants.REPAYMENT_SCHEDULE_FAILURE, error }
  }
}

/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import Svg from "react-inlinesvg"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { Typography } from "@material-ui/core"
import { ButtonGroup, Button } from "shards-react"
import Header from "../../../components/widget/widgetTitle"

import { modalActions } from "../../modals"

import "./PayDecline.scss"
import { useTranslation } from "react-i18next"
const headerSVG = require("../../../assets/images/icons/header-icon.svg")

const PaymentMethod = ({ modalID, data }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [paymentType, setPaymentType] = useState("mobile")

  const handeSubmit = () => {
    if (paymentType === "mobile") {
      data["bankPayment"] = false
      dispatch(modalActions.payDecline(data))
    } else {
      dispatch(modalActions.addBeneficiary(data))
    }
  }

  const handleChange = (e) => {
    setPaymentType(e.target.value)
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  return (
    <div className="site-modal">
      <Header
        title="Choose Payment Method"
        icon={<Svg className="text-center d-inline-block" src={headerSVG} />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        <Typography>Please select a payment method below:</Typography>
        <RadioGroup
          aria-label="payment-method"
          name="paymentMethod"
          value={paymentType}
          onChange={handleChange}
        >
          <FormControlLabel
            value="mobile"
            control={<Radio />}
            label="Pay to Mobile Money"
          />
          <FormControlLabel
            value="bank"
            control={<Radio />}
            label="Pay to Bank Account"
          />
        </RadioGroup>
        <ButtonGroup className="btn-group-actions site-modal__actions">
          <Button
            theme="primary"
            className="thin mr-2"
            onClick={() => {
              handeSubmit()
            }}
          >
            Confirm
          </Button>
          <Button
            theme="cancel"
            className="thin delete"
            onClick={() => {
              closeModal()
            }}
          >
            {t("applicationDisburse.cancel")}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default PaymentMethod

import { alertActions } from "../alert";
import { clientConstants } from "./_constants";
import { ClientService } from "./_service";

/**
 * Reset Questionnaire
 *
 * @param {*} mfiId object
 */
const getAllClients = (mfiId, size, page) => {
  return (dispatch) => {
    dispatch(request({}));
    ClientService.getAllClients(mfiId, size, page).then(
      (res) => {
        dispatch(success(res));
        // dispatch(alertActions.success(message.success));
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(message.error));
      }
    );
  };

  function request() {
    return { type: clientConstants.GETALL_CLIENT_REQUEST };
  }
  function success(data) {
    return { type: clientConstants.GETALL_CLIENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: clientConstants.GETALL_CLIENT_FAILURE, error };
  }
};

/**
 * Get Overview Page
 *
 * @param {*} mfiId object
 */
const getClientOverview = (mfiId) => {
  return (dispatch) => {
    dispatch(request({}));
    ClientService.getClientOverview(mfiId).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: clientConstants.GET_CLIENT_OVERVIEW_REQUEST };
  }
  function success(data) {
    return { type: clientConstants.GET_CLIENT_OVERVIEW_SUCCESS, data };
  }
  function failure(error) {
    return { type: clientConstants.GET_CLIENT_OVERVIEW_FAILURE, error };
  }
};

/**
 * Get Overview Graph Page
 *
 * @param {*} mfiId object
 */
const getClientOverviewGraph = (mfiId) => {
  return (dispatch) => {
    dispatch(request({}));
    ClientService.getClientOverviewGraph(mfiId).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: clientConstants.GET_CLIENT_OVERVIEW_GRAPH_REQUEST };
  }
  function success(data) {
    return { type: clientConstants.GET_CLIENT_OVERVIEW_GRAPH_SUCCESS, data };
  }
  function failure(error) {
    return { type: clientConstants.GET_CLIENT_OVERVIEW_GRAPH_FAILURE, error };
  }
};

/**
 * Get CLIENT PROFILE Page
 *
 * @param {*} id object
 * @param {*} mfiId object
 */
const getClientProfile = (mfiId, id) => {
  return (dispatch) => {
    dispatch(request({}));
    ClientService.getClientProfile(mfiId, id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: clientConstants.GET_CLIENT_PROFILE_REQUEST };
  }
  function success(data) {
    return { type: clientConstants.GET_CLIENT_PROFILE_SUCCESS, data };
  }
  function failure(error) {
    return { type: clientConstants.GET_CLIENT_PROFILE_FAILURE, error };
  }
};

/**
 * Get CLIENT TO VIEW NOTES
 *
 * @param {*} mfiId object
 * @param {*} loadId object
 */
const getClientNotes = (mfiId, loadId, page, size) => {
  return (dispatch) => {
    dispatch(request({}));
    ClientService.getClientNotes(mfiId, loadId, page, size).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: clientConstants.GET_NOTES_REQUEST };
  }
  function success(data) {
    return { type: clientConstants.GET_NOTES_SUCCESS, data };
  }
  function failure(error) {
    return { type: clientConstants.GET_NOTES_FAILURE, error };
  }
};

/**
 * Send Reminder
 */
const sendReminder = (payload) => {
  return (dispatch) => {
    dispatch(request({}));
    ClientService.sendReminder(payload).then(
      (res) => {
        dispatch(success(res));
        dispatch(alertActions.success('Reminder Sent'));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: clientConstants.SEND_REMINDER_REQUEST };
  }
  function success(data) {
    return { type: clientConstants.SEND_REMINDER_SUCCESS, data };
  }
  function failure(error) {
    return { type: clientConstants.SEND_REMINDER_FAILURE, error };
  }
};

/**
 * CREATE NOTE REQUEST
 */
const createNote = (payload) => {
  return (dispatch) => {
    dispatch(request({}));
    ClientService.createNote(payload).then(
      (res) => {
        dispatch(success(res));
        dispatch(alertActions.success('Action Note Created successully'));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: clientConstants.CREATE_NOTE_REQUEST };
  }
  function success(data) {
    return { type: clientConstants.CREATE_NOTE_SUCCESS, data };
  }
  function failure(error) {
    return { type: clientConstants.CREATE_NOTE_FAILURE, error };
  }
};

/**
 * CREATE NOTE REQUEST
 */
const createDocumentUpload = (mfiId, id, payload, updatefetchClient, removeAll) => {
  return (dispatch) => {
    dispatch(request({}));
    ClientService.createDocumentUpload(mfiId, id, payload).then(
      (res) => {
        dispatch(success(res));
        dispatch(alertActions.success('Documents have been uploaded successfully'));
        updatefetchClient()
        removeAll()
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.response.data.errors[0]));
      }
    );
  };

  function request() {
    return { type: clientConstants.CREATE_UPLOAD_REQUEST };
  }
  function success(data) {
    return { type: clientConstants.CREATE_UPLOAD_SUCCESS, data };
  }
  function failure(error) {
    return { type: clientConstants.CREATE_UPLOAD_FAILURE, error };
  }
};

/**
 * Search client
 */
const searchClient = (mfiId, payload) => {
  return (dispatch) => {
    dispatch(request({}));
    ClientService.searchClient(mfiId, payload).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: clientConstants.SEARCH_CLIENT_REQUEST };
  }
  function success(data) {
    return { type: clientConstants.SEARCH_CLIENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: clientConstants.SEARCH_CLIENT_FAILURE, error };
  }
};

/**
 * Search client
 */
const filterClient = (mfiId, searchParams, payload) => {
  return (dispatch) => {
    dispatch(request({}));
    ClientService.filterClient(mfiId, searchParams, payload).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: clientConstants.FILTER_CLIENT_REQUEST };
  }
  function success(data) {
    return { type: clientConstants.FILTER_CLIENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: clientConstants.FILTER_CLIENT_FAILURE, error };
  }
};

/**
 * Get Clients Assignees
 */
const getClientAssignees = (mfiId) => {
  return (dispatch) => {
    dispatch(request({}));
    ClientService.getClientAssignees(mfiId).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: clientConstants.GET_CLIENT_ASSIGNEES_REQUEST };
  }
  function success(data) {
    return { type: clientConstants.GET_CLIENT_ASSIGNEES_SUCCESS, data };
  }
  function failure(error) {
    return { type: clientConstants.GET_CLIENT_ASSIGNEES_FAILURE, error };
  }
};

/**
 * Assign to
 */
const AssignTo = (payload, refetchClients) => {
  const data = {
    mfiId: payload.mfiId,
    loanId: payload.loanId,
    userId: payload.userId,
  }
  return (dispatch) => {
    dispatch(request({}));
    ClientService.AssignTo(data).then(
      (res) => {
        dispatch(success(res));
        refetchClients()
        dispatch(alertActions.success('Assignment successfull'));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error('An Error Occurred'));
      }
    );
  };

  function request() {
    return { type: clientConstants.ASSIGN_TO_REQUEST };
  }
  function success(data) {
    return { type: clientConstants.ASSIGN_TO_SUCCESS, data };
  }
  function failure(error) {
    return { type: clientConstants.ASSIGN_TO_FAILURE, error };
  }
};

/**
 * Assign to on Client Profile
 */
const AssignToClientProfile = (payload, refetchClients) => {
  const data = {
    mfiId: payload.mfiId,
    loanId: payload.loanId,
    userId: payload.userId,
  }
  return (dispatch) => {
    dispatch(request({}));
    ClientService.AssignToClientProfile(data).then(
      (res) => {
        dispatch(success(res));
        refetchClients()
        dispatch(alertActions.success('Assignment successfull'));

      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error('An Error Occurred'));
      }
    );
  };


  function request() {
    return { type: clientConstants.ASSIGN_TO_REQUEST };
  }
  function success(data) {
    return { type: clientConstants.ASSIGN_TO_SUCCESS, data };
  }
  function failure(error) {
    return { type: clientConstants.ASSIGN_TO_FAILURE, error };
  }
};

/**
 * Get Clients Assignees
 */
const getClientToAssignBack = (mfiId, id) => {
  return (dispatch) => {
    dispatch(request({}));
    ClientService.getClientToAssignBack(mfiId, id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: clientConstants.GET_CLIENT_TO_ASSIGN_BACK_REQUEST };
  }
  function success(data) {
    return { type: clientConstants.GET_CLIENT_TO_ASSIGN_BACK_SUCCESS, data };
  }
  function failure(error) {
    return { type: clientConstants.GET_CLIENT_TO_ASSIGN_BACK_FAILURE, error };
  }
};


/**
 * Assign to
 */
const AssignBack = (payload, refetchClients) => {
  const data = {
    mfiId: payload.mfiId,
    loanId: payload.loanId,
    userId: payload.userId,
  }
  return (dispatch) => {
    dispatch(request({}));
    ClientService.AssignBack(data).then(
      (res) => {
        refetchClients()
        dispatch(success(res));
        dispatch(alertActions.success('Assignment successfull'));
      },
      (error) => {
        // dispatch(failure(error.response.data.errors[0]));
        // display appropriate error
        dispatch(alertActions.error(error.response.data.errors[0]));
      }
    );
  };

  function request() {
    return { type: clientConstants.ASSIGN_BACK_REQUEST };
  }
  function success(data) {
    return { type: clientConstants.ASSIGN_BACK_SUCCESS, data };
  }

};

const BMSendReminder = (payload) => {
  return (dispatch) => {
    dispatch(request({}));
    ClientService.BMSendReminder(payload).then(
      (res) => {
        dispatch(success(res));
        dispatch(alertActions.success('Reminder sent successfull'));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.response.data.errors[0]));
      }
    );
  };

  function request() {
    return { type: clientConstants.BM_SEND_REMINDER_REQUEST };
  }
  function success(data) {
    return { type: clientConstants.BM_SEND_REMINDER_SUCCESS, data };
  }
  function failure(error) {
    return { type: clientConstants.BM_SEND_REMINDER_FAILURE, error };
  }
};
export const clientActions = {
  getAllClients,
  getClientOverview,
  getClientOverviewGraph,
  getClientProfile,
  getClientNotes,
  sendReminder,
  createNote,
  createDocumentUpload,
  searchClient,
  filterClient,
  getClientAssignees,
  AssignTo,
  AssignBack,
  getClientToAssignBack,
  AssignToClientProfile,
  BMSendReminder,
};

import { Button, Checkbox, Input, ListItemText, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { alertActions } from '../../../../alert';
import { clientActions } from '../../../_actions';
import './filter.scss';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    preview: {
        width: '100%',
        backgroundColor: 'white',
        padding: '2rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    previewOptions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: '1rem',
        fontSize: '14px'
    },
    input: {
        height: '1rem',
        border: 'thin solid gray',
        padding: '18.5px 6px',
        borderRadius: '4px',
    },
    activeFilterName: {
        fontWeight: 'bold',
    },
    activeFilterItem: {
        paddingLeft: '0.3rem',
        color: '#909090'
    },
    button: {
        backgroundColor: '#008d6a',
        marginTop: '25px',
        color: 'white',
        padding: '6px 17px',
        '&:hover': {
            backgroundColor: "008d6a",
         },
         '&:active': {
            backgroundColor: "008d6a",
         },
    },
    editInfo: {
        fontWeight: 'bold',
        color: '#60c1f7'
    },
    statusDropdown: {
        padding: '0px 15px',
        margin: '1px 20px',
        border: '1px solid',
        borderRadius: '14px',
    },
    statusCheckbox: {
        padding: 0
    }
}));

const FilterClient = ({ setIsFilterOpen, mfi }) => {
    const classes = useStyles();
    const [isSearched, setIsSearched] = useState(false);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        arrearsStatuses: [],
    });
    const { t } = useTranslation();

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const areSearchInputsEmpty = () => {
        const inputStatus = (
            state.arrearsStatuses.length === 0 &&
            state?.minDisbursedAmount === undefined &&
            state?.startDisbursedDate === undefined
        )
        return inputStatus
    }

    const handleChangeStatus = (event) => {
        const { value } = event.target;
        setState((prevState) => ({ ...prevState, arrearsStatuses: value }));
      };

    const handleSearch = () => {
        if (areSearchInputsEmpty()) {
            dispatch(alertActions.error('One of the inputs is required'));
        } else {
            setIsSearched(true);
            const payload = {
                ...state,
                startDisbursedDate: state?.startDisbursedDate && moment(state?.startDisbursedDate).format('DD/MM/YYYY'),
                endDisbursedDate: state?.endDisbursedDate && moment(state?.endDisbursedDate).format('DD/MM/YYYY'),
            }
            dispatch(clientActions.filterClient(mfi, '', payload));
        }
    }

    const handleCloseFilter = () => {
        setIsFilterOpen(false);
        dispatch(clientActions.filterClient(mfi, '', {}));
    }

    const getStatus = (status) => {
        switch(status) {
            case 1:
                return 0;
            case 2:
                return 7;
            case 3:
                return 14;
            case 4:
                return 21;
            case 5:
                return 30;
            default:
                return '> 30';
        }
    }

    return (
        <div className={`${classes.preview} filter`}>
            <div>
                <span className={isSearched ? classes.activeFilterName : ''}>{t('module.client.arrears.filter.disbursedAmount')}:</span>
                {isSearched ? (
                    <span className={classes.activeFilterItem}>
                        {state?.minDisbursedAmount} &nbsp;
                        {state?.minDisbursedAmount !== undefined && '-'} &nbsp;
                        {state?.maxDisbursedAmount}</span>
                ) : (
                    <div className={classes.previewOptions}>
                        <TextField
                            id="minDisbursedAmount"
                            variant="outlined"
                            onChange={handleInputChange}
                            name="minDisbursedAmount"
                            placeholder={t('module.client.arrears.filter.minAmount')}
                            value={state?.minDisbursedAmount}
                        />
                        &nbsp;&nbsp;-&nbsp;&nbsp;
                        <TextField
                            id="maxDisbursedAmount"
                            variant="outlined"
                            onChange={handleInputChange}
                            name="maxDisbursedAmount"
                            placeholder={t('module.client.arrears.filter.maxAmount')}
                            value={state?.maxDisbursedAmount}
                        />
                    </div>
                )}
            </div>
            <div>
                <span className={isSearched ? classes.activeFilterName : ''}>{t('module.client.arrears.filter.disbursedDate')}:</span>
                {isSearched ? (
                    <span className={classes.activeFilterItem}>
                        {state?.startDisbursedDate} &nbsp;
                        {state?.startDisbursedDate !== undefined && '-' } &nbsp;
                        {state?.endDisbursedDate}
                    </span>
                ) : (
                    <div className={classes.previewOptions}>
                        <input
                            type="date"
                            name="startDisbursedDate"
                            onChange={handleInputChange}
                            className={classes.input}
                            placeholder="dd/mm/yyyy"
                            value={state?.startDisbursedDate}
                        />
                        &nbsp;&nbsp; - &nbsp;&nbsp;
                        <input
                            type="date"
                            name="endDisbursedDate"
                            onChange={handleInputChange}
                            className={classes.input}
                            placeholder="dd/mm/yyyy"
                            value={state?.endDisbursedDate}
                        />
                    </div>
                )}
            </div>
            <div>
                <span className={isSearched ? classes.activeFilterName : ''}>{t('module.client.arrears.filter.arrearsStatus')}:</span>
                {isSearched ? (
                    <span className={classes.activeFilterItem}>
                        {state?.arrearsStatuses?.map((status) => (
                            <span>{getStatus(status)} {t('module.client.arrears.filter.daysLate')}, &nbsp;</span>
                        ))}
                    </span>
                ) : (
                    <Select
                        labelId="arrearsStatuses"
                        id="arrearsStatuses"
                        value={state.arrearsStatuses}
                        onChange={handleChangeStatus}
                        name="arrearsStatuses"
                        multiple
                        input={<Input />}
                        className={classes.input}
                        renderValue={(selected) => selected.join(', ')}
                        style={{ width: '100%' }}
                    >
                        <MenuItem value="" disabled>
                            {t('module.client.arrears.filter.selectStatus')}
                        </MenuItem>
                        <MenuItem
                            value={1}
                            className={classes.statusDropdown}
                            style={{
                                backgroundColor: '#eff9fe',
                                borderColor: '#1e87f0'
                            }}
                        >
                            <Checkbox checked={state.arrearsStatuses.indexOf(1) > -1} className={classes.statusCheckbox} />
                            <ListItemText primary={`0 ${t('module.client.arrears.filter.daysLate')}`} />
                        </MenuItem>
                        <MenuItem
                            value={2}
                            className={classes.statusDropdown}
                            style={{
                                backgroundColor: '#fff6ee',
                                borderColor: '#faa05a'
                            }}
                        >
                            <Checkbox checked={state.arrearsStatuses.indexOf(2) > -1} className={classes.statusCheckbox} />
                            <ListItemText primary={`7 ${t('module.client.arrears.filter.daysLate')}`} />
                        </MenuItem>
                        <MenuItem
                            value={3}
                            className={classes.statusDropdown}
                            style={{
                                backgroundColor: '#d8eafc',
                                borderColor: '#cacaca'
                            }}
                        >
                            <Checkbox checked={state.arrearsStatuses.indexOf(3) > -1} className={classes.statusCheckbox} />
                            <ListItemText primary={`14 ${t('module.client.arrears.filter.daysLate')}`} />
                        </MenuItem>
                        <MenuItem
                            value={4}
                            className={classes.statusDropdown}
                            style={{
                                backgroundColor: '#edfbf6',
                                borderColor: '#cacaca'
                            }}
                        >
                            <Checkbox checked={state.arrearsStatuses.indexOf(4) > -1} className={classes.statusCheckbox} />
                            <ListItemText primary={`21 ${t('module.client.arrears.filter.daysLate')}`} />
                        </MenuItem>
                        <MenuItem
                            value={5}
                            className={classes.statusDropdown}
                            style={{
                                backgroundColor: '#f6e9ff',
                                borderColor: '#cacaca'
                            }}
                        >
                            <Checkbox checked={state.arrearsStatuses.indexOf(5) > -1} className={classes.statusCheckbox} />
                            <ListItemText primary={`30 ${t('module.client.arrears.filter.daysLate')}`} />
                        </MenuItem>
                        <MenuItem
                            value={6}
                            className={classes.statusDropdown}
                            style={{
                                backgroundColor: '#fef4f6',
                                borderColor: '#cacaca'
                            }}
                        >
                            <Checkbox checked={state.arrearsStatuses.indexOf(6) > -1} className={classes.statusCheckbox} />
                            <ListItemText primary={`> 30 ${t('module.client.arrears.filter.daysLate')}`} />
                        </MenuItem>
                    </Select>
                )}
            </div>
            <div>
                {!isSearched ? (
                    <Button
                        onClick={handleSearch}
                        className={classes.button}
                        variant="contained"
                        color="primary"
                    >
                        {t('module.client.arrears.filter.search')}
                    </Button>
                ) : (
                    <>
                        <Button className={classes.editInfo} onClick={() => setIsSearched(false)}>{t('module.client.arrears.filter.editInfo')}</Button>
                        <CloseIcon
                            className={classes.closeIcon}
                            onClick={handleCloseFilter}
                            style={{ cursor: 'pointer' }}
                        />
                    </>
                )}
            </div>
        </div>
    )
}

export default FilterClient;

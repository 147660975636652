import React from 'react';
import slugify from 'slugify';
import { isEmpty, replace } from 'lodash';
import { Column } from 'react-base-table';
import BasicTable from '../../../../components/baseTable/BasicTable';
import { TableRowsActions } from './TableRowsActions';
import { uniqueBy } from '../../../../utils/uniqueBy';

export function GroupsTable({ data }) {
    const columns = [];
    const actions = [];

    if (isEmpty(data)) {
        return null;
    }

    let filteredData = [];
    data && data.forEach(item => filteredData.push({
      code: item.code,
      description: item.description,
      action: item.action
    }));

    Object.keys(filteredData[0]).map((item) => {
        const payload = {
            key: item,
            dataKey: item,
            title: replace(replace(item, '_', ' '), '/', ' '),
            width: 450,
            resizable: true,
            className: ({ column }) => {
                const { key } = column;
                return `${slugify(key).toLowerCase()}`;
            },
            headerRenderer: (cell) => {
                const { column } = cell;
                const { key } = column;
                switch (key) {
                case 'id':
                    return 'ID';
                default:
                    return replace(replace(key, '_', ' '), '/', ' ');
                }
            },
            cellRenderer: (cell) => {
                const {
                    cellData, column, rowData,
                } = cell;

                const cellRender = cellData;
                const { key } = column;

                switch (key) {
                case 'action':
                    return <TableRowsActions { ...rowData } actions={actions} />;
                default:
                    return cellRender;
                }
            },
        };

        if (item === 'action') {
            payload.width = 100;
            payload.frozen = Column.FrozenDirection.RIGHT;
            payload.className = ({ column }) => {
                const { key } = column;
                return (`${slugify(key).toLowerCase()} action-cell`);
            };
        }

        columns.push(payload);

        return item;
    });

    return (
        <BasicTable
            columns={columns}
            data={uniqueBy(data, 'id')}
            fixed
        />
    );
}

import React, { useState, useEffect } from "react"
import PersonOutlineOutlinedIcon from "@material-ui/icons/LockOutlined"
import { connect } from "react-redux"
import { Button, FormSelect, FormInput, FormGroup } from "shards-react"
import { isEmpty } from "lodash"
import { useTranslation } from "react-i18next"
import { modalActions } from "../../modals"
import { cslActions } from ".."
import Header from "../../../components/widget/widgetTitle"

import { ComputationCodesSetup } from "../views/Formula/ComputationCodes"
import { Assumptions } from "../views/Formula/Assumptions"
import { OtherCalculations } from "../views/Formula/OtherCalculations"
import { Tdcv } from "../views/Formula/Tdcv"
import { CashFlowVariable } from "../component/CashFlowVariable"

function AddScoringVariablesEntry({
  scoreLogic,
  modalID,
  data = {},
  dispatch,
}) {
  const { uid, scoringVariables } = scoreLogic
  const { t } = useTranslation()
  const scoringVariablesDataTypes = [
    {
      label: "Text",
      value: "TEXT",
    },
    {
      label: "Numeric",
      value: "NUMERIC",
    },
  ]

  // eslint-disable-next-line no-param-reassign
  data.surveyUuid = uid

  const {
    types,
    codes: selectedCodes,
    selectOne = {
      code: true,
      assumptions: true,
      tdcv: false,
      emcv: false,
      otherCalculations: true,
    },
  } = data

  const [inputs, setInputs] = useState(data)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)

  useEffect(() => {
    setSubmitButtonDisabled(!shouldEnableResetButton(inputs))
  }, [inputs])

  function shouldEnableResetButton(fields) {
    return (
      fields.title && fields.id && fields.dataType && !isEmpty(fields.codes)
    )
  }

  const handleChange = async (e) => {
    const { name, value } = e.target
    setInputs((prevState) => ({ ...prevState, [name]: value }))
  }

  const childComponentHandler = (type, fields) => {
    setInputs((prevState) => ({ ...prevState, codes: fields[type] }))
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  const addSelection = () => {
    dispatch(cslActions.addScoringVariablesEntry(inputs))
    closeModal()
  }

  return (
    <div className="site-modal cashflow-statements__add-metric">
      <Header
        title={t(
          "module.scoreLogic.modal.addScoringVariablesEntry.headerTitle"
        )}
        icon={<PersonOutlineOutlinedIcon />}
        close={true}
        closeHandler={closeModal}
      />

      <div className="w-full p-3">
        <div className="block">
          <FormGroup>
            <label htmlFor="id">
              {t(
                "module.scoreLogic.modal.addScoringVariablesEntry.scorecardCategory"
              )}
            </label>
            <FormSelect
              name="id"
              required
              size="sm"
              className={"select"}
              onChange={handleChange}
            >
              <option key={-1} required value="">
                {t(
                  "module.scoreLogic.modal.addScoringVariablesEntry.selectCategory"
                )}
              </option>
              {scoringVariables.map((item) => {
                const { id, title } = item
                return (
                  <option key={id} value={id}>
                    {title}
                  </option>
                )
              })}
            </FormSelect>
          </FormGroup>

          <FormGroup>
            <label htmlFor="title">
              {t("module.scoreLogic.modal.addScoringVariablesEntry.title")}
            </label>
            <FormInput
              name="title"
              size="lg"
              className="mb-2"
              onChange={handleChange}
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="dataType">
              {t(
                "module.scoreLogic.modal.addScoringVariablesEntry.scorecardDataTypeLabel"
              )}
            </label>
            <FormSelect
              name="dataType"
              size="sm"
              className={"select"}
              onChange={handleChange}
            >
              <option key={-1} value="">
                {t(
                  "module.scoreLogic.modal.addScoringVariablesEntry.scorecardDataType"
                )}
              </option>
              {scoringVariablesDataTypes.map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                )
              })}
            </FormSelect>
          </FormGroup>

          {types.includes("code") && (
            <div className="flex">
              <ComputationCodesSetup
                scoreLogic={scoreLogic}
                modalID={modalID}
                content={data}
                handler={childComponentHandler}
                selected={selectedCodes}
                selectOne={selectOne.code}
              />
            </div>
          )}

          {types.includes("assumptions") && (
            <div className="flex">
              <Assumptions
                scoreLogic={scoreLogic}
                modalID={modalID}
                content={data}
                handler={childComponentHandler}
                selected={selectedCodes}
                selectOne={selectOne.assumptions}
              />
            </div>
          )}

          {types.includes("tdcv") && (
            <div className="flex">
              <Tdcv
                type="TIME_DEPENDENT_CALCULATED_VARIABLE"
                scoreLogic={scoreLogic}
                modalID={modalID}
                content={data}
                handler={childComponentHandler}
                selected={selectedCodes}
                selectOne={selectOne.tdcv}
              />
            </div>
          )}

          {types.includes("emcv") && (
            <div className="flex">
              <Tdcv
                type="EVERY_MONTH_CALCULATED_VARIABLE"
                scoreLogic={scoreLogic}
                modalID={modalID}
                content={data}
                handler={childComponentHandler}
                selected={selectedCodes}
                selectOne={selectOne.emcv}
              />
            </div>
          )}

          {types.includes("otherCalculations") && (
            <div className="flex">
              <OtherCalculations
                type="SINGLE_CALCULATION"
                scoreLogic={scoreLogic}
                modalID={modalID}
                content={data}
                handler={childComponentHandler}
                selected={selectedCodes}
                selectOne={selectOne.otherCalculations}
              />
            </div>
          )}

          <div className="flex">
            <OtherCalculations
              type="MPESA_CALCULATION"
              scoreLogic={scoreLogic}
              modalID={modalID}
              content={data}
              handler={childComponentHandler}
              selected={selectedCodes}
              selectOne
              mpesa
            />
          </div>

          {types.includes("cashflowVariable") && (
            <div className="flex">
              <CashFlowVariable
                type="CASHFLOW_VARIABLE"
                scoreLogic={scoreLogic}
                modalID={modalID}
                content={data}
                handler={childComponentHandler}
                selected={selectedCodes}
                selectOne={selectOne.cashflowVariable}
              />
            </div>
          )}
        </div>

        <div className="flex">
          <Button
            theme="primary"
            disabled={submitButtonDisabled}
            className="ml-auto"
            onClick={() => {
              addSelection()
            }}
          >
            {t("module.scoreLogic.modal.addScoringVariablesEntry.addSelection")}
          </Button>
          <Button
            theme="secondary"
            className="ml-2"
            onClick={() => {
              closeModal()
            }}
          >
            {t("module.scoreLogic.modal.addScoringVariablesEntry.cancel")}
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { scoreLogic, modals } = state
  return {
    scoreLogic,
    modals,
  }
}

const connectedAddScoringVariablesEntry = connect(mapStateToProps)(
  AddScoringVariablesEntry
)
export { connectedAddScoringVariablesEntry as AddScoringVariablesEntry }

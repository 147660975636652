import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {Container, Row, Col, Button} from 'shards-react';
import Svg from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../components/common/PageTitle';
import { SetQuestionnaire } from '../../scoreLogic/views/SetQuestionnaire';
import { SetExportFormat } from '../components/SetExportFormat';

import { toolsActions } from '..';
import Header from '../../../components/widget/widgetTitle';

const exportIconSVG = require('../../../assets/images/icons/export-header-icon.svg');

const Export = ({exportFormat, includeStatus, dispatch, uid}) => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const { t } = useTranslation()

    useEffect(() => {
        setSubmitted(false);
    }, [uid, exportFormat, includeStatus]);

    useEffect(() => {
        function shouldEnableSubmitButton() {
            return !submitted && exportFormat && uid;
        }

        setSubmitButtonDisabled(!shouldEnableSubmitButton());
    }, [submitted, uid, exportFormat]);

    const exportData = () => {
        setSubmitted(true);
        // eslint-disable-next-line no-unused-expressions
        !submitButtonDisabled && dispatch(toolsActions.exportApplications({ surveyUuid: uid, exportFormat, includeStatus }));
    };

    return (
        <Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle title={t('module.applicationManager.component.applicationsExport.button')} className="text-sm-left mb-3" />
            </Row>
            <Row className="bg-white">
                <Header title="Export" icon={<Svg className="text-center d-inline-block" src={ exportIconSVG } />}/>
            </Row>
            <Row className="bg-white h-full">
                <Col md="12" lg="12" className="mt-4 p-0 relative w-full h-full">
                    <div className="p-4">
                        <SetQuestionnaire desc={t('module.applicationManager.component.applicationsExport.subTitle')} />
                        <SetExportFormat />
                    </div>
                    <div className="wizard-actions">
                        <Button className="ml-auto" onClick={ exportData } disabled={ submitButtonDisabled }>{t('module.applicationManager.component.applicationsExport.button')}</Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

function mapStateToProps(state) {
    const { tools, scoreLogic } = state;
    const { exportFormat, includeStatus } = tools;
    const { uid } = scoreLogic;
    return { exportFormat, includeStatus, uid };
}

const connectedExport = connect(mapStateToProps)(Export);
export { connectedExport as Export };

import { isEmpty } from "lodash"
import { commentsConstants } from "./_constants"
import { commentsService } from "./_service"

export const commentActions = {
  addComment,
  getAll,
}

function addComment(id, comment, base64Image) {
  return (dispatch) => {
    dispatch(request({}))

    try {
      if (isEmpty(id)) {
        throw new Error("Application ID cannot be empty")
      }

      if (isEmpty(comment)) {
        throw new Error("Comment cannot be empty")
      }

      commentsService.addComment(id, comment, base64Image).then(
        (data) => {
          dispatch(success(data))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request(data) {
    return { type: commentsConstants.COMMENTS_SUBMIT_REQUEST, data }
  }
  function success(data) {
    return { type: commentsConstants.COMMENTS_SUBMIT_SUCCESS, data }
  }
  function failure(error) {
    return { type: commentsConstants.COMMENTS_SUBMIT_FAILURE, error }
  }
}

function getAll(id) {
  return (dispatch) => {
    dispatch(request({}))

    try {
      if (isEmpty(id)) {
        throw new Error("Application ID cannot be empty")
      }

      commentsService.getAll(id).then(
        (data) => {
          dispatch(success(data))
        },
        (error) => {
          dispatch(failure(error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request(data) {
    return { type: commentsConstants.COMMENTS_GETALL_REQUEST, data }
  }
  function success(data) {
    return { type: commentsConstants.COMMENTS_GETALL_SUCCESS, data }
  }
  function failure(error) {
    return { type: commentsConstants.COMMENTS_GETALL_FAILURE, error }
  }
}

import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Select, MenuItem } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { Container, Row, Button } from "shards-react"
import { AgGridReact } from "ag-grid-react"
import { useTranslation } from "react-i18next"
import cx from "classnames"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import PageTitle from "../../../components/common/PageTitle"
import { reportsActions } from "../_actions"
import { applicationActions } from "../../applicationManager"
import { modalActions } from "../../modals"
import styles from "./SummaryOverview.styles"
import { LoadingView } from "../../../views"

export const PaymentsReport = withStyles(styles)(({ classes }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { paymentsSummary, loading } = useSelector((state) => state.reports)

  paymentsSummary.forEach((summary) => {
    summary.avgPricePerKg = Number(summary.avgPricePerKg).toFixed(2)
  })

  const columnDefs = [
    { headerName: "Branch Name", field: "cwsName" },
    { headerName: "Quantity(Kgs)", field: "quantity" },
    { headerName: "Avg. Price/Kg", field: "avgPricePerKg" },
    { headerName: "Purchase Amount(UGX)", field: "purchaseAmount" },
    { headerName: "Advance Recovered(UGX)", field: "deduction" },
    { headerName: "Value of Coffee(UGX)", field: "totalAmount" },
    { headerName: "Commission Paid(UGX)", field: "commission" },
    { headerName: "Total Amount Paid(UGX)", field: "amountDisbursed" },
  ]

  useEffect(() => {
    dispatch(reportsActions.getPaymentsSummaryReport())
  }, [])

  const handleSummaryReportSelect = (e) => {
    const value = e.target.value
    if (value !== "xlsx" && value !== "csv") return
    dispatch(applicationActions.generatePurchaseSummaryReport(value))
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4 items-end justify-between">
        <PageTitle
          title={"Payments Summary Report"}
          subtitle={"Dashboard"}
          className="text-sm-left mb-3"
        />
        <div className="flex">
          <Button
            theme="secondary"
            className="mx-2 d-table ml-auto btn btn-filter"
            onClick={() =>
              dispatch(modalActions.paymentReportFilter("Coffee Grade"))
            }
          >
            Filter By Coffee Grade
          </Button>
          <Button
            theme="secondary"
            className="mx-2 d-table ml-auto btn btn-filter"
            onClick={() => dispatch(modalActions.paymentReportFilter("Region"))}
          >
            Filter By Region
          </Button>
          <Button
            theme="secondary"
            className="mx-2 d-table ml-auto btn btn-filter"
            onClick={() =>
              dispatch(
                modalActions.reportsDateFilter({ purchaseSummary: true })
              )
            }
          >
            {t("module.report.filterByDate")}
          </Button>
          <Select
            classes={{ root: classes.selectRoot }}
            variant="outlined"
            value="Download Summary Report"
            onChange={handleSummaryReportSelect}
            className={cx("ml-2")}
          >
            <MenuItem value="Download Summary Report">
              Download Summary Report
            </MenuItem>
            <MenuItem value="csv">csv</MenuItem>
            <MenuItem value="xlsx">xlsx</MenuItem>
          </Select>
        </div>
      </Row>
      {loading ? (
        <LoadingView />
      ) : (
        <div
          className="ag-theme-alpine"
          style={{ height: "600px", width: "100%" }}
        >
          <AgGridReact
            rowData={paymentsSummary}
            columnDefs={columnDefs}
            pagination={true}
            paginationAutoPageSize={true}
            suppressRowClickSelection={true}
          />
        </div>
      )}
    </Container>
  )
})

import React from "react"
import PersonOutlineOutlinedIcon from "@material-ui/icons/LockOutlined"
import { connect } from "react-redux"
import { ButtonGroup, Button } from "shards-react"
import Header from "../../../components/widget/widgetTitle"
import { configActions } from ".."
import { modalActions } from "../../modals"

const DeleteReportColumn = ({ data, modalID, dispatch }) => {
  const { id, profileView, cbsView } = data

  // eslint-disable-next-line no-underscore-dangle
  const _delete = () => {
    if (profileView) {
      dispatch(configActions.deleteProfileField(id))
    } else if (cbsView) {
      dispatch(configActions.deleteCbsField(id))
    } else {
      dispatch(configActions.deleteReportColumn(id))
    }
    closeModal()
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  let title = "Are you sure you want to delete the selected Report Column?"
  if (profileView)
    title = "Are you sure you want to delete the selected Profile Field?"
  else if (cbsView)
    title = "Are you sure you want to delete the selected CBS Field?"

  return (
    <div className="site-modal w-5">
      <Header
        title="Delete Checklist"
        icon={<PersonOutlineOutlinedIcon />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        <p>{title}</p>
        <ButtonGroup className="btn-group-actions site-modal__actions">
          <Button
            theme="primary"
            className="thin delete mr-2"
            onClick={() => {
              _delete()
            }}
          >
            Yes
          </Button>
          <Button
            theme="secondary"
            className="thin"
            onClick={() => {
              closeModal()
            }}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

const connectedDeleteReportColumn = connect(null)(DeleteReportColumn)
export { connectedDeleteReportColumn as DeleteReportColumn }

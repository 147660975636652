/* eslint-disable no-underscore-dangle */
import { isEmpty } from "lodash"
import { alertActions } from "../alert"
import { userManagerConstants } from "./_constants"
import { userManagerService } from "./_service"

export const userManagerActions = {
  mfiAdminRegister,
  mfiAdminUpdate,
  faAnalystRegister,
  getAll,
  delete: _delete,
  activate,
  deactivate,
  getLoanOfficersByHierarchy,
}

function mfiAdminRegister(userData) {
  return (dispatch) => {
    dispatch(request(userData))

    userManagerService.mfiAdminRegister(userData).then(
      () => {
        dispatch(success())
        dispatch(alertActions.success("Registration successful"))
        setTimeout(() => {
          window.location.href = "/users"
        }, 900)
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error))
      }
    )
  }

  function request(user) {
    return { type: userManagerConstants.REGISTER_REQUEST, user }
  }
  function success(user) {
    return { type: userManagerConstants.REGISTER_SUCCESS, user }
  }
  function failure(error) {
    return { type: userManagerConstants.REGISTER_FAILURE, error }
  }
}

function mfiAdminUpdate(id, userData, status = "", onSuccessCallback) {
  return (dispatch) => {
    dispatch(request(userData))

    try {
      if (isEmpty(id)) {
        throw new Error("Invalid id")
      }

      if (isEmpty(userData)) {
        throw new Error("Invalid data")
      }

      userManagerService
        .mfiAdminUpdate(id, userData)
        .then(() => {
          if (status === true) {
            dispatch(activate(id, onSuccessCallback))
          } else if (status === false) {
            dispatch(deactivate(id, onSuccessCallback))
          }
          setTimeout(() => {
            window.location.href = "/users"
          }, 900)
        })
        .then(() => {
          // dispatch(success({ id, ...userData }));
        })
        .catch((error) => {
          dispatch(failure(error))
        })
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request(data) {
    return { type: userManagerConstants.USER_UPDATE_REQUEST, data }
  }
  // function success(data) { return { type: userManagerConstants.USER_UPDATE_SUCCESS, data }; }
  function failure(error) {
    return { type: userManagerConstants.USER_UPDATE_FAILURE, error }
  }
}

function faAnalystRegister(userData) {
  return (dispatch) => {
    dispatch(request(userData))

    userManagerService.faAnalystRegister(userData).then(
      () => {
        dispatch(success())
        dispatch(alertActions.success(userData.message))
        setTimeout(() => {
          window.location = "/users"
        }, 900)
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error))
      }
    )
  }

  function request(user) {
    return { type: userManagerConstants.REGISTER_REQUEST, user }
  }
  function success(user) {
    return { type: userManagerConstants.REGISTER_SUCCESS, user }
  }
  function failure(error) {
    return { type: userManagerConstants.REGISTER_FAILURE, error }
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request({}))
    userManagerService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return { type: userManagerConstants.GETALL_REQUEST }
  }
  function success(users) {
    return { type: userManagerConstants.GETALL_SUCCESS, users }
  }
  function failure(error) {
    return { type: userManagerConstants.GETALL_FAILURE, error }
  }
}

function getLoanOfficersByHierarchy(data) {
  return (dispatch) => {
    dispatch(request({}))
    userManagerService.getLoanOfficersByHierarchy(data).then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error))
    )
  }

  function request() {
    return { type: userManagerConstants.GETALL_LO_REQUEST }
  }
  function success(users) {
    return { type: userManagerConstants.GETALL_LO_SUCCESS, users }
  }
  function failure(error) {
    return { type: userManagerConstants.GETALL_LO_FAILURE, error }
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(userId) {
  return (dispatch) => {
    dispatch(request(userId))

    userManagerService.delete(userId).then(
      () => dispatch(success(userId)),
      (error) => dispatch(failure(userId, error))
    )
  }

  function request(id) {
    return { type: userManagerConstants.DELETE_REQUEST, id }
  }
  function success(id) {
    return { type: userManagerConstants.DELETE_SUCCESS, id }
  }
  function failure(id, error) {
    return { type: userManagerConstants.DELETE_FAILURE, id, error }
  }
}

function activate(userId, onSuccessCallback) {
  return (dispatch) => {
    userManagerService.activate(userId).then(
      () => {
        dispatch(success(userId))
        onSuccessCallback && onSuccessCallback()
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function success(id) {
    return { type: userManagerConstants.USER_ACTIVATE, id }
  }
  function failure(id) {
    return { type: userManagerConstants.USER_ACTIVATE, id }
  }
}

function deactivate(userId, onSuccessCallback) {
  return (dispatch) => {
    userManagerService.deactivate(userId).then(
      () => {
        dispatch(success(userId))
        onSuccessCallback && onSuccessCallback()
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function success(id) {
    return { type: userManagerConstants.USER_DEACTIVATE, id }
  }
  function failure(id) {
    return { type: userManagerConstants.USER_DEACTIVATE, id }
  }
}

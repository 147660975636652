import React from "react"
import { replace } from "lodash"
import { Column } from "react-base-table"
import { Badge } from "shards-react"
import slugify from "slugify"
import { ApplicationTableRowsActions } from "./ApplicationTableRowsActions"

const generatePayload = (
  col,
  sortableColumns,
  tableHeader,
  tableHeaderRaw,
  hasWorkflow,
  actions,
  sortButton,
  filterDateButton
) => {
  const cleanCol = replace(replace(col.name, "_", " "), "/", " ")

  // Excel download table header
  tableHeader.push({ text: replace(replace(col.name, "_", " "), "/", " ") })
  tableHeaderRaw.push({ text: col.name })

  // validate column sort
  let sortable = false
  let dataType
  if (
    sortableColumns.filter((item) => item.columnTitle === col.key).length > 0
  ) {
    sortable = true
    dataType = sortableColumns.filter((item) => item.columnTitle === col.key)[0]
      .dataType
  }

  const payload = {
    key: col.key,
    dataKey: col.key,
    title: col.name,
    width: 150,
    resizable: true,
    className: ({ column }) => {
      const { key } = column
      return `${slugify(key).toLowerCase()}`
    },
    headerRenderer: (cell) => {
      const { column } = cell
      const { key } = column
      switch (key) {
        case "action":
          return "Action"
        default:
          if (dataType === "DATE") {
            return filterDateButton(key)
          }
          return sortButton(sortable, cleanCol, col.key, dataType)
      }
    },
    cellRenderer: (cell) => {
      const { cellData, column, rowData } = cell
      const {
        _workflowStatus,
        _stage,
        _xformId,
        _xformversion,
        _surveyStatus,
      } = rowData
      const cellRender = cellData
      const stage = _stage
      const status = _workflowStatus
      const surveyStatus = _surveyStatus

      const { key } = column

      switch (key) {
        case "action":
          return (
            <ApplicationTableRowsActions
              {...rowData}
              actions={actions}
              surveyStatus={surveyStatus}
              status={status}
              stage={stage}
              xformId={_xformId}
              xformversion={_xformversion}
              hasWorkflow={hasWorkflow}
            />
          )
        case "_stage":
          return (
            <Badge
              theme="complete"
              className={`badge-xs ${slugify(cellData).toLowerCase()}`}
            >
              {cellData &&
                replace(cellData.toLowerCase(), new RegExp("_", "g"), " ")}
            </Badge>
          )
        case "_workflowStatus":
          return (
            <Badge
              theme="complete"
              className={`badge-xs ${slugify(cellData).toLowerCase()}`}
            >
              {cellData && cellData.toLowerCase()}
            </Badge>
          )
        case "_surveyStatus":
          return cellData === "Complete" ? (
            cellRender
          ) : (
            <Badge
              theme="complete"
              className={`badge-xs ${slugify(cellData).toLowerCase()}`}
            >
              {cellData && cellData}
            </Badge>
          )
        default:
          return cellRender
      }
    },
  }

  if (col.key === "action") {
    payload.width = 100
    payload.frozen = Column.FrozenDirection.RIGHT
    payload.className = ({ column }) => {
      const { key } = column
      return `${slugify(key).toLowerCase()} action-cell`
    }
  }
  return { payload, tableHeader, tableHeaderRaw }
}

export default generatePayload

import React, { useRef, useState, useCallback, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { AgGridReact } from "ag-grid-react"
import DoneIcon from "@material-ui/icons/Done"
import CloseIcon from "@material-ui/icons/Close"
import UpdateIcon from "@material-ui/icons/Update"
import {
  Select,
  MenuItem,
  Button,
  Typography,
  Checkbox,
} from "@material-ui/core"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import "./ApplicationTable.scss"
import { LoadingView } from "../../../views"
import { modalActions } from "../../modals"
import PaymentDetailsDialog from "./PaymentDetailsDialog"
import formatNumber from "../../../utils/FormatNumber"

export function ApplicationPaymentTable({
  payments,
  headOfficeAcc,
  managementAccountant,
  setTabText,
}) {
  const pendingGridRef = useRef()
  const disburseGridRef = useRef()
  const declineGridRef = useRef()
  const dispatch = useDispatch()

  const {
    profile: { roles },
  } = useSelector((state) => state.authentication)

  const [rmPendingPayments, setRmPendingPayments] = useState([])
  const [maccPendingPayments, setMaccPendingPayments] = useState([])
  const [accPendingPayments, setAccPendingPayments] = useState([])
  const [failedPayments, setFailedPayments] = useState([])
  const [disbursedPayments, setDisbursedPayments] = useState([])
  const [declinedPayments, setDeclinedPayments] = useState([])
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null)

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [dialogData, setDialogData] = useState({})

  const openDialog = (data) => {
    setDialogData(data)
    setIsDialogOpen(true)
  }

  const closeDialog = () => {
    setIsDialogOpen(false)
  }

  payments.forEach((payment) => {
    payment["addCharge"] = "addCharge"
    payment["main"] = "main"
  })

  useEffect(() => {
    if (payments) {
      setRmPendingPayments(
        payments.filter((payment) => payment.status === "RM_APPROVAL")
      )
      setMaccPendingPayments(
        payments.filter((payment) => payment.status === "MGT_APPROVAL")
      )
      setAccPendingPayments(
        payments.filter((payment) => payment.status === "PENDING_DISBURSEMENT")
      )
      setFailedPayments(
        payments.filter((payment) => payment.status === "INCOMPLETE")
      )
      setDisbursedPayments(
        payments.filter((payment) => payment.status === "DISBURSED")
      )
      setDeclinedPayments(
        payments.filter((payment) => payment.status === "DECLINED")
      )
    }
  }, [payments])

  function onGridReady(params) {
    const { api, columnApi } = params
    const addChargeColumn = columnApi.getColumn("addCharge")
    api.forEachNode((node) => {
      if (node.data.status === "DISBURSED" || node.data.status === "DECLINED") {
        columnApi.setColumnVisible(addChargeColumn, false)
      }
    })
  }

  const applications = useSelector((state) => state.applications)
  const { loading } = applications

  const [selectedRows, setSelectedRows] = useState([])
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    setTabText()
  }, [tabIndex])

  const onCellClicked = (params) => {
    if (params.colDef.field === "id") {
      setDialogData(params.data)
      openDialog(params.data)
    }
  }

  const SelectBoxRenderer = ({ value, data, api }) => {
    const handleSelectChange = (event) => {
      const newValue = event.target.value
      const {
        id,
        mobileNo,
        amountToDisburse,
        farmerName,
        farmerCode,
        coffeeGrade,
        quantity,
        priceKg,
        deduction,
        commission,
        workflowUser,
        receivedBy,
        withdrawalCharge,
      } = data
      if (newValue === "Pay") {
        const payload = [
          {
            id,
            mobileNo,
            amountToDisburse,
            farmerName,
            farmerCode,
            coffeeGrade,
            quantity,
            priceKg,
            deduction,
            commission,
            workflowUser,
            receivedBy,
            withdrawalCharge,
            status: "DISBURSED",
          },
        ]
        const payloadData = { payload, disburse: true }
        dispatch(modalActions.paymentMethod(payloadData))
      } else if (newValue === "Approve") {
        const payload = [
          {
            id,
            mobileNo,
            amountToDisburse,
            farmerName,
            farmerCode,
            coffeeGrade,
            quantity,
            priceKg,
            deduction,
            commission,
            workflowUser,
            receivedBy,
            status: managementAccountant ? "MGT_APPROVAL" : "RM_APPROVAL",
          },
        ]
        const payloadData = { payload, approve: true }
        dispatch(modalActions.payDecline(payloadData))
      } else if (newValue === "Decline" || newValue === "Refuse") {
        const payload = [
          {
            id,
            mobileNo,
            amountToDisburse,
            farmerName,
            farmerCode,
            coffeeGrade,
            quantity,
            priceKg,
            deduction,
            commission,
            workflowUser,
            receivedBy,
            status: "DECLINED",
          },
        ]
        const payloadData = { payload, decline: true }
        dispatch(modalActions.payDecline(payloadData))
      }
      data.status = newValue
      api.applyTransaction({ update: [data] })
    }

    const { status } = data

    if (status === "DISBURSED") {
      return <Typography className="actioned-text">Disbursed</Typography>
    }

    if (status === "DECLINED") {
      return <Typography className="declined-text">Declined</Typography>
    }

    if (status === "INCOMPLETE") {
      return <Typography className="incomplete-text">Incomplete</Typography>
    }

    return (
      <Select
        disableUnderline
        defaultValue="actions"
        value={value}
        onChange={handleSelectChange}
        disabled={
          (headOfficeAcc && !managementAccountant) ||
          (status === "RM_APPROVAL" && managementAccountant) ||
          (status === "PENDING_DISBURSEMENT" &&
            (managementAccountant || roles?.includes("MFI_SUPERVISOR")))
        }
      >
        <MenuItem value="actions">Actions</MenuItem>
        {roles?.includes("MFI_SUPERVISOR") || managementAccountant
          ? [
              <MenuItem value="Approve">
                Approve <DoneIcon className="done-icon" />
              </MenuItem>,
              <MenuItem value="Refuse">
                Refuse <CloseIcon className="decline-icon" />
              </MenuItem>,
            ]
          : [
              <MenuItem value="Pay">
                Pay <DoneIcon className="done-icon" />
              </MenuItem>,
              <MenuItem value="Decline">
                Decline <CloseIcon className="decline-icon" />
              </MenuItem>,
            ]}
      </Select>
    )
  }

  const ChargeCellRenderer = ({ value, data, api }) => {
    const handleChargeChange = (e) => {
      const { computedTelcoCharge, amountToDisburse } = data
      if (e.target.checked) {
        data.withdrawalCharge = computedTelcoCharge
        data.amountToDisburse = amountToDisburse + computedTelcoCharge
        api.applyTransaction({ update: [data] })
      } else {
        data.withdrawalCharge = 0
        data.amountToDisburse = amountToDisburse - computedTelcoCharge
        api.applyTransaction({ update: [data] })
      }
    }
    return <Checkbox onChange={handleChargeChange} />
  }

  const handleMouseEnter = (rowIndex) => {
    setHoveredRowIndex(rowIndex)
  }

  const handleMouseLeave = () => {
    setHoveredRowIndex(null)
  }

  const handleUpdateMobileNo = (params) => {
    const { id, mobileNo, farmerCode } = params.data
    dispatch(modalActions.confirmMobileUpdate({ id, mobileNo, farmerCode }))
  }

  const cellRendererUpdateIcon = (params) => {
    const isHovered = params.rowIndex === hoveredRowIndex
    return (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onMouseEnter={() => handleMouseEnter(params.rowIndex)}
        onMouseLeave={handleMouseLeave}
      >
        {params.value}
        {isHovered && (
          <UpdateIcon
            onClick={() => handleUpdateMobileNo(params)}
            fontSize="small"
            color="secondary"
            className="ml-1"
          />
        )}
      </div>
    )
  }

  const columns = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerCheckboxSelectionCurrentPageOnly: true,
      width: 40,
      suppressMenu: true,
      pinned: "left",
      field: "main",
    },
    { field: "id", width: 150, sortable: true },
    {
      field: "status",
      width: 150,
      sortable: true,
    },
    {
      field: "receptionDate",
      width: 150,
      sortable: true,
    },
    { field: "farmerCode", sortable: true, width: 150 },
    {
      field: "farmerName",
      width: 200,
      sortable: true,
    },
    {
      field: "fieldOfficer",
      width: 150,
      sortable: true,
    },
    { field: "mobileNo", width: 200, cellRenderer: cellRendererUpdateIcon },
    {
      field: "cwsName",
      width: 150,
      sortable: true,
    },
    {
      field: "coffeeGrade",
      width: 200,
    },
    {
      field: "grnNo",
      width: 150,
      sortable: true,
    },
    {
      field: "quantity",
      width: 150,
      cellDataType: "number",
      sortable: true,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "priceKg",
      width: 150,
      cellDataType: "number",
      sortable: true,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "purchaseAmount",
      width: 150,
      cellDataType: "number",
      sortable: true,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "commission",
      width: 150,
      cellDataType: "number",
      sortable: true,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "deduction",
      width: 150,
      cellDataType: "number",
      sortable: true,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "totalAmount",
      width: 150,
      cellDataType: "number",
      sortable: true,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      width: 140,
      headerName: "Add Charge",
      field: "addCharge",
      cellRenderer: ChargeCellRenderer,
    },
    {
      field: "withdrawalCharge",
      width: 180,
      cellDataType: "number",
      sortable: true,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "amountToDisburse",
      width: 180,
      cellDataType: "number",
      sortable: true,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "beyonicRef",
      width: 150,
      sortable: true,
    },
    {
      field: "receipt",
      width: 150,
    },
    {
      field: "beyonicSms",
      headerName: "Beyonic SMS",
      width: 150,
      tooltipField: "beyonicSms",
      cellRenderer: (params) => {
        return params.data.beyonicSms
      },
    },
    {
      field: "beyonicStatus",
      width: 200,
      sortable: true,
      tooltipField: "beyonicStateReason",
    },
    {
      field: "source",
      width: 150,
      sortable: true,
    },
    {
      field: "actions",
      width: 150,
      pinned: "right",
      cellRenderer: SelectBoxRenderer,
      cellStyle: (params) => ({ display: "flex", alignItems: "center" }),
    },
  ]

  const isRowSelectable = (rowNode) =>
    rowNode.data ? rowNode.data.status === "PENDING_DISBURSEMENT" : false

  const getRowStyle = (rowNode) => {
    if (rowNode.data?.status === "INCOMPLETE") {
      return { color: "red" }
    }
  }
  const onSelectionChanged = useCallback((event) => {
    setSelectedRows(event.api.getSelectedRows())
  }, [])

  const onBulkSubmit = () => {
    const payload = selectedRows.map((row) => {
      const {
        id,
        mobileNo,
        amountToDisburse,
        farmerName,
        farmerCode,
        coffeeGrade,
        quantity,
        priceKg,
        deduction,
        commission,
      } = row
      return {
        id,
        mobileNo,
        amountToDisburse,
        farmerName,
        farmerCode,
        coffeeGrade,
        quantity,
        priceKg,
        deduction,
        commission,
        status: "Disburse",
      }
    })
    const payloadData = { payload, disburse: true }
    dispatch(modalActions.payDecline(payloadData))
  }

  if (loading) {
    return <LoadingView />
  }

  const getColumnsForTab = (tabIndex) => {
    if (tabIndex === 0) {
      // Pending/Failed tab
      const columnsToHide = [
        "beyonicSms",
        "beyonicRef",
        "beyonicStatus",
        "coffeeGrade",
        "receipt",
        "source",
      ]
      return columns.filter((column) => !columnsToHide.includes(column.field))
    } else {
      // Disbursed and Declined tabs
      return columns
    }
  }

  return (
    <>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(index) => {
          setTabIndex(index)
        }}
      >
        <TabList>
          {roles?.includes("MFI_SUPERVISOR") && (
            <>
              <Tab>My Pending Approvals</Tab>
              <Tab>Incomplete</Tab>
            </>
          )}
          {managementAccountant && <Tab>My Pending Approvals</Tab>}
          {(roles?.includes("MFI_CREDIT_ANALYST") ||
            roles?.includes("MFI_SUPERVISOR")) &&
            !headOfficeAcc && (
              <>
                <Tab>Pending Disbursements</Tab>
                <Tab>Disbursed</Tab>
              </>
            )}
          {roles?.includes("MFI_CREDIT_ANALYST") && headOfficeAcc && (
            <>
              <Tab>Incomplete</Tab>
              <Tab>RM Pending Approvals</Tab>
              <Tab>Pending Disbursements</Tab>
              <Tab>Disbursed</Tab>
            </>
          )}
          <Tab>Declined</Tab>
        </TabList>
        {(roles?.includes("MFI_SUPERVISOR") || managementAccountant) && (
          <TabPanel>
            {roles?.includes("MFI_SUPERVISOR") && (
              <div
                style={{ height: 600, width: "100%" }}
                className="ag-theme-alpine"
              >
                <AgGridReact
                  ref={pendingGridRef}
                  rowData={rmPendingPayments}
                  columnDefs={getColumnsForTab(0)}
                  onGridReady={onGridReady}
                  isRowSelectable={isRowSelectable}
                  rowSelection="multiple"
                  onSelectionChanged={onSelectionChanged}
                  pagination={true}
                  paginationAutoPageSize={true}
                  getRowStyle={getRowStyle}
                  onCellClicked={onCellClicked}
                />
              </div>
            )}
            {managementAccountant && (
              <div
                style={{ height: 600, width: "100%" }}
                className="ag-theme-alpine"
              >
                <AgGridReact
                  ref={pendingGridRef}
                  rowData={maccPendingPayments}
                  columnDefs={getColumnsForTab(0)}
                  onGridReady={onGridReady}
                  isRowSelectable={isRowSelectable}
                  rowSelection="multiple"
                  onSelectionChanged={onSelectionChanged}
                  pagination={true}
                  paginationAutoPageSize={true}
                  getRowStyle={getRowStyle}
                  onCellClicked={onCellClicked}
                />
              </div>
            )}
            {roles?.includes("MFI_CREDIT_ANALYST") && !headOfficeAcc && (
              <>
                <div
                  style={{ height: 600, width: "100%" }}
                  className="ag-theme-alpine"
                >
                  <AgGridReact
                    ref={pendingGridRef}
                    rowData={accPendingPayments}
                    columnDefs={getColumnsForTab(0)}
                    onGridReady={onGridReady}
                    isRowSelectable={isRowSelectable}
                    rowSelection="multiple"
                    onSelectionChanged={onSelectionChanged}
                    pagination={true}
                    paginationAutoPageSize={true}
                    getRowStyle={getRowStyle}
                    onCellClicked={onCellClicked}
                    suppressRowClickSelection={true}
                  />
                </div>
                <Button
                  onClick={onBulkSubmit}
                  disabled={selectedRows.length === 0}
                  className={`btn-bulk-submit ${
                    selectedRows.length === 0 ? "btn-disabled" : ""
                  }`}
                >
                  Send Bulk Payments
                </Button>
              </>
            )}
          </TabPanel>
        )}
        {(roles?.includes("MFI_SUPERVISOR") || headOfficeAcc) && (
          <TabPanel>
            <div
              style={{ height: 600, width: "100%" }}
              className="ag-theme-alpine"
            >
              <AgGridReact
                ref={disburseGridRef}
                rowData={failedPayments}
                columnDefs={columns}
                onGridReady={onGridReady}
                isRowSelectable={isRowSelectable}
                rowSelection="multiple"
                onSelectionChanged={onSelectionChanged}
                pagination={true}
                paginationAutoPageSize={true}
                onCellClicked={onCellClicked}
              />
            </div>
          </TabPanel>
        )}
        {headOfficeAcc && (
          <TabPanel>
            <div
              style={{ height: 600, width: "100%" }}
              className="ag-theme-alpine"
            >
              <AgGridReact
                ref={disburseGridRef}
                rowData={rmPendingPayments}
                columnDefs={columns}
                onGridReady={onGridReady}
                isRowSelectable={isRowSelectable}
                rowSelection="multiple"
                onSelectionChanged={onSelectionChanged}
                pagination={true}
                paginationAutoPageSize={true}
                onCellClicked={onCellClicked}
              />
            </div>
          </TabPanel>
        )}
        {(roles?.includes("MFI_CREDIT_ANALYST") ||
          roles?.includes("MFI_SUPERVISOR")) && (
          <TabPanel>
            <div
              style={{ height: 600, width: "100%" }}
              className="ag-theme-alpine"
            >
              <AgGridReact
                ref={pendingGridRef}
                rowData={accPendingPayments}
                columnDefs={getColumnsForTab(0)}
                onGridReady={onGridReady}
                isRowSelectable={isRowSelectable}
                rowSelection="multiple"
                onSelectionChanged={onSelectionChanged}
                pagination={true}
                paginationAutoPageSize={true}
                getRowStyle={getRowStyle}
                onCellClicked={onCellClicked}
                suppressRowClickSelection={true}
              />
            </div>
          </TabPanel>
        )}
        {(roles?.includes("MFI_CREDIT_ANALYST") ||
          roles?.includes("MFI_SUPERVISOR")) && (
          <TabPanel>
            <div
              style={{ height: 600, width: "100%" }}
              className="ag-theme-alpine"
            >
              <AgGridReact
                ref={disburseGridRef}
                rowData={disbursedPayments}
                columnDefs={columns}
                onGridReady={onGridReady}
                isRowSelectable={isRowSelectable}
                rowSelection="multiple"
                onSelectionChanged={onSelectionChanged}
                pagination={true}
                paginationAutoPageSize={true}
                onCellClicked={onCellClicked}
              />
            </div>
          </TabPanel>
        )}
        <TabPanel>
          <div
            style={{ height: 600, width: "100%" }}
            className="ag-theme-alpine"
          >
            <AgGridReact
              ref={declineGridRef}
              rowData={declinedPayments}
              columnDefs={columns}
              onGridReady={onGridReady}
              isRowSelectable={isRowSelectable}
              rowSelection="multiple"
              onSelectionChanged={onSelectionChanged}
              pagination={true}
              paginationAutoPageSize={true}
              onCellClicked={onCellClicked}
            />
          </div>
        </TabPanel>
      </Tabs>
      <PaymentDetailsDialog
        isOpen={isDialogOpen}
        onClose={closeDialog}
        data={dialogData}
        tabIndex={tabIndex}
      />
    </>
  )
}

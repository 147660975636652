import React, { useState } from "react"
import { useDispatch } from "react-redux"
import PersonOutlineOutlinedIcon from "@material-ui/icons/FilterList"
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core"
import { Button, ButtonGroup } from "shards-react"
import { useTranslation } from "react-i18next"
import Header from "../../../components/widget/widgetTitle"
import "./DateFilter.scss"

import { reportsActions } from ".."
import { modalActions } from "../../modals"
import { alertActions } from "../../alert"

export function PaymentFilter({ modalID, data }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [reseting, setReseting] = useState(false)
  const [region, setRegion] = useState([])
  const [coffeeGrades, setCoffeeGrades] = useState([])

  const filterText = data

  const handleChange = (e) => {
    setRegion([e.target.value])
  }

  const handleCheckboxChange = (event) => {
    const value = event.target.value
    setCoffeeGrades((prevState) => {
      if (prevState.includes(value)) {
        // If the value is already in the array, remove it
        return prevState.filter((v) => v !== value)
      } else {
        // If the value is not in the array, add it
        return [...prevState, value]
      }
    })
  }

  const filter = () => {
    if (filterText === "Region" && region.length < 1) {
      dispatch(alertActions.error("Please select a region"))
      return
    }
    if (filterText === "Coffee Grade" && coffeeGrades.length < 1) {
      dispatch(alertActions.error("Please select coffee grades"))
      return
    }
    if (filterText === "Region") {
      setLoading(true)
      dispatch(reportsActions.filterGeneralPaymentSummaryReport(region))
      setLoading(false)
      closeModal()
    } else {
      setLoading(true)
      dispatch(reportsActions.filterGeneralPaymentSummaryReport(coffeeGrades))
      setLoading(false)
      closeModal()
    }
  }

  const reset = () => {
    setReseting(true)
    dispatch(reportsActions.getPaymentsSummaryReport())
    setReseting(false)
    closeModal()
  }

  const closeModal = () => dispatch(modalActions.hideModal(modalID))

  return (
    <div className="site-modal">
      <Header
        title={`Filter By ${filterText}`}
        icon={<PersonOutlineOutlinedIcon />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        {filterText === "Region" ? (
          <RadioGroup
            aria-label="filter-region"
            name="region"
            value={region[0]}
            onChange={handleChange}
          >
            <FormControlLabel
              value="Central"
              control={<Radio />}
              label="Central"
            />
            <FormControlLabel
              value="Western"
              control={<Radio />}
              label="Western"
            />
          </RadioGroup>
        ) : (
          <FormGroup>
            <FormControlLabel
              value="Parchment (Commercial)"
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={coffeeGrades.includes("Parchment (Commercial)")}
                />
              }
              label="Parchment (Commercial)"
            />
            <FormControlLabel
              value="FAQ - RFA/4C"
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={coffeeGrades.includes("FAQ - RFA/4C")}
                />
              }
              label="FAQ - RFA/4C"
            />
            <FormControlLabel
              value="Certified Cherry A"
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={coffeeGrades.includes("Certified Cherry A")}
                />
              }
              label="Certified Cherry A "
            />
            <FormControlLabel
              value="Drugar (Non-Certified)"
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={coffeeGrades.includes("Drugar (Non-Certified)")}
                />
              }
              label="Drugar (Non-Certified)"
            />
            <FormControlLabel
              value="FAQ - AAA/RFA"
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={coffeeGrades.includes("FAQ - AAA/RFA")}
                />
              }
              label="FAQ - AAA/RFA"
            />
            <FormControlLabel
              value="Non- Certified Cherry A"
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={coffeeGrades.includes("Non- Certified Cherry A")}
                />
              }
              label="Non- Certified Cherry A"
            />
            <FormControlLabel
              value="Non. Cert. Robusta Cherry"
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={coffeeGrades.includes("Non. Cert. Robusta Cherry")}
                />
              }
              label="Non. Cert. Robusta Cherry"
            />
            <FormControlLabel
              value="FAQ - Non Certified"
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={coffeeGrades.includes("FAQ - Non Certified")}
                />
              }
              label="FAQ - Non Certified"
            />
            <FormControlLabel
              value="Non- Certified Cherry B"
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={coffeeGrades.includes("Non- Certified Cherry B")}
                />
              }
              label="Non- Certified Cherry B"
            />
            <FormControlLabel
              value="FAQ - Certified"
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={coffeeGrades.includes("FAQ - Certified")}
                />
              }
              label="FAQ - Certified"
            />
          </FormGroup>
        )}
        <div className="btn-group-actions site-modal__actions flex relative">
          <ButtonGroup className="btn-group-actions site-modal__actions">
            <Button
              theme="primary"
              className="thin mr-2"
              onClick={() => filter()}
            >
              {loading
                ? t("module.report.filtering")
                : t("module.report.filter")}
            </Button>
            <Button
              theme="secondary"
              className="thin delete"
              onClick={() => reset()}
            >
              {reseting
                ? t("module.report.reseting")
                : t("module.report.reset")}
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import slugify from "slugify"
import { isEmpty, replace, omit } from "lodash"
import { Column } from "react-base-table"
import { useTranslation } from "react-i18next"
import { uniqueBy } from "../../../../utils/uniqueBy"
import BasicTable from "../../../../components/baseTable/BasicTable"
import { AddRoleAction } from "./AddRoleAction"
import { DeleteAction } from "./DeleteAction"
import { EditableCell } from "./EditableCell"
import { FlowEditor } from "./FlowEditor"
import { EmptyContentView } from "../../../../views"

export function WorkFlowTable({ data: rawData, refreshTable }) {
  const columns = []
  const { t } = useTranslation()
  if (isEmpty(rawData)) {
    return <EmptyContentView title={t("workflowGroup.noWorkflowFound")} />
  }

  const disallowedDataItems = ["categoryWorkflow", "mfiCode"]

  const data = rawData.map((item) => {
    const validTableItems = omit(item, disallowedDataItems)
    return {
      ...validTableItems,
      categoryWorkflow: "",
      addFlowAction: "",
      deleteAction: "",
    }
  })
  Object.keys(data[0]).map((item) => {
    const payload = {
      key: item,
      dataKey: item,
      title: replace(replace(item, "_", " "), "/", " "),
      width: 150,
      resizable: true,
      className: ({ column }) => {
        const { key } = column
        return `${slugify(key).toLowerCase()}`
      },
      headerRenderer: ({ column: { key } }) => {
        switch (key) {
          case "id":
            return t("module.scoreLogic.view.scoreLogic.approvalWorkflow.id")
          case "addFlowAction":
          case "deleteAction":
            return ""
          case "categoryWorkflow":
            return t("module.scoreLogic.view.scoreLogic.approvalWorkflow.flow")
          default:
            return replace(
              replace(
                t(`module.scoreLogic.view.scoreLogic.approvalWorkflow.${key}`),
                "_",
                " "
              ),
              "/",
              " "
            )
        }
      },
      cellRenderer: (cell) => {
        const { cellData, column } = cell
        const { key } = column

        switch (key) {
          case "lowerLimit":
          case "upperLimit":
            return <EditableCell {...cell} />
          case "automaticApproval":
            return <EditableCell {...cell} toggleBtn />
          case "categoryWorkflow":
            return <FlowEditor {...cell} />
          case "addFlowAction":
            return <AddRoleAction {...cell} />
          case "deleteAction":
            return <DeleteAction {...cell} />
          default:
            return cellData
        }
      },
    }

    switch (item) {
      case "id":
        payload.width = 80
        break
      case "categoryWorkflow":
        payload.width = 1000
        break
      case "deleteAction":
      case "addFlowAction":
        payload.resizable = false
        payload.width = 100
        payload.frozen = Column.FrozenDirection.RIGHT
        payload.className = ({ column }) => {
          const { key } = column
          return `${slugify(key).toLowerCase()} action-cell`
        }
        break
      default:
    }

    columns.push(payload)

    return item
  })

  return (
    <BasicTable
      columns={columns}
      data={uniqueBy(data, "id")}
      fixed
      refreshTable={refreshTable}
    />
  )
}

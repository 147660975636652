import React from "react"
import { useDispatch } from "react-redux"
import { withStyles } from "@material-ui/core"
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Switch,
  IconButton,
} from "@material-ui/core"
import { Edit, Delete } from "@material-ui/icons"
import { modalActions } from "../../../modals"
import { mfiManagerActions } from "../.."
import { menuPanel, mpesaPanel, imagePanel } from "./Constants"
import styles from "./CustomizationList.style"

export default withStyles(styles)(({ data, mfiId, classes, panel }) => {
  const dispatch = useDispatch()

  const titleName = panel === mpesaPanel ? "url" : "title"

  const editCustomization = (item) => {
    dispatch(
      modalActions.manageCustomization({
        mfiId,
        panel,
        edit: true,
        ...item,
        title: item.title || item.url,
      })
    )
  }

  const deleteImage = (item) => {
    dispatch(mfiManagerActions.deleteMfiImage(item))
  }

  const toogleEnabled = (e, item) => {
    const { description, id, enabled, componentName } = item
    const newPayload = {
      id,
      [titleName]: item.title || item.url,
      description,
      enabled: !enabled,
      componentName,
      mfiId,
    }
    if (panel === menuPanel) {
      dispatch(mfiManagerActions.editCustomMenus(newPayload))
    } else if (panel === mpesaPanel) {
      dispatch(mfiManagerActions.editMpesaDetails(newPayload))
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="menu table">
        <TableHead>
          <TableRow classes={{ root: classes.rowRoot }}>
            <TableCell>ID</TableCell>
            <TableCell>{panel}</TableCell>
            <TableCell>
              {panel === imagePanel ? "Thumbnail" : "Description"}
            </TableCell>
            <TableCell>
              {panel === menuPanel && "Enabled"}
              {panel === mpesaPanel && "Include Mpesa"}
            </TableCell>
            {panel === menuPanel && <TableCell>Template</TableCell>}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.id}</TableCell>
              <TableCell>{item.title || item.url}</TableCell>
              {item.description && <TableCell>{item.description}</TableCell>}
              {item.cloud_storage_url && (
                <TableCell>
                  <img
                    className={classes.imgWidth}
                    src={item.cloud_storage_url}
                    alt={item.title}
                  />
                </TableCell>
              )}
              {panel !== imagePanel && (
                <TableCell>
                  {item.enabled}
                  <Switch
                    onChange={(e) => toogleEnabled(e, item)}
                    checked={item.enabled}
                    color="primary"
                    name="enabled"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>
              )}
              {panel === menuPanel && (
                <TableCell>{item.componentName}</TableCell>
              )}
              <TableCell>
                <IconButton
                  color="secondary"
                  classes={{ root: panel === imagePanel && classes.buttonRoot }}
                  onClick={
                    panel === imagePanel
                      ? () => deleteImage(item)
                      : () => editCustomization(item)
                  }
                >
                  {panel === imagePanel ? <Delete /> : <Edit />}
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
})

import { clientInstance, handleResponse } from "../../_helpers";

const getAllClients = async (mfiId, size, page) => {
  const res = await clientInstance
    .get(`clients/${mfiId}?size=${size}&page=${page}`);
  return handleResponse(res);
};

/**
 * Get Overview Page
 *
 * @param {*} mfiId object
 */
const getClientOverview = async (mfiId) => {
  const res = await clientInstance.get(`overview/${mfiId}`);
  return handleResponse(res);
};

const getClientOverviewGraph = async (mfiId) => {
  const res = await clientInstance.get(`overview/pending-tasks/graph/${mfiId}?days=7`);
  return handleResponse(res);
};

/**
 * Get Overview Page
 *
 * @param {*} mfiId object
 * @param {*} ID object
 */
const getClientProfile = async (mfiId, id) => {
  const res = await clientInstance.get(`clients/with-loan-arrears/client/${mfiId}/${id}`);
  return handleResponse(res);
};

/**
 * Get client notes
 *
 * @param {*} mfiId object
 * @param {*} loanId object
 */
const getClientNotes = async (mfiId, loanId, page, size) => {
  const res = await clientInstance.get(`notes/${mfiId}/${loanId}?size=${size}&page=${page}`);
  return handleResponse(res);
};

/**
 * Send Reminder
 *
 */
const sendReminder = async (payload) => {
  const res = await clientInstance.post(`reminders/send`, payload);
  return handleResponse(res);
};

/**
 * Send Reminder
 *
 */
const createNote = async (payload) => {
  const res = await clientInstance.post(`notes`, payload);
  return handleResponse(res);
};

/**
 * Send Reminder
 *
 */
const createDocumentUpload = async (mfiId, id, payload) => {
  const res = await clientInstance.post(`clients/client/document-checklist/upload/${mfiId}/${id}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return handleResponse(res);
};

/**
 * Seach client
 *
 */
const searchClient = async (mfiId, payload) => {
  const res = await clientInstance.get(`clients/search/with-loan-arrears/${mfiId}?queryString=${payload}`);
  return handleResponse(res);
};

/**
 *
 * @param {*} mfiId
 * @param {*} searchParams
 * @param {*} payload
 * @returns
 */
const filterClient = async (mfiId, searchParams, payload) => {
  const res = await clientInstance.post(`clients/filter/with-loan-arrears/${mfiId}?queryString=${searchParams}&size=${10}&page=${0}`, payload);
  return handleResponse(res);
};

/**
 *
 * @param {*} mfiId
 * @param {*} searchParams
 * @param {*} payload
 * @returns
 */
const getClientAssignees = async (mfiId) => {
  const res = await clientInstance.get(`clients/assignee/${mfiId}`);
  return handleResponse(res);
};

/**
 *
 * @param {*} payload
 * @returns
 */
const AssignTo = async (payload) => {
  const res = await clientInstance.patch(`clients/assign/to`, payload);
  return handleResponse(res);
};

/**
 *
 * @param {*} payload
 * @returns
 */
const AssignToClientProfile = async (payload) => {
  const res = await clientInstance.patch(`clients/assign/to`, payload);
  return handleResponse(res);
};

/**
 *
 * @param {*} id
 * @param {*} searchParams
 * @param {*} payload
 * @returns
 */
const AssignBack = async (payload) => {
  const res = await clientInstance.patch(`clients/assign/back`, payload);
  return handleResponse(res);
};

/**
 *
 * @param {*} id
 * @param {*} searchParams
 * @param {*} payload
 * @returns
 */
const getClientToAssignBack = async (mfiId, id) => {
  const res = await clientInstance.get(`clients/assign-back/${mfiId}/${id}`);
  return handleResponse(res);
};

/**
 *
 * @param {object} payload
 * @returns
 */
const BMSendReminder = async (payload) => {
  const res = await clientInstance.post(`notifications/send-reminder`, payload);
  return handleResponse(res);
};

/**
 * client service
 */
export const ClientService = {
  getAllClients,
  getClientOverview,
  getClientOverviewGraph,
  getClientProfile,
  getClientNotes,
  sendReminder,
  createNote,
  createDocumentUpload,
  searchClient,
  filterClient,
  getClientAssignees,
  AssignTo,
  AssignBack,
  getClientToAssignBack,
  AssignToClientProfile,
  BMSendReminder,
};

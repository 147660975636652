import { pendingTasksConstants } from "./_constants";

const initialState = {
    loading: false,
    pendingTasks: [],
};

/**
 * Data Tools
 *
 * @param {*} state
 * @param {*} action
 */
export const pendingTasks = (state = initialState, action) => {
    switch (action.type) {
        case pendingTasksConstants.GETALL_PENDING_TASKS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                pendingTasks: [],
            };
        case pendingTasksConstants.GETALL_PENDING_TASKS_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                pendingTasks: action.data,
            };
        case pendingTasksConstants.GETALL_PENDING_TASKS_FAILURE:
            return {
                ...state,
                loading: null,
                error: true,
                pendingTasksError: action.error,
                pendingTasks: [],
            };
        case pendingTasksConstants.GET_SINGLE_PENDING_TASK_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                singlePendingTask: [],
            };
        case pendingTasksConstants.GET_SINGLE_PENDING_TASK_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                singlePendingTask: action.data,
            };
        case pendingTasksConstants.GET_SINGLE_PENDING_TASK_FAILURE:
            return {
                ...state,
                loading: null,
                error: true,
                singlePendingTaskError: action.error,
                singlePendingTask: [],
            };
        case pendingTasksConstants.GETALL_BM_PENDING_TASKS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                BMPendingTasks: [],
            };
        case pendingTasksConstants.GETALL_BM_PENDING_TASKS_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                BMPendingTasks: action.data,
            };
        case pendingTasksConstants.GETALL_BM_PENDING_TASKS_FAILURE:
            return {
                ...state,
                loading: null,
                error: true,
                BMPendingTasksError: action.error,
                BMPendingTasks: [],
            };
        default:
            return state;
    }
}

import React, { Suspense } from "react"
import "./i18n"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { createTheme, ThemeProvider } from "@material-ui/core/styles"

import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/css/tailwind.css"
import "./assets/scss/main.scss"
import App from "./App"
// eslint-disable-next-line

import * as serviceWorker from "./serviceWorker"

const theme = createTheme({
  palette: {
    primary: {
      main: "#008d6a",
    },
    secondary: {
      main: "#60c1f7",
    },
  },
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Suspense fallback={"Loading"}>
        <App />
      </Suspense>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

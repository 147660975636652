import React, { useEffect, useState, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Container, Row, Col } from "shards-react";

import { withTranslation } from "react-i18next";
import {
  loanDistributed,
  cashFlowAnalysis,
  climateProfile,
} from "../../../utils/templates";
import PageTitle from "../../../components/common/PageTitle";
import { ApplicationEditor } from "../../applicationEditor";
import ClimateRiskProfile from "../../climateProfile/components";
import { DocumentChecklist } from "../../documentChecklist/views/List";
import { CreditScoreCard } from "../../creditScore/components/CreditScoreCard";
import { CashflowAnalysis } from "../../creditScore/components/CashFlowAnalysis";
import LoanDistributed from "../LoanDistributed/components";
import { Comments, AddComment } from "../../comments";
import { mfiManagerActions } from "../../mfiManager";
import { applicationActions } from "..";

const ViewApplication = ({ surveys, dispatch, t, applications }) => {
  const [questionnaireName, setQuestionnaireName] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const { surveyDeployment, uid } = applications;
  const [state, setState] = useState({ uid: uid });
  const { id, xformId, xformversion, tab, loggedInUser } =
    applications.applicationClicked;
  const clickedApplicationDetails =
    applications.items && applications.items.filter((item) => id === item.id);
  const canViewTabs = !(
    clickedApplicationDetails &&
    clickedApplicationDetails[0].stage === "MFI_LOAN_OFFICER" &&
    clickedApplicationDetails[0].workflowStatus.toUpperCase() === "REVIEW"
  );

  const authentication = useSelector((state) => state.authentication);
  const mfiId = authentication.profile.mfi.id;

  const { menus = [] } = useSelector((store) => store.mfis);

  const showLoanDistributed =
    menus.length > 0 &&
    menus.find(
      (menu) => menu.componentName === loanDistributed.label && menu.enabled
    ) !== undefined;

  const showClimateProfile =
    menus.length > 0 &&
    menus.find(
      (menu) => menu.componentName === climateProfile.label && menu.enabled
    ) !== undefined;

  const showCashFlowAnalysis =
    menus.length > 0 &&
    menus.find(
      (menu) => menu.componentName === cashFlowAnalysis.label && menu.enabled
    ) !== undefined;

  const getQuestionnaire = useCallback(() => {
    if (surveys.assets) {
      surveys.assets.map((questionnaire, idx) => {
        const { name, versionId } = questionnaire;
        let re = new RegExp(versionId, "g");
        return xformversion.match(re) && setQuestionnaireName(name);
      });
    }
    return;
  }, [surveys.assets, xformversion]);

  useEffect(() => {
    dispatch(mfiManagerActions.getCustomMenus(mfiId));
  }, [dispatch, mfiId]);

  useEffect(() => {
    getQuestionnaire();
  }, [getQuestionnaire]);

  const getTab = useCallback(() => {
    // check if user is trying to access checklist tab
    if (tab && tab === "creditscore") {
      setTabIndex(1);
    }
  }, [tab]);

  useEffect(() => {
    setState({ uid: uid });
  }, [uid]);

  useEffect(() => {
    getTab();
  }, [getTab]);

  useEffect(() => {
    let xFormId = "";
    if (state.uid) {
      [xFormId] = state.uid.split(":");
      dispatch(applicationActions.getFormFields(xFormId));
    }
  }, [dispatch, state.uid]);

  /**
   * Set Questionnaire uid and Get formulas by UID
   */
  useEffect(() => {
    state.uid && dispatch(applicationActions.setQuestionnaire(state.uid));
  }, [state.uid, dispatch]);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header pt-4 pb-1">
        <PageTitle sm="4" title={questionnaireName} className="text-sm-left" />
      </Row>
      <Row>
        <Col>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <TabList>
              <Tab>{t("module.applicationManager.view.view.view")}</Tab>
              {canViewTabs && (
                <>
                  <Tab>{t("module.applicationManager.view.view.score")}</Tab>
                  {/* <Tab>{t("module.applicationManager.view.view.report")}</Tab> */}
                </>
              )}
              {canViewTabs && showClimateProfile && (
                <Tab>
                  {t("module.applicationManager.view.view.climateProfile")}
                </Tab>
              )}
              {canViewTabs && showCashFlowAnalysis && (
                <Tab>{t("module.applicationManager.view.view.analysis")}</Tab>
              )}
              <Tab>{t("module.applicationManager.view.view.checklist")}</Tab>
              <Tab>{t("module.applicationManager.view.view.comments")}</Tab>
              {canViewTabs && showLoanDistributed && (
                <Tab>
                  {t("module.applicationManager.view.view.loanDistributed")}
                </Tab>
              )}
            </TabList>
            <TabPanel>
              <ApplicationEditor
                mfiId={mfiId}
                id={id}
                {...surveyDeployment}
                readonly
              />
            </TabPanel>
            {canViewTabs && (
              <>
                <TabPanel>
                  <CreditScoreCard
                    id={id}
                    xformId={xformId}
                    xformversion={xformversion}
                  />
                </TabPanel>
                {/* <TabPanel>
                  <CashflowReport id={id} />
                </TabPanel> */}
              </>
            )}
            {canViewTabs && showClimateProfile && (
              <TabPanel>
                <ClimateRiskProfile id={id} />
              </TabPanel>
            )}
            {canViewTabs && showCashFlowAnalysis && (
              <TabPanel>
                <CashflowAnalysis applicationId={id} />
              </TabPanel>
            )}
            <TabPanel>
              <div className="p-10">
                <DocumentChecklist id={id} />
              </div>
            </TabPanel>

            <TabPanel>
              <div className="p-10">
                <h2 className="section__title">
                  {t("module.applicationManager.view.view.recommendations")}
                </h2>
                <Comments id={id} />
                {clickedApplicationDetails &&
                  clickedApplicationDetails[0].currentUser === loggedInUser &&
                  clickedApplicationDetails[0].workflowStatus === "Review" && (
                    <AddComment id={id} />
                  )}
              </div>
            </TabPanel>
            {canViewTabs && showLoanDistributed && (
              <TabPanel>
                <LoanDistributed applicationId={id} />
              </TabPanel>
            )}
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { surveys, applications } = state;
  return { surveys, applications };
};

const connectedViewApplication = withTranslation()(
  connect(mapStateToProps)(ViewApplication)
);
export { connectedViewApplication as ViewApplication };

import { store } from '.';

export const isAuthenticated = () => {
    const newState = store.getState();
    const { authentication } = newState;
    const { expiresAt } = authentication;
    if (!expiresAt) {
        return false;
    }

    return !!(expiresAt && new Date().getTime() < expiresAt);
};

store.subscribe(isAuthenticated);

export function hasAuthority(role) {
    const newState = store.getState();
    const { authentication } = newState;

    const { user } = authentication;

    if (!user) {
        return false;
    }

    const { authorities } = user;

    if (!authorities) {
        return false;
    }

    return authorities.includes(role);
}

store.subscribe(hasAuthority);

export function getKoboToken() {
    const newState = store.getState();

    const { kobotoolkit } = newState;
    const { token } = kobotoolkit;

    if (!token) {
        return false;
    }

    return token;
}

store.subscribe(getKoboToken);

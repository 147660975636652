import React, { useRef, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Button } from "shards-react"
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core"
import PageTitle from "../../../components/common/PageTitle"
import { toolsActions } from ".."
import { LoadingView } from "../../../views"
import Papa from "papaparse"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import "./audittrail.css"

export const Audittrail = () => {
  const mfis = useSelector((state) => state.authentication)
  const mfisjson = JSON.stringify(mfis)
  const mfisObject = JSON.parse(mfisjson)
  const iduser = mfisObject.user.id
  const { loading, data } = useSelector((state) => state.tools)
  const tableRef = useRef()
  const today = new Date()
  const [date1, setDate1] = useState(today)
  const [date2, setDate2] = useState(today)
  const [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 20

  const dispatch = useDispatch()

  function getActivity() {
    const data = JSON.stringify({
      fromDate: date1.toISOString().split("T")[0],
      toDate: date2.toISOString().split("T")[0],
      id: iduser,
    })
    dispatch(toolsActions.listActivitiesLog(data))
      .then((res) => {
        setTableData(res.data.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    getActivity()
  }, [])

  const AudittrailData = () => {
    const table = tableRef.current
    const csv = Papa.unparse(tableData)
    const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" })
    const url = URL.createObjectURL(csvData)
    const link = document.createElement("a")
    link.setAttribute("href", url)
    link.setAttribute("download", "audit-trail.csv")
    link.style.visibility = "hidden"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleDate1Change = (date) => {
    setDate1(date)
  }

  const handleDate2Change = (date) => {
    setDate2(date)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    getActivity()
  }

  // Pagination logic
  const totalPages = Math.ceil(tableData.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentPageData = tableData
    .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
    .slice(startIndex, endIndex)
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }
  const formatCreatedDate = (createdDate) => {
    const date = new Date(createdDate)
    return date.toLocaleString()
  }

  return (
    <Container fluid className="main-content-container px-4">
      {loading && <LoadingView />}
      <Row noGutters className="page-header py-4">
        <PageTitle title="Audit trail" className="text-sm-left mb-3" />

        <Col>
          <Button className="float-right" onClick={AudittrailData}>
            Export
          </Button>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <form onSubmit={handleSubmit}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div style={{ display: "inline-block", marginRight: "10px" }}>
                <label htmlFor="date1" className="font-bold text-lg">
                  From Date :
                </label>
                <KeyboardDatePicker
                  id="date1"
                  value={date1}
                  onChange={handleDate1Change}
                  format="yyyy-MM-dd"
                  placeholder="From Date"
                  className="text-center"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </div>
              <div style={{ display: "inline-block", marginRight: "10px" }}>
                <label htmlFor="date2" className="font-bold text-lg">
                  To Date :
                </label>
                <KeyboardDatePicker
                  id="date2"
                  value={date2}
                  onChange={handleDate2Change}
                  format="yyyy-MM-dd"
                  placeholder="To Date"
                  className="text-center"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </div>
            </MuiPickersUtilsProvider>

            <Button type="submit">Submit</Button>
          </form>
          <TableContainer component={Paper} style={{ width: "100%" }}>
            <table ref={tableRef} style={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Sender ip</TableCell>
                  <TableCell>Event Type</TableCell>
                  <TableCell>Url</TableCell>
                  <TableCell>Created date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageData.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.username}</TableCell>
                    <TableCell>{row.ip}</TableCell>
                    {row.url === "/workflow/users/me/" &&
                    row.responseCode === 200 ? (
                      <TableCell>USER_AUTHENTICATED_SUCCESSFULLY</TableCell>
                    ) : (
                      <TableCell>{row.requestMethod}</TableCell>
                    )}
                    <TableCell>{row.url}</TableCell>
                    <TableCell>{formatCreatedDate(row.createdDate)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </table>
          </TableContainer>

          {/* Pagination */}
          <div style={{ marginTop: "10px", textAlign: "center" }}>
            {Array.from({ length: totalPages }, (_, index) => index + 1).map(
              (page) => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  style={{
                    margin: "5px",
                    padding: "5px 10px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    backgroundColor:
                      currentPage === page ? "#ccc" : "transparent",
                  }}
                >
                  {page}
                </button>
              )
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

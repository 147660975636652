/* eslint react-hooks/exhaustive-deps: 0 */
import React, {
    useState, useEffect, useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { configActions } from '../..';
import { FormSelect, FormGroup } from 'shards-react';
import { isEmpty, filter } from 'lodash';

import './customerProfile.scss';
import { useTranslation } from 'react-i18next';

export function CustomerProfile() {
    const scoreLogic = useSelector((store) => store.scoreLogic);
    const { t } = useTranslation();
    const { uid, computationCodes } = scoreLogic;
    const config = useSelector((store) => store.config);
    const { applicantProfileGlobalState } = config;

    const [surveyComputationCodes, setSurveyComputationCodes] = useState([]);
    const [submitCustomerProfile, setCustomerProfile] = useState(false);

    const dispatch = useDispatch();

    const [applicantCustomerProfileState, setApplicantProfileState] = useState({
        address: '',
        citizenship: '',
        dob: '',
        firstName: '',
        gender: '',
        lastName: '',
        maritalStatus: '',
        middleName: '',
        nationalId: '',
        occupation: '',
        phoneNumber1: '',
        phoneNumber2: '',
        surveyUuid: uid,
        title: 'Customer Profile',
        type: 'APPLICANT_PROFILE',
    });

    // update state data
    useEffect(() => {
        if (!submitCustomerProfile) {
            setApplicantProfileState({ ...applicantCustomerProfileState });
        }
    }, []);

    useEffect(() => {
        if ((applicantProfileGlobalState)) {
            setApplicantProfileState(applicantProfileGlobalState);
        }
    }, [applicantProfileGlobalState]);

    // gender - Submit data to the service
    const submitCustomerProfileData = useCallback(() => {
        if (submitCustomerProfile) {
            const payload = {
                ...applicantCustomerProfileState,
            };
            dispatch(configActions.updateApplicantCustomerProfile(payload));
            setCustomerProfile(false);
        }
    }, [submitCustomerProfile, dispatch, applicantCustomerProfileState]);


    useEffect(() => {
        submitCustomerProfileData();
    }, [submitCustomerProfile, submitCustomerProfileData]);

    const handleApplicantProfileChange = (e) => {
        const { name, value } = e.target;
        setApplicantProfileState((prevState) => ({ ...prevState, applicantCustomerProfileState, [name]: value }));
        setCustomerProfile(true);
    };

    useEffect(() => {
        const codes = computationCodes && computationCodes.filter(({ surveyUuid }) => surveyUuid === uid);
        if (!isEmpty(codes)) {
            setSurveyComputationCodes([...codes]);
        } else {
            setSurveyComputationCodes([]);
        }
    }, [computationCodes, uid]);

    const getCodeLabel = (xCode) => {
        const [filtered] = filter(surveyComputationCodes, { questionCode: xCode });
        const questionCode = Object(filtered);
        return `${questionCode.questionLabel} (${xCode})`;
    };

    return (
        <div>
            <div className="group p-4 w-1/2">
                <h4 className="mini-heading-top">Customer Profile</h4>
                <FormGroup className="group flex">
                    <label className="pr-4">First Name</label>
                    <FormSelect name="firstName" value={applicantCustomerProfileState.firstName && applicantCustomerProfileState.firstName} size="sm" className="mr-auto max-w-md" onChange={(e) => handleApplicantProfileChange(e)}>
                        <option key={-1} value="">{t('module.applications.applicationProfile.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => <option key={idx} value={questionnaire.questionCode}>{getCodeLabel(questionnaire.questionCode)}</option>)
                        }
                    </FormSelect>
                </FormGroup>
                <FormGroup className="group flex">
                    <label className="pr-4">Last Name</label>
                    <FormSelect name="lastName" value={applicantCustomerProfileState.lastName && applicantCustomerProfileState.lastName} size="sm" className="mr-auto max-w-md" onChange={(e) => handleApplicantProfileChange(e)}>
                        <option key={-1} value="">{t('module.applications.applicationProfile.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => <option key={idx} value={questionnaire.questionCode}>{getCodeLabel(questionnaire.questionCode)}</option>)
                        }
                    </FormSelect>
                </FormGroup>
                <FormGroup className="group flex">
                    <label className="pr-4">Middle Name</label>
                    <FormSelect name="middleName" value={applicantCustomerProfileState.middleName && applicantCustomerProfileState.middleName} size="sm" className="mr-auto max-w-md" onChange={(e) => handleApplicantProfileChange(e)}>
                        <option key={-1} value="">{t('module.applications.applicationProfile.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => <option key={idx} value={questionnaire.questionCode}>{getCodeLabel(questionnaire.questionCode)}</option>)
                        }
                    </FormSelect>
                </FormGroup>
                <FormGroup className="group flex">
                    <label className="pr-4">Date of birth</label>
                    <FormSelect name="dob" value={applicantCustomerProfileState.dob && applicantCustomerProfileState.dob} size="sm" className="mr-auto max-w-md" onChange={(e) => handleApplicantProfileChange(e)}>
                        <option key={-1} value="">{t('module.applications.applicationProfile.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => <option key={idx} value={questionnaire.questionCode}>{getCodeLabel(questionnaire.questionCode)}</option>)
                        }
                    </FormSelect>
                </FormGroup>
                <FormGroup className="group flex">
                    <label className="pr-4">Gender</label>
                    <FormSelect name="gender" value={applicantCustomerProfileState.gender && applicantCustomerProfileState.gender} size="sm" className="mr-auto max-w-md" onChange={(e) => handleApplicantProfileChange(e)}>
                        <option key={-1} value="">{t('module.applications.applicationProfile.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => <option key={idx} value={questionnaire.questionCode}>{getCodeLabel(questionnaire.questionCode)}</option>)
                        }
                    </FormSelect>
                </FormGroup>
                <FormGroup className="group flex">
                    <label className="pr-4">Marital Status</label>
                    <FormSelect name="maritalStatus" value={applicantCustomerProfileState.maritalStatus && applicantCustomerProfileState.maritalStatus} size="sm" className="mr-auto max-w-md" onChange={(e) => handleApplicantProfileChange(e)}>
                        <option key={-1} value="">{t('module.applications.applicationProfile.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => <option key={idx} value={questionnaire.questionCode}>{getCodeLabel(questionnaire.questionCode)}</option>)
                        }
                    </FormSelect>
                </FormGroup>
                <FormGroup className="group flex">
                    <label className="pr-4">Occupation</label>
                    <FormSelect name="occupation" value={applicantCustomerProfileState.occupation && applicantCustomerProfileState.occupation} size="sm" className="mr-auto max-w-md" onChange={(e) => handleApplicantProfileChange(e)}>
                        <option key={-1} value="">{t('module.applications.applicationProfile.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => <option key={idx} value={questionnaire.questionCode}>{getCodeLabel(questionnaire.questionCode)}</option>)
                        }
                    </FormSelect>
                </FormGroup>
                <FormGroup className="group flex">
                    <label className="pr-4">National ID</label>
                    <FormSelect name="nationalId" value={applicantCustomerProfileState.nationalId && applicantCustomerProfileState.nationalId} size="sm" className="mr-auto max-w-md" onChange={(e) => handleApplicantProfileChange(e)}>
                        <option key={-1} value="">{t('module.applications.applicationProfile.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => <option key={idx} value={questionnaire.questionCode}>{getCodeLabel(questionnaire.questionCode)}</option>)
                        }
                    </FormSelect>
                </FormGroup>
                <FormGroup className="group flex">
                    <label className="pr-4">Phone Number 1</label>
                    <FormSelect name="phoneNumber1" value={applicantCustomerProfileState.phoneNumber1 && applicantCustomerProfileState.phoneNumber1} size="sm" className="mr-auto max-w-md" onChange={(e) => handleApplicantProfileChange(e)}>
                        <option key={-1} value="">{t('module.applications.applicationProfile.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => <option key={idx} value={questionnaire.questionCode}>{getCodeLabel(questionnaire.questionCode)}</option>)
                        }
                    </FormSelect>
                </FormGroup>
                <FormGroup className="group flex">
                    <label className="pr-4">Phone Number 2</label>
                    <FormSelect name="phoneNumber2" value={applicantCustomerProfileState.phoneNumber2 && applicantCustomerProfileState.phoneNumber2} size="sm" className="mr-auto max-w-md" onChange={(e) => handleApplicantProfileChange(e)}>
                        <option key={-1} value="">{t('module.applications.applicationProfile.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => <option key={idx} value={questionnaire.questionCode}>{getCodeLabel(questionnaire.questionCode)}</option>)
                        }
                    </FormSelect>
                </FormGroup>
                <FormGroup className="group flex">
                    <label className="pr-4">Citizen ship</label>
                    <FormSelect name="citizenship" value={applicantCustomerProfileState.citizenship && applicantCustomerProfileState.citizenship} size="sm" className="mr-auto max-w-md" onChange={(e) => handleApplicantProfileChange(e)}>
                        <option key={-1} value="">{t('module.applications.applicationProfile.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => <option key={idx} value={questionnaire.questionCode}>{getCodeLabel(questionnaire.questionCode)}</option>)
                        }
                    </FormSelect>
                </FormGroup>
                <FormGroup className="group flex">
                    <label className="pr-4">Address</label>
                    <FormSelect name="address" value={applicantCustomerProfileState.address && applicantCustomerProfileState.address} size="sm" className="mr-auto max-w-md" onChange={(e) => handleApplicantProfileChange(e)}>
                        <option key={-1} value="">{t('module.applications.applicationProfile.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => <option key={idx} value={questionnaire.questionCode}>{getCodeLabel(questionnaire.questionCode)}</option>)
                        }
                    </FormSelect>
                </FormGroup>
            </div>
        </div>
    );
}

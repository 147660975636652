import React from 'react';
import { HierarchyDiagram } from './HierarchyDiagram';

export function ManageHierarchy({ id: mfiId, mfi }) {
    return (
        <div className="p-10">
          <h2 className="form-group-section">Hierarchy</h2>
          <HierarchyDiagram mfiId={mfiId} />
        </div>
    );
}

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import MainNavbar from '../components/layout/MainNavbar/MainNavbar';
import MiniSidebar from '../components/layout/MiniSidebar/MiniSidebar';
import { MainSidebar } from '../components/layout/MainSidebar/MainSidebar';
import MainFooter from '../components/layout/MainFooter';

const DefaultLayout = ({
    className, children, noNavbar, noFooter,
}) => (
    <Fragment>
        <MiniSidebar />
        <MainSidebar />
        <div
            className={ `${className} main-content` }
            tag="main"
        >
            {!noNavbar && <MainNavbar />}
            {children}
        </div>
        {!noFooter && <MainFooter />}
    </Fragment>
);

DefaultLayout.propTypes = {
    /**
   * Whether to display the navbar, or not.
   */
    noNavbar: PropTypes.bool,
    /**
   * Whether to display the footer, or not.
   */
    noFooter: PropTypes.bool,
};

DefaultLayout.defaultProps = {
    noNavbar: false,
    noFooter: false,
};

export default DefaultLayout;

import { axiosInstance, handleResponse } from "../../_helpers/base.service"
import qs from "qs"

const serviceGroup = "workflow"

export const userManagerService = {
  mfiAdminRegister,
  mfiAdminUpdate,
  faAnalystRegister,
  getAll,
  getById,
  delete: _delete,
  activate,
  deactivate,
  getLoanOfficersByHierarchy,
}

function getAll() {
  return axiosInstance.get(`${serviceGroup}/users`).then(handleResponse)
}

function getLoanOfficersByHierarchy(data) {
  return axiosInstance
    .post(`${serviceGroup}/users/loan-officers-hierarchy`, data)
    .then(handleResponse)
}

function getById(id) {
  return axiosInstance.get(`${serviceGroup}/users/${id}/`).then(handleResponse)
}

function mfiAdminRegister(user) {
  return axiosInstance
    .post(`${serviceGroup}/users/mfi-create-user/`, user)
    .then(handleResponse)
}

function mfiAdminUpdate(id, user) {
  return axiosInstance
    .patch(`${serviceGroup}/users/mfi-update-user/${id}/`, user)
    .then(handleResponse)
}

function faAnalystRegister(user) {
  return axiosInstance
    .post(`${serviceGroup}/users/fa-create-user/`, user)
    .then(handleResponse)
}

// prefixed function name with underscore because delete is a reserved word in javascript
// eslint-disable-next-line no-underscore-dangle
function _delete(id) {
  return axiosInstance
    .delete(`${serviceGroup}/users/${id}/`)
    .then(handleResponse)
}

function activate(id) {
  const requestConfig = {
    id: Number(id),
  }

  return axiosInstance
    .post(`${serviceGroup}/users/activate/`, requestConfig)
    .then(handleResponse)
}

function deactivate(id) {
  const requestConfig = {
    id: Number(id),
  }

  return axiosInstance
    .post(`${serviceGroup}/users/deactivate/`, requestConfig)
    .then(handleResponse)
}

/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

function Tdcv({ selected = [], selectOne, scoreLogic, handler }) {
    const [state, setState] = useState(selected);
    let returnType = 'singleCalculation';
    const { formulas } = scoreLogic;
    const formulaData = formulas.filter((item) => item.type === 'SINGLE_CALCULATION' );

    useEffect(() => {
        handler(returnType, { [returnType]: state });
    }, [state]);

    const selectItem = (which) => {
        let newState = [...state];

        if (selectOne) {
            newState = [];
        }

        const index = newState.indexOf(which);

        if (index === -1) {
            if (selectOne) {
                newState = [which];
            } else {
                newState.push(which);
            }
        } else {
            newState.splice(index, 1);
        }

        setState(newState);
    };

    return (
        <div className="items-group mb-2 tdcv">
          <h4>Formulas</h4>
          <div className="items-holder flex flex-wrap">
            {
              formulaData.map((formula) => {
                const { name, code } = formula;
                const active = state && state.includes(code) && 'variable-item-active';
                return (
                    <Tooltip key={code} placement="top" overlay={name} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                        <div className={`item variable-item ${active}` } onClick={ () => { selectItem(code); } } key={code}>{ code }</div>
                    </Tooltip>
                );
              })
            }
          </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { scoreLogic } = state;
    return {
        scoreLogic,
    };
};

const connectedComputationCodesSetup = connect(mapStateToProps)(Tdcv);
export { connectedComputationCodesSetup as Tdcv };

export const userManagerConstants = {
  USERMANAGER_REQUEST: "USERMANAGER_GETALL_REQUEST",
  USERMANAGER_SUCCESS: "USERMANAGER_GETALL_SUCCESS",
  USERMANAGER_FAILURE: "USERMANAGER_GETALL_FAILURE",

  REGISTER_REQUEST: "USERMANAGER_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERMANAGER_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERMANAGER_REGISTER_FAILURE",

  USER_UPDATE_REQUEST: "USERMANAGER_USER_UPDATE_REQUEST",
  USER_UPDATE_SUCCESS: "USERMANAGER_USER_UPDATE_SUCCESS",
  USER_UPDATE_FAILURE: "USERMANAGER_USER_UPDATE_FAILURE",

  GETALL_REQUEST: "USERMANAGER_GETALL_REQUEST",
  GETALL_SUCCESS: "USERMANAGER_GETALL_SUCCESS",
  GETALL_FAILURE: "USERMANAGER_GETALL_FAILURE",

  GETALL_LO_REQUEST: "GETALL_LO_REQUEST",
  GETALL_LO_SUCCESS: "GETALL_LO_SUCCESS",
  GETALL_LO_FAILURE: "GETALL_LO_FAILURE",

  DELETE_REQUEST: "USERMANAGER_DELETE_REQUEST",
  DELETE_SUCCESS: "USERMANAGER_DELETE_SUCCESS",
  DELETE_FAILURE: "USERMANAGER_DELETE_FAILURE",

  USER_DEACTIVATE: "USER_DEACTIVATE",
  USER_ACTIVATE: "USER_ACTIVATE",
}

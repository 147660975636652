import React from "react"
import { useDispatch } from "react-redux"
import PersonOutlineOutlinedIcon from "@material-ui/icons/LockOutlined"
import { Button } from "shards-react"
import { useTranslation } from "react-i18next"
import Countdown from "react-countdown"
import Header from "../../../components/widget/widgetTitle"
import { modalActions } from "../../modals"

export const AutoLogoutNotification = ({ modalID }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const expiredTime = parseInt(localStorage.getItem("_expiredTime") || 0, 10)

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  return (
    <div className="site-modal w-5">
      <Header
        title={t("module.authentication.component.logout.headerTitle")}
        icon={<PersonOutlineOutlinedIcon />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        <p className="mb-2">
          {t("module.authentication.component.logout.logoutWarning")}
        </p>
        <Countdown date={expiredTime} className="block" />
        <Button theme="primary" className="thin mt-2 mr-2" onClick={closeModal}>
          {t("module.authentication.component.logout.label")}
        </Button>
      </div>
    </div>
  )
}

import React from "react"
import { Tooltip } from "@material-ui/core"
import { useDispatch } from "react-redux"
import { WithWizard } from "react-albus"
import Svg from "react-inlinesvg"
import { size } from "lodash"
import { useTranslation } from "react-i18next"
import { CSVLink } from "react-csv"
import Header from "../../../components/widget/widgetTitle"
import DownloadIcon from "../../../assets/images/icons/download-icon.svg"
import { alertActions } from "../../alert"

const downloadSyncSVG = require("../../../assets/images/icons/score-heading-icon.svg")

export function Heading({
  titles: steps,
  computationCodes,
  uid,
  scoringVariables,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const csvDataCodes = computationCodes.filter(
    (item) => item.surveyUuid === uid
  )

  const downloadSV = () => {
    // create file in browser
    const fileName = "scoring-variables"
    const json = JSON.stringify(scoringVariables, null, 2)
    const blob = new Blob([json], { type: "application/json" })
    const href = URL.createObjectURL(blob)

    // create "a" HTLM element with href to file
    const link = document.createElement("a")
    link.href = href
    link.download = fileName + ".json"
    document.body.appendChild(link)
    link.click()

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  return (
    <WithWizard
      render={({ step }) => {
        let index = 0
        Object.keys(steps).map((key, idx) => {
          if (key === step.id) {
            index = idx + 1
          }
          return key
        })

        return (
          <Header
            title={steps[step.id]}
            icon={
              <Svg
                className="text-center d-inline-block"
                src={downloadSyncSVG}
              />
            }
          >
            {t("module.scoreLogic.view.scoreLogic.titles.computationCodes") ===
              steps[step.id] && (
              <Tooltip title="Download Computation Codes">
                <CSVLink
                  className="ml-4"
                  data={csvDataCodes}
                  filename={"computation_codes.csv"}
                  onClick={() =>
                    dispatch(alertActions.success("Downloading..."))
                  }
                >
                  <img src={DownloadIcon} alt="download icon" />
                </CSVLink>
              </Tooltip>
            )}
            {t("module.scoreLogic.view.scoreLogic.titles.sv") ===
              steps[step.id] && (
              <Tooltip title="Download Scoring Variables">
                <span className="ml-4 cursor-pointer" onClick={downloadSV}>
                  <img src={DownloadIcon} alt="download icon" />
                </span>
              </Tooltip>
            )}
            <span className="ml-auto mr-4 font-bold text-sm">{`${index} of ${size(
              steps
            )}`}</span>
          </Header>
        )
      }}
    />
  )
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingView } from '../../../../../../views';
import { modalActions } from '../../../../../modals';

const SendReminderDocumentChecklist = ({ mfiId, loanId }) => {
    const dispatch = useDispatch();
    const client = useSelector((state) => state.clients);
    const { profile, loading } = client;
    const { t } = useTranslation();

    const previewDocument = (modalTitle, documentUrl) => {
        dispatch(modalActions.previewClientDocument(23, modalTitle, documentUrl));
    }

    return (
        <>
            {loading && <LoadingView />}
            {/* view uploads/document checklist */}
            <div className="viewDocuments">
                {profile?.documentChecklist?.map(document => (
                    <div className="viewDocuments__document" key={document?.id}>
                        {document?.filename}
                        <button onClick={() => previewDocument(document?.filename, document?.fileUrl)}>
                            {t('module.client.arrears.action.modal.preview')}
                        </button>
                    </div>
                ))}
            </div>
        </>
    )
}

export default SendReminderDocumentChecklist;

import { workflowConstants } from "."
import { uniqueBy } from "../../utils/uniqueBy"

export const workflow = (
  state = {
    items: [],
    assigneesForward: [],
    assigneesBack: [],
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case workflowConstants.WORKFLOW_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case workflowConstants.WORKFLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data,
      }
    case workflowConstants.WORKFLOW_FAILURE:
      return {
        ...state,
        items: [],
        error: true,
        loading: false,
      }

    case workflowConstants.WORKFLOW_ASSIGNEES_REQUEST:
      return {
        ...state,
      }
    case workflowConstants.WORKFLOW_ASSIGNEES_FORWARD_SUCCESS:
      return {
        ...state,
        loading: false,
        assigneesForward: uniqueBy(
          [...state.assigneesForward, ...action.data],
          "uniqueKey"
        ), // TODO:: Each object needs to be unique by both id and applicationID
      }
    case workflowConstants.WORKFLOW_ASSIGNEES_BACK_SUCCESS:
      return {
        ...state,
        loading: false,
        assigneesBack: uniqueBy(
          [...state.assigneesBack, ...action.data],
          "uniqueKey"
        ), // TODO:: Each object needs to be unique by both id and applicationID
      }
    case workflowConstants.WORKFLOW_ASSIGNEES_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      }
    default:
      return state
  }
}

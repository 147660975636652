import React, { useRef, useState, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { Button, FormGroup } from "shards-react"
import { Switch } from "@material-ui/core"
import {
  FormWithConstraints,
  Input,
} from "react-form-with-constraints-bootstrap4"
import { useTranslation } from "react-i18next"
import { modalActions } from "../../../modals"
import { configActions } from "../../"
import { alertActions } from "../../../alert"

function ApplicationSetup(props) {
  // get document checklist from store
  const { applicationColumns } = props.config
  const { t } = useTranslation()
  const { computationCodes, uid: surveyUuid } = props.scoreLogic
  const [inputs, setInputs] = useState({
    columnCode: "",
    columnTitle: "",
    dataType: "",
  })
  const [edit, setEdit] = useState("")
  const [result, setResult] = useState(props.result || "")
  const dispatch = useDispatch()

  const form = useRef(null)
  const code = useRef(null)

  useEffect(() => {
    dispatch(
      configActions.addApplicationColumnState({ computationCode: props.result })
    )
    setResult(props.result)
    // scroll to form
    if (props.result) {
      const form = document.getElementById("form-setup")
      form.scrollIntoView()
    }
  }, [props.result, dispatch])

  useEffect(() => {
    dispatch(configActions.addApplicationColumnState({ edit: edit }))
  }, [edit, dispatch])

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputs((prevState) => ({
      ...prevState,
      [name]: value.replace(".", "・"),
    }))
    dispatch(
      configActions.addApplicationColumnState({
        [name]: value.replace(".", "・"),
      })
    )
  }

  const validateInputs = (payload) => {
    let valid = true
    Object.keys(payload).forEach((item) => {
      if (!payload[item]) {
        dispatch(
          alertActions.error(
            `${item} ${t("module.applications.applicationColumns.code")}`
          )
        )
        valid = false
      }
    })
    !edit &&
      applicationColumns.forEach((item, i) => {
        if (item.computationCode === payload.computationCode) {
          dispatch(
            alertActions.error(
              `${t("module.applications.applicationColumns.code")} ${
                payload.computationCode
              } ${t(
                "module.applications.applicationColumns.usedInAnotherColumn"
              )}`
            )
          )
          reset()
          valid = false
        }
      })

    return valid
  }

  const handleSave = (e) => {
    let payload = inputs
    payload["computationCode"] = result
    if (!validateInputs(payload)) {
      return
    }
    let validPayload = payload
    const selectedComputationCode = computationCodes.filter((item) => {
      return item.questionCode === validPayload.computationCode
    })
    validPayload["questionKey"] = selectedComputationCode[0].questionKey
    if (edit) {
      dispatch(configActions.editApplicationColumn(edit, validPayload))
      // scroll back to application column being edited
      const list = document.getElementById("setup" + edit)
      list.scrollIntoView()
    } else {
      validPayload["surveyUuid"] = surveyUuid
      dispatch(configActions.addApplicationColumn(validPayload))
    }
    reset()
  }

  const promptEdit = (item) => {
    // put clicked content on the form
    setEdit(item.id)
    setInputs({
      columnCode: item.columnCode,
      columnTitle: item.columnTitle,
      dataType: item.dataType,
    })
    setResult(item.computationCode)
    // set result on the computationCodes component
    props.updateComputationCode(item.computationCode)
    // send data to be edited to the state
    dispatch(
      configActions.addApplicationColumnState({
        computationCode: item.computationCode,
      })
    )
    dispatch(
      configActions.addApplicationColumnState({ columnCode: item.columnCode })
    )
    dispatch(
      configActions.addApplicationColumnState({ columnTitle: item.columnTitle })
    )
    dispatch(
      configActions.addApplicationColumnState({ questionKey: item.questionKey })
    )
    dispatch(
      configActions.addApplicationColumnState({ dataType: item.dataType })
    )
    // scroll to form
    const form = document.getElementById("form-setup")
    form.scrollIntoView()
  }

  const promptDelete = (id) => {
    // prompt delete modal
    dispatch(modalActions.deleteApplicationColumn({ id }))
  }

  const reset = () => {
    setInputs({
      columnCode: "",
      columnTitle: "",
      dataType: "",
    })
    setEdit("")
    // set result on the computationCodes component
    props.resetComputationCodeFn()
  }

  return (
    <div
      className="formula-list items-group relative mb-2"
      data-toggle="tooltip"
      title="Scroll to see more"
    >
      <h4 className="mini-heading-top">
        {t("module.applications.applicationColumns.columnSetup")}
      </h4>
      {applicationColumns &&
        applicationColumns.map((item, index) => (
          <div
            key={index}
            id={`setup${item.id}`}
            className="formula-list__item mx-6 pb-2"
          >
            <div className="formula-list__item--detail flex mb-4">
              <div className="label w-1/2">
                {t("module.applications.applicationColumns.columnCode")}:
              </div>{" "}
              <div className="detail w-1/2">{item.columnCode}</div>
            </div>
            <div className="formula-list__item--detail flex mb-4">
              <div className="label w-1/2">
                {t("module.applications.applicationColumns.columnTitle")}:
              </div>{" "}
              <div className="detail w-1/2">{item.columnTitle}</div>
            </div>
            <div className="formula-list__item--detail flex mb-4">
              <div className="label w-1/2">
                {t("module.applications.applicationColumns.dataType")}:
              </div>{" "}
              <div className="detail w-1/2">{item.dataType}</div>
            </div>
            <div className="formula-list__item--detail flex mb-4">
              <div className="label w-1/2">
                {t("module.applications.applicationColumns.computationCode")}:
              </div>{" "}
              <div className="detail w-1/2">{item.computationCode}</div>
            </div>
            <div className="flex document-setup_button-group">
              <Button
                onClick={(e) => promptEdit(item)}
                theme="secondary"
                className="thin mr-2 edit"
              >
                {t("module.applications.applicationColumns.edit")}
              </Button>
              <Button
                theme="cancel"
                className="ml-auto thin delete"
                onClick={() => promptDelete(item.id)}
              >
                {t("module.applications.applicationColumns.delete")}
              </Button>
            </div>
          </div>
        ))}

      <div id="form-setup" className="add-tdcv px-6">
        <FormWithConstraints ref={form} noValidate className="shadow-none">
          <FormGroup>
            <div className="flex">
              <label>
                {t("module.applications.applicationColumns.columnCode")} *
              </label>
              <Input
                type="text"
                innerRef={code}
                className="form-control"
                name="columnCode"
                value={inputs.columnCode}
                required
                onChange={handleChange}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <div className="flex">
              <label>
                {t("module.applications.applicationColumns.columnTitle")} *
              </label>
              <Input
                type="text"
                className="form-control"
                name="columnTitle"
                value={inputs.columnTitle}
                required
                onChange={handleChange}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <div className="flex">
              <label>
                {t("module.applications.applicationColumns.dataType")} *
              </label>
              <select
                name="dataType"
                onChange={handleChange}
                className="custom-select"
              >
                <option selected>
                  {t(
                    "module.applications.applicationColumns.selectColumnDataType"
                  )}
                </option>
                <option value="TEXT" selected={inputs.dataType === "TEXT"}>
                  {t("module.applications.applicationColumns.text")}.
                </option>
                <option value="NUMBER" selected={inputs.dataType === "NUMBER"}>
                  {t("module.applications.applicationColumns.number")}.
                </option>
                <option value="DATE" selected={inputs.dataType === "DATE"}>
                  {t("module.applications.applicationColumns.date")}.
                </option>
                <option value="ID" selected={inputs.dataType === "ID"}>
                  {t("module.applications.applicationColumns.id")}.
                </option>
              </select>
            </div>
          </FormGroup>

          <FormGroup>
            <label>
              {t("module.applications.applicationColumns.computationCode")} *
            </label>
            <div className="items-holder flex flex-wrap">
              {result && (
                <div className="variable-item item item-computationCode">
                  {result}
                </div>
              )}
            </div>
          </FormGroup>

          <FormGroup className="flex document-setup_button-group">
            <Button
              onClick={() => reset()}
              theme="cancel"
              className="thin delete"
            >
              {t("module.applications.applicationColumns.clear")}
            </Button>
            <Button
              onClick={(e) => handleSave(e)}
              theme="secondary"
              className="thin mr-2"
            >
              {t("module.applications.applicationColumns.save")}
            </Button>
          </FormGroup>
        </FormWithConstraints>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { config, scoreLogic } = state
  return {
    config,
    scoreLogic,
  }
}

const connectedApplicationSetup = connect(mapStateToProps)(ApplicationSetup)
export { connectedApplicationSetup as ApplicationSetup }

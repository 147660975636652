import { clientInstance, handleResponse } from '../../../../../_helpers';

// notifications

const fetchNotifications = async (mfiId) => {
  const res = await clientInstance
    .get(`notifications/${mfiId}`);
  return handleResponse(res);
};

// mark notifications as read

const markAllRead = async (mfiId) => {
  const res = await clientInstance
    .get(`notifications/mark-all-read/${mfiId}`);
  return handleResponse(res);
};

export const notificationsService = {
  fetchNotifications,
  markAllRead,
};

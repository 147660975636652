import React, { useEffect } from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { connect, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Button } from 'shards-react';
import { modalActions } from '../../../modals';
import Header from '../../../../components/widget/widgetTitle';
import { clientActions } from '../../_actions';
import './getClientProfile.scss';
import SendReminderDocumentChecklist from './modalComponents/sendReminder/documentChecklist';
import ViewNotes from './modalComponents/viewClient/viewNotes';
import SendActionReminder from './modalComponents/sendReminder/sendActionReminder';
import { useTranslation } from 'react-i18next';

const BMSendReminder = ({ modalID, data, dispatch }) => {
    const client = useSelector((state) => state.clients);
    const { profile, error } = client;
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(clientActions.getClientProfile(data?.mfiCode, data?.id))
    }, [data, dispatch]);

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal" style={{ width: '1120px' }}>
            <Header title={profile?.fullName} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={closeModal} />
            <div className="p-10">
                {error && (
                    <center>
                        <h3>{t('module.client.arrears.action.modal.error.title')}</h3>
                        <p>{t('module.client.arrears.action.modal.error.description')}</p>
                        <Button onClick={closeModal}>{t('module.client.arrears.action.modal.error.action')}</Button>
                    </center>
                )}
                {!error && (
                    <div>
                        <Tabs>
                            <TabList>
                                <Tab>{t('module.client.arrears.action.sendReminder')}</Tab>
                                <Tab>{t('module.client.arrears.action.modal.documentChecklist')}</Tab>
                                <Tab>{t('module.client.arrears.action.modal.viewNotes')}</Tab>
                            </TabList>
                            <TabPanel>
                                <SendActionReminder mfiId={profile?.mfiId} loanId={profile?.id} />
                            </TabPanel>
                            <TabPanel>
                                <SendReminderDocumentChecklist mfiId={profile?.mfiId} loanId={profile?.id} />
                            </TabPanel>
                            <TabPanel>
                                <ViewNotes mfiId={profile?.mfiId} loanId={profile?.id} />
                            </TabPanel>
                        </Tabs>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { clients, modals } = state;
    return { clients, modals };
};

const connectedSendReminder = connect(mapStateToProps)(BMSendReminder);
export { connectedSendReminder as BMSendReminder };

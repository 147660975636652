import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { Checkbox, Tooltip, OutlinedInput } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Button } from "shards-react"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles({
  inputRoot: {
    fontSize: "0.875rem",
  },
})

function Build({
  result,
  selection,
  handler,
  handleCopyFormula,
  resultHandler,
}) {
  // https://stackoverflow.com/questions/23774231/how-do-i-remove-all-null-and-empty-string-values-from-a-json-object/23774287
  const removeEmpty = (obj) => {
    // eslint-disable-next-line no-param-reassign
    Object.keys(obj).forEach(
      (k) => !obj[k] && obj[k] !== undefined && delete obj[k]
    )
    return obj
  }
  const [editableBuild, seteditableBuild] = useState(false)
  const [resultArray, setResultArray] = useState([])
  const [rawFormula, setRawFormula] = useState("")
  const [resultFormula, setResultFormula] = useState("")
  const { t } = useTranslation()

  const filtered = result.filter((el) => !isEmpty(el))

  useEffect(() => {
    setResultArray(filtered)
    setResultFormula(filtered.join(""))
  }, [result])

  const handleChange = (event) => {
    seteditableBuild(event.target.checked)
  }

  const handleFormulaChange = (e) => {
    setRawFormula(e.target.value)
    resultHandler()
  }

  const copyFormula = (e) => {
    handleCopyFormula(rawFormula)
  }

  const classes = useStyles()

  return (
    <div className="formula-build items-group relative mb-2">
      <div className="flex items-center justify-between">
        <h4>{t("module.scoreLogic.view.formula.build.title")}</h4>
        <div className="items-group formula-operators">
          <Tooltip title="Set Editable Build">
            <Checkbox
              checked={editableBuild}
              onChange={handleChange}
              color="primary"
              size="small"
            />
          </Tooltip>
          <Button
            theme="tertiary"
            name="("
            onClick={(e) => handler(e.target.name)}
          >
            (
          </Button>
          <Button
            theme="tertiary"
            name=")"
            onClick={(e) => handler(e.target.name)}
          >
            )
          </Button>
          <Button
            theme="tertiary"
            name="+"
            onClick={(e) => handler(e.target.name)}
          >
            +
          </Button>
          <Button
            theme="tertiary"
            name="-"
            onClick={(e) => handler(e.target.name)}
          >
            -
          </Button>
          <Button
            theme="tertiary"
            name="*"
            onClick={(e) => handler(e.target.name)}
          >
            x
          </Button>
          <Button
            theme="tertiary"
            name="/"
            onClick={(e) => handler(e.target.name)}
          >
            ÷
          </Button>

          <Button
            theme="secondary"
            name="C"
            onClick={(e) => {
              setRawFormula("")
              handler(e.target.name)
            }}
          >
            C
          </Button>
          <Button
            theme="secondary"
            name="CE"
            onClick={(e) => handler(e.target.name)}
          >
            CE
          </Button>

          <Button
            theme="primary"
            name="="
            disabled={!result && "disabled"}
            className="submit"
            onClick={(e) => handler(e.target.name)}
          >
            {t("module.scoreLogic.view.formula.build.button")}
          </Button>
        </div>
      </div>
      {editableBuild && (
        <div className="formula-operators mb-2 flex justify-between">
          <OutlinedInput
            type="text"
            className="w-4/5 rounded-sm text-sm"
            name="formula"
            onChange={handleFormulaChange}
            value={resultFormula || rawFormula}
            color="primary"
            inputProps={{ className: `p-1 ${classes.inputRoot}` }}
          />
          <Button theme="primary" className="submit" onClick={copyFormula}>
            {t("module.scoreLogic.view.formula.build.add")}
          </Button>
        </div>
      )}
      <div className="items-holder flex flex-wrap">
        {resultArray.map((item, idx) => {
          const keys = Object.keys(removeEmpty(selection)) // remove empty values, get keys
          return (
            <div key={idx} className={`variable-item item item-${keys[idx]}`}>
              {item}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const connectedBuild = connect(null)(Build)
export { connectedBuild as Build }

import { toast } from 'react-toastify';
import { notificationConstants } from './_constants';
import { notificationsService } from './_service';

const clearNotifications = (dispatch) => {
    dispatch(request(null, notificationConstants.CLEAR_NOTIFICATIONS));

    function request(data) { return { type: notificationConstants.FETCH_NOTIFICATIONS_REQUEST, data }; }

}

function getRealTimeNotifications(data, fetchAgain) {
    return (dispatch) => {
        dispatch(success(data));
        setTimeout(() => {
            fetchAgain()
        }, 1000);
    };
    function success() { return { type: notificationConstants.GET_REALTIME_NOTIFICATIONS, data }; }
}

function fetchNotifications(mfiId) {
    return (dispatch) => {
        dispatch(request(mfiId));
        try {

            notificationsService.fetchNotifications(mfiId)
                .then((data) => {
                    dispatch(success(data));
                },
                (error) => {
                    dispatch(failure(error));
                },
                );
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: notificationConstants.FETCH_NOTIFICATIONS_REQUEST, data }; }
    function success(data) { return { type: notificationConstants.FETCH_NOTIFICATIONS_SUCCESS, data }; }
    function failure(error) { return { type: notificationConstants.FETCH_NOTIFICATIONS_FAILURE, error }; }
}


function markAllAsReadNotifications(mfiId, fetchAgain) {
    return (dispatch) => {
        dispatch(request(mfiId));
        try {

            notificationsService.markAllRead(mfiId)
                .then((data) => {
                    dispatch(success(data));
                    toast.success('Marked all as read')
                    fetchAgain()
                },
                    (error) => {
                        dispatch(failure(error));
                    },
                );
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: notificationConstants.MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST, data }; }
    function success(data) { return { type: notificationConstants.MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS, data }; }
    function failure(error) { return { type: notificationConstants.MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE, error }; }
}

export const notificationsActions = {
    clearNotifications,
    markAllAsReadNotifications,
    fetchNotifications,
    getRealTimeNotifications
};

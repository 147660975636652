import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button } from 'shards-react';
import { modalActions } from '../../../modals';

export function AddQuestionnaireAction({ mfiId }) {
    const dispatch = useDispatch();
    const { t } = useTranslation()

    const addQuestionnaire = () => {
        dispatch(modalActions.addQuestionnaire(mfiId));
    };

    return (
        <Button theme="primary" onClick={ () => addQuestionnaire() }>
            {t('module.mfiSetup.addQuestionnaire.title')}
        </Button>
    );
}

import React from 'react';
import {
    Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { Container, Row, Col } from 'shards-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PageTitle from '../../../components/common/PageTitle';
import { ManageUser, userManagerActions } from '..';
import { authenticationActions } from '../../authentication';

const UsersAddView = () => {
    const { t } = useTranslation()

    return (
    <Container fluid className="main-content-container px-4 mb-4">
        <Row noGutters className="page-header pt-4 pb-1">
            <PageTitle sm="4" title={t('data.topBarNav.addUserAccount')} className="text-sm-left" />
        </Row>
        <Row>
            <Col>
                <Tabs defaultIndex={1}>
                    <TabList>
                        <Tab>{t('data.topBarNav.details')}</Tab>
                    </TabList>
                    <TabPanel>
                        <ManageUser />
                    </TabPanel>
                </Tabs>
            </Col>
        </Row>
    </Container>)
};

const mapStateToProps = (state) => {
    const { users } = state;
    return { users };
};

const mapDispatchToProps = (dispatch) => {
    dispatch(userManagerActions.getAll());
    dispatch(authenticationActions.getProfile());
    return {};
};

const connectedUsersAddView = connect(mapStateToProps, mapDispatchToProps)(UsersAddView);
export { connectedUsersAddView as UsersAddView };

/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useRef, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import Svg from "react-inlinesvg"
import { FormGroup, FormCheckbox, FormTextarea, Button } from "shards-react"
import {
  FormWithConstraints,
  Async,
  FieldFeedbacks,
  FieldFeedback,
} from "react-form-with-constraints-bootstrap4"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import Header from "../../../components/widget/widgetTitle"
// import { userRoleConstants } from '../../../_helpers';
import { workflowActions } from ".."
import { modalActions } from "../../modals/_actions"
import { alertActions } from "../../alert"
import { Comments } from "../../comments"
import { applicationActions } from "../../applicationManager"
import SignaturePad from "../utils"
import "./AssignModal.scss"

const headerSVG = require("../../../assets/images/icons/header-icon-assign.svg")

const AssignApplication = ({
  modalID,
  users,
  data,
  applicantLoanData,
  t,
  profile,
}) => {
  const {
    target,
    assigneeUsername,
    applicationId,
    fullSubmission,
    suppressDetails,
  } = data
  const { currentUser } = fullSubmission
  const {
    mfi: { mfiName },
  } = profile

  const { applicantName, applicantLoan } = applicantLoanData

  const dispatch = useDispatch()
  const form = useRef(null)
  const maxCommentLength = 500
  const [comment, SetComment] = useState()
  // eslint-disable-next-line
  const [targetUser, SetTargetUser] = useState(assigneeUsername)
  const [checklist, SetChecklist] = useState(false)
  const [commentLength, SetCommentLength] = useState(0)
  const [base64Image, setBase64Image] = useState("")
  //const assignees = users.items && users.items.filter((user) => user.roles.includes(userRoleConstants[target]));

  useEffect(() => {
    fullSubmission &&
      dispatch(applicationActions.getLoanInformation(fullSubmission))
  }, [fullSubmission])

  async function commentTooLong() {
    return commentLength < maxCommentLength
  }

  // const handleTargetUserChange = ({ e }) => {
  //     const { value } = e;
  //     SetTargetUser(value);
  // };

  const handleCommentChange = async (e) => {
    SetComment(e.target.value)
    SetCommentLength(e.target.value.length)
    // eslint-disable-next-line no-unused-expressions
    e.target.tagName !== "LABEL" &&
      (await form.current.validateFields(e.target))
  }

  const handleConfirmChange = async () => {
    SetChecklist(!checklist)
  }

  const saveSignature = (signatureImage) => {
    const imageStr = signatureImage.split(",")[1]
    setBase64Image(imageStr)
  }

  const assignApplication = async (e) => {
    e.preventDefault()
    if (!checklist) {
      // ask user to confirm
      dispatch(
        alertActions.error(t("alertActions.manageUser.assignModalCheckbox"))
      )
      return
    }

    if (!base64Image && !suppressDetails && mfiName === "AMZ") {
      dispatch(alertActions.error("You must include your signature!"))
      return
    }

    await form.current.validateForm()

    if (!form.current.isValid()) {
      return
    }
    const messages = {
      assignSuccessful: t("alertActions.applications.assignSuccessful"),
    }

    dispatch(
      workflowActions.assignApplication(
        applicationId,
        targetUser,
        target,
        comment,
        base64Image,
        messages
      )
    )
    closeModal()
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
    // uncommnet and add brief wait before redirect
    // if (suppressDetails) {
    //   window.location.href = "/groups"
    // }
  }

  const typeString = {
    MFI_LOAN_OFFICER: {
      title: t("module.applicationManager.modal.recalculateScore.loanOfficer"),
    },
    MFI_CREDIT_ANALYST: {
      title: t(
        "module.applicationManager.modal.recalculateScore.creditAnalyst"
      ),
    },
    MFI_SUPERVISOR: {
      title: t("module.applicationManager.modal.recalculateScore.supervisor"),
    },
  }

  return (
    <div className="site-modal">
      <Header
        title={`${t("module.workFlow.modal.assignModal.headerTitle")} ${
          typeString[target].title
        }`}
        icon={<Svg className="text-center d-inline-block" src={headerSVG} />}
        close={true}
        closeHandler={closeModal}
      />

      <div className="p-10">
        {!suppressDetails && (
          <>
            <div>
              <span className="font-bold">
                {t(
                  "module.applicationManager.modal.recalculateScore.applicantName"
                )}
              </span>{" "}
              <span>{applicantName}</span>
            </div>

            <div className="mb-3">
              <span className="font-bold">
                {t(
                  "module.applicationManager.modal.recalculateScore.loanAmount"
                )}
              </span>{" "}
              <span>{applicantLoan}</span>
            </div>
          </>
        )}

        <FormWithConstraints ref={form} className="shadow-none">
          {/* to be debugged after go-live */}
          {/* <FormGroup className="m-y flex content-center">
                        <label htmlFor="assignee" style={ { lineHeight: '36px', margin: 0 } }>{ typeString[target].title }</label>

                        <FormSelect id="#assignee" name="assignee" className="ml-auto w-auto" value={targetUser} onChange={handleTargetUserChange}>
                            { assignees && assignees.map((user) => {
                                const { id, username } = user;
                                return <option key={id} value={username}>{username}</option>;
                            }) }
                        </FormSelect>
                    </FormGroup> */}
          <Comments id={applicationId} noCloseButton={true} />
          <FormGroup>
            <label htmlFor="comment">
              {" "}
              {t("module.workFlow.modal.assignModal.commentSection1")}{" "}
              {typeString[target].title}{" "}
              {t("module.workFlow.modal.assignModal.commentSection2")}{" "}
            </label>
            <FormTextarea
              id="#comment"
              name="comment"
              className="mb-2"
              placeholder={t("module.workFlow.modal.assignModal.placeholder")}
              onChange={handleCommentChange}
            />
            <FieldFeedbacks for="comment">
              <FieldFeedback when="*" />
              <Async
                promise={commentTooLong}
                pending={<span className="d-block">...</span>}
                then={(available) =>
                  available ? (
                    ""
                  ) : (
                    <FieldFeedback key="2">
                      {t("module.workFlow.modal.assignModal.fieldFeedback")}
                    </FieldFeedback>
                  )
                }
              />
            </FieldFeedbacks>
            <FormCheckbox
              name="documents"
              onChange={handleConfirmChange}
              checked={checklist}
            >
              {t("module.workFlow.modal.assignModal.formCheckbox")}
            </FormCheckbox>
          </FormGroup>
          {!suppressDetails && mfiName === "AMZ" && (
            <>
              <p className="mb-2 mt-4">
                {`Include the sinature of the current user (${currentUser}) below:`}
              </p>
              <SignaturePad
                currentUser={currentUser}
                saveSignature={saveSignature}
              />
            </>
          )}

          <div className="btn-group-actions site-modal__actions flex">
            <Button
              theme="primary"
              className="flex-none rounded-sm mr-2 ml-auto"
              onClick={assignApplication}
            >
              {t("module.workFlow.modal.assignModal.assign")}
            </Button>
            <Button
              theme="secondary"
              className="flex-none rounded-sm"
              onClick={() => {
                closeModal()
              }}
            >
              {t("module.workFlow.modal.assignModal.cancel")}
            </Button>
          </div>
        </FormWithConstraints>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { users, modals, applications, authentication } = state
  const { applicationMetadata } = applications
  const { profile } = authentication
  let applicantLoanData = {}
  if (!isEmpty(applicationMetadata)) {
    const foundApplicant = applicationMetadata.filter(
      (item) => item.type === "APPLICANT_NAME"
    )
    const foundLoan = applicationMetadata.filter(
      (item) => item.type === "LOAN_AMOUNT"
    )
    const [applicantItem] = foundApplicant
    const [loanItem] = foundLoan
    const { value: applicantName } = applicantItem
    const { value: applicantLoan } = loanItem
    applicantLoanData = { applicantName, applicantLoan }
  }

  return {
    users,
    modals,
    applicantLoanData,
    profile,
  }
}

const connectedAssignApplication = withTranslation()(
  connect(mapStateToProps)(AssignApplication)
)
export { connectedAssignApplication as AssignApplication }

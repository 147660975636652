import React from "react"
import { Typography } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { GoogleMap, useJsApiLoader, Polygon } from "@react-google-maps/api"
import styles from "./Editor.styles"
import "./Editor.scss"

const GpsShape = ({ kuid, type, label, value, classes, google }) => {
  const getCenterCoords = (coords) => {
    const x = coords.map((xy) => xy[0])
    const y = coords.map((xy) => xy[1])
    const cx = (Math.min(...x) + Math.max(...x)) / 2
    const cy = (Math.min(...y) + Math.max(...y)) / 2
    return [cx, cy]
  }

  const geoShapeCoords = value.split(";")
  const coords = geoShapeCoords.map((geoPoint) => [
    parseFloat(geoPoint.trim().split(" ")[0]),
    parseFloat(geoPoint.trim().split(" ")[1]),
  ])
  const centerCoords = getCenterCoords(coords)

  const polygonCoords = coords.map((cd) => ({
    lat: Number(cd[0]),
    lng: Number(cd[1]),
  }))

  // Loading and error handling
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY, // Replace with your API Key
  })

  if (loadError) return <div>Error loading map</div>

  return isLoaded ? (
    <div className={classes.gpsContainer}>
      <div key={kuid} className={classes.gpsSection}>
        <Typography className={classes.gpsSubtitleShape}>{type}</Typography>
        <div className="form-input-note">{label[0]}</div>
      </div>
      <div className={classes.mapContainer}>
        <GoogleMap
          center={{ lat: centerCoords[0], lng: centerCoords[1] }}
          zoom={17}
          mapContainerStyle={{ width: "100%", height: "300px" }}
        >
          <Polygon
            path={polygonCoords}
            options={{
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#FF0000",
              fillOpacity: 0.35,
            }}
          />
        </GoogleMap>
      </div>
    </div>
  ) : (
    <div>Loading...</div>
  )
}

export default withStyles(styles)(GpsShape)

import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { FormSelect, FormGroup, Button } from 'shards-react';
import { isEmpty, filter, first } from 'lodash';
import { configActions } from '../../';
import { useTranslation } from 'react-i18next';

export function FirstComment() {
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const scoreLogic = useSelector((store) => store.scoreLogic);
    const config = useSelector((store) => store.config);
    const { computationCodes, uid } = scoreLogic;
    const { firstComments } = config;

    const [surveyComputationCodes, setSurveyComputationCodes] = useState([]);
    const [firstCommentState, setFirstCommentState] = useState({});

    useEffect(() => {
        const codes = computationCodes && computationCodes.filter(({ surveyUuid }) => surveyUuid === uid);
        if (!isEmpty(codes)) {
            setSurveyComputationCodes([...codes]);
        } else {
            setSurveyComputationCodes([]);
        }
    }, [computationCodes, uid]);



    useEffect(() => {
        firstComments && setFirstCommentState(firstComments);
    }, [firstComments]);

    const handleFirstCommentChange = async(evt) => {
        const { value } = evt.target;
        const ComputationCodes = first(surveyComputationCodes.filter(({ questionCode }) => questionCode === value));

        await setFirstCommentState({
          ...firstCommentState,
          computationCode: value,
          questionKey: ComputationCodes.questionKey,
          surveyUuid: uid,
        });

        if (firstCommentState && firstCommentState.id) {
          dispatch(configActions.editFirstComment(firstCommentState.id, {
           ...firstCommentState,
           computationCode: value,
           questionKey: ComputationCodes.questionKey,
         }));
        } else {
          dispatch(configActions.addFirstComment({
            ...firstCommentState,
            computationCode: value,
            questionKey: ComputationCodes.questionKey,
            surveyUuid: uid
          }));
        }
    };

    const deleteComment = () => {
      dispatch(configActions.deleteFirstComments(firstCommentState.id));
    }

    const getCodeLabel = (xCode) => {
        const [filtered] = filter(surveyComputationCodes, { questionCode: xCode });
        const questionCode = Object(filtered);
        return `${questionCode.questionLabel} (${xCode})`;
    };

    return (
        <div className="flex">
            <div className="group p-4">
                <h4 className="mini-heading-top">{t('module.applications.firstComment.title')}</h4>
                <FormGroup className="flex">
                    <label className="pr-4">{t('module.applications.applicationProfile.question')}</label>
                    <FormSelect name="computationCodeId" value={firstCommentState && firstCommentState.computationCode} size="sm" className="mr-auto max-w-md" onChange={e => handleFirstCommentChange(e)}>
                        <option key={-1} value="" selected={!firstCommentState.computationCode && true}>{t('module.applications.applicationProfile.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => {
                                const { questionCode } = questionnaire;
                                return <option key={idx} value={questionCode}>{getCodeLabel(questionCode)}</option>;
                            })
                        }
                    </FormSelect>
                </FormGroup>

                <FormGroup className="flex">
                    <label className="pr-4">{t('module.applications.applicationProfile.computationCode')}</label>
                    <div className="projection-expression mr-auto max-w-md">
                    {firstCommentState && firstCommentState.computationCode}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button theme="cancel" className="ml-auto thin delete" disabled={firstCommentState && !firstCommentState.computationCode && true} onClick={e => deleteComment()}>{t('module.applications.applicationColumns.delete')}</Button>
                    </div>
                </FormGroup>
            </div>
        </div>
    );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button } from 'shards-react';
import { mfiManagerActions } from '../..';

export function AddRoleAction({ rowData: { id: mfiID } }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const addSubRoleFlow = () => {
        dispatch(mfiManagerActions.addMFISubRoleLimitFlow(mfiID));
    };

    return (
        <Button theme="primary" className="dropdown-toggle dropdown-toggle__no-caret" onClick={ () => { addSubRoleFlow(); } }>
            {t('module.scoreLogic.view.scoreLogic.approvalWorkflow.addRole')}
        </Button>
    );
}

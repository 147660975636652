import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import TablePagination from "@material-ui/core/TablePagination"
import Paper from "@material-ui/core/Paper"
import slugify from "slugify"
import { Badge } from "shards-react"
import EnhancedTableHead from "./tableHead"
import { TableRowsActions } from "./tableRowsActions"
import "./table.scss"
import { withTranslation } from "react-i18next"

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    backgroundColor: "#fff",
  },
  table: {
    minWidth: 700,
    padding: "10px",
    overflowX: "scroll",
    display: "block",
  },
})

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

class EnhancedTable extends React.Component {
  state = {
    order: "asc",
    orderBy: "title",
    data: [],
    actions: [],
    page: 0,
    rowsPerPage: 20,
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = "desc"

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc"
    }

    this.setState({ order, orderBy })
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  generateRows() {
    const { data, actions, t } = this.props

    const selectedLanguage = localStorage.getItem("i18nextLng")

    const { order, orderBy, rowsPerPage, page } = this.state

    return stableSort(data, getSorting(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row) => {
        const { id, status } = row

        // TODO: Find a good solution to support other languages
        const statusClass =
          status === "active" || status === "actif"
            ? selectedLanguage === "fr"
              ? "actif"
              : "active"
            : selectedLanguage === "fr"
            ? "inactif"
            : "inactive"
        const badgeTheme =
          statusClass === "active" ||
          statusClass === "actif" ||
          statusClass === true
            ? "complete"
            : "error"

        const statusUpdated =
          status === true
            ? t("module.users.userAccounts.active")
            : t("module.users.userAccounts.inactive")
        const meta = []
        // remove status as it is handled by statusClass | statusUpdated
        // delete row.status;

        // hide status column data
        Object.defineProperty(row, "status", {
          enumerable: false,
        })

        // handle the column data within each row
        const cells = Object.entries(row).map((colData, index) => (
          <TableCell
            key={index}
            className={`table-${slugify(colData[0], { replacement: "-" })}`}
          >
            {colData[1]}
          </TableCell>
        ))
        meta.push(
          <TableCell key="1001" className="table-status">
            <Badge theme={badgeTheme} className={`badge-xs ${statusClass}`}>
              {typeof statusClass !== "boolean" ? statusClass : statusUpdated}
            </Badge>
          </TableCell>
        )
        meta.push(
          <TableCell key="1000">
            <div style={{ width: "100px" }}></div>
          </TableCell>
        )
        meta.push(
          <TableCell key="1000" className="table-action tableAction">
            <TableRowsActions
              {...row}
              actions={actions}
              status={
                typeof statusClass !== "boolean" ? statusClass : statusUpdated
              }
            />
          </TableCell>
        )
        cells.push(meta)

        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={id}>
            {cells}
          </TableRow>
        )
      })
  }

  render() {
    const { classes, data, t } = this.props
    const { order, orderBy, rowsPerPage, page } = this.state
    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            width="100%"
            style={{
              display: "table",
            }}
          >
            <EnhancedTableHead
              data={data}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>{this.generateRows()}</TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          onPageChange={this.handleChangePage}
          labelRowsPerPage={t("module.client.arrears.rowsPerPage")}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Paper>
    )
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
}

const table = withTranslation()(withStyles(styles)(EnhancedTable))
export { table as UserTable }

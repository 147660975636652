import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import {
  Container,
  Row,
  Form,
  FormGroup,
  FormSelect,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "shards-react"
import Svg from "react-inlinesvg"
import PageTitle from "../../../components/common/PageTitle"
import { userRoleConstants, hasAuthority } from "../../../_helpers"
import { useTranslation } from "react-i18next"

import { reportsActions } from ".."
import { authenticationActions } from "../../authentication"
import { mfiManagerActions } from "../../mfiManager"

import "./Overview.scss"
import { RepaymentReportChart } from "./chart"
import { modalActions } from "../../modals"
import { LoadingView } from "../../../views"
import { ClientErrorsView } from "../../client/components/ClientError"

const barGraphBgSVG = require("../../../assets/images/icons/bar-graph-bg.svg")

export const RepaymentsReports = () => {
  const { items: mfiList } = useSelector((store) => store.mfis)
  const { assets } = useSelector((store) => store.surveys)
  const user = useSelector((state) => state.authentication)
  const {
    profile: { mfi },
  } = user
  const {
    hierarchyEntityIdInput,
    hierarchy,
    repaymentsReports,
    loading,
    repaymentsReportsError,
  } = useSelector((store) => store.reports)
  // const { selectedRepaymentDate, hierarchyEntityIdInput, hierarchy, repaymentsReports, loading, repaymentsReportsError } = useSelector((store) => store.reports);
  const dispatch = useDispatch()

  const [selectedValue, setSelectedValue] = useState("")
  const [dropdown, setDropdown] = useState(false)
  const [dropdownApplication, setDropdownApplication] = useState(false)

  const [selectedEntity, setSelectedEntity] = useState("")
  const [mfiId, setMfiId] = useState(null)
  const [activeData, setActiveData] = useState({})

  const toggle = () => setDropdown(!dropdown)
  const toggleApplication = () => setDropdownApplication(!dropdownApplication)

  useEffect(() => {
    ;(mfiId || mfi?.id) &&
      dispatch(
        reportsActions.repaymentReports({
          mfiId: mfiId !== null ? mfiId : mfi?.id,
          date: moment().format("DD/MM/YYYY"),
          format: null,
          hierarchyEntityId: activeData?.id,
        })
      )
  }, [dispatch, mfiId, mfi, activeData])

  useEffect(() => {
    // populate entities/offices to be displayed
    if (hierarchy && hierarchyEntityIdInput) {
      hierarchy.map((item, i) => {
        const selectedHierarchyEntity = item.entities.filter(
          (entity, i) => entity.id === Number(hierarchyEntityIdInput)
        )
        if (selectedHierarchyEntity && selectedHierarchyEntity.length > 0) {
          setSelectedEntity(selectedHierarchyEntity[0].name)
          setActiveData(selectedHierarchyEntity[0])
        }
        return item
      })
    } else {
      setSelectedEntity("")
    }
  }, [hierarchy, hierarchyEntityIdInput])

  useEffect(() => {
    dispatch(authenticationActions.getProfile())
    if (
      hasAuthority(userRoleConstants.FA_ANALYST) ||
      hasAuthority(userRoleConstants.FA_ADMIN)
    ) {
      dispatch(mfiManagerActions.getAllMFIs())
    }
  }, [dispatch])

  useEffect(() => {
    // set first questionare as the selected one
    if (assets && assets.length > 0) {
      setSelectedValue(`${assets[0].uid}:${assets[0].versionId}`)
    }
  }, [assets])

  const { t } = useTranslation()

  useEffect(() => {
    if (selectedValue && !mfiId) {
      let payload = {
        surveyUuid: selectedValue,
      }
      if (hierarchyEntityIdInput) {
        payload.hierarchyEntityId = Number(hierarchyEntityIdInput)
      }

      dispatch(reportsActions.filterReports(payload))
    }
  }, [selectedValue, dispatch]) // eslint-disable-line

  useEffect(() => {
    if (mfiId) {
      let payload = {
        surveyUuid: selectedValue,
      }

      if (
        hasAuthority(userRoleConstants.FA_ANALYST) ||
        hasAuthority(userRoleConstants.FA_ADMIN)
      ) {
        payload.mfiId = Number(mfiId)
      }

      dispatch(reportsActions.resetHierarchyInputs())
      dispatch(reportsActions.filterReports(payload))
    }
  }, [selectedValue, dispatch, mfiId]) // eslint-disable-line

  const handleExport = (format) => {
    let payload = {
      format: format,
      mfiId: mfiId || mfi?.id,
      date: null,
      hierarchyEntityId: activeData?.id || null,
    }
    dispatch(reportsActions.exportDefaultReports(payload))
  }

  const handleApplicationExport = (format) => {
    let payload = {
      format: format,
      mfiId: mfiId || mfi?.id,
      date: null,
      hierarchyEntityId: activeData?.id || null,
    }
    dispatch(reportsActions.exportRepaymentsReports(payload))
  }

  const checkData = () => {
    if (
      repaymentsReports === null ||
      (repaymentsReports?.defaultsByAge?.length === 0 &&
        repaymentsReports?.defaultsByBusinessActivity?.length === 0 &&
        repaymentsReports?.defaultsByGender?.length === 0 &&
        repaymentsReports?.defaultsByLocation?.length === 0)
    ) {
      return true
    }
    return false
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={t("module.report.overview")}
          subtitle={t("module.report.dashboard")}
          className="text-sm-left mb-3"
        />
      </Row>

      <Row noGutters>
        <Form className="shadow-none bg-transparent m-0">
          <FormGroup className="flex items-center mb-0">
            {!(
              hasAuthority(userRoleConstants.FA_ANALYST) ||
              hasAuthority(userRoleConstants.FA_ADMIN)
            ) && mfi?.mfiName}
            {(hasAuthority(userRoleConstants.FA_ANALYST) ||
              hasAuthority(userRoleConstants.FA_ADMIN)) && (
              <>
                &nbsp;&nbsp;
                <FormSelect
                  name="mfiName"
                  size="sm"
                  className={`mr-auto w-auto ${!mfiId && "select-error"}`}
                  value={mfiId}
                  onChange={(e) => setMfiId(e.currentTarget.value)}
                >
                  <option key={-1} value="">
                    {t("module.scoreLogic.view.scoreLogic.titles.selectMFI")}
                  </option>
                  {mfiList.map((item, idx) => (
                    <option key={idx} value={item.id}>
                      {item.mfiName}
                    </option>
                  ))}
                </FormSelect>
              </>
            )}
            <div className="flex select-questionaire">
              <Button
                theme="secondary"
                className="mx-2 d-table ml-auto btn btn-filter"
                onClick={() =>
                  dispatch(modalActions.reportsOfficeFilter(selectedValue))
                }
              >
                {t("module.report.filterByOffice")}
                {selectedEntity && `: ${selectedEntity}`}
              </Button>
              &nbsp;&nbsp;
              <Button
                theme="secondary"
                className="mx-2 d-table ml-auto btn btn-filter"
                onClick={() =>
                  dispatch(
                    modalActions.repaymentReportsDateFilter(selectedValue)
                  )
                }
              >
                {t("module.report.filterByDate")}
              </Button>
              <Dropdown open={dropdown} size="lg" toggle={toggle}>
                <DropdownToggle className="btn-export-reports" caret>
                  {t("module.report.exportReport")}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onMouseDown={() => handleExport("csv")}>
                    {t("module.report.csv")}
                  </DropdownItem>
                  <DropdownItem onMouseDown={() => handleExport("xlsx")}>
                    {t("module.report.excel")}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              &nbsp;&nbsp;
              <Dropdown
                open={dropdownApplication}
                size="lg"
                toggle={toggleApplication}
              >
                <DropdownToggle className="btn-export-reports" caret>
                  {t("module.report.exportRepayment")}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onMouseDown={() => handleApplicationExport("csv")}
                  >
                    {t("module.report.csv")}
                  </DropdownItem>
                  <DropdownItem
                    onMouseDown={() => handleApplicationExport("xlsx")}
                  >
                    {t("module.report.excel")}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </FormGroup>
        </Form>
      </Row>

      <br />
      <br />
      {loading && <LoadingView />}
      {/* to do -> fix repaymentsReportsError*/}
      {!checkData() && !loading && repaymentsReportsError && (
        <div>
          <div style={{ display: "flex" }}>
            <RepaymentReportChart
              data={repaymentsReports?.defaultsByAge}
              label={t("module.report.repayment.defaultsByAge")}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <RepaymentReportChart
              data={repaymentsReports?.defaultsByBusinessActivity}
              label={t("module.report.repayment.defaultsByBusinessActivity")}
            />
          </div>
          <br />
          <br />
          <br />
          <div style={{ display: "flex" }}>
            <RepaymentReportChart
              data={repaymentsReports?.defaultsByGender}
              label={t("module.report.repayment.defaultsByGender")}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <RepaymentReportChart
              data={repaymentsReports?.defaultsByLocation}
              label={t("module.report.repayment.defaultsByLocation")}
            />
          </div>
        </div>
      )}
      {repaymentsReportsError && (
        <ClientErrorsView errorData={repaymentsReportsError.response?.data} />
      )}

      {checkData() && (
        <div className="cards-empty">
          <div>{t("module.report.noRecordsToShow")}</div>
          <Svg className="text-center d-inline-block" src={barGraphBgSVG} />
        </div>
      )}
    </Container>
  )
}

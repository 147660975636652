import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MainNavbar from '../components/layout/MainNavbar/MainNavbar';
import MiniSidebar from '../components/layout/MiniSidebar/MiniSidebar';
import { MainSidebar } from '../components/layout/MainSidebar/MainSidebar';
import MainFooter from '../components/layout/MainFooter';

const IconSidebar = ({
    className, children, noMiniSidebar, noMainSidebar, noNavbar, noFooter, menuVisible,
}) => (
    <div className={ `${className} ${menuVisible && 'sidebar-open'}` }>
        { !noMiniSidebar && <MiniSidebar /> }
        { !noMainSidebar && <MainSidebar /> }
        <div className="main-content" tag="main">
            {!noNavbar && <MainNavbar />}
            {children}
            {!noFooter && <MainFooter />}
        </div>
    </div>
);

IconSidebar.propTypes = {
    /**
   * Whether to display the mini sidebar, or not.
   */
    noMiniSidebar: PropTypes.bool,
    /**
   * Whether to display the main sidebar, or not.
   */
    noMainSidebar: PropTypes.bool,
    /**
   * Whether to display the navbar, or not.
   */
    noNavbar: PropTypes.bool,
    /**
   * Whether to display the footer, or not.
   */
    noFooter: PropTypes.bool,
    /**
   * Whether menu is visible
   */
    menuVisible: PropTypes.bool,
};

IconSidebar.defaultProps = {
    noMiniSidebar: false,
    noMainSidebar: true,
    noNavbar: false,
    noFooter: false,
    menuVisible: true,
};

const mapStateToProps = (state) => {
    const { settings } = state;
    return { settings };
};

const connectedIconSidebar = connect(mapStateToProps)(IconSidebar);
export { connectedIconSidebar as IconSidebar };

const EVENTS = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"]

class IdleTimer {
  constructor({ timeout, onTimeout, onExpired, onWarning, login }) {
    if (login) {
      this.cleanup()
      return
    }
    const expiredTime = parseInt(localStorage.getItem("_expiredTime") || 0, 10)
    if (expiredTime > 0 && expiredTime < Date.now()) {
      onExpired()
      return
    }
    this.timeout = timeout
    this.onTimeout = onTimeout
    this.onWarning = onWarning
    this.eventHandler = this.updateExpiredTime.bind(this)
    this.tracker()
    this.startInterval()
  }
  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker)
    }
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem("_expiredTime", Date.now() + this.timeout * 1000)
    })
  }

  tracker() {
    EVENTS.forEach((event) => window.addEventListener(event, this.eventHandler))
  }

  cleanup() {
    window.localStorage.removeItem("_expiredTime")
    clearInterval(this.interval)
    EVENTS.forEach((event) =>
      window.removeEventListener(event, this.eventHandler)
    )
  }

  startInterval() {
    this.updateExpiredTime()
    this.interval = setInterval(() => {
      const expiredTime = parseInt(
        localStorage.getItem("_expiredTime") || 0,
        10
      )
      if (expiredTime > Date.now() && expiredTime - Date.now() < 30000) {
        this.onWarning()
      }
      if (expiredTime < Date.now()) {
        this.onTimeout()
        this.cleanup()
      }
    }, 1000)
  }
}

export default IdleTimer

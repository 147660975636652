import React, { useState } from 'react';
import { ComputationCodesSetup } from '../Formula/ComputationCodes';
import { Tdcv } from '../Formula/Tdcv';
import { ReportSetup } from './ReportSetup';
import '../ApplicationColumns/applicationColumns.scss';

export function CreditScoreReport() {
  const [result, setResult] = useState('');

  const handleSelection = (selectionType, data) => {
      data.computationCode && setResult((prevState) => ({ computationCode: data.computationCode }));
      data.singleCalculation && setResult((prevState) => ({ singleCalculation: data.singleCalculation }));
  };

  const resetComputationCode = () => {
      setResult('');
      return;
  };

  const updateComputationCode = (data) => {
      setResult(data);
      return;
  };

    return (
        <div className="formula p-4">
            <h4 className="mini-heading-top">Credit Score Display Fields</h4>
            <div className="flex">
                <div className="w-1/2 pr-2">
                  <ComputationCodesSetup handler={ handleSelection } selected={result} selectOne={true}/>
                  <Tdcv handler={ handleSelection } selected={result} selectOne={true}/>
                </div>
                <div className="w-1/2 pl-2">
                  <ReportSetup
                    result={result}
                    resetComputationCodeFn={resetComputationCode}
                    updateComputationCode={updateComputationCode}
                    />
                </div>
            </div>
        </div>
    );
}

import React from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { connect } from 'react-redux';
import {
    ButtonGroup,
    Button,
} from 'shards-react';
import Header from '../../../components/widget/widgetTitle';
import { cslActions } from '..';
import { modalActions } from '../../modals';
import { useTranslation } from 'react-i18next';

const DeleteScoreCardCondition = ({ data: id, modalID, dispatch }) => {
    // eslint-disable-next-line no-underscore-dangle
    const _delete = () => {
        dispatch(cslActions.deleteScoreCardGrade(id));
        closeModal();
    };
    const { t } = useTranslation();

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal w-5">
            <Header title={t('module.scoreLogic.component.timeDependent.deleteModal.title')} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal } />
            <div className="p-10">
                <p>{t('module.scoreLogic.component.timeDependent.deleteModal.description')}</p>
                <ButtonGroup className="btn-group-actions site-modal__actions">
                    <Button theme="primary" className="thin delete mr-2" onClick={ () => { _delete(); } } >{t('module.scoreLogic.component.timeDependent.editModal.yes')}</Button>
                    <Button theme="secondary" className="thin" onClick={ () => { closeModal(); } }>{t('module.scoreLogic.component.timeDependent.editModal.cancel')}</Button>
                </ButtonGroup>
            </div>
        </div>
    );
};

const connectedDeleteScoreCardCondition = connect(null)(DeleteScoreCardCondition);
export { connectedDeleteScoreCardCondition as DeleteScoreCardCondition };

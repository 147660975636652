import axios from 'axios';
import { userRoleConstants } from '../../_helpers';
import { useTranslation } from 'react-i18next';

export const kobotoolkitService = {
    getAccessToken,
    getAssets,
    getSurveyDeploymentByVersion,
    getSurveyDeployment,
};

/**
 * Generate credentials to use when authenticating with KobotoolKit
 *
 * User accounts must be kept in sync with kobotoolkit including passwords.
 */
const getCurrentUserAuth = (credentials) => {
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : false;
    const defaultCredentials = {};

    if (!user) {
        return defaultCredentials;
    }

    const { authorities } = user;
    if (authorities.includes(userRoleConstants.FA_ADMIN) || authorities.includes(userRoleConstants.FA_ANALYST)) {
        return {
            username: process.env.REACT_APP_KOBOTOOLKIT_API_USERNAME,
            password: process.env.REACT_APP_KOBOTOOLKIT_API_PASSWORD,
        };
    }

    return credentials;
};

/**
 * Get Kobotoolkit authentication token
 *
 * Stores key in localstorage
 */
function getAccessToken(credentials) {
    const requestConfig = {
        baseURL: process.env.REACT_APP_KOBOTOOLKIT_API_BASE,
        auth: getCurrentUserAuth(credentials),
        params: {
            format: 'json',
        },
    };

    return axios.get('token/', requestConfig).then((res) => {
        if (res.status === 200) {
            const { token: accessTokenString } = res.data;
            localStorage.setItem('kobo', accessTokenString);
            return accessTokenString;
        } if (res.status === 404) {
            const { t } = useTranslation();
            const error = { message: t("module.koboToolKit.service.error") };
            return Promise.reject(error);
        }
        const error = (res && res.message) || res.statusText;
        return Promise.reject(error);
    });
}

function getAssets() {
    const accessTokenString = localStorage.getItem('kobo');

    const requestConfig = {
        baseURL: process.env.REACT_APP_KOBOTOOLKIT_API_BASE,
        params: {
            format: 'json',
        },
        headers: {
            Authorization: `Token ${accessTokenString}`,
        },
    };

    return axios.get('assets/', requestConfig).then((res) => {
        if (res.status === 200) {
            const { data } = res;
            const { results } = data;
            // eslint-disable-next-line camelcase
            return results.filter(({ uid, deployment__active }) => {
                // TODO: Remove First and Daily Survey Filter
                if (process.env.NODE_ENV === 'production' && (uid === 'adRbVE3A96pvFcqKiFkZvW' || uid === 'aq6heytXzkuS44EKjykuCA' || uid === 'ajuqcTpPPaCRozamPaL4Ft')) {
                    return false;
                }
                // eslint-disable-next-line camelcase
                return (deployment__active === true);
            });
        }
        const error = (res && res.message) || res.statusText;
        return Promise.reject(error);
    });
}

function getSurveyDeploymentByVersion(xformIdString, xformversion) {
    const requestConfig = {
        baseURL: process.env.REACT_APP_KOBOTOOLKIT_API_BASE,
        params: {
            format: 'json',
        },
        auth: {
            username: process.env.REACT_APP_KOBOTOOLKIT_API_USERNAME,
            password: process.env.REACT_APP_KOBOTOOLKIT_API_PASSWORD,
        },
    };

    return axios.get(`assets/${xformIdString}/versions/${xformversion}/`, requestConfig).then((res) => {
        if (res.status === 200) {
            const { data } = res;
            return data;
        }
        const error = (res && res.message) || res.statusText;
        return Promise.reject(error);
    });
}

function getSurveyDeployment(xformIdString) {
    const requestConfig = {
        baseURL: process.env.REACT_APP_KOBOTOOLKIT_API_BASE,
        params: {
            format: 'json',
        },
        auth: {
            username: process.env.REACT_APP_KOBOTOOLKIT_API_USERNAME,
            password: process.env.REACT_APP_KOBOTOOLKIT_API_PASSWORD,
        },
    };

    return axios.get(`assets/${xformIdString}/deployment/`, requestConfig).then((res) => {
        if (res.status === 200) {
            const { data } = res;
            const { asset } = data;
            return asset;
        }
        const error = (res && res.message) || res.statusText;
        return Promise.reject(error);
    });
}

import React, { Component } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
} from 'shards-react';
import { connect } from 'react-redux';
import { modalActions } from '../../../modals';

class TableRowsActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            actionsMenuOpen: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle(which) {
        const newState = { ...this.state };
        newState[which] = !this.state[which];
        this.setState(newState);
    }

    editAssumption() {
        this.props.dispatch(
            modalActions.editAssumption({
                id: this.props.id,
            }),
        );
    }

    deleteAssumption() {
        this.props.dispatch(
            modalActions.deleteAssumption({
                id: this.props.id,
            }),
        );
    }

    render() {
        return (
            <Dropdown open={this.state.actionsMenuOpen} toggle={() => this.toggle('actionsMenuOpen')}>
                <DropdownToggle caret>Actions</DropdownToggle>
                <DropdownMenu small className="dropdown-right">
                    <div className="dropdown-item">
                        <div onClick={() => { this.editAssumption(); }}>Edit</div>
                    </div>
                    <div className="dropdown-item">
                        <div onClick={() => { this.deleteAssumption(); }}>Delete</div>
                    </div>
                </DropdownMenu>
            </Dropdown>
        );
    }
}


const connectedtableRowsActions = connect(null)(TableRowsActions);
export { connectedtableRowsActions as TableRowsActions };

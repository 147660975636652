import { axiosInstance, handleResponse } from "../../_helpers/base.service"

const serviceGroup = "workflow"

export const workflowService = {
  assignApplication,
  approveApplication,
  declineApplication,
  getAssignees,
  disburseRefuseApplication,
  addTransactionData,
  disburseMultipleApplications,
}

function assignApplication(applicationId, assignee) {
  const requestConfig = {
    applicationId,
    assignee,
  }

  return axiosInstance
    .post(`${serviceGroup}/workflow/assign/`, requestConfig)
    .then(handleResponse)
}

function approveApplication(applicationId) {
  const requestConfig = {
    applicationId,
    action: "Approved",
  }

  return axiosInstance
    .post(
      `${serviceGroup}/workflow/approve-or-decline-application/`,
      requestConfig
    )
    .then(handleResponse)
}

function declineApplication(applicationId) {
  const requestConfig = {
    applicationId,
    action: "Declined",
  }
  return axiosInstance
    .post(
      `${serviceGroup}/workflow/approve-or-decline-application/`,
      requestConfig
    )
    .then(handleResponse)
}

function getAssignees(applicationId) {
  const requestConfig = {
    applicationId,
  }
  return axiosInstance
    .get(
      `${serviceGroup}/workflow/application/${applicationId}/approvers/`,
      requestConfig
    )
    .then(handleResponse)
}

function disburseRefuseApplication(data) {
  return axiosInstance
    .post(`${serviceGroup}/workflow/disburse-or-refuse-application/`, data)
    .then(handleResponse)
}

function disburseMultipleApplications(data) {
  return axiosInstance
    .post(`${serviceGroup}/workflow/disburse-multiple-applications/`, data)
    .then(handleResponse)
}

function addTransactionData(data) {
  return axiosInstance
    .post(`${serviceGroup}/aggregated-data/`, data)
    .then(handleResponse)
}

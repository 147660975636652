import React, { useState } from 'react';
import { FormControl, Button, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { clientActions } from '../../../../_actions';
import { useStylesClient } from './clientModalStyles';

const SendReminder = ({ mfiId, loanId, label }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [state, setState] = useState({
        mfiId,
        loanId,
        reminderType: 6,
        message: ''
    });
    const classes = useStylesClient();

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setState((prevState) => {
            return { ...prevState, mfiId, loanId, [name]: type === 'radio' ? parseInt(value) : value}
        })
    };

    const handleSubmit = () => {
        return dispatch(clientActions.sendReminder(state));
    }

    return (
        <div>
            <FormControl component="div" className={classes.formWrapper}>
                <FormLabel className={classes.label}>{t('module.client.arrears.action.modal.profile.reminderType')}</FormLabel> &nbsp;&nbsp;
                <RadioGroup
                    aria-label="action taken"
                    name="reminderType"
                    value={state.reminderType.toString()}
                    onChange={handleChange}
                    row
                >
                    <FormControlLabel value='6' control={<Radio />} label={t('module.client.arrears.action.modal.profile.message')} />
                </RadioGroup>
            </FormControl>
            <br />
            <FormControl className={classes.textAreaWrapper}>
                <textarea
                    aria-label="Send Message"
                    rows={4}
                    placeholder={t('module.client.arrears.action.modal.composeMessage')}
                    name="message"
                    className={classes.textArea}
                    onChange={handleChange}
                />
            </FormControl>
            <br />
            <br />
            <Button
                className={classes.button}
                onClick={handleSubmit}
                style={{
                    backgroundColor: `${state.message.length >= 1 ? '#008d6a' : '#c0c0c0'}`,
                    cursor: `${state.message.length >= 1 ? 'pointer' : 'not-allowed'}`
                }}
            >
                {t('module.client.arrears.action.modal.send')}
            </Button>
        </div>
    )
}

export default SendReminder;

export default (theme) => ({
  selectRoot: {
    color: "#002060",
    fontSize: 11,
    paddingTop: 4,
    paddingBottom: 4,
  },
  selectDisabled: {
    color: theme.palette.text.disabled,
    pointerEvents: "none",
  },
})

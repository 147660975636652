/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux'
import {Form, FormGroup, FormSelect} from 'shards-react';
import { LoadingView } from '../../../../views';
import { authenticationActions } from '../../../authentication';
import { mfiManagerActions } from '../../../mfiManager'
import { surveyActions } from '../../../surveys';
import { cslActions } from '../..';
import { withTranslation } from 'react-i18next';
import { userRoleConstants, hasAuthority } from '../../../../_helpers';

const SetQuestionnaire = (props) => {
    const { desc, surveys, setSelectedMfiId, mfis, authentication, scoreLogic, t } = props;
    const mfiList = mfis.items;
    const dispatch = useDispatch();
    const [mfiId, setMfiId] = useState(null)

    const { uid } = scoreLogic;
    const { assets, loading } = surveys;
    const defaultValue = uid || '';

    useEffect(() => {
      if (mfiId) {
        dispatch(surveyActions.getSurveys(mfiId));
      }
    }, [mfiId, dispatch]);

    useEffect(() => {
      if (mfiId && setSelectedMfiId) {
         setSelectedMfiId(mfiId)
      }
    }, [mfiId, dispatch, setSelectedMfiId]);



    useEffect(() => {
      dispatch(authenticationActions.getProfile());
      if (hasAuthority(userRoleConstants.FA_ANALYST) || hasAuthority(userRoleConstants.FA_ADMIN)) {
        dispatch(mfiManagerActions.getAllMFIs());
      }
    }, [dispatch]);

    useEffect(() => {
      if ((!hasAuthority(userRoleConstants.FA_ANALYST) || !hasAuthority(userRoleConstants.FA_ADMIN)) && authentication.profile.mfi) {
        dispatch(surveyActions.getSurveys(authentication.profile.mfi.id))
      }
    }, [dispatch, authentication]);

    useEffect(() => {
      // scroll page to top on displaying component
      const wrapper = document.getElementsByClassName("main-content");
      wrapper[0].scrollIntoView();
    }, [])

    const onSelectChanged = (e) => {
        const { currentTarget } = e;
        const { value } = currentTarget;
        props.dispatch(cslActions.setQuestionnaire(value));
    }

    return (
            <Form className="select-questionnaire shadow-none pr-6 pl-6">
                { loading && <LoadingView /> }
                <p>{desc}</p>
                <FormGroup className="flex items-center">
                {(hasAuthority(userRoleConstants.FA_ANALYST) || hasAuthority(userRoleConstants.FA_ADMIN)) && (
                  <>
                  <label htmlFor="mfiName" className="pr-10 mb-0">{t('module.scoreLogic.view.scoreLogic.titles.selectMFI')}</label>
                  <FormSelect name="mfiName" size="sm" className={`mr-auto w-auto ${!mfiId && 'select-error'}`} value={mfiId} onChange={(e) => setMfiId(e.currentTarget.value)}>
                      <option key={-1} value="">{t('module.scoreLogic.view.scoreLogic.titles.selectMFI')}</option>
                      {mfiList.map( (item, idx) => (<option key={idx} value={item.id}>{item.mfiName}</option>))}
                  </FormSelect>
                  </>
                )}
                </FormGroup>
                <FormGroup className="flex items-center">
                    <label htmlFor="questionnaire" className="pr-10 mb-0">{t('module.scoreLogic.view.setQuestionnaire.selectQuestionnaire')}</label>
                    <FormSelect name="questionnaire" size="sm" className={`mr-auto w-auto ${!mfiId && 'select-error'}`} onChange={(e) => onSelectChanged(e)} value={defaultValue}>
                        <option value="">{loading ? 'Fetching questionaires...' : t('module.scoreLogic.view.setQuestionnaire.selectQuestionnaire')}</option>
                        {
                            !loading && assets && assets.map((questionnaire, idx) => {
                                // eslint-disable-next-line no-shadow
                                const { uid, name, versionId } = questionnaire;
                                return <option key={idx} value={`${uid}:${versionId}`}>{ name }</option>;
                            })
                        }
                    </FormSelect>

                </FormGroup>
            </Form>
        );
}

function mapStateToProps(state) {
    const { surveys, scoreLogic, authentication, mfis } = state;
    return {
        surveys,
        scoreLogic,
        authentication,
        mfis
    };
}

const connectedSetQuestionnaire = withTranslation()(connect(mapStateToProps)(SetQuestionnaire));
export { connectedSetQuestionnaire as SetQuestionnaire };

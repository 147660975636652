/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from 'react';
import { FormSelect, FormGroup } from 'shards-react';
import { useDispatch } from 'react-redux';
import { first } from 'lodash';
import { useTranslation } from 'react-i18next';
import { cslActions } from '../../../_actions';

export const Projection = ({
    uid, surveyComputationCodes, formulas = [], scoreCardProfile = [], getCodeLabel,
}) => {
    const dispatch = useDispatch();
    const [updated, setUpdated] = useState();
    const { t } = useTranslation();
    const defaultProjectionState = {
        id: null,
        name: 'Projection period',
        expression: new Array(['', '']),
        surveyUuid: uid,
        type: 'PROJECTION_PERIOD',
        code: 'PP',
    };

    const defaultLoanTenorState = {
        computationCode: '',
        title: 'Loan Tenor',
        type: 'LOAN_TENOR',
    };

    const [projectionState, setProjectionState] = useState(defaultProjectionState);
    const [loanTenorState, setLoanTenorState] = useState(defaultLoanTenorState);

    useEffect(() => {
        const projectionPeriod = first(formulas.filter((item) => {
            const { type, surveyUuid } = item;
            return type === 'PROJECTION_PERIOD' && surveyUuid === uid;
        }));
        setProjectionState({ ...defaultProjectionState, ...projectionPeriod });
    }, [formulas, uid]);

    useEffect(() => {
        const loanTenor = first(scoreCardProfile.filter((item) => {
            const { type, surveyUuid } = item;
            return type === 'LOAN_TENOR' && surveyUuid === uid;
        }));
        setLoanTenorState({ ...defaultLoanTenorState, ...loanTenor, computationCode: projectionState.computationCode });
    }, [projectionState, scoreCardProfile, uid]);

    useEffect(() => {
        projectionState.id && updated && dispatch(cslActions.editFormula(projectionState.id, projectionState));
        if (!projectionState.id && updated) {
          dispatch(cslActions.addLoanFormula(projectionState));
        }
        setUpdated(false);
    }, [updated]);

    const handleProjectionExpressionChange = (index) => (evt) => {
        const { value } = evt.target;

        setProjectionState((prevState) => {
            const newState = { ...prevState };
            newState.expression[index] = value;
            return ({
                ...prevState,
                expression: [...newState.expression],
            });
        });

        if (Number(index) === 1) {
            const ComputationCodes = first(surveyComputationCodes.filter(({ questionCode }) => questionCode === value));
            setLoanTenorState({
                ...loanTenorState,
                computationCode: value,
                questionKey: ComputationCodes.questionKey,
                surveyUuid: uid,
            });
        }

        setUpdated(true);
    };

    return (
        <div className="flex">
            <div className="group p-4 w-1/2">
                <h3 className="section__title">{t('module.scoreLogic.view.projectionPeriod.projectionStart')}</h3>
                <FormGroup className="flex">
                    <label className="pr-4">{t('module.scoreLogic.view.projectionPeriod.question')}</label>
                    <FormSelect name="expression0" value={projectionState.expression && projectionState.expression[0]} size="sm" className="mr-auto max-w-md" onChange={handleProjectionExpressionChange(0)}>
                        <option key={-1} value="">{t('module.scoreLogic.view.projectionPeriod.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => {
                                const { questionCode } = questionnaire;
                                return <option key={idx} value={questionCode}>{getCodeLabel(questionCode)}</option>;
                            })
                        }
                    </FormSelect>
                </FormGroup>

                <FormGroup className="flex">
                    <label className="pr-4" style={{ width: '138px' }}>{t('module.scoreLogic.view.projectionPeriod.computationCode')}</label>
                    <div className="projectionState-expression">{projectionState.expression && projectionState.expression[0]}</div>
                </FormGroup>
            </div>

            <div className="group p-4 w-1/2">
                <h3 className="section__title">{t('module.scoreLogic.view.projectionPeriod.projectionLength')}</h3>
                <FormGroup className="group flex">
                    <label className="pr-4">{t('module.scoreLogic.view.projectionPeriod.question')}</label>
                    <FormSelect name="expression1" value={projectionState.expression && projectionState.expression[1]} size="sm" className="mr-auto max-w-md" onChange={handleProjectionExpressionChange(1)}>
                        <option key={-1} value="">{t('module.scoreLogic.view.projectionPeriod.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => {
                                const { questionCode } = questionnaire;
                                return <option key={idx} value={questionCode}>{getCodeLabel(questionCode)}</option>;
                            })
                        }
                    </FormSelect>
                </FormGroup>

                <FormGroup className="flex">
                    <label className="pr-4" style={{ width: '138px' }}>{t('module.scoreLogic.view.projectionPeriod.computationCode')}</label>
                    <div className="projectionState-expression">{projectionState.expression && projectionState.expression[1]}</div>
                </FormGroup>
            </div>
        </div>
    );
};

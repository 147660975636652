import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Form, FormGroup, FormSelect } from "shards-react"

import "./ApplicationTableFilters.scss"

const FilterApplicationQuestionnaire = ({
  selectedValue,
  className,
  assets,
  loading,
  handler,
  groupView,
  collectionView,
  t,
}) => {
  return (
    <Form className={`${className} shadow-none bg-transparent m-0`}>
      <FormGroup className="flex items-center mb-0">
        <FormSelect
          name="uid"
          size="sm"
          className="mr-auto w-auto"
          onChange={handler}
          value={selectedValue}
        >
          <option key={-1} value="">
            {loading
              ? "Fetching questionaires..."
              : t(
                  "module.applicationManager.component.applicationTableFilters.select"
                )}
          </option>
          {!loading &&
            assets &&
            assets
              .filter(
                (questionnaire) =>
                  (questionnaire.status === "Active" &&
                    ((!groupView &&
                      !questionnaire.groupCreation &&
                      !collectionView &&
                      !questionnaire.collection) ||
                      (groupView && questionnaire.groupCreation))) ||
                  (collectionView && questionnaire.collection)
              )
              .map((questionnaire, idx) => {
                const { uid, name, versionId } = questionnaire
                return (
                  <option key={idx} value={`${uid}:${versionId}`}>
                    {name}
                  </option>
                )
              })}
        </FormSelect>
      </FormGroup>
    </Form>
  )
}

const mapStateToProps = (state) => {
  const { surveys } = state
  const { assets, loading } = surveys
  return { assets, loading }
}

const connectedTableFilters = withTranslation()(
  connect(mapStateToProps)(FilterApplicationQuestionnaire)
)
export { connectedTableFilters as FilterApplicationQuestionnaire }

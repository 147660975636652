import React from "react"
import { useDispatch } from "react-redux"
import PersonOutlineOutlinedIcon from "@material-ui/icons/LockOutlined"
import { ButtonGroup, Button } from "shards-react"
import { useTranslation } from "react-i18next"
import Header from "../../../components/widget/widgetTitle"
import { modalActions } from "../../modals/_actions"
import { mfiManagerActions } from ".."

export function DeleteMFISubrole({ modalID, data: id }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const deleteMFISubRole = () => {
    dispatch(mfiManagerActions.deleteMFISubRole(id))
    closeModal()
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }
  return (
    <div className="site-modal">
      <Header
        title={t("module.mfiSetup.userRoles.deleteRoleTitle")}
        icon={<PersonOutlineOutlinedIcon />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        <p>{t("module.mfiSetup.userRoles.deleteDescription")}</p>
        <ButtonGroup className="btn-group-actions site-modal__actions">
          <Button
            theme="primary"
            className="thin delete mr-2"
            onClick={() => {
              deleteMFISubRole()
            }}
          >
            {t("module.mfiSetup.userRoles.yes")}
          </Button>
          <Button
            theme="secondary"
            className="thin"
            onClick={() => {
              closeModal()
            }}
          >
            {t("module.mfiSetup.userRoles.cancel")}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

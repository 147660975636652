import { createStore, applyMiddleware } from "redux"
import thunkMiddleware from "redux-thunk"
import { createLogger } from "redux-logger"
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly" // remove on deployment
import rootReducer from "./reducer"

const loggerMiddleware = createLogger()
const persistedState = {}
const middlewares =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(thunkMiddleware)
    : composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware))

// Redux debug tools
export const store = createStore(rootReducer, persistedState, middlewares)

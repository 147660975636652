import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Form, FormGroup, FormSelect, FormCheckbox } from "shards-react"
import { cslActions } from "../.."

export const ApprovalWorkflow = (props) => {
  useEffect(() => {
    // scroll page to top on displaying component
    const wrapper = document.getElementsByClassName("main-content")
    wrapper[0].scrollIntoView()
  }, [])
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const scoreLogic = useSelector((state) => state.scoreLogic)
  const [skipLoanOfficer, setSkipLoanOfficer] = useState(
    scoreLogic.surveyGroupWorkflow.skipLoanOfficer
      ? scoreLogic.surveyGroupWorkflow.skipLoanOfficer
      : false
  )
  const [assignLoanOfficer, setAssignLoanOfficer] = useState(
    scoreLogic.surveyGroupWorkflow.assignBackToLoanOfficer
      ? scoreLogic.surveyGroupWorkflow.assignBackToLoanOfficer
      : false
  )
  const [approveAssignForward, setApproveAssignForward] = useState(
    scoreLogic.surveyGroupWorkflow.approveAssignForward
      ? scoreLogic.surveyGroupWorkflow.approveAssignForward
      : false
  )
  const [workflowGroupId, setWorkflowGroupId] = useState(
    scoreLogic.surveyGroupWorkflow.workflowGroupId
  )
  const surveyUuid = scoreLogic.uid

  useEffect(() => {
    dispatch(cslActions.getSurveyWorkflowGroups(surveyUuid))
  }, [surveyUuid, dispatch])

  useEffect(() => {
    // get all groups
    props.selectedMfiId &&
      dispatch(cslActions.getWorkflowGroups(props.selectedMfiId))
  }, [dispatch, props.selectedMfiId])

  useEffect(() => {
    const payload = {
      surveyUuid: surveyUuid,
      workflowGroupId: workflowGroupId,
      assignBackToLoanOfficer: assignLoanOfficer,
      skipLoanOfficer: skipLoanOfficer,
      approveAssignForward,
    }
    // Dispatch with all data
    dispatch(cslActions.setSurveyWorkflowGroups(payload))
  }, [
    surveyUuid,
    workflowGroupId,
    assignLoanOfficer,
    skipLoanOfficer,
    approveAssignForward,
    dispatch,
  ])

  const onSelectChanged = (e) => setWorkflowGroupId(e.currentTarget.value)

  return (
    <>
      <Form className="shadow-none p-6">
        <p>
          {t("module.scoreLogic.view.scoreLogic.approvalWorkflow.subTitle")}
        </p>
        <FormGroup className="flex items-center">
          <label htmlFor="workflow" className="pr-10 mb-0">
            {t(
              "module.scoreLogic.view.scoreLogic.approvalWorkflow.selectWorkflow"
            )}
          </label>
          <FormSelect
            name="workflow"
            size="sm"
            className="mr-auto w-auto"
            onChange={onSelectChanged}
          >
            <option value="">
              {t(
                "module.scoreLogic.view.scoreLogic.approvalWorkflow.selectWorkflow"
              )}
            </option>
            {scoreLogic.groups &&
              scoreLogic.groups.map((group, idx) => {
                return (
                  <option
                    key={idx}
                    value={group.id}
                    selected={
                      scoreLogic.surveyGroupWorkflow.workflowGroupId ===
                      group.id
                    }
                  >
                    {group.code}
                  </option>
                )
              })}
          </FormSelect>
        </FormGroup>
      </Form>
      <FormGroup className="flex items-center">
        <label htmlFor="skipLoanOfficer" className="pr-10 mb-0"></label>
        <FormCheckbox
          name="skipLoanOfficer"
          checked={skipLoanOfficer}
          className="mr-auto w-auto"
          onChange={(e) => setSkipLoanOfficer(!skipLoanOfficer)}
        >
          {t(
            "module.scoreLogic.view.scoreLogic.approvalWorkflow.skipLoanOfficer"
          )}
        </FormCheckbox>
      </FormGroup>
      <FormGroup className="flex items-center">
        <label htmlFor="assignLoanOfficer" className="pr-10 mb-0"></label>
        <FormCheckbox
          name="assignLoanOfficer"
          checked={assignLoanOfficer}
          className="mr-auto w-auto"
          onChange={(e) => setAssignLoanOfficer(!assignLoanOfficer)}
        >
          {t(
            "module.scoreLogic.view.scoreLogic.approvalWorkflow.supervisorShouldAssign"
          )}
        </FormCheckbox>
      </FormGroup>
      <FormGroup className="flex items-center">
        <label htmlFor="approveAssignForward" className="pr-10 mb-0"></label>
        <FormCheckbox
          name="approveAssignForward"
          checked={approveAssignForward}
          className="mr-auto w-auto"
          onChange={(e) => setApproveAssignForward(!approveAssignForward)}
        >
          {t(
            "module.scoreLogic.view.scoreLogic.approvalWorkflow.approveAssignForward"
          )}
        </FormCheckbox>
      </FormGroup>
    </>
  )
}

import React, { useRef } from "react"
import SignatureCanvas from "react-signature-canvas"
import "./index.scss"

const SignaturePad = ({ currentUser, saveSignature }) => {
  const userSignatureCanvasRef = useRef(null)

  const clearSignature = () => {
    userSignatureCanvasRef.current.clear()
  }

  const getSignature = () => {
    const signatureImage = userSignatureCanvasRef.current.toDataURL("image/png")
    saveSignature(signatureImage)
  }

  return (
    <>
      <SignatureCanvas
        ref={userSignatureCanvasRef}
        penColor="#008d6a"
        minDistance={1}
        onEnd={getSignature}
        canvasProps={{
          width: 675,
          height: 85,
          className: "signature-canvas",
        }}
      />
      <div className="flex justify-between mb-3">
        <span className="text-xs">{`${currentUser} (current user)`}</span>
        <button className="text-xs" type="button" onClick={clearSignature}>
          Clear Signature
        </button>
      </div>
    </>
  )
}

export default SignaturePad

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { first, filter } from 'lodash';
import { withTranslation } from 'react-i18next';

import {
    Form,
    FormGroup,
    FormSelect,
} from 'shards-react';

import { Formula } from '../Formula/';

class TimeDependentCalculated extends Component {
    constructor(props) {
        super(props);
        this.state = { code: '' };
    }

    componentDidMount() {
      // scroll page to top on displaying component
      const wrapper = document.getElementsByClassName("main-content");
      wrapper[0].scrollIntoView();
    }

    handler(someValue) {
        this.setState(someValue);
    }

    onSelectChanged(e) {
        this.setState({ code: e.currentTarget.value });
    }

    render() {
        const { scoreLogic, t } = this.props;
        const { code } = this.state;
        const { uid, formulas } = scoreLogic;
        const surveyFormulas = formulas.filter((item) => item.surveyUuid === uid);
        const timeDependentFlags = filter(surveyFormulas, { type: 'TIME_DEPENDENCY_FLAG' });

        const Flags = Object(first(filter(surveyFormulas, { code, type: 'TIME_DEPENDENCY_FLAG' })));
        const { name } = Flags;

        return (
            <>
                { !code && <Form className="select-time-dependent-variable shadow-none">
                    <FormGroup className="flex items-center">
                        <label htmlFor="tdcv" className="pr-10 mb-0">{t('module.scoreLogic.view.timeDependentCalculated.selectVariable')}</label>
                        <FormSelect name="tdcv" size="sm" className="mr-auto w-auto" onChange={this.onSelectChanged.bind(this)}>
                            <option value="select">{t('module.scoreLogic.view.timeDependentCalculated.select')}</option>
                            {
                                timeDependentFlags && timeDependentFlags.map((tdcv, idx) => {
                                    // eslint-disable-next-line no-shadow
                                    const { code, name } = tdcv;
                                    return <option key={idx} value={code}>{ name }</option>;
                                })
                            }
                        </FormSelect>
                    </FormGroup>
                </Form> }
                { code && <Formula id={code} type='TIME_DEPENDENCY_FLAG' title={ `${code} - ${name}`}/> }
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { scoreLogic } = state;
    return {
        scoreLogic,
    };
};

const connectedTimeDependentCalculated = withTranslation()(connect(mapStateToProps)(TimeDependentCalculated));
export { connectedTimeDependentCalculated as TimeDependentCalculated };

/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { connect } from 'react-redux';
import { SidebarMainNavbar } from './SidebarMainNavbar';
import { SidebarNavItems } from './SidebarNavItems';


class MainSidebar extends React.Component {
    render() {
        const { settings } = this.props;
        const { menuVisible } = settings;

        !menuVisible ? document.getElementById('root').classList.remove('sidebar-open') : document.getElementById('root').classList.add('sidebar-open');

        const classes = classNames(
            'main-sidebar',
            'px-0',
            'font-sans',
            menuVisible && 'open',
        );

        return (
            <div tag="aside" className={classes}>
                <SidebarMainNavbar/>
                <SidebarNavItems />
            </div>
        );
    }
}

MainSidebar.propTypes = {
    /**
   * Whether to hide the logo text, or not.
   */
    hideLogoText: PropTypes.bool,
};

MainSidebar.defaultProps = {
    hideLogoText: false,
};

const mapStateToProps = (state) => {
    const { settings } = state;
    return {
        settings,
    };
};

const connectedMainSidebar = connect(mapStateToProps)(MainSidebar);
export { connectedMainSidebar as MainSidebar };

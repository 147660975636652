import React from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { connect, useSelector } from 'react-redux';
import {
    ButtonGroup,
    Button,
} from 'shards-react';
import Header from '../../../../components/widget/widgetTitle';
import { modalActions } from '../../../modals';
import { clientActions } from '../../_actions';
import { LoadingView } from '../../../../views';

const AssignToModal = ({ data, modalID, dispatch }) => {
    const { id: { payload, refetchClients } } = data;
    const { assigneeloading } = useSelector((state) => state.clients);

    // eslint-disable-next-line no-underscore-dangle
    const _assignTo = () => {
        // dispatch(configActions.AssignToModal(id));
        dispatch(clientActions.AssignTo(payload && payload, refetchClients))
        //for better ux
        setTimeout(() => {
            if (assigneeloading !== true) {
                closeModal();
            }
        }, 1800);

    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal w-5">
            <Header title="Assign to User" icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={closeModal} />
            <div className="p-10">
                <p>Are you sure you want to assign to {payload && payload.name}</p>
                {assigneeloading && <LoadingView/>}
                <ButtonGroup className="btn-group-actions site-modal__actions">
                    <Button theme="primary" className="thin delete mr-2" onClick={() => { _assignTo(); }} >Yes</Button>
                    <Button theme="secondary" className="thin" onClick={() => { closeModal(); }}>Cancel</Button>
                </ButtonGroup>
            </div>
        </div>
    );
};

const connectedAssignToModal = connect(null)(AssignToModal);
export { connectedAssignToModal as AssignToModal };

import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'shards-react';
import Svg from 'react-inlinesvg';
import { mfiManagerActions } from '../..';

const deleteIconSVG = require('./../../../../assets/images/icons/delete.svg');

export function DeleteAction({ rowData: { id } }) {
    const dispatch = useDispatch();
    const deleteIcon = <Svg className="text-center d-inline-block" src={deleteIconSVG} />;

    const deleteRole = () => {
        dispatch(mfiManagerActions.deleteMFISubRoleLimit(id));
    };

    return (
        <Button theme="danger" className="shadow-none" onClick={ () => { deleteRole(); } }>{deleteIcon}</Button>
    );
}

/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from 'react';
import { FormSelect, FormGroup } from 'shards-react';
import { useDispatch } from 'react-redux';
import { first } from 'lodash';
import { useTranslation } from 'react-i18next';
import { cslActions } from '../../../_actions';

export const Loan = ({
    uid, surveyComputationCodes, formulas = [], getCodeLabel,
}) => {
    const dispatch = useDispatch();
    const [updated, setUpdated] = useState(false);
    const { t } = useTranslation();
    const defaultState = {
        id: null,
        name: 'Existing Loan',
        expression: new Array(['', '']),
        surveyUuid: uid,
        type: 'EXISTING_LOAN',
        code: 'EL',
    };
    const [state, setState] = useState(defaultState);

    useEffect(() => {
        const existingLoan = first(formulas.filter((item) => {
            const { type, surveyUuid } = item;
            return type === 'EXISTING_LOAN' && surveyUuid === uid;
        }));
        setState({ ...defaultState, ...existingLoan });
    }, [formulas, uid]);

    useEffect(() => {
        state.id && updated && dispatch(cslActions.editFormula(state.id, state));
        !state.id && updated && dispatch(cslActions.addLoanFormula(state));
        setUpdated(false);
    }, [updated, dispatch]);

    const handleLoanExpressionChange = (index) => (evt) => {
        const { value } = evt.target;
        setState((prevState) => {
            const newState = {...prevState};
            newState.expression[index] = value;
            return ({
                ...prevState,
                expression: [...newState.expression],
            });
        });
        setUpdated(true);
    };

    return (
        <div className="flex">
            <div className="group p-4 w-1/2">
                <h3 className="section__title">{t('module.scoreLogic.view.projectionPeriod.existingLoan')}</h3>
                <FormGroup className="flex">
                    <label className="pr-4">{t('module.scoreLogic.view.projectionPeriod.question')}</label>
                    <FormSelect name="expression0" value={state.expression && state.expression[0]} size="sm" className="mr-auto max-w-md" onChange={handleLoanExpressionChange(0)}>
                        <option key={-1} value="">{t('module.scoreLogic.view.projectionPeriod.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => {
                                const { questionCode } = questionnaire;
                                return <option key={idx} value={questionCode}>{getCodeLabel(questionCode)}</option>;
                            })
                        }
                    </FormSelect>
                </FormGroup>

                <FormGroup className="flex">
                    <label className="pr-4" style={{ width: '138px' }}>{t('module.scoreLogic.view.projectionPeriod.computationCode')}</label>
                    <div className="projection-expression">{state.expression && state.expression[0]}</div>
                </FormGroup>
            </div>

            <div className="group p-4 w-1/2">
                <h3 className="section__title">{t('module.scoreLogic.view.projectionPeriod.monthsLeft')}</h3>
                <FormGroup className="group flex">
                    <label className="pr-4">{t('module.scoreLogic.view.projectionPeriod.question')}</label>
                    <FormSelect name="expression1" value={state.expression && state.expression[1]} size="sm" className="mr-auto max-w-md" onChange={handleLoanExpressionChange(1)}>
                        <option key={-1} value="">{t('module.scoreLogic.view.projectionPeriod.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => {
                                const { questionCode } = questionnaire;
                                return <option key={idx} value={questionCode}>{getCodeLabel(questionCode)}</option>;
                            })
                        }
                    </FormSelect>
                </FormGroup>

                <FormGroup className="flex">
                    <label className="pr-4" style={{ width: '138px' }}>{t('module.scoreLogic.view.projectionPeriod.computationCode')}</label>
                    <div className="projection-expression">{state.expression && state.expression[1]}</div>
                </FormGroup>
            </div>
        </div>
    );
};

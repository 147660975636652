import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import { clientActions } from '../../../_actions';
import { useTranslation } from 'react-i18next';

const SearchClients = ({ mfiId }) => {
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState("");
    const { t } = useTranslation();

    const handleSearchInput = (e) => {
      setSearchInput(e.target.value);
    }

    const handleSubmitSearch = (searchValue) => {
      dispatch(clientActions.searchClient(mfiId, encodeURIComponent(searchValue)));
    }

    const handleKeyPressOnSubmit = (e) => {
      if(e.keyCode === 13) {
          dispatch(clientActions.searchClient(mfiId, encodeURIComponent(searchInput)));
        }
    }

    const clearSearchInput = () => {
      setSearchInput("");
      setTimeout(() => {
        handleSubmitSearch("")
      }, 500);
    }

    return (
      <>
        <div style={{
          display: 'flex',
          paddingRight: '10px'
        }}>
          <input
            placeholder={t('module.client.arrears.search')}
            aria-label='search'
            style={{
              fontFamily: "inherit",
              fontSize: "inherit",
              marginLeft: 10,
              paddingTop: '2px',
              padding: '4px 12px',
              borderRadius: '4px 0 0 4px',
              border: '1px solid #185597',
              outline: 'none'
            }}
            className="inputSearch"
            onChange={handleSearchInput}
            onKeyDown={handleKeyPressOnSubmit}
            value={searchInput}
            autoFocus
          />
          {searchInput.length > 0 &&
            <span
              style={{
                borderRadius: "50%",
                margin: "0 0.6rem 0 -1.7rem",
                backgroundColor: "lightgrey",
                alignSelf: "center",
                padding: "0 2px"
              }}
            >
              <CloseIcon
                style={{
                  color: "grey",
                  fontSize: "0.9rem",
                  cursor: "pointer",
                  marginBottom: "2px"
                }}
                onClick={clearSearchInput}
              />
            </span>
          }
          <span style={{
            borderRadius: '0 3px 3px 0',
            borderTop: '1px solid',
            borderRight: '1px solid',
            borderBottom: '1px solid',
            marginLeft: '-2px',
            paddingLeft: '4px'
          }}>
            <SearchIcon
              style={{
                color: "#a1b6ca",
                marginTop: '4px',
                cursor: "pointer",
              }}
              onClick={() => handleSubmitSearch(searchInput)}
            />
          </span>
        </div>
      </>
    );
}

export default SearchClients;

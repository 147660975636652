import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'shards-react';
import { useTranslation } from 'react-i18next';
import Svg from 'react-inlinesvg';
import { modalActions } from '../../../modals';

// const editIconSVG = require('../../../../assets/images/icons/pencil-icon.svg');
const deleteIconSVG = require('../../../../assets/images/icons/delete.svg');

function ScoreCard({ scoreLogic, dispatch }) {
    const { uid, scoreGrade } = scoreLogic;
    const surveyScoreGrade = scoreGrade.filter((item) => item.surveyUuid === uid);
    const { t } = useTranslation();

    useEffect(() => {
      // scroll page to top on displaying component
      const wrapper = document.getElementsByClassName("main-content");
      wrapper[0].scrollIntoView();
    }, []);

    // eslint-disable-next-line no-restricted-globals
    const isNumeric = (x) => ((typeof x === 'number' || typeof x === 'string') && !isNaN(Number(x)));

    const addVariableCondition = () => {
        dispatch(modalActions.addScoreCardCondition());
    };

    // const editConfig = (id) => {
    //     dispatch(modalActions.addScoreCardCondition(id));
    // };

    // eslint-disable-next-line no-underscore-dangle
    const _delete = (id) => {
        dispatch(modalActions.deleteScoreCardCondition(id));
    };

    // const editIcon = <Svg className="text-center d-inline-block" src={editIconSVG} />;
    const deleteIcon = <Svg className="text-center d-inline-block" src={deleteIconSVG} />;

    return (
        <div className="scoring-variables shadow-none p-6">
            <div className="scoring-variables__title-bar flex">
                <div className="scoring-variables__label scoring-variables__label--code"></div>
                <div className="scoring-variables__label scoring-variables__label--description">{t('module.scoreLogic.view.scoreCard.description')}</div>
                <div className="scoring-variables__label scoring-variables__label--codes"></div>
                <div className="scoring-variables__label scoring-variables__label--threshold">{t('module.scoreLogic.view.scoreCard.threshold')}</div>
                <div className="scoring-variables__label scoring-variables__label--condition">{t('module.scoreLogic.view.scoreCard.condition')}</div>
                <div className="scoring-variables__label scoring-variables__label--max-points">{t('module.scoreLogic.view.scoreCard.maxPoints')}</div>
            </div>
            <div className="scoring-variables__list">
                {
                    <div className={'scoring-variables__row flex'}>
                        <div className="scoring-variables__entries">
                            <div className="scoring-variables__title flex">
                                <div className="scoring-variables__title-inner">
                                    <div className="scoring-variables__title-label">{t('module.scoreLogic.view.scoreCard.totalPoints')}\</div>
                                    <Button theme="primary" className="m-2" onClick={ () => { addVariableCondition(); } }>{t('module.scoreLogic.view.scoreCard.addCondition')}</Button>
                                </div>
                                <div className="spacer"></div>
                                <div className="scoring-variables__title-number"></div>
                            </div>
                            {
                                <div className="scoring-variables__entry flex">
                                    <div className="scoring-variables__configurations ml-auto">
                                        { surveyScoreGrade && surveyScoreGrade.map((item) => {
                                            const { id, grade, maximum } = item;
                                            return (
                                                <div className="scoring-variables__configuration flex items-center" key={id}>
                                                    {/*<Button theme="success" className="scoring-variables__configuration-edit shadow-none" onClick={ () => { editConfig(id); } }>{editIcon}</Button>*/}
                                                    <Button theme="danger" className="scoring-variables__configuration-delete shadow-none" onClick={ () => { _delete(id); } }>{deleteIcon}</Button>
                                                    <div className="scoring-variables__configuration-value">{maximum}</div>
                                                    <div className="scoring-variables__configuration-condition"> { isNumeric(maximum) && `${t('module.scoreLogic.view.scoreCard.ifLessThan')} ${maximum}` } </div>
                                                    <div className="scoring-variables__configuration-maxScore">{grade}</div>
                                                </div>
                                            );
                                        }) }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { scoreLogic } = state;
    return { scoreLogic };
};

const connectedScoreCard = connect(mapStateToProps)(ScoreCard);
export { connectedScoreCard as ScoreCard };

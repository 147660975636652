import React, { useState } from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useDispatch } from 'react-redux';
import {FormGroup,FormInput,ButtonGroup,Button} from 'shards-react';
import Header from '../../../components/widget/widgetTitle';
import { mfiManagerActions } from '..';
import { modalActions } from '../../modals';

export const EditHierarchy = ({ modalID, data }) => {
    const { id, name, mfiId, layer } = data;
    const dispatch = useDispatch();
    const [entityName, setEntityName] = useState(name);
    const [tabIndex, setTabIndex] = useState(0);
    // const [tab, setTab] = useState("entity");
    const [layerName, setLayerName] = useState(layer.name);

    const editHierarchy = () => {
        const payload = { mfiId: Number(mfiId) };
        if (tabIndex === 0) {
          payload.name = entityName;
          dispatch(mfiManagerActions.editHierarchy(payload, Number(id.replace('node', '')), layer.id));
        } else {
          payload.name = layerName;
          dispatch(mfiManagerActions.editHierarchyLayer(payload, layer.id));
        }
        closeModal();
    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal">
            <Header title={tabIndex === 0 ? 'Edit Entity' : 'Edit Layer'} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal }/>
            <div className="hierarchy-modal-container">
              {tabIndex === 0
                ? (
                  <>
                  <h6>Edit Layer instead? <b onMouseDown={() => setTabIndex(1)}>Click Here</b></h6>
                  <hr />
                  <FormGroup>
                      <label htmlFor="name">Entity Name</label>
                      <FormInput value={entityName} name="name" size="md" className="mb-2" onChange={(e) => setEntityName(e.target.value)}/>
                  </FormGroup>
                  <ButtonGroup className="btn-group-actions site-modal__actions">
                      <Button theme="primary" className="thin mr-2" onClick={() => editHierarchy()}>Edit</Button>
                      <Button theme="secondary" className="thin delete" onClick={ () => { closeModal(); } }>Cancel</Button>
                  </ButtonGroup>
                  </>
                )
                : (
                  <>
                  <h6>Edit Entity instead? <b onMouseDown={() => setTabIndex(0)}>Click Here</b></h6>
                  <hr />
                  <FormGroup>
                      <label htmlFor="name">Layer Name</label>
                      <FormInput value={layerName} name="layerName" size="md" className="mb-2" onChange={(e) => setLayerName(e.target.value)}/>
                  </FormGroup>
                  <ButtonGroup className="btn-group-actions site-modal__actions">
                      <Button theme="primary" className="thin mr-2" onClick={() => editHierarchy()}>Edit</Button>
                      <Button theme="secondary" className="thin delete" onClick={ () => { closeModal(); } }>Cancel</Button>
                  </ButtonGroup>
                  </>
                )
              }
            </div>
        </div>
    );
};

/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { useTranslation } from 'react-i18next';

function CashFlowVariable({ selected = [], selectOne, handler }) {
    const surveyCashFlowVariable = [{
        name: 'Affordability',
        code: 'K',
    }];

    const [state, setState] = useState(selected);
    const returnType = 'cashFlowVariable';
    const { t } = useTranslation();


    useEffect(() => {
        handler(returnType, { [returnType]: state });
    }, [state]);

    const selectItem = (which) => {
        let newState = [...state];

        if (selectOne) {
            newState = [];
        }

        const index = newState.indexOf(which);

        if (index === -1) {
            if (selectOne) {
                newState = [which];
            } else {
                newState.push(which);
            }
        } else {
            newState.splice(index, 1);
        }

        setState(newState);
    };

    return (
        <div className="items-group mb-2 cashflow-variable w-full">
            <h4>{t('module.scoreLogic.component.cashFlowVariable.title')}</h4>
            <div className="items-holder flex flex-wrap">
                {
                    surveyCashFlowVariable.map((assumption) => {
                        const { name, code } = assumption;
                        const active = state && state.includes(code) && 'variable-item-active';

                        return (
                            <Tooltip key={code} placement="top" overlay={name} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                <div className={`item variable-item ${active} variable-item__code-${code}` } onClick={ () => { selectItem(code); } } key={code}>{ name }</div>
                            </Tooltip>
                        );
                    })
                }
            </div>
        </div>
    );
}

const mapStateToProps = () => {};

const connectedCashFlowVariable = connect(mapStateToProps)(CashFlowVariable);
export { connectedCashFlowVariable as CashFlowVariable };

import React from 'react';
import { Container, Row, Col } from 'shards-react';

import { ForgotUsername as ForgotUsernameComponent } from '..';

export const ForgotUsernameView = () => (
    <Container fluid className="main-content-container px-4 pb-4">
        <Row>
            <Col lg="12" md="12">
                <ForgotUsernameComponent />
            </Col>
        </Row>
    </Container>
);

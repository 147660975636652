import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import PersonOutlineOutlinedIcon from "@material-ui/icons/LockOutlined"
import {
  FormGroup,
  FormInput,
  FormSelect,
  ButtonGroup,
  Button,
  FormCheckbox,
} from "shards-react"
import { useTranslation } from "react-i18next"
import { map } from "lodash"
import Header from "../../../components/widget/widgetTitle"
import { mfiManagerActions } from ".."
import { modalActions } from "../../modals"

export const ManageMFISubRoles = ({ modalID, data }) => {
  const { subroleID, mfiCode, mfiID: mfiId, hierarchyLayer } = data
  const { hierarchy, roles } = useSelector((store) => store.mfis)
  const dispatch = useDispatch()
  const [hierarchies, setHierarchies] = useState([])
  const [inputs, setInputs] = useState({
    code: "",
    description: "",
    role: "",
    hierarchyLayerId: "",
    canDisburse: false,
    mfiCode,
    ...data,
  })
  const { t } = useTranslation()

  useEffect(() => {
    if (mfiId) {
      dispatch(mfiManagerActions.getHierarchy(mfiId))
    }
    dispatch(mfiManagerActions.getMFIRoles())
  }, [dispatch, mfiId])

  useEffect(() => {
    if (subroleID && roles) {
      const targetRoleDetails = roles.filter(
        (item) => item.name === inputs.role
      )
      if (targetRoleDetails.length > 0) {
        setInputs((prevState) => ({
          ...prevState,
          role: targetRoleDetails[0].id,
        }))
      }
    }
  }, [subroleID, roles, inputs.role])

  useEffect(() => {
    if (hierarchyLayer) {
      const targetHierarchy = hierarchies.filter(
        (item, i) => item.name === hierarchyLayer
      )
      if (targetHierarchy.length > 0) {
        setInputs((prevState) => ({
          ...prevState,
          hierarchyLayerId: targetHierarchy[0].id,
        }))
      }
    }
  }, [hierarchyLayer, hierarchies])

  useEffect(() => {
    if (hierarchy) {
      let updatedHierarchies = []
      hierarchy.forEach((item, i) => {
        updatedHierarchies.push({ name: item.name, id: item.id })
      })
      setHierarchies(updatedHierarchies)
    }
  }, [hierarchy])

  /**
   * Exclude some Roles from selection
   */
  const excludeRoles = ["SUPER_ADMIN", "FA_ADMIN", "MFI_ADMIN", "FA_ANALYST"]

  const handleChange = async (e) => {
    const { name, value } = e.target
    setInputs((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleCheckBoxChange = () => {
    setInputs((prevState) => ({
      ...prevState,
      canDisburse: !prevState.canDisburse,
    }))
  }

  const addMFISubRoles = async () => {
    let payload = {
      code: inputs.code,
      description: inputs.description,
      hierarchyLayerId: Number(inputs.hierarchyLayerId),
      mfiId: Number(mfiId),
      roleId: Number(inputs.role),
      canDisburse: inputs.canDisburse,
    }

    !subroleID && (await dispatch(mfiManagerActions.addMFISubRoles(payload)))
    subroleID &&
      (await dispatch(mfiManagerActions.updateMFISubRole(subroleID, payload)))
    dispatch(mfiManagerActions.getMFISubRoles(mfiId))
    closeModal()
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  return (
    <div className="site-modal">
      <Header
        title={
          subroleID
            ? t("module.mfiSetup.userRoles.editRoleTitle")
            : t("module.mfiSetup.userRoles.addRoleTitle")
        }
        icon={<PersonOutlineOutlinedIcon />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        <FormGroup>
          <label htmlFor="code">
            {t("module.mfiSetup.userRoles.roleCode")}
          </label>
          <FormInput
            defaultValue={inputs.code}
            name="code"
            size="md"
            className="mb-2"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="description">
            {t("module.mfiSetup.userRoles.description")}
          </label>
          <FormInput
            defaultValue={inputs.description}
            name="description"
            size="md"
            className="mb-2"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="role">
            {t("module.mfiSetup.userRoles.systemRole")}
          </label>
          <FormSelect
            name="role"
            size="sm"
            className={"select"}
            onChange={handleChange}
            value={inputs.role}
          >
            <option key={-1} value="">
              c
            </option>
            {map(roles, (item) => {
              if (!excludeRoles.includes(item.name)) {
                return (
                  <option
                    key={item.id}
                    value={item.id}
                    selected={inputs.role === item.id}
                  >
                    {item.name}
                  </option>
                )
              }
            })}
          </FormSelect>
        </FormGroup>
        <FormGroup>
          <label htmlFor="hierarchyLayerId">
            {t("module.mfiSetup.userRoles.level")}
          </label>
          <FormSelect
            name="hierarchyLayerId"
            size="sm"
            className={"select"}
            onChange={handleChange}
            value={inputs.hierarchyLayerId}
          >
            <option key={-1} value="">
              {t("module.mfiSetup.userRoles.selectLevel")}
            </option>
            {map(hierarchies, (item) => {
              return (
                <option
                  key={item.id}
                  value={item.id}
                  selected={inputs.hierarchyLayerId === item.id}
                >
                  {item.name}
                </option>
              )
            })}
          </FormSelect>
        </FormGroup>
        <FormGroup>
          <FormCheckbox
            checked={inputs.canDisburse}
            onChange={handleCheckBoxChange}
          >
            {t("module.mfiSetup.userRoles.canDisburse")}
          </FormCheckbox>
        </FormGroup>
        <ButtonGroup className="btn-group-actions site-modal__actions">
          <Button
            theme="primary"
            className="thin mr-2"
            onClick={() => {
              addMFISubRoles()
            }}
          >
            {t("module.mfiSetup.userRoles.save")}
          </Button>
          <Button
            theme="secondary"
            className="thin delete"
            onClick={() => {
              closeModal()
            }}
          >
            {t("module.mfiSetup.userRoles.cancel")}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

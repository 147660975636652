/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { useTranslation } from 'react-i18next';

function Assumptions({
    scoreLogic, selected = [], selectOne, handler,
}) {
    const { uid, assumptions } = scoreLogic;
    const surveyAssumptions = assumptions.filter((item) => item.surveyUuid === uid);
    const { t } = useTranslation();

    const [state, setState] = useState(selected);
    const returnType = 'assumptions';

    useEffect(() => {
        handler(returnType, { [returnType]: state });
    }, [state]);

    const selectItem = (which) => {
        let newState = [...state];

        if (selectOne) {
            newState = [];
        }

        const index = newState.indexOf(which);

        if (index === -1) {
            if (selectOne) {
                newState = [which];
            } else {
                newState.push(which);
            }
        } else {
            newState.splice(index, 1);
        }

        setState(newState);
    };

    return (
        <div className="items-group mb-2 assumptions">
            <h4>{t('module.scoreLogic.view.formula.assumptions.title')}</h4>
            <div className="items-holder flex flex-wrap">
                {
                    surveyAssumptions.map((assumption) => {
                        const { name, code } = assumption;
                        const active = state && state.includes(code) && 'variable-item-active';

                        return (
                            <Tooltip key={code} placement="top" overlay={name} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                <div className={`item variable-item ${active}` } onClick={ () => { selectItem(code); } } key={code}>{ code }</div>
                            </Tooltip>
                        );
                    })
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { scoreLogic } = state;
    return {
        scoreLogic,
    };
};

const connectedAssumptions = connect(mapStateToProps)(Assumptions);
export { connectedAssumptions as Assumptions };

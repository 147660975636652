import React, { useState, useEffect } from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Button } from 'shards-react';

import Header from '../../../components/widget/widgetTitle';
import { modalActions } from '../../modals';
import { cslActions } from '..';
import { ComputationCodesSetup } from '../views/Formula/ComputationCodes';
import { Assumptions } from '../views/Formula/Assumptions';
import { OtherCalculations } from '../views/Formula/OtherCalculations';
import { Tdcv } from '../views/Formula/Tdcv';
import { CashFlowVariable } from '../component/CashFlowVariable';

const AddCashflowVariable = ({scoreLogic, modalID, data, dispatch}) => {
    const {
        types,
        codes,
        title,
        selectOne = {
            code: true,
            cashflowVariable: true,
            assumptions: true,
            tdcv: false,
            emcv: false,
            otherCalculations: true,
        },
    } = data;

    const [inputs, setInputs] = useState({});
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

    let selectedCodes = codes;

    if (isEmpty(codes)) {
        selectedCodes = [];
    }

    if (typeof codes === 'string') {
        selectedCodes = [codes];
    }

    useEffect(() => {
        setSubmitButtonDisabled(!shouldEnableResetButton(inputs));
    }, [inputs]);

    function shouldEnableResetButton(fields) {
        return !isEmpty(fields);
    }

    const childComponentHandler = (type, fields) => {
        setInputs((prevState) => ({ ...prevState, [type]: fields[type] }));
    };

    const addSelected = () => {
        dispatch(cslActions.updateCashflowStatementsVariables({ ...data, data: inputs }));
        closeModal();
    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };


    return (
        <div className="site-modal cashflow-statements__add-variable">
            <Header title={title && title} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal }/>

            <div className="w-full p-3">
                { (types.includes('code') || types.includes('code1') || types.includes('code2')) && <div className="flex">
                    <ComputationCodesSetup scoreLogic={ scoreLogic } modalID={modalID} content={data} handler={childComponentHandler} selected={selectedCodes} selectOne={selectOne.code}/>
                </div> }

                { types.includes('assumptions') && <div className="flex">
                    <Assumptions scoreLogic={ scoreLogic } modalID={modalID} content={data} handler={childComponentHandler} selected={selectedCodes} selectOne={selectOne.assumptions}/>
                </div> }

                { types.includes('tdcv') && <div className="flex">
                    <Tdcv type="TIME_DEPENDENT_CALCULATED_VARIABLE" scoreLogic={ scoreLogic } modalID={modalID} content={data} handler={childComponentHandler} selected={selectedCodes} selectOne={selectOne.tdcv}/>
                </div> }

                { types.includes('emcv') && <div className="flex">
                    <Tdcv type="EVERY_MONTH_CALCULATED_VARIABLE" scoreLogic={ scoreLogic } modalID={modalID} content={data} handler={childComponentHandler} selected={selectedCodes} selectOne={selectOne.emcv}/>
                </div> }

                { types.includes('otherCalculations') && <div className="flex">
                    <OtherCalculations type="SINGLE_CALCULATION" scoreLogic={ scoreLogic } modalID={modalID} content={data} handler={childComponentHandler} selected={selectedCodes} selectOne={selectOne.otherCalculations}/>
                </div> }

                { types.includes('cashflowVariable') && <div className="flex">
                    <CashFlowVariable type="CASHFLOW_VARIABLE" scoreLogic={ scoreLogic } modalID={modalID} content={data} handler={childComponentHandler} selected={selectedCodes} selectOne={selectOne.cashflowVariable}/>
                </div> }

                <div className="flex">
                    <Button theme="primary" disabled={submitButtonDisabled} className="ml-auto" onClick={ () => { addSelected(); } }>Add Selected</Button>
                    <Button theme="secondary" className="ml-2" onClick={ () => { closeModal(); } }>Cancel</Button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { scoreLogic, modals } = state;
    return { scoreLogic, modals };
};

const connectedAddCashflowVariable = connect(mapStateToProps)(AddCashflowVariable);
export { connectedAddCashflowVariable as AddCashflowVariable };

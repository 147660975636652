export const kobotoolkitConstants = {
    KOBO_AUTH_REQUEST: 'KOBO_AUTH_REQUEST',
    KOBO_AUTH_TOKEN: 'KOBO_AUTH_TOKEN',
    KOBO_AUTH_FAILURE: 'KOBO_AUTH_FAILURE',

    KOBO_ASSETS_REQUEST: 'KOBO_ASSETS_REQUEST',
    KOBO_ASSETS_SUCCESS: 'KOBO_ASSETS_SUCCESS',
    KOBO_ASSETS_FAILURE: 'KOBO_ASSETS_FAILURE',

    KOBO_SURVEY_REQUEST: 'KOBO_SURVEY_REQUEST',
    KOBO_SURVEY_SUCCESS: 'KOBO_SURVEY_SUCCESS',
    KOBO_SURVEY_FAILURE: 'KOBO_SURVEY_FAILURE',

    KOBO_GET_QUESTIONNAIRES_REQUEST: 'KOBO_GET_QUESTIONNAIRES_REQUEST',
    KOBO_GET_QUESTIONNAIRES_SUCCESS: 'KOBO_GET_QUESTIONNAIRES_SUCCESS',
    KOBO_GET_QUESTIONNAIRES_FAILURE: 'KOBO_GET_QUESTIONNAIRES_FAILURE',
};

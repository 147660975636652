export const clientConstants = {
    /** Clients */
    GETALL_CLIENT_REQUEST: 'GETALL_CLIENNT_REQUEST',
    GETALL_CLIENT_SUCCESS: 'GETALL_CLIENNT_SUCCESS',
    GETALL_CLIENT_FAILURE: 'GETALL_CLIENNT_FAILURE',

    // Overview page
    GET_CLIENT_OVERVIEW_REQUEST: 'GET_CLIENT_OVERVIEW_REQUEST',
    GET_CLIENT_OVERVIEW_SUCCESS: 'GET_CLIENT_OVERVIEW_SUCCESS',
    GET_CLIENT_OVERVIEW_FAILURE: 'GET_CLIENT_OVERVIEW_FAILURE',

    // overview graph
    GET_CLIENT_OVERVIEW_GRAPH_REQUEST: 'GET_CLIENT_OVERVIEW_GRAPH_REQUEST',
    GET_CLIENT_OVERVIEW_GRAPH_SUCCESS: 'GET_CLIENT_OVERVIEW_GRAPH_SUCCESS',
    GET_CLIENT_OVERVIEW_GRAPH_FAILURE: 'GET_CLIENT_OVERVIEW_GRAPH_FAILURE',

    // get single client details
    GET_CLIENT_PROFILE_REQUEST: 'GET_CLIENT_PROFILE_REQUEST',
    GET_CLIENT_PROFILE_SUCCESS: 'GET_CLIENT_PROFILE_SUCCESS',
    GET_CLIENT_PROFILE_FAILURE: 'GET_CLIENT_PROFILE_FAILURE',

    // single client - view notes
    GET_NOTES_REQUEST: 'GET_NOTES_REQUEST',
    GET_NOTES_SUCCESS: 'GET_NOTES_SUCCESS',
    GET_NOTES_FAILURE: 'GET_NOTES_FAILURE',

    // Create note
    CREATE_NOTE_REQUEST: 'CREATE_NOTE_REQUEST',
    CREATE_NOTE_SUCCESS: 'CREATE_NOTE_SUCCESS',
    CREATE_NOTE_FAILURE: 'CREATE_NOTE_FAILURE',

    // Send reminder
    SEND_REMINDER_REQUEST: 'SEND_REMINDER_REQUEST',
    SEND_REMINDER_SUCCESS: 'SEND_REMINDER_SUCCESS',
    SEND_REMINDER_FAILURE: 'SEND_REMINDER_FAILURE',

    // uploads
    CREATE_UPLOAD_REQUEST: 'CREATE_UPLOAD_REQUEST',
    CREATE_UPLOAD_SUCCESS: 'CREATE_UPLOAD_SUCCESS',
    CREATE_UPLOAD_FAILURE: 'CREATE_UPLOAD_FAILURE',

    // search client
    SEARCH_CLIENT_REQUEST: 'SEARCH_CLIENT_REQUEST',
    SEARCH_CLIENT_SUCCESS: 'SEARCH_CLIENT_SUCCESS',
    SEARCH_CLIENT_FAILURE: 'SEARCH_CLIENT_FAILURE',

    // Filter client
    FILTER_CLIENT_REQUEST: 'FILTER_CLIENT_REQUEST',
    FILTER_CLIENT_SUCCESS: 'FILTER_CLIENT_SUCCESS',
    FILTER_CLIENT_FAILURE: 'FILTER_CLIENT_FAILURE',


    // Client assignee
    GET_CLIENT_ASSIGNEES_REQUEST: 'GET_CLIENT_ASSIGNEES_REQUEST',
    GET_CLIENT_ASSIGNEES_SUCCESS: 'GET_CLIENT_ASSIGNEES_SUCCESS',
    GET_CLIENT_ASSIGNEES_FAILURE: 'GET_CLIENT_ASSIGNEES_FAILURE',

    // Client assignee
    ASSIGN_TO_REQUEST: 'ASSIGN_TO_REQUEST',
    ASSIGN_TO_SUCCESS: 'ASSIGN_TO_SUCCESS',
    ASSIGN_TO_FAILURE: 'ASSIGN_TO_FAILURE',

     // Client assignee
     ASSIGN_TO_ON_CLIENT_PROFILE_REQUEST: 'ASSIGN_TO_ON_CLIENT_PROFILE_REQUEST',
     ASSIGN_TO_ON_CLIENT_PROFILE_SUCCESS: 'ASSIGN_TO_ON_CLIENT_PROFILE_SUCCESS',
     ASSIGN_TO_ON_CLIENT_PROFILE_FAILURE: 'ASSIGN_TO_ON_CLIENT_PROFILE_FAILURE',

       // Client to assign back
    GET_CLIENT_TO_ASSIGN_BACK_REQUEST: 'GET_CLIENT_TO_ASSIGN_BACK_REQUEST',
    GET_CLIENT_TO_ASSIGN_BACK_SUCCESS: 'GET_CLIENT_TO_ASSIGN_BACK_SUCCESS',
    GET_CLIENT_TO_ASSIGN_BACK_FAILURE: 'GET_CLIENT_TO_ASSIGN_BACK_FAILURE',

    // Client Assign Back
    ASSIGN_BACK_REQUEST: 'ASSIGN_BACK_REQUEST',
    ASSIGN_BACK_SUCCESS: 'ASSIGN_BACK_SUCCESS',
    ASSIGN_BACK_FAILURE: 'ASSIGN_BACK_FAILURE',

    // BM Send Reminder
    BM_SEND_REMINDER_REQUEST: 'BM_SEND_REMINDER_REQUEST',
    BM_SEND_REMINDER_SUCCESS: 'BM_SEND_REMINDER_SUCCESS',
    BM_SEND_REMINDER_FAILURE: 'BM_SEND_REMINDER_FAILURE',
}

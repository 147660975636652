import { axiosInstance, handleResponse } from "../../_helpers/base.service"

export const applicationEditorService = {
  saveApplication,
  updateApplicationForm,
  updateApplication,
  updateMultipleApplication,
}

const serviceGroup = "workflow"

function saveApplication(data, mediaFiles) {
  const formData = new FormData()
  formData.append("jsonRequest", JSON.stringify(data))
  mediaFiles.forEach((element) => {
    formData.append("media_files", element.files)
  })
  return axiosInstance
    .post("/application-publisher-client/publisher-client/dashboard", formData)
    .then(handleResponse)
}

function updateApplicationForm(id, data) {
  return axiosInstance
    .patch(`${serviceGroup}/applications/application/${id}`, data)
    .then(handleResponse)
}

function updateApplication(data) {
  return axiosInstance
    .patch(
      `${serviceGroup}/applications/application/adjust-approval-loan-amount-tenor-and-recalculate`,
      data
    )
    .then(handleResponse)
}

function updateMultipleApplication(data) {
  return axiosInstance
    .patch(
      `${serviceGroup}/applications/application/adjust-approval-loan-tenor-and-recalculate`,
      data
    )
    .then(handleResponse)
}

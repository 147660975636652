import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formula } from '../Formula/index';

function SingleCalculations() {
  const { t } = useTranslation();
  useEffect(() => {
    // scroll page to top on displaying component
    const wrapper = document.getElementsByClassName("main-content");
    wrapper[0].scrollIntoView();
  }, []);

  return (<Formula type='SINGLE_CALCULATION' title={t('module.scoreLogic.view.formula.headerTitle')} />);
}
const connectedSingleCalculations = connect(null)(SingleCalculations);
export { connectedSingleCalculations as SingleCalculations };

import { Divider, ListItem, ListItemText, Typography } from '@material-ui/core';
import React from 'react';
import Visible from '../../../../assets/images/client/eye.svg';
import VisibleOff from '../../../../assets/images/client/eye-off.svg';
import NotificationReadStatusIcon from './notificationReadStatusIcon';

const NotificationItem = ({ row, index }) => {
    return (
        <>
            <ListItem alignItems="flex-start" style={{ display: 'block', backgroundColor: `${row.read ? '' : '#e2e8f0'}` }}>
                <ListItemText
                    secondary={
                        <React.Fragment>
                            <div style={{ display: 'block' }}>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    style={{
                                        color: '#002060',
                                    }}
                                >
                                    {row && row.message}
                                </Typography>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography
                                        component="p"
                                        variant="body2"
                                        style={{
                                            color: 'grey',
                                            fontWeight: 'light',
                                            fontSize: 'small',
                                            paddingTop: '5px',

                                        }}
                                    >
                                        {row && row.createdDate}
                                    </Typography>
                                    <Typography
                                        component="p"
                                        variant="body2"
                                        style={{
                                            display: 'flex',
                                            color: 'grey',
                                            fontWeight: 'light',
                                            fontSize: 'small',
                                            paddingTop: '5px',

                                        }}
                                    >
                                        {/* NOTE */}
                                        {/* TODO: Rewrite this logic when there's time */}
                                        {row?.read ? <NotificationReadStatusIcon img={Visible} status="Read notification" /> : <NotificationReadStatusIcon img={VisibleOff} status="Unread notification" />}
                                        {row?.read ? `read` : `unread`}
                                    </Typography>
                                </div>
                            </div>

                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider component="li" />
        </>
    )
}

export default NotificationItem;

/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Svg from "react-inlinesvg"
import { Typography, TextField, Grid } from "@material-ui/core"
import { ButtonGroup, Button } from "shards-react"
import Header from "../../../components/widget/widgetTitle"

import { modalActions } from "../../modals"
import { applicationActions } from "../_actions"

import "./PayDecline.scss"
import { useTranslation } from "react-i18next"
import { ErrorsView, LoadingView } from "../../../views"
const headerSVG = require("../../../assets/images/icons/header-icon.svg")

const AddVillageBank = ({ modalID, data }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { loading, error } = useSelector((state) => state.applications)
  const [villageBankName, setVillageBankName] = useState("")
  const [parentId, setParentId] = useState(null)

  useEffect(() => {
    if (data && data.parentId !== null && parentId === null) {
      setParentId(data.parentId)
    }
  }, [data])

  const handleSubmit = () => {
    const villageBankData = { name: villageBankName, parentId }
    dispatch(applicationActions.addVillageBank(villageBankData))
    closeModal()
    setTimeout(() => {
      window.location.reload()
    }, 2000)
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  return (
    <div className="site-modal">
      <Header
        title="Create New Village Bank"
        icon={<Svg className="text-center d-inline-block" src={headerSVG} />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        {loading && <LoadingView />}
        {error && <ErrorsView />}
        {!loading && !error && (
          <>
            <Grid container className="mb-1">
              <Grid item sm={3}>
                <Typography>Village Bank Name :</Typography>
              </Grid>
              <Grid item sm={7}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={villageBankName}
                  onChange={(e) => setVillageBankName(e.target.value)}
                />
              </Grid>
            </Grid>
          </>
        )}

        <ButtonGroup
          className="btn-group-actions site-modal__actions"
          style={{ marginTop: "20px" }}
        >
          <Button
            variant="contained"
            color="primary"
            className="thin mr-2"
            onClick={handleSubmit}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className="thin delete"
            onClick={closeModal}
          >
            {t("applicationDisburse.cancel")}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}
export default AddVillageBank

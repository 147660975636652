/* eslint array-callback-return: 0 */
import { settingsConstants } from '.';

const initialState = {
    navItems: [],
    menuVisible: true,
    currency: 'MMK',
};

export const settings = (state = initialState, action) => {
    switch (action.type) {
    case settingsConstants.NAV_ITEMS:
        return {
            ...state,
            navItems: action.data,
        };
    case settingsConstants.TOGGLE_SIDEBAR_DROPDOWN:
        let navItems = [...state.navItems];
        let navGroupIdx = null;
        let navItemIdx = null;

        navItems.map((navItem, _idx) => {
            const __idx = navItem.items.indexOf(action.data);
            if (__idx !== -1) {
                navGroupIdx = _idx;
                navItemIdx = __idx;
            }
        });

        navItems[navGroupIdx].items[navItemIdx].open = !navItems[
            navGroupIdx
        ].items[navItemIdx].open;

        navItems = navItems.map((i) => {
            i.items = i.items.map((_i, idx) => {
                if (idx !== navItemIdx) {
                    _i.open = false;
                }
                return _i;
            });
            return i;
        });

        return {
            ...state,
            navItems,
        };
    case settingsConstants.TOGGLE_SIDEBAR:
        return {
            ...state,
            menuVisible: !state.menuVisible,
        };
    case settingsConstants.CURRENCY:
        return {
            ...state,
            currency: action.data,
        };
    default:
        return state;
    }
};

import React from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { connect } from 'react-redux';
import {
    ButtonGroup,
    Button,
} from 'shards-react';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/widget/widgetTitle';
import { userManagerActions } from '..';
import { modalActions } from '../../modals/_actions';


const ActivateUser = ({ modalID, data, dispatch }) => {
    const activateUser = () => {
        dispatch(userManagerActions.activate(data));
        closeModal();
    };

    const { t } = useTranslation();

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal">
            <Header title={t('module.scoreLogic.modal.activateUser.headerTitle')} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal }/>
            <div className="p-10">
                <p className="label">{t('module.scoreLogic.modal.activateUser.description')}</p>
                <ButtonGroup className="btn-group-actions site-modal__actions">
                    <Button theme="primary" className="thin mr-2" onClick={ () => { activateUser(); } } >{t('module.scoreLogic.modal.activateUser.save')}</Button>
                    <Button theme="secondary" className="thin delete" onClick={ () => { closeModal(); } }>{t('module.scoreLogic.modal.activateUser.cancel')}</Button>
                </ButtonGroup>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { users, modals } = state;
    return { users, modals };
};

const connectedActivateUser = connect(mapStateToProps)(ActivateUser);
export { connectedActivateUser as ActivateUser };

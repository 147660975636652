export default () => ({
  headerText: {
    paddingLeft: "3rem",
    paddingTop: "20px",
    color: "#002060",
    fontSize: "22px",
    fontFamily: "Rubik",
    fontWeight: 600,
    textAlign: "center",
  },
  headerText1: {
    paddingLeft: "3rem",
    paddingTop: "20px",
    marginleft: "5px",
    color: "#002060",
    fontSize: "22px",
    fontFamily: "Rubik",
    fontWeight: 400,
    textAlign: "left",
    backgroundColor: "rgb(173, 216, 230)",
  },
  gridContainer: {
    marginBottom: 10,
    marginTop: 10,
  },
  gridPie: {
    maxWidth: "47.2%",
    flexBasis: "47.2%",
  },
  gridBar: {
    maxWidth: "94.4%",
    flexBasis: "94.4%",
  },
  paperContainer: {
    margin: 10,
    backgroundColor: "white",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important",
  },
  paperHeight: {
    height: "100%",
  },
  cashflowtitle: {
    color: "black",
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "30px",
  },
  cardcontainer: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  cardsup: {
    marginRight: "0.3em",
  },
  gridSVG: {
    display: "flex",
    justifyContent: "flex-end",
  },
  svgitem: {
    stroke: "grey",
    opacity: 0.5,
    width: "30%",
  },
  arrowred: {
    color: "red",
  },
  arrowgreen: {
    color: "#008d6a",
  },
  cashsubtitle1: {
    fontSize: "22px",
    color: "black",
    marginTop: 0,
    textAlign: "left",
    fontSize: "1.5em",
    fontWeight: "bold",
    "@media only screen and (max-width: 768px)": {
      fontSize: "1em",
    },
  },

  cardHeader: {
    borderTop: "5px solid #008d6a",
    paddingBottom: 8,
  },
  cardcontent: {
    paddingTop: 0,
    display: "flex",
    justifyContent: "space-between",
    width: "85%",
    height: "60%",
  },
  title1: {
    fontFamily: "Roboto, sans-serif",
    color: "black",
    margin: 0,
    fontSize: "18px",
  },
  cashCard: {
    boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)!important;",
    transition: "all 0.3s ease-in-out",
    borderBottomRightRadius: "30px",
    borderBottomLeftRadius: "30px",
    height: "122px",
    width: "270px",
    padding: 0,
    "&:hover": {
      boxShadow: "0px 5px 20px rgba(135, 206, 235, 0.8)!important;",
      transition: "all 0.3s ease-in-out",
      borderBottomRightRadius: "30px",
      borderBottomLeftRadius: "30px",
    },
  },
  cashtitle: {
    fontSize: "22px",
    color: "black",
    marginTop: 0,
  },
  smallChartContainer: {
    width: "10px",
    height: "10px",
  },
})

import React from 'react';
import { connect } from 'react-redux';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import Header from '../../../components/widget/widgetTitle';
import { ManageAssumption } from '../component/ManageAssumption';
import { modalActions } from '../../modals';

function EditAssumption({ scoreLogic, data, dispatch }) {
    const { id } = data;
    const { assumptions } = scoreLogic;
    const filtered = assumptions.filter((item) => item.id === id);
    const [savedData] = filtered;
    const { type } = savedData;

    const closeModal = () => {
        dispatch(modalActions.hideModal(1));
    };

    return (
        <div className="site-modal assumptions-edit">
            <div className="site-modal assumptions-type">
                <Header title={'Edit Assumption'} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal } />
                <ManageAssumption id={id} type={type} savedData={savedData}/>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { scoreLogic } = state;
    return { scoreLogic };
}

const connectedEditAssumption = connect(mapStateToProps)(EditAssumption);
export { connectedEditAssumption as EditAssumption };

import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDropzone } from 'react-dropzone';
import './uploads.scss';
import ClientDocumentPreview from '../previewModal';
import { clientActions } from '../../../../_actions';
import { LoadingView } from '../../../../../../views';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => createStyles({
    fileInput: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#f3f5f8',
        color: '#e0e7ec',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        cursor: 'pointer',
    },
    selectedFilesTitle: {
        margin: 0,
        fontSize: '1.263rem',
        fontWeight: 'bolder',
    }
}));

const DocumentUpload = ({ updatefetchClient, mfiId, loanId }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const client = useSelector((state) => state.clients);
    const { profile, loading } = client;
    const [submitFile, setSubmitFile] = useState({});
    const [submitted, setSubmitted] = useState(false)


    const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
        maxFiles: 100,
        multiple: true,
        onDrop: acceptedFiles => {
            setSubmitFile(acceptedFiles);
        },
    });

    const removeFile = (file) => () => {
        setSubmitFile(acceptedFiles.splice(acceptedFiles.indexOf(file), 1))
    }

    const removeAll = () => {
        acceptedFiles.length = 0
        setSubmitFile(acceptedFiles.splice(0, acceptedFiles.length))
        inputRef.current.value = ''
    }

    const handleSubmit = async () => {
        const formdata = new FormData();
        // eslint-disable-next-line array-callback-return
        await submitFile.map(file => {
            formdata.append("files", file);
        })
        dispatch(clientActions.createDocumentUpload(mfiId, loanId, formdata, updatefetchClient, removeAll));
        setSubmitted(true)
    }


    useEffect(() => {
        if (submitted && !loading) {
            dispatch(clientActions.getClientNotes(mfiId, loanId));
            setSubmitted(false);
        }
    }, [dispatch, submitted, loading, mfiId, loanId]);

    const files = acceptedFiles.map((file) => (
        <li style={{padding: '5px 0'}} key={file.path}>
            {file.path} - {file.size} {t('module.client.arrears.action.modal.profile.bytes')}
            <button className="remove_file" onClick={removeFile(file)}>{t('module.client.arrears.action.modal.profile.removeFile')}</button>
        </li>
    ));

    const classes = useStyles();

    return (
        <>
            {loading && <LoadingView />}
            <div {...getRootProps({ className: 'dropzone' })} className={classes.fileInput} >
                <input {...getInputProps()} />
                <p style={{ marginBlockStart: 0, marginBlockEnd: 0, textAlign: 'center' }}>
                    <CloudUploadIcon color={'#107d54'}></CloudUploadIcon> <br></br>
                    {t('module.client.arrears.action.modal.profile.uploadClientDocuments')}
                </p>
            </div>

            {/* preview */}
            {acceptedFiles?.length > 0 && (
                <aside>
                    <h4 className={classes.selectedFilesTitle}>{t('module.client.arrears.action.modal.profile.files')}</h4>
                    <ul>{files}</ul>
                    { files.length > 0 && (
                        <button className="remove_all" onClick={removeAll}>
                            {t('module.client.arrears.action.modal.profile.removeAll')}
                        </button>
                    )}
                </aside>
            )}

            <Button
                style={{
                    left: '88.5%',
                    backgroundColor: `${Object.keys(submitFile).length !== 0 ? '#008d6a' : '#c0c0c0'}`,
                    cursor: `${Object.keys(submitFile).length !== 0 ? 'pointer' : 'not-allowed'}`,
                    color: 'white',
                    padding: '4px 35px',
                    marginTop: '15px',
                    textTransform: 'capitalize',
                }}
                onClick={Object.keys(submitFile).length !== 0 ? handleSubmit : null}
            >
                {t('module.client.arrears.action.modal.profile.upload')}
            </Button>

            {/* view uploads/document checklist */}
            <div className="viewDocuments">
                {profile?.documentChecklist?.map((document) => (
                    <div className="viewDocuments__document" key={document?.id}>
                        {document?.filename}
                        <ClientDocumentPreview filename={document?.filename} documentUrl={document?.fileUrl}/>
                    </div>
                ))}
            </div>
        </>
    )
}

export default DocumentUpload;

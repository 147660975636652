import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Button, FormSelect, FormGroup, FormRadio,
} from 'shards-react';
import { filter } from 'lodash';
import {
    Input,
    FieldFeedbacks,
    FieldFeedback,
} from 'react-form-with-constraints-bootstrap4';
import { MatrixTable } from './Matrix/MatrixTable';
import { useTranslation } from 'react-i18next';

function MatrixAssumptionCodes({
    scoreLogic, inputs, setInputs, formRef: ref, saveAssumption,
}) {
    const { uid, computationCodes } = scoreLogic;
    const surveyComputationCodes = computationCodes.filter((item) => item.surveyUuid === uid);
    const [matrixComplete, setMatrixComplete] = useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const shouldEnableSubmitButton = () => {
            const formIsValid = ref.current.isValid();
            return (formIsValid && !ref.current.hasFeedbacks());
        };
        setSubmitButtonDisabled(!shouldEnableSubmitButton());
    }, [inputs, ref]);

    const handleMatrixChange = async ({ target, currentTarget }) => {
        const { name, value } = target;

        setInputs((prevState) => ({
            ...prevState,
            matrix: {
                ...prevState.matrix,
                [name]: value,
            },
        }));

        // eslint-disable-next-line no-unused-expressions
        currentTarget.tagName !== 'LABEL' && await ref.current.validateFields(currentTarget);
    };

    const handleGridState = (rowData) => {
        setInputs((prevState) => ({
            ...prevState,
            matrix: {
                ...prevState.matrix,
                grid: rowData,
            },
        }));
    };

    const getCodeLabel = (code) => {
        const [filtered] = filter(surveyComputationCodes, { questionCode: code });
        const questionCode = Object(filtered);
        return `${questionCode.questionLabel} (${code})`;
    };

    const setupTable = (complete) => {
        setMatrixComplete(complete);
    };

    return (
        <div className="mx-0">
            {
                !matrixComplete
                && <>
                <div className="flex">
                    <div className="group py-4 w-1/2 px-2">
                        <h3 className="section__title">{t('module.scoreLogic.component.assumptions.matrixAssumptionCodes.xValues')}</h3>

                        <FormGroup>
                            <div className="group">
                                <label className="w-1/2 pr-4">{t('module.scoreLogic.component.assumptions.matrixAssumptionCodes.computationQuestion')}</label>
                                <FormSelect
                                    required
                                    ref={() => ref}
                                    name="xComputationCode"
                                    value={inputs.matrix.xComputationCode}
                                    size="sm"
                                    className="mr-auto max-w-md"
                                    onChange={ handleMatrixChange }
                                >
                                    <option key={-1} value="">Select</option>
                                    {
                                        surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => {
                                            const { questionCode } = questionnaire;
                                            return <option key={idx} value={questionCode}>{ getCodeLabel(questionCode) }</option>;
                                        })
                                    }
                                </FormSelect>
                            </div>
                            <FieldFeedbacks for="xComputationCode">
                                <FieldFeedback when="*" />
                            </FieldFeedbacks>
                        </FormGroup>

                        <FormGroup className="flex">
                            <label className="w-1/2 pr-4">{t('module.scoreLogic.component.assumptions.matrixAssumptionCodes.code')}</label>
                            <label>{ inputs.matrix.xComputationCode }</label>
                        </FormGroup>

                        <FormGroup className="flex">
                            <div className="flex">
                                <label className="w-1/2 pr-4">{t('module.scoreLogic.component.assumptions.matrixAssumptionCodes.questionType')}</label>
                                <div className="block">
                                    <FormRadio name="xType" checked={inputs.matrix.xType === 'type'} value="type" onChange={ handleMatrixChange } >{t('module.scoreLogic.component.assumptions.matrixAssumptionCodes.optionType')}</FormRadio>
                                    <FormRadio name="xType" checked={inputs.matrix.xType === 'numeric'} value="numeric" onChange={ handleMatrixChange }>{t('module.scoreLogic.component.assumptions.matrixAssumptionCodes.numeric')}</FormRadio>
                                </div>
                            </div>
                        </FormGroup>

                        { inputs.matrix.xType === 'numeric'
                        && <>
                            <FormGroup>
                                <div className="flex">
                                    <label className="w-1/2 pr-4">{t('module.scoreLogic.component.assumptions.matrixAssumptionCodes.binWidth')}</label>
                                    <Input
                                        required
                                        ref={() => ref}
                                        name="xBinWidth"
                                        defaultValue={inputs.matrix.xBinWidth}
                                        size="sm"
                                        className="form-control mb-2"
                                        onChange={ handleMatrixChange }
                                        type="number"
                                    />
                                </div>
                                <FieldFeedbacks for="xBinWidth">
                                    <FieldFeedback when="*" />
                                </FieldFeedbacks>
                            </FormGroup>

                            <FormGroup>
                                <div className="flex">
                                    <label className="w-1/2 pr-4">{t('module.scoreLogic.component.assumptions.matrixAssumptionCodes.maxValue')}</label>
                                    <Input
                                        required
                                        ref={() => ref}
                                        name="xMaxValue"
                                        defaultValue={inputs.matrix.xMaxValue}
                                        size="sm"
                                        className="form-control mb-2"
                                        onChange={ handleMatrixChange }
                                        type="number"
                                    />
                                </div>
                                <FieldFeedbacks for="xMaxValue">
                                    <FieldFeedback when="*" />
                                </FieldFeedbacks>
                            </FormGroup>
                        </>
                        }

                    </div>

                    <div className="group py-4 w-1/2 px-2">
                        <h3 className="section__title">{t('module.scoreLogic.component.assumptions.matrixAssumptionCodes.yValues')}</h3>

                        <FormGroup>
                            <div className="group">
                                <label className="w-1/2 pr-4">{t('module.scoreLogic.component.assumptions.matrixAssumptionCodes.computationQuestion')}</label>
                                <FormSelect required ref={() => ref} name="yComputationCode" value={inputs.matrix.yComputationCode} size="sm" className="mr-auto max-w-md" onChange={ handleMatrixChange }>
                                    <option key={-1} value="">Select</option>
                                    {
                                        surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => {
                                            const { questionCode } = questionnaire;
                                            return <option key={idx} value={questionCode}>{ getCodeLabel(questionCode) }</option>;
                                        })
                                    }
                                </FormSelect>
                            </div>
                            <FieldFeedbacks for="yComputationCode">
                                <FieldFeedback when="*" />
                            </FieldFeedbacks>
                        </FormGroup>

                        <FormGroup className="flex">
                            <label className="w-1/2 pr-4">{t('module.scoreLogic.component.assumptions.matrixAssumptionCodes.code')}</label>
                            <label>{ inputs.matrix.yComputationCode }</label>
                        </FormGroup>

                        <FormGroup>
                            <div className="flex">
                                <label className="w-1/2 pr-4">{t('module.scoreLogic.component.assumptions.matrixAssumptionCodes.questionType')}</label>
                                <div className="block">
                                    <FormRadio name="yType" checked={inputs.matrix.yType === 'type'} value="type" onChange={ handleMatrixChange } >{t('module.scoreLogic.component.assumptions.matrixAssumptionCodes.optionType')}</FormRadio>
                                    <FormRadio name="yType" checked={inputs.matrix.yType === 'numeric'} value="numeric" onChange={ handleMatrixChange }>{t('module.scoreLogic.component.assumptions.matrixAssumptionCodes.numeric')}</FormRadio>
                                </div>
                            </div>
                        </FormGroup>

                        { inputs.matrix.yType === 'numeric'
                        && <>
                            <FormGroup>
                                <div className="flex">
                                    <label className="w-1/2 pr-4">{t('module.scoreLogic.component.assumptions.matrixAssumptionCodes.binWidth')}</label>
                                    <Input
                                        required
                                        ref={() => ref}
                                        name="yBinWidth"
                                        defaultValue={inputs.matrix.yBinWidth}
                                        size="sm"
                                        className="form-control mb-2"
                                        onChange={ handleMatrixChange }
                                        type="number"
                                    />
                                </div>
                                <FieldFeedbacks for="yBinWidth">
                                    <FieldFeedback when="*" />
                                </FieldFeedbacks>
                            </FormGroup>

                            <FormGroup>
                                <div className="flex">
                                    <label className="w-1/2 pr-4">{t('module.scoreLogic.component.assumptions.matrixAssumptionCodes.maxValue')}</label>
                                    <Input
                                        required
                                        ref={() => ref}
                                        name="yMaxValue"
                                        defaultValue={inputs.matrix.yMaxValue}
                                        size="sm"
                                        className="form-control mb-2"
                                        onChange={ handleMatrixChange }
                                        type="number"
                                    />
                                </div>
                                <FieldFeedbacks for="yMaxValue">
                                    <FieldFeedback when="*" />
                                </FieldFeedbacks>
                            </FormGroup>
                        </>
                        }

                    </div>
                </div>
                <Button disabled={ submitButtonDisabled } className="btn" onClick={ () => { setupTable(true); } }>{t('module.scoreLogic.component.wizardActions.continue')}</Button>
                </>
            }
            { matrixComplete
                && <>
                    <MatrixTable data={inputs} xTitle={ getCodeLabel(inputs.matrix.xComputationCode)} yTitle={ getCodeLabel(inputs.matrix.yComputationCode) } handleGridState={ handleGridState } />
                    <div className="fields flex">
                        <Button theme="primary" disabled={ submitButtonDisabled } className="btn" onClick={ () => { saveAssumption(false); } }>{t('module.scoreLogic.component.wizardActions.saveClose')}</Button>
                    </div>
                </>
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    const { scoreLogic } = state;
    return {
        scoreLogic,
    };
};

const connectedMatrixAssumptionCodes = connect(mapStateToProps)(MatrixAssumptionCodes);
export { connectedMatrixAssumptionCodes as MatrixAssumptionCodes };

import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, FormCheckbox } from "shards-react"
import Header from "../../../components/widget/widgetTitle"
import { useTranslation } from "react-i18next"
import { modalActions } from "../../modals/_actions"
import { applicationActions } from ".."
import Svg from "react-inlinesvg"

const toolsIcon = require("../../../assets/images/icons/tools-icon.svg")

const FILTER_LABELS = {
  fieldOfficer: "By Field Officer",
  cwsName: "By Branch",
}

export function PurchaseFilter({ modalID, data }) {
  const { purchaseActiveFilters } = useSelector((state) => state.applications)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [activeFilters, setActiveFilters] = useState(purchaseActiveFilters)

  const { filterData, selectedValue } = data

  const handleClick = (name) => {
    if (activeFilters.includes(name)) {
      const newActiveFilters = activeFilters.filter((filter) => filter !== name)
      setActiveFilters(newActiveFilters)
    } else {
      setActiveFilters([...activeFilters, name])
    }
  }

  const purchaseFilter = (reset) => {
    if (reset) {
      dispatch(applicationActions.filterPayments([]))
    } else {
      dispatch(applicationActions.filterPayments(activeFilters))
    }
    closeModal()
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  return (
    <div className="site-modal">
      <Header
        title={`Filter by ${FILTER_LABELS[selectedValue]}`}
        icon={<Svg className="text-center d-inline-block" src={toolsIcon} />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        {filterData && filterData.length > 0 ? (
          filterData.map((item, idx) => (
            <FormCheckbox
              key={idx}
              name="offices"
              checked={activeFilters.includes(item.toLowerCase().trim())}
              onChange={() => handleClick(item.toLowerCase().trim())}
            >
              {item}
            </FormCheckbox>
          ))
        ) : (
          <div>{t("module.applications.nothingToFilter")}.</div>
        )}
        <br />
        {filterData && filterData.length > 0 && (
          <div className="btn-group-actions site-modal__actions flex justify-end">
            <Button
              theme="light"
              className="mr-2"
              onClick={() => purchaseFilter(true)}
            >
              {t("module.applications.reset")}
            </Button>
            <Button
              theme="primary"
              className="delete mr-2"
              onClick={() => purchaseFilter()}
            >
              {t("module.applications.filter")}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

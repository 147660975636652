import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'shards-react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SetQuestionnaire } from '../../scoreLogic/views/SetQuestionnaire';
import { Wizard, WithWizard } from 'react-albus';
import { withTranslation } from 'react-i18next';
import { Button } from 'shards-react';
import { toolsActions } from '../';
import Header from '../../../components/widget/widgetTitle';
import PageTitle from '../../../components/common/PageTitle';
import Svg from 'react-inlinesvg';

import '../../config/views/Config.scss';
const toolsSync = require('../../../assets/images/icons/tools-sync-icon.svg');

const BulkInit = ({ scoreLogic, dispatch, t }) => {
    const { uid } = scoreLogic;
    const bulkInitApplicationsHandler = () => {
      if (!uid) { return; }
      dispatch(toolsActions.bulkInitApplications(uid));
    }

    return (
        <Container fluid className="main-content-container px-4">
            <CssBaseline />
            <Row noGutters className="page-header pt-4 pb-1">
                <PageTitle sm="9" title={t('module.tools.bulkInitialiase.title')} className="text-sm-left" />
            </Row>
            <Row noGutters className="page-header py-1">
                <Col md="12" lg="10" className="mx-auto mt-4 score-logic relative h-full">
                <Wizard render={ () => (
                  <>
                    <Header title={t('module.tools.bulkInitialiase.title')} icon={<Svg className="text-center d-inline-block" src={toolsSync} />} />
                    <div className={ `flex bg-white p-2 setup-questionnaire-complete` }>
                      <div className="content">
                          <SetQuestionnaire desc={t('module.tools.bulkInitialiase.titleSubDescription')} />
                      </div>
                    </div>
                    <WithWizard
                      render={() => (
                        <div className="wizard-actions">
                            <Button className="next" onClick={() => bulkInitApplicationsHandler()} disabled={ !uid && true }>
                              {t('module.tools.bulkInitialiase.initialise')}
                            </Button>
                        </div>)} />
                  </>
                    )} />
                </Col>
            </Row>
        </Container>
    );
};

function mapStateToProps(state) {
    const { scoreLogic } = state;
    return { scoreLogic };
}

const connectedSync = withTranslation()(connect(mapStateToProps)(BulkInit));
export { connectedSync as BulkInit };

import React, { useState, useEffect, useRef } from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { connect, useSelector } from 'react-redux';
import { FormGroup, Button } from 'shards-react';
import { isEmpty } from 'lodash';
import { FieldFeedbacks, FormWithConstraints } from 'react-form-with-constraints';
import { FieldFeedback, Input } from 'react-form-with-constraints-bootstrap4';
import { cslActions } from '..';
import { modalActions } from '../../modals';
import Header from '../../../components/widget/widgetTitle';
import { useTranslation } from 'react-i18next';

function EditScoringVariablesCondition({ modalID, data, dispatch }) {
    const {category, entryId, id, value, maxScore} = data;
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const form = useRef(null);
    const { t } = useTranslation();
    const { scoringVariables } = useSelector((state) => state.scoreLogic);

    const [fieldDataType, setFieldDataType] = useState();
    const [inputs, setInputs] = useState({ maxScore, value });

    useEffect(() => {
        const filterGroup = scoringVariables.filter((item) => item.title === data?.category);
        if(filterGroup) {
            const dataType = filterGroup[0]?.entries?.filter((item) => item.id === data?.entryId);
            setFieldDataType(dataType[0]?.dataType);
        }
    }, [scoringVariables, data]);

    useEffect(() => {
        function shouldEnableSubmitButton() {
            return inputs.maxScore && inputs.value;
        }
        setSubmitButtonDisabled(!shouldEnableSubmitButton());
    }, [inputs]);

    const handleChange = ({ target }) => {
        const { name, value: newValue } = target;
        setInputs((prevState) => ({ ...prevState, [name]: newValue }));
        form.current.validateFields(target);
    };

    const isValidData = () => {
        if (!isEmpty(inputs.maxScore) && !isEmpty(inputs.value) && !form.current.hasFeedbacks()) {
            return true;
        }
        return false;
    };

    const save = async () => {
        await form.current.validateForm();

        if (!isValidData() || !form.current.isValid()) {
            return;
        }

        dispatch(cslActions.editScoringVariablesCondition({
            ...inputs, id, category, entryId,
        }));
        closeModal();
    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal scoring-variables__add-variable">
            <Header title={t('module.scoreLogic.modal.editScoringVariablesCondition.headerTitle')} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal }/>
            <div className="w-full p-6">
                <FormWithConstraints ref={form} noValidate className="shadow-none">
                    <FormGroup>
                        <label htmlFor="threshold">{t('module.scoreLogic.modal.editScoringVariablesCondition.value')}</label>
                        <Input
                            className="form-control mb-2"
                            name="value"
                            defaultValue={inputs.value}
                            value={inputs.value}
                            onChange={handleChange}
                            type={fieldDataType === 'TEXT' ? 'text' : 'number' }
                            min='0'
                            required
                        />
                        <FieldFeedbacks for="value">
                            <FieldFeedback when="*" />
                        </FieldFeedbacks>
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="maxScore">{t('module.scoreLogic.modal.editScoringVariablesCondition.maximumPoints')}</label>
                        <Input
                            className="form-control mb-2"
                            name="maxScore"
                            defaultValue={inputs.maxScore}
                            onChange={handleChange}
                            type="text"
                            required
                        />
                        <FieldFeedbacks for="maxScore">
                            <FieldFeedback when="*" />
                        </FieldFeedbacks>
                    </FormGroup>
                </FormWithConstraints>
            </div>

            <div className="flex pb-6 pr-6">
                <Button theme="primary" disabled={submitButtonDisabled} className="ml-auto" onClick={ () => { save(); } }>{t('module.scoreLogic.modal.editScoringVariablesCondition.save')}</Button>
                <Button theme="secondary" className="ml-2" onClick={ () => { closeModal(); } }>{t('module.scoreLogic.modal.editScoringVariablesCondition.cancel')}</Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { scoreLogic, modals } = state;
    return { scoreLogic, modals };
};

const connectedEditScoringVariablesCondition = connect(mapStateToProps)(EditScoringVariablesCondition);
export { connectedEditScoringVariablesCondition as EditScoringVariablesCondition };

import React from 'react';
import { connect } from 'react-redux';
import { filter } from 'lodash';
import { Button } from 'shards-react';
import { useTranslation } from 'react-i18next';
import { modalActions } from '../../../modals';

// import { useTranslation } from 'react-i18next';
// const { t } = useTranslation();

function ListTDCV({
    id, type, scoreLogic, dispatch,
}) {
    const { formulas } = scoreLogic;
    const { t } = useTranslation();

    let filterConfig = { type };

    if (type === 'TIME_DEPENDENCY_FLAG') {
        filterConfig = { parentFormulaCode: id, type: 'TD_TO_TIME_DEPENDENCY_FLAG' };
    } else if (type === 'TIME_DEPENDENT_CALCULATED_VARIABLE') {
        filterConfig = { type: 'TIME_DEPENDENCY_FLAG' };
    }

    const timeDependentFlags = filter(formulas, filterConfig);

    // eslint-disable-next-line no-underscore-dangle
    const _delete = (itemId) => {
        dispatch(modalActions.deleteFormula({ itemId }));
    };

    return (
        <div className="formula-list items-group relative mb-2">
            <h4 className="mini-heading-top">{t('module.scoreLogic.view.formula.listTDCV.title')}</h4>
            {
                timeDependentFlags && timeDependentFlags.map((item) => {
                    const {
                        id: flagId, name, expression, code,
                    } = item;

                    return (
                        <div className="formula-list__item mx-6 pb-2" key={flagId}>
                            <div className="formula-list__item--detail"><span className="label">{t('module.scoreLogic.view.formula.addTDCV.code')}:</span> <span className="detail">{code}</span></div>
                            <div className="formula-list__item--detail"><span className="label">{t('module.scoreLogic.view.formula.addTDCV.description')}:</span> <span className="detail">{name}</span></div>
                            <div className="formula-list__item--detail"><span className="label">{t('module.scoreLogic.view.formula.addTDCV.formula')}:</span> <span className="detail">{expression}</span></div>
                            <div className="formula-list__item--actions flex">
                                <Button theme="cancel" className="ml-auto thin delete" onClick={ () => _delete(flagId) }>{t('module.scoreLogic.view.scoringVariables.delete')}</Button>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}


const mapStateToProps = (state) => {
    const { scoreLogic } = state;
    return {
        scoreLogic,
    };
};

const connectedListTDCV = connect(mapStateToProps)(ListTDCV);
export { connectedListTDCV as ListTDCV };

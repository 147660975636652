import React, { useRef, useState, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { Button, FormGroup } from "shards-react"
import {
  FormWithConstraints,
  Input,
} from "react-form-with-constraints-bootstrap4"
import { useTranslation } from "react-i18next"
import { modalActions } from "../../../modals"
import { configActions } from "../../"
import { alertActions } from "../../../alert"

function ReportSetup(props) {
  // get document checklist from store
  const { creditScoreReportSetup } = props.config
  const { t } = useTranslation()
  const { formulas, computationCodes, uid: surveyUuid } = props.scoreLogic
  const [inputs, setInputs] = useState({
    code: "",
    label: "",
  })
  const [edit, setEdit] = useState("")
  const [result, setResult] = useState(props.result || "")
  const dispatch = useDispatch()

  const form = useRef(null)
  const code = useRef(null)

  useEffect(() => {
    if (props.result.computationCode) {
      dispatch(
        configActions.addCreditScoreReportSetupState({
          computationCode: props.result.computationCode,
        })
      )
    } else if (props.result.singleCalculation) {
      dispatch(
        configActions.addCreditScoreReportSetupState({
          singleCalculation: props.result.singleCalculation,
        })
      )
    }
    setResult(props.result)
    // scroll to form
    if (props.result) {
      const form = document.getElementById("form-setup")
      form.scrollIntoView()
    }
  }, [props.result, dispatch])

  useEffect(() => {
    edit &&
      dispatch(configActions.addCreditScoreReportSetupState({ edit: edit }))
  }, [edit, dispatch])

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputs((prevState) => ({
      ...prevState,
      [name]: value.replace(".", "・"),
    }))
    dispatch(
      configActions.addCreditScoreReportSetupState({
        [name]: value.replace(".", "・"),
      })
    )
  }

  const validateInputs = (payload) => {
    let valid = true
    Object.keys(payload).forEach((item) => {
      if (!payload[item]) {
        dispatch(
          alertActions.error(`${item} ${t("alertActions.report.emptyField")}`)
        )
        valid = false
      }
    })
    return valid
  }

  const handleSave = (e) => {
    let payload = inputs
    if (result.computationCode) {
      const computationCodeCheck = computationCodes.filter(
        (computationCode) =>
          computationCode.questionCode === result.computationCode.toString()
      )
      payload["questionKeyOrFormulaCode"] = computationCodeCheck[0].questionKey
      payload["type"] = "QUESTION_KEY"
    } else if (result.singleCalculation) {
      payload["questionKeyOrFormulaCode"] = result.singleCalculation.toString()
      payload["type"] = "SINGLE_CALCULATION"
    }

    if (!validateInputs(payload)) {
      return
    }
    let validPayload = payload

    if (edit) {
      // delete validPayload.code;
      dispatch(configActions.editCreditScoreReportSetup(edit, validPayload))
      // scroll back to application column being edited
      const list = document.getElementById("setup" + edit)
      list.scrollIntoView()
    } else {
      validPayload["surveyUuid"] = surveyUuid
      dispatch(configActions.addCreditScoreReportSetup(validPayload))
    }
    reset()
  }

  const promptEdit = (item) => {
    // put clicked content on the form
    setResult("")
    setEdit(item.id)
    setInputs({
      code: item.code,
      label: item.label,
    })
    const computationCodeCheck = computationCodes.filter(
      (computationCode) =>
        computationCode.questionKey === item.questionKeyOrFormulaCode
    )
    const formulasCheck = formulas.filter(
      (formula) => formula.code === item.questionKeyOrFormulaCode
    )
    if (computationCodeCheck.length > 0) {
      setResult({
        computationCode: computationCodeCheck[0].questionCode,
      })
    } else if (formulasCheck.length > 0) {
      setResult({
        singleCalculation: item.questionKeyOrFormulaCode,
      })
    }
    // set result on the computationCodes component
    // send data to be edited to the state
    // dispatch(configActions.addCreditScoreReportSetupState({id: item.id}));
    // dispatch(configActions.addCreditScoreReportSetupState({surveyUuid: item.surveyUuid}));
    dispatch(configActions.addCreditScoreReportSetupState({ type: item.type }))
    // dispatch(configActions.addCreditScoreReportSetupState({code: item.code}));
    dispatch(
      configActions.addCreditScoreReportSetupState({ label: item.label })
    )
    dispatch(
      configActions.addCreditScoreReportSetupState({
        questionKeyOrFormulaCode: item.questionKeyOrFormulaCode,
      })
    )
    // scroll to form
    const form = document.getElementById("form-setup")
    form.scrollIntoView()
  }

  const promptDelete = (id) => {
    // prompt delete modal
    dispatch(modalActions.deleteCreditScoreReportSetup({ id }))
  }

  const reset = () => {
    setInputs({
      code: "",
      label: "",
    })
    setEdit("")
    // set result on the computationCodes component
    props.resetComputationCodeFn()
  }

  return (
    <div className="formula-list items-group relative mb-2">
      <h4 className="mini-heading-top">
        {t("module.applications.applicationColumns.columnSetup")}
      </h4>
      {creditScoreReportSetup &&
        creditScoreReportSetup.map((item, index) => (
          <div
            key={index}
            id={`setup${item.id}`}
            className="formula-list__item mx-6 pb-2"
          >
            <div className="formula-list__item--detail flex mb-4">
              <div className="label w-1/2">
                {t("module.applications.reports.labelCode")}:
              </div>{" "}
              <div className="detail w-1/2">{item.code}</div>
            </div>
            <div className="formula-list__item--detail flex mb-4">
              <div className="label w-1/2">
                {t("module.applications.reports.label")}:
              </div>{" "}
              <div className="detail w-1/2">{item.label}</div>
            </div>
            <div className="formula-list__item--detail flex mb-4">
              <div className="label w-1/2">
                {t("module.applications.reports.formulaComputationCode")}:
              </div>{" "}
              <div className="detail w-1/2">
                {item.questionKeyOrFormulaCode}
              </div>
            </div>
            <div className="flex document-setup_button-group">
              <Button
                onClick={(e) => promptEdit(item)}
                theme="secondary"
                className="thin mr-2 edit"
              >
                {t("module.applications.applicationColumns.clear")}
              </Button>
              <Button
                theme="cancel"
                className="ml-auto thin delete"
                onClick={() => promptDelete(item.id)}
              >
                {t("module.applications.applicationColumns.delete")}
              </Button>
            </div>
          </div>
        ))}

      <div id="form-setup" className="add-tdcv px-6">
        <FormWithConstraints ref={form} noValidate className="shadow-none">
          <FormGroup>
            <div className="flex">
              <label>{t("module.applications.reports.labelCode")} *</label>
              <Input
                type="text"
                innerRef={code}
                className="form-control"
                name="code"
                value={inputs.code}
                required
                onChange={handleChange}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="flex">
              <label>{t("module.applications.reports.label")} *</label>
              <Input
                type="text"
                className="form-control"
                name="label"
                value={inputs.label}
                required
                onChange={handleChange}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <label>
              {result.computationCode
                ? "Computation Code *"
                : t("module.applications.applicationColumns.formulaCode")}
            </label>
            <div className="items-holder flex flex-wrap">
              {result.computationCode && (
                <div className="variable-item item item-computationCode">
                  {result.computationCode}
                </div>
              )}
              {result.singleCalculation && (
                <div className="variable-item item item-computationCode">
                  {result.singleCalculation}
                </div>
              )}
            </div>
          </FormGroup>
          <FormGroup className="flex document-setup_button-group">
            <Button
              onClick={() => reset()}
              theme="cancel"
              className="thin delete"
            >
              {t("module.applications.applicationColumns.clear")}
            </Button>
            <Button
              onClick={(e) => handleSave(e)}
              theme="secondary"
              className="thin mr-2"
            >
              {t("module.applications.applicationColumns.save")}
            </Button>
          </FormGroup>
        </FormWithConstraints>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { config, scoreLogic } = state
  return {
    config,
    scoreLogic,
  }
}

const connectedReportSetup = connect(mapStateToProps)(ReportSetup)
export { connectedReportSetup as ReportSetup }

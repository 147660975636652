import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TooltipTrigger from 'react-popper-tooltip';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import { useTranslation } from "react-i18next";

import { AddQuestionnaireAction } from "./AddQuestionnaireAction";
import { modalActions } from "../../../modals";

export default function QuestionnairesTable({ mfiId }) {
  const { questionnaires: data, loading } = useSelector((store) => store.mfis);
  const [selectedItem, setSelectedItem] = useState(null)
  const dispatch = useDispatch();
  const { t } = useTranslation()

  const Trigger = ({ getTriggerProps, triggerRef }) => {
    return (
      <button
        {...getTriggerProps({
          ref: triggerRef,
          className: 'trigger btn questionnaire-actionButton',
        })}
      >
        <MoreHorizIcon />
      </button>
    );
  }

  const Tooltip = ({
    getTooltipProps,
    getArrowProps,
    tooltipRef,
    arrowRef,
    placement,
  }) => {
    return (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: 'tooltip-container table-actions',
        })}
      >
        <div
          {...getArrowProps({
            ref: arrowRef,
            'data-placement': placement,
            className: 'tooltip-arrow',
          })}
        />
        <div className="tooltip-body">
          <div className="dropdown dropdown-right">{items}</div>
        </div>
      </div>
    );
  }

  const items = (
    <div className="dropdown-item__questionnaire">
      <button
        onClick={() => handleEditQuestionnaire({ item: selectedItem, id: selectedItem.id })}>
        {t('module.mfiSetup.actions.edit')}
        </button>
      <br />
      <button onClick={() => deleteQuestionnaire(selectedItem.id)}>
        {t('module.mfiSetup.actions.delete')}
        </button>
      <br />
      <button onClick={() =>
        dispatch(
          modalActions.resetQuestionnaire({
            uuid: selectedItem.uuid,
            versionId: selectedItem.version,
          })
        )
      }>{t('module.mfiSetup.actions.reset')}</button><br />
      <a href={selectedItem && selectedItem.cloud_storage_url}>{t('module.mfiSetup.actions.download')}</a>
    </div>
  );

  const deleteQuestionnaire = (id) => {
    dispatch(modalActions.deleteQuestionnaire({ mfiId, id }));
  };

  const handleEditQuestionnaire = (item, id) => {
    dispatch(modalActions.editQuestionnaire(item, id));
  };

  return (
    <div className="table-questionnaires">
      {data.map((item) => (
        <div className="table-questionnaires__row">
          <div>{item.title}</div>
          <div>
            {item.active ? (
              <button
                onClick={() =>
                  dispatch(
                    modalActions.deactivateQuestionnaire({ mfiId, id: item.id })
                  )
                }
              >
                <ToggleOnIcon style={{ color: 'green' }} fontSize="large" />
              </button>
            ) : (
                <button
                  onClick={() =>
                    dispatch(
                      modalActions.activateQuestionnaire({ mfiId, id: item.id })
                    )
                  }
                >
                  <ToggleOffIcon style={{ color: 'grey' }} fontSize="large" />
                </button>
              )}
          </div>

          {items && <div onClick={() => setSelectedItem(item)}><TooltipTrigger placement="right" trigger="click" tooltip={Tooltip}>
            {Trigger}
          </TooltipTrigger></div>}
        </div>
      ))}

      {loading && <div>{t('component.exceptions.loading.title')}...</div>}

      <AddQuestionnaireAction mfiId={mfiId} />
    </div>
  );
}

export const workflowConstants = {
    WORKFLOW_REQUEST: 'WORKFLOW_REQUEST',
    WORKFLOW_SUCCESS: 'WORKFLOW_SUCCESS',
    WORKFLOW_FAILURE: 'WORKFLOW_FAILURE',

    WORKFLOW_ASSIGNEES_REQUEST: 'WORKFLOW_ASSIGNEES_REQUEST',
    WORKFLOW_ASSIGNEES_FORWARD_SUCCESS: 'WORKFLOW_ASSIGNEES_FORWARD_SUCCESS',
    WORKFLOW_ASSIGNEES_BACK_SUCCESS: 'WORKFLOW_ASSIGNEES_BACK_SUCCESS',
    WORKFLOW_ASSIGNEES_FAILURE: 'WORKFLOW_ASSIGNEES_FAILURE',

    DISBURSE_REFUSE_REQUEST: 'DISBURSE_REFUSE_REQUEST',
    DISBURSE_REFUSE_SUCCESS: 'DISBURSE_REFUSE_SUCCESS',
    DISBURSE_REFUSE_FAILURE: 'DISBURSE_REFUSE_FAILURE',
};

import React, { useState } from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { connect } from 'react-redux';
import {
    ButtonGroup,
    Button,
} from 'shards-react';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/widget/widgetTitle';
import { cslActions } from '..';
import { modalActions } from '../../modals';

import { ManageFormula } from '../component/ManageFormula';

import './AddFormula.scss';

const AddFormula = ({ modalID, dispatch }) => {
    const [state, setState] = useState({});
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const { t } = useTranslation();

    const saveVariable = () => {
        dispatch(cslActions.addFormula([state]));
        closeModal();
    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    const stateHandler = (newState) => {
        setState(newState);
        setSubmitButtonDisabled(false);
    };

    return (
        <div className="site-modal">
            <Header title={t('module.scoreLogic.modal.addFormula.headerTitle')} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal }/>
            <div className="p-10">
                <ManageFormula handler={ stateHandler } />
                <ButtonGroup className="btn-group-actions site-modal__actions">
                    <Button
                        theme="primary"
                        className="rounded-sm mr-2 btn-add-formula"
                        onClick={ () => { saveVariable(); } }
                        disabled={submitButtonDisabled}
                    >{t('module.scoreLogic.modal.addFormula.save')}</Button>
                    <Button
                        theme="secondary"
                        className="rounded-sm"
                        onClick={ () => { closeModal(); } }
                    >{t('module.scoreLogic.modal.addFormula.cancel')}</Button>
                </ButtonGroup>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { scoreLogic, modals } = state;
    return {
        scoreLogic,
        modals,
    };
};

const connectedAddFormula = connect(mapStateToProps)(AddFormula);
export { connectedAddFormula as AddFormula };

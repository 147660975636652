import axios from 'axios';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import qs from 'qs';
import { authenticationService } from '../modules/authentication';

let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [];

export function authHeader() {
    // return authorization header with jwt token
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.accessTokenString) {
        const { accessTokenString } = user;
        return {
            Authorization: `Bearer ${accessTokenString}`,
        };
    }
    return {};
}

export function localeHeader() {
    const locale = localStorage.getItem('i18nextLng');

    return {
        'Accept-Language': `${locale}`,
    };
}

export const requestDefaults = {
    baseURL: process.env.REACT_APP_API_BASE,
    headers: {
        'Content-Type': 'application/json',
        'Accept-Language': localStorage.getItem('i18nextLng'),
    },
    auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
    },
};

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
    headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        ...authHeader(),
        'Accept-Language': localStorage.getItem('i18nextLng'),
    },
});

export const clientInstance = axios.create({
    baseURL: process.env.REACT_APP_CLIENT_BASE,
    headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        ...authHeader(),
        'Accept-Language': localStorage.getItem('i18nextLng'),
    },
});

export const integrationsInstance = axios.create({
  baseURL: process.env.REACT_APP_API_INTEGRATIONS_BASE,
  headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      ...authHeader(),
      'Accept-Language': localStorage.getItem('i18nextLng'),
  },
});

export const axiosInstanceODK = axios.create({
    baseURL: process.env.REACT_APP_ODK_API_BASE,
    headers: {
      'Content-Type': 'application/json',
        accept: 'application/json',
        ...authHeader(),
        'Accept-Language': localStorage.getItem('i18nextLng'),
    },
});

/**
 * Fetch new access Token from remote using refresh_token
 */
function getAccessToken() {
    const user = JSON.parse(localStorage.getItem('user'));

    const args = {
        method: 'post',
        url: '/oauth/token',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
            'Accept-Language': localStorage.getItem('i18nextLng'),
        },
        data: qs.stringify({
            refresh_token: user.refresh_token,
            grant_type: 'refresh_token',
        }),
    };

    const requestConfig = {
        ...requestDefaults,
        ...args,
    };

    return axios(requestConfig).then((response) => response).catch((error) => error);
}

function isTokenExpiredError(error) {
    const { response } = error;

    if (!response) {
        return true;
    }

    const { status, data } = response;

    const user = JSON.parse(localStorage.getItem('user'));

    if (!user) {
        return false;
    }

    const { accessTokenString } = user;
    const accessToken = jwt_decode(accessTokenString);
    const currentUnixTime = new Date().getTime() / 1000;

    if (data.error === 'invalid_token' || status === 401 || currentUnixTime > accessToken.exp) {
        return true;
    }

    return false;
}

function addSubscriber(callback) {
    subscribers.push(callback);
}

/**
 * When the refresh is successful, we start retrying the requests one by one and empty the queue
 */
function onAccessTokenFetched(user) {
    const { accessTokenString } = user;
    subscribers.forEach((callback) => callback(accessTokenString));
    subscribers = [];
}

/**
 * Fetch new token and retry failed requests
 * @param {*} error
 */
function resetTokenAndReattemptRequest(error) {
    try {
        const originalRequest = error.config;

        /* Proceed to the token refresh procedure
        We create a new Promise that will retry the request,
        clone all the request configuration from the failed
        request in the error object. */
        const retryOriginalRequest = new Promise((resolve) => {
        /* We need to add the request retry to the queue
            since there another request that already attempt to
            refresh the token */
            addSubscriber((accessTokenString) => {
                axiosInstance.defaults.headers.Authorization = `Bearer ${accessTokenString}`;
                axiosInstanceODK.defaults.headers.Authorization = `Bearer ${accessTokenString}`;
                originalRequest.headers.Authorization = `Bearer ${accessTokenString}`;
                resolve(axios(originalRequest));
            });
        });

        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;

            getAccessToken().then((payload) => {
                if (payload.status === 200) {
                    const {
                        access_token: accessToken,
                        refresh_token: refreshToken,
                    } = payload.data;

                    // Decode token to get user data
                    const user = jwt_decode(accessToken);

                    user.accessTokenString = accessToken;
                    user.refresh_token = refreshToken;

                    return user;
                }
                if (payload.response && (payload.response.status === 401 || payload.response.status === 400)) {
                // auto logout if 401 response returned from api
                    authenticationService.logout();
                    window.location.reload(true);
                }
                const tokenError = payload.message || payload.statusText;
                return Promise.reject(tokenError);
            }).then((user) => {
                isAlreadyFetchingAccessToken = false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                onAccessTokenFetched(user);
                return user;
            });
        }

        return retryOriginalRequest;
    } catch (err) {
        return Promise.reject(err);
    }
}

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (isTokenExpiredError(error)) {
            return resetTokenAndReattemptRequest(error);
        }
        return Promise.reject(error);
    },
);


axiosInstanceODK.interceptors.response.use(
    (response) => response,
    (error) => {
        if (isTokenExpiredError(error)) {
            return resetTokenAndReattemptRequest(error);
        }
        return Promise.reject(error);
    },
);

export function handleResponse(res) {
    if (res.status === 200 || res.status === 201) {
        const { data } = res.data;
        return data;
    }
    const { response } = res;
    const { data } = response;
    const { message } = data;
    return Promise.reject(message);
}

export function handleResponseError(error) {
    const { response } = error;

    if (!response) {
        return error.toString();
    }

    const { data } = response;

    if (!data) {
        return error.toString();
    }

    const { message } = data;

    if (!message) {
        return error.toString();
    }

    return message;
}

import React from 'react';
import { Container, Row, Col } from 'shards-react';

import { ForgotPassword as ForgotPasswordComponent } from '../index';
import { Branding } from '../../../components/branding';

export const ForgotPasswordView = () => (
    <Container fluid className="main-content-container px-4 pb-4">
        <Row>
            <Col lg="12" md="12">
            <div className="login-page">
            <Branding />
            <ForgotPasswordComponent />
              </div>

            </Col>
        </Row>
    </Container>
);

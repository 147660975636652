import React from 'react';
import { Container, Button } from 'shards-react';
import { withTranslation } from 'react-i18next';

const NotFoundView = ( { t, i18n } ) => (
    <Container fluid className="main-content-container px-4 pb-4">
        <div className="error">
            <div className="error__content">
                <h2>{t("view.notFound.code")}</h2>
                <h3>{t("view.notFound.title")}</h3>
                <p>{t("view.notFound.text")}</p>
                <Button pill>&larr; {t("view.notFound.button")}</Button>
            </div>
        </div>
    </Container>
);

const translatedNotFoundView = withTranslation()(NotFoundView);
export { translatedNotFoundView as NotFoundView };

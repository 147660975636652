/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from 'react';
import { FormSelect, FormGroup } from 'shards-react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { first } from 'lodash';
import { cslActions } from '../../../_actions';

export const ApplicationProfile = ({uid, surveyComputationCodes, scoreCardProfile = [], getCodeLabel}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [updatedApplicantName, setUpdatedApplicantName] = useState();
    const [updatedLoanAmount, setUpdatedLoanAmount] = useState();
    const defaultLoanAmountState = {
        title: 'Loan Amount',
        type: 'LOAN_AMOUNT',
        computationCode: '',
    };

    const defaultApplicantNameState = {
        title: 'Applicant Name',
        type: 'APPLICANT_NAME',
        computationCode: '',
    };

    const [loanAmountState, setLoanAmountState] = useState(defaultLoanAmountState);
    const [applicantNameState, setApplicantNameState] = useState(defaultApplicantNameState);

    useEffect(() => {
        if (!loaded) {
            const loanAmount = first(scoreCardProfile.filter((item) => {
                const { type, surveyUuid } = item;
                return type === 'LOAN_AMOUNT' && surveyUuid === uid;
            }));

            setLoanAmountState((prevState) => ({ ...prevState, ...loanAmount }));

            const applicantName = first(scoreCardProfile.filter((item) => {
                const { type, surveyUuid } = item;
                return type === 'APPLICANT_NAME' && surveyUuid === uid;
            }));
            setApplicantNameState((prevState) => ({ ...prevState, ...applicantName }));
            setLoaded(true);
        }
    }, [scoreCardProfile, uid]);

    useEffect(() => {
        applicantNameState.id && dispatch(cslActions.editScoreCardProfileItem(applicantNameState.id, applicantNameState));
        !applicantNameState.id && applicantNameState.computationCode && dispatch(cslActions.addScoreCardProfileItem([applicantNameState]));
    }, [applicantNameState]);

    useEffect(() => {
        loanAmountState.id && dispatch(cslActions.editScoreCardProfileItem(loanAmountState.id, loanAmountState));
        !loanAmountState.id && loanAmountState.computationCode && dispatch(cslActions.addScoreCardProfileItem([loanAmountState]));
    }, [loanAmountState]);

    const handleApplicantNameChange = () => (evt) => {
        const { value } = evt.target;
        const ComputationCodes = first(surveyComputationCodes.filter(({ questionCode }) => questionCode === value));
        setApplicantNameState({
            ...applicantNameState,
            computationCode: value,
            questionKey: ComputationCodes.questionKey,
            surveyUuid: uid,
        });
        setUpdatedApplicantName(!updatedApplicantName);
    };

    const handleLoanAmountChange = () => (evt) => {
        const { value } = evt.target;
        const ComputationCodes = first(surveyComputationCodes.filter(({ questionCode }) => questionCode === value));
        setLoanAmountState({
            ...loanAmountState,
            computationCode: value,
            questionKey: ComputationCodes.questionKey,
            surveyUuid: uid,
        });
        setUpdatedLoanAmount(!updatedLoanAmount);
    };

    return (
        <div className="flex">
            <div className="group p-4 w-1/2">
                <h4 className="mini-heading-top">{t('module.applications.applicationProfile.applicantName')}</h4>
                <FormGroup className="flex">
                    <label className="pr-4">{t('module.applications.applicationProfile.question')}</label>
                    <FormSelect name="computationCodeId" value={applicantNameState.computationCode} size="sm" className="mr-auto max-w-md" onChange={handleApplicantNameChange()}>
                        <option key={-1} value="">{t('module.applications.applicationProfile.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => {
                                const { questionCode } = questionnaire;
                                return <option key={idx} value={questionCode}>{getCodeLabel(questionCode)}</option>;
                            })
                        }
                    </FormSelect>
                </FormGroup>

                <FormGroup className="flex">
                    <label className="pr-4" style={{ width: '138px' }}>{t('module.applications.applicationProfile.computationCode')}</label>
                    <div className="projection-expression">{applicantNameState.computationCode}</div>
                </FormGroup>
            </div>

            <div className="group p-4 w-1/2">
                <h4 className="mini-heading-top">{t('module.applications.applicationProfile.loanAmount')}</h4>
                <FormGroup className="group flex">
                    <label className="pr-4">{t('module.applications.applicationProfile.question')}</label>
                    <FormSelect name="computationCodeId" value={loanAmountState.computationCode} size="sm" className="mr-auto max-w-md" onChange={handleLoanAmountChange()}>
                        <option key={-1} value="">{t('module.applications.applicationProfile.selectQuestion')}</option>
                        {
                            surveyComputationCodes && surveyComputationCodes.map((questionnaire, idx) => {
                                const { questionCode } = questionnaire;
                                return <option key={idx} value={questionCode}>{getCodeLabel(questionCode)}</option>;
                            })
                        }
                    </FormSelect>
                </FormGroup>

                <FormGroup className="flex">
                    <label className="pr-4" style={{ width: '138px' }}>{t('module.applications.applicationProfile.computationCode')}</label>
                    <div className="projection-expression">{loanAmountState.computationCode}</div>
                </FormGroup>
            </div>
        </div>
    );
};

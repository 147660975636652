/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import Svg from "react-inlinesvg"
import { Typography } from "@material-ui/core"
import { ButtonGroup, Button } from "shards-react"
import Header from "../../../components/widget/widgetTitle"

import { modalActions } from "../../modals"

import "./PayDecline.scss"
import { useTranslation } from "react-i18next"
import { applicationActions } from "../_actions"
const headerSVG = require("../../../assets/images/icons/header-icon.svg")

const ConfirmMobileUpdate = ({ modalID, data }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { mobileNo, farmerCode, id } = data

  const handeSubmit = () => {
    const payload = {
      paymentId: id.toString(),
      farmerCode,
    }
    dispatch(applicationActions.updateMobileNo(payload))
    closeModal()
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  return (
    <div className="site-modal">
      <Header
        title="Mobile Update Confirmation"
        icon={<Svg className="text-center d-inline-block" src={headerSVG} />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        <Typography variant="body2" paragraph>
          Are you sure you want to update mobile no:{" "}
          <span className="font-semibold">{mobileNo}</span>?
        </Typography>
        <ButtonGroup className="btn-group-actions site-modal__actions">
          <Button
            theme="primary"
            className="thin mr-2"
            onClick={() => {
              handeSubmit()
            }}
          >
            Confirm
          </Button>
          <Button
            theme="cancel"
            className="thin delete"
            onClick={() => {
              closeModal()
            }}
          >
            {t("applicationDisburse.cancel")}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default ConfirmMobileUpdate

import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Button from "@material-ui/core/Button"
import { Typography,Radio, RadioGroup,FormControlLabel } from "@material-ui/core"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import PersonOutlineOutlinedIcon from "@material-ui/icons/LockOutlined"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Header from "../../../components/widget/widgetTitle"
import { useTranslation } from "react-i18next"
import { authenticationActions } from ".."
import Svg from "react-inlinesvg"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { useHistory } from "react-router-dom"

const successSVG = require("../../../assets/images/icons/success-anime-icon.svg")

const REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
const REGEX1 = /^\+?[1-9]\d+$/



const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#f1f8ff",
    },
  },
  container: {
    height: "calc(100vh - 46px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    backgroundColor: "var(--primary)",
    "& *": {
      color: "#fff",
    },
  },
  title: {
    padding: "35px",
    paddingBottom: "0",
    fontSize: "17px",
  },
  paper: {
    width: "400px",
    padding: 0,
    backgroundColor: theme.palette.common.white,
    borderRadius: "4px",
    boxShadow: "0 30px 50px 0 rgba(119, 151, 178, 0.2)",
    border: "solid 1px #cee0e9",
    color: "#002060",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "transparent",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    padding: "35px",
    paddingTop: "10px",
  },
  submit: {
    backgroundColor: "var(--primary)",
    margin: theme.spacing(3, 0, 2),
  },
  notificationText: {
    color: "#008d6a",
  },
  success:{
    display: 'flex',
    justifyContent:'center',
  },
  textsuccess:{
    display: 'flex',
    textAlign:'center',
  }
}))

export function ForgotPassword() {
  const resetEmailSuccess = useSelector(
    (state) => state.authentication.resetEmailSuccess
  )

  const resetSmsSuccess = useSelector(
    (state) => state.authentication.resetSmsSuccess
  )

  const classes = useStyles()
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [error, setError] = useState(false)
  const [error1, setError1] = useState(false)
  const [resetMethod, setResetMethod] = useState("email");

  const dispatch = useDispatch()
  const history = useHistory()

  function handleUserNameChange(e) {
    const { value } = e.target
    const error = value.match(REGEX)
    setEmail(value)
    setPhone()
    setError(!error)
  }

  function handlePhoneChange(value) {
    const error1 = value.match(REGEX1)

    setPhone(value)
    setEmail()
    setError1(!error1)
  }

    function handleSubmit(e) {
    e.preventDefault()
    if (error) return
    const host = window.location.origin
    const data = {
      phone,
      host,

    }
    dispatch(authenticationActions.forgotPasswordSMS(data))

  }

  function handleSubmit(e) {
    e.preventDefault();
    if (error) return;

    const data = {
      ...(resetMethod === 'email' && { email }),
      ...(resetMethod === 'sms' && { telephone: `+${phone}` }),
      ...(resetMethod === 'sms' && { phone: `+${phone}` }),
      ...(resetMethod === 'sms' && { host: window.location.origin }),
      ...(resetMethod === 'email' && { host: window.location.origin }),
    };

    const action = resetMethod === 'email'
      ? authenticationActions.forgotPassword
      : authenticationActions.forgotPasswordSMS;

    dispatch(action(data));
  }

  function redirect(e) {
    history.push('/reset-password/sms')
  }

  const { t } = useTranslation()

   return (
    <Container className={classes.container} component="main" maxWidth="xs">
      <CssBaseline />
      {resetSmsSuccess && redirect()}
      <div className={classes.paper}>
        <Header
          title={t("module.authentication.component.forgotPassword.headerTitle")}
          icon={<PersonOutlineOutlinedIcon />}
        />

        {resetEmailSuccess ? (
          <Typography className={`p-8 pb-0 ${classes.notificationText}`}>
            <div className={classes.success}>
              <Svg src={successSVG} />
            </div>
            <div className={classes.textsuccess}>
              An email with a link to reset your password has been sent to the
              email provided.
            </div>
          </Typography>
        ) : (
          <div>
        <Typography className="p-8 pb-0 text-black">
          Please select your password reset method.
        </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>

            <RadioGroup
              aria-label="contact-method"
              name="contact-method"
              value={resetMethod}
              onChange={(event) => setResetMethod(event.target.value)}
            >
              <FormControlLabel value="email" control={<Radio />} label="Email" />
              <FormControlLabel value="sms" control={<Radio />} label="SMS" />
            </RadioGroup>
            {resetMethod === "email" && (
              <>
                <Typography>Please enter your email address.</Typography>
                <TextField
                  error={error}
                  type="email"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoFocus
                  value={email}
                  onChange={handleUserNameChange}
                  helperText={error ? "Enter a valid email address" : ""}
                  disabled={resetEmailSuccess}
                />
              </>
            )}
            {resetMethod === "sms" && (
              <>
              <Typography>Please enter your phone number.</Typography>
              <br/>
              <PhoneInput
                specialLabel="Phone *"
                country="ke"
                value={phone}
                onChange={handlePhoneChange}
                placeholder="__ __ __ __ __ __ __"
                placeholderTextColor="green"
                inputStyle={{
                  borderColor: error1 ? "red" : "#008d6a",
                  borderWidth: "1.7px",
                  width: "100%",
                  height: "200%",
                }}
                containerStyle={{ color: "#008d6a" }}

              />
              </>
            )}
            {error1 && (
              <span style={{ color: "red" }}>Invalid phone number</span>
            )}
            <Grid container direction="row" justify="flex-end">
              <Grid item xs={4}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={!email && !phone || error || resetEmailSuccess}
                >
                  {t("module.authentication.component.forgotPassword.submit")}
                </Button>
              </Grid>
            </Grid>
          </form>
         </div>
        )}
        <br />
      </div>
    </Container>
  );

}

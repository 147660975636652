import React, { useState } from "react"
import { includes } from "lodash"
import { ComputationCodesSetup } from "./ComputationCodes"
import { Assumptions } from "./Assumptions"
import { Build } from "./Build"
import { Tdcv } from "./Tdcv"
import { AddTDCV } from "./AddTDCV"
import { ListTDCV } from "./ListTDCV"

export function Formula({ id, type, title }) {
  const [result, setResult] = useState([])
  const [error, setError] = useState(false)
  const [expression, setExpression] = useState([])
  const [selection, setSelection] = useState({
    computationCode: "",
    assumption: "",
    tdcv: "",
  })

  const operators = ["-", "+", "*", "/", "(", ")"]

  const handleExpression = (data) => {
    setExpression(data)
  }

  const handleSelection = (selectionType, data) => {
    // eslint-disable-next-line no-unused-expressions
    data[selectionType] &&
      setSelection((prevState) => ({
        ...prevState,
        [selectionType]: data[selectionType],
      }))
    // eslint-disable-next-line no-unused-expressions
    data[selectionType] && setResult([...result, ...data[selectionType]])
  }

  const handleCopyFormula = (formula) => {
    const resultArray = []
    let formulaCode = ""
    formula.split("").forEach((c) => {
      if (!operators.includes(c)) {
        formulaCode += c
      } else {
        if (formulaCode) resultArray.push(formulaCode)
        resultArray.push(c)
        formulaCode = ""
      }
    })
    if (formulaCode) resultArray.push(formulaCode)
    setResult(resultArray)
  }

  const resultHandler = () => {
    reset()
  }

  /**
   * Run actions based on button click
   *
   * @param {*} button Type of button clicked in the calculator
   */
  const onClick = (button) => {
    switch (button) {
      case "=":
        calculate()
        break
      case "C":
        reset()
        break
      case "CE":
        backspace()
        break
      default:
        // eslint-disable-next-line no-unused-expressions
        result && setResult([...result, button])
        break
    }
  }

  /**
   * Combine and validate variables and operators
   * Then set Expression State
   */
  const calculate = () => {
    try {
      const index = result.indexOf("--")
      // eslint-disable-next-line no-bitwise
      if (~index) {
        result[index] = "+"
      }

      validateCalculation()

      setError(false)
      setExpression(result)
    } catch (e) {
      setResult([])
      setError(true)
    }
  }

  /**
   * Replace Codes with numerics and validate calculation
   *
   * Scan for items not in the operators array, replace found items with a numeric value eg 5, then run a mock
   * calculation
   */
  const validateCalculation = () => {
    const checkResult = result

    let operationString = ""

    checkResult.map((item) => {
      const value = !includes(operators, item) ? Number(5) : item
      operationString += value
      return value
    })

    /* eslint no-eval: 0 */
    return eval(operationString)
  }

  const reset = () => {
    setResult([])
    setError(false)
  }

  const backspace = () => {
    // eslint-disable-next-line no-unused-expressions
    result && setResult(result.slice(0, -1))
    setError(false)
  }

  return (
    <div className="formula p-4">
      <h4 className="mini-heading-top">{title}</h4>
      <div className="flex">
        <div className="w-1/2 pr-2">
          <ComputationCodesSetup
            handler={handleSelection}
            selected={result}
            selectOne={true}
          />
          <Assumptions
            handler={handleSelection}
            selected={result}
            selectOne={true}
          />
          <Tdcv
            id={id}
            type={type}
            handler={handleSelection}
            selected={result}
            selectOne={true}
          />
          <Build
            id={id}
            expressionHandler={handleExpression}
            handler={onClick}
            selection={selection}
            result={result}
            handleCopyFormula={handleCopyFormula}
            resultHandler={resultHandler}
          />
        </div>
        <div className="w-1/2 pl-2">
          <ListTDCV
            id={id}
            type={type}
            resultHandler={resultHandler}
            resultStatus={error}
          />
          <AddTDCV
            id={id}
            expression={expression}
            type={type}
            name={title}
            selection={selection}
            resultHandler={resultHandler}
            resultStatus={error}
          />
        </div>
      </div>
    </div>
  )
}

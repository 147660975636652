import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Button } from "shards-react"
import { useTranslation } from "react-i18next"
import { cslActions } from "../.."

import "./ScoreLogicTest.scss"

function ScoreLogicTest({ scoreLogic, dispatch }) {
  const { uid, recalculate } = scoreLogic
  const { t } = useTranslation()

  useEffect(() => {}, [])

  const handleTest = () => {
    dispatch(cslActions.testScoreLogic(uid))
  }

  const handleRecalculate = () => {
    dispatch(cslActions.recalculateScoreLogic(uid))
  }

  return (
    <div className="scoring-variables shadow-none p-6">
      <div className="ml-auto">
        <p>{t("module.scoreLogic.view.scoreLogic.scoreLogicTest.subTitle")}</p>
        <Button theme="primary" className="btn" onClick={() => handleTest()}>
          {t("module.scoreLogic.view.scoreLogic.scoreLogicTest.testScoreLogic")}
        </Button>
        &nbsp;&nbsp;
        <Button
          theme="secondary"
          className="btn"
          onClick={() => handleRecalculate()}
          disabled={recalculate}
          style={{ marginTop: "10px" }}
        >
          {t("module.scoreLogic.view.scoreLogic.scoreLogicTest.recalculate")}
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { scoreLogic } = state
  return { scoreLogic }
}

const connectedScoreLogicTest = connect(mapStateToProps)(ScoreLogicTest)
export { connectedScoreLogicTest as ScoreLogicTest }

export default () => ({
  tableRoot: {
    padding: "2.5em",
  },
  tableInput: {
    width: "50%",
  },
  btnWrapper: {
    width: "50%",
    display: "flex",
    justifyContent: "end",
  },
  inputRoot: {
    fontSize: "15px",
  },
  cellRoot: {
    fontSize: "15px",
  },
  tableHeader: {
    backgroundColor: "#ffffff !important",
    backgroundImage: "none !important",
    boxShadow: "none !important",
  },
  submitBtn: {
    padding: "6px 12px",
    fontSize: "0.75rem",
  },
})

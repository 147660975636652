import React from 'react';
import classNames from 'classnames';
import Svg from 'react-inlinesvg';
import { Nav } from 'shards-react';

const smartIconSvg = require('../../../assets/images/icons/xs-stencil.svg');

function MiniSidebar() {
    const classes = classNames(
        'mini-sidebar',
        'px-0',
        'font-sans',
        'pt-3',
        'pb-3',
        'flex',
        'flex-col',
    );

    return (
        <div
            tag="aside"
            className={classes}
        >
            <Nav navbar className="flex-column p-2">
                <Svg className="text-center d-inline-block item-icon-wrapper" src={smartIconSvg} />
            </Nav>
        </div>
    );
}


export default MiniSidebar;

import React, { useState } from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { connect } from 'react-redux';
import { ButtonGroup, Button } from 'shards-react';
import { useTranslation } from 'react-i18next';

import Header from '../../../components/widget/widgetTitle';
import { cslActions } from '..';
import { modalActions } from '../../modals';

import { ManageFormula } from '../component/ManageFormula';

const EditFormula = ({ modalID, data, dispatch }) => {
    const { id } = data;
    const [state, setState] = useState({});
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const { t } = useTranslation();

    const saveVariable = () => {
        dispatch(cslActions.editFormula(id, state));
        closeModal();
    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    const inputsHandler = (newState) => {
        setState(newState);
        setSubmitButtonDisabled(false);
    };

    return (
        <div className="site-modal">
            <Header title={t('module.scoreLogic.component.timeDependent.editModal.title')} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal }/>

            <div className="p-10">
                <ManageFormula id={id} handler={ inputsHandler } />
                <ButtonGroup className="btn-group-actions site-modal__actions">
                    <Button theme="primary" className="rounded-sm mr-2" onClick={ () => { saveVariable(); } } disabled={submitButtonDisabled} >{t('module.scoreLogic.component.timeDependent.editModal.save')}</Button>
                    <Button theme="secondary" className="rounded-sm" onClick={ () => { closeModal(); } }>{t('module.scoreLogic.component.timeDependent.editModal.cancel')}</Button>
                </ButtonGroup>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { modals } = state;
    return { modals };
};

const connectedEditFormula = connect(mapStateToProps)(EditFormula);
export { connectedEditFormula as EditFormula };

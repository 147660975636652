import React from "react"
import { useDispatch } from "react-redux"
import slugify from "slugify"
import { Switch } from "@material-ui/core"
import { Badge } from "shards-react"
import { isEmpty, replace } from "lodash"
import { Column } from "react-base-table"
import { useTranslation } from "react-i18next"
import BasicTable from "../../../../components/baseTable/BasicTable"
import { TableRowsActions } from "./TableRowsActions"
import { uniqueBy } from "../../../../utils/uniqueBy"
import { modalActions } from "../../../modals"

export function RolesTable({ data, mfiID }) {
  const columns = []
  const actions = []
  const { t } = useTranslation()
  const dispatch = useDispatch()

  if (isEmpty(data)) {
    return null
  }
  data.forEach((item, i) => {
    data[i].hierarchyLayer = item.hierarchyLayer && item.hierarchyLayer.name
  })

  const toogleCanDisburse = (e, rowData) => {
    const {
      id: subroleID,
      code,
      description,
      mfiCode,
      role,
      hierarchyLayer,
      canDisburse,
    } = rowData
    dispatch(
      modalActions.manageMFISubRoles({
        subroleID,
        code,
        description,
        mfiCode,
        mfiID,
        role,
        hierarchyLayer,
        canDisburse,
      })
    )
  }

  Object.keys(data[0]).map((item) => {
    const payload = {
      key: item,
      dataKey: item,
      title: replace(replace(item, "_", " "), "/", " "),
      width: 150,
      resizable: true,
      className: ({ column }) => {
        const { key } = column
        return `${slugify(key).toLowerCase()}`
      },
      headerRenderer: (cell) => {
        const { column } = cell
        const { key } = column
        switch (key) {
          case "id":
            return t(`module.mfiSetup.manageMfi.id`)
          default:
            return replace(
              replace(t(`module.mfiSetup.manageMfi.${key}`), "_", " "),
              "/",
              " "
            )
        }
      },
      cellRenderer: (cell) => {
        const { cellData, column, rowData } = cell

        const cellRender = cellData
        const { key } = column
        const status = t(`module.mfiSetup.manageMfi.active`)
        const { canDisburse } = rowData

        switch (key) {
          case "status":
            return (
              <Badge
                theme="complete"
                className={`badge-xs ${slugify(status).toLowerCase()}`}
              >
                {status &&
                  replace(status.toLowerCase(), new RegExp("_", "g"), " ")}
              </Badge>
            )
          case "action":
            return (
              <TableRowsActions
                {...rowData}
                actions={actions}
                mfiID={mfiID}
                status={status}
              />
            )
          case "canDisburse":
            return (
              <>
                {canDisburse}
                <Switch
                  onChange={(e) => toogleCanDisburse(e, rowData)}
                  checked={cellData}
                  color="primary"
                  name="canDisburse"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </>
            )
          default:
            return cellRender
        }
      },
    }

    if (item === "action") {
      payload.width = 100
      payload.frozen = Column.FrozenDirection.RIGHT
      payload.className = ({ column }) => {
        const { key } = column
        return `${slugify(key).toLowerCase()} action-cell`
      }
    }

    columns.push(payload)

    return item
  })

  return <BasicTable columns={columns} data={uniqueBy(data, "id")} fixed />
}

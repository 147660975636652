import React from 'react';
import { useTranslation } from 'react-i18next';
import TooltipTrigger from 'react-popper-tooltip';

export function TableRowsActions({ id, mfiCode }) {
    const { t } = useTranslation()

    function Trigger({ getTriggerProps, triggerRef }) {
        return (
            <button
                {...getTriggerProps({
                    ref: triggerRef,
                    className: 'trigger dropdown-toggle btn btn-primary',
                })}
            >
                {t('module.mfiSetup.actions.actions')}
            </button>
        );
    }

    function Tooltip({
        getTooltipProps,
        getArrowProps,
        tooltipRef,
        arrowRef,
        placement,
    }) {
        return (
            <div
                {...getTooltipProps({
                    ref: tooltipRef,
                    className: 'tooltip-container table-actions',
                })}
            >
                <div
                    {...getArrowProps({
                        ref: arrowRef,
                        'data-placement': placement,
                        className: 'tooltip-arrow',
                    })}
                />
                <div className="tooltip-body">
                    <div className="dropdown dropdown-right">{items}</div>
                </div>
            </div>
        );
    }

    const items = (
        <div className="dropdown-item">
            <a href={ `/mfis/edit/${id}/${mfiCode}` }>{t('module.mfiSetup.actions.editMfi')}</a>
        </div>
    );

    return (
        <>
            {items && <TooltipTrigger placement="right" trigger="click" tooltip={Tooltip}>
                {Trigger}
            </TooltipTrigger>}
        </>
    );
}

import React, { createContext } from 'react'
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { notificationsActions } from '../client/views/notifications/store/_actions';
import { useDispatch, useSelector } from 'react-redux';

const WebSocketContext = createContext(null)

export { WebSocketContext }

export default ({ children }) => {

    const user = useSelector((state) => state.authentication);

    const { mfi = {} } = user && user?.profile
    const dispatch = useDispatch()

    const fetchAgain = () => {
        if (mfi !== null) {
            const mfiId = mfi && mfi?.id

            if (mfiId !== undefined) {
                dispatch(notificationsActions.fetchNotifications(mfiId))
            }
        }

    }

    let socket;

    const token = JSON.parse(localStorage.getItem('user'));
    // loan officer token - the one linked to loan arrears

    if (token) {

        if (token.accessTokenString !== undefined) {

            if ((!socket && (token !== null))) {
                const socket = new SockJS('https://integrations.test.backend-service.incentroafrica.com/integration-data/bg-notification?access_token=' + token.accessTokenString);
                socket.withCredentials = true;
                const stompClient = Stomp.over(socket);
                stompClient.connect({}, function (frame) {
                    stompClient.subscribe('/user/queue/message', function (greeting) {
                        var jsonData = JSON.parse(greeting.body);
                        dispatch(notificationsActions.getRealTimeNotifications(jsonData, fetchAgain))
                    });
                });
            }
        }
    }

    return (
        <WebSocketContext.Provider>
            {children}
        </WebSocketContext.Provider>
    )
};

import React, { useState } from 'react';
import { FormControl, FormLabel, Grid, RadioGroup, FormControlLabel, Radio, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-date-picker'
import { clientActions } from '../../../../_actions';
import { useStylesClient } from './clientModalStyles';
import { useTranslation } from 'react-i18next';

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('/');
}

const Actions = ({ mfiId, loanId }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [state, setState] = useState({
        mfiId,
        loanId,
        actionTaken: 1,
        notes: ''
    });
    const classes = useStylesClient();
    const [value, onChange] = useState(new Date());

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setState((prevState) => {
            return { ...prevState, mfiId, loanId, [name]: type === 'radio' ? parseInt(value) : value}
        })
    };

    const handleSubmit = () => {
        const datePicked = { date: formatDate(value) }
        const data = { ...state, ...datePicked };
        return dispatch(clientActions.createNote(data));
    }

    return (
        <div>
            <Grid container>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <FormControl component="div" className={classes.formWrapper}>
                        <FormLabel className={classes.label}>{t('module.client.arrears.action.modal.profile.actionsTaken')}</FormLabel> &nbsp;&nbsp;
                        <RadioGroup
                            aria-label="action taken"
                            name="actionTaken"
                            value={state.actionTaken.toString()}
                            onChange={handleChange}
                            row
                        >
                            <FormControlLabel value="1" control={<Radio />} label={t('module.client.arrears.action.modal.profile.demandLetter')} />
                            <FormControlLabel value="2" control={<Radio />} label={t('module.client.arrears.action.modal.profile.sms')} />
                            <FormControlLabel value="3" control={<Radio />} label={t('module.client.arrears.action.modal.profile.phoneCall')} />
                            <FormControlLabel value="4" control={<Radio />} label={t('module.client.arrears.action.modal.profile.homeBusinessVisit')} />
                            <FormControlLabel value="5" control={<Radio />} label={t('module.client.arrears.action.modal.profile.quarantorCall')} />
                        </RadioGroup>
                    </FormControl>

                    <DatePicker
                        onChange={onChange}
                        value={value}
                        format="dd/MM/y"
                    />
                </div>
                <br />
                <textarea
                    aria-label="notes"
                    placeholder={`${t('module.client.arrears.action.modal.profile.quarantorCall')}...`}
                    rows={4}
                    name="notes"
                    className={classes.textArea}
                    onChange={handleChange}
                />
                <br />
                <br />
                <Button
                    className={classes.button}
                    style={{
                        backgroundColor: `${state?.notes?.length >= 1 ? '#008d6a' : '#c0c0c0'}`,
                        cursor: `${state?.notes?.length >= 1 ? 'pointer' : 'not-allowed'}`
                    }}
                    onClick={handleSubmit}
                >
                    {t('module.client.arrears.action.modal.profile.save')}
                </Button>
            </Grid>
        </div>
    )
}

export default Actions;

import axios from "axios"
import { integrationsInstance, handleResponse } from "../../_helpers"

/**
 * Sync Applications
 *
 * @param {*} surveyUid
 */
const syncApplications = (surveyUid) =>
  axios({
    baseURL: process.env.REACT_APP_KOBOTOOLKIT_PUBLISHER_API_BASE,
    method: "get",
    url: "publisher-client/sync/",
    auth: {
      username: "QXgT*Mm2RraR3xGpWsY",
      password: "PTHTc*TRG2ioNT@8eWN",
    },
    params: {
      xFormIdString: `${surveyUid}`,
    },
  })

const syncIncompleteCropinApplications = () =>
  axios({
    baseURL: process.env.REACT_APP_KOBOTOOLKIT_PUBLISHER_API_BASE,
    method: "post",
    url: "publisher-client/rebuild",
    auth: {
      username: "QXgT*Mm2RraR3xGpWsY",
      password: "PTHTc*TRG2ioNT@8eWN",
    },
  })

const syncCropinApplications = () =>
  axios({
    baseURL: process.env.REACT_APP_KOBOTOOLKIT_PUBLISHER_API_BASE,
    method: "post",
    url: "publisher-client/fetch-loans-cropin",
    auth: {
      username: "QXgT*Mm2RraR3xGpWsY",
      password: "PTHTc*TRG2ioNT@8eWN",
    },
  })

function fetchCoffeePurchasesCropin() {
  return integrationsInstance
    .post("musoni/payments/fetch-coffee-purchases")
    .then(handleResponse)
}

function getPurchaseSyncLastModified() {
  return integrationsInstance
    .get("musoni/payments/purchase-sync-last-modified")
    .then(handleResponse)
}

function createGroupClientsMusoni(payload) {
  return integrationsInstance
    .post("musoni/exposed/create-group-clients", payload)
    .then(handleResponse)
}

export const publisherService = {
  syncApplications,
  syncIncompleteCropinApplications,
  syncCropinApplications,
  fetchCoffeePurchasesCropin,
  getPurchaseSyncLastModified,
  createGroupClientsMusoni,
}

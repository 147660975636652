/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { filter } from 'lodash';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { useTranslation } from 'react-i18next';

function Tdcv({
    id, type, selected = [], selectOne, scoreLogic, handler,
}) {
    // Set component state
    const [state, setState] = useState(selected);
    const { t } = useTranslation();

    let filterConfig = { type };
    let title = t('module.scoreLogic.component.emcv.tdcvTitle');
    let returnType = 'tdcv';

    if (type === 'TIME_DEPENDENCY_FLAG') {
        filterConfig = { parentFormulaCode: id, type: 'TD_TO_TIME_DEPENDENCY_FLAG' };
    } else if (type === 'TIME_DEPENDENT_CALCULATED_VARIABLE') {
        filterConfig = { type: 'TIME_DEPENDENCY_FLAG' };
    } else if (type === 'EVERY_MONTH_CALCULATED_VARIABLE') {
        title = t('module.scoreLogic.component.emcv.emcvTitle');
        returnType = 'emcv';
    } else if (type === 'SINGLE_CALCULATION') {
        title = t('module.scoreLogic.component.emcv.formulas');
        returnType = 'otherCalculations';
    }

    const { formulas } = scoreLogic;
    const Flags = filter(formulas, filterConfig);

    useEffect(() => {
        handler(returnType, { [returnType]: state });
    }, [state]);

    const selectItem = (which) => {
        let newState = [...state];

        if (selectOne) {
            newState = [];
        }

        const index = newState.indexOf(which);

        if (index === -1) {
            if (selectOne) {
                newState = [which];
            } else {
                newState.push(which);
            }
        } else {
            newState.splice(index, 1);
        }

        setState(newState);
    };

    return (
        <div className="items-group mb-2 tdcv">
            <h4>{title}</h4>
            <div className="items-holder flex flex-wrap">
                {
                    Flags.map((formula) => {
                        const { name, code } = formula;
                        const active = state && state.includes(code) && 'variable-item-active';

                        return (
                            <Tooltip key={code} placement="top" overlay={name} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                <div className={`item variable-item ${active}` } onClick={ () => { selectItem(code); } } key={code}>{ code }</div>
                            </Tooltip>
                        );
                    })
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { scoreLogic } = state;
    return {
        scoreLogic,
    };
};

const connectedComputationCodesSetup = connect(mapStateToProps)(Tdcv);
export { connectedComputationCodesSetup as Tdcv };

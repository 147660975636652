import React from 'react';

const NotificationReadStatusIcon = ({ img, status }) => {
    return (
        <img
            src={img}
            alt={status}
            style={{
                height: '16px',
                paddingRight: '4px',
                opacity: '0.4'
            }}
        />
    )
}

export default NotificationReadStatusIcon;

import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import slugify from "slugify"
import { Button as FileButton, CircularProgress } from "@material-ui/core"
import { Button } from "shards-react"
import Svg from "react-inlinesvg"
import { useTranslation } from "react-i18next"
import { modalActions } from "../../../modals"
import { cslActions } from "../../../scoreLogic/_actions"

const editIconSVG = require("../../../../assets/images/icons/pencil-icon.svg")
const deleteIconSVG = require("../../../../assets/images/icons/delete.svg")

function ScoringVariables({ scoreLogic, dispatch }) {
  const { uid, scoringVariables } = scoreLogic
  const { t } = useTranslation()
  const fileRef = useRef()

  const [uploadingVariables, setUploadingVariables] = useState(false)
  const [uploadData, setUploadData] = useState([])
  useEffect(() => {
    // scroll page to top on displaying component
    const wrapper = document.getElementsByClassName("main-content")
    wrapper[0].scrollIntoView()
  }, [])

  useEffect(() => {
    const postData = async () => {
      if (uploadData.length > 0) {
        await uploadEntries(uploadData)
        setUploadingVariables(false)
      }
    }
    postData()
  }, [uploadData])

  /**
   * Select variable
   *
   * @param {*} category
   * @param {*} id
   */
  const addVariableCode = (category, id, types, code, title, dataType) => {
    dispatch(
      modalActions.addScoringVariablesVariable({
        category,
        id,
        types,
        codes: [code],
        title,
        dataType,
      })
    )
  }

  const deleteNewEntry = (id) => {
    dispatch(modalActions.deleteScoringVariablesEntry(id))
  }

  const addVariableCondition = (category, id) => {
    dispatch(modalActions.addScoringVariablesCondition({ category, id }))
  }

  const addNewEntry = (data) => {
    dispatch(modalActions.addScoringVariablesEntry(data))
  }

  const uploadEntries = (data) => {
    dispatch(cslActions.uploadScoringVariablesEntries(data))
  }

  const editConfig = (category, id, entryId, maxScore, value) => {
    dispatch(
      modalActions.editScoringVariablesCondition({
        type: "configuration",
        category,
        entryId,
        id,
        maxScore,
        value,
      })
    )
  }

  // eslint-disable-next-line no-underscore-dangle
  const _delete = (id) => {
    dispatch(modalActions.deleteScoringVariablesCondition(id))
  }

  // eslint-disable-next-line no-restricted-globals
  const isNumeric = (x) =>
    (typeof x === "number" || typeof x === "string") && !isNaN(Number(x))

  const editIcon = (
    <Svg className="text-center d-inline-block" src={editIconSVG} />
  )
  const deleteIcon = (
    <Svg className="text-center d-inline-block" src={deleteIconSVG} />
  )

  const getEntryConfigurations = (configs) => {
    return configs.map((config) => ({
      value: config.value,
      maxScore: config.maxScore,
    }))
  }

  const getCategoryEntries = (entries) => {
    return entries.map((entry) => {
      const { title, code, dataType, configurations } = entry
      return {
        surveyUuid: uid,
        title,
        code,
        dataType,
        configurations: getEntryConfigurations(configurations),
      }
    })
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      setUploadingVariables(true)
      const fileReader = new FileReader()
      fileReader.readAsText(e.target.files[0], "UTF-8")
      fileReader.onload = async (e) => {
        const dataFromFile = JSON.parse(e.target.result)
        const data = dataFromFile.map((category) => {
          return {
            scoreCardCategoryId: category.id,
            entries: getCategoryEntries(category.entries),
          }
        })
        setUploadData(data)
      }
    }
  }

  return (
    <div className="scoring-variables shadow-none p-6 pt-0">
      {scoringVariables[0]?.entries.length === 0 && (
        <FileButton
          color="secondary"
          className="mb-1 mt-0 mr-1"
          variant="contained"
          component="label"
          disabled={uploadingVariables}
        >
          {uploadingVariables
            ? "Uploading"
            : "Upload Scoring Variables From File"}
          <input type="file" hidden ref={fileRef} onChange={onFileChange} />
        </FileButton>
      )}
      {uploadingVariables && <CircularProgress size={20} />}
      <div className="scoring-variables__title-bar flex">
        <div className="scoring-variables__label scoring-variables__label--code"></div>
        <div className="scoring-variables__label scoring-variables__label--description">
          {t("module.scoreLogic.view.scoringVariables.description")}
        </div>
        <div className="scoring-variables__label scoring-variables__label--codes"></div>
        <div className="scoring-variables__label scoring-variables__label--threshold">
          {t("module.scoreLogic.view.scoringVariables.threshold")}
        </div>
        <div className="scoring-variables__label scoring-variables__label--condition">
          {t("module.scoreLogic.view.scoringVariables.condition")}
        </div>
        <div className="scoring-variables__label scoring-variables__label--max-points">
          {t("module.scoreLogic.view.scoringVariables.maxPoints")}
        </div>
      </div>
      <div className="scoring-variables__list">
        {scoringVariables &&
          scoringVariables.map((item, cardIdx) => {
            const { id, title, entries } = item
            const slug = slugify(title).toLowerCase()
            const category = title
            const surveyEntries = entries.filter(
              (item) => item.surveyUuid === uid
            )

            return (
              <div className={`scoring-variables__row flex ${slug}`} key={id}>
                <div className="scoring-variables__number">{cardIdx + 1}</div>
                <div className="scoring-variables__entries">
                  <div className="scoring-variables__title flex">
                    <div className="scoring-variables__title-label">
                      {title}
                    </div>{" "}
                    <div className="spacer"> </div>
                  </div>
                  {surveyEntries &&
                    surveyEntries.map((item, idx) => {
                      const {
                        id: entryId,
                        title,
                        code,
                        configurations,
                        dataType,
                      } = item
                      return (
                        <div
                          className="scoring-variables__entry flex"
                          key={entryId}
                        >
                          <div className="scoring-variables__entry-number">{`${
                            cardIdx + 1
                          }.${idx + 1}`}</div>
                          <div className="scoring-variables__entry-title">
                            <div className="scoring-variables__entry-title-text">
                              {title}
                            </div>
                            <Button
                              theme="primary"
                              className="m-2"
                              onClick={(e) => {
                                addVariableCondition(category, entryId)
                              }}
                            >
                              {t(
                                "module.scoreLogic.view.scoringVariables.addCondition"
                              )}
                            </Button>
                          </div>
                          <div className="scoring-variables__action--add flex items-center">
                            <div className="flex variable-holder mr-2 items-center">
                              <div className="item variable-item mr-2">
                                {code}
                              </div>
                              <div
                                className="scoring-variables__action--add--plus ml-auto"
                                onClick={(e) => {
                                  addVariableCode(
                                    category,
                                    entryId,
                                    [
                                      "code",
                                      "otherCalculations",
                                      "cashflowVariable",
                                    ],
                                    code,
                                    title,
                                    dataType
                                  )
                                }}
                              >
                                &hellip;
                              </div>
                            </div>
                            <Button
                              theme="secondary"
                              className="thin delete scoring-variables__action--delete--plus ml-auto mb-1 mr-2"
                              onClick={(e) => {
                                deleteNewEntry(entryId)
                              }}
                            >
                              {t(
                                "module.scoreLogic.view.scoringVariables.delete"
                              )}
                            </Button>
                          </div>
                          <div className="scoring-variables__configurations">
                            <div className="scoring-variables__configuration-spacer"></div>
                            {configurations &&
                              configurations.map((item) => {
                                const { id, maxScore, value } = item
                                return (
                                  <div
                                    className="scoring-variables__configuration flex items-center"
                                    key={id}
                                  >
                                    <Button
                                      theme="success"
                                      className="scoring-variables__configuration-edit shadow-none"
                                      onClick={() => {
                                        editConfig(
                                          category,
                                          id,
                                          entryId,
                                          maxScore,
                                          value
                                        )
                                      }}
                                    >
                                      {editIcon}
                                    </Button>
                                    <Button
                                      theme="danger"
                                      className="scoring-variables__configuration-delete shadow-none"
                                      onClick={() => {
                                        _delete(id)
                                      }}
                                    >
                                      {deleteIcon}
                                    </Button>
                                    <div className="scoring-variables__configuration-value">
                                      {value}
                                    </div>
                                    <div className="scoring-variables__configuration-condition">
                                      {" "}
                                      {isNumeric(value)
                                        ? "if less than"
                                        : "if equal to"}{" "}
                                    </div>
                                    <div className="scoring-variables__configuration-maxScore">
                                      {maxScore}
                                    </div>
                                  </div>
                                )
                              })}
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            )
          })}
      </div>
      <div className="scoring-variables__entries--add-new ml-auto mt-4">
        <Button
          theme="secondary"
          className="btn"
          onClick={() => {
            addNewEntry({
              types: ["code", "otherCalculations", "cashflowVariable"],
            })
          }}
          disabled={uploadingVariables}
        >
          {t("module.scoreLogic.view.scoringVariables.scoringVariable")}
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { scoreLogic } = state
  return { scoreLogic }
}

const connectedScoringVariables = connect(mapStateToProps)(ScoringVariables)
export { connectedScoringVariables as ScoringVariables }

import React from 'react';
import { connect } from 'react-redux';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/widget/widgetTitle';
import { ManageAssumption } from '../component/ManageAssumption';
import { modalActions } from '../../modals';

function AddAssumption({ data, dispatch }) {
    const { t } = useTranslation();
    const closeModal = () => {
        dispatch(modalActions.hideModal(1));
    };

    return (
        <div className="site-modal assumptions-type">
            <Header title={t('module.scoreLogic.modal.addAssumption.headerTitle')} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal }/>
            <ManageAssumption savedData={{ ...data }}/>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { scoreLogic } = state;
    return { scoreLogic };
};

const connectedAddAssumption = connect(mapStateToProps)(AddAssumption);
export { connectedAddAssumption as AddAssumption };

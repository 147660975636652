/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Svg from "react-inlinesvg"
import { Grid, Typograpghy } from "@material-ui/core"
import { ButtonGroup, Button } from "shards-react"
import currency from "currency.js"
import Header from "../../../components/widget/widgetTitle"

import { modalActions } from "../../modals"
import { applicationActions } from "../_actions"

import "./PayDecline.scss"
import { useTranslation } from "react-i18next"
import { ErrorsView, LoadingView } from "../../../views"
const headerSVG = require("../../../assets/images/icons/header-icon.svg")

const PaymentDecline = ({ modalID, data }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const authentication = useSelector((store) => store.authentication)
  const { assignWorkflowUser, loading, error } = useSelector(
    (state) => state.applications
  )

  const {
    profile: {
      mfi: { currency: mfiCurrency },
    },
  } = authentication

  const { payload, disburse, approve, decline, bankPayment, bankName } = data
  const skipManagementAccount = payload[0].amountToDisburse < 30000000
  const workflowUser =
    payload[0].workflowUser === "ugacof_namayanja"
      ? payload[0].receivedBy
      : payload[0].workflowUser

  useEffect(() => {
    if (
      (approve && skipManagementAccount) ||
      payload[0].workflowUser === "ugacof_namayanja"
    ) {
      dispatch(applicationActions.getNextPurchasesWorkflowUser(workflowUser))
    }
  }, [approve])

  const totalDisburseAmount = payload.reduce(
    (acc, choice) => acc + choice.amountToDisburse,
    0
  )

  const handeSubmit = () => {
    if (approve) {
      payload[0].workflowUser =
        skipManagementAccount || payload[0].workflowUser === "ugacof_namayanja"
          ? assignWorkflowUser
          : "ugacof_namayanja"
    }
    payload[0]["bankPayment"] = bankPayment
    dispatch(applicationActions.processFarmerCoffeePayments(payload))
    closeModal()
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  const approvePrompt = `Are you sure you want to approve and assing to
              ${
                skipManagementAccount ||
                payload[0].workflowUser === "ugacof_namayanja"
                  ? assignWorkflowUser
                  : "ugacof_namayanja"
              }
            the selected payment for:`

  return (
    <div className="site-modal">
      <Header
        title="Payment Confirmation"
        icon={<Svg className="text-center d-inline-block" src={headerSVG} />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        {loading && <LoadingView />}
        {error && <ErrorsView />}
        <p>
          {disburse && !loading && !error && (
            <>
              Would you like to make{" "}
              <span className="font-bold">{payload.length}</span> payment(s) of{" "}
              <span className="font-bold">
                {mfiCurrency} {currency(totalDisburseAmount).format()}
              </span>
            </>
          )}
          {decline &&
            !loading &&
            !error &&
            `Are you sure you want to decline the selected payment for:`}
          {approve && !loading && !error && approvePrompt}
        </p>
        {!loading &&
          !error &&
          !bankPayment &&
          payload.map((choice, index) => (
            <p key={index} className="whitespace-pre-wrap">
              &emsp;{mfiCurrency} {currency(choice.amountToDisburse).format()}{" "}
              for {choice.farmerName} (Mobile {choice.mobileNo})
            </p>
          ))}

        {!loading &&
          !error &&
          bankPayment &&
          payload.map((payment) => (
            <>
              <Grid container className="mb-1">
                <Grid item sm={4}>
                  Account Name:
                </Grid>
                <Grid item sm={4}>
                  {payment.accountName}
                </Grid>
              </Grid>
              <Grid container className="mb-1">
                <Grid item sm={4}>
                  Bank Name:
                </Grid>
                <Grid item sm={4}>
                  {bankName}
                </Grid>
              </Grid>
              <Grid container className="mb-2">
                <Grid item sm={4}>
                  Account Number:
                </Grid>
                <Grid item sm={4}>
                  {payment.accountNo}
                </Grid>
              </Grid>
              <Grid container className="mb-4 font-bold">
                <Grid item sm={4}>
                  Amount:
                </Grid>
                <Grid item sm={4}>
                  {mfiCurrency} {currency(payment.amountToDisburse).format()}
                </Grid>
              </Grid>
            </>
          ))}

        <ButtonGroup className="btn-group-actions site-modal__actions">
          <Button
            theme="primary"
            className="thin mr-2"
            onClick={() => {
              handeSubmit()
            }}
          >
            Confirm
          </Button>
          <Button
            theme="cancel"
            className="thin delete"
            onClick={() => {
              closeModal()
            }}
          >
            {t("applicationDisburse.cancel")}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default PaymentDecline

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'shards-react';
import { WorkFlowTable } from './WorkFlowTable';
import WorkflowGroupSelect from './WorkflowGroupSelect';
import { WorkFlowDataActions } from './WorkflowActions';
import { mfiManagerActions } from '../..';
import './ManageWorkflow.scss';

export function ManageWorkflow(props) {
    const { mfi } = props;
    const { mfiCode, id: mfiId } = mfi;
    const dispatch = useDispatch();
    const { subRoleLimits, filteredSubroleLimits, refreshTable, groups } = useSelector((store) => store.mfis);


    useEffect(() => {
        dispatch(mfiManagerActions.getWorkflowGroups(mfiId));
    }, [dispatch, mfiId]);

    useEffect(() => {
        dispatch(mfiManagerActions.getMFISubRoleLimits(mfiCode));
    }, [dispatch, mfiCode]);

    useEffect(() => {
        dispatch(mfiManagerActions.getMFISubRoles(mfiId));
    }, [dispatch, mfiId]);

    const addNewFlow = () => {
        dispatch(mfiManagerActions.addMFISubRoleLimit(mfiCode));
    };
    let updatedSubRoleLimits;
    if (filteredSubroleLimits === null) {
      updatedSubRoleLimits = subRoleLimits;
    } else {
      updatedSubRoleLimits = filteredSubroleLimits;
    }

    return (
        <>
            <WorkflowGroupSelect groups={groups} />
            <WorkFlowDataActions {...props}/>
            <WorkFlowTable {...props} data={ updatedSubRoleLimits  } refreshTable={refreshTable}/>
            <Button className="m-4" theme="primary" onClick={() => { addNewFlow(); }} >Add Flow</Button>
        </>
    );
}

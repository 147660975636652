import { useState, useEffect } from 'react';

function getWindowDimensions() {
    const v = window;
    const d = document;
    const windowWidth = v.innerWidth ? v.innerWidth : d.documentElement.clientWidth;
    const windowHeight = v.innerHeight ? v.innerHeight : d.documentElement.clientHeight;
    return {
        windowWidth,
        windowHeight,
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

import React, { useState } from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useDispatch } from 'react-redux';
import {
    FormGroup,
    FormInput,
    ButtonGroup,
    Button,
} from 'shards-react';
import Header from '../../../components/widget/widgetTitle';
import { mfiManagerActions } from '..';
import { modalActions } from '../../modals';

export const AddWorkflowGroup = ({ modalID, data }) => {
    const { mfiId, title, edit } = data;
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState({
        code: '', description: '', ...data
    });

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setInputs((prevState) => ({ ...prevState, [name]: value }));
    };

    const addWorkflowGroup = () => {
        const payload = {
            ...inputs,
        };
        payload['mfiId'] = mfiId;
        dispatch(mfiManagerActions.addWorkflowGroup( payload));
        closeModal();
    };

    const editWorkflowGroup = () => {
        const payload = {
            ...inputs,
        };
        // remove edit and title
        let newPayload = {
          code: payload.code,
          description: payload.description,
          id: payload.id,
          mfiCode: payload.mfiCode,
          mfiId: payload.mfiId
        };
        dispatch(mfiManagerActions.editWorkflowGroup(newPayload));
        closeModal();
    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal">
            <Header title={title} icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal }/>
            <div className="p-10">
                <FormGroup>
                    <label htmlFor="code">Group Code</label>
                    <FormInput defaultValue={inputs.code} name="code" size="md" className="mb-2" disabled={edit && true} onChange={ handleChange }/>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="description">Description</label>
                    <FormInput defaultValue={inputs.description} name="description" size="md" className="mb-2" onChange={ handleChange }/>
                </FormGroup>
                <ButtonGroup className="btn-group-actions site-modal__actions">
                    <Button theme="primary" className="thin mr-2" onClick={ () => { edit ? editWorkflowGroup() : addWorkflowGroup(); } } >{edit ? 'Edit' : 'Save'}</Button>
                    <Button theme="secondary" className="thin delete" onClick={ () => { closeModal(); } }>Cancel</Button>
                </ButtonGroup>
            </div>
        </div>
    );
};

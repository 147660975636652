import React from 'react';

const LoanDetailsCard = ({ keyValue, value }) => {
    return (
        <div className="clientDetails__section_row">
            <p>{keyValue}</p>
            <p>{value}</p>
        </div>
    )
}

export default LoanDetailsCard;

import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { replace } from 'lodash';

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    th: {
        textTransform: 'capitalize',
    },
    action: {
      position: 'absolute',
      right: '10px',
      background: '#eef4f9',
      textTransform: 'capitalize',
      width: '100px'
    },
    sizeSmall: {
        padding: 0,
        lineHeight: 1,
    },
});

class ClientEnhancedTableHead extends React.Component {
    render() {
        const {classes, data, isUniqueAction=false, t} = this.props;

        const columns = data && data.length > 0 && Object.keys(data[0]).map((column) => {
            const columnData = {
                id: column,
                label: replace(replace(column, '_', ' '), '/', ' '),
            };
            return columnData;
        });

        return (
            <TableHead>
                <TableRow>
                    {columns && columns.length> 0  && columns.map((column) => (
                        <TableCell
                            key={column.id}
                            align={column.numeric ? 'right' : 'left'}
                            padding={column.disablePadding ? 'none' : 'default'}
                            size="small"
                            sortDirection={false}
                            className={classes.th}
                            style={{
                                display: `${(column.label === 'id' || column.label === 'mfiId' || column.label === 'action') && 'none'}`
                            }}
                        >
                            {column.label !== undefined && t(`module.client.allClientsTableHeader.${column.label}`)}
                        </TableCell>
                    ))}
                    <TableCell
                        align="left"
                        padding="normal"
                        size="small"
                        className="action action-cell"
                    >
                        {isUniqueAction ? 'View' : t(`module.users.userAccounts.Actions`)}
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }
}

export default withTranslation()(withStyles(styles)(ClientEnhancedTableHead));

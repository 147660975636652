import React from 'react';
import {
    Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { Container, Row, Col } from 'shards-react';
import { connect } from 'react-redux';
import { filter } from 'lodash';

import PageTitle from '../../../components/common/PageTitle';
import { ManageUser, userManagerActions } from '..';
import { authenticationActions } from '../../authentication';

const UsersEditView = ({ users, match }) => {
    const { params } = match;
    const { id } = params;
    const { items } = users;

    if (!items) {
        return false;
    }

    const [user] = filter(items, (item) => Number(item.id) === Number(id));

    if (!user) {
        return false;
    }

    return (
        <Container fluid className="main-content-container px-4 mb-4">
            <Row noGutters className="page-header pt-4 pb-1">
                <PageTitle sm="4" title="Edit User Account" className="text-sm-left" />
            </Row>
            <Row>
                <Col>
                    <Tabs>
                        <TabList>
                            <Tab>Details</Tab>
                        </TabList>
                        <TabPanel>
                            <ManageUser id={id}/>
                        </TabPanel>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state) => {
    const { users } = state;
    return { users };
};

const mapDispatchToProps = (dispatch) => {
    dispatch(userManagerActions.getAll());
    dispatch(authenticationActions.getProfile());
    return {};
};

const connectedUsersEditView = connect(mapStateToProps, mapDispatchToProps)(UsersEditView);
export { connectedUsersEditView as UsersEditView };

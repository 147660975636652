import { configConstants, configService } from "."
import { alertActions } from "../alert"

/**
 * Export modules
 */
export const configActions = {
  addApplicationColumnState,
  addReportColumnState,
  getApplicationColumns,
  getReportColumns,
  addApplicationColumn,
  addReportColumn,
  addProfileField,
  getProfileFields,
  editProfieFields,
  deleteProfileField,
  editApplicationColumn,
  editReportColumn,
  deleteApplicationColumn,
  deleteReportColumn,
  getCreditScoreReportSetup,
  addCreditScoreReportSetup,
  editCreditScoreReportSetup,
  deleteCreditScoreReportSetup,
  addCreditScoreReportSetupState,
  addFirstComment,
  getFirstComments,
  editFirstComment,
  deleteFirstComments,
  getReportQuestion,
  updateApplicantGender,
  updateApplicantAge,
  updateApplicantMaritalStatus,
  updateApplicantLoan,
  updateNewApplicant,
  addReportQuestion,
  updateLandSize,
  updateApplicantCustomerProfile,
  updateNewApplicantCustomerProfile,
  getProfileQuestion,
  addCbsField,
  getCbsFields,
  editCbsField,
  deleteCbsField,
}

/**
 * get credit score
 *
 * @param {*} surveyUuid
 */
function getCreditScoreReportSetup(surveyUuid) {
  return (dispatch) => {
    dispatch(request())
    configService.getCreditScoreReportSetup(surveyUuid).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error("Fetching Credit Score Setup failed: ", error)
        )
      }
    )
  }

  function request() {
    return {
      type: configConstants.CONFIG_CREDIT_SCORE_REPORT_SETUP_GET_REQUEST,
    }
  }
  function success(data) {
    return {
      type: configConstants.CONFIG_CREDIT_SCORE_REPORT_SETUP_GET_SUCCESS,
      data,
    }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_CREDIT_SCORE_REPORT_SETUP_GET_FAILURE,
      error,
    }
  }
}

/**
 * add credit score to state
 *
 * @param {*} payload
 */
function addCreditScoreReportSetupState(payload) {
  return (dispatch) => {
    dispatch(success(payload))
  }

  function success(payload) {
    return {
      type: configConstants.CONFIG_CREDIT_SCORE_REPORT_SETUP_STATE_ADD_SUCCESS,
      data: payload,
    }
  }
}

/**
 * add credit score
 *
 * @param {*} payload
 */
function addCreditScoreReportSetup(payload) {
  return (dispatch) => {
    dispatch(request())
    configService.addCreditScoreReportSetup(payload).then(
      (data) => {
        dispatch(success(data))
        dispatch(alertActions.success("Add successful"))
      },
      (error) => {
        dispatch(failure(error.response.data.message))
        dispatch(
          alertActions.error("Add failed: ", error.response.data.message)
        )
      }
    )
  }

  function request() {
    return {
      type: configConstants.CONFIG_CREDIT_SCORE_REPORT_SETUP_SAVE_REQUEST,
    }
  }
  function success(data) {
    return {
      type: configConstants.CONFIG_CREDIT_SCORE_REPORT_SETUP_SAVE_SUCCESS,
      data,
    }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_CREDIT_SCORE_REPORT_SETUP_SAVE_FAILURE,
      error,
    }
  }
}

/**
 * edit credit score
 *
 * @param {*} payload
 */
function editCreditScoreReportSetup(id, payload) {
  return (dispatch) => {
    dispatch(request())
    configService.editCreditScoreReportSetup(id, payload).then(
      (data) => {
        dispatch(success(data))
        dispatch(alertActions.success("Edit successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Edit failed: ", error))
      }
    )
  }

  function request() {
    return {
      type: configConstants.CONFIG_CREDIT_SCORE_REPORT_SETUP_EDIT_REQUEST,
    }
  }
  function success(data) {
    return {
      type: configConstants.CONFIG_CREDIT_SCORE_REPORT_SETUP_EDIT_SUCCESS,
      data,
    }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_CREDIT_SCORE_REPORT_SETUP_EDIT_FAILURE,
      error,
    }
  }
}

/**
 * Delete credit score
 *
 * @param {*} id
 */
function deleteCreditScoreReportSetup(id) {
  return (dispatch) => {
    dispatch(request())
    try {
      configService.deleteCreditScoreReportSetup(Number(id)).then(
        () => {
          dispatch(success(id))
          dispatch(alertActions.success("Delete succesful"))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(alertActions.error("Delete failed: ", error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request() {
    return {
      type: configConstants.CONFIG_CREDIT_SCORE_REPORT_SETUP_DELETE_REQUEST,
    }
  }
  function success(id) {
    return {
      type: configConstants.CONFIG_CREDIT_SCORE_REPORT_SETUP_DELETE_SUCCESS,
      id,
    }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_CREDIT_SCORE_REPORT_SETUP_DELETE_FAILURE,
      error,
    }
  }
}

/**
 * add Document checklist to state
 *
 * @param {*} payload
 */
function addApplicationColumnState(payload) {
  return (dispatch) => {
    dispatch(success(payload))
  }

  function success(payload) {
    return {
      type: configConstants.CONFIG_APPLICATION_COLUMN_STATE_ADD_SUCCESS,
      data: payload,
    }
  }
}

/**
 * add Document checklist to state for Report columns
 *
 * @param {*} payload
 */
function addReportColumnState(payload) {
  return (dispatch) => {
    dispatch(success(payload))
  }

  function success(payload) {
    return {
      type: configConstants.CONFIG_REPORT_COLUMN_STATE_ADD_SUCCESS,
      data: payload,
    }
  }
}

/**
 * get Application Colums
 *
 * @param {*} surveyUuid
 */
function getApplicationColumns(surveyUuid) {
  return (dispatch) => {
    dispatch(request())
    configService.getApplicationColumns(surveyUuid).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error("Fetching Application Columns failed: ", error)
        )
      }
    )
  }

  function request() {
    return { type: configConstants.CONFIG_APPLICATION_COLUMN_GET_REQUEST }
  }
  function success(data) {
    return { type: configConstants.CONFIG_APPLICATION_COLUMN_GET_SUCCESS, data }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_APPLICATION_COLUMN_GET_FAILURE,
      error,
    }
  }
}

/**
 * get Report Colums
 *
 * @param {*} surveyUuid
 */
function getReportColumns(surveyUuid) {
  return (dispatch) => {
    dispatch(request())
    configService.getReportColumns(surveyUuid).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Fetching Report Columns failed: ", error))
      }
    )
  }

  function request() {
    return { type: configConstants.CONFIG_REPORT_COLUMN_GET_REQUEST }
  }
  function success(data) {
    return { type: configConstants.CONFIG_REPORT_COLUMN_GET_SUCCESS, data }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_REPORT_COLUMN_GET_FAILURE,
      error,
    }
  }
}

/**
 * add Application Colums
 *
 * @param {*} payload
 */
function addApplicationColumn(payload) {
  return (dispatch) => {
    dispatch(request())
    configService.addApplicationColumn(payload).then(
      (data) => {
        dispatch(success(data))
        dispatch(alertActions.success("Add successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Add failed: ", error))
      }
    )
  }

  function request() {
    return { type: configConstants.CONFIG_APPLICATION_COLUMN_SAVE_REQUEST }
  }
  function success(data) {
    return {
      type: configConstants.CONFIG_APPLICATION_COLUMN_SAVE_SUCCESS,
      data,
    }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_APPLICATION_COLUMN_SAVE_FAILURE,
      error,
    }
  }
}

/**
 * add Report Colums
 *
 * @param {*} payload
 */
function addReportColumn(payload) {
  return (dispatch) => {
    dispatch(request())
    configService.addReportColumn(payload).then(
      (data) => {
        dispatch(success(data))
        dispatch(alertActions.success("Add successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Add failed: ", error))
      }
    )
  }

  function request() {
    return { type: configConstants.CONFIG_REPORT_COLUMN_SAVE_REQUEST }
  }
  function success(data) {
    return {
      type: configConstants.CONFIG_REPORT_COLUMN_SAVE_SUCCESS,
      data,
    }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_REPORT_COLUMN_SAVE_FAILURE,
      error,
    }
  }
}

/**
 * edit APPLICATION Column
 *
 * @param {*} payload
 */
function editApplicationColumn(id, payload) {
  return (dispatch) => {
    dispatch(request())
    configService.editApplicationColumn(id, payload).then(
      (data) => {
        dispatch(success(data))
        dispatch(alertActions.success("Edit successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Edit failed: ", error))
      }
    )
  }

  function request() {
    return { type: configConstants.CONFIG_APPLICATION_COLUMN_EDIT_REQUEST }
  }
  function success(data) {
    return {
      type: configConstants.CONFIG_APPLICATION_COLUMN_EDIT_SUCCESS,
      data,
    }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_APPLICATION_COLUMN_EDIT_FAILURE,
      error,
    }
  }
}

/**
 * edit REPORT Column
 *
 * @param {*} payload
 */
function editReportColumn(id, payload) {
  return (dispatch) => {
    dispatch(request())
    configService.editReportColumn(id, payload).then(
      (data) => {
        dispatch(success(data))
        dispatch(alertActions.success("Edit successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Edit failed: ", error))
      }
    )
  }

  function request() {
    return { type: configConstants.CONFIG_REPORT_COLUMN_EDIT_REQUEST }
  }
  function success(data) {
    return {
      type: configConstants.CONFIG_REPORT_COLUMN_EDIT_SUCCESS,
      data,
    }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_REPORT_COLUMN_EDIT_FAILURE,
      error,
    }
  }
}

/**
 * Delete Application Column
 *
 * @param {*} id
 */
function deleteApplicationColumn(id) {
  return (dispatch) => {
    dispatch(request())
    try {
      configService.deleteApplicationColumn(id).then(
        () => {
          dispatch(success(id))
          dispatch(alertActions.success("Delete succesful"))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(alertActions.error("Delete failed: ", error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request() {
    return { type: configConstants.CONFIG_APPLICATION_COLUMN_DELETE_REQUEST }
  }
  function success(id) {
    return {
      type: configConstants.CONFIG_APPLICATION_COLUMN_DELETE_SUCCESS,
      id,
    }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_APPLICATION_COLUMN_DELETE_FAILURE,
      error,
    }
  }
}

/**
 * Delete Report Column
 *
 * @param {*} id
 */
function deleteReportColumn(id) {
  return (dispatch) => {
    dispatch(request())
    try {
      configService.deleteReportColumn(id).then(
        () => {
          dispatch(success(id))
          dispatch(alertActions.success("Delete succesful"))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(alertActions.error("Delete failed: ", error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request() {
    return { type: configConstants.CONFIG_REPORT_COLUMN_DELETE_REQUEST }
  }
  function success(id) {
    return {
      type: configConstants.CONFIG_REPORT_COLUMN_DELETE_SUCCESS,
      id,
    }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_REPORT_COLUMN_DELETE_FAILURE,
      error,
    }
  }
}

/**
 * get profile fields
 *
 * @param {*} surveyUuid
 */
function getProfileFields(surveyUuid) {
  return (dispatch) => {
    dispatch(request())
    configService.getProfileFields(surveyUuid).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Fetching profile fields failed: ", error))
      }
    )
  }

  function request() {
    return { type: configConstants.CONFIG_PROFIE_FIELD_GET_REQUEST }
  }
  function success(data) {
    return { type: configConstants.CONFIG_PROFIE_FIELD_GET_SUCCESS, data }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_PROFIE_FIELD_GET_FAILURE,
      error,
    }
  }
}

/**
 * add profile fields
 *
 * @param {*} payload
 */
function addProfileField(payload) {
  return (dispatch) => {
    dispatch(request())
    configService.addProfileField(payload).then(
      (data) => {
        dispatch(success(data))
        dispatch(alertActions.success("Add successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Add failed: ", error))
      }
    )
  }

  function request() {
    return { type: configConstants.CONFIG_PROFIE_FIELD_SAVE_REQUEST }
  }
  function success(data) {
    return {
      type: configConstants.CONFIG_PROFILE_FIELD_SAVE_SUCCESS,
      data,
    }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_PROFILE_FIELD_SAVE_FAILURE,
      error,
    }
  }
}

/**
 * edit profile fields
 *
 * @param {*} payload
 */
function editProfieFields(id, payload) {
  return (dispatch) => {
    dispatch(request())
    configService.editProfieFields(id, payload).then(
      (data) => {
        dispatch(success(data))
        dispatch(alertActions.success("Edit successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Edit failed: ", error))
      }
    )
  }

  function request() {
    return { type: configConstants.CONFIG_PROFILE_FIELD_EDIT_REQUEST }
  }
  function success(data) {
    return {
      type: configConstants.CONFIG_PROFILE_FIELD_EDIT_SUCCESS,
      data,
    }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_PROFILE_FIELD_EDIT_FAILURE,
      error,
    }
  }
}

/**
 * Delete profile fields
 *
 * @param {*} id
 */
function deleteProfileField(id) {
  return (dispatch) => {
    dispatch(request())
    try {
      configService.deleteProfileField(id).then(
        () => {
          dispatch(success(id))
          dispatch(alertActions.success("Delete succesful"))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(alertActions.error("Delete failed: ", error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request() {
    return { type: configConstants.CONFIG_PROFILE_FIELD_DELETE_REQUEST }
  }
  function success(id) {
    return {
      type: configConstants.CONFIG_PROFILE_FIELD_DELETE_SUCCESS,
      id,
    }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_PROFILE_FIELD_DELETE_FAILURE,
      error,
    }
  }
}

/**
 * get CBS fields
 *
 * @param {*} surveyUuid
 */
function getCbsFields(surveyUuid) {
  return (dispatch) => {
    dispatch(request())
    configService.getCbsFields(surveyUuid).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Fetching CBS fields failed: ", error))
      }
    )
  }

  function request() {
    return { type: configConstants.CONFIG_CBS_FIELD_GET_REQUEST }
  }
  function success(data) {
    return { type: configConstants.CONFIG_CBS_FIELD_GET_SUCCESS, data }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_CBS_FIELD_GET_FAILURE,
      error,
    }
  }
}

/**
 * add CBS fields
 *
 * @param {*} payload
 */
function addCbsField(payload) {
  return (dispatch) => {
    dispatch(request())
    configService.addCbsField(payload).then(
      (data) => {
        dispatch(success(data))
        dispatch(alertActions.success("Add successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Add failed: ", error))
      }
    )
  }

  function request() {
    return { type: configConstants.CONFIG_CBS_FIELD_SAVE_REQUEST }
  }
  function success(data) {
    return {
      type: configConstants.CONFIG_CBS_FIELD_SAVE_SUCCESS,
      data,
    }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_CBS_FIELD_SAVE_FAILURE,
      error,
    }
  }
}

/**
 * edit CBS field
 *
 * @param {*} payload
 */
function editCbsField(id, payload) {
  return (dispatch) => {
    dispatch(request())
    configService.editCbsField(id, payload).then(
      (data) => {
        dispatch(success(data))
        dispatch(alertActions.success("Edit successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Edit failed: ", error))
      }
    )
  }

  function request() {
    return { type: configConstants.CONFIG_CBS_FIELD_EDIT_REQUEST }
  }
  function success(data) {
    return {
      type: configConstants.CONFIG_CBS_FIELD_EDIT_SUCCESS,
      data,
    }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_CBS_FIELD_EDIT_FAILURE,
      error,
    }
  }
}

/**
 * Delete CBS field
 *
 * @param {*} id
 */
function deleteCbsField(id) {
  return (dispatch) => {
    dispatch(request())
    try {
      configService.deleteCbsField(id).then(
        () => {
          dispatch(success(id))
          dispatch(alertActions.success("Delete succesful"))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(alertActions.error("Delete failed: ", error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request() {
    return { type: configConstants.CONFIG_CBS_FIELD_DELETE_REQUEST }
  }
  function success(id) {
    return {
      type: configConstants.CONFIG_CBS_FIELD_DELETE_SUCCESS,
      id,
    }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_CBS_FIELD_DELETE_FAILURE,
      error,
    }
  }
}

/**
 * get First Comments
 *
 * @param {*} surveyUuid
 */
function getFirstComments(surveyUuid) {
  return (dispatch) => {
    dispatch(request())
    configService.getFirstComments(surveyUuid).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Fetching first comments failed: ", error))
      }
    )
  }

  function request() {
    return { type: configConstants.CONFIG_GET_FIRST_COMMENTS_REQUEST }
  }
  function success(data) {
    return { type: configConstants.CONFIG_GET_FIRST_COMMENTS_SUCCESS, data }
  }
  function failure(error) {
    return { type: configConstants.CONFIG_GET_FIRST_COMMENTS_FAILURE, error }
  }
}

/**
 * Add first comment
 *
 * @param {*} data
 */
function addFirstComment(data) {
  return (dispatch) => {
    dispatch(request())
    try {
      configService.addFirstComment(data).then(
        (data) => {
          dispatch(success(data))
          dispatch(alertActions.success("Added succesful"))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(alertActions.error("Request failed: ", error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request() {
    return { type: configConstants.CONFIG_ADD_FIRST_COMMENT_REQUEST }
  }
  function success(data) {
    return { type: configConstants.CONFIG_ADD_FIRST_COMMENT_SUCCESS, data }
  }
  function failure(error) {
    return { type: configConstants.CONFIG_ADD_FIRST_COMMENT_FAILURE, error }
  }
}

/**
 * edit first comment
 *
 * @param {*} surveyUuid
 */
function editFirstComment(surveyUuid, data) {
  return (dispatch) => {
    dispatch(request())
    try {
      configService.editFirstComment(surveyUuid, data).then(
        (data) => {
          dispatch(success(data))
          dispatch(alertActions.success("Edit succesful"))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(alertActions.error("Request failed: ", error))
        }
      )
    } catch (error) {
      dispatch(failure(error))
    }
  }

  function request() {
    return { type: configConstants.CONFIG_EDIT_FIRST_COMMENT_REQUEST }
  }
  function success(data) {
    return { type: configConstants.CONFIG_EDIT_FIRST_COMMENT_SUCCESS, data }
  }
  function failure(error) {
    return { type: configConstants.CONFIG_EDIT_FIRST_COMMENT_FAILURE, error }
  }
}

/**
 * delete First Comments
 *
 * @param {*} surveyUuid
 */
function deleteFirstComments(id) {
  return (dispatch) => {
    dispatch(request())
    configService.deleteFirstComments(id).then(
      (data) => {
        dispatch(success(data))
        dispatch(alertActions.success("Delete successful"))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Delete first comments failed: ", error))
      }
    )
  }

  function request() {
    return { type: configConstants.CONFIG_DELETE_FIRST_COMMENT_REQUEST }
  }
  function success(data) {
    return { type: configConstants.CONFIG_DELETE_FIRST_COMMENT_SUCCESS, data }
  }
  function failure(error) {
    return { type: configConstants.CONFIG_DELETE_FIRST_COMMENT_FAILURE, error }
  }
}

/**
 * get reports question
 *
 * @param {*} surveyUuid
 */
function getReportQuestion(surveyUuid) {
  return (dispatch) => {
    dispatch(request())
    configService.getReportQuestion(surveyUuid).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error))
      }
    )
  }

  function request() {
    return { type: configConstants.GET_REPORTS_QUESTION_REQUEST }
  }
  function success(data) {
    return { type: configConstants.GET_REPORTS_QUESTION_SUCCESS, data }
  }
  function failure(error) {
    return { type: configConstants.GET_REPORTS_QUESTION_FAILURE, error }
  }
}

/**
 * get reports question
 *
 * @param {*} surveyUuid
 */
function getProfileQuestion(id) {
  return (dispatch) => {
    dispatch(request())
    configService.getProfileQuestion(id).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error))
      }
    )
  }

  function request() {
    return { type: configConstants.GET_CUSTOMER_PROFILE_QUESTION_REQUEST }
  }
  function success(data) {
    return { type: configConstants.GET_CUSTOMER_PROFILE_QUESTION_SUCCESS, data }
  }
  function failure(error) {
    return {
      type: configConstants.CONFIG_APPLICATION_COLUMN_DELETE_FAILURE,
      error,
    }
  }
}
/**
 * update Applicant data to state
 *
 * @param {*} payload
 */
function updateApplicantGender(payload) {
  return (dispatch) => {
    dispatch(success(payload))
  }
  function success(payload) {
    return {
      type: configConstants.UPDATE_APPLICATION_GENDER_STATE,
      data: payload,
    }
  }
}

function updateApplicantAge(payload) {
  return (dispatch) => {
    dispatch(success(payload))
  }
  function success(payload) {
    return {
      type: configConstants.UPDATE_APPLICATION_AGE_STATE,
      data: payload,
    }
  }
}

function updateApplicantMaritalStatus(payload) {
  return (dispatch) => {
    dispatch(success(payload))
  }
  function success(payload) {
    return {
      type: configConstants.UPDATE_APPLICATION_MARITAL_STATUS_STATE,
      data: payload,
    }
  }
}

function updateApplicantLoan(payload) {
  return (dispatch) => {
    dispatch(success(payload))
  }
  function success(payload) {
    return {
      type: configConstants.UPDATE_APPLICATION_LOAN_STATE,
      data: payload,
    }
  }
}

/**
 * update new Applicant data to state
 *
 * @param {*} payload
 */
function updateNewApplicant(payload) {
  return (dispatch) => {
    dispatch(success(payload))
  }
  function success(payload) {
    return { type: configConstants.UPDATE_NEW_APPLICATION_STATE, data: payload }
  }
}

function updateLandSize(payload) {
  return (dispatch) => {
    dispatch(success(payload))
  }
  function success(payload) {
    return { type: configConstants.UPDATE_NEW_LAND_SIZE_STATE, data: payload }
  }
}

/**
 * add report question
 *
 */
function addReportQuestion() {
  return (dispatch, getState) => {
    const {
      applicantGenderGlobalState,
      applicantAgeGlobalState,
      applicantMaritalStatusGlobalState,
      applicantLoanGlobalState,
      newApplicantGlobalState,
      landSizeGlobalState,
      applicantProfileGlobalState,
    } = getState().config
    dispatch(request())
    const addReportQuestionDispatch = (payload) => {
      if (!payload) return
      return configService.addReportQuestion(payload).then(
        (data) => {
          dispatch(success(data))
          dispatch(alertActions.success("Submit successful"))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(alertActions.error(error))
        }
      )
    }
    const editReportQuestionDispatch = (payload) => {
      return configService.editReportQuestion(payload.id, payload).then(
        (data) => {
          dispatch(success(data))
          dispatch(alertActions.success("Submit successful"))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(alertActions.error(error))
        }
      )
    }

    const editProfileDispatch = (payload) => {
      return configService.editReportQuestion(payload.id, payload).then(
        (data) => {
          dispatch(success(data))
          dispatch(alertActions.success("Submit successful"))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(alertActions.error(error))
        }
      )
    }

    const addProfileDispatch = (payload) => {
      return configService.addProfileQuestion(payload).then(
        (data) => {
          dispatch(success(data))
          dispatch(alertActions.success("Submit successful"))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(alertActions.error(error))
        }
      )
    }

    if (applicantGenderGlobalState && applicantGenderGlobalState.id) {
      // remove some fields
      editReportQuestionDispatch(applicantGenderGlobalState)
    } else {
      addReportQuestionDispatch(applicantGenderGlobalState)
    }

    if (applicantAgeGlobalState && applicantAgeGlobalState.id) {
      //remove some fields
      editReportQuestionDispatch(applicantAgeGlobalState)
    } else {
      addReportQuestionDispatch(applicantAgeGlobalState)
    }

    if (
      applicantMaritalStatusGlobalState &&
      applicantMaritalStatusGlobalState.id
    ) {
      //remove some fields
      editReportQuestionDispatch(applicantMaritalStatusGlobalState)
    } else {
      addReportQuestionDispatch(applicantMaritalStatusGlobalState)
    }

    if (applicantLoanGlobalState && applicantLoanGlobalState.id) {
      //remove some fields
      editReportQuestionDispatch(applicantLoanGlobalState)
    } else {
      addReportQuestionDispatch(applicantLoanGlobalState)
    }

    if (applicantProfileGlobalState) {
      // remove some fields
      addProfileDispatch(applicantProfileGlobalState)
    } else {
      editProfileDispatch(applicantProfileGlobalState)
    }

    if (landSizeGlobalState && landSizeGlobalState.id) {
      // remove some fields
      editReportQuestionDispatch(landSizeGlobalState)
    } else {
      addReportQuestionDispatch(landSizeGlobalState)
    }

    if (newApplicantGlobalState && newApplicantGlobalState.id) {
      // remove some fields
      editReportQuestionDispatch(newApplicantGlobalState)
    } else {
      addReportQuestionDispatch(newApplicantGlobalState)
    }
  }

  function request() {
    return { type: configConstants.ADD_REPORT_QUESTION_REQUEST }
  }
  function success(data) {
    return { type: configConstants.ADD_REPORT_QUESTION_SUCCESS, data }
  }
  function failure(error) {
    return { type: configConstants.ADD_REPORT_QUESTION_FAILURE, error }
  }
}

/**
 * update Applicant data to state
 *
 * @param {*} payload
 */
function updateApplicantCustomerProfile(payload) {
  return (dispatch) => {
    dispatch(success(payload))
  }
  function success() {
    return {
      type: configConstants.UPDATE_APPLICATION_CUSTOMER_PROFILE_STATE,
      data: payload,
    }
  }
}

/**
 * update new Applicant data to state
 *
 * @param {*} payload
 */
function updateNewApplicantCustomerProfile(payload) {
  return (dispatch) => {
    dispatch(success(payload))
  }
  function success(payload) {
    return {
      type: configConstants.UPDATE_NEW_APPLICATION_CUSTOMER_PROFILE_STATE,
      data: payload,
    }
  }
}

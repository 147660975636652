/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { surveyActions } from "../../surveys"
import { userManagerActions } from "../../userManager"
import { authenticationActions } from "../../authentication"
import { applicationActions } from ".."

import { Container } from "shards-react"
import { ComponentSwitch } from "./ComponentSwitch"

export const PaymentApplication = () => {
  const applications = useSelector((state) => state.applications)
  // const surveys = useSelector(state => state.surveys);
  const authentication = useSelector((state) => state.authentication)
  const dispatch = useDispatch()

  useEffect(() => {
    // set default page to ListApplication
    dispatch(
      applicationActions.applicationClicked({
        page: "ListApplicationPayment",
        history: false,
      })
    )
  }, [])

  if (applications.applicationClicked.page !== "ListApplicationPayment")
    return null
  return (
    <Container fluid className="main-content-container px-4">
      <ComponentSwitch tag={applications.applicationClicked.page} />
    </Container>
  )
}

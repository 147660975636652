export const loanDistributed = {
  name: "loan_distributed",
  label: "Loan Distributed",
}

export const farmMonitoring = {
  name: "farm_monitoring",
  label: "Farm Monitoring",
}

export const checklist = {
  name: "checklist",
  label: "checklist",
}

export const cashFlowAnalysis = {
  name: "cashflow_analysis",
  label: "Cash Flow Analysis",
}

export const climateProfile = {
  name: "climate_profile",
  label: "Climate Risk Profile",
}

export const viewProfile = {
  name: "view_profile",
  label: "View Profile",
}

export const shortenedViewData = {
  name: "shortened_view_data",
  label: "Shortened View Data",
}

export const templates = [
  loanDistributed,
  farmMonitoring,
  checklist,
  cashFlowAnalysis,
  climateProfile,
  viewProfile,
  shortenedViewData,
]

import React, { useState } from "react"
import { useDispatch } from "react-redux"
import Svg from "react-inlinesvg"
import XLSX from "xlsx"
import { ButtonGroup, Button } from "shards-react"
import Header from "../../../components/widget/widgetTitle"
import { modalActions } from "../../modals/_actions"
import { SetUploadFile } from "../../tools/components/SetUploadFile"
import "./ExportApplications.scss"
import { applicationActions } from "../_actions"
const icon = require("../../../assets/images/icons/export-options-icon.svg")

const PAYMENTS = [
  "receptionDate",
  "cwsName",
  "farmerName",
  "mobileNo",
  "farmerCode",
  "coffeeGrade",
  "grnNo",
  "quantity",
  "priceKg",
  "purchaseAmount",
  "commission",
  "totalAmount",
  "deduction",
  "amountToDisburse",
  "fieldOfficer",
]

export function ImportPayments({ modalID, data }) {
  const [uploadFile, setUploadFile] = useState()
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  const uploadFileHandler = (file) => {
    setUploadFile(file)
  }

  const readXlsxFile = () => {
    const reader = new FileReader()
    reader.onload = (event) => {
      const binaryStr = event.target.result
      const workbook = XLSX.read(binaryStr, { type: "binary" })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const data = XLSX.utils.sheet_to_json(worksheet)

      // assuming the first row in the sheet contains the headers
      const headers = Object.keys(data[0])

      const payload = data.map((row) =>
        headers.reduce((obj, header, index) => {
          obj[PAYMENTS[index]] = row[header]
          return obj
        }, {})
      )
      dispatch(applicationActions.uploadPayments(payload))
      closeModal()
    }
    reader.readAsBinaryString(uploadFile)
  }

  return (
    <div className="site-modal">
      <Header
        title={"Import Payments via Excel"}
        icon={<Svg className="text-center d-inline-block" src={icon} />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        <div className="items-center">
          <SetUploadFile handler={uploadFileHandler} />
        </div>
        <ButtonGroup className="btn-group-actions site-modal__actions flex justify-end">
          <Button
            theme="primary"
            className="delete mr-2"
            onClick={readXlsxFile}
          >
            Import Payments
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

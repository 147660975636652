export default () => ({
  chartContainer: {
    padding: "1.5rem",
    paddingLeft: 0,
  },
  chartContainerNet: {
    padding: "1.5rem",
    height: 600,
  },
  noChartLoaded: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 200,
  },
  notAvailableText: {
    color: "#CACEDB",
  },
})

import React from 'react';
import { WithWizard } from 'react-albus';
import { connect } from 'react-redux';
import { Button } from 'shards-react';
import { useTranslation } from 'react-i18next';
import { cslActions } from '..';
import { alertActions } from '../../alert';
import { hasAuthority } from '../../../_helpers';
import { history } from '../../../_helpers';

function WizardActions({ scoreLogic, dispatch }) {
    const { uid } = scoreLogic;
    const disable = !uid ? 'disabled' : '';
    const { t } = useTranslation();

    const saveData = (step, next) => {
        dispatch(cslActions.submitData(step, scoreLogic));
        next();
    };

    const saveFinal = async (step) => {
        dispatch(alertActions.success(t('module.scoreLogic.component.wizardActions.previous')));
        await dispatch(cslActions.submitData(step, scoreLogic));
        history.push('/');
    };

    const sync = () => {
        dispatch(cslActions.syncDocumentChecklist(uid));
    };

    return (
        <WithWizard
            render={({ next, previous, step }) => {
                const { id } = step;
                return (
                    <div key={id} className="wizard-actions">
                        { id !== 'questionnaire' && <Button className="prev" onClick={previous} disabled={ disable }>
                            {t('module.scoreLogic.component.wizardActions.previous')}</Button> }
                        { id === 'documentChecklist' && <Button className="prev" onClick={sync} disabled={ disable }>{t('module.scoreLogic.component.wizardActions.sync')}</Button> }
                        { (id === 'approvalWorkflow' || id === 'scoreCard')
                          ? <Button className="next" onClick={ () => { if (id === 'scoreCard' &&
                            hasAuthority('MFI_ADMIN')) { saveFinal(step); } else {
                                saveData(step, next); } } } disabled={ disable }> {(id === 'scoreCard' &&
                                hasAuthority('MFI_ADMIN')) ?
                                `${t('module.scoreLogic.component.wizardActions.saveClose')}` : `${t('module.scoreLogic.component.wizardActions.saveContinue')}`} </Button>
                          : id !== 'scoreLogicTest' && <Button className="next" onClick={ () => next() } disabled={ disable }>
                              {t('module.scoreLogic.component.wizardActions.continue')}</Button>
                        }
                        { id === 'scoreLogicTest' && <Button className="next" onClick={ () => { saveFinal(step); } } disabled={ disable }>
                        {t('module.scoreLogic.component.wizardActions.saveClose')}</Button> }
                    </div>
                );
            } }
        />
    );
}

function mapStateToProps(state) {
    const { scoreLogic } = state;
    return {
        scoreLogic,
    };
}

const connectedWizardActions = connect(mapStateToProps)(WizardActions);
export { connectedWizardActions as WizardActions };

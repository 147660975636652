import React, { useState, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Select, MenuItem } from "@material-ui/core"
import { Link } from "react-router-dom"
import { Container, Row, Button } from "shards-react"
import { withStyles } from "@material-ui/core/styles"
import { AgGridReact } from "ag-grid-react"
import cx from "classnames"
import { camelCase, isEmpty } from "lodash"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import SearchIcon from "@material-ui/icons/Search"
import { useSelector, useDispatch } from "react-redux"
import PageTitle from "../../../components/common/PageTitle"
import { CSVLink } from "react-csv"
import { LoadingView } from "../../../views"
import styles from "./ListPayment.styles"
import { alertActions } from "../../alert"
import { surveyActions } from "../../surveys"
import { modalActions } from "../../modals"
import { applicationActions } from "../_actions"
import { FilterApplicationQuestionnaire } from "../components/filterQuestionnaire"
import "./Members.scss"
import { formatDate } from "../../../utils/FormatDate"

export const GroupsApplication = withStyles(styles)(({ classes }) => {
  const authentication = useSelector((state) => state.authentication)
  const applications = useSelector((state) => state.applications)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { applicationColumns, loading, usersToAssign } = applications
  const {
    profile: { username },
  } = authentication

  const approvalUsers = !isEmpty(usersToAssign)
    ? usersToAssign.forward.map((user) => user.username)
    : []

  useEffect(() => {
    if (username) dispatch(applicationActions.fetchUsersToAssign(username))
  }, [dispatch, username])

  let initialGroupFieldsHeaders =
    applications?.items?.filter(
      (item) =>
        (item.currentUser === username && !item.subRoleStageFinal) ||
        approvalUsers.includes(item.currentUser)
    ).length > 0 &&
    Object.entries(
      applications?.items?.filter(
        (item) =>
          (item.currentUser === username && !item.subRoleStageFinal) ||
          approvalUsers.includes(item.currentUser)
      )[0].json
    )
      .filter(([key, value]) => {
        try {
          const parsedValue = JSON.parse(value)
          return !Array.isArray(parsedValue) // Include if not an array
        } catch (error) {
          return true // Include if parsing fails
        }
      })
      .map(([key, value]) => ({ headerName: key, field: camelCase(key) }))

  let initialGroupFieldsValues = applications?.items
    ?.filter(
      (item) =>
        (item.currentUser === username && !item.subRoleStageFinal) ||
        approvalUsers.includes(item.currentUser)
    )
    .map((item) => {
      // return all fields that are not a representation of array from application columns -> holds group level information
      const filteredData = Object.entries(item.json)
        .filter(([key, value]) => {
          try {
            const parsedValue = JSON.parse(value)
            return !Array.isArray(parsedValue) // Include if not an array
          } catch (error) {
            return true // Include if parsing fails
          }
        })
        .map(([key, value]) => ({ [camelCase(key)]: value }))
      return filteredData
    })

  const membersInfo = applications?.items
    ?.filter(
      (item) =>
        (item.currentUser === username && !item.subRoleStageFinal) ||
        approvalUsers.includes(item.currentUser)
    )
    ?.map((item) => {
      // return all fields that is a representation of array from application columns -> Holds the repeat group with members information
      const filteredData = Object.entries(item.json)
        .filter(([key, value]) => {
          try {
            const parsedValue = JSON.parse(value)
            return Array.isArray(parsedValue) // Include if it is an array
          } catch (error) {
            return false // exclude if parsing fails
          }
        })
        .map(([key, value]) => JSON.parse(value))
      return filteredData
    })
    .flat()

  if (initialGroupFieldsHeaders) {
    const totalMembers = { headerName: "Total Members", field: "totalMembers" }
    initialGroupFieldsHeaders = [...initialGroupFieldsHeaders, totalMembers]
  }

  if (initialGroupFieldsValues && membersInfo) {
    initialGroupFieldsValues.forEach((field, index) => {
      field.push({ totalMembers: membersInfo[index]?.length })
    })
  }

  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState("")
  const [searchInput, setSearchInput] = useState("")

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value)
  }

  /* const handleSearchIconClick = () => {
    filterData()
  } */

  const viewMembersRenderer = (params) => {
    const { data, rowIndex } = params
    const {
      groupName,
      submittedBy,
      groupID,
      workflowStatus,
      subRoleStageFinal,
      stage,
    } = data
    const dateCreated = formatDate(
      new Date(
        applications?.items?.filter(
          (item) =>
            (item.currentUser === username && !item.subRoleStageFinal) ||
            approvalUsers.includes(item.currentUser)
        )[rowIndex].submissionTime
      ),
      "y-m-d"
    )
    const groupMembersInfo =
      membersInfo &&
      membersInfo[rowIndex]?.map((raw) => {
        let individualMember = { dateCreated }
        Object.entries(raw).forEach(([key, value]) => {
          applicationColumns.forEach((column) => {
            const memberKeyParts = column.questionKey.split("/")
            const memberKeyEnd = memberKeyParts[memberKeyParts.length - 1]
            if (key.endsWith(memberKeyEnd)) {
              let memberValue = value.trim()
              if (memberValue === "0") {
                memberValue = "Male"
              } else if (memberValue === "1") {
                memberValue = "Female"
              }
              individualMember = {
                ...individualMember,
                [camelCase(column.columnTitle)]: memberValue,
              }
            }
          })
        })
        return individualMember
      })
    const groupGeneralInfo = {
      groupName,
      clientOfficer: submittedBy,
    }
    return (
      <Link
        to={{
          pathname: "/members",
          state: {
            groupGeneralInfo,
            groupMembersInfo,
            groupID,
            workflowStatus,
            subRoleStageFinal,
            stage,
          },
        }}
      >
        View Members
      </Link>
    )
  }

  const columnDefs = [
    { headerName: "Group ID", field: "groupID" },
    { headerName: "Date Created", field: "dateCreated", sortable: true },
    { headerName: "Stage", field: "stage" },
    { headerName: "Current User", field: "currentUser" },
    { headerName: "Client Officer", field: "submittedBy" },
    { headerName: "Workflow Status", field: "workflowStatus" },
    { headerName: "Subrole Stage", field: "subRoleStageFinal", hide: true },
    {
      headerName: "View Members",
      field: "view",
      cellRenderer: viewMembersRenderer,
      pinned: "right",
      resizable: false,
      sortable: false,
      filter: false,
    },
  ]

  if (initialGroupFieldsHeaders) {
    initialGroupFieldsHeaders.forEach((field) => {
      columnDefs.push(field)
    })
  }

  let columnData = applications?.items
    ?.filter(
      (item) =>
        (item.currentUser === username && !item.subRoleStageFinal) ||
        approvalUsers.includes(item.currentUser)
    )
    .map((item) => {
      return {
        groupID: item.id,
        dateCreated: formatDate(new Date(item.submissionTime), "y-m-d"),
        stage: item.stage,
        currentUser: item.currentUser,
        workflowStatus: item.workflowStatus,
        subRoleStageFinal: item.subRoleStageFinal,
        submittedBy: item.submittedBy,
      }
    })

  if (initialGroupFieldsValues) {
    initialGroupFieldsValues.forEach((field, index) => {
      field.forEach((elem) => {
        columnData[index] = {
          ...columnData[index],
          ...elem,
        }
      })
    })
  }

  const [filteredData, setFilteredData] = useState(columnData || [])

  // Filter data based on searchInput
  const filterData = () => {
    return columnData?.filter((item) =>
      item.groupName?.toLowerCase().includes(searchInput.toLowerCase())
    )
  }

  // Update filteredData whenever searchInput changes
  useEffect(() => {
    setFilteredData(filterData())
  }, [searchInput])

  // Update filteredData whenever applications changes
  useEffect(() => {
    setFilteredData(columnData)
  }, [applications])

  // set applications items to [] if selectedQuestionnaire is empty
  useEffect(() => {
    if (!selectedQuestionnaire) {
      setFilteredData([])
    }
  }, [selectedQuestionnaire])

  useEffect(() => {
    if (authentication.profile.mfi) {
      dispatch(surveyActions.getSurveys(authentication.profile.mfi.id))
    }
  }, [authentication.profile])

  useEffect(() => {
    selectedQuestionnaire &&
      dispatch(
        applicationActions.getApplicationBySurveyUuid(selectedQuestionnaire)
      )
  }, [selectedQuestionnaire])

  useEffect(() => {
    selectedQuestionnaire &&
      dispatch(applicationActions.getApplicationColumns(selectedQuestionnaire))
  }, [selectedQuestionnaire, dispatch])

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
    }),
    []
  )

  const selectQuestionnaire = (e) => {
    const { value } = e.target
    setSelectedQuestionnaire(value)
  }

  return (
    <Container maxWidth="xl" fluid className="main-content-container px-4">
      <Row noGutters className="page-header pt-4 flex">
        <PageTitle sm="4" title={"Groups"} className="text-sm-left" />
      </Row>
      <Row noGutters className="page-header pt-4 flex">
        <FilterApplicationQuestionnaire
          handler={selectQuestionnaire}
          selectedValue={selectedQuestionnaire}
          groupView
        />
        <div className="application__actions ml-auto flex content-center">
          <Select
            classes={{ root: classes.selectRoot }}
            variant="outlined"
            value="Download Groups"
            className={cx("ml-2", {
              [classes.selectDisabled]: filteredData?.length === 0,
            })}
          >
            <MenuItem value="Download Groups">Download Groups</MenuItem>

            <CSVLink
              className="hover:no-underline"
              data={filteredData || []}
              filename={"groups.csv"}
              onClick={() => dispatch(alertActions.success("Downloading..."))}
            >
              <MenuItem value="csv">csv</MenuItem>
            </CSVLink>

            <CSVLink
              className="hover:no-underline"
              data={filteredData || []}
              filename={"groups.xlsx"}
              onClick={() => dispatch(alertActions.success("Downloading..."))}
            >
              <MenuItem value="xlsx">xlsx</MenuItem>
            </CSVLink>
          </Select>
        </div>
      </Row>
      {loading ? (
        <LoadingView />
      ) : (
        <div
          className="ag-theme-alpine"
          style={{ height: "600px", width: "100%", marginTop: "20px" }}
        >
          <AgGridReact
            rowData={filteredData || []}
            columnDefs={columnDefs}
            pagination={true}
            paginationAutoPageSize={true}
            suppressRowClickSelection={true}
            stopEditingWhenGridLosesFocus={true}
            defaultColDef={defaultColDef}
          />
        </div>
      )}
    </Container>
  )
})

export default GroupsApplication

/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Svg from "react-inlinesvg"
import { Grid, Divider } from "@material-ui/core"
import { ButtonGroup, Button } from "shards-react"
import currency from "currency.js"
import Header from "../../../components/widget/widgetTitle"

import { modalActions } from "../../modals"
import { toolsActions } from "../../tools"
import { workflowActions } from "../../workflow"
import "./PayDecline.scss"
import { useTranslation } from "react-i18next"
import { ErrorsView, LoadingView } from "../../../views"
const headerSVG = require("../../../assets/images/icons/header-icon.svg")

const ApprovePayCollection = ({ modalID, data }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const authentication = useSelector((store) => store.authentication)
  const { loading, error } = useSelector((state) => state.applications)
  const {
    profile: {
      mfi: { currency: mfiCurrency },
    },
  } = authentication

  const { payload, selectedQuestionnaire } = data

  const handeSubmit = () => {
    const repaymentData = payload.map((item) => ({
      farmerCode: item.farmerCode,
      transactionAmount: item.transactionAmount,
      mfiName: item.mfiName,
    }))
    const collectionIds = payload.map((item) => item.id)
    dispatch(
      toolsActions.repayMusoniLoan(
        repaymentData,
        collectionIds,
        selectedQuestionnaire
      )
    )
    closeModal()
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  return (
    <div className="site-modal">
      <Header
        title="Approve Confirmation"
        icon={<Svg className="text-center d-inline-block" src={headerSVG} />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        {loading && <LoadingView />}
        {error && <ErrorsView />}
        {!loading && !error && (
          <p>Are you sure you want to repay the following collections:</p>
        )}
        {!loading &&
          !error &&
          payload.map((data) => (
            <>
              <Grid container className="mb-2">
                <Grid item sm={12}>
                  <Grid container>
                    <Grid item sm={4} className="font-bold">
                      Name:
                    </Grid>
                    <Grid item sm={6}>
                      {data.clientName}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item sm={4} className="font-bold">
                      NRC No:
                    </Grid>
                    <Grid item sm={6}>
                      {data.farmerCode}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item sm={4} className="font-bold">
                      Collection Amount:
                    </Grid>
                    <Grid item sm={6}>
                      {mfiCurrency} {currency(data.transactionAmount).format()}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider className="mb-4" />
            </>
          ))}

        <ButtonGroup className="btn-group-actions site-modal__actions">
          <Button
            theme="primary"
            className="thin mr-2"
            onClick={() => {
              handeSubmit()
            }}
          >
            Confirm
          </Button>
          <Button
            theme="cancel"
            className="thin delete"
            onClick={() => {
              closeModal()
            }}
          >
            {t("applicationDisburse.cancel")}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default ApprovePayCollection

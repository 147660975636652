import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Row, Container, Button, FormSelect } from "shards-react"
import {
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { withStyles } from "@material-ui/core/styles"
import { userRoleConstants, hasAuthority } from "../../../_helpers"
import PageTitle from "../../../components/common/PageTitle"
import { surveyActions } from "../../surveys"
import { alertActions } from "../../alert"
import { reportsActions } from ".."
import "./Overview.scss"
import styles from "./SummaryOverview.styles"

export const SummaryOverview = withStyles(styles)(({ classes }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { assets } = useSelector((store) => store.surveys)
  const { items: mfiList } = useSelector((store) => store.mfis)

  const [selectedValues, setSelectedValues] = useState([])
  const [checkedValue, setCheckedValue] = useState({})
  const [mfiId, setMfiId] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [surveyIds, setSurveyIds] = useState([])
  const [surveyNames, setSurveyNames] = useState([])

  useEffect(() => {
    mfiId && dispatch(surveyActions.getSurveys(mfiId))
  }, [mfiId, dispatch])

  const handleChange = (event, surveyId, surveyName) => {
    setCheckedValue({
      ...checkedValue,
      [event.target.name]: event.target.checked,
    })
    if (event.target.checked) {
      setSelectedValues([...selectedValues, event.target.name])
      setSurveyIds([...surveyIds, surveyId])
      setSurveyNames([...surveyNames, surveyName])
    } else {
      setSelectedValues(
        selectedValues.filter(
          (selectedValue) => event.target.name !== selectedValue
        )
      )
      setSurveyIds(surveyIds.filter((sId) => surveyId !== sId))
      setSurveyNames(surveyNames.filter((sn) => surveyName !== sn))
    }
  }

  const handleStartDateChange = (date) => {
    setStartDate(date)
  }
  const handleEndDateChange = (date) => {
    setEndDate(date)
  }

  const handleSummaryExport = () => {
    let payload = {
      format: "xlsx",
      surveyUuids: selectedValues,
      surveyIds,
      surveyNames,
    }

    if (
      hasAuthority(userRoleConstants.FA_ANALYST) ||
      hasAuthority(userRoleConstants.FA_ADMIN)
    ) {
      if (mfiId) {
        payload.mfiId = Number(mfiId)
      } else {
        dispatch(alertActions.error(t("module.report.selectMFIFirst")))
        return
      }
    }

    if (endDate) payload.endDate = endDate.toLocaleDateString("en-CA")
    if (startDate) payload.startDate = startDate.toLocaleDateString("en-CA")

    dispatch(reportsActions.exportSummaryReports(payload))
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={t("module.report.summary")}
          subtitle={t("module.report.dashboard")}
          className="text-sm-left mb-3"
        />
      </Row>
      <Grid container>
        <Grid item sm={12} className={classes.mfiSelectGrid}>
          {(hasAuthority(userRoleConstants.FA_ANALYST) ||
            hasAuthority(userRoleConstants.FA_ADMIN)) && (
            <FormSelect
              name="mfiName"
              size="sm"
              className={`mr-auto w-auto ${!mfiId && "select-error"}`}
              value={mfiId}
              onChange={(e) => setMfiId(e.currentTarget.value)}
            >
              <option key={-1} value="">
                {t("module.scoreLogic.view.scoreLogic.titles.selectMFI")}
              </option>
              {mfiList.map((item, idx) => (
                <option key={idx} value={item.id}>
                  {item.mfiName}
                </option>
              ))}
            </FormSelect>
          )}
        </Grid>
        <Grid item sm={5}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">
              {t("module.report.selectQuestionnaire")}
            </FormLabel>
            <FormGroup>
              {assets &&
                assets.map((questionnaire, idx) => {
                  const { id, uid, name, versionId } = questionnaire
                  return (
                    <FormControlLabel
                      key={idx}
                      control={
                        <Checkbox
                          checked={checkedValue[`${uid}:${versionId}`]}
                          onChange={(e) => handleChange(e, id, name)}
                          name={`${uid}:${versionId}`}
                          color="primary"
                        />
                      }
                      label={name}
                      classes={{ label: classes.controlLabel }}
                    />
                  )
                })}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item sm={5}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">
              {t("module.report.filterByDate")}
            </FormLabel>
          </FormControl>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
              <Grid item sm={12}>
                <KeyboardDatePicker
                  disableToolbar
                  disableFuture
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="start-date"
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  maxDate={endDate || new Date("2100 - 01 - 01")}
                />
              </Grid>
              <Grid item sm={12}>
                <KeyboardDatePicker
                  disableToolbar
                  disableFuture
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="end-date"
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  minDate={startDate || new Date("1900 - 01 - 01")}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item sm={2} />
        <Grid item sm={5} />
        <Grid item sm={5}>
          <Button
            theme="primary"
            className={`mx-2 btn ${classes.expBtn}`}
            disabled={
              selectedValues.length < 1 ||
              (startDate && !endDate) ||
              (!startDate && endDate)
            }
            onClick={() => handleSummaryExport()}
          >
            {t("module.report.exportReport")}
          </Button>
        </Grid>
        <Grid item sm={2} />
      </Grid>
    </Container>
  )
})

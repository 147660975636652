import { applicationEditorConstants, applicationEditorService } from "."
import { history } from "../../_helpers"
import { alertActions } from "../alert"

export const applicationEditorActions = {
  saveApplication,
  updateApplication,
}

/**
 * Save selected
 */
function saveApplication(inputs, mediaFiles, messages) {
  return (dispatch) => {
    dispatch(request({}))
    applicationEditorService.saveApplication(inputs, mediaFiles).then(
      () => {
        dispatch(success(inputs))
        dispatch(alertActions.success(messages.applicationSuccess))
        history.push("/applications")
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request(data) {
    return { type: applicationEditorConstants.APPLICATION_EDITOR_REQUEST, data }
  }
  function success(data) {
    return { type: applicationEditorConstants.APPLICATION_EDITOR_SUCCESS, data }
  }
  function failure(error) {
    return {
      type: applicationEditorConstants.APPLICATION_EDITOR_FAILURE,
      error,
    }
  }
}

/**
 * Save selected
 */
function updateApplication(id, inputs, messages) {
  return (dispatch) => {
    dispatch(request({}))
    applicationEditorService.updateApplicationForm(id, inputs).then(
      () => {
        dispatch(success(inputs))
        dispatch(alertActions.success(messages.editSuccess))
        history.push("/applications")
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request(data) {
    return { type: applicationEditorConstants.APPLICATION_EDITOR_REQUEST, data }
  }
  function success(data) {
    return { type: applicationEditorConstants.APPLICATION_EDITOR_SUCCESS, data }
  }
  function failure(error) {
    return {
      type: applicationEditorConstants.APPLICATION_EDITOR_FAILURE,
      error,
    }
  }
}

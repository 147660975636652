import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import PageTitle from '../../../../components/common/PageTitle';
import { FilterMfis } from '../../components/filterMfis';
import { authenticationActions } from '../../../authentication';
import { mfiManagerActions } from '../../../mfiManager';
import { pendingTasksActions } from './store/_actions';
import { EmptyContentView, LoadingView } from '../../../../views';
import { ClientErrorsView } from '../../components/ClientError';
import { PendingTasksList } from './pendingTasksList';
import { useTranslation } from 'react-i18next';

export const PendingTasks = () => {
    const user = useSelector((state) => state.authentication);
    const mfis = useSelector((state) => state.mfis);
    const pendingTasksResponse = useSelector((state) => state.pendingTasks);
    const { profile: { mfi, roles }} = user;
    const allMfis = (roles?.includes('FA_ANALYST') || roles?.includes('FA_ADMIN')) && mfis?.items;
    const dispatch = useDispatch();
    const { pendingTasks, loading, error, pendingTasksError } = pendingTasksResponse
    const [selectedMfi, setSelectedMfi] = useState({})
    const [state, setState] = useState({
        page: pendingTasks?.number || 0,
        perPage: 20
    });
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(authenticationActions.getProfile());
    }, [dispatch]);

    useEffect(() => {
        dispatch(mfiManagerActions.getAllMFIs());
    }, [dispatch]);

    useEffect(() => {
        (selectedMfi?.id || mfi?.id) !== undefined && dispatch(pendingTasksActions.getAllPendingTasks((selectedMfi?.id || mfi?.id), state?.perPage, state?.page, ));
    }, [dispatch, selectedMfi, mfi, state]);

    const selectQuestionnaire = (e) => {
        const { value } = e.target;
        const mfi = allMfis.filter(mfiItem => {
            return mfiItem.mfiName === value;
        })
        setSelectedMfi(mfi[0]);
    };

    const pendingTasksData = pendingTasks?.content?.map((pendingTask) => ({ ...{ action: '' }, ...pendingTask, ...{ '': '' } }));

    return (
        <Container fluid className="main-content-container px-4 pt-4">
            <Row noGutters className="page-header pt-4 flex">
                <PageTitle sm="4" title={t('module.client.overview.pendingTasks')} className="text-sm-left" />
            </Row>
            <Row noGutters className="page-header pt-4 flex">
                <FilterMfis handler={selectQuestionnaire} selectedValue={selectedMfi?.mfiName !== undefined ? selectedMfi : mfi} items={allMfis?.length > 0 ? allMfis : pendingTasks[0]} />
            </Row>
            <Row className="mt-4 clientList">
                <Col>
                    { loading && <LoadingView /> }
                    { error && pendingTasksError !== null && <ClientErrorsView errorData={pendingTasksError?.response?.data} /> }
                    { !loading && !error && isEmpty(pendingTasks?.content) && <EmptyContentView title="No Pending Tasks Found" /> }
                    {!loading &&
                        <>
                            <PendingTasksList
                                mfiId={selectedMfi?.id || mfi?.id}
                                data={pendingTasksData}
                                setState={setState}
                                state={state}
                                paginationData={pendingTasks}
                            />
                        </>
                    }
                </Col>
            </Row>
        </Container>
    )
}

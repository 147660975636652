export const uniqueBy = (data, prop) => {
    const result = [];
    const map = new Map();

    // eslint-disable-next-line no-restricted-syntax
    for (const item of [...data]) {
        if (!map.has(item[prop])) {
            map.set(item[prop], true);
            result.push({ ...item });
        }
    }
    return result;
};

/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    first, filter, isEmpty, find,
} from 'lodash';
import uuid from 'uuid';
import { useTranslation } from 'react-i18next';
import {
    FormSelect,
    FormGroup,
} from 'shards-react';

import {
    FormWithConstraints,
    Input,
    Async,
    FieldFeedbacks,
    FieldFeedback,
} from 'react-form-with-constraints-bootstrap4';

const ManageFormula = ({ id, scoreLogic, handler }) => {
    const { uid, formulas, computationCodes } = scoreLogic;
    const { t } = useTranslation();

    let defaults = {};

    if (id) {
        const savedData = Object(first(filter(formulas, { id })));
        defaults = { ...savedData };
    }

    const form = useRef(null);
    const [codeInputDisabled, setCodeInputDisabled] = useState(true);
    const [inputs, setInputs] = useState({
        ...{
            id: uuid(),
            code: '',
            description: '',
            name: '',
            expression: [],
            surveyUuid: uid,
            type: 'TIME_DEPENDENCY_FLAG',
        },
        ...defaults,
    });


    useEffect(() => {
        const isValidData = () => {
            const shouldEnableCodeInput = () => !id;
            setCodeInputDisabled(!shouldEnableCodeInput());

            if (inputs && !isEmpty(inputs.expression)) {
                return true;
            }
            return false;
        };

        // eslint-disable-next-line no-unused-expressions
        isValidData() && handler(inputs);
    }, [inputs, id]);

    const getCodeLabel = (xCode) => {
        const [filtered] = filter(computationCodes, { questionCode: xCode });
        const questionCode = Object(filtered);
        return `${questionCode.questionLabel} (${xCode})`;
    };

    const handleChange = async ({ target, currentTarget }) => {
        const { name, value } = target;
        setInputs((prevState) => ({ ...prevState, [name]: value }));
        // eslint-disable-next-line no-unused-expressions
        currentTarget.tagName !== 'LABEL' && await form.current.validateFields(currentTarget);
    };

    const checkCodeAvailability = (value) => isEmpty(find(formulas.filter((item) => item.surveyUuid === uid), { code: value.toUpperCase() }));

    const handleExpressionChange = async ({ target }) => {
        const { value } = target;
        setInputs({ ...inputs, expression: [value] });
    };

    const [computationCode] = inputs.expression;

    return (
        <FormWithConstraints ref={form} noValidate className="shadow-none">

            <FormGroup>
                <div className="flex">
                    <label>{t('module.scoreLogic.view.timeDependent.TDVCode')}</label>
                    <Input
                        className="form-control mb-2"
                        name="code"
                        defaultValue={inputs.code}
                        disabled={codeInputDisabled}
                        onChange={handleChange}
                        type="text"
                        required
                    />
                </div>
                <FieldFeedbacks for="code">
                    <FieldFeedback when="*" />
                    <Async
                        promise={checkCodeAvailability}
                        pending={<span className="d-block">...</span>}
                        then={(available) => (available ? (
                            ''
                        ) : (
                            <FieldFeedback key="2">T{t('module.scoreLogic.view.manageFormula.fieldFeedback')}</FieldFeedback>
                        ))
                        }
                    />
                </FieldFeedbacks>
            </FormGroup>

            <FormGroup>
                <div className="flex">
                    <label className="pr-4">{t('module.scoreLogic.view.timeDependent.computationCode')}</label>
                    <FormSelect required name="expression" value={computationCode} size="sm" className="form-control mb-2" onChange={ handleExpressionChange }>
                        <option key={-1} value="">{t('module.scoreLogic.view.projectionPeriod.selectQuestion')}</option>
                        {
                            computationCodes && computationCodes.map((questionnaire, idx) => {
                                const { questionCode } = questionnaire;
                                return <option key={idx} value={questionCode}>{ getCodeLabel(questionCode) }</option>;
                            })
                        }
                    </FormSelect>
                </div>
            </FormGroup>

            <FormGroup>
                <div className="flex">
                    <label>{t('module.scoreLogic.view.timeDependent.description')}</label>
                    <Input
                        className="form-control mb-2"
                        name="name"
                        defaultValue={inputs.name}
                        onChange={handleChange}
                        type="text"
                        required
                    />
                </div>
                <FieldFeedbacks for="name">
                    <FieldFeedback when="*" />
                </FieldFeedbacks>
            </FormGroup>
        </FormWithConstraints>
    );
};

const mapStateToProps = (state) => {
    const { scoreLogic } = state;
    return {
        scoreLogic,
    };
};

const connectedManageFormula = connect(mapStateToProps)(ManageFormula);
export { connectedManageFormula as ManageFormula };

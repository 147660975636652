export default (theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  controlLabel: {
    color: "#3d5170",
    fontSize: "15px",
  },
  mfiSelectGrid: {
    marginBottom: 20,
    paddingLeft: 8,
  },
  expBtn: {
    marginTop: 10,
  },
  selectRoot: {
    color: "#002060",
    fontSize: 11,
    paddingTop: 7,
    paddingBottom: 7,
  },
})

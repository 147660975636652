import React from 'react';

function VideoPlayer({ documentUrl }) {

    return (
        <video width="400" controls>
            <source src={documentUrl} type="video/mp4" />
            <source src={documentUrl} type="video/ogg" />
            Your browser does not support HTML video.
        </video>
    )
}

export default VideoPlayer;

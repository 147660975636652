import uuid from 'uuid';
import { commentsConstants } from '.';

export const comments = (state = [], action) => {
    switch (action.type) {
    case commentsConstants.COMMENTS_SUBMIT_SUCCESS:
        return [
            ...state,
            { ...action.data, uid: uuid() },
        ];
    case commentsConstants.COMMENTS_GETALL_SUCCESS:
        return [...action.data];
    case commentsConstants.COMMENTS_GETALL_FAILURE:
    default:
        return state;
    }
};

export const pendingTasksConstants = {
    /** get pending tasks */
    GETALL_PENDING_TASKS_REQUEST: 'GETALL_PENDING_TASKS_REQUEST',
    GETALL_PENDING_TASKS_SUCCESS: 'GETALL_PENDING_TASKS_SUCCESS',
    GETALL_PENDING_TASKS_FAILURE: 'GETALL_PENDING_TASKS_FAILURE',

    // view a single pending list tasks
    GET_SINGLE_PENDING_TASK_REQUEST: 'GET_SINGLE_PENDING_TASK_REQUEST',
    GET_SINGLE_PENDING_TASK_SUCCESS: 'GET_SINGLE_PENDING_TASK_SUCCESS',
    GET_SINGLE_PENDING_TASK_FAILURE: 'GET_SINGLE_PENDING_TASK_FAILURE',

    // BM view all pending tasks
    GETALL_BM_PENDING_TASKS_REQUEST: 'GETALL_BM_PENDING_TASKS_REQUEST',
    GETALL_BM_PENDING_TASKS_SUCCESS: 'GETALL_BM_PENDING_TASKS_SUCCESS',
    GETALL_BM_PENDING_TASKS_FAILURE: 'GETALL_BM_PENDING_TASKS_FAILURE'
}

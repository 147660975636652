export const creditScoreConstants = {
  CSL_FORMULA_RESULTS_REQUEST: "CSL_FORMULA_RESULTS_REQUEST",
  CSL_FORMULA_RESULTS_SUCCESS: "CSL_FORMULA_RESULTS_SUCCESS",
  CSL_FORMULA_RESULTS_FAILURE: "CSL_FORMULA_RESULTS_FAILURE",

  CSL_SCORE_CARD_REQUEST: "CSL_SCORE_CARD_REQUEST",
  CSL_SCORE_CARD_SUCCESS: "CSL_SCORE_CARD_SUCCESS",
  CSL_SCORE_CARD_FAILURE: "CSL_SCORE_CARD_FAILURE",

  CSL_JSON_CHANGES_REQUEST: "CSL_JSON_CHANGES_REQUEST",
  CSL_JSON_CHANGES_SUCCESS: "CSL_JSON_CHANGES_SUCCESS",
  CSL_JSON_CHANGES_FAILURE: "CSL_JSON_CHANGES_FAILURE",

  CSL_CREDIT_SCORE_REPORT_REQUEST: "CSL_CREDIT_SCORE_REPORT_REQUEST",
  CSL_CREDIT_SCORE_REPORT_SUCCESS: "CSL_CREDIT_SCORE_REPORT_SUCCESS",
  CSL_CREDIT_SCORE_REPORT_FAILURE: "CSL_CREDIT_SCORE_REPORT_FAILURE",

  CASH_FLOW_ANALYSIS_REQUEST: "CASH_FLOW_ANALYSIS_REQUEST",
  CASH_FLOW_ANALYSIS_SUCCESS: "CASH_FLOW_ANALYSIS_SUCCESS",
  CASH_FLOW_ANALYSIS_FAILURE: "CASH_FLOW_ANALYSIS_FAILURE",
}

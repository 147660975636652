export function formatDate(date, format) {
  var dd = date.getDate()
  var mm = date.getMonth() + 1
  var yyyy = date.getFullYear()

  if (dd < 10) dd = "0" + dd
  if (mm < 10) mm = "0" + mm

  switch (format) {
    case "m-d-y":
      return mm + "-" + dd + "-" + yyyy
    case "m/d/y":
      return mm + "/" + dd + "/" + yyyy
    case "d-m-y":
      return dd + "-" + mm + "-" + yyyy
    case "y-m-d":
      return yyyy + "-" + mm + "-" + dd
    case "d/m/y":
      return dd + "/" + mm + "/" + yyyy
    default:
      return mm + "-" + dd + "-" + yyyy
  }
}

import React from "react"
import { connect, useSelector } from "react-redux"
import { Button } from "shards-react"
import Svg from "react-inlinesvg"
import { useTranslation } from "react-i18next"
import { first } from "lodash"
import { alertActions } from "../../alert"
import { modalActions } from "../../modals/_actions"
import "./ApplicationsExport.scss"

const downloadSyncSVG = require("../../../assets/images/icons/download-icon.svg")

function ApplicationsExport(props) {
  const exportFormat = "xlsx"
  const includeStatus = true
  const { className, applications, dispatch } = props
  const { loading, uid: surveyUuid } = applications
  const { t } = useTranslation()
  const kobotoolkit = useSelector((state) => state.kobotoolkit)
  const { assets } = kobotoolkit

  const Export = () => {
    if (!surveyUuid) {
      dispatch(alertActions.error(t("alertActions.applications.noSurvey")))
      return
    }

    const survey = first(
      assets?.filter((asset) => {
        const { uid, versionId: deployedVersionId } = asset
        const [xFormId, xFormVersion] = surveyUuid.split(":")
        return uid === xFormId && deployedVersionId === xFormVersion
      })
    )
    const surveyName = survey?.name
    // Open modal
    dispatch(
      modalActions.exportApplications({
        surveyUuid,
        exportFormat,
        includeStatus,
        surveyName,
      })
    )
  }

  return (
    <Button
      onClick={() => {
        Export()
      }}
      className={`${className}`}
      style={{
        height: "fit-content",
        alignSelf: "center",
      }}
    >
      <span className="mr-1">
        {!loading && (
          <Svg
            className="text-center d-inline-block h-3"
            src={downloadSyncSVG}
          />
        )}
      </span>
      <span>
        {t("module.applicationManager.component.applicationsExport.button")}
      </span>
    </Button>
  )
}

const mapStateToProps = (state) => {
  const { applications } = state
  return { applications }
}

const connectedApplicationsExport = connect(mapStateToProps)(ApplicationsExport)
export { connectedApplicationsExport as ApplicationsExport }

import React, { useState } from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useDispatch, useSelector } from 'react-redux';
import {FormGroup,FormInput,ButtonGroup,Button,FormSelect} from 'shards-react';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/widget/widgetTitle';
import { mfiManagerActions } from '..';
import { modalActions } from '../../modals';
import { alertActions } from '../../alert';

export const AddHierarchy = ({ modalID, data }) => {
    const { hierarchy: levels } = useSelector((store) => store.mfis);
    const { id, mfiId, layer } = data;
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = useState(0);
    const [inputs, setInputs] = useState({ name: '', level: '' });
    const [inputs2, setInputs2] = useState({ level: '' });
    const { t } = useTranslation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (tabIndex === 0) {
          setInputs((prevState) => ({ ...prevState, [name]: value }));
        } else {
          setInputs2((prevState) => ({ ...prevState, [name]: value }));
        }
    };

    const addHierarchy = () => {
        const payload = {...inputs};
        let layerIndex, selectedIndex;
        levels.forEach((item, i) => { if (item.id === layer.id) { layerIndex = i; } });
        levels.forEach((item, i) => { if (item.id === Number(payload.level)) { selectedIndex = i; } });
        if (Number(payload.level) === layer.id) {
          dispatch(alertActions.error(t('alertActions.addHierarchy.addEntitySameLayerError')));
          return;
        } else if (Number(payload.level) < layer.id) {
          dispatch(alertActions.error(t('alertActions.addHierarchy.addEntityOntoLayer')));
          return;
        } else if (layerIndex+1 !== selectedIndex) {
          dispatch(alertActions.error(t('alertActions.addHierarchy.addEntityBelowLayer')));
          return;
        }
        let newPayload = {
          mfiId:  Number(mfiId),
          topHierarchyEntityId: Number(id.replace("node", "")),
          name: payload.name,
          hierarchyLayerId:  Number(payload.level),
        };
        dispatch(mfiManagerActions.addHierarchy(newPayload));
        closeModal();
    };

    const onSelectChanged = (e) => {
      const { currentTarget } = e;
      const { name, value } = currentTarget;
      setInputs((prevState) => ({ ...prevState, [name]: value }));
    }


    const addLayer = () => {
        const payload = {...inputs2};

        let newPayload = {
          mfiId: Number(mfiId),
          topHierarchyLayerId: layer.id,
          name: payload.level,
        };
        dispatch(mfiManagerActions.addLayer(newPayload));
        closeModal();
    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal">
            <Header title={tabIndex === 0 ? t('alertActions.addHierarchy.addEntity') : t('alertActions.addHierarchy.addLayer')}icon={<PersonOutlineOutlinedIcon />} close={true} closeHandler={ closeModal }/>
            <div className="hierarchy-modal-container">
            {tabIndex === 0
              ? (
                <>
                <h6>{t('alertActions.addHierarchy.addLayerInstead')}? <b onMouseDown={() => setTabIndex(1)}>{t('alertActions.addHierarchy.clickHere')}</b></h6>
                <hr />
                <FormGroup>
                    <label htmlFor="name">{t('alertActions.addHierarchy.entityName')}</label>
                    <FormInput defaultValue={inputs.name} name="name" size="md" className="mb-2" onChange={handleChange}/>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="level">{t('alertActions.addHierarchy.entityName')}</label>
                    <FormSelect name="level" size="md" className="mb-2" onChange={(e) => onSelectChanged(e)}>
                        <option value="">{t('alertActions.addHierarchy.selectLevel')}</option>
                        {
                            levels && levels.map((level, idx) => {
                                return <option key={idx} value={level.id}>{level.name}</option>;
                            })
                        }
                    </FormSelect>
                </FormGroup>
                <ButtonGroup className="btn-group-actions site-modal__actions">
                    <Button theme="primary" className="thin mr-2" onClick={() => addHierarchy()}>{t('alertActions.addHierarchy.save')}</Button>
                    <Button theme="secondary" className="thin delete" onClick={() => closeModal()}>{t('alertActions.addHierarchy.cancel')}</Button>
                </ButtonGroup>
                </>
              )
              : (
                <>
                <h6>{t('alertActions.addHierarchy.addEntityInstead')}? <b onMouseDown={() => setTabIndex(0)}>{t('alertActions.addHierarchy.clickHere')}</b></h6>
                <hr />
                <FormGroup>
                    <label htmlFor="name">{t('alertActions.addHierarchy.entityLevel')}</label>
                    <FormInput defaultValue={inputs2.level} name="level" size="md" className="mb-2" onChange={handleChange}/>
                </FormGroup>
                <ButtonGroup className="btn-group-actions site-modal__actions">
                    <Button theme="primary" className="thin mr-2" onClick={() => addLayer()}>{t('alertActions.addHierarchy.save')}</Button>
                    <Button theme="secondary" className="thin delete" onClick={() => closeModal()}>{t('alertActions.addHierarchy.cancel')}</Button>
                </ButtonGroup>
                </>
              )
            }
            </div>
        </div>
    );
};

import {
  axiosInstance,
  axiosInstanceODK,
  handleResponse,
} from "../../_helpers/base.service"

const serviceGroup = "workflow"

/**
 * Get all MFIs
 */
const getAllMFIs = () =>
  axiosInstance.get(`${serviceGroup}/mfis/`).then(handleResponse)

/**
 *
 * @param {*} id
 */
const getMFIById = (id) =>
  axiosInstance.get(`${serviceGroup}/mfis/${id}/`).then(handleResponse)

/**
 *
 * @param {*} data
 */
const createMFI = (mfi) =>
  axiosInstance.post(`${serviceGroup}/mfis/`, mfi).then(handleResponse)

/**
 * Update paylod
 *
 * @param {*} id
 * @param {*} mfi
 */
const updateMFI = (id, mfi) =>
  axiosInstance.patch(`${serviceGroup}/mfis/${id}/`, mfi).then(handleResponse)

/**
 * Delete MFI
 *
 * @param {*} id
 */
// eslint-disable-next-line no-underscore-dangle
const deleteMFI = (id) =>
  axiosInstance.delete(`${serviceGroup}/mfis/${id}/`).then(handleResponse)

/**
 * Get all MFI SubRoles
 * {
 * "code": "string",
 * "description": "string",
 * "mfiCode": "string",
 * "role": "string"
 * }
 */
const addMFISubRoles = (data) =>
  axiosInstance.post(`${serviceGroup}/sub-roles/`, data).then(handleResponse)

/**
 * Get Mfi Sub Roles
 *
 * @param {*} mfiCode int
 */
const getMFISubRoles = (mfiID) =>
  axiosInstance
    .get(`${serviceGroup}/sub-roles/`, {
      params: {
        mfiId: mfiID,
      },
    })
    .then(handleResponse)

/**
 * Get Mfi Roles
 *
 * @param {*} mfiCode int
 */
const getMFIRoles = () =>
  axiosInstance.get(`${serviceGroup}/roles/`).then(handleResponse)
/**
 * Update MFI SubRole
 *
 * @param {*} id
 * @param {*} mfi
 */
const updateMFISubRole = (id, mfi) =>
  axiosInstance
    .patch(`${serviceGroup}/sub-roles/sub-role/${id}/`, mfi)
    .then(handleResponse)

/**
 * Delete MFI SubRole
 *
 * @param {*} id
 */
// eslint-disable-next-line no-underscore-dangle
const deleteMFISubRole = (id) =>
  axiosInstance
    .delete(`${serviceGroup}/sub-roles/sub-role/${id}/`)
    .then(handleResponse)

/**
 * Get Workflow Groups
 *
 * @param {*} mfiId int
 */
const getWorkflowGroups = (mfiId) =>
  axiosInstance
    .get(`${serviceGroup}/workflow-groups/`, {
      params: {
        mfiId,
      },
    })
    .then(handleResponse)

/**
 * Add workflow group
 *
 * @param {*} data object
 */
const addWorkflowGroup = (data) =>
  axiosInstance
    .post(`${serviceGroup}/workflow-groups/`, data)
    .then(handleResponse)

/**
 * Update workflow group
 *
 */
const editWorkflowGroup = (id, description) =>
  axiosInstance
    .patch(`${serviceGroup}/workflow-groups/workflow-group/${id}/`, {
      description: description,
    })
    .then(handleResponse)

/**
 * Add loan distributed
 *
 * @param {*} data object
 */
const addLoanDistributed = (data) =>
  axiosInstance
    .post(`${serviceGroup}/loan-distributed/`, data)
    .then(handleResponse)

/**
 * Update loan distributed item
 *
 */
const editLoanDistributed = (id, payload) => {
  return axiosInstance
    .patch(
      `${serviceGroup}/loan-distributed/loan-distributed-item/${id}/`,
      payload
    )
    .then(handleResponse)
}

/**
 * Get loan distributed for an application
 *
 * @param {*} applicationId string
 */
const getLoanDistributed = (applicationId) =>
  axiosInstance
    .get(`${serviceGroup}/loan-distributed/`, {
      params: {
        applicationId,
      },
    })
    .then(handleResponse)

/**
 * Update custom menus
 *
 */
const editCustomMenus = (payload) => {
  const { id, title, description, enabled, componentName, mfiId } = payload
  const newPayload = {
    title,
    description,
    enabled,
    componentName,
    mfiId,
  }
  return axiosInstance
    .patch(`${serviceGroup}/customize-menus/customize-menu/${id}/`, newPayload)
    .then(handleResponse)
}

/**
 * Update mpesa details
 *
 */
const editMpesaDetails = (payload) => {
  const { id, ...newPayload } = payload
  return axiosInstance
    .patch(
      `${serviceGroup}/mpesa-details/mpesa-detail/${payload.id}/`,
      newPayload
    )
    .then(handleResponse)
}

/**
 * Add mpesa details
 *
 * @param {*} data object
 */
const addMpesaDetails = (data) =>
  axiosInstance
    .post(`${serviceGroup}/mpesa-details/`, data)
    .then(handleResponse)

/**
 * Get Custom Menus for an Mfi
 *
 * @param {*} mfiId int
 */
const getMpesaDetails = (mfiId) =>
  axiosInstance
    .get(`${serviceGroup}/mpesa-details/`, {
      params: {
        mfiId,
      },
    })
    .then(handleResponse)

/**
 * Add custom menu
 *
 * @param {*} data object
 */
const addCustomMenu = (data) =>
  axiosInstance
    .post(`${serviceGroup}/customize-menus/`, data)
    .then(handleResponse)

/**
 * Get Custom Menus for an Mfi
 *
 * @param {*} mfiId int
 */
const getCustomMenus = (mfiId) =>
  axiosInstance
    .get(`${serviceGroup}/customize-menus/`, {
      params: {
        mfiId,
      },
    })
    .then(handleResponse)

/**
 * Get Mfi Role Limits
 *
 * @param {*} mfiCode int
 */
const getMFISubRoleLimits = (mfiCode) =>
  axiosInstance
    .get(`${serviceGroup}/loan-limit-categories/mfi`, {
      params: {
        mfiCode,
      },
    })
    .then(handleResponse)

/**
 * Add Mfi Role Limits
 *
 * @param {*} mfiCode int
 */
const addMFISubRoleLimits = (data) =>
  axiosInstance
    .post(`${serviceGroup}/loan-limit-categories/`, data)
    .then(handleResponse)

/**
 * Update Mfi Role Limits
 *
 * @param {*} mfiCode int
 */
const updateMFISubRoleLimits = (id, data) =>
  axiosInstance
    .patch(
      `${serviceGroup}/loan-limit-categories/loan-limit-category/${id}`,
      data
    )
    .then(handleResponse)

/**
 * Delete Mfi Role Limits
 *
 * @param {*} mfiCode int
 */
const deleteMFISubRoleLimits = (id) =>
  axiosInstance
    .delete(`${serviceGroup}/loan-limit-categories/loan-limit-category/${id}`)
    .then(handleResponse)

/**
 * Add Mfi Role
 */
const submitMFISubRoleLimitFlow = (data) =>
  axiosInstance
    .post(`${serviceGroup}/loan-limit-category-workflow/`, data)
    .then(handleResponse)

/**
 * Update Mfi Role
 */
const updateMFISubRoleLimitFlow = (data) =>
  axiosInstance
    .patch(`${serviceGroup}/loan-limit-category-workflow/`, data)
    .then(handleResponse)

/**
 * Get mfi Hierarchy Data
 *
 * @param {*} mfiCode int
 */
const getHierarchy = (mfiId) =>
  axiosInstance
    .get(`${serviceGroup}/hierarchy`, {
      params: {
        mfiId,
      },
    })
    .then(handleResponse)

/**
 * Update hierarchy entity
 */
const editHierarchy = (data, id) =>
  axiosInstance
    .patch(`${serviceGroup}/hierarchy/hierarchy-entity/${id}/`, data)
    .then(handleResponse)

/**
 * Update hierarchy Layer
 */
const editHierarchyLayer = (data, id) =>
  axiosInstance
    .patch(`${serviceGroup}/hierarchy/hierarchy-layer/${id}/`, data)
    .then(handleResponse)

/**
 * add hierarchy entity
 */
const addHierarchy = (data) =>
  axiosInstance
    .post(`${serviceGroup}/hierarchy/hierarchy-entity/`, data)
    .then(handleResponse)

/**
 * add hierarchy Layer
 */
const addLayer = (data) =>
  axiosInstance
    .post(`${serviceGroup}/hierarchy/hierarchy-layer`, data)
    .then(handleResponse)

// MOCK API CALLS
const getQuestionnaires = (id) => {
  return axiosInstanceODK.get(`/forms/?mfiId=${id}`).then(handleResponse)
}

const addQuestionnaire = (data) => {
  // Initialize the form data
  let formData = new FormData()
  formData.append("mfi_id", Number(data.mfi_id))
  formData.append("title", data.title)
  formData.append("description", data.description)
  formData.append("group_creation", data.groupCreation)
  formData.append("collection", data.collection)
  formData.append("submission", data.submision)
  return axiosInstanceODK.post("/forms/", formData).then(handleResponse)
}

const editQuestionnaire = (data, id) => {
  let formData = new FormData()
  formData.append("mfi_id", Number(data.mfi_id))
  formData.append("title", data.title)
  formData.append("description", data.description)
  data.submision !== null && formData.append("submission", data.submision)
  return axiosInstanceODK.put(`/forms/${id}/`, formData).then(handleResponse)
}

const deleteQuestionnaire = (formId) => {
  return axiosInstanceODK.delete(`/forms/${formId}/`).then(handleResponse)
}

const deactivateQuestionnaire = (data, id) => {
  return axiosInstanceODK
    .patch(`/forms/${id}/status/`, data)
    .then(handleResponse)
}

const resetQuestionnaire = (data, id) => {
  return axiosInstance
    .post(`/creditscore/surveys/survey/reset-scoring-configuration`, data)
    .then(handleResponse)
}

const addMfiImage = (data) => {
  // Initialize the form data
  let formData = new FormData()
  formData.append("mfi_id", Number(data.mfiId))
  formData.append("title", data.title)
  formData.append("submission", data.submission)
  return axiosInstanceODK.post("/images/", formData).then(handleResponse)
}

const getMfiImages = (id) => {
  return axiosInstanceODK.get(`/images/?mfiId=${id}`).then(handleResponse)
}

const deleteMfiImage = (imageId) => {
  return axiosInstanceODK.delete(`/images/${imageId}/`).then(handleResponse)
}

const addExternalIntegration = (data) => {
  return axiosInstance.post("/workflow/integrations", data).then(handleResponse)
}

const editExternalIntegration = (data) => {
  return axiosInstance
    .patch("/workflow/integrations", data)
    .then(handleResponse)
}

const getExternalIntegration = (id) => {
  return axiosInstance.get(`/workflow/integrations/${id}`).then(handleResponse)
}

/**
 * MFI Manager Service
 */
export const mfiManagerService = {
  getAllMFIs,
  getMFIById,
  createMFI,
  updateMFI,
  deleteMFI,
  addMFISubRoles,
  getMFISubRoles,
  updateMFISubRole,
  deleteMFISubRole,
  getMFISubRoleLimits,
  addMFISubRoleLimits,
  updateMFISubRoleLimits,
  deleteMFISubRoleLimits,
  submitMFISubRoleLimitFlow,
  updateMFISubRoleLimitFlow,
  getWorkflowGroups,
  addWorkflowGroup,
  editWorkflowGroup,
  getHierarchy,
  editHierarchy,
  editHierarchyLayer,
  addHierarchy,
  addLayer,
  getMFIRoles,
  getQuestionnaires,
  addQuestionnaire,
  addMfiImage,
  getMfiImages,
  deleteMfiImage,
  editQuestionnaire,
  deleteQuestionnaire,
  deactivateQuestionnaire,
  resetQuestionnaire,
  addExternalIntegration,
  editExternalIntegration,
  getExternalIntegration,
  addCustomMenu,
  getCustomMenus,
  editCustomMenus,
  addLoanDistributed,
  getLoanDistributed,
  editLoanDistributed,
  addMpesaDetails,
  editMpesaDetails,
  getMpesaDetails,
}

import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody,  makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import { clientActions } from '../../../../_actions';

const useStyles = makeStyles(() => ({
    pageOption: {
        border: 'thin solid grey',
        borderRadius: '3px',
        padding: '2px',
        marginRight: '1rem',
    }
  }));

const ViewNotes = ({ mfiId, loanId }) => {
    const client = useSelector((state) => state.clients);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { notesError, notes } = client;
    const [state, setState] = useState({
        page: 0,
        size: 5,
    });

    useEffect(() => {
        dispatch(clientActions.getClientNotes(mfiId, loanId, state?.page, state?.size));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mfiId, loanId]);

    const classes = useStyles();

    const pageOptionChange = (e) => {
        setState({ ...state, size: e.target.value });
        dispatch(clientActions.getClientNotes(mfiId, loanId, state?.page, e.target.value ));
    }

    const handleChangePage = (page) => {
        setState({ ...state, page });
        dispatch(clientActions.getClientNotes(mfiId, loanId, page, state?.size));
    };

    return (
        <div>
            {(notesError || notes?.content?.length === 0) && (
                <>
                    <br />
                    <h4>{notesError?.response?.data?.error || t('module.client.arrears.action.modal.documentChecklist')}</h4>
                </>
            )}
            {!notesError && notes?.content?.length > 0 && (
                <TableContainer>
                    <Table aria-label="view notes table">
                        <TableHead color="red">
                            <TableRow style={{ backgroundImage: 'linear-gradient(to bottom, #f8fbfe, #eff5fc)' }}>
                                <TableCell>{t('module.client.arrears.action.modal.date')}</TableCell>
                                <TableCell align="right">{t('module.client.arrears.action.modal.officer')}</TableCell>
                                <TableCell align="right">{t('module.client.arrears.action.modal.mode')}</TableCell>
                                <TableCell align="right">{t('module.client.arrears.action.modal.notes')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notes?.content?.map((note) => (
                                <TableRow key={note?.id}>
                                    <TableCell component="th" scope="row">
                                        {note?.date}
                                    </TableCell>
                                    <TableCell align="right">{note?.officer}</TableCell>
                                    <TableCell align="right">{note?.mode?.label}</TableCell>
                                    <TableCell align="right">{note?.notes}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <br />
            <br />

            {notes?.content?.length > 0 && (
                <div className="pagination">
                    <select
                        value={state?.size}
                        onChange={pageOptionChange}
                        className={classes.pageOption}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                    </select>
                    Page {state.page + 1} {t('module.client.arrears.action.modal.of')} {notes.totalPages} &nbsp;&nbsp;
                    <button
                        onClick={state.page > 0 ? () => handleChangePage(state.page-1) : null}
                        className={`${state.page < 1 && 'pagination__previous-disabled'}`}
                        style={{ cursor: `${state.page < 1 && 'not-allowed'}` }}
                    >
                        <ArrowBackIosIcon />
                    </button> &nbsp;
                    {state.page + 1} &nbsp;
                    <button
                        onClick={state.page + 1 < notes.totalPages ? () => handleChangePage(state.page+1) : null}
                        className={`${state.page + 2 > notes.totalPages && 'pagination__previous-disabled'}`}
                        style={{ cursor: `${state.page + 2 > notes.totalPages && 'not-allowed'}` }}
                    >
                            <ArrowForwardIosIcon />
                    </button>&nbsp;
                </div>
            )}
        </div>
    )
}

export default ViewNotes;

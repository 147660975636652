import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavLink,
} from "shards-react"
import { authenticationActions } from "../../../../modules/authentication"
import { Input as InputIcon } from "@material-ui/icons"
import { connect } from "react-redux"
import { replace } from "lodash"

const UserActions = (props) => {
  const [state, setState] = useState({
    visible: false,
  })
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const toggleUserActions = () => {
    setState({
      visible: !state.visible,
    })
  }

  const { user } = props.authentication

  if (!user || !user.user_name) {
    return null
  }

  const { user_name: userName, name } = user

  return (
    <Dropdown
      open={state.visible}
      toggle={toggleUserActions}
      className="user-profile-header relative pr-4"
    >
      <DropdownToggle caret tag={NavLink} className="flex text-nowrap relative">
        <div className="pr-2 pl-4">
          <div className="user-username font-bold">{userName}</div>
          <div className="user-role text-xs">{name}</div>
        </div>
      </DropdownToggle>
      <Collapse tag={DropdownMenu} right small open={state.visible}>
        <DropdownItem
          className="text-danger"
          onClick={() => dispatch(authenticationActions.logout())}
        >
          <InputIcon />{" "}
          {t("component.layout.mainNavbar.navbarNav.userActions.logout")}
        </DropdownItem>
      </Collapse>
    </Dropdown>
  )
}

const mapStateToProps = (state) => {
  const { authentication } = state
  return {
    authentication,
  }
}

const connectedUserActions = connect(mapStateToProps)(UserActions)
export { connectedUserActions as UserActions }

import React from 'react';
import { useDispatch } from 'react-redux';
import TooltipTrigger from 'react-popper-tooltip';
import { modalActions } from '../../../modals';

export function TableRowsActions(props) {
    const {
        id,
        code,
        description,
        mfiCode,
        mfiId,
    } = props;

    const dispatch = useDispatch();

    const editWorkflowGroup = () => {
        dispatch(
            modalActions.manageWorkflowGroups({
                id,
                mfiCode,
                mfiId,
                code,
                description,
                title: 'Edit workflow Group',
                edit: true
            }),
        );
    };


    function Trigger({ getTriggerProps, triggerRef }) {
        return (
            <button
                {...getTriggerProps({
                    ref: triggerRef,
                    className: 'trigger dropdown-toggle btn btn-primary',
                })}
            >
                Actions
            </button>
        );
    }

    function Tooltip({
        getTooltipProps,
        getArrowProps,
        tooltipRef,
        arrowRef,
        placement,
    }) {
        return (
            <div
                {...getTooltipProps({
                    ref: tooltipRef,
                    className: 'tooltip-container table-actions',
                })}
            >
                <div
                    {...getArrowProps({
                        ref: arrowRef,
                        'data-placement': placement,
                        className: 'tooltip-arrow',
                    })}
                />
                <div className="tooltip-body">
                    <div className="dropdown dropdown-right">{items}</div>
                </div>
            </div>
        );
    }

    const items = (
        <>
            <div className="dropdown-item">
                <div onClick={ () => { editWorkflowGroup(); } }>Edit</div>
            </div>
        </>
    );

    return (
        <>
            {items && <TooltipTrigger placement="right" trigger="click" tooltip={Tooltip}>
                {Trigger}
            </TooltipTrigger>}
        </>
    );
}

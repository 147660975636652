import { notificationConstants } from "./_constants";

const initialState = {
    notificationsData: undefined,
    loading: false,
    realTimeNotifications: {}
};

export const notifications = (state = initialState, action) => {
    switch (action.type) {
        case notificationConstants.FETCH_NOTIFICATIONS_REQUEST: {
            return {
                ...state,
                loading: true

            };
        }
        case notificationConstants.FETCH_NOTIFICATIONS_FAILURE: {
            return {
                ...state,
                loading: false

            };
        }
        case notificationConstants.MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST: {
            return {
                ...state,
                loading: true
            };
        }
        case notificationConstants.MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS: {
            return {
                ...state,
                loading: false

            };
        }
        case notificationConstants.MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE: {
            return {
                ...state,
                loading: false

            };
        }
        case notificationConstants.FETCH_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                notificationsData: action.data,
                loading: false
            };
        }
        case notificationConstants.CLEAR_NOTIFICATIONS: {
            return {
                ...state,
                notificationsData: undefined
            };
        }
        case notificationConstants.GET_REALTIME_NOTIFICATIONS: {
            return {
                ...state,
                realTimeNotifications: action.data
            };
        }

        default:
            return state;
    }
}

import React from 'react';
import {
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    DatePicker,
    Button,
} from 'shards-react';
import { useTranslation } from 'react-i18next';
import Svg from 'react-inlinesvg';
import { FormWithConstraints } from 'react-form-with-constraints-bootstrap4';
import './DateFilter.scss';

const datePickerIcon = require('../../../assets/images/icons/date-picker-icon.svg');


export function DateFilter(props) {
    const {
        from, to, handleChangeFrom, handleChangeTo, dateColumnFilter, highlightDates
    } = props;
    const { t } = useTranslation();
    return (
      <FormWithConstraints noValidate className="shadow-none">
        <InputGroup seamless className="mb-2" size="lg">
          <DatePicker
            className="form-control"
            placeholderText={t('module.report.clickToSelectStartDate')}
            selected={from}
            onChange={e => handleChangeFrom(e)}
            highlightDates={highlightDates}
            dropdownMode={t('module.report.select')}
          />
          <InputGroupAddon type="append">
            <InputGroupText>
              <Svg className="text-center d-inline-block" src={datePickerIcon} />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
        <InputGroup seamless className="mb-2" size="lg">
          <DatePicker
            className="form-control"
            placeholderText={t('module.report.clickToSelectEndDate')}
            selected={to}
            onChange={e => handleChangeTo(e)}
            highlightDates={highlightDates}
            dropdownMode={t('module.report.select')}
          />
          <InputGroupAddon type="append">
            <InputGroupText>
              <Svg className="text-center d-inline-block" src={datePickerIcon} />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
        <div className="btn-group-actions site-modal__actions flex relative">
            <Button
              className="reset-btn mr-2"
              onClick={ () => dateColumnFilter(true) }>
              Reset
            </Button>
            <Button
              className="filter-btn mr-2"
              onClick={ () => dateColumnFilter() }>
              Filter
            </Button>
        </div>
      </FormWithConstraints>
    );
}

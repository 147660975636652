import React from 'react';
import { WithWizard } from 'react-albus';
import Svg from 'react-inlinesvg';
import { size } from 'lodash';
import Header from '../../../components/widget/widgetTitle';


const downloadSyncSVG1 = require('../../../assets/images/icons/configuration-heading-icon.svg');
const downloadSyncSVG2 = require('../../../assets/images/icons/configuration-heading-icon2.svg');

export function Heading(props) {
    const { titles: steps, point } = props;
    const icon = point === 1 ? downloadSyncSVG1 : downloadSyncSVG2;
    return (
        <WithWizard
            render={ ({ step }) => {
                let index = 0;
                Object.keys(steps).map((key, idx) => {
                    if (key === step.id) {
                        index = idx + 1;
                    }
                    return key;
                });

                return (
                    <Header title="Configuration" icon={<Svg className="text-center d-inline-block" src={icon} />}>
                        {point === 2 && <span className="ml-auto mr-4 font-bold text-sm">{`${index} of ${size(steps)}`}</span>}
                    </Header>
                );
            } }
        />
    );
}

import React, { useEffect, useState, useCallback } from "react"
import { connect, useSelector } from "react-redux"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { Container, Row, Col } from "shards-react"
import { withTranslation } from "react-i18next"
// import { surveyActions } from '../../surveys';
import { ApplicationEditor } from "../../applicationEditor"
import { DocumentChecklist } from "../../documentChecklist/views/List"
import { Comments, AddComment } from "../../comments"
import { applicationActions } from ".."

import PageTitle from "../../../components/common/PageTitle"

const EditApplication = ({ surveys, dispatch, t, applications }) => {
  const { surveyDeployment, uid } = applications
  const [questionnaireName, setQuestionnaireName] = useState("")
  const [tabIndex, setTabIndex] = useState(0)
  const [state, setState] = useState({ uid: null })

  const authentication = useSelector((state) => state.authentication)
  const mfiId = authentication.profile.mfi.id

  const {
    id,
    // xformId,
    xformversion,
    tab,
    loggedInUser,
  } = applications.applicationClicked
  const clickedApplicationDetails =
    applications.items && applications.items.filter((item) => id === item.id)

  const getQuestionnaire = useCallback(() => {
    if (surveys.assets) {
      surveys.assets.map((questionnaire, idx) => {
        const { name, versionId } = questionnaire
        let re = new RegExp(versionId, "g")
        return xformversion.match(re) && setQuestionnaireName(name)
      })
    }
    return
  }, [surveys.assets, xformversion])

  const getTab = useCallback(() => {
    // check if user is trying to access checklist tab
    if (tab && tab === "checklist") {
      setTabIndex(1)
    }
  }, [tab])

  useEffect(() => {
    setState({ uid: uid })
  }, [uid])

  useEffect(() => {
    getTab()
  }, [getTab])

  useEffect(() => {
    getQuestionnaire()
  }, [getQuestionnaire])

  useEffect(() => {
    let xFormId = ""
    if (state && state.uid) {
      ;[xFormId] = state.uid.split(":")
      dispatch(applicationActions.getFormFields(xFormId))
    }
  }, [dispatch, state])

  /**
   * Set Questionnaire uid and Get formulas by UID
   */
  useEffect(() => {
    state &&
      state.uid &&
      dispatch(applicationActions.setQuestionnaire(state.uid))
  }, [state, dispatch])

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header pt-4 pb-1">
        <PageTitle sm="4" title={questionnaireName} className="text-sm-left" />
      </Row>
      <Row>
        <Col>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <TabList>
              <Tab>{t("module.applicationManager.view.edit.edit")}</Tab>
              <Tab>{t("module.applicationManager.view.edit.checklist")}</Tab>
              <Tab>{t("module.applicationManager.view.view.comments")}</Tab>
            </TabList>
            <TabPanel>
              <ApplicationEditor id={id} mfiId={mfiId} {...surveyDeployment} />
            </TabPanel>
            <TabPanel>
              <div className="p-10">
                <DocumentChecklist id={id} />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="p-10">
                <h2 className="section__title">
                  {t("module.applicationManager.view.view.recommendations")}
                </h2>
                <Comments id={id} />
                {clickedApplicationDetails &&
                  clickedApplicationDetails[0].currentUser === loggedInUser &&
                  clickedApplicationDetails[0].workflowStatus === "Review" && (
                    <AddComment id={id} />
                  )}
              </div>
            </TabPanel>
          </Tabs>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => {
  const { surveys, applications } = state
  return { surveys, applications }
}

const connectedEditApplication = withTranslation()(
  connect(mapStateToProps)(EditApplication)
)
export { connectedEditApplication as EditApplication }

import React from "react"
import { connect } from "react-redux"
import { commentActions } from "../_actions"
import { ApplicationDetailsActions } from "../../applicationManager"

import "./Comments.scss"
import { withTranslation } from "react-i18next"

class Comments extends React.Component {
  constructor(props) {
    super(props)
    props.dispatch(commentActions.getAll(props.id))
  }

  render() {
    const { comments, authentication, t } = this.props
    const loggedInUser = authentication.profile.name
    const mfiName = authentication.profile.mfi.mfiName

    return (
      <>
        <div className="comments">
          {comments &&
            comments.map((item, idx) => {
              const { name, timestamp, note, signatureCloudUrl, ip } = item
              return (
                <div
                  className={`${
                    name === loggedInUser ? "comment-right" : "comment-left"
                  }`}
                  key={idx}
                >
                  <div className="meta">
                    <span className="name">{name}</span>{" "}
                    {t("module.comments.component.view.repliedOn")}{" "}
                    <span className="time">{timestamp}</span>
                  </div>
                  <div className="content">{note}</div>
                  {mfiName === "AMZ" && (
                    <div className="meta">
                      <img
                        style={{ maxWidth: "50%", display: "inline-block" }}
                        src={signatureCloudUrl}
                        alt="Approver Signature"
                      />
                      <span>ip: {ip}</span>
                    </div>
                  )}
                </div>
              )
            })}
          {comments.length === 0 && (
            <div className="comments-empty">
              {t("module.comments.component.view.descriptionNoComments")}.
            </div>
          )}
        </div>
        {!this.props.noCloseButton && (
          <ApplicationDetailsActions id={this.props.id} saveDisabled={true} />
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const { comments, authentication } = state
  return {
    comments,
    authentication,
  }
}

const connectedComments = withTranslation()(connect(mapStateToProps)(Comments))
export { connectedComments as Comments }

import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { FormInput } from "shards-react"
import { Switch } from "@material-ui/core"
import { mfiManagerActions } from "../.."

export function EditableCell({
  rowData: { id },
  column: { key },
  cellData,
  toggleBtn,
}) {
  const dispatch = useDispatch()
  const [value, setValue] = useState(cellData)
  useEffect(() => {
    dispatch(mfiManagerActions.updateMFISubRoleLimit(id, key, value))
  }, [id, key, value, dispatch])

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const handleToggle = (e) => {
    setValue(e.target.checked)
  }

  if (toggleBtn) {
    return (
      <>
        {value}
        <Switch
          onChange={handleToggle}
          checked={value}
          color="primary"
          name="enabled"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </>
    )
  }

  return <FormInput onChange={handleChange} defaultValue={value} />
}

import { Link } from "react-router-dom"
import React from "react"
import Svg from "react-inlinesvg"

const barGraphBgSVG = require("../../../../assets/images/icons/bar-graph-bg.svg")

const ClientCard = ({
  link,
  title,
  subtitle,
  count,
  icon,
  unique,
  pageUid,
  all,
}) => {
  return (
    <Link
      className="report-card px-2"
      style={{
        cursor: `${link ? "pointer" : "default"}`,
        textDecoration: "none",
        backgroundImage: `${
          unique && "linear-gradient(to bottom, #00b668, #008d6a)"
        }`,
        color: `${unique && "white"}`,
      }}
      to={{
        pathname: link,
        state: { pageUid, all },
      }}
    >
      <p
        className="report-card-title"
        style={{ color: `${unique && "white"}` }}
      >
        {title}
      </p>
      <p
        className="report-card-title"
        style={{ color: `${unique ? "#e1e229" : "#818ea3"}` }}
      >
        {subtitle}
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "1rem",
        }}
      >
        <div
          className="report-card-value"
          style={{ color: `${unique ? "#e1e229" : "#107d54"}` }}
        >
          {count}
        </div>
        <img src={icon} alt="icon" />
      </div>
      {!unique && (
        <Svg className="text-center d-inline-block" src={barGraphBgSVG} />
      )}
      {/* {unique && <Svg className="text-center d-inline-block" src={ elementDashboardBgSVG } style={{ position: 'relative' }} />} */}
    </Link>
  )
}

export default ClientCard


import React from 'react';
import { Redirect } from 'react-router-dom';
import { authenticationActions } from '..';

export function LogoutView() {
    authenticationActions.logout();
    return (
        <Redirect to='/login' />
    );
}

/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import currency from 'currency.js';
import { isEmpty } from 'lodash';
import Svg from 'react-inlinesvg';
import { withTranslation } from 'react-i18next';

import {
    Form,
    FormGroup,
    FormTextarea,
    ButtonGroup,
    Button,
} from 'shards-react';
import Header from '../../../components/widget/widgetTitle';
import { workflowActions } from '..';
import { userRoleConstants } from '../../../_helpers';
import { commentActions } from '../../comments';
import { modalActions } from '../../modals';
import { alertActions } from '../../alert';
import { applicationActions } from '../../applicationManager';

const headerSVG = require('../../../assets/images/icons/header-icon.svg');

const ApproveApplication = ({
    currency: settingsCurrency, applicantLoanData, authentication, modalID, data, t,
}) => {
    const {
        applicationId, approve, decline, fullSubmission,
    } = data;

    const {
        applicantName, applicantLoan,
    } = applicantLoanData;

    const dispatch = useDispatch();
    const [state, setState] = useState({
        roles: [],
        loanLimit: null,
        loanAmount: null,
        applicantName: null,
    });

    const [hasWorkflow, setHasWorkflow] = useState(false);

    const title = approve ? t('module.applicationManager.modal.recalculateScore.approveLoan') : t('module.applicationManager.modal.recalculateScore.declineLoan');
    const { profile } = authentication;

    useEffect(() => {
        fullSubmission && dispatch(applicationActions.getLoanInformation(fullSubmission));
    }, [fullSubmission]);

    useEffect(() => {
        const { roles, loanLimit, subRole } = profile;
        setState((prevState) => ({ ...prevState, loanLimit, roles }));
        setHasWorkflow(!!subRole);
    }, [profile]);

    useEffect(() => {
        const { roles, loanLimit, subRole } = profile;
        setState((prevState) => ({
            ...prevState, loanLimit, subRole, roles,
        }));
    }, [profile]);

    const aboveLimitErrorMessage = `${t('module.applicationManager.modal.recalculateScore.loanOf')} ${currency(applicantLoan, { symbol: settingsCurrency, formatWithSymbol: true }).format()} ${t('module.applicationManager.modal.recalculateScore.loanAmountNNotFound')} ${currency(state.loanLimit, { symbol: settingsCurrency, formatWithSymbol: true }).format()}. ${t('module.applicationManager.modal.recalculateScore.assignApplicationSupervisor')}.`;
    const loanAmountErrorMessage = t('module.applicationManager.modal.recalculateScore.loanApprovalLimit');
    const loanApprovalLimitErrorMessage = t('module.applicationManager.modal.recalculateScore.aboveSet');

    const [form, setValues] = useState({
        note: '',
    });

    const handleChange = (e) => {
        setValues({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const validLoanLimit = () => {
        // Bypass Loan Limit check if user has workflow
        if (hasWorkflow) {
            return true;
        }

        // Bypass Loan Limit check for MFI_SUPERVISOR
        if (state.roles.includes(userRoleConstants.MFI_SUPERVISOR)) {
            return true;
        }

        if (!parseInt(applicantLoan, 10)) {
            dispatch(alertActions.error(loanAmountErrorMessage));
            return false;
        }

        if (!parseInt(state.loanLimit, 10)) {
            dispatch(alertActions.error(loanApprovalLimitErrorMessage));
            return false;
        }

        if (parseInt(applicantLoan, 10) >= parseInt(state.loanLimit, 10)) {
            dispatch(alertActions.error(aboveLimitErrorMessage));
            return false;
        }

        return true;
    };

    const approveApplication = () => {
        if (!validLoanLimit()) {
            closeModal();
            return false;
        }

        const messages = {
            editSuccessful: t('alertActions.applications.editSuccessful')
        }

        dispatch(
            workflowActions.approveApplication(
                applicationId,
                messages,
            ),
        );

        dispatch(
            commentActions.addComment(
                applicationId,
                form.note,
            ),
        );

        closeModal();
        return false;
    };

    const declineApplication = () => {
        if (!validLoanLimit()) {
            closeModal();
            return false;
        }

        dispatch(
            workflowActions.declineApplication(
                applicationId,
            ),
        );

        dispatch(
            commentActions.addComment(
                applicationId,
                form.note,
            ),
        );

        closeModal();
        return false;
    };

    const closeModal = () => {
        dispatch(modalActions.hideModal(modalID));
    };

    return (
        <div className="site-modal">
            <Header title={title} icon={<Svg className="text-center d-inline-block" src={ headerSVG } />} close={true} closeHandler={ closeModal } />

            <div className="p-10">
                <div><span className="font-bold">{t('module.applicationManager.modal.recalculateScore.applicantName')}</span> <span>{applicantName}</span></div>
                <div className="mb-3"><span className="font-bold">{t('module.workFlow.modal.approveModal.loanAmount')}</span> <span>{currency(applicantLoan, { symbol: settingsCurrency, formatWithSymbol: true }).format()}</span></div>

                <Form className="shadow-none">
                    <FormGroup>
                        <label htmlFor="noteTextarea">{t('module.workFlow.modal.approveModal.comment')}</label>
                        <FormTextarea id="#noteTextarea" name="note" className="mb-2" onChange={handleChange} defaultValue={form.note} />
                    </FormGroup>

                    <ButtonGroup className="btn-group-actions site-modal__actions">
                        { approve && <Button theme="primary" className="thin mr-2" onClick={ () => { approveApplication(); } } >{t('module.workFlow.modal.approveModal.approve')}</Button> }
                        { decline && <Button theme="secondary" className="thin decline mr-2" onClick={ () => { declineApplication(); } } >{t('module.workFlow.modal.approveModal.decline')}</Button> }
                        <Button theme="cancel" className="thin delete" onClick={ () => { closeModal(); } }>{t('module.workFlow.modal.approveModal.cancel')}</Button>
                    </ButtonGroup>
                </Form>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const {
        applications, settings, authentication, modals,
    } = state;
    // eslint-disable-next-line no-shadow
    const { currency } = settings;
    const { applicationMetadata } = applications;
    let applicantLoanData = {};
    if (!isEmpty(applicationMetadata)) {
        const foundApplicant = applicationMetadata.filter((item) => item.type === 'APPLICANT_NAME');
        const foundLoan = applicationMetadata.filter((item) => item.type === 'LOAN_AMOUNT');
        const [applicantItem] = foundApplicant;
        const [loanItem] = foundLoan;
        const { value: applicantName } = applicantItem;
        const { value: applicantLoan } = loanItem;
        applicantLoanData = { applicantName, applicantLoan };
    }

    return {
        currency, applicantLoanData, authentication, modals,
    };
};

const connectedApproveApplication = withTranslation()(connect(mapStateToProps)(ApproveApplication));
export { connectedApproveApplication as ApproveApplication };

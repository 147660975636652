import React, { useState, useEffect, useRef } from "react"
import { Button, FormGroup, FormSelect } from "shards-react"
import { useDispatch, useSelector } from "react-redux"
import {
  FormWithConstraints,
  Input,
  Async,
  FieldFeedbacks,
  FieldFeedback,
} from "react-form-with-constraints-bootstrap4"
import { isEmpty, find, isArray } from "lodash"
import { useTranslation } from "react-i18next"
import { history, userRoleConstants, hasAuthority } from "../../../_helpers"
import { userManagerActions } from "../index"
import { mfiManagerActions } from "../../mfiManager"
import { authenticationActions } from "../../authentication"
import { alertActions } from "../../alert"

const BASE_URL = window.location.origin

export function ManageUser({ id }) {
  const { t } = useTranslation()
  /**
   * Fetch all data
   */
  const filteredState = useSelector((state) => {
    const { items: mfiList, subRoles, hierarchy } = state.mfis
    const { items: userList } = state.users
    const { profile } = state.authentication
    let user = {
      name: null,
      username: null,
      email: null,
      telephone: null,
      roles: [],
      mfiName: null,
      subRole: null,
      loanLimit: 0,
      office: null,
    }
    if (id) {
      ;[user] = userList.filter((item) => Number(item.id) === Number(id))
    }
    return {
      user,
      userList,
      mfiList,
      profile,
      subRoles,
      hierarchy,
    }
  })

  const { user, userList, mfiList, profile, subRoles, hierarchy } =
    filteredState
  const dispatch = useDispatch()
  const formEl = useRef(null)
  const fieldDisable = !!id
  const [roleConfig, setRoleConfig] = useState([])
  const [subRoleConfig, setSubRoleConfig] = useState([...subRoles])
  const [mfiCode, setMfiCode] = useState(profile.mfi && profile.mfi.mfiCode)
  const [hierarchyLayerEntities, setHierarchyLayerEntities] = useState("")
  const [inputs, setInputs] = useState(user)
  const [officeConfig, setOfficeConfig] = useState([])
  const [mfiTag, setMfiTag] = useState("")
  const baseurl = window.location.origin


  useEffect(() => {
    if (subRoleConfig && subRoleConfig.length > 0 && id) {
      // const selectedUser = userList.filter((item) => Number(item.id) === Number(id))[0];
      const selectedUser = user
      const subRoleData = subRoleConfig.filter(
        (item) => item.code === selectedUser.subRole
      )
      const officeData = selectedUser.hierarchyEntity
        ? selectedUser.hierarchyEntity.id
        : ""
      // get subrole id
      const updatedInputs = {
        name: selectedUser.name,
        username: selectedUser.username,
        email: selectedUser.email,
        telephone: selectedUser.telephone,
        roles: selectedUser.roles,
        mfiName: selectedUser.mfi ? selectedUser.mfi.mfiName : null,
        subRole: subRoleData.length > 0 ? subRoleData[0].id : "",
        loanLimit: 0,
        office: officeData,
      }
      // const newHierarchyLayerEntities = subRoleConfig.filter((item) => Number(item.id) === Number(officeData));
      if (subRoleData.length > 0 && subRoleData[0].hierarchyLayer) {
        setHierarchyLayerEntities(subRoleData[0].hierarchyLayer)
      }
      setInputs(updatedInputs)
    }
  }, [subRoleConfig, id, userList, user])

  useEffect(() => {
    if (profile.mfi) {
      // get hierarchy data.
      dispatch(mfiManagerActions.getHierarchy(profile.mfi.id))
      // get subroles
      dispatch(mfiManagerActions.getMFISubRoles(profile.mfi.id))
      if (mfiList && mfiList.length === 0) {
        ;(!hasAuthority(userRoleConstants.FA_ANALYST) ||
          !hasAuthority(userRoleConstants.FA_ADMIN)) &&
          setMfiTag(profile.mfi.mfiName.toLowerCase())
      }
    }
  }, [dispatch, profile, mfiList])

  useEffect(() => {
    // filter through hierarchy to display entities attached to this role
    if (hierarchyLayerEntities) {
      const selectedHierarchyLayer = hierarchy.filter(
        (item, i) => item.id === hierarchyLayerEntities.id
      )
      if (selectedHierarchyLayer && selectedHierarchyLayer.length > 0) {
        setOfficeConfig(selectedHierarchyLayer[0].entities)
      }
    }
  }, [hierarchy, hierarchyLayerEntities])

  /**
   * Make api call to fetch MFIs on load
   */
  useEffect(() => {
    dispatch(authenticationActions.getProfile())
    !hasAuthority(userRoleConstants.MFI_ADMIN) &&
      dispatch(mfiManagerActions.getAllMFIs())
  }, [dispatch])

  useEffect(() => {
    setSubRoleConfig(() => subRoles)
  }, [dispatch, mfiCode, subRoles])

  useEffect(() => {
    if (inputs.mfiName !== "FA") {
      const foundMFI = mfiList.filter((item) => item.mfiName === inputs.mfiName)
      const [mfi] = foundMFI
      if (mfi) {
        setMfiCode(mfi.mfiCode)
      }
    }
  }, [mfiList, inputs.mfiName])

  // useEffect(() => {
  //     mfiCode && dispatch(mfiManagerActions.getMFISubRoles(mfiCode));
  // }, [dispatch, mfiCode]);

  /**
   * Set correct MFIname.
   */
  useEffect(() => {
    if (profile && profile.mfi) {
      const { mfi } = profile
      setMfiCode(mfi.mfiCode)
      setInputs((prevState) => ({
        ...prevState,
        mfiName: hasAuthority(userRoleConstants.MFI_ADMIN)
          ? mfi.id
          : prevState.mfiName,
      }))
    }
  }, [profile])

  /**
   * Set SubRoles and Roles.
   */
  useEffect(() => {
    if (
      hasAuthority(userRoleConstants.FA_ANALYST) ||
      hasAuthority(userRoleConstants.FA_ADMIN)
    ) {
      // if (inputs.mfiName === 'FA') {
      //     setRoleConfig([
      //         'FA_ANALYST',
      //         'FA_ADMIN',
      //     ]);
      // } else {
      setRoleConfig(["MFI_ADMIN", "FA_ANALYST", "FA_ADMIN"])
    } else if (hasAuthority(userRoleConstants.MFI_ADMIN)) {
      setRoleConfig([
        "MFI_LOAN_OFFICER",
        "MFI_CREDIT_ANALYST",
        "MFI_SUPERVISOR",
      ])
    }
  }, [inputs.mfiName])

  /**
   * Clean up username to make sure it has no MFI prefix
   * The MFI prefix will be automatically added when submitting the request.
   */
  useEffect(() => {
    if (mfiTag) {
      setInputs((prevState) => {
        if (!prevState.username || !prevState.mfiName) {
          return prevState
        }
        const { username } = prevState
        const prefix = `${mfiTag}_`
        const newUsername = username.replace(prefix, "")
        return { ...prevState, username: newUsername }
      })
    }
  }, [inputs.mfiName, inputs.username, mfiTag])

  useEffect(() => {
    setInputs((prevState) => {
      if (isArray(inputs.roles)) {
        const [firstRole] = inputs.roles
        return { ...prevState, role: firstRole }
      }
      return prevState
    })
  }, [inputs.roles])

  const getPrefixedUsername = () => `${mfiTag.toLowerCase()}_${inputs.username}`

  async function checkUsernameAvailability() {
    if (id) {
      return true
    }
    return isEmpty(
      find(userList, { username: getPrefixedUsername().toLowerCase() })
    )
  }

  const handleChange = async ({ target, currentTarget }) => {
    const { name, value } = currentTarget
    setInputs((prevState) => ({ ...prevState, [name]: value }))
    await formEl.current.validateFields(target)
  }

  const handleMfiChange = ({ currentTarget }) => {
    const { value } = currentTarget
    setInputs((prevState) => ({ ...prevState, mfiName: value }))
    setMfiTag(mfiList.filter((item) => item.id === Number(value))[0].mfiName)
  }

  const handleMfiRoleChange = ({ currentTarget }) => {
    const { value } = currentTarget
    setInputs((prevState) => ({ ...prevState, role: value }))
  }

  const handleSubRoleChange = ({ currentTarget }) => {
    const { value } = currentTarget
    setInputs((prevState) => ({ ...prevState, subRole: value }))
    const newHierarchyLayerEntities = subRoleConfig.filter(
      (item) => item.id === value
    )
    if (newHierarchyLayerEntities[0].hierarchyLayer) {
      setHierarchyLayerEntities(newHierarchyLayerEntities[0].hierarchyLayer)
      // clear office when user changes subrole
      setInputs((prevState) => ({ ...prevState, office: "" }))
      dispatch(alertActions.info(t("alertActions.manageUser.selectOffice")))
    } else {
      // show message that role is not attached to any offices
      dispatch(alertActions.error(t("alertActions.manageUser.subRoleError")))
    }
  }

  const handleMfiOfficeChange = ({ currentTarget }) => {
    const { value } = currentTarget
    setInputs((prevState) => ({ ...prevState, office: value }))
  }

  const onSuccessRedirect = () => {
    history.push("/users")
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    await formEl.current.validateForm()
    const formIsValid = formEl.current.isValid()

    if (!formIsValid) {
      return false
    }
    let createPayload = {}
    let updatePayload = {}
    if (hasAuthority(userRoleConstants.MFI_ADMIN)) {
      if (!inputs.office) {
        dispatch(
          alertActions.error(t("alertActions.manageUser.createUserError"))
        )
        return
      }

      if (!id) {
        createPayload = {
          email: inputs.email,
          telephone: inputs.telephone,
          hierarchyEntityId: Number(inputs.office),
          name: inputs.name,
          subRoleId: Number(inputs.subRole),
          username: getPrefixedUsername().toLowerCase(),
          baseurl: baseurl,
        }
        dispatch(userManagerActions.mfiAdminRegister(createPayload))
        return
      }

      updatePayload = {
        hierarchyEntityId: Number(inputs.office),
        subRoleId: Number(inputs.subRole),
      }
      dispatch(
        userManagerActions.mfiAdminUpdate(
          id,
          updatePayload,
          inputs.status,
          onSuccessRedirect
        )
      )
    } else if (hasAuthority(userRoleConstants.FA_ANALYST)) {
      if (!id) {
        createPayload = {
          email: inputs.email,
          telephone: inputs.telephone,
          name: inputs.name,
          role: inputs?.role,
          mfiId: Number(inputs.mfiName),
          username: getPrefixedUsername().toLowerCase(),
          baseurl: baseurl,
        }
        dispatch(userManagerActions.faAnalystRegister(createPayload))
        return
      }
    } else if (hasAuthority(userRoleConstants.FA_ADMIN)) {
      if (!id) {
        createPayload = {
          email: inputs.email,
          telephone: inputs.telephone,
          name: inputs.name,
          role: inputs?.role,
          username: getPrefixedUsername().toLowerCase(),
          baseurl: baseurl,
        }
        dispatch(userManagerActions.faAnalystRegister(createPayload))
        return
      }
    }

    // if (!id) {
    //     dispatch(userManagerActions.register({
    //         name: inputs.name,
    //         username: getPrefixedUsername().toLowerCase(),
    //         role: inputs.role,
    //         subRole: inputs.subRole,
    //         mfiName: inputs.mfiName,
    //         email: inputs.email,
    //         loanLimit: inputs.loanLimit,
    //     }));
    // }

    // if (id) {
    //     dispatch(userManagerActions.update(id, {
    //         name: inputs.name,
    //         role: inputs.role,
    //         subRole: inputs.subRole,
    //         loanLimit: inputs.loanLimit,
    //     }, inputs.status, onSuccessRedirect));
    // }

    return
  }

  const getMfiSelect = () => (
    <FormGroup>
      <label htmlFor="mfiName">{t("module.users.mfi.mfiName")}</label>
      <FormSelect
        name="mfiName"
        value={inputs.mfiName}
        onChange={handleMfiChange}
        disabled={fieldDisable}
      >
        <option key={-1} value="">
          {t("module.users.mfi.selectMfiName")}
        </option>
        {mfiList.map((item, idx) => (
          <option key={idx} value={item.id}>
            {item.mfiName}
          </option>
        ))}
      </FormSelect>
    </FormGroup>
  )

  const getMfiMainRole = () => (
    <FormGroup>
      <label htmlFor="role">Role</label>
      <FormSelect
        name="role"
        value={inputs.role}
        onChange={handleMfiRoleChange}
      >
        {/* TODO: Find a better way to display options/roles according to the current user */}
        <option key={-1} value="">
          Select role
        </option>
        {hasAuthority(userRoleConstants.FA_ADMIN) && (
          <>
            <option value="FA_ADMIN">FA_ADMIN</option>
            <option value="FA_ANALYST">FA_ANALYST</option>
          </>
        )}
        {hasAuthority(userRoleConstants.FA_ANALYST) && (
          <option value="MFI_ADMIN">MFI_ADMIN</option>
        )}
        {hasAuthority(userRoleConstants.MFI_ADMIN) &&
          roleConfig.map((item, idx) => (
            <option key={idx} value={item}>
              {item}
            </option>
          ))}
      </FormSelect>
    </FormGroup>
  )

  const mfiOfficeLogic = (item) => {
    if (item.entityType === "Process") {
      return item.id
    }
    return item.topHierarchyEntityId ? item.topHierarchyEntityId : item.id
  }

  const getMfiOffices = () => (
    <FormGroup>
      <label htmlFor="role">Office</label>
      <FormSelect
        name="role"
        value={inputs.role}
        onChange={handleMfiOfficeChange}
      >
        <option key={-1} value="">
          Select Office
        </option>
        {officeConfig?.map((item, idx) => (
          <option key={idx} value={mfiOfficeLogic(item)}>
            {item.name}
          </option>
        ))}
      </FormSelect>
    </FormGroup>
  )

  const getMfiSubRole = () => (
    <FormGroup>
      <label htmlFor="subRole">{t("module.users.mfi.mfiSubRole")}</label>
      <FormSelect
        name="subRole"
        value={inputs.subRole}
        onChange={handleSubRoleChange}
      >
        <option key={-1} value="">
          {t("module.users.mfi.selectSubRole")}
        </option>
        {subRoleConfig.map((item, idx) => {
          const { id, description } = item
          return (
            <option key={idx} value={id}>
              {description}
            </option>
          )
        })}
      </FormSelect>
    </FormGroup>
  )

  const getUserLimits = () => (
    <FormGroup>
      <label htmlFor="loanLimit">{t("module.users.mfi.loanLimit")}</label>
      <Input
        required
        type="number"
        name="loanLimit"
        className="form-control"
        defaultValue={inputs.loanLimit}
        onChange={handleChange}
      />
      <FieldFeedbacks for="loanLimit">
        <FieldFeedback when="*" />
      </FieldFeedbacks>
    </FormGroup>
  )

  return (
    <FormWithConstraints
      ref={formEl}
      noValidate
      className="user-add-form shadow-none p-10"
      onSubmit={handleSubmit}
    >
      <h2 className="form-group-section">
        {t("module.users.addNew.userDetails")}
      </h2>
      <div className="flex">
        <div className="w-1/2 pr-10">
          <FormGroup>
            <label htmlFor="name">{t("module.users.addNew.fullNames")}</label>
            <Input
              type="text"
              id="name"
              disabled={fieldDisable}
              name="name"
              className="form-control"
              defaultValue={inputs.name}
              required
              onChange={handleChange}
            />
            <FieldFeedbacks for="name">
              <FieldFeedback when="*" />
            </FieldFeedbacks>
          </FormGroup>

          <FormGroup>
            <label htmlFor="email">
              {t("module.users.addNew.emailAddress")}
            </label>
            <Input
              type="email"
              id="email"
              disabled={fieldDisable}
              name="email"
              className="form-control"
              defaultValue={inputs.email}
              onChange={handleChange}
            />
            <FieldFeedbacks for="email">
              <FieldFeedback when="*" />
            </FieldFeedbacks>
          </FormGroup>

          <FormGroup>
            <label htmlFor="telephone">
              {t("module.users.addNew.telephone")}
            </label>
            <Input
              type="tel"
              id="telephone"
              disabled={fieldDisable}
              name="telephone"
              className="form-control"
              defaultValue={inputs.telephone}
              onChange={handleChange}
              pattern="\+[0-9]{3}[0-9]{9}"
              placeholder="+254720123456"
            />
            <FieldFeedbacks for="telephone">
              <FieldFeedback when="*" />
            </FieldFeedbacks>
          </FormGroup>
        </div>
        <div className="w-1/2">
          <FormGroup>
            <label htmlFor="username">
              {t("module.users.addNew.username")}
            </label>
            <div className="input-group mb-3">
              {mfiTag && (
                <div className="input-group-prepend">
                  <span className="input-group-text">{mfiTag}_</span>
                </div>
              )}
              <Input
                type="text"
                id="username"
                disabled={fieldDisable}
                name="username"
                className="form-control"
                placeholder={inputs.username}
                required
                onChange={handleChange}
              />
            </div>
            <FieldFeedbacks for="username">
              <FieldFeedback when="*" />
              <Async
                promise={checkUsernameAvailability}
                pending={<span className="d-block">...</span>}
                then={(available) =>
                  available ? (
                    <FieldFeedback key="1" info style={{ color: "#28a745" }}>
                      {t("module.users.addNew.usernameIsAvailable")}
                    </FieldFeedback>
                  ) : (
                    <FieldFeedback key="2">
                      {t("module.users.addNew.usernameIsTaken")}
                    </FieldFeedback>
                  )
                }
              />
            </FieldFeedbacks>
          </FormGroup>
          {hasAuthority(userRoleConstants.FA_ANALYST) && getMfiSelect()}
          {(hasAuthority(userRoleConstants.FA_ANALYST) ||
            hasAuthority(userRoleConstants.FA_ADMIN)) &&
            getMfiMainRole()}
          {hasAuthority(userRoleConstants.MFI_ADMIN) && getMfiSubRole()}
          {hasAuthority(userRoleConstants.MFI_ADMIN) && getMfiOffices()}
        </div>
      </div>

      {isEmpty(subRoleConfig) &&
        inputs.roles &&
        inputs.roles.includes(userRoleConstants.MFI_CREDIT_ANALYST) && (
          <div className="p-1">
            <h2 className="form-group-section">
              {t("module.users.addNew.permissions")}
            </h2>
            <div className="flex">
              <div className="w-1/2 pr-10">{getUserLimits()}</div>
            </div>
          </div>
        )}

      <FormGroup className="action-group application-action-group">
        <div className="inline-flex">
          <Button
            theme="primary"
            className="mx-2 d-table ml-auto btn"
            type="submit"
          >
            {t("module.users.addNew.saveClose")}
          </Button>
          <Button
            theme="secondary"
            className="mx-2 d-table ml-auto btn"
            onClick={() => {
              history.push("/users")
            }}
          >
            {t("module.users.addNew.close")}
          </Button>
        </div>
      </FormGroup>
    </FormWithConstraints>
  )
}

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {Form, FormGroup} from 'shards-react';
import { alertActions } from '../../alert';
import './SetUploadFile.scss';

export const SetUploadFile = ({ handler: fileHandler }) => {
    const fileInputEl = useRef(null);
    const dispatch = useDispatch();
    const [file, setFile] = useState({});
    const { t } = useTranslation()

    const supportedFilesTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];

    useEffect(() => {
        fileHandler(file);
    }, [file, fileHandler]);

    const isValid = (input) => {
        if (!input) {
            return false;
        }
        const { type } = input;

        if (supportedFilesTypes.includes(type)) {
            return true;
        }
        return false;
    };

    const handleFileSelect = (event) => {
        const { target: { files } } = event;
        event.preventDefault();
        const [tempFile] = files;
        if (isValid(tempFile)) {
            return setFile(tempFile);
        }
        dispatch(alertActions.error(t('alertActions.mfiManager.uploadExcelFile')));
        return false;
    };

    const { name } = file;

    return (
        <Form className="select-file shadow-none pr-6 pl-6">
            <FormGroup>
                <input className="border p-1 file-upload" type="file" ref={fileInputEl} name="importFile" onChange={handleFileSelect} accept={supportedFilesTypes.join(',')}/>
                <label htmlFor="file-import" className="m-0 flex items-center" onClick={() => fileInputEl.current.click()} >
                    <span>{name || t('module.tools.import.choosexlsxFile')}</span>
                    <strong>{t('module.tools.import.chooseFile')}</strong>
                </label>
            </FormGroup>
        </Form>
    );
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    background: "#d8e8f4",
    padding: "10px 16px",
  },
}));

const CardTitle = ({ title }) => {
  const classes = useStyles();

  return <div className={classes.root}>{title}</div>;
};

export default CardTitle;

import React from "react"

import { ResetPassword as ResetPasswordComponent } from ".."
import { Branding } from "../../../components/branding"

export const ResetPasswordView = () => (
  <div className="login-page">
    <Branding />
    <ResetPasswordComponent />
  </div>
)

import { creditScoreConstants } from "."

export const creditScore = (state = {}, action) => {
  switch (action.type) {
    case creditScoreConstants.CSL_FORMULA_RESULTS_REQUEST:
    case creditScoreConstants.CSL_SCORE_CARD_REQUEST:
      return { ...state, loading: true }

    case creditScoreConstants.CSL_FORMULA_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        graph: action.data,
      }

    case creditScoreConstants.CSL_SCORE_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        score: action.data,
      }
    case creditScoreConstants.CSL_JSON_CHANGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        changeLogs: action.data,
      }
    case creditScoreConstants.CSL_CREDIT_SCORE_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        report: [],
      }
    case creditScoreConstants.CSL_CREDIT_SCORE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        report: action.data,
      }
    case creditScoreConstants.CSL_CREDIT_SCORE_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        report: [],
      }
    case creditScoreConstants.CSL_FORMULA_RESULTS_FAILURE:
    case creditScoreConstants.CSL_SCORE_CARD_FAILURE:
      return { ...state, error: action.error }
    case creditScoreConstants.CASH_FLOW_ANALYSIS_REQUEST:
      return { ...state, cashFlowLoading: true, error: null, cashFlow: [] }
    case creditScoreConstants.CASH_FLOW_ANALYSIS_SUCCESS:
      return {
        ...state,
        cashFlowLoading: false,
        error: null,
        cashFlow: action.data,
      }
    case creditScoreConstants.CASH_FLOW_ANALYSIS_FAILURE:
      return {
        ...state,
        cashFlowLoading: false,
        error: action.error,
        cashFlow: [],
      }
    default:
      return state
  }
}

import React from "react"
import ReactApexChart from "react-apexcharts"

export default function ScoreChart({ climateFinalScore, climateMaxScore }) {
  const climateScorePercent = Math.round(
    (climateFinalScore / climateMaxScore) * 100
  )
  const series = [climateScorePercent]

  const options = {
    chart: {
      width: "80%",
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 1,
            fontWeight: "bold",
            fontSize: "1.2rem",
          },
        },
      },
    },
    colors: ["#008d6a"],
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="radialBar"
      width="80%"
      height={185}
    />
  )
}

/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from "react"
import PropTypes from "prop-types"
import Svg from "react-inlinesvg"
import { NavLink as RouteNavLink } from "react-router-dom"
import { connect } from "react-redux"
import {
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownItem,
  Collapse,
} from "shards-react"

import { settingsActions } from "../../../modules/settings"
import { withTranslation } from "react-i18next"

const AMZ_NAMES = ["groups", "villageBanks", "collections"]

class SidebarNavItem extends React.Component {
  constructor(props) {
    super(props)
    this.toggleDropdown = this.toggleDropdown.bind(this)
  }

  toggleDropdown(item) {
    this.props.dispatch(settingsActions.toggleSidebarDropdown(item))
  }

  isAuthorised(item) {
    const { roles, authorities, profile } = this.props
    const mfiName = profile?.mfi?.mfiName

    if (mfiName !== "UGACOF" && item.name === "payments") {
      return false
    }

    if (mfiName !== "AMZ" && AMZ_NAMES.includes(item.name)) {
      return false
    }

    if (!roles || !authorities) {
      return false
    }

    if (authorities) {
      const newAuthorities = [...authorities]
      newAuthorities.push("PUBLIC")
      if (newAuthorities.some((authority) => roles.indexOf(authority) >= 0)) {
        return true
      }
    }

    return false
  }

  render() {
    const { item, settings, t, profile } = this.props
    const hasSubItems = item.subMenus && item.subMenus.length
    const classes = "pl-3 m-0"

    const mfiName = profile?.mfi?.mfiName

    if (!this.isAuthorised(item)) {
      return null
    }

    return (
      <NavItem style={{ paddingLeft: `${hasSubItems && "8px"}` }}>
        <NavLink
          className={hasSubItems && `dropdown-toggle ${classes}`}
          tag={hasSubItems ? "a" : RouteNavLink}
          to={hasSubItems ? "#" : item.to}
          onClick={() => this.toggleDropdown(item)}
        >
          {item.icon && (
            <Svg
              className="d-inline-block item-icon-wrapper"
              src={require(`../../../assets/images/icons/${item.icon}`)}
            />
          )}
          {item.htmlBefore && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
            />
          )}
          {item.title && settings.menuVisible && (
            <span>{t(`data.sidebarNavItems.${item.name}`)}</span>
          )}
          {item.htmlAfter && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
            />
          )}
        </NavLink>
        {hasSubItems && (
          <Collapse
            tag={DropdownMenu}
            small
            open={item.open}
            style={{ top: 0 }}
          >
            {item.subMenus
              .filter(
                (menu) =>
                  mfiName === "UGACOF" ||
                  (menu.name !== "paymentsReport" &&
                    menu.name !== "syncExternalIntegration")
              )
              .map((subItem, idx) => (
                <DropdownItem key={idx} tag={RouteNavLink} to={subItem.to}>
                  {t(`data.sidebarNavItems.${subItem.name}`)}
                </DropdownItem>
              ))}
          </Collapse>
        )}
      </NavItem>
    )
  }
}

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object,
}

const mapStateToProps = (state) => {
  const { settings, authentication } = state
  const { user, profile } = authentication
  const { authorities } = user || ""
  return { settings, authorities, profile }
}

const connectedSidebarNavItem = withTranslation()(
  connect(mapStateToProps)(SidebarNavItem)
)
export { connectedSidebarNavItem as SidebarNavItem }

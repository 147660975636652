// import axios from 'axios';
import { axiosInstance, handleResponse } from '../../_helpers';

export const surveyService = {
    getSurveys,
};

const serviceGroup = 'creditscore';


function getSurveys(mfiId) {
    let endpoint = `${serviceGroup}/surveys/mfi/`
    if (mfiId) {
      endpoint = `${serviceGroup}/surveys/mfi/${mfiId}`
    }
    return axiosInstance.get(endpoint).then(handleResponse);
}
